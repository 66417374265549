import React, { useContext, useEffect, useMemo } from 'react'
import { BigBody, Body, H2, Small } from '../../../../../styles/typography'
import { StyledRadioButton } from '../../../../../features/subscription/locationSubscription/styles'
import { StyledAlertWrapper, StyledDateStepWrapper, StyledOptionCard, StyledPublishWrapper } from './styles'
import "react-datepicker/dist/react-datepicker.css";
import { StyledDatePicker } from '../../../../../features/reportsDatePicker/StyledDatePicker'
import TwinkleIcon from '../../../../../assets/icons/TwinkleIcon'
import { FiAlertCircle } from 'react-icons/fi';
import { PrimeTimePostingType, PublishMethod, PUBLISH_METHOD, ScheduleMethod, SCHEDULE_METHOD } from './types';
import { isSameDay } from '../../../../../helpers/date.util';
import { Creative } from '../../../../../features/creatives/creative.types';
import { gmtToLocaleString } from '../../../../../helpers';
import { useTheme } from 'styled-components';

// * Step Validaiton
export const validateDateStep = (date: Date, time: Date, publishMethod: PublishMethod, scheduleMethod: ScheduleMethod): Error | null => {
    const isFutureAndCustom = (publishMethod === PUBLISH_METHOD.FUTURE) && (scheduleMethod === SCHEDULE_METHOD.CUSTOM)

    if (date && !scheduleMethod) {
        const errorObject = {
            name: 'MissingScheduleMethod',
            message: 'Missing Schedule Option'
        }

        return errorObject
    }

    // * At this point the user has opted to schedule (custom) - we expect a date AND time
    if (publishMethod === PUBLISH_METHOD.FUTURE && !date) {
        const errorObject = {
            name: 'DateError',
            message: 'Please Select A Date'
        }
        return errorObject
    }

    if (isFutureAndCustom && !time) {
        const errorObject = {
            name: 'TimeError',
            message: 'Please Select A Time'
        }
        return errorObject
    }

    return null
}

// * Getting Initial Dates for POSTS - we'll remove the exp check later
export const getInitialPostDates = (creative: Creative) => {
    // * Case 1: Post has been posted (ASAP)
    if (!creative || !creative?.start) return { start: null, peakStartDate: null }

    const now = new Date()
    const initialStart = new Date(gmtToLocaleString(creative?.start, 'none'))
    const isFutureDate = initialStart > now

    // * Case 2: Post is SCHEDULED and HAS a peakStartDate (we set initialDate as the BE populates this field for us on the first date selector)
    if (creative?.peakStartDate && creative?.start && isFutureDate) return { start: initialStart, peakStartDate: creative?.peakStartDate }

    // * Case 3: Post is SCHEDULED and is using a CUSTOM time
    if (!creative.peakStartDate && creative.start && isFutureDate) return { start: initialStart, peakStartDate: null }

    // * Fallback
    return { start: null, peakStartDate: null }
}

type CreativeStepPostProps = {
    context: any,
    primeTimePostingData: PrimeTimePostingType
}


const CreativeStepPostDate = ({ context, primeTimePostingData } : CreativeStepPostProps) => {
    const theme = useTheme()
    const { formData }: { formData: any, updateFormData: (key: any, value: any) => void } = useContext(context)

    const {
        publishMethod,
        setPublishMethod,
        scheduleMethod,
        setScheduleMethod,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
    } = primeTimePostingData


    // * Editing Flow (pre-select stuff : any if post is STILL scheduled)
    useEffect(() => {
        // * Case 1: Creative was scheduled to use prime time posting but hasn't been posted yet
        if (!!formData?.peakStartDate && !!formData?.start) {
            setPublishMethod(PUBLISH_METHOD.FUTURE)
            setScheduleMethod(SCHEDULE_METHOD.PRIME)
            return
        }

        // * Case 2: Creative was scheduled to with a custom time
        if (!!formData?.start) {
            setPublishMethod(PUBLISH_METHOD.FUTURE)
            setScheduleMethod(SCHEDULE_METHOD.CUSTOM)
            return
        }
    }, [])


    // * The user cannot schedule a Post for Prime Time IF they select TODAYS date
    const isPrimeTimeDisabled = useMemo(() => {
        const now = new Date()
        return isSameDay(now, selectedDate)
    }, [selectedDate])

    // * Copy Text For Placeholder
    const userTimeZoneText = `(${new Date().toLocaleDateString('en-US', {
        day: '2-digit',
        timeZoneName: 'short'
    }).slice(4)})`;

    return (
        <StyledDateStepWrapper>
            <H2 regular>Schedule Post Publishing</H2>
            <div style={{ marginTop: '24px' }}>
                <BigBody style={{ marginBottom: '16px' }}>Select publish date</BigBody>
                <div className='date-options-wrapper'>
                    <StyledOptionCard>
                        <StyledRadioButton
                            type='radio'
                            name='publishType'
                            value='publish'
                            onChange={() => setPublishMethod(PUBLISH_METHOD.ASAP)}
                            checked={publishMethod === PUBLISH_METHOD.ASAP}
                        />
                        <div>
                            <Body semibold>ASAP</Body>
                            <Small>Publish your post immediately after you are done building.</Small>
                        </div>
                    </StyledOptionCard>
                    <StyledOptionCard>
                        <StyledRadioButton
                            type='radio'
                            name='publishType'
                            value='publish'
                            onChange={() => setPublishMethod(PUBLISH_METHOD.FUTURE)}
                            checked={publishMethod === PUBLISH_METHOD.FUTURE}
                        />
                        <div>
                            <Body semibold>Schedule for the Future</Body>
                            <Small>Publish your post at a set date and time for all your networks, campaigns, and businesses.</Small>
                            {
                                publishMethod === 'Future' &&
                                <StyledPublishWrapper>
                                    <div className='date-picker-wrapper'>
                                        <StyledDatePicker
                                            minDate={new Date()}
                                            selected={selectedDate}
                                            onChange={(date: Date) => setSelectedDate(date)}
                                            selectsStart
                                            placeholderText={`Select a date ${userTimeZoneText}`}
                                            startDate={selectedDate}
                                        />
                                    </div>
                                    {
                                        selectedDate &&
                                        <div className='publish-options-wrapper'>
                                            <StyledOptionCard>
                                                <StyledRadioButton
                                                    type='radio'
                                                    name='scheduleMethod'
                                                    value='schedule'
                                                    onChange={() => setScheduleMethod(SCHEDULE_METHOD.PRIME)}
                                                    disabled={isPrimeTimeDisabled}
                                                    checked={scheduleMethod === SCHEDULE_METHOD.PRIME}
                                                />
                                                <div>
                                                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}><TwinkleIcon color={theme.color.accentColor2} /><Body semibold>Prime Time (recommended)</Body></div>
                                                    <Small>Publish your post for each network based on their peak user engagement times.</Small>
                                                        {
                                                            isPrimeTimeDisabled &&
                                                            <StyledAlertWrapper>
                                                                <FiAlertCircle />
                                                                <Small>
                                                                    Prime Time cannot be used when publish date is set to the current date.
                                                                </Small>
                                                            </StyledAlertWrapper>
                                                        }
                                                </div>
                                            </StyledOptionCard>
                                            <StyledOptionCard>
                                                <StyledRadioButton
                                                    type='radio'
                                                    name='scheduleMethod'
                                                    value='schedule'
                                                    onChange={() => setScheduleMethod(SCHEDULE_METHOD.CUSTOM)}
                                                    checked={scheduleMethod === SCHEDULE_METHOD.CUSTOM}
                                                />
                                                <div>
                                                    <Body semibold>Custom Time</Body>
                                                    <Small>Publish your post at a set date and time for all your networks, campaigns, and businesses.</Small>
                                                    {
                                                        scheduleMethod === 'Custom' &&
                                                        <div className='date-picker-wrapper'>
                                                            <StyledDatePicker
                                                                minDate={formData?.start}
                                                                onChange={(date: Date) => setSelectedTime(date)}
                                                                placeholderText={`Select a time ${userTimeZoneText}`}
                                                                selected={selectedTime}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                isClearable
                                                                timeIntervals={60}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                                onKeyDown={(e) => e.preventDefault()} // * This prevents users from entering a custom time - we can only allow scheduling posts by the hour
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </StyledOptionCard>
                                        </div>
                                    }
                                </StyledPublishWrapper>
                            }
                        </div>
                    </StyledOptionCard>
                </div>
            </div>
        </StyledDateStepWrapper>
    )
}

export default CreativeStepPostDate