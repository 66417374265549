import React from "react";
import {
  StyledYoutubePreview,
  OptionalPreviews,
  YoutubeTopSection,
  CreativeContainer,
} from "./styles";
import youTicon from "../../assets/images/youtube_icon.png";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { H2, Small } from "../../styles/typography";
import { getPreviewImageUrl } from "../../helpers/creative.util";
import styled from "styled-components";
import StringUtil from "../../helpers/string.util";
import useVersionData from "../../hooks/useVersionData";


const YouTubePreview = ({ creative, previewText }) => {
  const { preview, youTubeId, cta } = creative;
  const { defaultCta } = useVersionData()

  return (
    <StyledYoutubePreview>
      <OptionalPreviews>
        <div className="optional-prev-wrapper">

        <img
            className="optional-prev-image"
            src={youTicon}
            alt="youtube logo"
          />
          <H2>Youtube Preview</H2>
        </div>
      </OptionalPreviews>
      <YoutubeTopSection>
        <CreativeContainer isThumbnail={true}>
          <p className="visit_advertiser">Visit Advertiser</p>
          <img
            src={getPreviewImageUrl({preview, youTubeId})}
            alt="fb-img"
          />
        </CreativeContainer>
        <Footer>
          <Top>
            <AdInfo>
             <AdSquare> <Small style={{color: "white"}}>Ad</Small>    </AdSquare>
              <AdText>
                <Small colorInherit semibold>{previewText?.youtubeHeadline}</Small>
                <Small colorInherit >{previewText?.youtubeDescription}</Small>
              </AdText>
          
            </AdInfo>
            <DotsWrapper>
                <HiOutlineDotsVertical color="#ACB0BD" size={'27px'}/>
              </DotsWrapper>
          </Top>
          <Button>
            {StringUtil.displaySnakeStr(cta || defaultCta)}
          </Button>

        </Footer>
      </YoutubeTopSection>
    </StyledYoutubePreview>
  );
};

export default YouTubePreview;

const Footer = styled.div`
  min-height:120px;
  padding: 0.4rem;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AdInfo = styled.div`
   display: flex;
   gap: 1rem;
`

const AdSquare = styled.div`
      min-width: 32px;
      height: 24px;
      border-radius: 4px;
      background-color: #256DFE;
      color: white;
      display: grid;
      place-content: center;
`

const AdText = styled.div`
  line-height: 1;
  display: grid;
  gap: 5px;

`
const Button = styled.div`
  margin-top: 10px;
  background-color: #0760D4;
  width: 280px;
  height: 40px;
  display: grid;
  place-content: center;
  margin: 15px auto;
  color: white;
  border-radius: 41px;
`

const DotsWrapper = styled.div`
  min-width: 16px;
  align-self: flex-start;
`