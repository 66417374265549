import { LOCATION_CONNECTIONS } from "../../../../../../../features/connections/constants";
import { CONNECTION_STATUS_COPY, getLocationConnections } from "../../../../../../../features/locations/util";
import columnBuilder from "../../../columnBuilder";


const xReportCategory = columnBuilder()
	.category('platform-connections')
	.subCategory('X')
	.makeHeader((meta) => {
		return `X ${meta.name}`
	})

const xColumns = [
	xReportCategory
		.id('x-connection-status')
		.name('Connection Status')
		.makeRow((data) => {
			const connections = getLocationConnections(data.input.location)
			return CONNECTION_STATUS_COPY[connections[LOCATION_CONNECTIONS.TWITTER]]
		}),
	xReportCategory
		.id('x-connection-status-landing-page')
		.name('Landing Page')
		.makeRow((data) => {
			if (!data.input.location.twitterHandle) return

			return `https://x.com/${data.input.location.twitterHandle}`
		}),
]

export const xReportColumns = xColumns