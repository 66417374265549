import React from 'react'
import Modal from '../../../components/modal/Modal';
import { OutlineBtn } from '../../../styles/buttons';
import { Body, Span } from '../../../styles/typography';
import { ListModalWrapper } from './styles';

const SearchTextModal = ({creative, onClose}) => {
  const searchHeadlines = creative.searchHeadlines;
  const searchDescriptions = creative.searchDescriptions;

// probably can define anchor styles within the typography for when we use them within Body tags, etc
  return (
    <Modal width='800px' title={"Your Ad's Headlines and Descriptions"}>
      <ListModalWrapper>
        <Body underline color="accentColor2" onClick={() => window.open('https://support.google.com/google-ads/answer/7684791?hl=en', '_blank')} style={{marginBottom: '10px'}}>Learn More about Google Responsive Search Ads</Body>
        <div className='item-list-container'>
        <Body>
            <ul style={{listStyle: 'none'}}>
              {
                searchHeadlines && searchHeadlines.map((item, idx) => {
                  return (
                    <li key={idx}>
                        <Span bold>Headline {idx + 1}:</Span> {item}
                    </li>
                  )
                })
              }
              {
                searchDescriptions && searchDescriptions.map((item, idx) => {
                  return (
                    <li key={idx}>
                        <Span bold>Description {idx + 1}:</Span> {item}
                    </li>
                  )
                })
              }
            </ul>
          </Body>
        </div>
        <OutlineBtn onClick={onClose}>
            Okay
        </OutlineBtn>
        </ListModalWrapper>
    </Modal>
  )
}

export default SearchTextModal