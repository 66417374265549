import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ExperimentName, EXPERIMENTS } from '../../helpers/experimentHelpers';

/*
    This hook allows for clearer use of experiments in the FCC

    It is called in a component by passing in the experiment name, imported from experimentHelpers

    Example Use Case:
    const Component = (props) => {
        const sampleExperiment = useExperiment(EXPERIMENTS.SAMPLE);

        if(!sampleExperiment.active) {
            return null
        }

        const foo = () => {
            ...
            sampleExperiment.ifActive(() => {
                toast.msg("The experiment is active")
            })
            ...
        }

        return <div onClick={foo}>This is some copy, and I'll print {sampleExperiment.decide('A', 'B')} inside</div>
    }

    By naming the return value sampleExperiment instead of destructuring it into { active, decide } we make
    it easier to add new experiments in the future. Each reference to active or decide is tied to the name
    of the experiment itself.

    {
        active: whether or not the experiment is turned on
        decide: given two params a and b, return a if experiment is active, b if experiment is not active
        ifActive: takes param v which can be a function or a value, will return null if not active or will call v and return value or just return b,
        ifNotActive: same as ifActive, just if not active
    }
*/

export const isFccExperimentName = (experiment: string): experiment is ExperimentName => {
    return Object.values(EXPERIMENTS).includes(experiment as ExperimentName);
}

export type Experiment = {
    active: boolean
    decide: <T, J>(a: T, b: J) => T | J
    ifActive: <T>(a: T) => T | undefined
    ifNotActive: <T>(a: T) => T | undefined
}

export const makeExperimentObject = (experiment: ExperimentName, userExperiments?: ExperimentName[]): Experiment => {

    const hasExperiment = !!userExperiments && userExperiments.includes(experiment);

    return {
        active: hasExperiment,
        decide: <T, J>(a: T, b: J) => hasExperiment ? a : b,
        ifActive: <T,>(a: T) => {
            if (!hasExperiment) return

            if (typeof a === 'function') {
                return a()
            }
            return a
        },
        ifNotActive: <T,>(a: T) => {
            if (hasExperiment) return

            if (typeof a === 'function') {
                return a()
            }
            return a
        },
    }
}

export const useExperiment = (experiment: ExperimentName): Experiment => {

    const userExperiments = useSelector((state) => state.user?.experiments);

    const actions = useMemo(() => {
        return makeExperimentObject(experiment, userExperiments);
    }, [experiment, userExperiments]);

    return actions;
};

export default useExperiment;