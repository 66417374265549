import React from 'react'

import { StyledCreativeStatusToggle, CreativeStatusToggleCircle } from './styles';

const CreativeStatusToggle = ({ size, isOn, handleToggle }) => {
    return (
        <StyledCreativeStatusToggle size={size} isOn={isOn} onClick={handleToggle}>
            <CreativeStatusToggleCircle size={size} isOn={isOn} />
        </StyledCreativeStatusToggle>
    )
}

export default CreativeStatusToggle
