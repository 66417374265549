import React, { useMemo } from 'react'
import useFilterUtilFunctions from '../advancedFilter/hooks/useFilterUtilFunctions'
import { ActiveFilterLabel } from '../../components/reusableUI/components/labelOption';
import { StyledActiveContainer } from './styles';

const ActiveFilters = ({ filters, setFilters, isAd }) => {
    const { selectedFiltersHandler } = useFilterUtilFunctions({ filters: filters, setFilters: setFilters, isLocalFilter: true });
    const isPost = !isAd && true;

    const activeFilters = useMemo(() => {
        return Object.entries(filters)
            // For each filter take the filterList (first map) of each category and create a SEPERATE object for each one (second map)
            .map(([category, filterList]) => filterList.map((filter) => ({ category, filter })))
            .flat();
    }, [filters]);

    return (
        <StyledActiveContainer>
            {
                activeFilters.map(activeFilter => {
                    const { category, filter } = activeFilter
                    return (
                        <ActiveFilterLabel key={filter} text={filter} onRemove={() => selectedFiltersHandler({filterCategory: category, filterLabel: filter})} isPost={isPost} isAd={isAd}/>
                    )
                })
            }
        </StyledActiveContainer>
    )
}

export default ActiveFilters