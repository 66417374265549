export const ENVIRONMENTS = {
    DEVELOPMENT: 'development',
    TEST: 'test',
    STAGING: 'staging',
    PRODUCTION: 'production'
} as const

export type Environment = typeof ENVIRONMENTS[keyof typeof ENVIRONMENTS]

export const getEnvironmentType = (): Environment => {
    const origin = window.location.origin;
    if (origin.indexOf('http://localhost') > -1) return 'development'
    if (origin.indexOf('eulerity-staging') > -1) return 'staging'
    if (origin.indexOf('eulerity-tester') > -1) return 'test'
    return 'production'
}