import React from "react";
import ReactDOM from "react-dom";

import { StyledOverlay, LoadingSpinner, OverlayContent } from './styles';

import { useSelector } from 'react-redux';
import spinner from '../../assets/images/spinner.gif'


const Overlay = () => {
  const overlayShowing = useSelector(state => state.overlay.overlayShowing)
  const message = useSelector(state => state.overlay.message)

  if (!overlayShowing) return null;

  return ReactDOM.createPortal(
    <StyledOverlay>
      <OverlayContent>
        <LoadingSpinner src={spinner} />
        {message}
      </OverlayContent>
    </StyledOverlay>,
    document.getElementById("overlay")
  );
}

export default Overlay