import React, { useState } from 'react';
import Confirm from '../../../components/confirm/Confirm';
import { toast } from 'react-toastify';
import useCreativeActions from '../../../hooks/useCreativeActions';
import { OutlineBtn } from '../../../styles/buttons';

const ModalApproval = ({ creative, onClose, refreshCreatives }) => {
    const [openModal, setOpenModal] = useState(false);
    const { reviewCreative } = useCreativeActions()

    return (
            <>
                <OutlineBtn onClick={() => setOpenModal(true)}>Approve</OutlineBtn>
                {openModal &&
                    <Confirm
                        header={`Accept ${creative.kind === 'Creative' ? 'Dynamic': creative.kind} Ad`}
                        onClose={() => setOpenModal(false)}
                        text='Are you sure you want to accept this ad? This cannot be undone.'
                        confirm={{
                            text: 'Approve',
                            callback: () => {
                                reviewCreative({
                                    creative,
                                    approved: true,
                                    reasonId: 'FRANCHISE',
                                    description: ''
                                }).then(() => {
                                    toast.success('Your creative is now approved')
                                    refreshCreatives?.();
                                    setOpenModal(false)
                                    onClose()
                                })
                            }
                        }}
                        cancel={{
                            text: 'Cancel',
                            callback: () => {
                                setOpenModal(false)
                            }
                        }}
                    />
                }
            </>
        )
}

export default ModalApproval;