const Regex = {
  PHONE: /^[()+\-*#\s]*(?:\d[()+\-.*#\s]*){7,40}$/,
  EMAIL: /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
  YOUTUBE_VIDEO_URL: /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/w\/|embed\/|shorts\/)|(?:(?:watch)?\/?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/,
  REPEATED_SPECIAL_CHARS: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2,}/,
  EMOJI_GLOBAL: /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
  EMOJI: /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/,
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
  // Instagram Handles only support letters, numbers, period, and underscore (1-30 characters)
  INSTAGRAM_HANDLE: /^[a-zA-Z0-9._]{1,30}$/,
  INSTAGRAM_HANDLE_INVALID_CHARACTERS: /[^a-zA-Z0-9._]/,
  NAME: /^[A-Za-zÀ-ÖØ-öø-ÿ]([A-Za-zÀ-ÖØ-öø-ÿ' -]*[A-Za-zÀ-ÖØ-öø-ÿ])?$/,
  ZIP_CODE: /^\d{5}(-\d{4})?$|^[A-Za-z0-9\s-]{3,10}$/
};

export default Regex;