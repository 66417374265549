import styled from "styled-components";

const handleColorType = props => {
    switch (props.colorScheme) {
        case 'ad':
            return props.theme.color.creativeColor1
        case 'post':
            return props.theme.color.creativeColor2
        default:
            return props.theme.color.accentColor2
    }
}

// *  Input and Filter Button Container
export const StyledSearchContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    height: 36px;

    svg {
        color: ${props => props.theme.color.textColor1};
    }

    // * Overide reusable search component props
    div input {
        width: 780px;
        height: 36px;
        border-radius: 9px;
        font-size: 18px;
        font-weight: 400;
    }
`


export const StyleOptionsContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    > div {
        width: 510px;
    }
`

export const StyledStepWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .confirm-message {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
    }
`

// * ACTIVE FILTER STYLES * //
export const StyledActiveContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 5px;

    ::-webkit-scrollbar {
        display: none;
    }
`

// * Container for list of location options
export const StyledLocationOptions = styled.div`
    border: 1px solid ${props => props.theme.color.greyAccent1 };
    border-radius: 9px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: 312px;
    padding: 16px;

    .options-list {
        overflow: scroll;
    }
`

export const StyledTabContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`

export const TabItem = styled.div`
    cursor: pointer;
    border-bottom: ${props => props.isSelected ? `2px solid ${handleColorType(props)}` : ''};
    font-weight: ${props => props.isSelected ? 'bold' : ''};
`

export const StyledQuickOptions = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .quick-opt-container {
        margin-right: auto;
    }
`

// * Single Option
export const StyledEntityOption = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.color.textColor1};
    background-color: ${props => props.theme.color.backgroundColor2};
    border: 1px solid ${props => props.colorScheme === 'ad' ? props.theme.color.creativeColor1 : props.colorScheme === 'post' ? props.theme.color.creativeColor2 : props.theme.color.accentColor3};
    border-radius: 4px;
    margin: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    opacity: ${props => props.isDisabled ? '0.4' : ''};

    .statusAndType {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .infoContainer {
        display: flex;
        gap: 0.25rem;
    }
`

// * OPTION STYLES * //
export const StyledContainerHeader = styled.div`
    p {
        margin-left: 5px;
    }
`