import { API } from "../../../../../constants/api"
import { getBaseUrl } from "../../../../../helpers"
import { MILLIS_PER_DAY } from "../../../../../helpers/constants"
import { getDaysInMonth } from "../../../../../helpers/date.util"
import { makeRequest } from "../../../../../helpers/make-request"
import { canManageFranchise, isMember } from "../../../../../helpers/permissionsHelper"

export const PAYMENT_OPTIONS = {
    NONE: '',
    TRADITIONAL_PAYMENT: 'Traditional Payment',
    CORPORATE_BALANCE: 'Corporate Balance'
}

export const SUBSCRIPTION_MANAGER = {
    CORPORATE: 'Corporate',
    BUSINESS_OWNER: 'Business Owner',
    NONE: '-'
}

export const SUBSCRIPTION_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const SCHEDULE_CHANGE_CASE = {
    PAUSE: -1,
    RESET_BILLING_CYCLE: -2,
}

export const usdToOtherCurrency = (usdAmount, conversionRate) => Math.ceil(usdAmount * conversionRate)
export const otherCurrencyToUsd = (otherCurrencyAmount, conversionRate) => otherCurrencyAmount / conversionRate

export const getSubscriptionManager = subscription => {
    const isCanceledSubscription = subscription?.status !== SUBSCRIPTION_STATUS.ACTIVE && subscription?.cancelDate
    if (isCanceledSubscription || !subscription) {
        return SUBSCRIPTION_MANAGER.NONE
    } else {
        return subscription?.managingUserKey ? SUBSCRIPTION_MANAGER.CORPORATE : SUBSCRIPTION_MANAGER.BUSINESS_OWNER
    }
}

export const checkCanCreateSubscription = async () => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `${API.SUBSCRIPTION.CHECK_CAN_CREATE}`,
            method: 'GET',
            retry: 3,
            callback: response => resolve(JSON.parse(response.canCreateSubscription)),
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const BILLING_CALC_OPTION = {
    THIS: 'this',
    NEXT: 'next'
}
export const calculateNextBillingCycleDate = (periodEnd, option) => {
    const calculateNextPeriodEnd = (currPeriodEnd) => {
        //0 days means last day of prev month
        let lastDayOfNextMonth = new Date(
            currPeriodEnd.getFullYear(),
            currPeriodEnd.getMonth() + 2,
            0,
            currPeriodEnd.getHours()
        );
        //Not enough days next month, so use last day of next month.
        if (lastDayOfNextMonth.getDate() <= currPeriodEnd.getDate()) {
            return lastDayOfNextMonth;
        } else {
            currPeriodEnd.setMonth(currPeriodEnd.getMonth() + 1);
            return currPeriodEnd;
        }
    };

    //!IMPLEMENTATION
    let billingPeriodEnd = new Date(periodEnd + ' GMT');

    let dateToSet = new Date(billingPeriodEnd.getTime() - MILLIS_PER_DAY); //no need to offset because we have the correct time by adding GMT above
    //if its next billing period then call calculateNextPeriodEnd,
    //if the user tries to schedule on the day of the period end
    //do it twice

    if (option === BILLING_CALC_OPTION.NEXT) {
        if (new Date().getTime() >= dateToSet.getTime()) {
            dateToSet = calculateNextPeriodEnd(dateToSet);
        }
        dateToSet = calculateNextPeriodEnd(dateToSet);
    }

    //if the user tries to update on the day of period end then set it to next month
    if (new Date().getTime() >= dateToSet.getTime()) {
        dateToSet = calculateNextPeriodEnd(dateToSet);
    }

    return dateToSet;
};

export const isDraftOrCanceledLocation = (location) => {
    const isDraft = !location?.subscriptions?.length
    const hasCanceledSubscription = location?.subscriptions?.length && location?.subscriptions[0].status === SUBSCRIPTION_STATUS.INACTIVE && Boolean(location?.subscriptions[0].cancelDate)
    return isDraft || hasCanceledSubscription
}

export const hasTraditionalPaymentMethod = subscription => {
    if (!subscription) return false
    return Boolean(subscription?.token)
}

export const isCorporateManagedSubscription = subscription => Boolean(subscription?.managingUserKey)

export const canManageSubscription = (accessLevel, location) => {
    if (!location.subscriptions.length) return true;
    if (canManageFranchise(accessLevel)) return true;
    // no managing user key means that the subscription is not corporate managed
    if (isMember(accessLevel) && !location.subscriptions[0].managingUserKey) return true;
    return false;
}

export const getNextPeriodEnd = (currentEndDate, targetChangeDate) => {
    if (currentEndDate.getDate() > getDaysInMonth(targetChangeDate)) {
        // the next month doesnt have enough days
        return new Date(targetChangeDate.getFullYear(), targetChangeDate.getMonth()+1, 0) // use the last day
    } else {
        let d = new Date(currentEndDate.getTime())
        while (d.getTime() < targetChangeDate.getTime()) {
            d.setMonth(d.getMonth()+1)
        }
        return d
    }
}

export const getCycleRemainingPercentage = (periodEndDate, changeDate = new Date()) => {
    let diffInMs = (periodEndDate == null) ? 0 : Math.max(0, periodEndDate.getTime() - changeDate.getTime());
    return diffInMs / (getDaysInMonth(changeDate) * MILLIS_PER_DAY)
}

// The actual maximum value that could be set on the budget selection slider
// because sometimes the max cannot be selected if the specified step wont allow it steps: [0,50,100,150] so a max value of 125 wouldn't be selectable
export const getMaxBasedOnStep = (min, max, interval) => {
    let possibleInputs = []
    for (let nextInput = min; nextInput <= max; nextInput+= interval){
        possibleInputs.push(nextInput)
    }
    return Math.max(...possibleInputs)
}

export const prepSubscription = async (location) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `${API.SUBSCRIPTION.PREP}?uid=${location.user.raw.name}&lid=${location.id}`,
            method: 'GET',
            retry: 3,
            callback: response => resolve(response),
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

// Promos
export const displayPromoBoost = promo => {
    if (!promo?.boost) return ''
    return `(+${promo?.boost * 100}% boost)`
}

export const getPromoGainMultiplier = promo => promo?.boost ? promo.boost + 1 : 1

//* Payment Sources
export const getPaymentSources = async ({uid}) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `${API.SUBSCRIPTION.GET_STRIPE_SOURCES}?uid=${uid}`,
            method: 'GET',
            retry: 3,
            callback: response => resolve(response),
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const getInitialPaymentTypeOption = (location, managedBy, exposeStripeBalance) => {
    //! ONLY traditional payment is supported for user-owned subscriptions
    // and franchises which do not have exposeStripeBalance enabled in Admin UI Franchise page
    if (managedBy === 'location' || !exposeStripeBalance) return PAYMENT_OPTIONS.TRADITIONAL_PAYMENT
    if (isDraftOrCanceledLocation(location)) {
        return PAYMENT_OPTIONS.NONE
    } else {
        return location.subscriptions?.[0]?.token ? PAYMENT_OPTIONS.TRADITIONAL_PAYMENT : PAYMENT_OPTIONS.CORPORATE_BALANCE
    }
}

// Waiting for subscription changes

export const waitForCreatedSubscription = ({ location, selectedSource }) => {
    return new Promise((resolve, reject) => {
        let refreshTries = 0;

        const checkForUpdatedSubscription = () => {
            setTimeout(() => {
                window.eulerity.makeApiCall(`${API.SUBSCRIPTION.GET}?uid=${location.user.raw.name}&lid=${location.id}`, 'GET', null, function(newSubscription){
                    console.log('Subscription Response', newSubscription)
                    // Create Or Update Happened
                    const createHappened = newSubscription.status === 'ACTIVE';
                    const selectedBankAccount = selectedSource?.type === 'bankaccount';
                    const declineHappened = newSubscription.declineDate && location.subscriptions?.[0]?.declineDate !== newSubscription.declineDate
                    const triedManyTimes = refreshTries >= 10;

                    if(triedManyTimes) {
                        return reject();
                    } else if (createHappened || selectedBankAccount || declineHappened) {
                        return resolve(newSubscription)
                    } else {
                        refreshTries++
                        checkForUpdatedSubscription()
                    }
                })
            }, 2000)
        }

        checkForUpdatedSubscription();
    })
}

export const waitForUpdatedSubscription = ({ location, selectedSource }) => {
    const currentSubscription = location?.subscriptions?.[0]
    return new Promise((resolve, reject) => {
        let refreshTries = 0;

        const checkForUpdatedSubscription = () => {
            setTimeout(() => {
                window.eulerity.makeApiCall(`${API.SUBSCRIPTION.GET}?uid=${location.user.raw.name}&lid=${location.id}`, 'GET', null, function(newSubscription){
                    console.log('Subscription Response', newSubscription)
                    // Create Or Update Happened
                    const selectedBankAccount = selectedSource?.type === 'bankaccount';
                    const activationHappened = newSubscription.status !== currentSubscription.status && newSubscription.status === 'ACTIVE'
                    const budgetChangeHappened = currentSubscription?.status === 'ACTIVE' && currentSubscription?.chargedMonthlyUsd !== newSubscription?.chargedMonthlyUsd
                    const sourceChangeHappened = currentSubscription?.status === 'ACTIVE' && currentSubscription?.token !== newSubscription?.token
                    const promoChangeHappened = currentSubscription?.status === 'ACTIVE' && currentSubscription?.promo !== newSubscription?.promo
                    const declineHappened = newSubscription.declineDate && location.subscriptions?.[0]?.declineDate !== newSubscription.declineDate
                    const triedManyTimes = refreshTries >= 10;

                    if(triedManyTimes) {
                        return reject();
                    } else if (activationHappened || selectedBankAccount || budgetChangeHappened || sourceChangeHappened || promoChangeHappened || declineHappened) {
                        return resolve(newSubscription)
                    } else {
                        refreshTries++
                        checkForUpdatedSubscription()
                    }
                })
            }, 2000)
        }

        checkForUpdatedSubscription();
    })
}

export const addPaymentSource = (uid) => {
    return new Promise(async (resolve, reject) => {
        const oldPaymentSources = await getPaymentSources({ uid });

        const onReturnToPage = async () => {
            window.removeEventListener('focus', onReturnToPage);

            const newPaymentSources = await getPaymentSources({
                uid,
            });

            const newPaymentSource = newPaymentSources.find(
                (s) => !oldPaymentSources.find((os) => os.id === s.id)
            );

            if (newPaymentSource) {
                resolve(newPaymentSource);
            } else {
                reject();
            }
        };

        const createCheckoutSessionRequest = makeRequest()
            .url(API.SUBSCRIPTION.CREATE_CHECKOUT_SESSION)
            .param('uid', uid);

        const { stripePublicKey, sessionId } = await createCheckoutSessionRequest.get();

        const urlParams = new URLSearchParams();
        urlParams.append('sessionId', sessionId);
        urlParams.append('publicKey', stripePublicKey);

        window.addEventListener('focus', onReturnToPage);

        window.open(`${getBaseUrl()}/stripe-redirect.html?${urlParams.toString()}`, '_blank');
    });
};