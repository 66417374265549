import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { POST } from '../../../../../constants/creative'
import { PATH } from '../../../../../constants/urlPath'
import { hexToRgba } from '../../../../../helpers/colors.util'
import { getPostFailureDetailCopy, getPostFailureDetail } from '../../../../../pages/locations/Reports/SocialPostingPerformance/functions/getToolTipText'
import { XS } from '../../../../../styles/typography'
import { Network, Post, PostWithMetadata } from '../../../creative.types'
import { useCreativeMetadata } from '../../../hooks/useCreativeMetadata'
import useSubscriptionAccess from '../../../../subscription/hooks/useSubscriptionAccess'

const NetworkStatusItemDetailContainer = styled.div`
  padding: 0.75rem 1rem;
  width: 100%;

  gap: 0.5rem;

  border: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
  border-radius: 0.3125rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NetworkStatusItemDetailActionText = (props: {
	post: PostWithMetadata,
	network: Network,
}) => {
	const networkStatus = props.post.postedNetworks?.[props.network]
	const history = useHistory()
	const { goToLocationSubscriptionSetting } = useSubscriptionAccess({
		createSubscriptionRedirect: PATH.SOCIAL_POSTING,
		updateSubscriptionRedirect: `${PATH.SOCIAL_POSTING}/settings/subscription`
	})

	const detail = getPostFailureDetail(props.post, props.network, networkStatus?.reason)

	if (!detail.reason) return null

	if (!detail.isPersistent) {
        return null
	}

	if (detail.reason === POST.REASONS.PAGE_ID) {
		return (
			<Link to={`${PATH.SOCIAL_POSTING}/settings/connections`}>
				<XS color="accentColor2">Connect Page</XS>
			</Link>
		)
	}

	if (detail.reason === POST.REASONS.ACCOUNT) {
		return (
			<Link to={`${PATH.SOCIAL_POSTING}/settings/connections`}>
				<XS color="accentColor2">Connect Account</XS>
			</Link>
		)
	}

	if (detail.reason === POST.REASONS.ACCESS) {
		return (
			<Link to={`${PATH.SOCIAL_POSTING}/settings/connections`}>
				<XS color="accentColor2">Grant Access</XS>
			</Link>
		)
	}

    if (detail.reason === POST.REASONS.SUBSCRIPTION) {
		return (
			<XS color="accentColor2" onClick={() => {
				if (props.post.metadata.location) {
					goToLocationSubscriptionSetting(props.post.metadata.location)
				} else {
					history.push(PATH.SOCIAL_POSTING)
				}
			}}>Subscribe Campaign</XS>
		)
	}

	return null
}

export const NetworkStatusItemDetail = (props: {
	post: Post
	network: Network
	setIsDetailExpanded: (value: boolean) => void
}) => {
	const metadata = useCreativeMetadata(props.post)
	const { postedNetworks } = props.post
	const networkStatus = postedNetworks?.[props.network]

	const postFailureDetail = getPostFailureDetail({...props.post, metadata}, props.network, networkStatus?.reason)

	return (
		<NetworkStatusItemDetailContainer>
			<FiAlertCircle size={16} />
			<XS style={{
				marginRight: 'auto',
			}}>
				{getPostFailureDetailCopy(postFailureDetail)}
			</XS>
			<NetworkStatusItemDetailActionText
				post={{ ...props.post, metadata }}
				network={props.network}
			/>
		</NetworkStatusItemDetailContainer>
	)
}
