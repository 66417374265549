//* CenterElement is a reusable component that centers the element base on the parent width and height or custom


export const CenterElement = ({style = {},children}) => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: "grid",
                placeItems: "center",
                ...style}}
        >
            {children}
        </div>
    )
}