import React from 'react'
import { XS } from '../../styles/typography'
import { COLORS } from '../../constants/colors'
import { StyledSearchOnlyTableCard } from './styles'
import StringUtil from '../../helpers/string.util'

const SearchOnlyTableCard = ({ creative }) => {
    const { searchHeadlinePreview, searchDescriptionPreview } = creative

    return (
        <StyledSearchOnlyTableCard>
            <XS style={{ color: COLORS.SEARCH_HEADLINE }} bold>{StringUtil.truncateString(searchHeadlinePreview, 16)}</XS>
            <XS style={{ color: COLORS.SEARCH_DESC }}>{StringUtil.truncateString(searchDescriptionPreview, 16)}</XS>
        </StyledSearchOnlyTableCard>
    )
}

export default SearchOnlyTableCard