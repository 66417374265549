import styled, { css } from 'styled-components'
import { hexToRgb, hexToRgba } from '../helpers/colors.util'
import { H3 } from './typography'

// * Function will apply dynamic box shadow with accentColor theme
export const createBoxShadow = () => {
    return css`
        ${(props) => {
            return `0px 4px 24px rgba(${hexToRgb(
                props.theme.color.accentColor3
            )}, 0.2);`
        }}
    `
}

export const Card = styled.div<{
    customStyle?: string
    width?: string
}>`
    background: ${(props) => props.theme.color.backgroundColor2};
    box-shadow: ${createBoxShadow};
    border-radius: 9px;
    ${(props) => {
        if (props.width) {
            return css`
                width: ${props.width};
            `
        }
    }}
    //Custom Styles
    ${(props) => {
        switch (props.customStyle) {
            case 'emailTemplates': {
                return css`
                    box-shadow: none;
                `
            }
            case 'insightsCard': {
                return css`
                    padding: 3rem;
                    width: 70rem;
                `
            }
            default:
                return
        }
    }}
`

export const CardHeader = styled(H3)`
    display: block;
    color: ${(props) => hexToRgba(props.theme.color.textColor1, 0.6)};
    line-height: 19px;
`
