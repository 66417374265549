import { createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.color.textColor1};
    transition: all 0.50s linear;
  }
  a {
    text-decoration: none;
    color:${({ theme }) => theme.color.accentColor2};
  }
`