type AlertName = "INFO" | "WARNING" | "SUCCESS" | "ALERT" | "LEARN_MORE"

type AlertType = {
    TYPE : string
}

export const ALERT : Record<AlertName, AlertType> = {
    INFO: {
        TYPE: 'INFO'
    },
    WARNING: {
        TYPE: 'WARNING'
    },
    SUCCESS: {
        TYPE: 'SUCCESS'
    },
    ALERT: {
        TYPE: 'ALERT'
    },
    LEARN_MORE: {
        TYPE: 'LEARN_MORE'
    }
}