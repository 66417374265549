import React, { useMemo } from 'react'
import { Body, Small } from '../../styles/typography'
import EntityOption from './EntityOption'
import { StyledContainerHeader, StyledLocationOptions, StyledQuickOptions, StyledTabContainer, TabItem } from './styles'
import { TAB_TYPES, configureTabPrep } from './filters.utils'

const SearchOptions = ({ filteredOptions, selectedOptions, resolveSelect, resolveSelectAll, resolveDeselectAll,searchTerm, isAd, colorScheme, tabsToDisplay, selectedTab, setSelectedTab }) => {
    const optionsToDisplay = useMemo(() => {
        const options = []

        filteredOptions.forEach((opt) => {
            if (!searchTerm.length) {
                return options.push(opt)
            }

            if (opt.displayName.toLowerCase().includes(searchTerm)) {
                return options.push(opt)
            }
        })

        return configureTabPrep(options)

    }, [filteredOptions, searchTerm])

    const selectedOptionsToDisplay = useMemo(() => {
        return configureTabPrep(selectedOptions)
    }, [selectedOptions])

    // * We want to hide "Select All" if all the options shown are already selected
    const shouldHideSelectAll = useMemo(() => {
        return optionsToDisplay[selectedTab].options.every(option => selectedOptions.some(sOption => sOption?.id === option?.id))
    }, [optionsToDisplay, selectedOptions, selectedTab])

    const shouldShowDeselectAll = useMemo(() => {
        return selectedOptionsToDisplay[selectedTab]?.options?.length > 0
    }, [selectedTab, selectedOptionsToDisplay])


    return (
        <div>
            <StyledContainerHeader>
                <Body semibold>Results</Body>
            </StyledContainerHeader>
            <StyledLocationOptions selectedOptions={selectedOptions}>
                <StyledTabContainer>
                    {
                        tabsToDisplay?.hasCampaigns &&
                        <TabItem onClick={() => setSelectedTab(TAB_TYPES.CAMPAIGNS)} colorScheme={colorScheme} isSelected={TAB_TYPES.CAMPAIGNS === selectedTab}>
                            <Body>Campaigns ({optionsToDisplay[TAB_TYPES.CAMPAIGNS]?.optionsLength})</Body>
                        </TabItem>
                    }
                    {
                        tabsToDisplay?.hasFolders &&
                        <TabItem className='tab-item' onClick={() => setSelectedTab(TAB_TYPES.FOLDERS)} colorScheme={colorScheme} isSelected={TAB_TYPES.FOLDERS === selectedTab}>
                            <Body>Folders ({optionsToDisplay[TAB_TYPES.FOLDERS]?.optionsLength})</Body>
                        </TabItem>
                    }
                    {
                        tabsToDisplay?.hasBlueprints &&
                        <TabItem className='tab-item' onClick={() => setSelectedTab(TAB_TYPES.BLUEPRINTS)} colorScheme={colorScheme} isSelected={TAB_TYPES.BLUEPRINTS === selectedTab}>
                            <Body>Blueprints ({optionsToDisplay[TAB_TYPES.BLUEPRINTS]?.optionsLength})</Body>
                        </TabItem>
                    }
                </StyledTabContainer>
                {
                    optionsToDisplay[selectedTab]?.options?.length > 0 &&
                    <StyledQuickOptions>
                        <div className='quick-opt-container' style={{display: 'flex', gap:'16px'}}>
                            {
                                !shouldHideSelectAll &&
                                <Small color={colorScheme === "ad" ? 'creativeColor1' : colorScheme === "post" ? "creativeColor2" : 'accentColor2'} onClick={() => resolveSelectAll(optionsToDisplay[selectedTab]?.options)}>Select All</Small>
                            }
                            {
                                shouldShowDeselectAll &&
                                <Small color={colorScheme === "ad" ? 'creativeColor1' : colorScheme === "post" ? "creativeColor2" : 'accentColor2'} onClick={() => resolveDeselectAll(optionsToDisplay[selectedTab]?.options)}>Deselect All</Small>
                            }
                        </div>
                    </StyledQuickOptions>
                }
                <div className='options-list'>
                    {
                        optionsToDisplay[selectedTab]?.options.map(option => {
                            const isDisabled = selectedOptions.some((opt => opt.id === option.id));

                            return (
                                <EntityOption
                                    onSelectOption={resolveSelect}
                                    option={option} // * Entire option object
                                    key={option.value}
                                    isDisabled={isDisabled}
                                    isAd={isAd}
                                    colorScheme={colorScheme}
                                />)
                        })
                    }
                </div>
            </StyledLocationOptions>
        </div>
    )
}

export default SearchOptions
