import React from 'react';
import { StyledStepLocations } from '../styles';
import { Body, H2 } from '../../../../styles/typography';
import { BsFacebook, BsGoogle, BsLinkedin, BsTwitterX } from 'react-icons/bs';
import { FiInstagram } from 'react-icons/fi';
import { CustomCheckbox } from '../../../../components/checkbox/styles';
import { StyledOptionItem, StyledOptionList, StyleOptionLabel } from '../../../../styles/options';
import { CREATIVE_KIND } from '../../../../helpers/creative.util';

export const IMAGE_POST_NETWORKS = ["facebook", "twitter", "linkedin", "instagram", "gmb"]
export const VIDEO_POST_NETWORKS = ["facebook", "twitter", "linkedin", "instagram"]

const CreativeStepNetworks = ({context}) => {
    const { formData, updateFormData } = React.useContext(context)

    const availableNetworks = formData.kind === CREATIVE_KIND.POST ? IMAGE_POST_NETWORKS : VIDEO_POST_NETWORKS

    const isSelected = networkName => {
        if (!formData?.targetNetworks?.length) return false;
        return formData?.targetNetworks.includes(networkName)
    }

    const handleToggleNetwork = e => {
        const network = e.target.value
        const isNetworkSelected = formData?.targetNetworks?.includes(network)
        if (isNetworkSelected) {
            if (network === 'instagram') updateFormData('instagramCollaborators', []);
            updateFormData('targetNetworks', formData?.targetNetworks?.filter(n => n !== network))
        } else {
            updateFormData('targetNetworks', [...formData?.targetNetworks, network])
        }
    }

    return (
        <StyledStepLocations>
            <H2 regular>Select Networks</H2>
            <Body>These are the network(s) for which the post will run.</Body>

            <StyledOptionList>
                {
                    availableNetworks.includes('facebook') &&
                    <StyledOptionItem selected={isSelected('facebook')}>
                        <CustomCheckbox>
                            <input
                                type="checkbox"
                                name="targetNetworks"
                                value="facebook"
                                checked={isSelected('facebook')}
                                onChange={handleToggleNetwork}
                                id="facebookNetwork"
                            />
                            <label htmlFor="facebookNetwork" />
                        </CustomCheckbox>
                        <StyleOptionLabel>
                            <BsFacebook size={30}/>
                            <Body>Facebook</Body>
                        </StyleOptionLabel>
                    </StyledOptionItem>
                }

                {
                    availableNetworks.includes('twitter') &&
                    <StyledOptionItem selected={isSelected('twitter')}>
                        <CustomCheckbox>
                            <input
                                type="checkbox"
                                name="targetNetworks"
                                value="twitter"
                                checked={isSelected('twitter')}
                                onChange={handleToggleNetwork}
                                id="twitterNetwork"
                            />
                            <label htmlFor="twitterNetwork" />
                        </CustomCheckbox>
                        <StyleOptionLabel>
                            <BsTwitterX size={30}/>
                            <Body>X</Body>
                        </StyleOptionLabel>
                    </StyledOptionItem>
                }

                {
                    availableNetworks.includes('linkedin') &&
                    <StyledOptionItem selected={isSelected('linkedin')}>
                        <CustomCheckbox>
                            <input
                                type="checkbox"
                                name="targetNetworks"
                                value="linkedin"
                                checked={isSelected('linkedin')}
                                onChange={handleToggleNetwork}
                                id="linkedInNetwork"
                            />
                            <label htmlFor="linkedInNetwork" />
                        </CustomCheckbox>
                        <StyleOptionLabel>
                            <BsLinkedin size={30}/>
                            <Body>LinkedIn</Body>
                        </StyleOptionLabel>
                    </StyledOptionItem>
                }

                {
                    availableNetworks.includes('instagram') &&
                    <StyledOptionItem selected={isSelected('instagram')}>
                        <CustomCheckbox>
                            <input
                                type="checkbox"
                                name="targetNetworks"
                                value="instagram"
                                checked={isSelected('instagram')}
                                onChange={handleToggleNetwork}
                                id="instagramNetwork"
                            />
                            <label htmlFor="instagramNetwork" />
                        </CustomCheckbox>
                        <StyleOptionLabel>
                            <FiInstagram size={30}/>
                            <Body>Instagram</Body>
                        </StyleOptionLabel>
                    </StyledOptionItem>
                }

                {
                    availableNetworks.includes('gmb') &&
                    <StyledOptionItem selected={isSelected('gmb')}>
                        <CustomCheckbox>
                            <input
                                type="checkbox"
                                name="targetNetworks"
                                value="gmb"
                                checked={isSelected('gmb')}
                                onChange={handleToggleNetwork}
                                id="googleNetwork"
                            />
                            <label htmlFor="googleNetwork" />
                        </CustomCheckbox>
                        <StyleOptionLabel>
                            <BsGoogle size={30} />
                            <Body>Google Business Profile</Body>
                        </StyleOptionLabel>
                    </StyledOptionItem>
                }

            </StyledOptionList>
        </StyledStepLocations>
    )
}

export default CreativeStepNetworks;