import columnBuilder from "../../../columnBuilder";
import { reportLoader } from "../../../loaders";
import { getDayReport, numberAggregator } from "../shared";

const facebookLeadsCategory = columnBuilder()
	.category('performance')
	.subCategory('Facebook Leads')
	.loadRow(reportLoader)

const facebookLeadsColumns = [
	facebookLeadsCategory
		.id('facebook-leads-total')
		.name('Total')
		.makeHeader((meta) => {
			return `Facebook Leads: ${meta.name}`
		})
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			if (!targetDate) return

			return targetDate.leads
		})
		.aggregateRow(numberAggregator)
		.toString(value => {
			if (value === undefined) return "N/A"
			return value.toString()
		})
]

export default facebookLeadsColumns