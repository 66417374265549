import React from 'react'
import { DisclaimerContainer, MetricSummary } from '../styles'
import { BigBody, Small } from '../../../../styles/typography'
import { compareDates, fillCreativeReports, getCreativeReportData } from '../../../../helpers/index'
import { useCreativeContext } from '../CreativeModalV2'
import { OBJECTIVE } from '../../../../helpers/objectives'
import { CREATIVE_STATUS } from '../../../../helpers/creative.util'
import { BsHourglassBottom } from 'react-icons/bs'
import { GoAlert } from 'react-icons/go'
import AdMetricsTable from './AdMetricsTable'

const AdMetrics = ({ creativeReports }) => {
    const { creative, metadata } = useCreativeContext()
    const { location } = metadata

    const reportData = fillCreativeReports(getCreativeReportData(creativeReports, creative).sort(compareDates))
    const { ads7Days, ads30Days, ads90Days, clicks7Days, clicks30Days, clicks90Days, CTR7Days, CTR30Days, CTR90Days, leads7Days, leads30Days, leads90Days } = reportData;
    const { status } = creative

    const adMetricsData = [
        {
            name: "Ads",
            toolTip: "Ads represent the number of times your ad is shown on someone’s screen.",
            weekValue: ads7Days || 0,
            monthValue: ads30Days || 0,
            quarterValue: ads90Days || 0
        },
        {
            name: "Clicks",
            toolTip: "Clicks represent the number of times someone clicked on your ad.",
            weekValue: clicks7Days || 0,
            monthValue: clicks30Days || 0,
            quarterValue: clicks90Days || 0
        },
        {
            name: "CTR",
            toolTip: "CTR represents the number of clicks that your ad receives divided by the number of times your ad is shown.",
            weekValue: CTR7Days || 0,
            monthValue: CTR30Days || 0,
            quarterValue: CTR90Days || 0,
            isPercent: true
        },
        {
            name: "Leads",
            toolTip: "Leads represent the number of times someone completed filling the lead form from this ad.",
            weekValue: leads7Days || 0,
            monthValue: leads30Days || 0,
            quarterValue: leads90Days || 0,
            shouldHide: location?.objective !== OBJECTIVE.LEADS.VALUE
        }
    ].filter(metric => !metric.shouldHide)

    const renderMetrics = () => {
        if (status === CREATIVE_STATUS.ACTIVE || status === CREATIVE_STATUS.PAUSED) return <AdMetricsTable adMetricsData={adMetricsData} />
        if (status === CREATIVE_STATUS.UNREVIEWED) return (
            <DisclaimerContainer>
                <BsHourglassBottom size={30} />
                <Small>Your ad is not yet active, when data is available for your ad it will be displayed here.</Small>
            </DisclaimerContainer>
        )
        if (status === CREATIVE_STATUS.REJECTED) return (
            <DisclaimerContainer>
                <GoAlert size={30} />
                <Small>
                    Your ad has been rejected because your text or image contains inappropriate content. <br />
                    Please change your image or header/description text to something more appropriate.
                </Small>
            </DisclaimerContainer>
        )
    }

    return (
        <MetricSummary>
            <BigBody semibold>Metrics Summary</BigBody>
            {
                renderMetrics()
            }
        </MetricSummary>
    )
}

export default AdMetrics