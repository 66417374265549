import { gmtDate } from "../../../../../../../helpers/date.util";
import { SUBSCRIPTION_MANAGER } from "../../../../../../locations/modals/settings/subscription/subscription.util";
import columnBuilder from "../../../columnBuilder";

const subscriptionReportCategory = columnBuilder()
	.category('business-and-campaigns-settings')
	.subCategory('Subscription')

const subscriptionColumns = [
	subscriptionReportCategory
		.id('paid-by')
		.name('Paid By')
		.makeRow((data) => {
			const subscription = data.input.location.subscriptions.find(sub => !!sub)

			if (!subscription) {
                return SUBSCRIPTION_MANAGER.NONE
            }

            if (subscription.managingUserKey) {
                return SUBSCRIPTION_MANAGER.CORPORATE
            }

            return SUBSCRIPTION_MANAGER.BUSINESS_OWNER
		}),
	subscriptionReportCategory
		.id('subscription-activation-date')
		.name('Subscription Activation Date')
		.makeRow((data) => {
			const subscription = data.input.location.subscriptions.find(sub => !!sub)

			if (!subscription) {
				return 'N/A'
			}

			return gmtDate(subscription.created)
		}),
    subscriptionReportCategory
        .id('subscription-amount')
        .name('Subscription Amount')
        .makeRow((data) => {
            const subscription = data.input.location.subscriptions.find(sub => !!sub)

            if (!subscription) {
                return 'N/A'
            }

            return new Intl.NumberFormat('en-US', {
                style: "currency",
                currency: subscription.currency,
            }).format(subscription.monthlySpend)
        }),
	subscriptionReportCategory
		.id('subscription-renewal-date')
		.name('Subscription Renewal Date')
		.makeRow((data) => {
			const subscription = data.input.location.subscriptions.find(sub => !!sub)

			if (!subscription || subscription.status !== "ACTIVE") {
				return 'N/A'
			}

			return gmtDate(subscription.periodEnd)
		}),
	subscriptionReportCategory
		.id('subscription-status')
		.name('Subscription Status')
		.makeRow((data) => {
			const subscription = data.input.location.subscriptions.find(sub => !!sub)

			if (!subscription) {
				return 'N/A'
			}

			return subscription.state
		})
]

export default subscriptionColumns