import React, { useRef } from 'react'
import _ from 'lodash';
import { MILLIS_PER_HOUR } from '../../helpers/constants';
// * This component simply serves as an app wide wrapper to enable idle tracking

const IdleWrapper = (props) => {
    const { setIsIdle, isIdle } = props;
    const idleTimer = useRef(null)

    const handleIdleTimer = () => {
        // * Reset timer (upon mouse move)
        clearTimeout(idleTimer.current)

        // * If the user is already idle - stop function call and return
        if (isIdle) return

        // * Begin timer for handling idle user
        idleTimer.current = setTimeout(() => {
            setIsIdle(true)
        }, MILLIS_PER_HOUR * 4) // * This value represents how long it takes for the modal to pop up if the user is idle
    }

    return (
        <div id='idleWrapper' onMouseMove={_.throttle(handleIdleTimer, 5000)}>
            {props.children}
        </div>
    )
}

export default IdleWrapper