// https://usehooks.com/useEventListener/

import { useEffect, useRef } from 'react';

const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        if (!element || !handler) return;

        const listener = (e) => savedHandler.current(e);

        element.addEventListener(eventName, listener);

        return () => {
            element.removeEventListener(eventName, listener);
        };
    }, [eventName, handler, element]);
};

export default useEventListener;
