import React, { useState } from 'react'
import { CategoryItem, CategoryOptionsWrapper, CounterContainer, FilterContainer, FilterOption, FilterOptionContainer, QuickActionsContainer, SearchContainer } from './styles'
import { FaChevronLeft, FaChevronRight, FaSearch } from 'react-icons/fa'
import { Body, XS } from '../../../styles/typography'
import useAdvancedFilter from '../hooks/useAdvancedFilter'
import InputWithIcon from '../../../components/reusableUI/components/inputWithIcon'


const AdvancedFilterV2 = ({ categories, selectedFilters, setSelectedFilters, colorScheme }) => {
    const {
        selectedCategory,
        filteredOptions,
        allOptionsSelected,
        someOptionsSelected,
        handleSelectCategory,
        countSelectedOptions,
        handleSelectOption,
        isChecked,
        handleUpdateQuery,
        handleSelectAll,
        handleDeSelectAll
    } = useAdvancedFilter({selectedFilters, setSelectedFilters, categories})

    // * Navigation
    const [categoryPage, setCategoryPage] = useState(1);
    const categoriesPerPage = 4;
    const startingIndex = (categoryPage - 1) * categoriesPerPage;
    const endingIndex = startingIndex + categoriesPerPage
    const renderCategories = categories.slice(startingIndex, endingIndex)
    const isFirstPage = categoryPage === 1
    const isLastPage = endingIndex >= categories?.length

    const handleNext = () => setCategoryPage(prev => prev + 1)
    const handlePrev = () => setCategoryPage(prev => prev - 1)

    return (
        <FilterContainer onClick={e => e.stopPropagation()}>
            <div className='filter-content'>
                <div className='category-wrapper'>
                    <div className='chevron-container'>
                        {
                            !isFirstPage && <FaChevronLeft onClick={() => handlePrev()}/>

                        }
                    </div>
                    <CategoryOptionsWrapper>
                        {
                            renderCategories.map((item, index) => {
                                return (
                                    <CategoryItem isSelected={selectedCategory === item.categoryName} onClick={() => handleSelectCategory(item)} key={index} colorScheme={colorScheme}>
                                        <div className='category-text'>{item.categoryName.toUpperCase()}</div>
                                    </CategoryItem>
                                )
                            })
                        }
                    </CategoryOptionsWrapper>
                    <div className='chevron-container'>
                        {
                            !isLastPage && <FaChevronRight onClick={() => handleNext()}/>
                        }
                    </div>
                </div>
            </div>
            <SearchContainer>
                <InputWithIcon icon={<FaSearch />} type="text" placeholder="Search Filter Category" onChange={(e) => handleUpdateQuery(e.target.value)} style={{padding: '2px 8px', height: '36px'}}/>
            </SearchContainer>
            <CounterContainer colorScheme={colorScheme}>
                {countSelectedOptions()}
            </CounterContainer>
            <QuickActionsContainer>
                {
                    !allOptionsSelected && <XS cursor='true' onClick={() => handleSelectAll()}>Select All</XS>
                }
                {
                    someOptionsSelected && <XS cursor='true' onClick={() => handleDeSelectAll()}>Deselect All</XS>
                }
            </QuickActionsContainer>
            <FilterOptionContainer>
                {
                    filteredOptions.map((option, index) => {
                        return (
                            <FilterOption key={index} colorScheme={colorScheme}>
                                <input type='checkbox' onChange={() => handleSelectOption(option)} checked={isChecked(option)}/>
                                <Body ellipsis style={{flex: '1'}}>{option}</Body>
                            </FilterOption>
                        )
                    })
                }
            </FilterOptionContainer>
        </FilterContainer>
    )
}

export default AdvancedFilterV2