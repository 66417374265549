import {isLocationMatch} from './index';
import { toast } from 'react-toastify';
import { isPostEntity } from './creative.util';
import { isSecondaryLocation } from '../types/location';

export const BULK_ACTION = {
  DELETE: 'delete',
  PAUSE: 'pause',
  RESUME: 'resume',
  ACCEPT: 'accept'
}

const getCloneLocation = ( clone, locations, defaultLocation, repoList ) => {
    if (!clone.location) return null;
    let allLocations = [...locations, ...repoList];
    if (defaultLocation) allLocations.push(defaultLocation);
    return allLocations.find(l => l.user.raw.name === clone.location.raw.parentKey.name && l.id === clone.location.raw.id);
}

export const filterEditableClones = ({ action, creative, blockPostsOnSecondariesExp, clones = [], memberList, locations, defaultLocation, repoList, errorCallback, callback }) => {
    clones.forEach( clone => {
      // Set the clone's location data
      clone.locationData = getCloneLocation(clone, locations, defaultLocation, repoList);
      if (!clone.locationData) return;

      //  Set the clone's user data
      clone.userData = memberList.find(m => m.id === clone.location.raw.parentKey.name)

      // * DECIDE IF THIS CLONE CAN BE EDITED
      clone.canEdit = true;
      const isDefaultFolderEdit = isLocationMatch(clone.locationData, defaultLocation);
      const isOtherFolderEdit = !isDefaultFolderEdit && clone.locationData.user.raw.name === defaultLocation?.user.raw.name;
      const isLocationEdit = !isDefaultFolderEdit && !isOtherFolderEdit;

      // * IN FOLDERS - Check the c.fStatus to determine whether it should be cloned to new locations
      // * IN LOCATIONS/CAMPAIGNS - Check the c.status if necessary
      switch(action){
        case BULK_ACTION.PAUSE:
          // Prevent Pausing if Not Active
          if (isLocationEdit && clone.status !== 'ACTIVE') clone.canEdit = false;
          if ((isDefaultFolderEdit || isOtherFolderEdit) && clone.fStatus !== 'ACTIVE')  clone.canEdit = false;
          break;
        case BULK_ACTION.DELETE:
          // Let Backend Handle Validation
          break;
        case BULK_ACTION.RESUME:
          // Prevent Resuming if Not Paused
          if (isLocationEdit && clone.status !== 'PAUSED') clone.canEdit = false;
          if ((isDefaultFolderEdit || isOtherFolderEdit) && clone.fStatus !== 'PAUSED') clone.canEdit = false;
          break;
        case BULK_ACTION.ACCEPT:
          // Prevent accepting if not optional
          // The filtering when getting the clones always ensures that the only creatives here also have the same kind and isMagic values
          break;
        default:
          console.error('Unidentified bulk action: ' + action);
          break;
      }

      // Set Unselectables
      if (!clone.canEdit) clone.isUnselectable = true;
    })

      // If No Clones or None Editable, Display Error
    const noneEditable = !clones?.some(c => c.canEdit)

    if (noneEditable){
      if (errorCallback) {
        errorCallback();
      } else {
        // Display some error message instead
        const isPost = isPostEntity(creative)
        if (action === BULK_ACTION.PAUSE) toast.error('No Pauseable Ads Found');
        if (action === BULK_ACTION.DELETE) toast.error(`No Deletable ${isPost ? 'Posts' : 'Ads'} Found`);
        if (action === BULK_ACTION.RESUME) toast.error('No Resumable Ads Found');
        if (action === BULK_ACTION.ACCEPT) toast.error('No Acceptable Ads Found');
      }
      return;
    }

    // Editable found
    const editbleClones = clones.filter(c => {
      const hasRequiredData = c.locationData && c.userData
      const shouldHide = blockPostsOnSecondariesExp.active && isPostEntity(c) && isSecondaryLocation(c.locationData)
      return hasRequiredData && !shouldHide
    })
    callback?.(editbleClones);
  }

export const getCreativeClones = ({ creative, franchiseName, callback }) => {
    if (!creative) return;

    window.eulerity.makeApiCall('/api/creative/getClones?franchise=' + encodeURIComponent(franchiseName) + '&location=' + creative.location.websafe + '&' + creative.qsParam + '=' + creative.id, 'GET', null, response => {
      const sameKindCreatives = response.filter(c => c.kind === creative.kind && c.isMagic === creative.isMagic);
      callback?.(sameKindCreatives);
    });
}

  export const getFolderText = (type, creativeType, folderName) => {
    if(creativeType === 'Post') return `Selecting the ${folderName} will prevent new campaigns created with this folder from receiving this post.`
      else {
        if(type === BULK_ACTION.DELETE) return `Selecting the ${folderName} will prevent new campaigns created with this folder from receiving this ad.`
        if(type === BULK_ACTION.PAUSE)  return `Selecting the ${folderName} will prevent new campaigns created with this folder from receiving this ad.`
        if(type === BULK_ACTION.RESUME) return `Selecting the ${folderName} will allow new campaigns created with this folder to receive this ad.`
        if(type === BULK_ACTION.ACCEPT) return `Selecting the ${folderName} will permanently delete this ${creativeType} from the blueprint location. All new campaigns created will not have access to this ad.`
      }
  }
