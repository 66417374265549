import React, { useRef, useLayoutEffect, useState } from 'react';
import { useTooltip } from './useTooltip';
import styled from 'styled-components';
import { createBoxShadow } from '../../styles/card';

const TooltipContainer = styled.div`
  background: ${(props) => props.theme.color.backgroundColor2} !important;
  color: ${(props) => props.theme.color.textColor1} !important;
  box-shadow: ${createBoxShadow};
  opacity: 1 !important;
  z-index: 9999;
  border-radius: 6px;
  font-weight: 400;
  padding: 10px !important;
  max-width: 250px;
  position: fixed;
  visibility: hidden;
  word-break: break-word;
`;

export const Tooltip = () => {
  const { tooltipContent, tooltipVisible, targetRef } = useTooltip();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<React.CSSProperties>({});

  useLayoutEffect(() => {
    if (!tooltipVisible || !targetRef || !tooltipRef.current) {
      setStyle((prev) => ({ ...prev, visibility: 'hidden' }));
      return;
    }

    const targetRect = targetRef.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let top = targetRect.top - tooltipRect.height - 8;
    let left = targetRect.left + (targetRect.width - tooltipRect.width) / 2;

    if (top < 0) {
      top = targetRect.bottom + 8;
    }

    left = Math.max(8, Math.min(left, viewportWidth - tooltipRect.width - 8));

    if (top + tooltipRect.height > viewportHeight) {
      top = viewportHeight - tooltipRect.height - 8;
    }

    setStyle({
      top: `${top}px`,
      left: `${left}px`,
      visibility: 'visible',
    });
  }, [tooltipVisible, targetRef]);

  if (!tooltipVisible || !tooltipContent) return null;

  return (
    <TooltipContainer ref={tooltipRef} style={style}>
      {tooltipContent}
    </TooltipContainer>
  );
};
