import React from "react";
import { DateHelper } from "../../../../../../../helpers";
import { makeIdentifiable } from "../../../../../../../helpers/structures/Queue";
import { Small, XS } from "../../../../../../../styles/typography";
import { isPrimaryLocation } from "../../../../../../../types/location";
import columnBuilder from "../../../columnBuilder";
import { userColumnLoader } from "../../../loaders";

const defaultReportCategory = columnBuilder({
	category: 'default',
	subCategory: 'General',
})

const dateFormatter = (date: Date) => DateHelper.getStringFromDate(date, "MDY", "/")

const defaultColumns = [
	defaultReportCategory
		.name('Date')
		.id('date')
		.makeRow((data) => {
			return data.input.date
		})
		.toString(dateFormatter),
	defaultReportCategory
		.name('Business')
		.id('business')
		.makeRow((data) => {
			return makeIdentifiable(data.input.location, (location) => location.name)
		})
		.toString((location) => {
			return location.name
		})
		.toHTML((location) => {
			return (
				<div style={{
					display: 'flex',
					flexDirection: 'column',
				}}>
					<Small semibold>{location.name}</Small>
					<XS>{location.streetAddress}</XS>
				</div>
			)
		}),
	defaultReportCategory
		.name('Campaign')
		.id('campaign')
		.makeRow((data) => {
			return makeIdentifiable(data.input.location, (location) => location.nickname)
		})
		.toString((location) => {
			return location.nickname
		})
		.toHTML((location) => {
			return (
				<div style={{
					display: 'flex',
					flexDirection: 'column',
				}}>
					<Small semibold>{location.nickname}</Small>
					<XS>{isPrimaryLocation(location) ? "Primary" : "Secondary"}</XS>
				</div>
			)
		}),
	defaultReportCategory
		.name('Email')
		.id('email')
		.loadColumn(userColumnLoader)
		.makeRow((data) => {
			const user = data.column.find(user => data.input.location.user.raw.name === user.id)
			return user?.email ?? "None"
		})
]

export default defaultColumns