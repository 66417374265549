import columnBuilder from "../../../columnBuilder";
import { getDayReport, reduceNumberArray } from "../shared";
import { reportLoader } from '../../../loaders';
import { calculateCPC } from "../../../../../../../helpers";

const cpcReportCategory = columnBuilder()
	.category('performance')
	.subCategory('Cost Per Click')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `CPC: ${meta.name}`
	})

type Cpc = {
	clicks: number | undefined
	cost: number | undefined
}

const cpcFormatter = (value: Cpc | undefined) => {
	if (value === undefined) return "N/A"
	const cpc = calculateCPC(value.clicks, value.cost)
	return `$${cpc.toFixed(2)}`
}

const cpcAggregator = (one: Cpc | undefined, two: Cpc | undefined) => {
	if (one === undefined && two === undefined) return undefined
	if (one === undefined) return two
	if (two === undefined) return one

	let clicks

	if (one.clicks === undefined) {
		clicks = two.clicks
	} else if (two.clicks === undefined) {
		clicks = one.clicks
	} else {
		clicks = one.clicks + two.clicks
	}

	let cost

	if (one.cost === undefined) {
		cost = two.cost
	} else if (two.cost === undefined) {
		cost = one.cost
	} else {
		cost = one.cost + two.cost
	}

	return {
		clicks,
		cost
	}
}

const cpcColumns = [
	cpcReportCategory
		.id('cpc-total')
		.name('Total')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			if (!targetDate) return

			const cost = reduceNumberArray(Object.values(targetDate.channelMetrics || {}).map(channel => channel.cost))
			const clicks = reduceNumberArray(Object.values(targetDate.channelMetrics || {}).map(channel => channel.clicks))

			return {
				clicks,
				cost
			}
		})
		.aggregateRow(cpcAggregator)
		.toString(cpcFormatter),
	cpcReportCategory
		.id('cpc-by-display')
		.name('Display')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.display

			if (!channelMetric) {
				return
			}

			return {
				clicks: channelMetric.clicks,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpcAggregator)
		.toString(cpcFormatter),
	cpcReportCategory
		.id('cpc-by-search')
		.name('Search')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.search

			if (!channelMetric) {
				return
			}

			return {
				clicks: channelMetric.clicks,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpcAggregator)
		.toString(cpcFormatter),
	cpcReportCategory
		.id('cpc-by-social')
		.name('Social')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.social

			if (!channelMetric) {
				return
			}

			return {
				clicks: channelMetric.clicks,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpcAggregator)
		.toString(cpcFormatter),
	cpcReportCategory
		.id('cpc-by-video')
		.name('Video')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.video

			if (!channelMetric) {
				return
			}

			return {
				clicks: channelMetric.clicks,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpcAggregator)
		.toString(cpcFormatter),
]

export default cpcColumns