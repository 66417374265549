import React from 'react'
import { StyledModalFooter } from './styles';
import {PrimaryCTABtn, OutlineBtn, DangerBtn, ConfirmBtn} from '../../styles/buttons';

const ModalFooter = ({buttons, onSubmit, footerContent}) => {
    return (
        <StyledModalFooter>
            {
                buttons && buttons.map((btn, idx) => {
                    const { text, onClick } = btn;
                    return  text === 'Cancel' ? <DangerBtn key={idx} onClick={onClick}> {text} </DangerBtn> :
                            text === 'Confirm' ?  <ConfirmBtn key={idx} onClick={onClick}> {text} </ConfirmBtn>:
                            <OutlineBtn key={idx} onClick={onClick}> {text} </OutlineBtn>
                })
            }
            {
                onSubmit && <PrimaryCTABtn onClick={ onSubmit }>Submit</PrimaryCTABtn>
            }
            {
                footerContent &&
                <div className="footerContent">
                    {footerContent}
                </div>
            }
        </StyledModalFooter>
    )
}

export default ModalFooter
