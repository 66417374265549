import styled from 'styled-components';

export const StyledFetchingData = styled.div`
    text-align: center;
    padding: 40px;
    height: 100vh;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        max-width: 300px;
    }
`