import { useCallback } from 'react'
import useDynamicSetting from './useDynamicSetting'
import { PermissionableLocationField } from '../types/primitives'
import { LOCATION_FIELD_ACCESS_LEVEL } from '../constants/restrictions'

//* This hook contains functions that handle location level restrictions
const useLocationFieldAccess = () => {
    const { locationFieldAccessLevels } = useDynamicSetting()

    const isHiddenField = useCallback((field: PermissionableLocationField) : boolean => {
        return locationFieldAccessLevels[field] === LOCATION_FIELD_ACCESS_LEVEL.HIDDEN
    },[locationFieldAccessLevels])

    const isReadOnlyField = useCallback((field: PermissionableLocationField) : boolean => {
        return locationFieldAccessLevels[field] === LOCATION_FIELD_ACCESS_LEVEL.READ_ONLY
    }, [locationFieldAccessLevels])

    const isLockedDemographic = useCallback((field: PermissionableLocationField) : boolean => {
        return isHiddenField(field) || isReadOnlyField(field)
    }, [isHiddenField, isReadOnlyField])

    return {
        isHiddenField,
        isReadOnlyField,
        isLockedDemographic
    }
}

export default useLocationFieldAccess