import { getEnvironmentType } from "./util";

const useEnvironment = () => {
    const environment = getEnvironmentType()

  return {
    name: environment,
    isDevelopment: environment === 'development',
    isStaging: environment === 'staging',
    isTest: environment === 'test',
    isProduction: environment === 'production'
   }
}

export default useEnvironment