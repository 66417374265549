import {hasActiveSubscription, isLocationKeyMatch} from '../helpers';

export const currentStatus = (isActive, subscriptions, allowInactivePosting) => {
    if (isActive) return 'Active';
    if (allowInactivePosting) return 'Post Only';
    if (!subscriptions.length) return 'Draft';
    return 'Inactive';
}

export const getLocationGroupStatus = (primaryLocation, allProfiles) => {
    // Gets the overall status of a primaryLocation and its profiles
    const profilesOfPrimary = allProfiles.filter(p => p.primaryLocation && isLocationKeyMatch(`${p.primaryLocation.raw.parentKey.name}-${p.primaryLocation.raw.id}`, primaryLocation))
    if(hasActiveSubscription(primaryLocation) || profilesOfPrimary.find(p => hasActiveSubscription(p))) {
        return {label: 'Active', value: 1}
    }
    if (primaryLocation.subscriptions.length || profilesOfPrimary.find(p => p.subscriptions.length)) {
        return {label: 'Inactive', value: 0}
    }
    return {label: 'Draft', value: -1}
}

export const subscriptionSort = (a,b, lowToHigh) => {
    let aProp = a?.subscriptions[0]?.status === 'ACTIVE'  ?  'monthlySpend' : 'pausedMonthlySpend'
    let bProp = b?.subscriptions[0]?.status === 'ACTIVE' ?   'monthlySpend' : 'pausedMonthlySpend'

    a = a?.subscriptions[0]?.[aProp] ? a?.subscriptions[0]?.[aProp] : lowToHigh ? -1 : -1
    b = b?.subscriptions[0]?.[bProp] ? b?.subscriptions[0]?.[bProp] : lowToHigh ? -1 : -1

    if (a > b)
        return lowToHigh ? 1 : -1
    if (a < b)
        return lowToHigh ? -1 : 1
    return 0
}

export const dateSort = (a, b, mostRecent) => {
    let dateA = new Date(a?.subscriptions[0]?.created).getTime();
    let dateB = new Date(b?.subscriptions[0]?.created).getTime();

    dateA = dateA ? dateA : mostRecent ? 0 : -1;
    dateB = dateB ? dateB : mostRecent ? 0 : -1;

    if (dateA > dateB)
        return mostRecent ? -1 : 1
    if (dateA < dateB)
        return mostRecent ? 1 : -1
    return 0
}

export const doLocationUsersMatch = (location, otherLocation) => {
    return location?.user?.raw?.name === otherLocation?.user?.raw?.name
}