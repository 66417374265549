import { useMemo } from "react"
import { useSelector } from "react-redux"
import { API } from "../../../constants/api"
import { makeRequest } from "../../../helpers/make-request"
import { useRequest } from "../../../hooks/useRequest"
import { useCreativeMetadata, useGetCreativeMetadata } from "./useCreativeMetadata"
import { Creative, AdWithMetadata, Post, Ad, PostWithMetadata, CreativeWithMetadata } from "../creative.types"

export const useCreativeClones = (creative: Creative) => {

    const franchiseName = useSelector(state => state.franchise.name)
    const { location } = useCreativeMetadata(creative)

    // The request to get all of the clones from the backend
    const request = makeRequest()
            .url(API.CREATIVE.GET_CLONES)
            .param('franchise', franchiseName)
            .param('location', location?.websafe || "")
            .param(creative.qsParam, creative.id)

    // Use a hook to fetch and get some data on the reqeust
    const { data, error, loading } = useRequest(request)

    return {
        clones: (data ?? []) as Creative[],
        clonesLoading: loading,
        clonesError: error
    }
}

export const useCreativeClonesMetadata = (creative: Ad | Post) : {
    clones: AdWithMetadata[] | PostWithMetadata[],
    clonesLoading: boolean,
    clonesError: any,
} => {
    const { clones, clonesLoading, clonesError } = useCreativeClones(creative)
    const getMetadata = useGetCreativeMetadata()

    const _clones: (AdWithMetadata[] | PostWithMetadata[]) = useMemo(() => {
        return clones?.map((c: Creative) => ({
                ...c,
                metadata: getMetadata({ creative: c})
            }))
            .filter((c: CreativeWithMetadata) => !!c.metadata.location) as AdWithMetadata[] | PostWithMetadata[]
    }, [clones, getMetadata])

    return {
        clones: _clones,
        clonesLoading,
        clonesError
    }
}