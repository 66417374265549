import React, { useCallback, useMemo, useState } from 'react';

import useDeprecatedDefineSuperTableFilterDoNotUse from '../hooks/useDefineSuperTableFilter';
import { RenderValueWrap, RenderValuesWrap } from './SuperTableGenericTextFilterStyle';
import { Body } from '../../../styles/typography';
import CheckBox from '../../checkbox/CheckBox';

const DeprecatedSuperTableGenericTextFilterDoNotUse = ({ items, renderItem, extractFilterString }) => {
    const [selectedRenderValues, setSelectedRenderValues] = useState([]);

    const filterItem = useCallback(
        (item) => {
            if (selectedRenderValues.length === 0) return true;

            const val = extractFilterString ? extractFilterString(item) : renderItem(item);

            return selectedRenderValues.includes(val);
        },
        [selectedRenderValues, renderItem, extractFilterString]
    );

    useDeprecatedDefineSuperTableFilterDoNotUse(filterItem);

    const renderValues = useMemo(() => {
        const set = new Set();

        for (const item of items) {
            const val = extractFilterString ? extractFilterString(item) : renderItem(item);

            if (typeof val !== 'string') {
                console.error("failed to generate a string from renderItem or extractFilterString, instead got:", val);
                continue;
            }

            set.add(val);
        }

        return Array.from(set).sort();
    }, [items, renderItem, extractFilterString]);

    const handleRenderValueToggled = (renderValue) => {
        if (selectedRenderValues.includes(renderValue)) {
            setSelectedRenderValues(
                selectedRenderValues.filter(
                    (selectedRenderValue) => selectedRenderValue !== renderValue
                )
            );
        } else {
            setSelectedRenderValues([...selectedRenderValues, renderValue]);
        }
    };


    return (
        <RenderValuesWrap>
            {renderValues.map((renderValue) => {
                return (
                    <RenderValueWrap
                        key={renderValue}
                        onClick={() => handleRenderValueToggled(renderValue)}
                    >
                        <CheckBox checked={selectedRenderValues.includes(renderValue)} />
                        <Body>
                            {renderValue}
                        </Body>
                    </RenderValueWrap>
                );
            })}
        </RenderValuesWrap>
    );
};

export default DeprecatedSuperTableGenericTextFilterDoNotUse;
