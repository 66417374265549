import styled, { css } from "styled-components";
import {PrimaryCTABtn} from "../../../styles/buttons";
export const StyledBulkEditModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const EnhancePrimaryButton = styled(PrimaryCTABtn)`
  width: 10rem;
  font-weight: 400;
`
export const StyleEditBulkHeader = styled.div`
  text-align: start;
  width: 100%;
`;
export const StyledCloneList = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 347px));
  grid-auto-rows: 10px;
  justify-content: center;
  justify-items: center;
  align-items: start;
`

export const StyledCloneItem = styled.div`
  width: 310px;
  min-height: min-content;
  padding: 5px;
  margin: 5px;

  overflow: hidden;
  cursor: pointer;
  border-radius: 9px;
  outline: 3px solid ${(props) => props.theme.color.accentColor2};
  position: relative;
  display: grid;
  place-items: center;

  .clone_item-info {
    margin: 1rem 0 1rem 0;
    font-size: 16px;
    display: grid;
    row-gap: 5px;
    width: 90%;
  }
  .clone_selected {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 1rem;
    position: absolute;
    width: 100%;
    height: 10rem;
    position: relative;
    svg {
      z-index: 10;
      margin-left: 1rem;
      path {
        stroke: ${(props) => props.theme.color.textColor1};
      }
    }
  }
  .checkbox-container {
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 14px;
    top: 4px;
    background: transparent;
    border: 2px solid grey;
    border-radius: 3px;
  }

  .clone_item-header {
    font-weight: bold;
  }
  .clone_item_name {
    font-weight: 300;
  }

  .checkbox-wrapper {
    height: 1.5rem;
    width: 100%;
    margin: 0.5rem 0 1rem 0;

  }
  img {
    width: 15rem;
  }
  .clone_item-email {
    word-break: break-all;
  }


  ${({ isSelected, bulkEditAction }) =>
    isSelected &&
    bulkEditAction === "delete" &&
    css`
      outline-color: ${(props) => props.theme.color.errorNegativeButtonColor};
    `}
  ${({ isSelected, bulkEditAction }) =>
    isSelected &&
    bulkEditAction === "pause" &&
    css`
      outline-color: ${(props) => props.theme.color.warningButtonColor};
    `}
    ${({ isSelected, bulkEditAction }) =>
    isSelected &&
    bulkEditAction === "resume" &&
    css`
      outline-color: ${(props) => props.theme.color.activeButtonColor};
    `}
    ${({ isSelected, bulkEditAction }) =>
    isSelected &&
    bulkEditAction === "accept" &&
    css`
      outline-color: ${(props) => props.theme.color.activeButtonColor};
    `}

    ${({ isUnselectable }) =>
    isUnselectable &&
    css`
      opacity: 0.5;
      cursor: default;
      filter: grayscale(1);
      pointer-events: none;
    `}

    // Style Clones where the api calls succeeded already. Unselectable styling.
    ${({ isEdited, bulkEditAction }) =>
    isEdited &&
    bulkEditAction === "delete" &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.color.errorNegativeButtonColor};
        opacity: 0.45;
      }
    `}
    ${({ isEdited, bulkEditAction }) =>
    isEdited &&
    bulkEditAction === "pause" &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.color.warningButtonColor};
        opacity: 0.45;
      }
    `}
    ${({ isEdited, bulkEditAction }) =>
    isEdited &&
    bulkEditAction === "resume" &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.color.activeButtonColor};
        opacity: 0.45;
      }
    `}
    ${({ isEdited, bulkEditAction }) =>
    isEdited &&
    bulkEditAction === "accept" &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.color.activeButtonColor};
        opacity: 0.45;
      }
    `}
`;

export const StyledHeaderBulkActionsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
`

export const StyledActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 180px;
`