export type PublishMethod = 'ASAP' | 'Future'
export type ScheduleMethod = 'Prime' | 'Custom' | null

export const PUBLISH_METHOD: { [key: string]: PublishMethod } = {
    ASAP: 'ASAP',
    FUTURE: 'Future'
}

export const SCHEDULE_METHOD: { [key: string]: ScheduleMethod } = {
    PRIME: 'Prime',
    CUSTOM: 'Custom'
}

export type PrimeTimePostingType = {
    adjustedDate: Date;
    isPrimeTimeDisabled: boolean,
    publishMethod: PublishMethod,
    scheduleMethod: ScheduleMethod,
    selectedDate: Date | null,
    selectedTime: Date | null,
    setPublishMethod: (value: PublishMethod) => void,
    setScheduleMethod: (value: ScheduleMethod) => void,
    setSelectedTime: (date: Date) => void,
    setSelectedDate: (date: Date) => void,
    validateDateStep: (callback: () => void)  => void,
    validateStartDate: (date: Date) => void,
    validateStartTime: (date: Date) => void
}