import { LOCATION_CONNECTIONS } from '../../../../../../../features/connections/constants'
import {
    getLocationConnections,
    getNetworkPageUrl
} from '../../../../../../../features/locations/util'
import { POST_NETWORK } from '../../../../../../../helpers/creative.util'
import columnBuilder from '../../../columnBuilder'

const facebookReportCategory = columnBuilder()
    .category('platform-connections')
    .subCategory('Facebook')
    .makeHeader((meta) => {
        return `Facebook: ${meta.name}`
    })

const facebookColumns = [
    facebookReportCategory
        .id('facebook-total')
        .name('Connection Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            const isPostingAccessible =
                connections[LOCATION_CONNECTIONS.FACEBOOK_POSTING]
            const isAdvertisingAccessible =
                connections[LOCATION_CONNECTIONS.FACEBOOK_ADVERTISING]

            return !!isPostingAccessible || !!isAdvertisingAccessible
        })
        .toString((data) => (data ? 'Connected' : 'Not Connected')),
    facebookReportCategory
        .id('facebook-default-landing-page')
        .name('Default Landing Page')
        .makeRow((data) => {
            const { url } = getNetworkPageUrl(
                data.input.location,
                POST_NETWORK.FACEBOOK
            )

            return url
        })
        .toString((data) => data || 'Not connected'),
    facebookReportCategory
        .id('facebook-lead-form')
        .name('Lead Form')
        .makeRow((data) => {
            return data.input.location.facebookLeadFormId
        })
        .toString((data) => data ?? 'Not connected'),
    facebookReportCategory
        .id('facebook-page-id')
        .name('Page ID')
        .makeRow((data) => {
            return data.input.location.facebookPageId
        })
        .toString((data) => data ?? 'Not connected'),
    facebookReportCategory
        .id('facebook-advertising-access')
        .name('Advertising Access Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return !!connections[LOCATION_CONNECTIONS.FACEBOOK_ADVERTISING]
        })
        .toString((data) => (data ? 'Connected' : 'Not Connected')),
    facebookReportCategory
        .id('facebook-posting-access')
        .name('Posting Access Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return !!connections[LOCATION_CONNECTIONS.FACEBOOK_POSTING]
        })
        .toString((data) => (data ? 'Connected' : 'Not Connected')),
]

export default facebookColumns
