import React, { ReactNode, createContext, useContext } from 'react'
import { TaskStateContext } from './types';
import useBackgroundTasks from './useBackgroundTasks';

export const BackgroundTasksContext = createContext<TaskStateContext>({} as TaskStateContext)

export const BackgroundJobsContextProvider = ({ children }: {children: ReactNode}) => {
    const value: TaskStateContext = useBackgroundTasks()
    return <BackgroundTasksContext.Provider value={value}>{children}</BackgroundTasksContext.Provider>
}


export const useJob = () => {
    const context = useContext(BackgroundTasksContext)

    if (!context) {
        throw new Error("useJob must be used in the BackgroundTask provider!")
    }

    return context
}