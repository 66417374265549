import { API } from '../constants/api';
import {
    CLEAR_CALENDAR_TAB,
    LOAD_CREATIVE_FOLDERS,
    LOAD_CREATIVE_FOLDER_CREATIVES,
} from './types';

export const loadCreativeFoldersForCalendar = (franchiseName, defaultLocation, cb) => (dispatch) => {

    // Load the Default Campaign Creative Folder (default location)
    if (defaultLocation) {
        let creatives = []
        const uid = defaultLocation.user.raw.name
        const lid = defaultLocation.id
        const key = `${uid}-${lid}`;
        window.eulerity.makeBatchedApiCall({
            url: `${API.CREATIVE.LIST}?uid=${uid}&lid=${lid}`,
            dataCallback: function (resp) {
                creatives = creatives.concat(resp);
            },
            doneCallback: function () {
                dispatch({
                    type: LOAD_CREATIVE_FOLDER_CREATIVES,
                    payload: {
                        key,
                        creatives,
                    },
                });
                cb?.()
            },
        });
    }

    // Load the Other Folders
    if (!franchiseName) return;
    window.eulerity.makeApiCall(
        `${API.FRANCHISE.GET_CREATIVE_REPOS}?franchise=${encodeURIComponent(franchiseName)}`,
        'GET',
        null,
        async (response) => {
            dispatch({
                type: LOAD_CREATIVE_FOLDERS,
                payload: response,
            });
            for (const folder of response) {
                const uid = folder.primaryLocation.raw.parentKey.name;
                const lid = folder.id;
                const key = `${uid}-${lid}`;

                const folderCreatives = await new Promise((resolve) => {
                    let creatives = [];

                    window.eulerity.makeBatchedApiCall({
                        url: `${API.CREATIVE.LIST}?uid=${uid}&lid=${folder.id}`,
                        dataCallback: function (resp) {
                            creatives = creatives.concat(resp);
                        },
                        doneCallback: function () {
                            resolve(creatives);
                        },
                    });
                });

                dispatch({
                    type: LOAD_CREATIVE_FOLDER_CREATIVES,
                    payload: {
                        key,
                        creatives: folderCreatives,
                    },
                });
            }
        }
    );
};

export const clearCalendar = () => dispatch => {
    dispatch({type: CLEAR_CALENDAR_TAB})
}