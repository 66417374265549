import { AiOutlineDollarCircle } from 'react-icons/ai';
import styled, { css } from 'styled-components';
import { decideTextColor, hexToRgba } from '../../helpers/colors.util';
import { LOCATION_STATUS } from "../../features/locations/util";
import { Card } from '../../styles/card';
import { BigBody, getColorFromVariant } from '../../styles/typography';
import { BlueprintTypeOption, BlueprintTypeOptionsWrapper } from '../blueprints/styles';

export const StyleLocationComponentWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
    margin-top: 0.5rem;
`
//Search
export const SearchField = styled.input`
    border-color: transparent !important;
    font-size: 1rem;
    background:${props => props.theme.color.backgroundColor2};
    color: ${props => props.theme.color.textColor1};
    caret-color: ${props => props.theme.color.accentColor2};
    font-family: ${props => props.theme.font?.body?.family};
    &:focus {
        /* border: none !important; */
        outline: none !important;
    }
`

export const SearchContainer = styled(Card)`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    height: 40px;
    width: 100%;

    input {
        font-size: 14px;
    }

    .search-icon {
        color:${props => props.theme.color.accentColor3};
        font-size: 1.8rem;
        margin-right: 5px;
    }
`
// Wrapper
export const LocationContentContainer = styled.div`
    display: grid;
    grid-template-columns: 300px 800px;
    grid-gap: 30px;
`

export const CreateLocationButton = styled.div`
    padding: 0.4rem;
    cursor: pointer;
    margin-top: -0.4rem;
    width: 100%;

    .create-location-wrapper {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;
        margin-bottom: 0.4rem;
    }
`

// Detail
export const LocationSummaryCard = styled(Card)`
    min-height: 300px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;

    .summary-detail {
        width: 100%;
        max-width: 800px;
    }

    table {
        border-spacing: 15px;
        width:100%;
    }

    table th {
        text-align:left;
        min-width: 140px;
        font-weight: 300;
        font-size: 0.9rem;
    }

    .smart-budget-icon {
        width: 22px;
        height: 22px;
        margin: auto;
        margin-right: 5px;
        margin-left: 0px;
    }
`
export const LocationSummaryHeader = styled.div`
    border-bottom: 1px solid ${props => props.theme.color.greyAccent2};
    text-align: left;
    line-height: 1.2rem;
    padding: 10px;
    position: relative;

    .setting-icon {
        font-size: 1.8rem;
        color: ${props => props.theme.color.accentColor3};
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
`

export const LocationSummaryInfo = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
`
export const LocationSummaryTitle = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 33px;
    gap: 2px;

    p {
        line-height: 25px;
    }
`
export const LocationLabelContainer = styled.div`
    margin: auto;
    width: 100%;
    max-height: 4rem;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 3%;
    gap: 0.5rem;

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

export const LocationCaption = styled.li`
    list-style-type: none;
    text-align: center;
`;

export const LocationSummaryDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    p {
        margin-bottom: 0px !important;
    }

    .address {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .inviteToApp {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        color: ${props => props.theme.color.accentColor2};
        font-style: normal;
        font-weight: normal;
        cursor: pointer;
        .link {
            margin-left: 5px;
            text-decoration: underline;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`

export const SmartBudgetDetail = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 10px !important;
    max-width: 325px;
    .smart-budget-icon {
        min-width: 45px;
        max-width: 45px;
        width: 45px;
        path {
            fill: ${({ theme }) => theme.color.accentColor2}
        }
    }
`

export const LocationStatus = styled.td<{
    status: typeof LOCATION_STATUS[keyof typeof LOCATION_STATUS]
}>`
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    font-size: 0.9rem;
    ${({status}) => status === LOCATION_STATUS.ACTIVE && css `
        color: ${props => props.theme.color.activeButtonColor};
    `}
    ${({status}) => [LOCATION_STATUS.INACTIVE, LOCATION_STATUS.DRAFT, LOCATION_STATUS.PAUSED, LOCATION_STATUS.DECLINED].some(item => item === status) && css `
        color: ${props => props.theme.color.greyAccent1};
    `}
    ${({status}) => status === LOCATION_STATUS.PENDING && css `
        color: ${props => props.theme.color.warningButtonColor};
    `}
`

export const LocationCreativesCard = styled(Card)`
    margin-top: 30px;
    height: 950px;
    padding: 28px 32px;
    display: flex;
    flex-direction: column;
`

export const LocationCreativeItem = styled.div<{
    status: string
}>`
    cursor: pointer;
    position: relative;
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: center;

    p {
        align-self: flex-start;
    }

    .locationCreativePreview {
        position: relative;
        width: 100%;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 0;
    }

    .locationCreativeHeader {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    ${({status}) =>  status === 'NOSUBSCRIPTION' &&
        status  && css `
             filter: grayscale(100%);
    `}
`

export const CreativesContainer = styled.div`
    flex-grow: 1;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(166px,0fr));
    grid-gap: 13px;
    row-gap: 13px;
    margin: 37px 10px;
    overflow-y: auto;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-content: flex-start;

    .creativeThumbnail {
        height: 131.34px;
    }
`

// General Settings
export const NewLabelContainer = styled.div`
    margin: 10px 0px;
    display: flex;
    flex-direction: column;

    input[name=label] {
        width: 100%;
    }

    .addNewLabelBtn {
        width: 25px;
        height: 25px;
        text-align: center;
        margin: 5px auto;
        display: inline-block;
        align-self: center;
        background-color: ${props => props.theme.color.accentColor2};
        color: ${props => props.theme.color.backgroundColor2};
        border-radius: 50%;
        cursor: pointer;
    }
`

export const AddLabelForm = styled.div`
    display: flex;

    .labelRow {
        display: flex;
        flex-direction: column;
        flex-grow: 0.97;
    }
`

// Target Demographics
export const GeoTargetActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 20px ;
    gap: 20px;
`

export const StyleGeoLocationModal = styled.div`
    display: grid;
    place-items: center;
    min-height: 500px;
`

export const StyleGeoLocationModalWrap = styled.div`
    margin: 0 auto;
    width: 100%;
`

export const StyleSearchGeoLocation = styled.div`
    display: grid;
    row-gap: 2rem;
    width: 100%;
    max-width: 820px;
`

export const StyleGeoResults = styled.div`
    height: 320px;
    border-radius: 9px;
    border: 1px solid ${props => props.theme.color.greyAccent1};
    width: 100%;
    transition: all .2s;
`

export const StyleGeoResultsWrap = styled.div`
    padding: 1rem;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
`

export const StyleSelectedGeoLocations = styled(StyleGeoResults)<{
    setDynamicHeight: number
}>`
    height: ${props => props.setDynamicHeight >= 1 && '100px'};
    height: ${props => props.setDynamicHeight >= 2 && '140px'};
    height: ${props => props.setDynamicHeight >= 3 && '210px'};
    height: ${props => props.setDynamicHeight > 3 && '270px'};
    padding: 0px;
    display: flex;
    flex-direction: column;
`

export const SelectedLocationsWrap = styled.div`
    height: 100%;
    padding: 1rem;
    overflow-y: scroll;
    padding: 1rem;
`

export const StyleEachGeoItem = styled.div<{
    hover: boolean,
    disabled: boolean
}>`
    position: relative;
    display: flex;
    height: 48px;
    border-radius: 9px;
    border: 1px solid ${props => props.theme.color.accentColor2};
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    .blur-selected-item {
        position: absolute;
        width: 105%;
        height: 110%;
        background-color: white;
        top: -3px;
        left: -1rem;
        opacity: 0.8;
    }

    opacity: ${props => props.disabled ? "0.2" : ""};

    ${props => props.hover && css`
        &:hover {
            background-color: ${props => props.theme.color.accentColor2};
            font-weight: 600;
            color: ${props => decideTextColor(props.theme.color.accentColor2)};
        }
    `}

    .grid_layout {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
       align-content: center;
    }
    .geo_item-palce {
        flex: 4;
    }
    .geo_item-info {
        flex: 2;
    }
`


export const StyleEachSelectedGeoItem = styled(StyleEachGeoItem)`
      background-color: ${props => props.theme.color.accentColor2};
      color: ${props => decideTextColor(props.theme.color.accentColor2)};
      font-weight: 600;
`

export const StyleUploadContainer = styled.div`
    .upload_csv_buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }
`
export const StyleLoader = styled.div<{
    visibility: boolean
}>`
    margin-top: 1rem;
    border: 1px solid ${props => props.theme.color.greyAccent2};
    border-radius: 9px;
    width: 100%;
    height: 25px;
    position: relative;
    overflow: hidden;
    visibility: ${props => props.visibility ? 'visible' : 'hidden'};

    .progress_bar {
        display: flex;
        z-index: 100;
        position: absolute;
        height: 25px;
        background-color: ${props => props.theme.color.accentColor2};
        width: 0;
        transition: all 0.3s;
        justify-content: center;
        align-items: center;
    }

`
export const StyleUploadedList = styled.div`
  border-radius: 9px;
  border: 1px solid ${props => props.theme.color.accentColor2};

`
export const StyleUploadedListItem = styled.div`
    height: 5rem;
    padding: 1rem;
    border-bottom: 1px solid ${props => props.theme.color.accentColor2};
    display: flex;
    &:last-child {
        border: none;
    }
    .uploaded_item-geolocation,
    .uploaded_item-zipcode {
      display: flex;
    }
    .uploaded_item-zipcode {
        flex: 1;
        justify-content: center;
        align-items: center;

    }
    .uploaded_item-geolocation {
        flex: 2;
        align-items: center;
    }

    .uploaded_item-latitude,
    .uploaded_item-longitude,
    .uploaded_item-radius,
    .uploaded_item-status {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1
    }
    .valid_geotarget {
        color: ${props => props.theme.color.activeButtonColor}
    }
    .invalid_geotarget {
        color: ${props => props.theme.color.errorNegativeButtonColor}
    }
`

export const StyleUploadGeoButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 1rem;
`

// Form Styles
export const StyledForm = styled.div`
    padding-top: 1rem;
    width: 80%;
    max-width: 1000px;

    input[type=text],
    textarea {
        background:  transparent;
        color: ${props => props.theme.color.textColor1};
        padding: 0.25rem 0.5rem;
        margin: 2px 0;
        border-radius: 9px;
        min-height: 34px;
        border: 1px solid ${props => props.theme.color.accentColor3};
        caret-color: ${props => props.theme.color.accentColor2};
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    button {
        display: flex;
    }

    label {
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        text-transform: uppercase;
    }

    .select_coutry {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }
`

export const SquareOptionsWrapper = styled(BlueprintTypeOptionsWrapper)<{
    center?: boolean
}>`
    ${props => props.center && "justify-content: center;"}
    flex-wrap: wrap;
`

export const SquareOption = styled(BlueprintTypeOption)<{
    center?: boolean
    selected?: boolean
}>`
    border-color: ${props => props.theme.color.accentColor2};
    max-width: 240px;
    ${props => props.center && "margin: 40px auto;"}
    svg {
        fill: ${props => props.theme.color.accentColor2};
    }
    svg path {
        stroke: none;
    }
    .diggingIcon path {
        fill: ${props => props.theme.color.accentColor2};
        stroke: none;
    }
    .icon {
        width: 50px;
    }

`

// Reports
export const ReportsContainer = styled(Card)`
    min-height: 650px;
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    padding: 28px 32px;

    text {
        fill:${props => props.theme.color.textColor1}
    }
`;

// GENDER

export const DonutContainer = styled.div`
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
`;

export const DonutColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto;

    svg, .male {
        color: ${props => props.theme.color.creativeColor4};
    }

    svg, .female {
        color: ${props => props.theme.color.creativeColor3};
    }

    .social {
        border: 1px solid ${ props => props.theme.color.creativeColor2}
    }

    .search {
        border: 1px solid ${ props => props.theme.color.creativeColor1}
    }

    .video {
        border: 1px solid ${ props => props.theme.color.creativeColor3}
    }

    .other {
        border: 1px solid ${ props => props.theme.color.creativeColor4}
    }
`;

// BUDGET

export const BudgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 171px;
    height: 135px;
    margin: 10px;
`;

// AdvancedMetrics
export const AdvancedMetricsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
        width: 37px;
        height: 49px;
    }
    .yellow_card {
        border: 1px solid ${ props => props.theme.color.warningButtonColor};
        border-radius: 9px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .blue_card {
        border: 1px solid ${ props => props.theme.color.accentColor2};
        border-radius: 9px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const MetricColumn = styled.div`
    width: 332px;
    height: 200px;
    div {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
    }
`

export const CPContainer = styled.div`
    flex-direction: column;
`;


// Top Sites
export const TopSitesContainer = styled.div`
    margin-bottom: 20px;
    .topsiteImage {
        margin: 20px auto 20px auto;
        display: block;
        width: 380px;
        height: 380px;
    }
`;

// Age

export const AgeContainer = styled.div`
    li {
        margin-bottom: 20px;
    }
`;

export const AgeValues = styled.div`
    display: flex;
    justify-content: space-around;
    width: 550px;
    margin-left: 100px;
    h2 {
        font-size: 18px;
        font-weight: 400;
    }
`;

export const DeviceAudienceContainer = styled.div`
    display: flex;
    width: 60%;
    margin: 0 auto;
`;

export const DeviceList = styled.div`
    display: flex;
    flex-direction: column;
    height: 180px;
    width: 200px;
    justify-content: space-around;
    margin-top: 65px;
    img {
        height: 50px;
        display: block;
        margin: 0 auto;
    }
    img:nth-child(1) {
        width: 30px;
    }
    img:nth-child(2) {
        width: 60px;
    }
    img:nth-child(3) {
        width: 80px;
    }
`;



// NEW LOCATION MENU
export const LocationListActionWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 5px 0px;
`

export const StyleCollapseAll = styled.div`
    flex: 2;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    column-gap: 1rem;
    padding: 0.5rem;
    font-size: 0.7rem;
    p {
        font-size: 10px;
        cursor: pointer;
        line-height: 1.2;
        border-bottom: 1px solid ${props => props.theme.color.accentColor2};;
    }
`

export const StyledSelectWrapper = styled.div`
    flex: 1 1 50px;
    font-weight: 400;
    font-size: 12px;
`
export const StyledNewMenu = styled(Card)`
    position: relative;
    width: 100%;
    min-width: 200px;
    height: min-content;
    display: flex;
    flex-direction: column;
    .location-wrapper {
        height: 33rem;
    }

`

export const StyleLocationNewItemWrapper = styled.div`
    .location_hidden_profile {
            width: 100%;
            max-height: 0;
    }
`

export const StyleLocationNewItem = styled.div<{
    selected: boolean,
}>`
        cursor: pointer;
        width: 100%;
        min-height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background-color: ${ props => props.selected ? props.theme.color.accentColor4 : props.theme.color.backgroundColor2};
        color: ${ props => decideTextColor(props.selected ? props.theme.color.accentColor4 : props.theme.color.backgroundColor2)};
        border-bottom: 1px solid ${({theme}) => theme.color.accentColor3};

        .profile_arrow {
            display: flex;
            justify-content: center;
            margin-right: 9px;
            align-items: center;
            cursor: pointer;
            border-radius: 100%;
        }
`

export const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
`

export const CreativeAccessWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    max-width: 500px;
    flex-wrap: wrap;
`

export const ActiveBubble = styled.div<{
    color: string
}>`
    background-color: ${props => props.color.startsWith('#') ? props.color : getColorFromVariant(props.color || "accentColor2")};
    color: ${props => props.theme.color.textColor3};
    padding: 2px 10px;
    border-radius: 6px;
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-items: baseline;
    gap: 5px;
`

export const StyleLocationNewProfileWrapper = styled.div<{
    shouldHide: boolean,
    selected: boolean,
    status: string,
    allowInactivePosting: boolean
}>`
    cursor: pointer;
    display: ${props => props.shouldHide ? 'none' : 'grid'};
    grid-template-columns: 20px 1fr;

    padding: 8px 0px 8px 16px;
    color: ${props => decideTextColor(props.selected ?  props.theme.color.accentColor2 : props.theme.color.backgroundColor2, // decide the text color based on the background color
        [props.theme.color.textColor1, props.theme.color.textColor3])}; // provide options such that we get a hex back}; // dim the text if this is not active and allowInactive posting is not enabled
    background-color: ${ props => {
        if (props.selected) return props.theme.color.accentColor2;
        switch (props.status) {
            case 'Draft':
                if (props.allowInactivePosting) {
                    return hexToRgba(props.theme.color.accentColor4, 0.5);
                } else {
                    return hexToRgba(props.theme.color.greyAccent1, 0.25);
                }
            case 'Post Only':
            case 'Active':  return hexToRgba(props.theme.color.accentColor4, 0.5);
            case 'Inactive': return hexToRgba(props.theme.color.greyAccent1, 0.25);
            default: return;
        }
    }};
    font-weight: ${ props => props.selected ? '600' : '' };
    align-items: flex-start;

    border-top: 1px solid ${props => hexToRgba(props.theme.color.accentColor3, 0.5)};
    &:first-child {
        border-top: none;
        border-bottom: ${props => hexToRgba(props.theme.color.accentColor3, 0.5)};
    }
    &:last-child {
        border-bottom: 1px solid ${props => hexToRgba(props.theme.color.accentColor3, 0.5)};
    }


    .profile-info {
        width: 100%;
        line-height: 1.15;
    }
`

export const IconsCard = styled.div<{
    dimmed: boolean,
    selected: boolean,
    empty: boolean
}>`
    display: ${props => props.empty ? "none" : "flex"}; // only show if there are icons to display
    max-width: 250px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px 6px;
    opacity: ${props => props.dimmed ? 0.85 : 1};

    .icons {
        color: ${props => decideTextColor(props.selected ?  props.theme.color.accentColor2 : props.theme.color.backgroundColor2, // decide the text color based on the background color
        [props.theme.color.textColor1, props.theme.color.textColor3])}; // provide options such that we get a hex back}; // dim the text if this is not active and allowInactive posting is not enabled
    }

    .with-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
`

// use when we have more status icons to input
const sharedIconStyles = css<{
    selected: boolean
}>`
    color: ${props => props.selected ?  props.theme.color.backgroundColor2 : props.theme.color.accentColor2};
    margin-right: 5px;
    width: 15px;
`

export const DollarIcon = styled(AiOutlineDollarCircle)`
    ${sharedIconStyles}
`

export const LocationDownloadContainer = styled.div`
    p {
        margin: 0 auto;
        width: 657px;
        font-weight: 400;
        font-size: 12px;
        padding: 10px;
    }

    `;

export const DatePickerDownload = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
`;

export const StyleUpdateLocationLogo = styled.div`
    margin-bottom: 16px;

    .update_logo-wrapper {
        display: flex;
        gap: 24px;
    }
    .update_logo-lg {
        width: 80px;
        height: 80px;
        border: ${props => `1px solid ${props.theme.color.accentColor3}`};
        border-radius: 9px;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .update_logo-text-wrapper {
        div {
            display: flex;
            justify-content: space-between;
        }
    }
`
export const CreativeTypeDescriptionWrap = styled.div`
    margin: 2.5rem 5%;
    margin-bottom: 0px;
    .descriptionWrapper {
            min-height: 2.8rem;
        }
    .wikiContainer {
        display: flex;
        align-items: center;
        margin-top: 15px;
        height: max-content;
        > * {
            width: 50%;
        }
    }
`
export const CreativeTypesDisplayWrap = styled.div`
    margin: 10px;
    font-weight: 600;
    padding: 2px 1rem;
    display: flex;
    align-items: center;
    gap: 1.25rem;

    svg {
        margin-right: 0.25rem;
    }

    > div:first-of-type {
        display: flex;
        align-items: center;
    }
`

export const CreativeStatusBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.color.accentColor4};
    border-radius: 6px;
    min-width: 640px;
`

export const CreativeTypesDisplayIcon = styled.div`
    display: flex;
    svg {
        max-height: 20px;
    }
`
export const CreativeOverlayWrap = styled.div<{
    hideBackgroundFade: boolean
}>`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    ${props => !props.hideBackgroundFade && "background-color: rgba(255, 255, 255, 0.6);"};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const SortSelectWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin-right: 5%;

    .react-select__control {
        opacity: 0.6;
        border-color: rgb(51 77 110 / 60%);
    }
    .react-select__control:hover {
        cursor: pointer;
        border-color: rgb(51 77 110 / 100%);
        opacity: 1;
    }

    .react-select__option:hover {
        background-color: rgba(135, 162, 255, 0.2);
    }
    > div {
        display: flex;
        align-content: center;
        align-items: center;
    }
`

export const WrapSelectors = styled.div`
 margin: 1rem 0;
`

export const InterestGroupsHeader = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
`


export const TargetDemographicsModalContentWrap = styled.div`
    max-width: 990px;
`

export const NewFormInput = styled.div`
    display: grid;
    grid-template-columns: 168px auto;
    gap: 1.5rem;
    padding-left: 3rem;

    label:not(.ignore-center) {
        margin-top: 0.75rem;
    }

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }
`

export const DeletionResultsSubtitle = styled(BigBody)`
    margin-top: 1rem;
`

export const DeletionResultsTableTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
`

export const DeletionResultsTableImageWrap = styled.div`
    max-width: 100px;
    max-height: 100px;
`

export const DeletionResultsTableWrap = styled.div`
    .supertable-wrap {
        border: solid 1px ${props => props.theme.color.textColor1};
        border-radius: 9px;
    }

    .supertable-header {
        padding: 1rem 2rem;
        border-bottom: solid 1px ${props => props.theme.color.textColor1};
    }

    .supertable-header-row-data {
        text-align: left;
    }

    .supertable-row {
        padding: 0px 2rem;
        margin-top: 2rem;
    }

    .supertable-row:last-of-type {
        margin-bottom: 1rem;
    }

    .supertable-row-data {
        display: flex;
        justify-content: flex-start;
    }
`

export const DeletionResultsOkayWrap = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    button {
        width: 180px;
    }
`

export const StyledScheduleChangeWrap = styled.div`
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border: solid 0.0625rem ${props => props.theme.color.accentColor2};
    border-radius: 0.5625rem;
    margin-bottom: 0.625rem;
`

export const IconWrapper = styled.div`
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
    svg path {
        stroke: ${({ theme }) => theme.color.textColor3};
    }
`

export const GlowingSuccessIconWrap = styled.div`
    background-color: ${props => hexToRgba(props.theme.color.accentColor3, 0.2)};
    border-radius: 100%;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;


    svg {
        background-color: ${props => props.theme.color.accentColor2};
        border-radius: 100%;
        padding: 0.5rem;
        fill: ${props => decideTextColor(props.theme.color.accentColor2)};
    }
`

export const NewCallForwardingNumberWrap = styled.div`
    padding: 1.5rem;

    background-color: ${props => props.theme.color.accentColor4};
    border: solid 1px ${props => props.theme.color.accentColor3};
    border-radius: 0.25rem;
`

// Social Media
export const SocialMediaContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;

    img {
        width: 20px;
    }
`;

// Supertable Container
export const SuperTableContainer = styled.div`
    width: 100%;
    max-height: 50vh;
    overflow-x: auto;
    white-space: nowrap;

    svg {
        fill: ${props => props.theme.color.accentColor2};
    }
`;