import React from "react";
import { useSelector } from "react-redux";
import { StyledCloneItem } from "./styles";
import { isLocationMatch } from "../../../helpers";
import { CheckIcon } from "../../../styles/customIcons";
import {getFolderText} from '../../../helpers/bulkEdits';
import { getPreviewImageUrl } from "../../../helpers/creative.util";

const CloneItem = ({ clone, bulkEditAction, isSelected = false, isEdited = false, selectClone, deselectClone, creativeType }) => {
  const { id, locationData, userData, youTubeId, preview, isUnselectable } = clone;

  const defaultLocation = useSelector((state) => state.franchise.defaultLocation);
  const defaultRepoName = 'Default Campaign Creative Folder'
  const isDefaultLocation = isLocationMatch(defaultLocation, locationData);
  const isCreativeFolder = !isDefaultLocation && locationData.user.raw.name === defaultLocation?.user.raw.name;

  const handleClick = (e) => {
    e.preventDefault();
    isSelected ? deselectClone(clone) : selectClone(clone);
  };

  const handleOnLoadCapture = (e) => {
    e.currentTarget.style.gridRowEnd = ` span ${Math.ceil(e.currentTarget.clientHeight / 10) + 3}`;
  };

  // TEXT
  const getCampaignTypeHeader = () => {
    if (isDefaultLocation) return defaultRepoName;
    if (isCreativeFolder) return 'Creative Folder'
    return 'Campaign Name'
  }
  const getCampaignName = () => {
    if (isDefaultLocation) return "";
    if (isCreativeFolder) return locationData?.nickname;
    return `${locationData?.nickname}`
  }

  const getCampaignTypeDescription = () => {
    if (isDefaultLocation || isCreativeFolder) return "";
    return locationData?.primaryLocation ? `Secondary Profile of ${clone.locationData.name}` : `Primary Profile of ${clone.locationData.name}`
  }

  if (!locationData) return null;

  return (
    <StyledCloneItem onLoadCapture={handleOnLoadCapture} isUnselectable={isUnselectable} isSelected={isSelected} isEdited={isEdited} bulkEditAction={bulkEditAction} onClick={(e) => handleClick(e)}>
      <div className="checkbox-wrapper">
        {isSelected && (
          <div className="clone_selected">
            <div className="checkbox-container" />
            <CheckIcon size="20" color="grey" />
            {`Selected to ${bulkEditAction[0].toUpperCase() + bulkEditAction.slice(1)}`}
          </div>
        )}
      </div>
      <img src={getPreviewImageUrl({preview, youTubeId})} alt={`creative ${id}`} />
      <div className="clone_item-info">
        {locationData && (
          <div className="clone_item-location">
            <p className="clone_item-header">{`${getCampaignTypeHeader()}:`}</p>
            <p className="clone_item_name">{getCampaignName()}</p>
            <p>{getCampaignTypeDescription()}</p>
          </div>
        )}
        {
          isDefaultLocation || isCreativeFolder ? (
           <p>{getFolderText(bulkEditAction, creativeType, isDefaultLocation ? defaultRepoName : `${locationData?.nickname} Folder`)}</p>
        ) : (
            userData && (
                <div className="clone_item-user">
                  <p className="clone_item-header">
                    {"Campaign Owner"}:
                  </p>
                  <p className='clone_item-email'>{isDefaultLocation ? "" : `${userData.email}`}</p>
                </div>
              )
         )
        }
      </div>
    </StyledCloneItem>
  );
};

export default CloneItem;