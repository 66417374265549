import React, { createContext, useContext } from "react"
import { RequestManager, requestManager } from "./RequestManager"

const RequestManagerContext = createContext<RequestManager | undefined>(undefined)

export const useRequestManager = () => {
	const manager = useContext(RequestManagerContext)
	if (!manager) {
		return requestManager({
			enabled: true,
		})
	}
	return manager
}

export const RequestManagerProvider = (props : {
	children: React.ReactNode,
	requestManager?: RequestManager
}) => {
	const manager = props.requestManager ?? requestManager()
	return (
		<RequestManagerContext.Provider value={manager}>
			{props.children}
		</RequestManagerContext.Provider>
	)
}