import {
    CLEAR_IMAGE_BUILDER, SELECTED_FILTER, TOGGLE_HEADER_FILTER, TOGGLE_DESCRIPTION_FILTER,
    HEADER_TEXT, DESCRIPTION_TEXT, TOGGLE_LOGO_FILTER, GET_SELECTED_IMAGE,
    GET_SELECTED_LOGO,
    GET_CANVAS, ZOOM_IN_AND_OUT, INITIAL_TEMPLATE, IMAGE_FILTERS, CLEAR_IMAGE_FILTERS, CANVAS_DIMENSIONS,
    CANVAS_BORDERS, CANVAS_HEADER_FONT_SIZE, CANVAS_DESCRIPTION_FONT_SIZE, GET_Z_INDEX, IS_Z_INDEX_SELECTED, GET_IN_MEMORY_IMG, CLEAR_SELECTED_ASSET,
    LOAD_VIDEO_EXTENDED_TEXT, TOGGLE_LOGO_DYNAMIC
} from '../actions/types';

const initialState = {
    tempFile: null,
    zIndex: {},
    isZindexSelected: false,
    postedHeaderFontSize: 0,
    postedDescriptionFontSize: 0,
    canvasBorder: false,
    canvasDimensions: { ty: 0, tx: 0, },
    imageFilters: 'brightness(1) contrast(1) blur(0px) grayscale(0) hue-rotate(0deg) invert(0) saturate(1) sepia(0)',
    headerText: '',
    descriptionText: '',
    selectedImage: null,
    selectedLogo: null,
    isImageSelected: false,
    isLogoSelected: false,
    extendedText: '',

    zooming: '',
    templateName: '',
    canvas: null,
    selectedFilter: 'image',
    headerFilters: {
        textColor: '#000000',
        textOpacity: 1,
        backgroundColor: [255, 255, 255],
        backgroundOpacity: '',
        justify: 'center',
        fontSize: '26',
        box: null,
    },

    descriptionFilters: {
        textColor: '#000000',
        textOpacity: 1,
        backgroundColor: [255, 255, 255],
        backgroundOpacity: 1,
        justify: 'center',
        fontSize: '26',
        box: null
    },
    logoFilters: {
        backgroundOpacity: 1,
        backgroundColor: [255, 255, 255],
        box: null
    },
    dynamicLogo: false
}

const buildImageReducer = (state = initialState, action) => {

    switch (action.type) {
        case CLEAR_IMAGE_BUILDER: return initialState;

        case GET_CANVAS: {

            return { ...state, canvas: action.payload }
        }
        case SELECTED_FILTER: {
            return { ...state, selectedFilter: action.payload }
        }
        case TOGGLE_HEADER_FILTER: {

            return { ...state, headerFilters: { ...state.headerFilters, [action.filter]: action.payload } }
        }
        case TOGGLE_DESCRIPTION_FILTER: {
            return { ...state, descriptionFilters: { ...state.descriptionFilters, [action.filter]: action.payload } }
        }

        case TOGGLE_LOGO_FILTER: {
            return { ...state, logoFilters: { ...state.logoFilters, [action.filter]: action.payload } }
        }
        case TOGGLE_LOGO_DYNAMIC: {
            return {...state, dynamicLogo: action.payload}
        }
        case HEADER_TEXT: {
            return { ...state, headerText: action.payload }
        }
        case DESCRIPTION_TEXT: {
            return { ...state, descriptionText: action.payload }
        }
        case GET_SELECTED_IMAGE: {
            return { ...state, isImageSelected: true, selectedImage: action.payload }
        }
        case GET_SELECTED_LOGO: {
            return { ...state, isLogoSelected: true, selectedLogo: action.payload }
        }

        case CLEAR_SELECTED_ASSET: {
            let type = action.payload;
            if(type === 'logo') return { ...state, isLogoSelected: false, selectedLogo: null }
            if(type === 'image') return { ...state, isImageSelected: false, selectedImage: null }
            return { ...state, isImageSelected: false, selectedImage: null, isLogoSelected: false, selectedLogo: null }
        }
        case IMAGE_FILTERS: {
            return { ...state, imageFilters: action.payload }
        }

        case CLEAR_IMAGE_FILTERS: {
            return { ...state, imageFilters: 'brightness(1) contrast(1) blur(0px) grayscale(0) hue-rotate(0deg) invert(0) saturate(1) sepia(0)' }
        }
        case CANVAS_DIMENSIONS: {
            return { ...state, canvasDimensions: action.payload }
        }
        case ZOOM_IN_AND_OUT: {
            return { ...state, zooming: action.payload }
        }
        case CANVAS_BORDERS: {
            return { ...state, canvasBorder: action.payload }
        }

        case CANVAS_DESCRIPTION_FONT_SIZE: {
            return { ...state, postedDescriptionFontSize: action.payload }
        }
        case CANVAS_HEADER_FONT_SIZE: {
            return { ...state, postedHeaderFontSize: action.payload }
        }

        case GET_Z_INDEX: {
            return { ...state, zIndex: action.payload }
        }
        case IS_Z_INDEX_SELECTED: {
            return { ...state, isZindexSelected: !state.isZindexSelected }
        }
        case  GET_IN_MEMORY_IMG: {
            return {...state, tempFile: action.payload}
        }
        case LOAD_VIDEO_EXTENDED_TEXT: {
            return {...state, extendedText: action.payload}
        }

        case INITIAL_TEMPLATE: {
            return {
                ...state,
                headerFilters: action.payload.headerBox,
                descriptionFilters: action.payload.headerDescriptionBox,
                logoFilters: action.payload.logoFilters,
                headerText: action.payload.headerText,
                descriptionText: action.payload.descriptionText,
                selectedImage: state.selectedImage || action.payload.image,
                selectedLogo: action.payload.logo,
                templateName: action.payload.templateName,
                extendedText: action.payload.extendedText
            }
        }
        default: {
            return state
        }
    }
}

export default buildImageReducer;