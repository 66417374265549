import React, { useState } from 'react'
import { useCreativeContext } from '../CreativeModalV2'
import { POST_NETWORK } from '../../../../helpers/creative.util'
import { BigBody, Body, HR, Small, XS } from '../../../../styles/typography'
import { DisclaimerContainer, MetricItemCard, MetricItemWrapper, MetricSummary, MetricsLifeTime, NetworkStatusItemWrap } from '../styles'
import NetworkIcon from '../NetworkIcon'
import { BsHourglassBottom } from 'react-icons/bs'
import StringUtil from '../../../../helpers/string.util'
import InfoTooltip from '../../../tooltip/InfoTooltip'


const PostMetrics = () => {
    const { creative, metadata } = useCreativeContext()
    const { isVideoPost } = metadata
    const metricCardWidth = isVideoPost ? '158px' : '205px';

    const SUMMARY_TYPE = {
        IMPRESSIONS: 'impressions',
        ENGAGEMENT: 'engagement',
        REACH: 'reach',
        VIEWS: 'views'
    }

    const [selectedSummary, setSelectedSummary] = useState(SUMMARY_TYPE.IMPRESSIONS)
    const { targetNetworks, totalImpressions, totalEngagedUsers, totalImpressionsUnique, totalVideoViews, postedNetworks } = creative

    const renderDataField = (network, metric) => {
        switch (metric) {
            case SUMMARY_TYPE.IMPRESSIONS:
                if (metadata.isVideoPost && network === POST_NETWORK.INSTAGRAM) return 'Impression data unavailable'
                return `${postedNetworks[network]?.impressions} impressions`
            case SUMMARY_TYPE.ENGAGEMENT:
                return `${postedNetworks[network]?.engagedUsers} engagements`
            case SUMMARY_TYPE.REACH:
                return `${postedNetworks[network]?.impressionsUnique} unique impressions`
            case SUMMARY_TYPE.VIEWS:
                return `${postedNetworks[network]?.videoViews} views`
            default:
                return 'No Data to Display'
        }
    }

    const summaryCards = [
        {
            name: 'Total Impressions',
            toolTip: 'Impressions represent the number of times your post entered someone’s screen.',
            value: totalImpressions,
            type: SUMMARY_TYPE.IMPRESSIONS,
        },
        {
            name: 'Total Engagement',
            toolTip: 'Engagements represent the number of people who clicked anywhere on your post.',
            value: totalEngagedUsers,
            type: SUMMARY_TYPE.ENGAGEMENT
        },
        {
            name: 'Total Reach',
            toolTip: 'Reach represents the number of people who had your post displayed to them.',
            value: totalImpressionsUnique,
            type: SUMMARY_TYPE.REACH
        },
        {
            name: 'Total Views',
            toolTip: 'Video views represent the number of times your video played for at least 3 seconds.',
            value: totalVideoViews,
            type: SUMMARY_TYPE.VIEWS,
            shouldHide: !isVideoPost
        }
    ].filter(val => !val.shouldHide)

    const lifeTimeValues = [
        {
            name: 'Facebook',
            value: renderDataField(POST_NETWORK.FACEBOOK, selectedSummary),
            icon: <NetworkIcon network={POST_NETWORK.FACEBOOK} />,
            shouldHide: !targetNetworks.includes(POST_NETWORK.FACEBOOK)
        },
        {
            name: 'X',
            value: renderDataField(POST_NETWORK.TWITTER, selectedSummary),
            icon: <NetworkIcon network={POST_NETWORK.TWITTER} />,
            shouldHide: !targetNetworks.includes(POST_NETWORK.TWITTER)
        },
        {
            name: 'Instagram',
            value: renderDataField(POST_NETWORK.INSTAGRAM, selectedSummary),
            icon: <NetworkIcon network={POST_NETWORK.INSTAGRAM} />,
            shouldHide: !targetNetworks.includes(POST_NETWORK.INSTAGRAM)
        },
        {
            name: 'LinkedIn',
            value: renderDataField(POST_NETWORK.LINKEDIN, selectedSummary),
            icon: <NetworkIcon network={POST_NETWORK.LINKEDIN} />,
            shouldHide: !targetNetworks.includes(POST_NETWORK.LINKEDIN)
        },
        {
            name: 'Google Business Profile',
            value: 'channel does not provide reporting',
            icon: <NetworkIcon network={POST_NETWORK.GMB} />,
            shouldHide: !targetNetworks.includes(POST_NETWORK.GMB)
        }
    ].filter(val => !val.shouldHide)


    const hasNoMetrics = (!totalEngagedUsers && !totalImpressions && !totalImpressionsUnique && !totalVideoViews)
    const hasNonGMBNetwork = targetNetworks.some(network => network !== POST_NETWORK.GMB)

    return (
        <MetricSummary>
            <BigBody semibold style={{ marginBottom: '18px' }}>Metrics Summary</BigBody>
            {
                hasNoMetrics && hasNonGMBNetwork ?
                    <DisclaimerContainer>
                        <BsHourglassBottom size={30} />
                        <Small>Reporting data is not yet available.</Small>
                    </DisclaimerContainer>
                    :
                    <>
                        <MetricItemWrapper>
                            {
                                summaryCards.map((summary, idx) => {
                                    return <MetricItemCard width={metricCardWidth} onClick={() => setSelectedSummary(summary.type)} isSelected={selectedSummary === summary.type} key={idx}>
                                        <div className='name-wrapper'>
                                            <Small className='title' semibold>{summary.name}</Small>
                                            <InfoTooltip tooltipContent={summary.toolTip} />
                                        </div>
                                        <Body bold>{summary.value}</Body>
                                    </MetricItemCard>
                                })
                            }
                        </MetricItemWrapper>
                        <MetricsLifeTime>
                            <Small semibold>Lifetime {StringUtil.capitalizeFirstLetter(selectedSummary)} Breakdown</Small>
                            {
                                lifeTimeValues.map((network, idx) => {
                                    return (
                                        <>
                                        <NetworkStatusItemWrap key={idx}>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                                                {network.icon}
                                                <Small semibold>{network.name}</Small>
                                            </div>
                                            <XS className='value'>{network.value}</XS>
                                        </NetworkStatusItemWrap>
                                        <HR spacing={0} />
                                        </>
                                    )
                                })
                            }
                        </MetricsLifeTime>
                    </>
            }
        </MetricSummary>
    )
}

export default PostMetrics