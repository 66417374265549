import { useDispatch, useSelector } from "react-redux";
import { hideOverlay, showOverlay } from "../../../actions/overlayActions";
import { API } from "../../../constants/api";
import { UPDATE_LOCATION_FIELD } from "../../../actions/types";
import { loadFranchise } from "../../../actions/franchiseActions";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { useAccess } from "../../../hooks/useAccess";

const useRunAudit = () => {
    const { isMember } = useAccess();

    const franchiseName = useSelector((state) => state.franchise.name);
    const locations = useSelector((state) => state.franchise.locations);

    const _D = useDispatch();

    const updateLocations = useCallback(async (locations) => {
        for (let i = 0; i < locations.length; i++) {
            _D(showOverlay(`Auditing campaign ${i + 1} of ${locations.length}...`))

            await new Promise((resolve) => {
                window.eulerity.makeApiCall(
                    `${API.LOCATION.GET}?uid=${locations[i].user.raw.name}&lid=${locations[i].id}`,
                    'GET',
                    null,
                    (resp) => {
                        _D({
                            type: UPDATE_LOCATION_FIELD,
                            payload: {
                                location: locations[i],
                                field: 'variableValues',
                                data: resp.variableValues,
                            },
                        });
                        resolve();
                    },
                    {
                        1000: () => {
                            toast.error('Failed to run audit on ' + locations[i].name);
                            resolve();
                        }
                    }
                );
            })

            _D(hideOverlay())
        }
    }, [_D]);

    const onRunAudit = useCallback(async (updateFranchise = true) => {
        // just reloads the stale data
        // don't need to reload any user data because it's not available to users

        if (!isMember() && updateFranchise) {
            _D(loadFranchise(franchiseName));
        }

        await updateLocations(locations);

        _D(hideOverlay());
    }, [franchiseName, locations, _D, isMember, updateLocations])

    return onRunAudit
};

export default useRunAudit;
