import ageColumns from "./age";
import costColumns from "./cost";
import deviceColumns from "./device";
import genderColumns from "./gender";

const distributionColumns = [
	...ageColumns,
	...costColumns,
	...deviceColumns,
	...genderColumns,
]

export default distributionColumns