import { API } from "../../../../../constants/api";
import { batchifyRequest, makeRequest } from "../../../../../helpers/make-request";
import { UserInfo } from "../../../../../types/user";
import { ReportInput, ReportRowInput } from "../reportBuilder";

export const userColumnLoader = (input: ReportInput) => {
    if (input.role === 'zee') {
        return [input.user as UserInfo]
    }

    return batchifyRequest(
        makeRequest()
            .url(API.FRANCHISE.LIST_USERS)
            .param('franchise', input.franchise.name)
    )
}
export const reportLoader = (input: ReportRowInput) => {
    return makeRequest()
        .url(API.REPORT.GET)
        .param('uid', input.location.user.raw.name)
        .param('lid', input.location.id)
        .retry(3)
}