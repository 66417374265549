import { FiInfo } from "react-icons/fi";
import styled from "styled-components";
import { createBoxShadow } from "../../styles/card";
import { TooltipTrigger } from "../../features/tooltip/TooltipTrigger";

// TODO - We'll want to move this to a general file, these will be our universal icon sizes (special cases to be given by design)
export enum IconSize {
    XS = 16,
    S = 20,
    M = 24,
    L = 32,
    XL = 56
}

// * This is our universal info icon that we use throughout the app - it is not needed to utilize the tooltip, but often paired with it.
export const InfoIcon = styled(FiInfo)<{size?: IconSize}>`
    height: ${props => props.size || IconSize.M};
    width: ${props => props.size || IconSize.M};
    color: ${props => props.theme.color.accentColor2};
    cursor: pointer;
`



type StyledTooltipTriggerProps = {
    maxWidth?: string;
    fontSize?: string;
    style?: React.CSSProperties;
  }

  const StyledTooltipTrigger = styled(TooltipTrigger)<StyledTooltipTriggerProps>`
    .tooltip-content {
      background: ${(props) => props.theme.color.backgroundColor2} !important;
      color: ${(props) => props.theme.color.textColor1} !important;
      box-shadow: ${createBoxShadow};
      opacity: 1 !important;
      z-index: 1;
      max-width: ${(props) => props.maxWidth || 'fit-content'};
      border-radius: 6px;
      font-weight: 400;
      padding: 10px !important;
      font-size: ${(props) => props.fontSize || 'inherit'};
    }
  `;

  export default StyledTooltipTrigger;