import { useState, useEffect } from "react";
import { RESTRICTIONS } from "../../../constants/restrictions";
import { CREATIVE_STATUS } from "../../../helpers/creative.util";
import { useAccess } from "../../../hooks/useAccess";
import useCreativeActions from "../../../hooks/useCreativeActions";
import { useCreativeContext } from "../CreativeModalV2/CreativeModalV2";
import { useCreativeMetadata } from "./useCreativeMetadata";
import { useDispatch } from "react-redux";
import { hideOverlay, showOverlay } from "../../../actions/overlayActions";
import { BULK_ACTION } from "../../../helpers/bulkEdits";
import { toast } from "react-toastify";

export const useCreativeActionState = () => {
    const _D = useDispatch()
    const [isAccepting, setIsAccepting] = useState(false)
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [isResumeWarningOpen, setIsResumeWarningOpen] = useState(false);
    const [isPauseWarningOpen, setIsPauseWarningOpen] = useState(false);

    const { editCreative, deleteCreative, resumeCreative, pauseCreative, BulkEditor } = useCreativeActions();
    const { isRestricted, showRestrictedMessage } = useAccess()
    const { creative, refreshCreatives, needsApproval, onClose, setIsCloneModalOpen, setView } = useCreativeContext()
    const metadata = useCreativeMetadata(creative)
    const { isFolder, location, isDeployedAd, isMagicEntity, creativeKindLabel } = metadata
    const { fStatus, status } = creative
    const toggleStatuses = ['PAUSED', 'ACTIVE']
    const isActiveOrPaused = toggleStatuses.includes(status) || (isFolder && toggleStatuses.concat([CREATIVE_STATUS.UNREVIEWED]).includes(fStatus));
    const showToggle = (isDeployedAd || isFolder) && isActiveOrPaused && (status !== CREATIVE_STATUS.REJECTED);

    // * Pause Handling
    const handlePlayPause = () => {
        if (isRestricted(RESTRICTIONS.CREATIVE_STATUS)) {
            showRestrictedMessage()
            return
        }
        if (isPaused) {
            setIsPaused(false)
            setIsResumeWarningOpen(true);
        } else {
            setIsPaused(true)
            setIsPauseWarningOpen(true)
        }
    }

    const closeResumePopup = () => {
        setIsPaused(true);
        setIsResumeWarningOpen(false)
    }

    const closePausePopup = () => {
        setIsPaused(false);
        setIsPauseWarningOpen(false);
    }

    const handleManageClones = () => {
        setView("manage")
    }

    // * Initialize Toggle Paused Status
    useEffect(() => {
        const statusToCheck = isFolder ? fStatus : status;
        if (statusToCheck === CREATIVE_STATUS.PAUSED) setIsPaused(true);
        if (statusToCheck === CREATIVE_STATUS.ACTIVE) setIsPaused(false);
        if (isFolder && statusToCheck === CREATIVE_STATUS.UNREVIEWED) setIsPaused(false);
    }, [status, fStatus])

    const onAcceptAll = () => {
        _D(showOverlay('Loading Bulk Accept'))
        BulkEditor.edit({
            action: BULK_ACTION.ACCEPT,
            callback: () => { _D(hideOverlay()) },
            noClonesCallback: () => {
                _D(hideOverlay())
                toast.error('Error finding creative to accept')
             },
             creative
        })
    }

    const onDeleteAll = () => {
        BulkEditor.edit({
            action: BULK_ACTION.DELETE,
            callback: () => { },
            noClonesCallback: () => {
                toast.error('Error finding creative to delete')
            },
            creative
        })
    }

    const onPauseAll = () => {
        BulkEditor.edit({
            action: BULK_ACTION.PAUSE,
            callback: () => { },
            noClonesCallback: () => { toast.error('Error finding creative to pause') },
            creative
        })
    }

    const onResumeAll = () => {
        BulkEditor.edit({
            action: BULK_ACTION.RESUME,
            callback: () => { },
            noClonesCallback: () => { toast.error('Error finding creative to resume') },
            creative
        })
    }

    // Access any one of these properties with _S.{whatever}
    return {
        BulkEditor,
        closePausePopup,
        closeResumePopup,
        creative,
        creativeKindLabel,
        deleteCreative,
        editCreative,
        handlePlayPause,
        handleManageClones,
        isAccepting,
        isDeleteWarningOpen,
        isDeployedAd,
        isFolder,
        isMagicEntity,
        isPaused,
        isPauseWarningOpen,
        isResumeWarningOpen,
        location,
        needsApproval,
        onAcceptAll,
        onDeleteAll,
        onPauseAll,
        onResumeAll,
        onClose,
        pauseCreative,
        refreshCreatives,
        resumeCreative,
        setIsAccepting,
        setIsCloneModalOpen,
        setIsDeleteWarningOpen,
        setIsPaused,
        setIsPauseWarningOpen,
        setIsResumeWarningOpen,
        showToggle,
    }
}