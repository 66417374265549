import styled from 'styled-components';

export const StyledHeader = styled.div`
   margin-bottom: 1.5rem;
   @media (max-width: 1177px) {
      flex-direction: column;
    }
`

export const MainContainer = styled.main`
  flex-grow: 1;
  row-gap: 14px;
  background: ${props => props.theme.color.backgroundColor1};
`

export const ContentDisplay = styled.div`
  padding-top: 38px;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns:
    [full-start] minmax(10px, 1fr) [center-start] repeat(
      11,
      [col-start] minmax(min-content, 100px) [col-end]
    )
    [center-end] minmax(10px, 1fr) [full-end];

  & > * {
    grid-column: center-start / center-end;
  }

  .display > div {
    min-height: 100vh;
  }
`

export const AppLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
`

export const disabledAppStyles = {pointerEvents: 'none', opacity: '0.2'} as const