import Popup from "reactjs-popup";
import styled, {css} from "styled-components";
import { decideTextColor } from "../../helpers/colors.util";
import { Card } from "../../styles/card";
import { BigBody, XS } from "../../styles/typography";

const inputStyle = ({
    background: 'radial-gradient(124.11% 124.11% at 50% 0%, rgba(205, 214, 246, 0.3) 39.58%, rgba(177, 177, 177, 0.3) 100%)',
    borderRadius: '9px',
})

export const BlueprintsContainer = styled(Card)`
    padding: 40px 48px;
    margin: 0 auto;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 200px);
    grid-gap: 0.5rem;
    margin-top: 24px;
`;

export const FolderWrapper = styled.div`
    border: 1px solid ${props => props.theme.color.greyAccent2};
    border-radius: 6px;
    padding: 31px 23px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ListCard = styled.div<{isSelected: boolean}>`
    background: ${props => props.theme.color.backgroundColor2};
    color: ${props => decideTextColor(props.theme.color.backgroundColor2)};
    border: 1px solid ${props => props.theme.color.accentColor3};
    border-radius: 6px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 4px;

    ${({ isSelected }) => isSelected && css`
        background: ${props => props.theme.color.accentColor4};
        color: ${props => decideTextColor(props.theme.color.accentColor4)};

        button {
            border: 1px solid ${props => decideTextColor(props.theme.color.accentColor4)};
            background: ${props => props.theme.color.backgroundColor2};
            color: ${props => decideTextColor(props.theme.color.accentColor4)};
        }
    `}

    .image {
        max-width: 50px;
        max-height: 50px;
    }

    .hideIcon {
        visibility: hidden;
    }
`;

export const StyledPopup = styled(Popup)`
    // move the whole thing up since the triangle is gone
    &-content {
        transform: translateY(-15px);
    }
    // do not display the little triange (per design)
    &-arrow {
    display: none;
    }
`;

export const BreadcrumbDropdown = styled.div`
    background-color: ${props => props.theme.color.backgroundColor2};
    border: 1px solid ${props => props.theme.color.greyAccent1};
    border-radius: 9px;
    z-index: 40;
`;

export const BreadcrumbButton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-radius: 9px;
    justify-content: flex-start;
    padding: 3px 20px 3px 10px;
    align-items: center;
    cursor: pointer;
    :hover {
        background-color: ${props => props.theme.color.greyAccent2};
    }
`;

export const NoImage = styled.div<{size?: string}>`
    width: ${props => props.size ?? '120px'};
    height: ${props => props.size ?? '120px'};
    background-color: ${props => props.theme.color.accentColor4};
`;

export const Label = styled(BigBody)`
    padding-right: 10px;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const SubLabel = styled(XS)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const BlueprintWrapper = styled.div`
    border: 1px solid ${props => props.theme.color.accentColor2};
    border-radius: 9px;
    padding: 20px 40px;
    margin: 20px auto;
    position: relative; //! Needed for Alert
`;

// BlueprintStepInfoContainer
export const BlueprintInfoContainer = styled.div`
    input, textarea {
        ${inputStyle}
    }
`;

export const InfoImageContainer = styled.div`
    display: flex;
    margin: 60px auto;
    margin-bottom: 30px;
    width: 616px;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
`;

export const InfoContainer = styled.div`
    padding: 25px;
    width: 100%;

    input {
        margin-bottom: 10px;
    }
`;

export const InfoInputContainer = styled.div`
    margin: 60px auto;
    width: 616px;
    input {
        margin-bottom: 10px;
    }
`;

export const BlueprintToggleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
`;

export const BlueprintToggle = styled.div<{offStyle?: string, isToggleOn: boolean}>`
    cursor: pointer;
    width: 70px;
    height: 35px;
    background: red;
    border-radius: 20px;;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    background-color: ${props => props.theme.color.accentColor2};
    ${({ offStyle }) => offStyle === 'disabled' && css`
        background-color: ${props => props.theme.color.greyAccent1};
    `}
    ${({ isToggleOn }) => isToggleOn && css`
        background-color: ${props => props.theme.color.activeButtonColor};
    `}
`;

export const BlueprintToggleBall = styled.div<{isToggleOn: boolean}>`
    width: 25px;
    height: 25px;
    margin-left: 0.3rem;
    display: block;
    border-radius: 100px;
    position: relative;
    background-color: ${props => props.theme.color.backgroundColor2};
    transition: all 0.1s;
    transform: translateX(0);

    ${({ isToggleOn }) => isToggleOn && css`
        transform: translateX(140%);
    `}
`;

export const BlueprintToggleButtonContainer = styled.div<{offStyle?: string, isToggleOn: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        color: ${props => props.theme.color.accentColor2};
        ${({ offStyle }) => offStyle === 'disabled' && css`
        color: ${props => props.theme.color.greyAccent1};
        `}

        ${({ isToggleOn }) => isToggleOn && css`
            color: ${props => props.theme.color.activeButtonColor};;
        `}
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;

    h1 {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div {
        display: flex;
        justify-content: flex-end;

        button {
            width: 180px;
            height: 40px;
            font-size: 14px;
            margin-left: 10px;
            white-space: nowrap;
        }
    }
`;

// Create Blueprint

export const GridItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 7fr;
    margin: 20px 0;
`;

export const GridInfoItem = styled.div`
    display: flex;
    gap: 10px;

    div {
        width: 100%;
    }

    img {
        max-width: 80px;
        max-height: 80px;
        object-fit: contain;
    }
`;

export const CreativeImg = styled.span`
    img, video, iframe {
        height: 50px;
        width: 50px;
        margin: 0 10px 10px 0;
        object-fit: cover;
    }
`;

export const CreativesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

// Summary
export const BlueprintIconWrapper = styled.div<{fillColor: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({fillColor}) => fillColor ?? 'transparent'};
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    svg {
        color: ${props => props.theme.color.accentColor2};
    }
`
export const BlueprintDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr 20px;
    margin: 30px 0;
    grid-gap: 30px;
    position: relative;
`;

// Targeting
export const TargetingContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const TargetingItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding: 0.5rem 0px;
    align-items: center;

    div {
        display: flex;
        align-items: center;
    }
`;

// Channels
export const ChannelsContainer = styled.div`
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        object-fit: contain;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

// Creatives Folder
export const CreativesListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const CreativeImgContainer = styled.div`
    img, video, iframe {
        height: 100px;
        width: 100px;
        margin: 0 10px 10px 0;
        object-fit: cover;
    }
`;

export const LoadMoreTemp = styled.div`
    width: 100px;
    height: 100px;
    border: 1px solid ${props => props.theme.color.accentColor2};
    border-radius: 9px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        color: ${props => props.theme.color.accentColor2};
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const CreativeFolderBtnContainer = styled.div`
    button {
        width: 200px !important;
    }
`;

//Type
export const BlueprintTypeOptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0px;
    gap: 40px;
`

const hoverOptionStyle = css`
    border-color: ${props => props.theme.color.accentColor3};
    background: ${props => props.theme.color.accentColor4};
    font-weight: bold;
`
export const BlueprintTypeOption = styled.div<{isSelected: boolean}>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 238px;
    height: 237px;
    padding: 40px 20px;
    border: 1px solid ${props => props.theme.color.accentColor3};
    border-radius: 4px;
    cursor: pointer;
    svg {
        width: 3.5rem;
        height: 3.5rem;
        margin-top: 10px;
        margin-bottom: 20px;
        path {
            stroke: ${(props) => props.theme.color.textColor1};
        }
    }

    :hover {
        ${hoverOptionStyle}
    }

    ${({ isSelected }) => isSelected && hoverOptionStyle}
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
`

//* Blueprint Label Step
export const BlueprintLabelDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.5rem;
`

export const BlueprintLabelInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1.5rem;
`