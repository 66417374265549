import { useDispatch, useSelector } from 'react-redux';
import { LoadStatus } from '../../../types/primitives';
import { useEffect } from 'react';
import { API } from '../../../constants/api';
import {
    loadNetworkLoginConnections,
    setNetworkLoginConnections,
    setNetworkLoginLoadStatus,
} from '../../../actions/userActions';
import { ResponseTypes } from '../../../types/apiResponses';

export const useNetworkLoginConnections = () => {
    const _D = useDispatch();
    const networkLoginConnections = useSelector((s) => s.user.networkLogins);
    const status: LoadStatus = useSelector((s) => s.user.networkLoginLoadStatus);

    useEffect(() => {
        if (status !== 'unloaded') return;

        _D(loadNetworkLoginConnections());
    }, [status, _D]);

    return {
        networkLoginConnections: networkLoginConnections as
            | ResponseTypes[typeof API.USER.LIST_NETWORK_LOGIN_CONNECTIONS]['paginatedData']
            | null,
        status,
        refresh: () => {
            _D(setNetworkLoginConnections(null));
            _D(setNetworkLoginLoadStatus('unloaded'));
        },
    };
};
