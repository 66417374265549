import { useLayoutEffect, useState } from "react";
import { gsap } from "gsap";

const useDropDownAnimation = (mainClass, childrenClass, options) => {
  const [mainAnimation, setMainAnimation] = useState(null);
  const [childrenAnimation, setChildrenAnimation] = useState();
  const [status, setStatus] = useState(null)

  /*
    DOC
    This Hook creates animation for any dropdown
    Usage pass two classes the main class and the childClass

    This hook exposes one method and one propery the function acts like a button and the status is the status tells if the dropdown is open

    initial css values for the mainClass
      visibility: hidden;
      height: 0rem;

    Ask @taulant-eulerity for any implementation details
*/

useLayoutEffect(() => {
    if(options?.startAsOpen && status===null) setStatus(true)
    let childrenAnim, dropAnimation;
    childrenAnim = gsap
      .timeline({ onReverseComplete: () => {
        dropAnimation.reverse()
      } })
      .fromTo(`.${childrenClass}`, {visibility: "hidden", opacity: 0, x: -10 , y: options.marginTop}, {visibility: "visible", duration: 0.1, opacity: 1, x: 0, y: options.marginTop })
      .reverse();
    dropAnimation = gsap
      .timeline({ onComplete: () => childrenAnim.play() })
      .fromTo(`.${mainClass}`, { height: 0, visibility: "hidden" },  {height: options.height, visibility: "visible" , duration: 0.1})
      [options.startAsOpen ? 'play' : 'reverse']();

    setMainAnimation(dropAnimation);
    setChildrenAnimation(childrenAnim);
  }, []);



  const handleDropDownAnimation = () => {
    setStatus(prew => !prew)
    if(mainAnimation.reversed()) {
      mainAnimation.play()
    } else  childrenAnimation.reverse()
  }

  return { handleDropDownAnimation, status };
};

export default useDropDownAnimation;
