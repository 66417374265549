import { useSelector } from "react-redux"
import { hasActiveSubscription, isLocationKeyMatch, getLocationKey } from "../helpers"
import { getLocationUserEmail } from "../features/locations/util"

// * This hook is used for initializing the pre-selected locations and repo's for the build flow (when editing)

const useInitialSelection = (filters = {}) => {
    // * Selectors
    const repoList = useSelector(state => state.repo.repoList)
    const locations = useSelector(state => state.franchise.locations)
    const defaultLocation = useSelector(state => state.franchise.defaultLocation)
    const memberList = useSelector(state => state.franchise.memberList)

    const getRepoFromKey = (key, repoList) => {
        return repoList.find(r => isLocationKeyMatch(key, r))
    }

    const getDefaultLocation = () => {
        return {
            ...defaultLocation,
            category: 'Creative Folders',
            displayName: "Default Campaign Creative Folder",
            label: "Default Campaign Creative Folder",
            type: 'repo',
            value: `${defaultLocation.user.raw.name}_${defaultLocation.id}`,
        }
    }

    const getInitialSelection = (targetLocations) => {
        const filteredLocations = filters.locationFilter ? locations.filter(filters.locationFilter) : locations
        const locationsToAutoSelect = filteredLocations.filter(l => targetLocations.includes(getLocationKey(l, '_')))
            .map(location => {
                return {
                    ...location,
                    category: location?.primaryLocation ? 'Secondary Campaigns' : 'Primary Campaigns',
                    displayName: location.nickname,
                    email: getLocationUserEmail(location, memberList),
                    isActive: hasActiveSubscription(location),
                    label: `${location.nickname} (${location.name})`,
                    status: location?.subscriptions.length ? location?.subscriptions[0]?.status : 'DRAFT',
                    type: 'location',
                    value: `${location.user.raw.name}_${location.id}`,
                }
            })

        const defaultLocationToAutoSelect = defaultLocation && targetLocations.includes(getLocationKey(defaultLocation, '_')) ? [getDefaultLocation()] : []

        let foldersToAutoSelect = defaultLocation && targetLocations.filter(t => {
            const [uid, lid] = t.split('_')
            return uid === defaultLocation.user.raw.name && parseInt(lid) !== defaultLocation.id;
        }).map(folderKey => {
            return {
                ...getRepoFromKey(folderKey, repoList),
                category: 'Creative Folders',
                displayName: getRepoFromKey(folderKey, repoList)?.nickname || folderKey,
                label: getRepoFromKey(folderKey, repoList)?.nickname || folderKey,
                type: 'repo',
                value: folderKey,
            }
        })

        return [...locationsToAutoSelect, ...defaultLocationToAutoSelect, ...(foldersToAutoSelect || [])]
    }

    return {
        getInitialSelection
    }

}

export default useInitialSelection