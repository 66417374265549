import React, { useEffect, useState } from "react";
import getSuperTableComponents from "../../../../components/SuperTable/getSuperTableComponents";
import { JobModalType, JobTasksResponse } from "../../types";
import { XS } from "../../../../styles/typography";
import useMember from "../../../../hooks/useMember";
import { makeRequest } from "../../../../helpers/make-request";
import { API } from "../../../../constants/api";
import DisplayMedia from "../../../creatives/CreativeModalV2/DisplayMedia";
import { CreativeInformationWrapper } from "../../../creatives/CreativeModalV2/ManageClones";
import { useTheme } from "styled-components";
import { isAdEntity, isPostEntity } from "../../../../helpers/creative.util";
import { PostInformation } from "../../../creatives/CreativeModalV2/Post/ManagePostClones";
import { AdInformation } from "../../../creatives/CreativeModalV2/Ad/ManageAdClones";
import { Ad, Creative } from "../../../creatives/creative.types";
import { useSelector } from "react-redux";
import { Loader } from "../../../../components/waitScreens/Loader";
import { CenterElement } from "../../../../components/reusableUI/components/CenterElement";
import { EulerityLocation } from "../../../../types/location";
import { hexToRgba } from "../../../../helpers/colors.util";
import ModalV2 from "../../../../components/modal/ModalV2/ModalV2";
import { fetchFirstSuccessfulResponse } from "../../utils";

const CreativesJobModal = ({ jobResponse, onClose, type: title }: { jobResponse: JobTasksResponse[], onClose: () => void, type: JobModalType | null }) => {
    const { Wrap, Column } = getSuperTableComponents<JobTasksResponse>();
    const locations = useSelector((state) => state.franchise.locations);
    const [loading, setLoading] = useState<boolean>(true)
    const [creative, setCreative] = useState<Creative | null>(null);
    const franchiseLoading = useSelector(s => s.franchise.loading)
    const { getMember } = useMember()
    const theme = useTheme()


    const getCreativeOwner = (response: JobTasksResponse) => {
        try {
            const { uid } = JSON.parse(response.queryParams)
            return getMember(uid)?.email;
        } catch (error) {
            console.error("Couldn't parse json")
        }
    }

    const getCampaignMetaData = (queryParam: string, index: number) => {

        if (franchiseLoading) {
            return !index
                ? <div style={{ width: '100px', height: '16px', display: 'grid', placeContent: 'center' }}><Loader /></div>
                : null
        }

        try {
            const { uid, lid } = JSON.parse(queryParam)
            const location = locations.find((loc: EulerityLocation) => loc.id === lid && uid === loc?.user?.raw?.name)
            return <XS>{location.name} ({location.nickname})</XS>
        } catch (error) {
            console.error("Couldn't parse json")
        }
    }

    const CreativeInformation = ({ creative }: { creative: Creative | null }) => {
        if (!creative) return <></>

        if (isPostEntity(creative)) {
            return <PostInformation creative={creative} />
        }

        if (isAdEntity(creative)) {
            return <AdInformation creative={creative as Ad} />
        }

          throw new Error('Unknown creative type')
    }

    useEffect(() => {
        (async () => {
            const listOfQueryParams = []
            for(let jobR of jobResponse) {
                try {
                    const parsed = JSON.parse(jobR.queryParams)
                    listOfQueryParams.push(parsed)
                } catch(_) {}
            }

            const baseRequest = makeRequest().url(API.CREATIVE.GET)
            try {
                const creative = await fetchFirstSuccessfulResponse(listOfQueryParams, baseRequest)
                setCreative(creative)
            } catch (error) {
                console.error('Something went wrong getting the creative')
            } finally {
                setLoading(false)
            }
        })()
    },[jobResponse])


    return (
        <ModalV2 size="full" onClose={onClose} title={title || ""}>
            {
                loading ? <CenterElement style={{ height: '10rem' }}><Loader /></CenterElement> : (
                    creative && (
                        <CreativeInformationWrapper style={{ padding: 0, marginBottom: '40px' }}>
                        <div style={{
                            height: '10rem', width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2rem',
                        }}>
                            <DisplayMedia creative={creative}
                                style={{
                                    height: '10rem',
                                    width: '10rem',
                                    backgroundColor: theme.color.greyAccent2,
                                }} />
                            <CreativeInformation creative={creative} />
                        </div>
                    </CreativeInformationWrapper>
                    )
                )
            }

            <div style={{
                minHeight: '50px',
                maxHeight: '350px',
                overflow: 'scroll',
                boxShadow: `inset 0 10px 6px -6px ${hexToRgba(theme.color.accentColor3, 0.2)}`
            }}>
                <Wrap items={jobResponse}>
                <Column title='NAME'>
                        {(
                            (lineItem, index) => getCampaignMetaData(lineItem.queryParams, index)
                        )}
                    </Column>

                    <Column title='USER'>
                        {(
                            lineItem => <XS>{getCreativeOwner(lineItem)}</XS>
                        )}
                    </Column>

                    <Column title='FAILURE REASON'>
                        {(
                            lineItem => <XS>{lineItem.failureMessage || "Failed to be updated"}</XS>
                        )}
                    </Column>
                </Wrap>
            </div>
        </ModalV2>
    )
}


export default CreativesJobModal