import styled from "styled-components";

export const StyledSubscriptionForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

export const StyledSectionWrapper = styled.div`
    background-color: ${props => props.theme.color.accentColor4};
    border-radius: 8px;
    padding: 16px 24px;
`

export const StyledPaymentWrapper = styled.div`

    .option-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`

export const StyledPaymentCard = styled.div`
    background-color: ${props => props.theme.color.backgroundColor2};
    padding: 16px 24px;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.color.accentColor3};
    gap: 10px;
    display: flex;
    align-items: center;

    .icon-container {
        align-self: flex-start;
        margin-right: 18px;
    }
`

export const StyledBudgetWrapper = styled.div`

    .budget-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .budget-wrapper {
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .slider-wrapper {
        width: 454px;
    }

    .budget-estimate {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 198px;
        width: 280px;
        padding: 24px;
        background: ${props => props.theme.color.backgroundColor2};
        border: 1px solid ${props => props.theme.color.accentColor3};
        border-radius: 8px;
    }

    .promo-code {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`

export const StyledRadioButton = styled.input`
    height: 24px;
    width: 24px;
    accent-color: ${props => props.theme.color.accentColor2};
    cursor: pointer;
`

export const StyledTermsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .tos-item {
        display: flex;
        gap: 12px;
        align-items: center;
    }
`