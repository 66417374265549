import { combineReducers } from "redux";
import franchiseReducer from "./franchiseReducer";
import userReducer from "./userReducer";
import franchiseListReducer from "./franchiseListReducer";
import resourceReducer from "./resourceReducer";
import buildReducer from "./buildReducer";
import buildImageReducer from "./buildImageReducer";
import overlayReducer from "./overlayReducer";
import audienceReducer from "./audienceReducer";
import emailReducer from "./emailReducer";
import smsReducer from './smsReducer';
import repoReducer from "./repoReducer";
import corporateSettingReducer from "./corporateSettingReducer";
import tokensReducer from './tokensReducer'
import utilsReducer from './utilsReducer'
import UIReducer  from './UIReducer';
import calendarReducer from "./calendarReducer";
import summariesReducer from "./summariesReducer";
import advancedFiltersReducer from "./advancedFiltersReducer";

export default combineReducers({
  overlay: overlayReducer,
  user: userReducer,
  franchise: franchiseReducer,
  franchiseList: franchiseListReducer,
  resources: resourceReducer,
  build: buildReducer,
  buildImage: buildImageReducer,
  repo: repoReducer,
  emails: emailReducer,
  sms: smsReducer,
  audiences: audienceReducer,
  corporateSettings: corporateSettingReducer,
  tokens: tokensReducer,
  utilsReducer,
  ui:UIReducer,
  calendar: calendarReducer,
  summaries: summariesReducer,
  advancedFilters: advancedFiltersReducer
});
