import { toast } from "react-toastify"
import { RESTRICTIONS } from "../../../constants/restrictions"
import { useAccess } from "../../../hooks/useAccess"
import { checkCanCreateSubscription, isDraftOrCanceledLocation } from "../../../pages/locations/modals/settings/subscription/subscription.util"
import { LOCATION_SETUP } from "../../../constants/urlPath"
import { useHistory } from "react-router-dom"
import { EulerityLocation } from "../../../types/location"
import SubscriptionUtil from "../subscription.utils"

type UseSubscriptionAccessProps =  {
    createSubscriptionRedirect: string, // Where to go after creating a subscription
    updateSubscriptionRedirect: string // Where to go to update the subscription
}

// Validation and handling for user access to subscription features
const useSubscriptionAccess = ({ createSubscriptionRedirect, updateSubscriptionRedirect }: UseSubscriptionAccessProps) => {
    const { isRestricted, showRestrictedMessage, isMember } = useAccess()
    const history = useHistory()

    const goToLocationSubscriptionSetting = async (location: EulerityLocation) => {
        const { subscriptions } = location
        const isDraftLocation = !subscriptions?.length

        if (SubscriptionUtil.isCorporateManagedSub(subscriptions?.[0]) && isRestricted(RESTRICTIONS.VIEW_SUBSCRIPTION)) {
            return showRestrictedMessage()
        }

        if (isDraftLocation) {
            const canCreateSubscription = await checkCanCreateSubscription()
            const userError = 'Subscription creation is unavailable at this time. Contact your corporate admin.'
            const managerError = 'Subscription creation is unavailable at this time. Direct inquiries to support@eulerity.com.'

            if (!canCreateSubscription) {
                return toast.error(isMember() ? userError : managerError)
            }
        }

        // Send them to the Subscription Creation Flow and pass the origin
        if (isDraftOrCanceledLocation(location)) {
            history.push({
                pathname: LOCATION_SETUP.SUBSCRIPTION,
                state: { createSubscriptionRedirect }
            })
        } else {
            // Send them to the Update Subscription Flow
            history.push(updateSubscriptionRedirect)
        }
    }

  return (
    {
        goToLocationSubscriptionSetting
    }
  )
}

export default useSubscriptionAccess