import React from 'react'
import { BulkCreativeSelectorWrapper, StyledBulkCreativeSelector, StyledCreativeItem } from './styles'
import { H2, Small, Span, XS } from '../../../styles/typography'
import DisplayMedia from '../CreativeModalV2/DisplayMedia'
import { getCreativeKindLabel, isPostEntity } from '../../../helpers/creative.util'
import CheckBox from '../../../components/checkbox/CheckBox'
import { IoMdImage } from 'react-icons/io'
import { useThemeControl } from '../../../hooks/useThemeControl'
import useExperiment from '../../../hooks/useExperiment'
import { EXPERIMENTS } from '../../../helpers/experimentHelpers'

const BulkCreativesSelector = ({selectedCreatives, setSelectedCreatives, creatives}) => {
    const blockPostsOnSecondariesExp = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)
    const containPost = creatives.some(c => isPostEntity(c))
    const { getCreativeKindColor } = useThemeControl()

    const handleSelectAll = () => setSelectedCreatives([...creatives])
    const handleDeselectAll = () => setSelectedCreatives([])
    const handleSingleSelect = (creative) => {

        // * Deselection
        if (selectedCreatives.find(selectedCreative => selectedCreative?.websafe === creative?.websafe)) {
            const filteredCreatives = selectedCreatives.filter(selectedCreative => selectedCreative?.websafe !== creative?.websafe)
            setSelectedCreatives([...filteredCreatives])
            return
        }

        setSelectedCreatives(prev => [...prev, creative])
    }

    return (
        <BulkCreativeSelectorWrapper>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <H2>Select Creatives</H2>
                <div className='select-wrapper'>
                    {
                        selectedCreatives?.length !== creatives?.length &&
                        <Small bold style={{ cursor: 'pointer' }} color='accentColor2' onClick={handleSelectAll}>Select All</Small>
                    }
                    {
                        selectedCreatives?.length > 0 &&
                        <Small bold style={{ cursor: 'pointer' }} color='accentColor2' onClick={handleDeselectAll}>Deselect All</Small>
                    }
                </div>
            </div>
            <XS>Select all campaigns you want to clone your creative to. {blockPostsOnSecondariesExp.active && containPost ? "Keep in mind: Social post creatives can only be applied to primary campaigns. Please double check your selections before proceeding." : ""}</XS>
            <StyledBulkCreativeSelector>
                {
                    creatives.map((creative, idx) => {
                        const isChecked = selectedCreatives.some(selectedCreative => selectedCreative?.websafe === creative?.websafe)

                        return <StyledCreativeItem key={idx} isChecked={isChecked} kind={creative?.kind}>
                            <div className='item-header'>
                                <IoMdImage size={24} style={{color: getCreativeKindColor(creative?.kind)}}/>
                                <Small>{getCreativeKindLabel(creative)}</Small>
                            </div>
                            <div
                                style={{position: 'relative', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSingleSelect(creative)
                                }}
                            >
                                <div className='checkbox-wrapper'>
                                    <CheckBox
                                        size='large'
                                        checked={isChecked}
                                    />
                                </div>
                                <div className='item-media'>
                                    <DisplayMedia creative={creative} />
                                </div>
                            </div>
                        </StyledCreativeItem>
                    })
                }
            </StyledBulkCreativeSelector>
            <div className='counter-wrapper'>
                <Small><Span bold>{selectedCreatives?.length}</Span> of {creatives?.length} creatives selected</Small>
            </div>
        </BulkCreativeSelectorWrapper>
    )
}

export default BulkCreativesSelector