import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { adOrPostFork, setUrlPath, selectBuilderLocation, selectCustomOrPremadeFork, selectFork, selectYoutubeOrUploadFork, setBreadCrumbPathBulk, setCreationType, setLastEdited, setUploadedMediaData, setYoutubeUrl, setMagicOrTemplateFork } from "../actions/buildActions";
import { clearSelectedAssets, getLoadCreative, getSelectedImage, getSelectedLogo, getVdeoLoadCreative } from "../actions/buildImageActions";
import { getRepoList } from "../actions/repoActions";
import { buildMemberLocation } from "../helpers/build.util";
import { BULK_ACTION, filterEditableClones, getCreativeClones } from "../helpers/bulkEdits";
import { adOrPostController, getCreativeLocationID, getCreativeUserID, isAdEntity, isDeployedAd, isDeployedPost, isImageCreative, isPostEntity, isVideoCreative } from "../helpers/creative.util";
import { useAccess } from "./useAccess";
import { RESTRICTIONS } from "../constants/restrictions";
import { useLocation } from "react-router-dom";
import { createBreadCrumbsFromEdit } from "../pages/build/newForks/fork.util";
import { getLocationByKey, getLocationKeyRaw } from "../helpers";
import { FORKS as _F } from "../constants/fork";
import { PATH } from "../constants/urlPath";
import { API } from "../constants/api";
import useExperiment from "./useExperiment";
import { EXPERIMENTS } from "../helpers/experimentHelpers";

/**
 * This hook exposes helpful creative actions that are reused throughout the FCC
 * This way we do not need to keep passing so many props into such functions
 * whenever, for example, we need deleteCreative functionality within multiple components
 */
const useCreativeActions = () => {
    const { isRestricted, isMember, canManageFranchise, showRestrictedMessage } = useAccess()
    const memberList = useSelector(state => state.franchise.memberList);
    const franchiseName = useSelector(state => state.franchise.name);
    const franchiseLoading = useSelector(state => state.franchise.loading);
    const locations = useSelector(state => state.franchise.locations);
    const defaultLocation = useSelector(state => state.franchise.defaultLocation);
    const summaries = useSelector(state => state.summaries.memberSummaries)
    const repoList = useSelector(state => state.repo.repoList)
    const blockPostsOnSecondariesExp = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)

    const [ bulkEditOptions, setBulkEditOptions ] = useState(null);

    const _D = useDispatch();
    const history = useHistory();
    const URLpath = useLocation()

    // withUser because the summary object doesn't typically have a "user" prop
    const getLocationSummaryWithUser = ({ location }) => {
        const websafe = location?.websafe;
        const userID = location?.user.raw.name;

        const group = summaries.find((summ) => summ.id === userID);
        if (!group) return {};

        const clone = { ...group };
        delete clone.locations;

        let summary = group.locations.find((loc) => loc.websafe === websafe);
        if (!summary) return {};

        summary = {
            ...summary,
            user: clone,
        };

        return summary;
    };

    const getCreativeUser = ({creative}) => {
        if (!creative || !memberList?.length) return;
        const userData = memberList?.find(m => m.id === getCreativeUserID(creative))
        return userData
    }

    const getCreativeLocation = ({creative}) => {
        let locs = [...(locations || []), ...(repoList || [])]

        if(defaultLocation){
            locs.push(defaultLocation)
        }

        return locs.find(loc => loc.websafe === creative?.location?.websafe) || {}
    }

    const handleEdit = ({ creative, location, nickname, forkFunction, reject, resolve}) => {
            const _IS_AD_ENTITY = isAdEntity(creative);
            const _IS_POST_ENTITY = isPostEntity(creative);

            // Check Restrictions
            if (_IS_AD_ENTITY && isRestricted(RESTRICTIONS.CREATIVE_EDIT)) {
                showRestrictedMessage();
                return reject();
            }

            if (_IS_POST_ENTITY && isRestricted(RESTRICTIONS.POST_EDIT)) {
                showRestrictedMessage();
                return reject();
            }

            const { image, logo, premade, preview, media, kind, isMagic, youTubeId } = creative;

            _D(setUrlPath(URLpath.pathname));
            _D(adOrPostFork(adOrPostController(kind)));
            _D(
                setBreadCrumbPathBulk(
                    createBreadCrumbsFromEdit({
                        nickname,
                        origin: URLpath.pathname,
                        youTubeId,
                        kind,
                        isMagic,
                        premade,
                        isMember: isMember(),
                    })
                )
            );
            // Handle Images
            if (isImageCreative(creative)) {
                forkFunction?.();
                _D(setUploadedMediaData(null));
                // load the assets for the preview
                image ? _D(getSelectedImage(image)) : _D(clearSelectedAssets('image'));
                logo ? _D(getSelectedLogo(logo)) : _D(clearSelectedAssets('logo'));
                _D(getLoadCreative(creative));

                // load the correct path
                premade
                    ? _D(selectCustomOrPremadeFork(_F.PREMADE_CREATIVE))
                    : _D(selectCustomOrPremadeFork(_F.CUSTOM_CREATIVE));
                _IS_POST_ENTITY
                    ? _D(selectFork(_F.SOCIAL_IMAGE))
                    : _D(selectFork(_F.DYNAMIC));
                _D(selectBuilderLocation(buildMemberLocation(location || locations[0])));
                _D(setLastEdited({ ...creative, locationData: location }));
                history.push(PATH.CREATE_IMAGE);
            }

            // Handle Videos
            if (isVideoCreative(creative)) {
                // YOUTUBE FLOW
                forkFunction?.();
                if (creative.youTubeId) {
                    _D(selectYoutubeOrUploadFork(_F.YOUTUBE_LINK));
                    _D(setUploadedMediaData(null));
                    _D(
                        setYoutubeUrl(`https://www.youtube.com/watch?v=${creative.youTubeId}`)
                    );
                } else {
                    // UPLOAD MEDIA FLOW
                    _D(
                        setUploadedMediaData({
                            gif: preview === media ? preview : null,
                            media: preview !== media ? media : null,
                            thumbnail: preview !== media ? preview : null,
                        })
                    );
                    _D(selectYoutubeOrUploadFork(_F.VIDEO_UPLOAD));
                }

                _IS_POST_ENTITY
                    ? _D(selectFork(_F.SOCIAL_VIDEO))
                    : _D(selectFork(_F.DYNAMIC_VIDEO));
                _D(selectBuilderLocation(buildMemberLocation(location || locations[0])));
                _D(getVdeoLoadCreative(creative));
                _D(setLastEdited({ ...creative, locationData: location }));
                history.push(PATH.CREATE_VIDEO);
                resolve()
            }
    }

    const _isFolder = (creative, overrideLocation) =>  {
        const location = overrideLocation || getCreativeLocation({creative})
        const locationSummary = getLocationSummaryWithUser({
            location
        })
        const _IS_DEFAULT_FOLDER = canManageFranchise() && locationSummary.websafe === defaultLocation.websafe
        const _IS_OTHER_FOLDER = canManageFranchise() && !_IS_DEFAULT_FOLDER && locationSummary?.user?.id === defaultLocation?.user?.raw?.name
        return _IS_DEFAULT_FOLDER || _IS_OTHER_FOLDER
    }

    // * CREATIVE ACTIONS
    // 1. EDIT
    const _editCreative = ({ creative, overrideLocation }) => {
        return new Promise((resolve, reject) => {
            const location = overrideLocation || getCreativeLocation({creative})
            const handleMagicForks = () => {
                if (isMember()) {
                    _D(setMagicOrTemplateFork(null));
                } else {
                    _D(setMagicOrTemplateFork(creative.isMagic  ? 'magic' : null));
                    _D(setCreationType(creative.isMagic ? _F.OPTIONAL_CREATIVE : _F.AUTOMATIC_CREATIVE));
                }
            };

            const locationKey = getLocationKeyRaw(creative.location.raw)
            const nickname = getLocationByKey(locations, locationKey)?.nickname || ''

            handleEdit({ creative, location, nickname, forkFunction: handleMagicForks, reject, resolve })

        });
    };

    // EDIT A TEMPLATE:
    // Open the editing flow and set some flags such that we can edit and then create the new creative
    const _editTemplate = ({ creative, folderName }) => {
        return new Promise((resolve, reject) => {
            const handleTemplateFork = () => {
                _D(setMagicOrTemplateFork('template'));
                _D(setCreationType(_F.AUTOMATIC_CREATIVE)); // we no not want these to be optional ever
            };

            handleEdit({ creative: {...creative, isMagic: false}, location: null, nickname: folderName, forkFunction: handleTemplateFork, reject, resolve})
        });
    };

    // 2. DELETE
    const _deleteCreative = ({creative, suppressToast = false}) => {
        return new Promise((resolve, reject) => {

            if(!franchiseName || !creative) {
                console.error('Missing delete creative params');
                return reject();
            }

            const _IS_DEPLOYED_AD = isDeployedAd(creative);
            const _IS_DEPLOYED_POST = isDeployedPost(creative);

            if (
                (_IS_DEPLOYED_AD && isRestricted(RESTRICTIONS.CREATIVE_STATUS)) ||
                (_IS_DEPLOYED_POST && isRestricted(RESTRICTIONS.POST_DELETE))
            ) {
                showRestrictedMessage();
                return reject();
            }

            window.eulerity.makeApiCall({
                url: `${API.CREATIVE.DELETE}?franchise=${encodeURIComponent(franchiseName)}&location=${creative.location.websafe}&${creative.qsParam}=${creative.id}`,
                method: 'POST',
                callback: () => {
                    if (!suppressToast) toast.success("Deleted successfully.");
                    resolve()
                },
                errorCallbacks: {
                    1000: error => {
                        let messageObj = JSON.parse(error.response)
                        if (!suppressToast) toast.error(messageObj.message)
                        reject(error)
                    }
                }
            })
        })
    }

    const _jobDeleteCreatives = ({creative}) => {
        if(!franchiseName || !creative) return

        if ((isDeployedAd(creative) && isRestricted(RESTRICTIONS.CREATIVE_STATUS)) ||
            (isDeployedPost(creative) && isRestricted(RESTRICTIONS.POST_DELETE))) {
            return
        }
        return {
            url: API.CREATIVE.DELETE,
            method: 'POST',
            queryParams: {
                uid: creative?.location?.raw?.parentKey?.name,
                lid: creative?.location?.raw?.id,
                franchise: franchiseName,
                [creative.qsParam]: creative.id
            },
            body: {},
            description: 'delete creative'
        }
    }

    // 3. PAUSE
    const _pauseCreative = ({creative, overrideLocation, suppressToast = false }) => {
        return new Promise((resolve, reject) => {

            const uid = getCreativeUserID(creative)
            const lid = getCreativeLocationID(creative)

            if(_isFolder(creative, overrideLocation)) {
                window.eulerity.makeApiCall({
                    url: `${API.FRANCHISE.DEACTIVATE_CREATIVE}?franchise=${encodeURIComponent(franchiseName)}&uid=${uid}&lid=${lid}&${creative.qsParam}=${creative.id}`,
                    method: 'POST',
                    callback: () => {
                        if (!suppressToast) toast.success(`This creative has been paused. It will not be copied over to any new onboarding campaigns.`)
                        resolve()
                    },
                    errorCallbacks: {
                        1000: (error) => {
                            if (!suppressToast) toast.error('Uh oh! For optimal campaign performance, new campaigns will need to have at least 4 running dynamic ads.')
                            reject(error)
                        }
                    }
                })
            } else {
                window.eulerity.makeApiCall({
                    url: `${API.CREATIVE.PAUSE}?uid=${creative.location.raw.parentKey.name}&lid=${creative.location.raw.id}&${creative.qsParam}=${creative.id}`,
                    method: 'POST',
                    callback: () => {
                        if (!suppressToast) toast.success('Your creative has been paused');
                        resolve()
                    },
                    errorCallbacks: {
                        1000: (error) => {
                            if (!suppressToast) toast.error('Uh oh! For optimal campaign performance, there must be at least 4 running dynamic ads.')
                            reject(error)
                        }
                    }
                })
            }
        })
    }

    const _jobPauseCreatives = ({creative, overrideLocation}) => {
        if(_isFolder(creative, overrideLocation)) {
            console.log("Deactivate,", API.FRANCHISE.DEACTIVATE_CREATIVE)
            return {
                url: API.FRANCHISE.DEACTIVATE_CREATIVE,
                method: 'POST',
                queryParams: {
                    uid: getCreativeUserID(creative),
                    lid: getCreativeLocationID(creative),
                    franchise: encodeURIComponent(franchiseName),
                    [creative.qsParam]: creative.id
                },
                body: {},
                description: 'deactivate creative'
            }
        }

        return {
                url: API.CREATIVE.PAUSE,
                method: 'POST',
                queryParams: {
                    uid: creative.location.raw.parentKey.name,
                    lid: creative.location.raw.id,
                    [creative.qsParam]: creative.id},
                body: {},
                description: 'pause creative'
        }
    }

    // 4. RESUME
    const _resumeCreative = ({creative, overrideLocation = undefined, suppressToast = false }) => {

        return new Promise((resolve, reject) => {

            const uid = getCreativeUserID(creative)
            const lid = getCreativeLocationID(creative)

            if(_isFolder(creative, overrideLocation)) {
                window.eulerity.makeApiCall({
                    url: `${API.FRANCHISE.ACTIVATE_CREATIVE}?franchise=${encodeURIComponent(franchiseName)}&uid=${uid}&lid=${lid}&${creative.qsParam}=${creative.id}`,
                    method: 'POST',
                    callback: () => {
                        if (!suppressToast) toast.success(`This creative is now active. It will be copied over to any new onboarding campaigns.`)
                        resolve()
                    },
                    errorCallbacks: {
                        1000: (error) => {
                            let messageObj = JSON.parse(error.response);
                            if (!suppressToast) toast.error(messageObj.message)
                            reject(error)
                        },
                    }
                })
            } else {
                window.eulerity.makeApiCall({
                    url: `${API.CREATIVE.RESUME}?uid=${creative.location.raw.parentKey.name}&lid=${creative.location.raw.id}&${creative.qsParam}=${creative.id}`,
                    method: 'POST',
                    callback: () => {
                        if (!suppressToast) toast.success('Your creative is now active')
                        resolve()
                    },
                    errorCallbacks: {
                        1000: (error) => {
                            let messageObj = JSON.parse(error.response);
                            if (!suppressToast) toast.error(messageObj.message)
                            reject(error)
                        },
                    }
                })
            }
        })

    }

    const _jobResumeCreative = ({creative, overrideLocation}) => {


        if(_isFolder(creative, overrideLocation)) {
            return {
                url: API.FRANCHISE.ACTIVATE_CREATIVE,
                method: 'POST',
                queryParams: {
                    uid: getCreativeUserID(creative),
                    lid: getCreativeLocationID(creative),
                    franchise: encodeURIComponent(franchiseName),
                    [creative.qsParam]: creative.id
                },
                body: {},
                description: 'activate creative'
            }
        }

        return {
                url: API.CREATIVE.RESUME,
                method: 'POST',
                queryParams: {
                    uid:  getCreativeUserID(creative),
                    lid: getCreativeLocationID(creative),
                    [creative.qsParam]: creative.id},
                body: {},
                description: 'resume creative'
        }
    }
    // 5. ACCEPTING A CREATIVE
    // Generalize function to open the accepting flow for optional ads and templates
    // Open the accepting flow and set the needed flags to customize the flow
    const _startAcceptingTemplate = ({ creative, inRepo }) => {
        _D(getLoadCreative(creative));
        _D(setMagicOrTemplateFork((creative.isMagic && !inRepo) ? 'magic' : 'template'))
    }

    // ACCEPT A MAGIC
    const _acceptMagic = ({ creative, data }) => {
        return new Promise((resolve, reject) => {
            window.eulerity.makeApiCall({
                url: `${API.CREATIVE.ACCEPT_MAGIC}?franchise=${encodeURIComponent(franchiseName)}&location=${creative.location.websafe}&${creative.qsParam}=${creative.id}`,
                method: 'POST',
                obj: data,
                callback: () => {
                    resolve()
                },
                errorCallbacks: {
                    1000: (error) => {
                        reject(error)
                    },
                }
            })
        })
    }

 const _jobAcceptCreative = ({creative, data}) => {
    return {
        url: API.CREATIVE.ACCEPT_MAGIC,
        method: 'POST',
        queryParams: {
            location: creative.location.websafe,
            franchise: encodeURIComponent(franchiseName),
            [creative.qsParam]: creative.id
        },
        body: data,
        description: 'activate creative'
    }
 }


    // 6. REVIEW
    const _reviewCreative = ({creative, approved, reasonId, description}) => {
        return new Promise((resolve, reject) => {
            window.eulerity.makeApiCall({
                url: `${API.FRANCHISE.REVIEW}?franchise=${encodeURIComponent(franchiseName)}&approved=${approved}&location=${creative.location.websafe}&${creative.qsParam}=${creative.id}&reasonid=${reasonId}&description=${escape(description)}`,
                method: 'POST',
                callback: () => {
                    resolve()
                },
                errorCallbacks: {
                    1000: (error) => {
                        reject(error)
                    },
                }
            })
        })
    }

    // 7. BULK EDIT
    const _bulkEditCreative = ({creative, action, callback, noClonesCallback}) => {
        if (franchiseLoading) {
            toast.error('Campaigns are currently being loaded. Please try again shortly.')
            return;
        }

        const _IS_DEPLOYED_AD = isDeployedAd(creative);
        const _IS_DEPLOYED_POST = isDeployedPost(creative);

        // Check User Restrictions. Pause and Resume are checked the same way
        switch (action) {
            case BULK_ACTION.DELETE:
                if (
                    (_IS_DEPLOYED_AD && isRestricted(RESTRICTIONS.CREATIVE_STATUS)) ||
                    (_IS_DEPLOYED_POST && isRestricted(RESTRICTIONS.POST_DELETE))
                ) {
                    showRestrictedMessage();
                    return;
                }
                break;
            case BULK_ACTION.ACCEPT:
                // No restrictions for accepting optional creatives
                break;
            case BULK_ACTION.PAUSE:
            case BULK_ACTION.RESUME:
                if (isRestricted(RESTRICTIONS.CREATIVE_STATUS)) {
                    showRestrictedMessage();
                    return;
                }
                break;
            default:
                console.error('Bulk action not supported: ' + action);
                return;
        }

        // Open the Modal
        openBulkEditModal({
            creative,
            action,
            callback,
            errorCallback: noClonesCallback
        })
    }

    const openBulkEditModal = ({ creative, action, callback, errorCallback }) => {
        // After getting all the necessary data, decide which clones are editable
        const dataReadyCallback = (clonesList, repoList = []) => {
            filterEditableClones({
                action,
                memberList,
                locations,
                defaultLocation,
                repoList,
                creative: creative,
                clones: clonesList,
                errorCallback,
                blockPostsOnSecondariesExp,
                callback: editableClones => {
                    setBulkEditOptions({
                        action,
                        clones: editableClones
                    })
                    callback?.()
                }
            })
        }

        // Get the necessary data:
        // 1. The clones obviously!
        // 2. and then the repos (if this user can manage folders aka repos)
        getCreativeClones({
            creative: creative,
            franchiseName,
            callback: clonesResponse => {
                if (canManageFranchise()) {
                    _D(
                        getRepoList(franchiseName, repoList => {
                            dataReadyCallback(clonesResponse, repoList)
                        })
                    )
                } else {
                    dataReadyCallback(clonesResponse)
                }
            }
        })
    }


    return {
        // SINGLE ACTIONS
        editCreative: _editCreative,
        deleteCreative: _deleteCreative,
        jobDeleteCreatives: _jobDeleteCreatives,
        pauseCreative: _pauseCreative,
        jobPauseCreatives: _jobPauseCreatives,
        jobResumeCreative: _jobResumeCreative,
        resumeCreative: _resumeCreative,
        acceptMagic: _acceptMagic,
        jobAcceptCreative: _jobAcceptCreative,
        reviewCreative: _reviewCreative,
        // UTIL
        getCreativeUser,
        getCreativeLocation,
        // BULK EDIT ACTIONS
        BulkEditor: {
            edit: _bulkEditCreative,
            options: bulkEditOptions,
            setOptions: setBulkEditOptions
        },
        startAcceptingTemplate: _startAcceptingTemplate,
        editTemplate: _editTemplate,
    }
}

export default useCreativeActions