export type ParentTarget = "t" | "f"
export type ParentTargetLabel = "Parent" | "Not Parent"

export const parentTargetCopy: {
    [key in ParentTarget]: ParentTargetLabel
} = {
    t: "Parent",
    f: "Not Parent"
} as const

export const getParentTargets = (parentTargets: ParentTarget[]): ParentTarget[] => {
    if (!parentTargets || !parentTargets.length) {
        return Object.keys(parentTargetCopy) as ParentTarget[]
    }

    return parentTargets
}

export const getParentTargetLabel = (target: ParentTarget) => {
    return parentTargetCopy[target]
}