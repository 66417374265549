import { useEffect, useState } from 'react'

import FacebookPreview from "../components/previews/FacebookPreview";
import InstagramPreview from "../components/previews/InstagramPreview";
import WebsitePreview from "../components/previews/WebsitePreview";
import SearchPreview from "../components/previews/SearchPreview";
import YouTubePreview from "../components/previews/YouTubePreview";
import ResizedAdsPreview from "../components/previews/ResizedAdsPreview";
import TwitterPreview from "../components/previews/TwitterPreview";
import { CREATIVE_KIND } from '../helpers/creative.util';
import LinkedInPreview from '../components/previews/LinkedInPreview';
import GoogleBusinessPreview from '../components/previews/GoogleBusinessPreview';
import { IMAGE_POST_NETWORKS, VIDEO_POST_NETWORKS } from '../pages/build/modals/steps/CreativeStepNetworks';

const useAdPreviewSlides = (creative, networks, location) => {
    // * Preview Variables
    const facebookPreview = { Component: FacebookPreview, type: 'facebook' }
    const instagramPreview = { Component: InstagramPreview, type: 'instagram' }
    const websitePreview = { Component: WebsitePreview, type: 'display' }
    const searchPreview = { Component: SearchPreview, type: 'search' }
    const resizeAdsPreview = { Component: ResizedAdsPreview, type: 'resized' }
    const youtubePreview = { Component: YouTubePreview, type: 'youtube' }
    const twitterPreview = { Component: TwitterPreview, type: 'twitter' }
    const linkedInPreview = { Component: LinkedInPreview, type: 'linkedin' }
    const googleMyBusinessPreview = { Component: GoogleBusinessPreview, type: 'gmb'}

    //* IMAGE AND VIDEO POSTS
    const socialPostPreviews = [facebookPreview, instagramPreview, twitterPreview, linkedInPreview, googleMyBusinessPreview]
                                .filter(p => {
                                    let availableSocialNetworks = creative?.kind === CREATIVE_KIND.POST ? IMAGE_POST_NETWORKS : VIDEO_POST_NETWORKS
                                    return availableSocialNetworks.includes(p.type)
                                })

    const [previewData, setPreviewData] = useState([]);
    const [slideNumber, setSlideNumber] = useState(0);

    const goLeft = () => slideNumber === 0 ? setSlideNumber(-100 * (previewData.length - 1)) : setSlideNumber(slideNumber + 100);
    const goRight = () => slideNumber === -100 * (previewData.length - 1) ? setSlideNumber(0) : setSlideNumber(slideNumber - 100);


    //* Preview Handling
    const handleDynamicAdPreviews = () => {
        let displayList = []
        const isPremade = creative.premade;

        if (location?.weight?.map) {
            // * Extract weight key value pairs
            const { AdwordsDisplayCampaign, AdwordsDisplayRemarketingCampaign, AdwordsSearchCampaign, AdwordsVideoCampaign, FacebookNewsCampaign, FacebookNewsRemarketingCampaign, FacebookVideoCampaign, FacebookVideoRemarketingCampaign, LinkedInCampaign, LinkedInVideoCampaign } = location.weight.map
            // * Adds Facebook and Instagram Previews
            if (FacebookNewsCampaign > 0 || FacebookNewsRemarketingCampaign > 0 ) {
                displayList.push(facebookPreview)
                displayList.push(instagramPreview)
            }

            // * Adds Search Preview
            if (AdwordsSearchCampaign > 0) displayList.push(searchPreview)

            // * Adds LinkedIn Preview
            if (LinkedInCampaign > 0) displayList.push(linkedInPreview)

            // * Adds Web and Resize Previews
            if (AdwordsDisplayCampaign > 0 || AdwordsDisplayRemarketingCampaign > 0) {
                displayList.push(websitePreview)
                displayList.push(resizeAdsPreview)
            }

            // * If displayList is empty check Video Ad Weights
            if (!displayList.length) {

                // * Adds Facebook and Instagram Previews
                if (FacebookVideoCampaign > 0 || FacebookVideoRemarketingCampaign > 0) {
                    displayList.push(facebookPreview)
                    displayList.push(instagramPreview)
                }

                // * Adds Search Preview
                if (AdwordsVideoCampaign > 0) displayList.push(searchPreview)

                // * Adds LinkedIn Preview
                if ( LinkedInVideoCampaign > 0) displayList.push(linkedInPreview)

                // * If display list is still empty (meaning all video weights are 0), use all previews
                if (!displayList.length) {
                    displayList.push(facebookPreview)
                    displayList.push(instagramPreview)
                    displayList.push(searchPreview)
                    displayList.push(linkedInPreview)
                    displayList.push(websitePreview)
                    displayList.push(resizeAdsPreview)
                }
            }
        } else {
            // * Use all previews (when location.weight.map is null)
            displayList.push(facebookPreview)
            displayList.push(instagramPreview)
            displayList.push(searchPreview)
            displayList.push(linkedInPreview)
            displayList.push(websitePreview)
            displayList.push(resizeAdsPreview)
        }

        // * Remove resizedAdPreviews if ad is premade
        if (isPremade) displayList = displayList.filter(preview => preview.type !== 'resized')
        setPreviewData(displayList)
    }

    const handleVideoAdPreviews = () => {
        let displayList = []
        const isYoutubeVideo = creative?.youTubeId

        if (location?.weight?.map) {
            const { AdwordsSearchCampaign, AdwordsVideoCampaign, FacebookNewsCampaign, FacebookNewsRemarketingCampaign, FacebookVideoCampaign, FacebookVideoRemarketingCampaign, LinkedInCampaign, LinkedInVideoCampaign } = location.weight.map

            // * Adds Facebook and Instagram Previews
            if (FacebookVideoCampaign > 0 || FacebookVideoRemarketingCampaign > 0) {
                displayList.push(facebookPreview)
                displayList.push(instagramPreview)
            }

            if (LinkedInVideoCampaign > 0) {
                displayList.push(linkedInPreview)
            }

            // * Adds Youtube Preview
            if (isYoutubeVideo && AdwordsVideoCampaign > 0) displayList.push(youtubePreview)

            // * If displayList is empty check dynamic Ad weights
            if (!displayList.length) {

                // * Adds Facebook and Instagram Previews
                if (FacebookNewsCampaign > 0 || FacebookNewsRemarketingCampaign > 0 ) {
                    displayList.push(facebookPreview)
                    displayList.push(instagramPreview)
                }

                // * Adds Youtube Preview
                if (isYoutubeVideo && AdwordsSearchCampaign > 0) displayList.push(youtubePreview)

                // * Adds LinkedIn Preview
                if ( LinkedInCampaign > 0) displayList.push(linkedInPreview)

                // * If displayList length is still empty, add all valid previews
                if (!displayList.length) {
                    displayList.push(facebookPreview)
                    displayList.push(instagramPreview)
                    displayList.push(linkedInPreview)

                    // * Adds Youtube Preview
                    if (isYoutubeVideo) displayList.push(youtubePreview)
                }
            }
        } else {
            // * If location.weight.map is null - add all valid previews
            displayList.push(facebookPreview)
            displayList.push(instagramPreview)
            displayList.push(linkedInPreview)

            // * Adds Youtube Preview
            if (isYoutubeVideo) displayList.push(youtubePreview)
        }
        setPreviewData(displayList)
    }

    const handlePostPreviews = () => {
        // No networks specified - show all post previews
        if (!networks?.length) {
            setPreviewData(socialPostPreviews);
        } else {
            // Only show specified networks
            let networksFilter = typeof networks === 'string' ? networks.split(',') : networks;
            const filteredPreviews = socialPostPreviews.filter(preview => networksFilter?.includes(preview.type))
            setPreviewData(filteredPreviews)
        }
        setSlideNumber(0);
    }

    // *When Networks Change - Change the list of post previews
    useEffect(() => {
        handlePostPreviews();
    }, [networks])

    // *Initialize Previews
    useEffect(() => {
        if (!creative) return

        switch (creative.kind) {
            case CREATIVE_KIND.CREATIVE: {
                handleDynamicAdPreviews()
                return;
            }
            case CREATIVE_KIND.VIDEO_CREATIVE: {
                handleVideoAdPreviews()
                return;
            }
            case CREATIVE_KIND.POST:
            case CREATIVE_KIND.VIDEO_POST: {
                handlePostPreviews()
                return;
            }
            default: {
                console.error('Error Initializing Previews')
                setPreviewData([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creative]);

    return { previewData, slideNumber, goLeft, goRight }
}

export default useAdPreviewSlides;
