import { PERMISSIONS } from '../constants/restrictions';

//! --- DEPRECATED. USE USEACCESS HOOK FOR THIS LOGIC
// Permission Helper Checks

export const isMember = accessLevel => {
    return PERMISSIONS.MEMBER.includes(accessLevel);
}

export const canSelectFranchise = accessLevel => {
    return PERMISSIONS.FRANCHISE_SELECT.includes(accessLevel)
}

export const canManageFranchise = accessLevel => {
    return PERMISSIONS.FRANCHISE_MANAGEMENT.includes(accessLevel)
}

export const hasRestriction = (user, restriction) => {
    if (!isMember(user.accessLevel)) return false
    return user.disabledClientPermissions?.includes(restriction)
}
