import { ModalSize } from "./types";

export const getModalSize = (size : ModalSize) => {
  switch (size) {
    case 'sm': return {
      width : '544px',
      minHeight: '211px',
    };
    case 'md': return {
      width: '736px',
      minHeight: '222px',
    };
    case 'lg': return {
      width: '928px',
      minHeight: '222px',
    };
/**
 * @deprecated the 'full' size modal specs will be deprecated
 */
    case 'full': return {
      width: '1120px',
      minHeight: '222px',
    };
    default: return {
      width: 'auto',
      height: 'auto'
    }
  }
}

export const getPadding = (hasNav : boolean) => {
  return { padding: `${hasNav ? "56px 48px 40px" : "40px 48px"}`}
}