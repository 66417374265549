import { SELECT_FORK, SELECT_CUSTOM_OR_PREMADE_FORK, SET_YOUTUBE_URL,
    SET_BUILDER_LOCATION, SET_BUILDER_LOGOS,
     CLEAR_BUILD_TAB, SET_UPLOADED_MEDIA_DATA, SELECT_YOUTUBE_OR_UPLOAD_FORK,
     SET_LAST_EDITED, AD_OR_POST_FORK, CREATION_TYPE, SET_BREADCRUMB_PATH,REMOVE_BREADCRUMB_PATH, SET_URL_PATH, SET_BREADCRUMB_BULK, SET_BUILDER_FRANCHISE_IMAGES, MAGIC_OR_TEMPLATE_FORK, SET_SELECTED_REPO_KEY} from './types'


export const clearBuildTab = () => dispatch => {
    dispatch({
        type: CLEAR_BUILD_TAB
    })
}
export const setYoutubeUrl = (value) => dispatch => {
        dispatch({
            type: SET_YOUTUBE_URL,
            payload: value
        })
}

export const setUploadedMediaData = (mediaData) => dispatch => {
    dispatch({
        type: SET_UPLOADED_MEDIA_DATA,
        payload: mediaData
    })
}

export const selectFork = fork => dispatch => {
    dispatch({
        type: SELECT_FORK,
        payload: fork
    })
}

export const adOrPostFork = fork => dispatch => {
    dispatch({
        type: AD_OR_POST_FORK,
        payload: fork
    })
}

export const setMagicOrTemplateFork = value => dispatch => {
    dispatch({
        type: MAGIC_OR_TEMPLATE_FORK,
        payload: value
    })
}

export const setCreationType = (value) => dispatch => {
    dispatch({
        type:  CREATION_TYPE,
        payload: value,
    })
}

export const selectCustomOrPremadeFork = fork => dispatch => {
    dispatch({
        type: SELECT_CUSTOM_OR_PREMADE_FORK,
        payload: fork,
    })
}
export const selectYoutubeOrUploadFork = fork => dispatch => {
    dispatch({
        type:  SELECT_YOUTUBE_OR_UPLOAD_FORK,
        payload: fork,
    })
}

export const selectBuilderLocation = location => dispatch => {
    // For franchisees this allows us to select which location to use
    // for suggesting images, logos, text, etc as they do not have access to franchise wide data
    dispatch({
        type: SET_BUILDER_LOCATION,
        payload: location
    })
}

export const setFranchiseImages = images => dispatch => {
    dispatch({
        type: SET_BUILDER_FRANCHISE_IMAGES,
        payload: images
    })
}

export const setBuilderLogos = logos => dispatch => {
    dispatch({
        type: SET_BUILDER_LOGOS,
        payload: logos
    })
}

export const setBreadCrumbPath = (path, skipException = false) => dispatch  => {
    dispatch({
        type: SET_BREADCRUMB_PATH,
        payload: {path, skipException}
    })
}
export const setBreadCrumbPathBulk = (breadcrumbs) => dispatch => {
    dispatch({
        type: SET_BREADCRUMB_BULK,
        payload: breadcrumbs
    })
}


export const removeBreadCrumbPath = (path) =>  dispatch => {
    dispatch({
        type: REMOVE_BREADCRUMB_PATH,
        payload: path
    })
}

export const setUrlPath = (path) => dispatch => (
    dispatch({
        type: SET_URL_PATH,
        payload: path
    })
)

// LAST EDITED
export const setLastEdited = creative => dispatch => {
    dispatch({
        type: SET_LAST_EDITED,
        payload: creative
    })
}

export const setSelectedRepoKey = selectedRepoKey => dispatch => {
    dispatch ({
        type: SET_SELECTED_REPO_KEY,
        payload: selectedRepoKey
    })
}