import { StyledDatePickerBtn } from './styles'
import React, { useState, useEffect } from 'react'
import { _C } from './constants'
import { toast } from 'react-toastify'
import { FiCalendar } from 'react-icons/fi'
import styled from 'styled-components'
import { StyledDatePicker } from './StyledDatePicker'

const StyledCalendar = styled(FiCalendar)`
    stroke: ${({ theme }) => theme.color.accentColor2};
    fill: none !important;
`

export type DatePickerData = {
    selectedStartDate: Date
    selectedEndDate: Date
    setStartDate: (date: Date) => void
    setEndDate: (date: Date) => void
    showMonthYearPicker?: boolean
    isModal?: boolean
    maxInToTheFuture?: Date
    howFarBackOnTime?: Date
}

type ReportsDatePickerProps = {
    datePickerData: DatePickerData
    startText?: React.ReactNode
    endText?: React.ReactNode
    wrapStyle?: React.CSSProperties
    buttonStyle?: React.CSSProperties
}

const ReportsDatePicker = ({
    datePickerData,
    startText,
    endText,
    wrapStyle = {},
    buttonStyle = {},
}: ReportsDatePickerProps) => {
    let _S = datePickerData
    const [selectedPicker, setPicker] = useState<string | null>(null)

    useEffect(() => {
        const click = (e: Event) => {
            if (!e.target || !('id' in e.target)) return
            const id = e.target.id
            if (id === 'btn' || id === 'svg') return
            setPicker(null)
        }
        const DOMobject = _S?.isModal
            ? document.querySelector('#modal')
            : document
        if (!DOMobject) return
        DOMobject.addEventListener('click', click)
        return () => {
            DOMobject.removeEventListener('click', click)
        }
    }, [_S?.isModal])

    const MAX_DATE = _S?.maxInToTheFuture
    const MIN_DATE = _S?.howFarBackOnTime

    const handleSetStartDate = (date: Date) => {
        if (date.getTime() >= _S?.selectedEndDate.getTime())
            return toast.error("Can't select higher than end date.")
        _S?.setStartDate(date)
    }

    const handleSetEndDate = (date: Date) => {
        if (date.getTime() <= _S?.selectedStartDate.getTime())
            return toast.error("Can't select lower than start date.")
        _S?.setEndDate(date)
    }

    const CustomDatePickerButton = ({
        picker,
        onClick,
    }: {
        picker: string
        onClick?: React.ComponentProps<typeof StyledDatePickerBtn>['onClick']
    }) => {
        const date =
            picker === _C.START_DROPDOWN
                ? _S?.selectedStartDate
                : _S?.selectedEndDate
        let _value = date?.toLocaleDateString()
        if (_S?.showMonthYearPicker) {
            _value = `${date.getMonth() + 1}/${date.getFullYear()}`
        }
        return (
            <StyledDatePickerBtn
                id="btn"
                picker={picker}
                selectedPicker={selectedPicker}
                onClick={onClick}
                style={buttonStyle}
            >
                {_value}
                <StyledCalendar id="svg" size={18} />
            </StyledDatePickerBtn>
        )
    }

    return (
        <div style={{ ...wrapStyle, display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                {startText}
                <StyledDatePicker
                    customInput={
                        <CustomDatePickerButton picker={_C.START_DROPDOWN} />
                    }
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    startDate={_S?.selectedStartDate}
                    selected={_S?.selectedStartDate}
                    onChange={handleSetStartDate}
                    onCalendarOpen={() => {
                        setPicker(_C.START_DROPDOWN)
                    }}
                    showMonthYearPicker={_S?.showMonthYearPicker}
                    />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                {endText}
                <StyledDatePicker
                    customInput={
                        <CustomDatePickerButton picker={_C.END_DROPDOWN} />
                    }
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    selected={_S?.selectedEndDate}
                    onChange={handleSetEndDate}
                    onCalendarOpen={() => {
                        setPicker(_C.END_DROPDOWN)
                    }}
                    showMonthYearPicker={_S?.showMonthYearPicker}
                />
            </div>
        </div>
    )
}
export default ReportsDatePicker
