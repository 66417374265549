import React from 'react'

import { StyledProgressBar, StyledBar } from'./styles';

const ProgressBar = ({ progressPercent }) => {
    return (
        <StyledProgressBar>
            <StyledBar width={progressPercent}/>
        </StyledProgressBar>
    )
}

export default ProgressBar
