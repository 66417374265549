import React from 'react';
import { BigBody, Body, H2, Link, Span } from '../../../styles/typography';
import { formatPhoneNumberStr, getCountryCodeForPhoneNumber } from '../../../features/locations/util';
import { useDispatch } from 'react-redux';
import { hideOverlay, showOverlay } from '../../../actions/overlayActions';
import { makeRequest } from '../../../helpers/make-request';
import { API } from '../../../constants/api';
import { OutlineBtn, PrimaryCTABtn } from '../../../styles/buttons';
import { toast } from 'react-toastify';
import { updateLocationField } from '../../../actions/franchiseActions';
import Modal from '../../../components/modal/Modal';
import { GlowingSuccessIconWrap, NewCallForwardingNumberWrap } from '../style';
import { BsCheck2, BsCheck2Circle } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import useCountries from '../../../hooks/useCountries';

export const CALL_FORWARDING_LEASE_STATUS_TYPE = {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

const CallForwardingSetup = ({ showAsLink = false, location }) => {
    const _D = useDispatch();
    const theme = useTheme();
    const { getCountry } = useCountries();

    const [generatedCallForwardingNumber, setGeneratedCallForwardingNumber] = React.useState(null);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);

    const handleSetupCallForwarding = () => {
        if (process.env.NODE_ENV === 'development') {
            const shouldShowSuccess = Math.random() < 0.5;

            if (shouldShowSuccess) {
                setGeneratedCallForwardingNumber('+16463316367');
                setShowSuccessModal(true);
            } else {
                setShowErrorModal(true);
            }
        } else {
            _D(showOverlay('Setting up your Call Tracking number...'));

            const request = makeRequest()
                .url(API.LOCATION.ENABLE_CALL_FORWARDING)
                .param('uid', location?.user?.raw?.name)
                .param('lid', location?.id);

            request
                .post()
                .then((resp) => {
                    // backend sends a "success" object if the location already has a call tracking number
                    if (Object.keys(resp).length === 1 && resp?.status === 'success') {
                        toast.success('This campaign already has a Call Tracking number!');
                        return;
                    }
                    // otherwise, backend returns the updated location object
                    setGeneratedCallForwardingNumber(resp?.phoneForwarding);
                    setShowSuccessModal(true);
                    // update the location object in redux
                    _D(
                        updateLocationField({
                            location,
                            field: 'phoneForwarding',
                            data: resp?.phoneForwarding,
                        })
                    );
                    _D(
                        updateLocationField({
                            location,
                            field: 'phoneForwardingLeaseStatus',
                            data: resp?.phoneForwardingLeaseStatus,
                        })
                    );
                })
                .catch(() => {
                    setShowErrorModal(true);
                })
                .finally(() => {
                    _D(hideOverlay());
                });
        }
    };

    return (
        <>
            {showSuccessModal && (
                <Modal
                    onClose={() => {
                        setGeneratedCallForwardingNumber(null);
                        setShowSuccessModal(false);
                    }}
                    width='928px'
                >
                    <H2>Set up Call Tracking</H2>
                    <GlowingSuccessIconWrap style={{ margin: '1.5rem auto' }}>
                        <BsCheck2 size='74' />
                    </GlowingSuccessIconWrap>
                    <H2 center color='accentColor2'>
                        We've generated a call tracking number for you!
                    </H2>
                    <BigBody style={{ marginTop: '2.5rem' }}>
                        Through the platform, you'll now be able to see how many calls are coming to
                        your business from your advertising and review recordings of calls from
                        leads.
                    </BigBody>
                    <NewCallForwardingNumberWrap style={{ marginTop: '3rem' }}>
                        <BigBody>Your Call Tracking Number</BigBody>
                        <div
                            style={{
                                marginTop: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <Span style={{ fontSize: '32px' }}>{getCountry(getCountryCodeForPhoneNumber(generatedCallForwardingNumber))?.emoji}</Span>
                            <BigBody bold color='textColor1'>
                                {formatPhoneNumberStr(generatedCallForwardingNumber)}
                            </BigBody>
                            <BsCheck2Circle style={{ color: theme.color.activeButtonColor }} />
                        </div>
                        <Body>This number was generated based on your area code.</Body>
                    </NewCallForwardingNumberWrap>
                    <Body center style={{ marginTop: '3rem' }}>
                        Calls to this number will forward calls to your business phone number:{' '}
                        <Span semibold> {formatPhoneNumberStr(location.phone)}</Span>
                    </Body>

                    <PrimaryCTABtn
                        style={{ margin: '3rem auto 0px auto', width: '160px' }}
                        onClick={() => {
                            setGeneratedCallForwardingNumber(null);
                            setShowSuccessModal(false);
                        }}
                    >
                        Okay
                    </PrimaryCTABtn>
                </Modal>
            )}
            {showErrorModal && (
                <Modal
                    width='928px'
                    onClose={() => {
                        setShowErrorModal(false);
                    }}
                >
                    <H2>Set up Call Tracking</H2>
                    <Body style={{ marginTop: '1.5rem' }}>
                        A number could not be generated with the same area code as your business
                        phone number.
                        <br />
                        Please contact{' '}
                        <a href='mailto:support@eulerity.com'>support@eulerity.com</a> for
                        assistance.
                    </Body>

                    <PrimaryCTABtn
                        style={{ margin: '3rem auto 0px auto', width: '160px' }}
                        onClick={() => {
                            setShowErrorModal(false);
                        }}
                    >
                        Okay
                    </PrimaryCTABtn>
                </Modal>
            )}
            {
                !location.phoneForwarding && (showAsLink ? (
                    <Link onClick={handleSetupCallForwarding}>Set up Call Tracking Number</Link>
                ) : (
                    <OutlineBtn onClick={handleSetupCallForwarding}>
                        Set Up Call Tracking Number
                    </OutlineBtn>
                ))
            }
            {
                location.phoneForwardingLeaseStatus === CALL_FORWARDING_LEASE_STATUS_TYPE.SUCCESS && location.phoneForwarding && (
                    <Body>{formatPhoneNumberStr(location.phoneForwarding)}</Body>
                )
            }
            {
                location.phoneForwardingLeaseStatus === CALL_FORWARDING_LEASE_STATUS_TYPE.PENDING && (
                    <Body>Leasing your phone number... please check back later.</Body>
                )
            }
            {
                location.phoneForwardingLeaseStatus === CALL_FORWARDING_LEASE_STATUS_TYPE.FAILED && (
                    <Body>
                        A number could not be generated with the same area code as your business
                        phone number.
                        <br />
                        Please contact{' '}
                        <a href='mailto:support@eulerity.com'>support@eulerity.com</a> for
                        assistance.
                    </Body>
                )
            }
        </>
    );
};

export default CallForwardingSetup;
