import React from "react";

type ShowProps = {
    when: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const Show = (props: ShowProps) => {
    return props.when ? <>{props.children}</> : null;
}