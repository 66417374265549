import {
    CLEAR_CALENDAR_TAB,
    LOAD_CREATIVE_FOLDERS,
    LOAD_CREATIVE_FOLDER_CREATIVES,
} from '../actions/types';

const initialState = {
    creativeFolders: [],
    creativesByCreativeFolder: {},
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CREATIVE_FOLDERS: {
            return {
                ...state,
                creativeFolders: action.payload,
            };
        }

        case LOAD_CREATIVE_FOLDER_CREATIVES: {
            return {
                ...state,
                creativesByCreativeFolder: {
                    ...state.creativesByCreativeFolder,
                    [action.payload.key]: action.payload.creatives,
                },
            };
        }

        case CLEAR_CALENDAR_TAB: {
            return initialState
        }

        default: {
            return { ...state };
        }
    }
};

export default calendarReducer;
