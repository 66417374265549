import React from "react";
import {IoGlobeOutline} from 'react-icons/io5'
import {
  StyledWebsitePreview,
  StyledWebsiteWrapper,
  OptionalPreviews,
} from "./styles";
import webWraperImage from "../../assets/images/disclaimerPreviewImage.png";
import { H2 } from "../../styles/typography";

const ResizedAdsPreview = () => {
  return (
    <StyledWebsitePreview>
      <OptionalPreviews>
        <div className="optional-prev-wrapper disclamer-wrapper">
        <IoGlobeOutline style={{marginRight: '20px'}} size='40' />
          <H2>
            Your Ads are Made
            <br/> into Different Sizes
          </H2>
        </div>
      </OptionalPreviews>
      <StyledWebsiteWrapper>
        <img
          className="website_ad_template disclaimer_image"
          src={`${webWraperImage}`}
          alt="disclaimer-template"
        />
      </StyledWebsiteWrapper>
    </StyledWebsitePreview>
  );
};

export default ResizedAdsPreview;
