import React, { useContext } from 'react'
import { H1, H2 } from '../../../../styles/typography'
import { StyledModalTitleV2 } from '../styles';
import { CenterElement } from '../../../reusableUI/components/CenterElement';
import { ModalContext } from "../context/ModalContextProvider"
import { ModalTitleProp } from '../types';

//* Usage: Use this component inside the ModalWrapper
//* Handles various title size and centering based on the size prop that's
//* passed to the ModalWrapper

const ModalTitle = ({
  title,
  icon,
} : ModalTitleProp ) => {
  const { size } = useContext(ModalContext)

  const titleComponent = {
    "sm" : <H2 center color='textColor1'>{title}</H2>,
    "md" : <H2 color='textColor1'>{title}</H2>,
    "lg" : <H2 color='textColor1'>{title}</H2>,
    "full" : <H1 color='textColor1'>{title}</H1>
  }

  return (
    <StyledModalTitleV2>
      {icon && <CenterElement style={{ marginBottom: "16px"}}>{icon}</CenterElement>}
      {titleComponent[size]}
    </StyledModalTitleV2>
  )
}

export default ModalTitle