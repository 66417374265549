import React, { useMemo } from "react"
import { useSelector } from 'react-redux';

// Uses:
// 1. Pass uid and extract the member we're looking for
// 2. Just use the getMember function otherwise if we need to get the member information adhoc
const useMember = (uid) => {
    const memberList = useSelector((state) => state.franchise.memberList);

    const getMember = React.useCallback((uid) => {
        return memberList.find((member) => member.id === uid);
    }, [memberList])

    const member = useMemo(() => {
        if (!uid) return null
        return getMember(uid)
    }, [uid, memberList])

    return {
        member,
        getMember
    };
};

export default useMember;
