import { BackendCombinedTheme, ThemeOptions } from './../features/themeProvider/types';
import { buildColorObject, ColorTheme } from "../helpers/colors.util";
import { buildFontObject, loadFonts } from "../helpers/font.utils";
import { buildFooterObject } from "../components/footer/footer.util";
import { CLEAR_BRAND_THEME, GET_CLIENT_API_KEYS, LOAD_BRAND_THEME, LOAD_DEFAULTS, TOGGLE_THEME, UPDATE_BRAND_THEME_COLOR } from "./types"
import { API } from "../constants/api";
import { keyMap } from '../helpers/array.util';
import { AnyAction, Dispatch } from 'redux';
import { VersionAPIResponse } from '../helpers/ui.utils';
import { makeRequest } from '../helpers/make-request';

export const toggleTheme = (theme: ThemeOptions) => (dispatch: Dispatch) => {
    if(!theme) {
        console.error('No theme specified');
        return;
    }
    dispatch({
        type: TOGGLE_THEME ,
        payload: theme
    })
}

export const loadDefaults =( versionData: VersionAPIResponse ) => (dispatch: Dispatch) => {
    if (!versionData) return
    const uiData = versionData.themes
    const darkThemeColor = buildColorObject(uiData['default-theme'])
    const darkThemeFont = buildFontObject(uiData['default-theme'])
    const darkThemeFooter = buildFooterObject(uiData['default-theme'])
    const lightThemeColor = buildColorObject(uiData['eulerity-light'])
    const lightThemeFont = buildFontObject(uiData['eulerity-light'])
    const lightThemeFooter = buildFooterObject(uiData['eulerity-light'])

    loadFonts(keyMap(darkThemeFont).map((fontItem) => ({...darkThemeFont[fontItem]})))
    loadFonts(keyMap(lightThemeFont).map((fontItem) => ({...lightThemeFont[fontItem]})))

    dispatch({
        type: LOAD_DEFAULTS,
        payload: {
            ...versionData,
            darkThemeColor,
            darkThemeFont,
            darkThemeFooter,
            lightThemeColor,
            lightThemeFont,
            lightThemeFooter,
        }
    })
}

export const loadBrandTheme = (theme: BackendCombinedTheme) => (dispatch: Dispatch<AnyAction>) => {
    if (!theme) return
    const brandTheme = buildColorObject(theme)
    const fontTheme = buildFontObject(theme)
    const footerTheme = buildFooterObject(theme)
    const brandThemeFontPromise = loadFonts(keyMap(fontTheme).map(fontItem => ({...fontTheme[fontItem]})))
    dispatch({
        type: LOAD_BRAND_THEME,
        payload: {brandTheme, fontTheme, footerTheme}
    })

    // make sure the fonts are loaded before switching to the theme
    brandThemeFontPromise.then( _fonts => {
        toggleTheme('brand')(dispatch)
    })
}

export const updateBrandThemeColor = (theme: ColorTheme) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
        type: UPDATE_BRAND_THEME_COLOR,
        payload: theme
    })
}

export const clearBrandTheme = () => (dispatch: Dispatch) => {
    let fccTheme = window.localStorage.getItem('data-theme') as ThemeOptions || 'light';
    toggleTheme(fccTheme)(dispatch)
    dispatch({type: CLEAR_BRAND_THEME})
}

export const getClientApiKeys = () => async (dispatch: Dispatch) => {
    makeRequest()
        .url(API.USER.CLIENT_API_KEYS)
        .retry(5)
        .get()
        .then(response => {
            dispatch({
                type: GET_CLIENT_API_KEYS,
                payload: response
            })
        })
        .catch(error => {
            console.error(error)
        })
}
