import React from "react"
import { Ad, AdWithMetadata, CreativeMetadataFull, Post, PostWithMetadata } from "../../creative.types"

export const ManageClonesContext = React.createContext<{
    creative: Ad | Post
    metadata: CreativeMetadataFull
    clones: AdWithMetadata[] | PostWithMetadata[]
    clonesLoading: boolean
} | null>(null)

export const useManageClonesContext = () => {
    const context = React.useContext(ManageClonesContext)

    if (!context) {
        throw new Error(
        'useManageClonesContext must be used within PartialFundingContext'
        )
    }

    return context
}