import { getAgeTargetLabel, getAgeTargets } from '../../../../../../../helpers/demographics/ageTargets'
import { getGenderTargetLabel, getGenderTargets } from '../../../../../../../helpers/demographics/genderTargets'
import {
    isGeoLocation,
    isGeoRadius,
} from '../../../../../../../helpers/demographics/geoRadius'
import { getIncomeTargetLabel, getIncomeTargets } from '../../../../../../../helpers/demographics/incomeTarget'
import { getParentTargetLabel, getParentTargets } from '../../../../../../../helpers/demographics/parentTargets'
import columnBuilder from '../../../columnBuilder'

const targetingReportCategory = columnBuilder()
    .category('business-and-campaigns-settings')
    .subCategory('Targeting')

// useful when you want to map over an array of values and format them
export const arrayToString =
    <T>(labelToCopy: (value: T) => string) =>
    (value: T[]) => {
        if (!value || !value.length) {
            return 'None'
        }
        return value.map(labelToCopy).join(', ')
    }

const targetingColumns = [
    targetingReportCategory
        .id('age')
        .name('Age')
        .makeRow((data) => {
            return getAgeTargets(data.input.location.ageTargets)
        })
        .toString(arrayToString(getAgeTargetLabel)),
    targetingReportCategory
        .id('parents')
        .name('Parents')
        .makeRow((data) => {
            return getParentTargets(data.input.location.parentTargets)
        })
        .toString(arrayToString(getParentTargetLabel)),
    targetingReportCategory
        .id('gender')
        .name('Gender')
        .makeRow((data) => {
            return getGenderTargets(data.input.location.genderTargets)
        })
        .toString(arrayToString(getGenderTargetLabel)),
    targetingReportCategory
        .id('income-targets')
        .name('Income Targets')
        .makeRow((data) => {
            return getIncomeTargets(data.input.location.incomeTargets)
        })
        .toString(arrayToString(getIncomeTargetLabel)),
    targetingReportCategory
        .id('geolocation-targets')
        .name('Geolocation Targets')
        .makeRow((data) => {
            if (!data.input.location.geoTargets) return []

            return data.input.location.geoTargets.filter(isGeoLocation)
        })
        .toString(
            arrayToString((value) => {
                return `${value.targetType} - ${value.canonicalName}`
            })
        ),

    targetingReportCategory
        .id('georadius-targets')
        .name('Georadius Targets')
        .makeRow((data) => {
            if (!data.input.location.geoTargets) return []

            return data.input.location.geoTargets.filter(isGeoRadius)
        })
        .toString(
            arrayToString((target) => {
                const { geoRadius } = target
                return `Radius: ${
                    geoRadius.radius
                } mi. (${geoRadius.latitude.toFixed(
                    3
                )}, ${geoRadius.longitude.toFixed(3)})`
            })
        ),
]

export default targetingColumns
