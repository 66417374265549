import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { isSameDay } from '../../../helpers/date.util'
import { PublishMethod, PUBLISH_METHOD, ScheduleMethod, SCHEDULE_METHOD } from '../modals/steps/PeakPostTime/types'

type PrimeTimePostingInitializer = {
    start: Date | null
}

const usePrimeTimePosting = <T extends PrimeTimePostingInitializer>({ formData }: { formData?: T }) => {
    const [selectedDate, _setSelectedDate] = useState<Date | null>(formData?.start ?? null)
    const [selectedTime, _setSelectedTime] = useState<Date | null>(formData?.start ?? null)
    const [publishMethod, _setPublishMethod] = useState<PublishMethod>("ASAP")
    const [scheduleMethod, _setScheduleMethod] = useState<ScheduleMethod>(null)

    // For Custom Time Option: Construct Datetime from separate date/time inputs
    const adjustedDate = useMemo(() => {
        if (!selectedDate || !selectedTime) return null
        const year = selectedDate?.getFullYear()
        const month = selectedDate?.getMonth()
        const day = selectedDate?.getDate()

        // * Extract values from selectedTime
        const hours = selectedTime?.getHours()
        const mins = selectedTime?.getMinutes()
        const seconds = selectedTime?.getSeconds()

        // * Combine the extracted values into a new date
        return new Date(year, month, day, hours, mins, seconds)
    }, [selectedDate, selectedTime])

    // For Prime Time Option: yyy-mm-dd format
    const formattedPrimeDate = useMemo(() => {
        if (scheduleMethod !== SCHEDULE_METHOD.PRIME || !selectedDate) return null

        const year = selectedDate.toLocaleString("default", { year: "numeric" });
        const month = selectedDate.toLocaleString("default", { month: "2-digit" });
        const day = selectedDate.toLocaleString("default", { day: "2-digit" });
        return year + '-' + month + '-' + day
    }, [scheduleMethod, selectedDate])

    // * SETTER FUNCTIONS
    const setPublishMethod = (method: PublishMethod) => {
        // * Case 1: User opts for ASAP
        if (method === PUBLISH_METHOD.ASAP) {
            // * Reset other state
            _setScheduleMethod(null)
            _setSelectedDate(null)
            _setSelectedTime(null)
        }

        _setPublishMethod(method)
    }

    const setScheduleMethod = (method: ScheduleMethod) => {
        // * Case 2: User picks a date and opts for Prime Time
        if (method === SCHEDULE_METHOD.PRIME && selectedDate) {
            _setSelectedTime(null)
        }

        _setScheduleMethod(method)
    }

    const setSelectedDate = (startDate: Date) => {
        _setSelectedDate(startDate)

        // * This is to ensure the user cannot change back to todays date and utilize Prime time posting
        const now = new Date()
        if (isSameDay(now, startDate)) setScheduleMethod(SCHEDULE_METHOD.CUSTOM)

        // * Reset time if user ever changes the date
        _setSelectedTime(null)
    }

    const setSelectedTime = (startTime: Date) => {
        const now = new Date()

        if (!selectedDate) return

        if (isSameDay(startTime, selectedDate)) {
            if (startTime < now) return toast.error('Please select a time in the future')
        }

        _setSelectedTime(startTime)
    }

    return {
        publishMethod,
        setPublishMethod,
        scheduleMethod,
        setScheduleMethod,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        adjustedDate,
        formattedPrimeDate,
    }
}

export default usePrimeTimePosting