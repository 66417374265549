import React from 'react';
import { Small } from '../../styles/typography';
import { BackButtonContainer } from './styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { NAV } from '../../constants/urlPath';
import { COLORS } from '../../constants/colors';
import { LeftArrow } from '../../styles/icons';
import { CSSProperties, useTheme } from 'styled-components';
import { Theme } from '../../types/theme';

const BackButton = ({ onBack, text, to = undefined, allowReturnToCaller = false, styles={}, ...props } : {
    onBack?: () => void;
    text?: string;
    to?: string;
    allowReturnToCaller?: boolean;
    props?: any;
    className?: string;
    styles?: CSSProperties
}) => {

    const { state } = useLocation()
    const theme: Theme = useTheme()
    const shouldReturnToCaller: boolean = allowReturnToCaller && (state as any)?.[NAV.RETURN_TO_CALLER]
    const history = useHistory()

    const backAction = () => {
        history.goBack()
    }
    if (to) {
        if (shouldReturnToCaller) {
            return <BackButton onBack={backAction} text={text} />
        } else {
            return (
                <Link to={to} style={{...styles}} {...props}>
                    <BackButton onBack={onBack} text={text} />
                </Link>
            );
        }
    }
    return (
        <BackButtonContainer className={props.className} onClick={shouldReturnToCaller ? backAction : onBack } {...props}>
            <LeftArrow color={theme.color.accentColor2} />
            {text && <Small color={COLORS.BRAND.ACCENT_COLOR_2}>{text}</Small>}
        </BackButtonContainer>
    );
};
export default BackButton;