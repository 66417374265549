import _ from "lodash";

import {
  ADD_DEFAULT_LOGO,
  ADD_FRANCHISE_IMAGE,
  ADD_FRANCHISE_LOGO,
  CHANGE_LABEL,
  CLEAR_FRANCHISE,
  CREATE_LOCATION,
  CREATE_MEMBER,
  DELETE_LABEL,
  DELETE_LOCATION,
  GET_FRANCHISE_IMAGES,
  GET_FRANCHISE_LOGOS,
  LOAD_ALL_FONTS,
  LOAD_FRANCHISE,
  LOAD_FRANCHISE_APPROVALS,
  LOAD_FRANCHISE_COLORS,
  LOAD_FRANCHISE_LABELS,
  LOAD_LOCATIONS,
  LOAD_LOCATION_AUDIENCES,
  LOAD_LOCATION_EMAILS,
  LOAD_LOCATION_SMS,
  LOAD_MEMBER,
  LOAD_MEMBERS,
  LOAD_TOP_TEXT_SUGGESTIONS,
  REFRESH_API_TOKENS,
  SELECT_LOCATION,
  SET_LOADING,
  UPDATE_FRANCHISE,
  UPDATE_GTM_CONTAINER_ID,
  UPDATE_KEYWORDS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FIELD,
  UPDATE_MEMBERS,
  UPDATE_MULTIPLE_LOCATIONS_KEYWORDS,
  UPDATE_DEFAULT_CHANNELS,
  CHAT_GPT_SUGGESTIONS,
  YOUTUBE_AI_SUGGESTIONS,
  UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE,
  UPDATE_FRANCHISE_IMAGE,
  UPDATE_FRANCHISE_LOGO,
  DELETE_FRANCHISE_IMAGE,
  DELETE_FRANCHISE_LOGO,
  LOAD_FONT,
  DELETE_FONT,

} from "../actions/types";
import { getLocationKey, getLocationKeyRaw, isLocationKeyMatch, isLocationMatch } from "../helpers";
import { filterDuplicateAudiences } from "../helpers/audiences.util";
import { Audience, Keyword, Label } from '../types/primitives';
import { FranchiseActionTypes } from './franchiseReducerTypes';
import { FranchiseState, LoadedFranchise, isInLoadedState } from './../types/franchise';
import { EulerityLocation } from "../types/location";
import { EulerityFont } from "../helpers/font.utils";
import { EulerityLocationInfo } from "../features/creatives/creative.types";
import { INTERVAL } from "../types/subscription";

const initialState: FranchiseState = {
  adwordsGlobalSiteTags: [],
  allFonts: [],
  allowInactivePosting: false,
  allowLocCreationWithoutBlueprint: true,
  allowZorsToSetGa4Filters: false,
  alternateUsers: [],
  approvals: false,
  approvalsList: undefined, // Null to show that it might be loading
  callForwardingOptIn: false,
  chatGPT: {},
  youtubeAISuggestions: {},
  dataStudioLink: "",
  defaultCurrency: "",
  defaultLid: undefined,
  defaultLocation: undefined,
  defaultUid: "",
  disabledClientPermissions: [],
  disableStockFonts: false,
  disableStockImages: false,
  disableStockLogos: false,
  displayName: "",
  dynamicLink: "",
  emailDep: false,
  enableAnalyticsGoalsViewBySource: false,
  enableAnalyticsSessionsViewBySource: false,
  enableCallForwarding: false,
  enableCreativeApproval: false,
  exposeStripeBalance: false,
  facebookPixelSnippets: [],
  featureChatGptCost: 0,
  featureChatGptEnabled: false,
  featureReputationManagementCost: 0,
  featureReputationManagementEnabled: false,
  featureEmailCreativeCost: 0,
  featureEmailCreativeEnabled: false,
  featureSmsCreativeCost: 0,
  featureSmsCreativeEnabled: false,
  featureSocialPostingCost: 0,
  featureSocialPostingEnabled: false,
  franchiseColors: [],
  friendlyDynamic: "",
  gtmContainerId: undefined,
  labels: [],
  loading: true,
  locationFieldAccessLevels: undefined,
  locations: [],
  managers: [],
  memberList: [],
  members: [],
  minIntervalCount: INTERVAL.MONTHLY,
  monthlySaas: 0,
  name: "",
  numAllowedSubscriptions: 0,
  quarterlySaasDiscount: 0,
  searchDescriptions: [],
  searchHeadlines: [],
  searchTermReportEnabled: false,
  selectedLocationWebsafe: undefined,
  semiAnnuallySaasDiscount: 0,
  sendMonthlyReports: false,
  showTimelineView: false,
  smsDep: false,
  subscription: undefined,
  subscriptionEmailTemplate: null,
  supportLink: "",
  suppressCorporateSubs: false,
  theme: {},
  tokens: [],
  topTexts: undefined,
  videoTopTexts: undefined,
  tosLink: "",
  variables: [],
  yearlySaasDiscount: 0,
  hideGA4DataFromLocations: false,
  hideBrandAwarenessDataFromLocations: false,
}

// initalstate is our FranchiseState
const franchiseReducer = (state: FranchiseState = initialState, action: FranchiseActionTypes): FranchiseState => {

  switch (action.type) {
    case SET_LOADING: {
      // Setting loading to false while not all values are actually loaded is a bad idea
      if (!action.payload && !isInLoadedState(state, initialState)) {
        console.warn("Entering an invlaid state")
      }
      // here we can be certain that all fields are defined
      return {
        ...state as LoadedFranchise,
        loading: action.payload
      };
    }
    case LOAD_FRANCHISE: {
      // something to map franchise to franchisestate
      let updatedState: FranchiseState = { ...state, ...action.payload }
      // ! Filter out alternate users from list of members because these only need to be used for scaling corporate managed subscriptions
      const alternateUsers = action.payload.alternateUsers || []
      if (alternateUsers?.length) updatedState.members = action.payload.members?.filter(m => !alternateUsers.includes(m)) || []
      return updatedState
    }

    case UPDATE_FRANCHISE: {
      return {...state, ...action.payload}
    }
    case LOAD_MEMBER: {
      return {
        ...state,
        memberList: [...state.memberList || [], action.payload],
      };
    }
    case LOAD_MEMBERS: {
      return {
        ...state,
        memberList: [...state.memberList || [], ...action.payload],
      }
    }
    case UPDATE_MEMBERS: {
      const { id, franchise } = action.payload;
      return {
        ...state,
        name: franchise || '',
        members: [...state.members, id]
      }

    }
    case LOAD_LOCATIONS: {
      const newLocationRefference = [...state.locations || [], ...action.payload]
      return {
        ...state,
        locations: newLocationRefference,
      };
    }
    case LOAD_TOP_TEXT_SUGGESTIONS: {
      const {topTexts, videoTopTexts } = action.payload
      return {
        ...state,
        videoTopTexts,
        topTexts,
      };
    }
    case LOAD_FRANCHISE_LABELS: {
      let allLocations: EulerityLocation[] = []
      if (state.loading) {
        console.warn("Attempting to load labels while loading franchise")
        allLocations = _.cloneDeep(state.locations) as EulerityLocation[]
      } else {
        allLocations = _.cloneDeep(state.locations)
      }
      const franchiseLabels = new Set<string>();

      allLocations.forEach((location) => {
        location.labels?.forEach((label: Label) => {
          franchiseLabels.add(label);
        });
      });

      return {
        ...state,
        labels: Array.from(franchiseLabels).sort(),
      };
    }
    case LOAD_LOCATION_AUDIENCES: {
        const audiences: Audience[] = action.payload;

        let allLocations: EulerityLocation[] = []
        if (state.loading) {
          console.warn("Attempting to load audiences while loading franchise")
          allLocations = _.cloneDeep(state.locations) as EulerityLocation[]
        } else {
          allLocations = _.cloneDeep(state.locations)
        }
        let defaultLocation = _.clone(state.defaultLocation)

        allLocations = allLocations.map(location => {
          const previousAudiences = location.audiences || []
          const incomingAudiences = audiences.filter((audience: Audience) => getLocationKeyRaw((audience.location as EulerityLocationInfo).raw) === getLocationKey(location))

          return {
            ...location,
            audiencesLoaded: true,
            audiences: filterDuplicateAudiences(incomingAudiences, previousAudiences) // incoming audiences must go first so they have priority
          }
        })

        if (defaultLocation) {
          defaultLocation = {
              ...defaultLocation,
              audiencesLoaded: true,
              audiences: filterDuplicateAudiences(
                  audiences.filter(
                      (audience: Audience) =>
                          getLocationKeyRaw((audience.location as EulerityLocationInfo).raw) ===
                          getLocationKey(defaultLocation)
                  ),
                  defaultLocation?.audiences || []
              ),
          };
      }

        return {
          ...state,
          locations: allLocations,
          defaultLocation
        }
    }
    case LOAD_LOCATION_EMAILS: {
        console.log('Loaded Emails');
        let allLocations = [...state.locations || []];
        let defLoc = state.defaultLocation;
        if (getLocationKey(action.payload) === getLocationKey(defLoc)) {
          defLoc = action.payload;
        } else {
          let index = allLocations.findIndex(location => getLocationKey(location) === getLocationKey(action.payload))
          allLocations[index] = {
            ...allLocations[index],
            ...action.payload
          }
        }

        return {
          ...state,
          locations: allLocations,
          defaultLocation: defLoc,
          emailDep: !state.emailDep
        }
    }
    case LOAD_LOCATION_SMS: {
      console.log('Loaded SMS Messages')
      let allLocations = [...state.locations || []];
      let defLoc = state.defaultLocation;
      if (getLocationKey(action.payload) === getLocationKey(defLoc)) {
        defLoc = action.payload;
      } else {
        let index = allLocations.findIndex(location => getLocationKey(location) === getLocationKey(action.payload))
        allLocations[index] = {
          ...allLocations[index],
          ...action.payload
        }
      }

      return {
        ...state,
        locations: allLocations,
        defaultLocation: defLoc,
        smsDep: !state.smsDep
      }
    }
    case CREATE_MEMBER: {
      const { id, name, email, lastLoginDays } = action.payload;
      const newUser = {
        id,
        name,
        email,
        lastLoginDays,
      };
      let allMembers = state.memberList || [];
      allMembers.push(newUser);

      return {
        ...state,
        memberList: allMembers,
      };
    }
    case SELECT_LOCATION: {
      return {
        ...state,
        selectedLocationWebsafe: action.payload?.websafe
      }
    }
    case UPDATE_LOCATION: {
		const payload = action.payload
		if (isLocationMatch(state.defaultLocation, payload)) {
			return {...state, defaultLocation: action.payload}
		}

		let allLocations: EulerityLocation[] = []
		if (state.loading) {
			console.warn("Attempting to update a location while loading franchise")
			allLocations = _.cloneDeep(state.locations) as EulerityLocation[]
		} else {
			allLocations = _.cloneDeep(state.locations)
		}
		let updatedLocation = action.payload;

		let index = allLocations.findIndex(location =>getLocationKey(location) === getLocationKey(updatedLocation));

		//Copy unreturned location fields
		updatedLocation.subscriptions = allLocations[index].subscriptions;

		//Update
		allLocations[index] = _.cloneDeep(updatedLocation)

		return {
		...state,
		locations: allLocations,
		};
    }
    case UPDATE_LOCATION_FIELD: {
      const { location, field, data } = action.payload;
      let allLocations: EulerityLocation[] = []
        if (state.loading) {
          console.warn("Attempting to update a location field while loading franchise")
          allLocations = _.cloneDeep(state.locations) as EulerityLocation[]
        } else {
          allLocations = _.cloneDeep(state.locations)
        }
      let index = allLocations.findIndex(l => getLocationKey(l) === getLocationKey(location))
      // Update
      if (field === 'subscription') {
        allLocations[index].subscriptions = [data]
      } else {
        allLocations[index] = {
          ...allLocations[index],
          [field]: data
        }
      }

      return {
        ...state,
        locations: _.cloneDeep(allLocations)
      }
    }
    case CREATE_LOCATION: {
      let newLocation = action.payload;
      let allLocations = [...state.locations || []];
      allLocations.push(newLocation);
      return {
        ...state,
        locations: allLocations,
      };
    }
    case DELETE_LOCATION: {
      const { uid, lid } = action.payload;
      let deletedLocationKey = `${uid}-${lid}`;
      let updatedLocations: EulerityLocation[] = []
      if (state.loading) {
        console.warn("Attempting to delete a location while loading franchise")
        updatedLocations = _.cloneDeep(state.locations) as EulerityLocation[]
      } else {
        updatedLocations = _.cloneDeep(state.locations)
      }
      updatedLocations = updatedLocations.filter(location => !isLocationKeyMatch(deletedLocationKey, location))
      return {
        ...state,
        locations: updatedLocations || [],
        selectedLocationWebsafe: ''
      }
    }
    case CLEAR_FRANCHISE:
      return initialState;
    case GET_FRANCHISE_IMAGES: {
      return { ...state, franchiseImages: action.payload };
    }
    case GET_FRANCHISE_LOGOS: {
      return { ...state, franchiseLogos: action.payload };
    }
    case UPDATE_FRANCHISE_IMAGE: {
      if (state.loading) return state
      if (!state.franchiseImages) return {
        ...state,
        franchiseImages: {
          name: state.name,
          images: [action.payload]
        }
      }

      const updatedImages = [
        ...state.franchiseImages.images.filter(img => img.blobKey !== action.payload.blobKey),
        action.payload
      ]

      return {
        ...state,
        franchiseImages: {
          ...state.franchiseImages,
          images: updatedImages
        }
      }
    }
    case UPDATE_FRANCHISE_LOGO: {
      if (state.loading) return state
      if (!state.franchiseLogos) return {
        ...state,
        franchiseLogos: {
          name: state.name,
          images: [action.payload]
        }
      }
      const updatedLogos = [
        ...state.franchiseLogos.images.filter(img => img.blobKey !== action.payload.blobKey),
        action.payload
      ]

      return {
        ...state,
        franchiseLogos: {
          ...state.franchiseLogos,
          images: updatedLogos
        }
      }
    }
    case DELETE_FRANCHISE_IMAGE: {
      if (state.loading) return state
      if (!state.franchiseImages) return {
        ...state,
        franchiseImages: {
          name: state.name,
          images: []
        }
      }

      const updatedImages = [...state.franchiseImages?.images.filter(img => img.blobKey !== action.payload.blobKey)]
      return {
        ...state,
        franchiseImages: {
          ...state.franchiseImages,
          images: updatedImages
        }
      }
    }
    case DELETE_FRANCHISE_LOGO: {
      if (state.loading) return state
      if (!state.franchiseLogos) return {
        ...state,
        franchiseLogos: {
          name: state.name,
          images: []
        }
      }

      const updatedLogos = state.franchiseLogos?.images.filter(img => img.blobKey !== action.payload.blobKey)

      return {
        ...state,
        franchiseLogos: {
          ...state.franchiseLogos,
          images: updatedLogos
        }
      }
    }

    case LOAD_ALL_FONTS: {
      let fonts = action.payload;
      fonts.sort((a: EulerityFont, b: EulerityFont) => (a.family > b.family) ? 1 : -1)
      return { ...state, allFonts: fonts  };
    }
    case LOAD_FONT: {
        const newFonts = [...state.allFonts || [], action.payload];
        return { ...state, allFonts: newFonts };
    }
    case DELETE_FONT: {
        const newFonts = state.allFonts?.filter(font => font !== action.payload);
        return { ...state, allFonts: newFonts };
        }
    case LOAD_FRANCHISE_APPROVALS: {
      return { ...state, approvalsList: action.payload}
    }
    case ADD_FRANCHISE_IMAGE: {
      const currentImages = state.franchiseImages?.images || [];

      // we dont have this loaded in...
      if (!state.franchiseImages) {
        return {
          ...state,
          franchiseImages: {
            name: state.name,
            images: [action.payload],
          }
        }
      }

      return {
        ...state,
        franchiseImages: {
          ...state.franchiseImages,
          images: [action.payload, ...currentImages],
        },
      };
    }

    case ADD_FRANCHISE_LOGO: {
      const currentLogos = state.franchiseLogos?.images || [];

      // we dont have this loaded in...
      if (!state.franchiseLogos) {
        return {
          ...state,
          franchiseLogos: {
            name: state.name,
            images: [action.payload],
          }
        }
      }

      return {
        ...state,
        franchiseLogos: {
          ...state.franchiseLogos,
          images: [action.payload, ...currentLogos],
        },
      };
    }
    case LOAD_FRANCHISE_COLORS: {
      return { ...state, franchiseColors: action.payload };
    }
    case UPDATE_GTM_CONTAINER_ID: {
      return { ...state, gtmContainerId: action.payload };
    }
    case DELETE_LABEL: {
      const newLocations = [];

      for(const location of state.locations || [])  {
        newLocations.push({
          ...location,
          labels: location.labels.filter((label: Label) => label !== action.oldLabel)
        })
      }

      return {
        ...state,
        locations: newLocations,
      }
    }
    case CHANGE_LABEL: {
      const newLocations = [];

      for(const location of state.locations || [])  {
        newLocations.push({
          ...location,
          labels: location.labels.map((label: Label) => label === action.oldLabel ? action.newLabel : label)
        })
      }

      return {
        ...state,
        locations: newLocations
      }
    }
    case  UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE: {
      return { ...state, subscriptionEmailTemplate: action.payload }
    }
    case REFRESH_API_TOKENS: {
      return {...state, tokens: action.payload }
    }
    case ADD_DEFAULT_LOGO: {
      return {
        ...state,
        defaultLocation: {
          ...state.defaultLocation!,
          logo: action.payload
        }
      }
    }
    case UPDATE_KEYWORDS: {
      const { lid, uid, keywords } = action.payload

      const locations = [...state.locations || []]
      const defaultLocation = state.defaultLocation

      const updatedLocations = locations.map(loc => {
        if(loc.id.toString() !== lid.toString() || loc.user.raw.name !== uid) {
          return loc
        }

        return {
          ...loc,
          keywords
        }
      })

      let updatedDefaultLocation = defaultLocation ? { ...defaultLocation } : null

      if(defaultLocation && defaultLocation.id === lid && defaultLocation.user.raw.name === uid) {
          updatedDefaultLocation = {
            ...defaultLocation,
            keywords
          }
      }

      return {
        ...state,
        locations: updatedLocations,
        defaultLocation: updatedDefaultLocation!
      }
    }

    case UPDATE_MULTIPLE_LOCATIONS_KEYWORDS: {
      const { keywordsByLocation } = action.payload

      const locations = [...state.locations || []]
      const defaultLocation = state.defaultLocation

      // 1 Get Updated Locations
      const updatedLocations = locations.map(loc => {
        const key = loc.id + "_" + loc.user.raw.name

        let keywords: Keyword[] = keywordsByLocation[key] || loc.keywords

        return {
          ...loc,
          keywords
        }
      })

      // 2 Get Updated Default Location
      let updatedDefaultLocation = null

      if (defaultLocation) { //(only if it is not null -- like in Member View)
        const defaultLocationKey = defaultLocation.id + "_" + defaultLocation.user.raw.name
        updatedDefaultLocation = {...defaultLocation}
        if(keywordsByLocation[defaultLocationKey]) {
            updatedDefaultLocation = {
              ...defaultLocation,
              keywords: keywordsByLocation[defaultLocationKey]
            }
        }
      }

      return {
        ...state,
        locations: updatedLocations,
        defaultLocation: updatedDefaultLocation!
      }
    }

    case UPDATE_DEFAULT_CHANNELS: {
      return {
        ...state,
        defaultLocation: {
          ...state.defaultLocation!,
          weight: action.payload
        }
      }
    }

    case CHAT_GPT_SUGGESTIONS: {
      return {
        ...state, chatGPT: action.payload
      }
    }

    case YOUTUBE_AI_SUGGESTIONS: {
      return {
        ...state, youtubeAISuggestions: action.payload
      }
    }

    default:
      return state;
  }
};

export default franchiseReducer;