import { DateHelper } from '../../../../../../../helpers'
import columnBuilder from '../../../columnBuilder'
import { reportLoader } from '../../../loaders'
import { numberAggregator } from "../shared"

const spendReportCategory = columnBuilder()
    .category('performance')
    .subCategory('Budgeted Spend')
    .loadRow(reportLoader)
    .makeHeader((meta) => {
        return `${meta.subCategory}: ${meta.name}`
    })

const spendColumns = [
    spendReportCategory
        .id('spend-total')
        .name('Total')
        .makeRow((data) => {
            const { input, row: locationReport } = data
            const targetDate = locationReport.data.find(
                (report) =>
                    report.date === DateHelper.dateToYYMMDD(input.date, 'YMD')
            )
            return targetDate?.cost
        })
        .aggregateRow(numberAggregator)
        .toString((value) => {
            if (!value) return "N/A"
            return `$${value.toFixed(2)}`
        }),
]

export default spendColumns
