import _ from 'lodash'
import React, { ComponentPropsWithRef, useEffect, useMemo } from 'react'
import { useRef, useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { FiCopy, FiMinus, FiRefreshCw, FiSave } from 'react-icons/fi'
import { IoColorPaletteOutline } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import {
	clearBrandTheme,
	loadBrandTheme,
	toggleTheme,
	updateBrandThemeColor,
} from '../../actions/UIActions'
import { API } from '../../constants/api'
import { copyToClipboard } from '../../helpers'
import {
	Hex,
	ColorTheme,
	buildBackendThemeObject,
} from '../../helpers/colors.util'
import { makeRequest } from '../../helpers/make-request'
import { IconBtn } from '../../styles/buttons'
import { Small, HR } from '../../styles/typography'
import { Card } from '../brandAwareness/components/Card'
import { getEnvironmentType } from '../environment/util'
import { ThemeOptions } from '../themeProvider/types'

const BrandThemeAnchor = styled.div`
	cursor: pointer;
	position: fixed;
	right: 0px;
	top: 5rem;

	width: 50px;
	height: 50px;
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
	background-color: ${({ theme }) => theme.color.greyAccent2};

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const ColorItemComponent = (props: {
	updateColor: (key: string, value: string) => Error | undefined
	color: Hex
	label: string
}) => {
	const ref = useRef<HTMLInputElement | null>(null)

	const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.updateColor(props.label, e.target.value)
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<div
				style={{
					outline: 'none',
					width: '35px',
					height: '35px',
					borderRadius: '8px',
					backgroundColor: props.color,
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
				}}
			>
				<input
					ref={ref}
					value={props.color}
					onChange={handleColorChange}
					type="color"
					style={{
						opacity: 0,
					}}
				/>
			</div>
			<Small semibold>{props.label}</Small>
		</div>
	)
}

const ThemeSectionComponentEditable = ({
	colors,
	updateColor,
}: {
	colors: ColorTheme
	updateColor: (theme: ColorTheme) => Error | undefined
}) => {
	const colorKeys = Object.entries(colors)
	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
				}}
			>
				{colorKeys.map(([name, value]) => (
					<ColorItemComponent
						key={name}
						color={value}
						label={name}
						updateColor={(key: string, value: string) => {
							updateColor({
								...colors,
								[key]: value,
							})
							return undefined
						}}
					/>
				))}
			</div>
		</div>
	)
}

const DraggableDiv = (props: ComponentPropsWithRef<'div'>) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const dragStart = useRef<React.DragEvent<HTMLDivElement>>()
	const [dragging, setDragging] = useState(false)

	const dragStartHandler = (ev: React.DragEvent<HTMLDivElement>) => {
		if (!ref.current) return
		ref.current.style.opacity = '0.01'

		dragStart.current = ev
		setDragging(true)
	}

	const dragEndHandler = (ev: React.DragEvent<HTMLDivElement>) => {
		if (!ref.current || !dragStart.current) return
		ref.current.style.opacity = '1'

		const modalCoords = ref.current.getBoundingClientRect()

		const modalTop = modalCoords.top
		const modalLeft = modalCoords.left

		const xDiff = dragStart.current.clientX - ev.clientX
		const yDiff = dragStart.current.clientY - ev.clientY

		const newTop = modalTop - yDiff
		const newLeft = modalLeft - xDiff

		ref.current.style.top = `${newTop}px`
		ref.current.style.left = `${newLeft}px`

		dragStart.current = undefined
		setDragging(false)
	}

	return (
		<>
			<div
				ref={ref}
				draggable
				onDragStart={dragStartHandler}
				onDragEnd={dragEndHandler}
				style={{
					position: 'fixed',
					zIndex: 999999,
					top: '50%',
					left: '50%',
					...props.style,
				}}
			>
				{props.children}
			</div>
			<div
				onDragOver={(ev) => {
					ev.preventDefault()
				}}
				style={{
					display: dragging ? 'block' : 'none',
					position: 'fixed',
					width: '100vw',
					height: '100vh',
					top: '0px',
					left: '0px',
				}}
			/>
		</>
	)
}

export const BrandThemeLiveUpdater = () => {
	//* Custom Hooks
	const _D = useDispatch()
	const theme = useTheme()

	//* Redux State
	const franchiseLoading = useSelector((s) => s.franchise.loading)
	const activeTheme = useSelector((s) => s.ui.theme)
	const brandTheme = useSelector((s) => s.ui.brandTheme)
	const franchiseName = useSelector((s) => s.franchise.name)

	//* Local State
	const [isMinimized, setIsMinimized] = useState(true)
	const [isVisible, setIsVisible] = useState(
		getEnvironmentType() !== 'production'
	)
	const initialActiveTheme = useRef<ThemeOptions>(activeTheme)
	const initialBrandTheme = useRef<ColorTheme | undefined>(brandTheme?.color)
	const hasEditedTheme = useMemo(() => {
		const _brandTheme = initialBrandTheme.current

		if (!_brandTheme) {
			return activeTheme === 'brand'
		}

		return !_.isEqual(_brandTheme, brandTheme?.color)
	}, [brandTheme, activeTheme])

	//* Updating the local state
	useEffect(() => {
		if (hasEditedTheme) return
		initialActiveTheme.current = activeTheme
	}, [activeTheme, hasEditedTheme])

	useEffect(() => {
		if (franchiseLoading || !!initialBrandTheme.current) return
		initialBrandTheme.current = brandTheme?.color
	}, [franchiseLoading, brandTheme?.color])

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.ctrlKey) {
				if (event.code === 'KeyV') {
					setIsVisible((isVisible) => !isVisible)
				}
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [])

	const liveUpdateBrandColor = (theme: ColorTheme) => {
		if (typeof theme !== 'object') return new Error('Please retry')

		// we can only edit the brand theme
		if (activeTheme !== 'brand') {
			_D(toggleTheme('brand'))
		}

		// replace the brand theme with the new color theme
		_D(updateBrandThemeColor(theme))
	}

	//* Load the initial theme again
	const resetColorTheme = () => {
		if (!hasEditedTheme) return

		if (initialBrandTheme.current) {
			_D(updateBrandThemeColor(initialBrandTheme.current))
		} else {
			_D(clearBrandTheme())
		}

		_D(toggleTheme(initialActiveTheme.current))
	}

	//* Function to update theme on the backend
	const finalizeBrandColor = async () => {
		const newBackendColorTheme = buildBackendThemeObject(theme.color)

		try {
			// TODO figure out why we cant just use api/franchise/updateFranchise
			const fullFranchise = await makeRequest()
				.url(API.FRANCHISE.GET_FULL)
				.param('franchise', franchiseName)
				.get()

			await makeRequest()
				.url(API.FRANCHISE.UPDATE)
				.body({
					theme: newBackendColorTheme,
					name: franchiseName,
					updated: fullFranchise.updated,
				})
				.post()

			const franchise = await makeRequest()
				.url(API.FRANCHISE.GET_FRANCHISE)
				.param('franchise', franchiseName)
				.get()

			const updatedTheme = franchise.theme

			if (!updatedTheme) {
				throw new Error('Theme not saved')
			}

			initialBrandTheme.current = updatedTheme.color
			_D(loadBrandTheme(updatedTheme))

			toast.success('Updated the brand theme')
		} catch {
			toast.error('Could not update the brand theme')
		}
	}

	//* Copy the JSON to the clipboard
	const copyCurrentTheme = () => {
		const newBackendColorTheme = buildBackendThemeObject(theme.color)

		copyToClipboard(JSON.stringify(newBackendColorTheme))
		toast.success('Copied to clipboard')
	}

	return (
		<>
			<BrandThemeAnchor
				style={{
					display: isVisible && isMinimized ? 'flex' : 'none',
				}}
				onClick={() => setIsMinimized(false)}
			>
				<BiChevronLeft color={theme.color.navbarTextColor} />
				<IoColorPaletteOutline color={theme.color.navbarTextColor} />
			</BrandThemeAnchor>
			<DraggableDiv
				style={{
					display: isVisible && !isMinimized ? 'initial' : 'none',
				}}
			>
				<Card.Wrap
					style={{
						maxHeight: '25rem',
						backgroundColor: theme.color.backgroundColor2,
						boxShadow: `${theme.color.greyAccent1} 0px 7px 29px 0px`,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							alignItems: 'center',
							cursor: 'grab',
						}}
					>
						<Card.Header>Design your brand theme</Card.Header>
						<FiMinus
							style={{
								cursor: 'pointer',
								color: theme.color.textColor2,
							}}
							onClick={() => {
								setIsMinimized(true)
							}}
						/>
					</div>
					<HR spacing={0.5} />
					<div
						style={{
							overflowY: 'auto',
							padding: '1rem',
						}}
					>
						<ThemeSectionComponentEditable
							colors={theme.color}
							updateColor={liveUpdateBrandColor}
						/>
					</div>
					<div
						style={{
							width: '100%',
							display: 'grid',
							gridTemplateColumns: 'repeat(3, 1fr)',
						}}
					>
						<IconBtn>
							<FiRefreshCw onClick={resetColorTheme} />
						</IconBtn>
						<IconBtn>
							<FiCopy onClick={copyCurrentTheme} />
						</IconBtn>
						<IconBtn>
							<FiSave onClick={finalizeBrandColor} />
						</IconBtn>
					</div>
				</Card.Wrap>
			</DraggableDiv>
		</>
	)
}
