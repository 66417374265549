import { calculateCPM } from "../../../../../../../helpers";
import columnBuilder from "../../../columnBuilder";
import { getDayReport } from "../shared";
import { reportLoader } from '../../../loaders';

const cpmReportCategory = columnBuilder()
	.category('performance')
	.subCategory('CPM')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.subCategory}: ${meta.name}`
	})

type Cpm = {
	impressions: number | undefined
	cost: number | undefined
}

const cpmFormatter = (value: Cpm | undefined) => {
	if (value === undefined) return "N/A"
	const cpc = calculateCPM(value.impressions, value.cost)
	return `$${cpc.toFixed(2)}`
}

const cpmAggregator = (one: Cpm | undefined, two: Cpm | undefined) => {
	if (one === undefined && two === undefined) return undefined
	if (one === undefined) return two
	if (two === undefined) return one

	let impressions;

	if (one.impressions === undefined) {
		impressions = two.impressions
	} else if (two.impressions === undefined) {
		impressions = one.impressions
	} else {
		impressions = one.impressions + two.impressions
	}

	let cost;

	if (one.cost === undefined) {
		cost = two.cost
	} else if (two.cost === undefined) {
		cost = one.cost
	} else {
		cost = one.cost + two.cost
	}

	return {
		impressions,
		cost
	}
}

const cpmColumns = [
	cpmReportCategory
		.id('cpm-total')
		.name('Total')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			if (!targetDate || !targetDate.impressions || !targetDate.cost) return

			return {
				impressions: targetDate.impressions || undefined,
				cost: targetDate.cost || undefined
			}
		})
		.aggregateRow(cpmAggregator)
		.toString(cpmFormatter),
	cpmReportCategory
		.id('cpm-by-display')
		.name('Display')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.display

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpmAggregator)
		.toString(cpmFormatter),
	cpmReportCategory
		.id('cpm-by-search')
		.name('Search')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.search

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpmAggregator)
		.toString(cpmFormatter),
	cpmReportCategory
		.id('cpm-by-social')
		.name('Social')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.social

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpmAggregator)
		.toString(cpmFormatter),
	cpmReportCategory
		.id('cpm-by-video')
		.name('Video')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			const channelMetric = targetDate?.channelMetrics?.video

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				cost: channelMetric.cost
			}
		})
		.aggregateRow(cpmAggregator)
		.toString(cpmFormatter),
]

export default cpmColumns