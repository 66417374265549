import { getEnvironmentType } from '../../../../features/environment/util'
import {
    Loaded,
    RequestManager,
    Runnable,
} from '../../../../helpers/service/RequestManager'
import {
    MakeRequest,
    BatchifyRequest,
    isBatchifyRequest,
    isMakeRequest,
} from '../../../../types/api'
import { ReportInput, ReportRowInput } from './reportBuilder'
import { InsightReportCategory, InsightReportSubCategory } from './reportTypes'


// columnBuilder usage:
// This is a utility function to build a column for a report.
// It is used to define:
// - how to identify the column
// - how to display the column header
// - how to load the column data
// - how to load the row data
// - how to generate the row data
// - how to aggregate the row data
// - how to format the row data

// const userColumn = columnBuilder()
//     .loadColumn(
//         (input) => makeRequest().url(API.USER.GET)
//     )
//     .loadRow(
//         (input) =>
//             [
//                 batchifyRequest(
//                     makeRequest()
//                         .url(API.FRANCHISE.LIST_USERS)
//                         .param('franchise', input.franchise.name)
//                 ),
//                 makeRequest().url(API.USER.GET),
//             ] as const
//     )
//     .makeRow((data) => {
//         const { input, row, column } = data
//         const [users, user] = row
//         return users.map((user) => ({
//             user,
//         }))
//     })
// const campaignTypeColumn = columnBuilder().makeRow((data) =>
//     isPrimaryLocation(data.input.location) ? 'Primary' : 'Secondary'
// )

type Formatter<T> = {
    formatString: (data: T) => string
    formatHTML: (data: T) => React.ReactNode
}

type ReportColumnMetadata = {
    id: string
    name: string
    groupId: string
    groupName: string
    category: InsightReportCategory
    subCategory: InsightReportSubCategory<InsightReportCategory>
}

export type ReportColumn<CellType> = ReportColumnMetadata & {
    header: string
    loadColumn: (requestManager: RequestManager) => (input: ReportInput) => Promise<void>
    loadRow: (
        requestManager: RequestManager
    ) => (input: ReportRowInput) => Promise<void>

    makeRow: (input: ReportRowInput) => CellType
    aggregateRow: RowAggregator<CellType>
} & Formatter<CellType>

// Utility type to extract keys that are `undefined` in the given type `T`.
type UndefinedKeys<T> = {
    [K in keyof T]: T[K] extends undefined ? K : never
}[keyof T]

// Utility type to remove keys that are `undefined` in the given type `T`.
type RemoveUndefinedKeys<T> = {
    [K in Exclude<keyof T, UndefinedKeys<T>>]: T[K]
}

type RowMakerInput<ColumnData, RowData> = RemoveUndefinedKeys<{
    input: ReportRowInput
    column: ColumnData
    row: RowData
}>

type ColumnLoader<ColumnData> = (input: ReportInput) => ColumnData
type HeaderMaker = (meta : ReportColumnMetadata) => string
type RowLoader<RowData> = (input: ReportRowInput) => RowData
type RowMaker<ColumnData, RowData, RowType> = (data: RowMakerInput<Loaded<ColumnData>, Loaded<RowData>>) => RowType

export type RowAggregator<CellType> = (one: CellType, two: CellType) => CellType

type ReportColumnBuilderOptions<ColumnDataType, RowDataType, CellType> = Partial<{
    id: string
    name: string
    category: InsightReportCategory
    subCategory: InsightReportSubCategory<InsightReportCategory>

    groupId: string
    groupName: string

    makeHeader: HeaderMaker
    toString: (data: CellType) => string
    toHTML: (data: CellType) => React.ReactNode
    rowMaker: RowMaker<ColumnDataType, RowDataType, CellType>
    rowAggregator: RowAggregator<CellType>
    columnLoader: ColumnLoader<ColumnDataType>
    rowLoader: RowLoader<RowDataType>
}>

type ReportColumnBuilder<ColumnDataType, RowDataType, CellType> = {
    id: (id: string) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    name: (header: string) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    groupId: (groupId: string) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    groupName: (groupName: string) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    category: (
        category: InsightReportCategory
    ) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    subCategory: (
        subCategory: InsightReportSubCategory<InsightReportCategory>
    ) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>

    makeHeader: (makeHeader: HeaderMaker) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>

    toString: (toString: (value: CellType) => string) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>
    toHTML: (toString: (value: CellType) => React.ReactNode) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>

    aggregateRow: (aggregator: RowAggregator<CellType>) => ReportColumnBuilder<ColumnDataType, RowDataType, CellType>

    _getOptions: () => ReportColumnBuilderOptions<ColumnDataType, RowDataType, CellType>
    _build: () => ReportColumn<CellType> & {
        makeColumn: (inputs: ReportRowInput[], makeRowKey?: (input: ReportRowInput) => string) => {
            value: CellType
            string: string
            html: React.ReactNode
        }[]
    }
    loadColumn<NewColumnDataType>(
        loader: ColumnLoader<NewColumnDataType>
    ): ReportColumnBuilder<NewColumnDataType, RowDataType, CellType>
    loadRow: <NewDataType>(
        loader: RowLoader<NewDataType>
    ) => ReportColumnBuilder<ColumnDataType, NewDataType, undefined>
    makeRow: <NewCellType>(
        cellGenerator: RowMaker<ColumnDataType, RowDataType, NewCellType>
    ) => ReportColumnBuilder<ColumnDataType, RowDataType, NewCellType>
}

export const makeRunnable = <T extends MakeRequest<any> | BatchifyRequest<any>>(
    data: T
): T & Runnable => {
    const runner = isBatchifyRequest(data)
        ? data.run
        : isMakeRequest(data)
        ? data.get
        : undefined
    if (!runner) {
        throw new Error('Invalid request type')
    }
    return {
        ...data,
        run: runner,
    }
}

const statefulReportColumn = <CellType>(column: ReportColumn<CellType>) => {

    // row index to the cell
    const rows = new Map<string, CellType>()

    const keyMap = new Map<string, Set<string>>()

    const makeColumn = (inputs: ReportRowInput[], makeRowKey?: (input: ReportRowInput) => string) => {

        keyMap.clear()

        inputs.forEach((input) => {

            if (!rows.has(input.getKey())) {
                const row = column.makeRow(input)
                rows.set(input.getKey(), row)
            }

            const key = makeRowKey ? makeRowKey(input) : input.getKey()
            const keys = keyMap.get(key) || new Set<string>()
            keys.add(input.getKey())
            keyMap.set(key, keys)
        })
    }

    return {
        ...column,
        makeColumn: (inputs: ReportRowInput[], makeRowKey?: (input: ReportRowInput) => string) => {
            const _column = new Map<string, CellType>()

            makeColumn(inputs, makeRowKey)

            inputs.forEach((input) => {
                const key = makeRowKey ? makeRowKey(input) : input.getKey()
                const keys = keyMap.get(key) || new Set<string>()

                if (keys.size === 0) {
                    return
                }

                const values = Array.from(keys.values()).map((key) => rows.get(key))

                const value = values.reduce((acc, cur) => {
                    if (!acc) {
                        return cur
                    }
                    if (!cur) {
                        return acc
                    }
                    return column.aggregateRow(acc, cur)
                })

                _column.set(key, value as CellType)
            })

            return [
                {
                    value: column.header as CellType,
                    string: column.header,
                    html: column.header,
                },
                ...Array.from(_column.values()).map((value) => ({
                    value: value,
                    string: column.formatString(value),
                    html: column.formatHTML(value),
                })),
            ]
        }
    }
}

const columnBuilder = function <
    ColumnDataType = undefined,
    RowDataType = undefined,
    CellType = undefined
>(
    options?: ReportColumnBuilderOptions<ColumnDataType, RowDataType, CellType>
): ReportColumnBuilder<ColumnDataType, RowDataType, CellType> {

    let columnLoader = options?.columnLoader
    let rowLoader = options?.rowLoader
    let rowMaker = options?.rowMaker
    let rowAggregator = options?.rowAggregator
    let toString = options?.toString
    let toHTML = options?.toHTML
    let makeHeader = options?.makeHeader
    let id = options?.id
    let name = options?.name
    let groupId = options?.groupId
    let groupName = options?.groupName
    let category = options?.category
    let subCategory = options?.subCategory

    let columnValue: Loaded<ColumnDataType> | undefined = undefined
    const rowValues = new Map<string, Loaded<RowDataType>>()

    const _load = (requestManager: RequestManager) => {
        return <T>(input: T) : Promise<Loaded<T>> => {
            if (isMakeRequest(input) || isBatchifyRequest(input)) {
                return requestManager.scheduleRequestAwaitable(makeRunnable(input))
            }

            if (Array.isArray(input) && input.every((loader) => isMakeRequest(loader) || isBatchifyRequest(loader))) {
                const accessors = input.map((loader) => requestManager.scheduleRequestAwaitable(makeRunnable(loader)))
                return Promise.all(accessors) as Promise<Loaded<T>>
            }

            return Promise.resolve(input as Loaded<T>)
        }
    }

    const _loadColumn = (requestManager: RequestManager) => {
        return async (input: ReportInput) => {
            if (!columnLoader) {
                return
            }
            const value = await _load(requestManager)(columnLoader(input))
            columnValue = value
        }
    }

    const _loadRow = (requestManager: RequestManager) => {
        return async (input: ReportRowInput) => {
            if (!rowLoader) {
                return
            }
            const value = await _load(requestManager)(rowLoader(input))
            rowValues.set(input.getKey(), value)
        }
    }

    const _makeRow = (input: ReportRowInput) => {
        const rowValue = rowValues.get(input.getKey())

        if (!rowMaker) {
            throw new Error('Row maker is not defined')
        }

        const data = {
            input,
            row: rowValue,
            column: columnValue,
        }

        const value = rowMaker(data as any)

        if (!toString) {
            throw new Error('toString is not defined')
        }

        return value
    }

    const defaultStringify = (data: CellType) => {
        if (!data) return 'N/A'

        if (typeof data === 'number') {
            return data.toString()
        }

        if (typeof data === 'string') {
            return data
        }

        if (typeof data === 'object' && 'toString' in data) {
            return data.toString()
        }

        console.error(
            'No Formatter on column: ',
            id,
            ' for ',
            data,
            typeof data
        )
        return 'No Formatter'
    }

    return {
        _getOptions() {
            return {
                id,
                name,
                groupId,
                groupName,
                category,
                subCategory,
                columnLoader,
                rowLoader,
                rowMaker,
                rowAggregator,
                toString,
                toHTML,
                makeHeader,
            }
        },
        loadColumn<NewColumnDataType>(_loader: ColumnLoader<NewColumnDataType>) {
            return columnBuilder<NewColumnDataType, RowDataType, CellType>({
                ...this._getOptions(),
                columnLoader: _loader,
                rowMaker: undefined,
                toString: undefined,
                toHTML: undefined,
            })
        },
        loadRow<NewRowDataType>(_loader: RowLoader<NewRowDataType>) {
            return columnBuilder<ColumnDataType, NewRowDataType>({
                ...this._getOptions(),
                rowLoader: _loader,
                rowMaker: undefined,
                toString: undefined,
                toHTML: undefined,
                rowAggregator: undefined,
            })
        },
        makeRow<NewCellType>(_rowMaker: RowMaker<ColumnDataType, RowDataType, NewCellType>) {
            return columnBuilder<ColumnDataType, RowDataType, NewCellType>({
                ...this._getOptions(),
                rowMaker: _rowMaker,
                toString: undefined,
                toHTML: undefined,
                rowAggregator: undefined,
            })
        },
        aggregateRow(_rowAggregator: RowAggregator<CellType>) {
            return columnBuilder({
                ...this._getOptions(),
                rowAggregator: _rowAggregator,
            })
        },
        _build() {
            if (!rowMaker) {
                throw new Error('Maker is not defined for column: ' + id)
            }
            if (!toString) {
                toString = defaultStringify
                if (getEnvironmentType() === 'development') {
                    console.warn('toString is not defined for column: ' + id)
                }
            }
            if (!rowAggregator) {
                rowAggregator = (one, two) => one
            }

            if (!id || !name || !category || !subCategory) {
                throw new Error(
                    'id, name, category, and subCategory are required for column: ' +
                        id
                )
            }

            groupName = groupName || name
            groupId = groupId || id

            return statefulReportColumn({
                id,
                name,
                header: makeHeader ? makeHeader({ id, name, groupId, groupName, category, subCategory }) : name,
                groupId,
                groupName,
                category,
                subCategory,
                formatString: toString,
                formatHTML: toHTML || toString,
                loadColumn: _loadColumn,
                loadRow: _loadRow,
                makeRow: _makeRow,
                aggregateRow: rowAggregator,
            })
        },
        id(id: string) {
            return columnBuilder({
                ...this._getOptions(),
                id,
            })
        },
        name(header: string) {
            return columnBuilder({
                ...this._getOptions(),
                name: header,
            })
        },
        category(category: InsightReportCategory) {
            return columnBuilder({
                ...this._getOptions(),
                category,
            })
        },
        subCategory(subCategory) {
            return columnBuilder({
                ...this._getOptions(),
                subCategory,
            })
        },
        groupId(groupId) {
            return columnBuilder({
                ...this._getOptions(),
                groupId,
            })
        },
        groupName(groupName) {
            return columnBuilder({
                ...this._getOptions(),
                groupName,
            })
        },
        toString(toString) {
            return columnBuilder({
                ...this._getOptions(),
                toString,
            })
        },
        toHTML(toHTML) {
            return columnBuilder({
                ...this._getOptions(),
                toHTML,
            })
        },
        makeHeader(makeHeader) {
            return columnBuilder({
                ...this._getOptions(),
                makeHeader,
            })
        }
    }
}

export default columnBuilder
