import styled from "styled-components";

export const StyledCreativeDetails = styled.div`
    display: flex;
    gap: 26px;
    margin-top: 24px;

    .media {
        height: 164px;
        width: 164px;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .creative-data {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        max-height: 160px;
        overflow: scroll;
    }

    .data-item {
        display: flex;
        gap: 16px;
    }

    .header {
        min-width: 240px;
    }

`

export const CloneStatusTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
`

export const CloneStatusTable = styled.div`
    border: 1px solid ${props => props.theme.color.textColor1};
    border-radius: 9px;
    max-height: 670px;
    width: 1114px;

    .headers {
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.color.textColor1};
        padding: 22px 40px;
        flex: 1;
    }

    .header {
        flex: 1;
    }

    .creative-content {
        padding: 22px 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 600px;
        overflow: scroll;
    }

`

export const CloneItem = styled.div`
    display: flex;
    gap: 120px;

    .media {
        height: 100px;
        width: 100px;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        iframe {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .clone-info {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`

// * Creative Folders (bulk selector)
export const BulkCreativeSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 48px;

    .select-wrapper {
        display: flex;
        gap: 16px;
        text-decoration: underline;
        color: ${props => props.theme.color.accentColor2};
    }

    .counter-wrapper {
        display: flex;
        justify-content: end;
    }
`

export const StyledBulkCreativeSelector = styled.div`
    border: 1px solid ${props => props.theme.color.accentColor2};
    border-radius: 8px;
    padding: 12px 16px;
    max-height: 310px;
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 16px;
    column-gap: 8px;
`

export const StyledCreativeItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    gap: 6px;

    .item-header {
        display: flex;
        align-items: center;
        gap: 8px
    }

    .checkbox-wrapper {
        position: absolute;
        top: 4px;
        left: 4px;
        cursor: pointer;
    }

    .item-media {
        height: 190px;
        width: 190px;
        border-radius: 8px;
        overflow: hidden;
        border: ${props => props.isChecked && `${props.theme.color.accentColor2} 1.5px solid`};

        img, iframe, video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }
`