import { useMemo } from "react"
import { useSelector } from "react-redux"
import { sortByName } from "../helpers/sorters"

type Country = {
    name: string;
    code: string;
    emoji: string;
}

type CountryOption = {
    value: Country['code'];
    label: Country['name'];
    emoji: Country['emoji'];
}

const useCountries = () => {
    const countries = useSelector(state => state.resources.countries) as Country[]
    const countryOptions = useMemo(() => {
        const options: CountryOption[] = countries.sort(sortByName).map(country => {
            const { name, code, emoji } = country
            return { value: code, label: name, emoji: emoji ?? '' }
        })

        return options
    }, [countries])

    const getCountry = (code: string) => {
        return countries.find(country => country.code === code)
    }

    return {
        countryOptions,
        getCountry
    }
}

export default useCountries