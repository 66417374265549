import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as QuestionMark } from '../../assets/images/question-mark.svg';
import { getSrvPath } from '../../helpers';
import { canManageFranchise } from '../../helpers/permissionsHelper';
import { OutlineBtn } from '../../styles/buttons';
import { H2, XS, Body } from '../../styles/typography';
import "external-svg-loader"
import { LinkGroupLayout, Wrap,Top,Bottom } from './styles';
import { pageInfo, PageRoute } from './pageInfo';

type Link={
    name: string;
    url: string;
    blobKey?: string;
}
type FooterLinkProps={
    link: Link;
    noIcons?: boolean;
}
type LinkGroupProps={
    links: Link[];
}
const FooterLink = ({link, noIcons}:FooterLinkProps) => (
    <div className="link" key={link.name} onClick={() => window.open(link.url, "_blank")} rel="noreferrer">
        {!noIcons && <div className="link-icon">{link.blobKey &&  (
            <svg
                data-src={getSrvPath(link.blobKey)}
            ></svg>
        )}</div>}
        <XS className="link-text" color="textColor2">{link.name}</XS>
    </div>
)

const LinkGroup = ({ links }:LinkGroupProps) => {

    // check to see if all of the link objects have an image
    // we do not want to render the icon column if there are none
    let empty = links.every(link => !link.blobKey)
    return (
        <LinkGroupLayout noIcons={empty}>
            {links.map((link, index) => (
                <FooterLink key={link.name + index} link={link} noIcons={empty} />
            ))}
        </LinkGroupLayout>)
}

const Footer = () => {
    const accessLevel = useSelector(s => s.user.accessLevel)
    const { footer } = useTheme()
    const { pathname } = useLocation();
    const routeKey = pathname.replace('/app', '') as PageRoute;
    const info = pageInfo[routeKey] ?? {};
    const wikiLink = canManageFranchise(accessLevel) ? info.managerWiki : info.userWiki || null
    const numLinks = footer?.links.length
    const numLinkGroups = Math.min(4, numLinks)
    const linksPerGroup = Math.ceil(numLinks / numLinkGroups)
    const today = new Date()

    return (
        <Wrap>
            {wikiLink && (
                <Top>
                    <QuestionMark id='questionMark'/>
                    <div>
                        <H2>Questions?</H2>
                        <Body>
                            Visit the
                            <a href={wikiLink} target='_blank' rel='noreferrer'>
                                {' '}{info.descriptionText || 'Eulerity'} Wiki{' '}
                            </a>
                            to read more about this page.
                            <br />
                            Contact us for more help at
                            <a href='mailto:support@eulerity.com'>
                                {' support@eulerity.com'}
                            </a>.
                        </Body>
                    </div>
                    <OutlineBtn style={{marginLeft: 'auto'}} onClick={() => window.open(wikiLink)}>Learn More {info.descriptionText && `About ${info.descriptionText}`}</OutlineBtn>
                </Top>
            )}

            <Bottom>
                <img className='logo' src={getSrvPath(footer?.logo)} alt={''} />
                <div className="link-section">
                    {Array.from({ length: numLinkGroups }, (_, i) => (<LinkGroup
                        key={i}
                        links={footer?.links.slice(i * linksPerGroup, (i + 1) * linksPerGroup)}
                    />
                    ))
                    }
                </div>
                <div className="location-section">
                    <span style={{whiteSpace: 'pre-line'}}><XS color="textColor2"><MdLocationOn />{footer?.address}</XS></span>
                    <XS color="textColor2">{footer?.phone}</XS>
                    <XS  color="textColor2">{`Eulerity ${today.getFullYear()} Inc. All Rights Reserved.`}</XS>
                </div>
            </Bottom>
        </Wrap>
    );
};

export default Footer;
