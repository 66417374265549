import { COLLAPSED_LOCATIONS } from '../actions/types';


const initialState = {
    collapsedLocations: [],
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case COLLAPSED_LOCATIONS: {
                if(action.clearAll) {
                    return {...state,   collapsedLocations: [] }
                }
                if(action.typeOfAction === 'add') {
                    return {...state,   collapsedLocations: [...state.collapsedLocations, action.payload]}
                } else if (action.typeOfAction === 'remove') {
                    return {...state,   collapsedLocations: state.collapsedLocations.filter(item => item !== action.payload)}
                }
                break
            }
        default: return state;
    }
}

export default userReducer;