import {
    ApiPromise,
    BatchifyRequest,
    MakeRequest,
} from '../../types/api'
import queue from '../structures/Queue'
import { Identifiable } from '../structures/Queue'

// This is the RequestManager! It's a class that manages requests and runs them concurrently.

// Usage:

// const manager = requestManager()
// const request = makeRequest().url(API.USER.GET).retry(3)
// const promise = manager.scheduleRequest(request)
// await manager.run()
// const fullUser = manager.getResolvedRequest(request)

// This is really useful when working with asynchronous data processing, and you want to manage the requests in a queue.
// Example (reportBuilder.ts):

// const fetchReportData = async (
//     input: ReportInput,
//     columns: ColumnOption[],
// ) => {
//     const manager = requestManager()
//     const reportInputs = makeReportRowInputs(input) // This is a helper function that creates an array of report row inputs
//     const loaderCleanupPromises = []
//     for (const column of columns) {
//         loaderCleanupPromises.push(column.loadColumn(manager)(input))
//         const rowLoader = column.loadRow(manager) // This adds a row loader (ie: makeRequest) to the manager
//         for (const input of reportInputs) {
//             loaderCleanupPromises.push(rowLoader(input))
//         }
//     }
//     await manager.run()
//     await Promise.all(loaderCleanupPromises)
// }

// Now consumers of this API can not worry about who/when/what/how data is being fetched:

// (columnBuilder.ts)
// const load = (requestManager: RequestManager) => {
//     return <T>(input: T) : Promise<Loaded<T>> => {
//         if (isMakeRequest(input) || isBatchifyRequest(input)) {
//             return requestManager.scheduleAwaitable(makeRunnable(input))
//         }

//         if (Array.isArray(input) && input.every((loader) => isMakeRequest(loader) || isBatchifyRequest(loader))) {
//             const accessors = input.map((loader) => requestManager.scheduleAwaitable(makeRunnable(loader)))
//             return Promise.all(accessors) as Promise<Loaded<T>>
//         }

//         return Promise.resolve(input as Loaded<T>)
//     }
// }

// Such that when you load you can call it like this:
// const loadColumn = (requestManager: RequestManager) => {
//     return async (input: ReportInput) => {
//         if (!columnLoader) { // taken from the closure of the object
//             return
//         }
//         if (isMakeRequest(input) || isBatchifyRequest(input)) {
//             return requestManager.scheduleAwaitable(makeRunnable(input))
//         }

//         return Promise.resolve(input as Loaded<T>)
//     }
// }

// As you can see you can await promises in the control flow as if they are happening inline, but they are actually being managed by the request manager.


export type Request = Runnable & Identifiable

export type Runnable = {
    run: () => Promise<any>
}

export const isRunnable = <T extends object>(data: T): data is T & Runnable => {
    return 'run' in data && typeof data.run === 'function'
}

export type Loaded<DataType> =
    DataType extends BatchifyRequest<infer BatchedEndpoint>
        ? ApiPromise<BatchedEndpoint>['paginatedData']
        : DataType extends MakeRequest<infer Endpoint>
          ? ApiPromise<Endpoint>
          : DataType extends readonly any[]
            ? { [K in keyof DataType]: Loaded<DataType[K]> }
            : DataType

type RequestManagerQueue<T extends Request> = {
    enqueue: (task: T) => void
    dequeue: () => T | undefined
    size: () => number
    has: (task: T) => boolean
}

type RequestManagerOptions<T extends Request = Request> = {
    enabled?: boolean
    maxConcurrentRequests?: number
    queue?: RequestManagerQueue<T>
    onTaskAdded?: (task: T) => void
    onTaskComplete?: (task: T) => void
    onTaskFailed?: (task: T) => void
}

export const requestManager = function(opts?: RequestManagerOptions) {

    let _queue = opts?.queue ?? queue<Request>()
    let maxConcurrentRequests = opts?.maxConcurrentRequests ?? 1
    let cache = new Map<string, any>()
    let inFlightCount = 0
    let hasStarted = opts?.enabled ?? false
    let running = false

    const getUnresolved = () => {
        return [...cache.values()].filter(value => value instanceof Promise)
    }

    const getUnresolvedCount = () => {
        return getUnresolved().length
    }

    const dequeueAndRun = () => {
        const nextRequest = _queue.dequeue()
        if (nextRequest) {
            nextRequest.run()
        }
    }

    const invalidateKey = (key: string) => {
        cache.delete(key)
    }

    const scheduleRequest = <AddedRequest extends Request>(request: AddedRequest): void => {
        // we do not care about the rejection here
        scheduleRequestAwaitable(request).catch(() => {})
    }

    const scheduleRequestAwaitable = async <AddedRequest extends Request>(request: AddedRequest): Promise<Loaded<AddedRequest>> => {

        // promise that will throw errors
        const _task = new Promise<Loaded<AddedRequest>>((resolve, reject) => {

            // promise that will not throw errors
            const executor = async () => {
                inFlightCount++
                try {
                    const result = await request.run()
                    cache.set(request.getKey(), result)
                    opts?.onTaskComplete?.(request)
                    resolve(result)
                } catch (error) {
                    cache.set(request.getKey(), error)
                    opts?.onTaskFailed?.(request)
                    reject(error)
                } finally {
                    inFlightCount--
                }
            }

            if (cache.has(request.getKey())) {
                resolve(cache.get(request.getKey()))
                return
            }

            opts?.onTaskAdded?.(request)
            _queue.enqueue({
                ...request,
                run: executor,
            })
        })

        if (_queue.has(request) && !cache.has(request.getKey())) {
            cache.set(request.getKey(), _task)
        }

        if (hasStarted && !running) {
            run()
        }

        if (running && inFlightCount < maxConcurrentRequests && _queue.size() > 0) {
            dequeueAndRun()
        }

        return _task
    }

    const getResolvedRequest = <T extends Request>(request: T) => {

        const data = cache.get(request.getKey())

        if (!data || data instanceof Promise) {
            throw new Error('RequestManager did not finish running. Did not `await run()` and failed to load ' + request.getKey())
        }

        if (data instanceof Error) {
            throw data
        }

        return data as Loaded<T>
    }

    const getScheduledRequest = <T extends Request>(request: T) => {

        if (!cache.has(request.getKey())) {
            return undefined
        }

        return cache.get(request.getKey()) as Promise<Loaded<T>>
    }

    const run = async () => {
        running = true
        hasStarted = true
        try {
            while (inFlightCount < maxConcurrentRequests && _queue.size() > 0) {
                dequeueAndRun()
            }
            while (getUnresolvedCount() > 0) {
                const arrayCache = getUnresolved()
                if (arrayCache.length > 0) {
                    await Promise.race(arrayCache)
                    dequeueAndRun()
                }
            }
        } catch {}
        running = false
    }

    return {
        scheduleRequest,
        scheduleRequestAwaitable,
        getResolvedRequest,
        getScheduledRequest,
        invalidateKey,
        run,
        cache,
    }
}

export type RequestManager = ReturnType<typeof requestManager>
