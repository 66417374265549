import gsap from 'gsap'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_ANIMATION, TOGGLE_SIDEBAR } from '../actions/types'
import { SidebarStates } from '../helpers/ui.utils'

/*
 * useSidebar() allows us to get the state and manipulate the sidebar
 *
 * collapse: is the sidebar collapsed?
 * hideSidebar: hide it completely
 * showSidebar: expand it to full width
 * collapseSideBar: toggle between the full and collapsed state
 *
 */

const useSidebar = () => {
  const _D = useDispatch()
  const uiState = useSelector(s => s.ui)
  const { collapseAnimation, hideAnimation } = uiState
  const sidebarState = useSelector(s => s.ui.sidebar)
  const collapsed = useMemo(() => sidebarState !== "full", [sidebarState])

  useEffect(() => {
	// only run this the first time the hook is created
	if (collapseAnimation && hideAnimation) return

	// One to compact it and one to expand
	const animateSidebar = gsap.timeline().to('.sidebar_container', { minWidth: '50px', width: 50, ease: 'back' })
	animateSidebar.reverse()
	animateSidebar.pause()
	// Hide
	const animateHide = gsap.timeline().to('.sidebar_container', { minWidth: '0px', width: '0px', ease: 'back', padding: '0px'})
	animateHide.reverse()
	animateHide.pause()
	_D({
	  type: SET_ANIMATION,
	  payload: {
		hide: animateHide,
		collapse: animateSidebar
	  }
	})
  }, [_D, collapseAnimation, hideAnimation])

  const hideSidebar = useCallback(() => {
	hideAnimation?.play?.()
	_D({ type: TOGGLE_SIDEBAR, payload: "hidden" as SidebarStates })
  }, [hideAnimation, _D])

  const showSidebar = useCallback(() => {
	hideAnimation?.reverse?.();
	_D({ type: TOGGLE_SIDEBAR, payload: "full" as SidebarStates })
  }, [hideAnimation, _D])

  const collapseSideBar = () => {
	  if (collapseAnimation?.reversed()) {
		  collapseAnimation?.play();
	  } else {
		  collapseAnimation?.reverse();
	  }
	  _D({ type: TOGGLE_SIDEBAR })
  }

  // keep track of the sidebar height
  const [sidebarHeight, setSidebarHeight] = useState(0)

  // update the sidebar height when the sidebar is resized
  useEffect(() => {
	const resizeObserver = new ResizeObserver((entries) => {
		setSidebarHeight(entries[0].target.getBoundingClientRect().height)
	})

	resizeObserver.observe(document.getElementById('sidebar')!)

	return () => resizeObserver.disconnect()
  }, [])

	return {
		collapsed,
		collapseSideBar,
		hideSidebar,
		showSidebar,
		sidebarHeight
	}
}

const useHideSidebar = () => {
	const { hideSidebar, showSidebar, collapsed } = useSidebar()

	useEffect(() => {
	  hideSidebar()
	  return () => showSidebar()
	}, [hideSidebar, showSidebar])
	return { collapsed }
}
export { useSidebar, useHideSidebar }