import React from 'react'

import { MILLIS_PER_DAY } from '../../../../helpers/constants';
import { gmtToLocaleString } from '../../../../helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { StyledStepDates, DateTimeField, MinDurationWarning } from '../styles';

import { toast } from 'react-toastify';
import { Body, H2 } from '../../../../styles/typography';
import NumberUtil from '../../../../helpers/number.util';
import { useSelector } from 'react-redux';
import { DatePickerWrapper } from '../../../calendar/components/Options/styles';
import useVersionData from '../../../../hooks/useVersionData';
import { useAccess } from '../../../../hooks/useAccess';
import { useMemo } from 'react';

export const getInitialAdDates = creative => {
    if (!creative) return { start: null, end: null }
    //Try to initialize values
    let initialStart = null
    let initialEnd = null
    const now = new Date()

    if (creative) {
        // When Accepting or Editing a creative
        if (creative?.start) initialStart = new Date(gmtToLocaleString(creative.start, 'none'))
        if (creative?.end) initialEnd = new Date(gmtToLocaleString(creative.end, 'none'))
    }

    return {
        // Start is in the future
        start: initialStart && initialStart > now ? initialStart : null,
        // End is after the start and in the future
        end: (initialEnd && initialEnd > now) && ((initialStart && (initialEnd > initialStart)) || !initialStart) ? initialEnd : null,
    }
}

const CreativeStepDates = ({ isPost, context }) => {
    const { formData, updateFormData } = React.useContext(context)
    const isMagicFork = useSelector(s => s.build.magicOrTemplateFork) === 'magic'
    const { canManageFranchise } = useAccess()
    const { minAdDuration } = useVersionData()


    const minDurationWarning = useMemo(() => {
        // Does not apply to posts or ads with no end date
        if (isPost || !formData?.end) return null

        let startDate = formData?.start || new Date() // If no date specified, use now
        let endDate = formData?.end

        const currentDurationLength = (endDate.getTime() - startDate.getTime()) / MILLIS_PER_DAY
        if (currentDurationLength < minAdDuration) {
            return `You've decided to schedule your ad for ${NumberUtil.formatNumber(currentDurationLength, 3)} days.
            We recommend scheduling your ad for at least ${minAdDuration} days to make sure your ad can run through proper review and optimization processes.`
        } else {
            return ``
        }

    }, [formData?.start, formData?.end])

    const isCreatingOptionalAd = !isPost && isMagicFork && canManageFranchise() // a manager is creating an ad and is in the magic fork

    const addDays = (start, days) => {
        if (!start) return null;

        const newDate = new Date(start);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    const validateStartChange = date => {
        const end = formData?.end
        const now = new Date()

        // No start date set
        if (!date) {
            return updateFormData('start', null)
        }
        // Error: Trying to set past start date
        if (date < now) {
            toast.error('Start date must be in the future. Leave empty to start immediately.')
            return updateFormData('start', null)
        }

        // If the new start is after a end date, reset the end date
        if (end && date > end) {
            updateFormData('end', null)
        }

        updateFormData('start', date)
    }

    const validateEndChange = date => {
        const now = new Date()

        // No end date set
        if (!date) {
            return updateFormData('end', null)
        }

        // Error: Trying to set past end date
        if (date < now) {
            toast.error('End date must be in the future.')
            return updateFormData('end', null)
        }

        // Note: The min end date is already handled by minDate field on the Date Picker

        return updateFormData('end', date)
    }

    // Returns users timezone as text
    const userTimeZoneText = `(${new Date().toLocaleDateString('en-US', {
        day: '2-digit',
        timeZoneName: 'short'
    }).slice(4)})`;

    return (
        <StyledStepDates>
            <H2 regular>Select Dates</H2>

            <DateTimeField>
                <Body>{isPost ? 'Post' : 'Start Date'}</Body>
                <DatePickerWrapper fullWidth={true}>
                    <DatePicker
                        selected={formData?.start}
                        onChange={date => validateStartChange(date)}
                        minDate={new Date()}
                        dateFormat="Pp"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        selectsStart
                        isClearable
                        placeholderText={`Select a date and time ${userTimeZoneText}`}
                        startDate={formData?.start}
                        endDate={formData?.end}
                    />
                </DatePickerWrapper>
                <Body>
                    {
                        isPost ? 'Leaving date blank means the post will be created as soon as possible'
                            : 'Leaving date blank means the ad will start as soon as possible'
                    }
                </Body>
            </DateTimeField>

            {
                !isPost &&
                <>
                    <DateTimeField>
                        <Body>End Date</Body>
                        <DatePickerWrapper fullWidth={true}>
                            <DatePicker
                                selected={formData?.end}
                                onChange={date => validateEndChange(date)}
                                minDate={addDays(formData?.start, 1) || new Date()}
                                dateFormat="Pp"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                selectsStart
                                isClearable
                                placeholderText={`Select a date and time ${userTimeZoneText}`}
                                startDate={formData?.start}
                                endDate={formData?.end}
                            />
                        </DatePickerWrapper>
                        <Body>
                            Leaving date blank means ad runs until it is paused or deleted
                        </Body>

                        {
                            isCreatingOptionalAd &&
                            <Body>
                                Unaccepted optional ads will be deleted by their end date. Accepted optional ads with an end date will result in creatives that stop running on the end date.
                            </Body>
                        }
                    </DateTimeField>


                    <MinDurationWarning center>
                        {minDurationWarning}
                    </MinDurationWarning>
                </>
            }
        </StyledStepDates>
    )
}

export default CreativeStepDates
