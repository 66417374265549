import React from "react";
import { toast } from "react-toastify";
import { copyToClipboard } from "../../../helpers";
import { XS } from "../../../styles/typography";

export const CopyCreativeID = ({ creative, title = "Copy to clipboard"}) => {
  const handleCopy = () => {
    const textToCopy = `${creative.kind}:${creative.id}`
    copyToClipboard(textToCopy, () => {
      toast.success('Copied to Clipboard')
    })
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <XS color="greyAccent1" onClick={handleCopy} title={title}>
        ID: {creative.id}
      </XS>
    </div>
  );
};
