import React from 'react'
import ModalV2 from '../modal/ModalV2/ModalV2'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { Body, H2, Link, Small } from '../../styles/typography';
import { toast } from 'react-toastify';

type ColorItem = {
    name: string;
    value: string;
}

const ThemeContainer = styled.div`
    display: flex;
`;

const ThemeSection = styled.div`
    padding: 0.3rem;
`;

const ColorContainer = styled.div`
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
`;

const ColorSwatch = styled.div<{ value: string }>`
    cursor: pointer;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    background-color: ${(props) => props.value};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

const ThemeBuilderLink = styled.div`
    padding: 7px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.color.textColor2};
    width: max-content;
    transition: all 0.3s;
    margin-bottom: 1rem;

    &:hover {
        background-color: ${(props) => props.theme.color.greyAccent2};
    }
`;

const THEME_CREATOR_URL = 'http://eulerity-theme-creator.s3-website-us-east-1.amazonaws.com/';

const copyToClipboard = (colorName: string) => {
    navigator.clipboard.writeText(colorName).then(() => {
        toast.success(`Color Name (${colorName}) Copied!`);
    });
};

const ColorItemComponent = ({ color }: { color: ColorItem }) => (
    <ColorContainer>
        <ColorSwatch value={color.value} onClick={() => copyToClipboard(color.name)} />
        <Small semibold>{color.name}</Small>
    </ColorContainer>
);

const ThemeSectionComponent = ({ title, colors }: { title: string; colors: [string, string][] }) => (
    <ThemeSection>
        <H2 style={{ marginBottom: '1rem' }} semibold> {title} </H2>
        <div>
            {colors.length
                ? (colors.map(([name, value]) => (<ColorItemComponent key={name} color={{ name, value }} />)))
                : ( <Body>No {title} Set</Body>)
            }
        </div>
    </ThemeSection>
);

const BrandTheme = ({onClose}: {onClose: () => void}) => {
    const brandColors = useSelector((state) => Object.entries(state.ui.brandTheme?.color || {}));
    const lightColors = useSelector((state) => Object.entries(state.ui.lightTheme.color || {}));
    const darkColors = useSelector((state) => Object.entries(state.ui.darkTheme.color || {}));

    return (
        <ModalV2 size="full" onClose={onClose}>
            <>
                <ThemeBuilderLink>
                    <Link href={THEME_CREATOR_URL} target="_blank" rel="noreferrer" nodecoration>
                        <Body semibold>Build Your Theme With Theme Creator</Body>
                    </Link>
                </ThemeBuilderLink>

                <ThemeContainer>
                    <ThemeSectionComponent title="Default Light Theme" colors={lightColors} />
                    <ThemeSectionComponent title="Default Dark Theme" colors={darkColors} />
                    <ThemeSectionComponent title="Brand Theme" colors={brandColors} />
                </ThemeContainer>
            </>
        </ModalV2>
    );
};

export default BrandTheme;
