import { LOCATION_CONNECTIONS } from '../../../../../../../features/connections/constants'
import {
    CONNECTION_STATUS_COPY,
    getLocationConnections
} from '../../../../../../../features/locations/util'
import columnBuilder from '../../../columnBuilder'

const instagramReportCategory = columnBuilder()
    .category('platform-connections')
    .subCategory('Instagram')
    .makeHeader((meta) => {
        return `Instagram: ${meta.name}`
    })

const instagramColumns = [
    instagramReportCategory
        .id('instagram-connection-status')
        .name('Connection Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return (
                !!connections[LOCATION_CONNECTIONS.INSTAGRAM_ADVERTISING] ||
                !!connections[LOCATION_CONNECTIONS.INSTAGRAM_POSTING]
            )
        })
        .toString((data) => (data ? 'Connected' : 'Not Connected')),
    instagramReportCategory
        .id('instagram-connection-status-handle')
        .name('Handle')
        .makeRow((data) => {
            return data.input.location.businessInstagramUsername
        })
        .toString((value) => {
            return value ?? 'N/A'
        }),
    instagramReportCategory
        .id('instagram-connection-advertising-status')
        .name('Advertising Access Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return connections[LOCATION_CONNECTIONS.INSTAGRAM_ADVERTISING]
        })
        .toString((data) => CONNECTION_STATUS_COPY[data]),
    instagramReportCategory
        .id('instagram-connection-posting-status')
        .name('Posting Access Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return connections[LOCATION_CONNECTIONS.INSTAGRAM_POSTING]
        })
        .toString((data) => CONNECTION_STATUS_COPY[data]),
]

export default instagramColumns
