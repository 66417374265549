import { ColorTheme, Hex } from '../helpers/colors.util';

import styled, { css } from 'styled-components';
import { decideTextColor, hexToRgba } from '../helpers/colors.util';
import React from 'react';
import { EulerityFont } from '../helpers/font.utils';

export const VARIANT = {
    POST: 'post',
    AD: 'ad',
    SMS: 'sms',
    EMAIL: 'email'
}

type VariantProps = {
    variant?: string,
    color?: string,
    theme: any
}

export const getColorFromVariant = (defaultValueKey : string) => (props : VariantProps) => {
    if(!props.variant || !Object.values(VARIANT).includes(props.variant)) return props.theme.color[props.color || defaultValueKey]

    if(props.variant === VARIANT.POST) {
        return props.theme.color.creativeColor2
    } else if (props.variant === VARIANT.AD) {
        return props.theme.color.creativeColor1
    } else if (props.variant === VARIANT.SMS) {
        return props.theme.color.creativeColor3
    } else if (props.variant === VARIANT.EMAIL) {
        return props.theme.color.creativeColor4
    }
}

export const breakWord = css`
    -ms-word-break: break-all;
    -ms-word-wrap: break-all;
    -webkit-word-break: break-word;
    -webkit-word-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-line;
`

// ! THESE ARE DEPRECATED
export const TextLink = styled.span<any>`
    text-decoration: underline;
    cursor: pointer;
    font-size: ${props => {
        switch(props.size) {
            case 'sm': {
                return '0.7rem';
            }
            default: {
                return '1rem';
            }
        }
    }};
    color: ${props => props.theme.color.accentColor2};
`

//! USE THESE NEW TEXT STYLES DEFINED BY THE DESIGN TEAM INSTEAD
/*

    GENERAL PROPS:

        regular, semibold, bold: pass these props to set the weight of the variant that you are using (will override the default we set)
        center: pass this prop to center the text
        underline: sets the text decoration on the font
        uppercase: transform the text to be all uppercase
        colorInherit: ignore the default color of the font and use whatever the parent specifies, this is used most in buttons

    SETTING COLORS:

        <Body color="accentColor1">words</Body>

        Setting the color attribute to the string name of a color in the theme will grab the appropriate color from the theme. If not set the font variant will use whatever the default value is.

            Choosing between colors: Example where we want to change the color based on status

        <Small color={isActive ? "accentColor3" : "errorNegativeButtonColor"}>STATUS</Small>

    MAKING LINKS:

        Passing an onClick event to a font variant will automatically make the cursor a pointer on hover. Typically we also want it to be underlined and show a different color, so a link could look like this.

        <Body underline color="accentColor2" onClick={() => window.open('google.com')}>CLICK ME</Body>


 */

// Define al of the props that we want to expose
export type TypographyProps = {
    regular?: boolean,
    semibold?: boolean,
    bold?: boolean,
    center?: boolean,
    underline?: boolean,
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void,
    uppercase?: boolean,
    colorInherit?: boolean,
    colorUnset?: boolean,
    ellipsis?: boolean,
    inline?: boolean,
    italic?: boolean,
    small?: boolean,
    xs?: boolean,
    error?: boolean,
    cursor?: boolean,
    disabled?: boolean,
    noTransformCase?: boolean,
    color?: keyof ColorTheme,
    background?: Hex,
    fontSize?: string,
    sizeInherit?: boolean,
}

const sharedStyles = css<TypographyProps>`
    ${({regular}) => regular && fontWeightRegular}
    ${({semibold}) => semibold && fontWeightSemiBold}
    ${({bold}) => bold && fontWeightBold}
    ${({center}) => center && textAlignCenter}
    ${({underline}) => underline && textUnderline}
    ${({onClick}) => onClick && textClickable}
    ${({uppercase}) => uppercase && textUppercase}
    ${({colorInherit}) => colorInherit && 'color: inherit;'}
    ${({colorUnset}) => colorUnset && 'color: unset;'}
    ${({ellipsis}) => ellipsis && textEllipsis}
    ${({inline}) => inline && textInline}
    ${({italic}) => italic && 'font-style: italic;'}
    ${({small}) => small && 'font-size: 14px;'}
    ${({xs}) => xs && 'font-size: 12px;'}
    ${({error}) => error && textColorError}
    ${({cursor}) => cursor && 'cursor: pointer;'}
    ${({disabled}) => disabled && textDisabled}
    ${({sizeInherit}) => sizeInherit && 'font-size: inherit;'}

    .breakAll {
        word-break: break-all;
    }
`
export const fontWeightRegular = css`
    font-weight: 400; // pass regular prop
    font-family: ${({theme}) => theme.font.body?.family};
`
export const fontWeightSemiBold = css`
    font-weight: 600; // pass semibold prop
    font-family: ${({theme}) => theme.font.semiboldBody?.family};
`
export const fontWeightBold = css`
    font-weight: 700; // pass bold prop
    font-family: ${({theme}) => theme.font.boldBody?.family};
`
const textAlignCenter = css`
    text-align: center;
`

const textUppercase = css`
    text-transform: uppercase;
`

const textUnderline = css`
    text-decoration: underline;
`
const textClickable = css`
    cursor: pointer;
`
const textEllipsis = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
const textColorError = css`
    color: ${props => props.theme.color.errorNegativeButtonColor};
`

export const textDisabled = css`
    color: ${(props) => hexToRgba(props.theme.color.textColor1, 0.6)} !important;
`

const textInline = css`
    display: inline;
`

export const Title = styled.div<TypographyProps>`
    font-family: ${({theme}) => theme.font.title?.family};
    font-size: 42px;
    color: ${getColorFromVariant("textColor1")};

    ${sharedStyles}
`

export const H1 = styled.h1<TypographyProps>`
    font-family: ${({theme}) => theme.font.h1?.family};
    font-size: 32px;
    color: ${getColorFromVariant("textColor2")};

    ${sharedStyles}
`

export const H2 = styled.h2<TypographyProps>`
    font-family: ${({theme}) => theme.font.h2?.family};
    font-size: 24px;
    color: ${props => getColorFromVariant(props.color || "textColor2")};

    ${sharedStyles}
`

export const H3 = styled.h3<TypographyProps>`
    font-family: ${({theme}) => theme.font.h3?.family};
    font-size: 14px;
    color: ${getColorFromVariant("textColor2")};
    ${sharedStyles}
`

export const H4 = styled.h4<TypographyProps>`
    font-family: ${({theme}) => theme.font.h4?.family};
    font-size: 14px;
    ${({noTransformCase}) => !noTransformCase && textUppercase}
    color: ${getColorFromVariant("textColor1")};

    ${sharedStyles}
`

export const BigBody = styled.p<TypographyProps>`
    font-family: ${({theme}) => theme.font.body?.family};
    line-height: 1.5rem;
    font-size: 18px;
    color: ${props => getColorFromVariant(props.color || "textColor1")};
    ${sharedStyles}
`

export const BodyCss = css<TypographyProps>`
    font-family: ${({theme, bold, semibold}) => {
        if (bold) return  theme.font.boldBody?.family
        if (semibold) return theme.font.semiboldBody?.family
        return theme.font.body?.family
    }};
    font-size: 16px;
`

export const Body = styled.p<TypographyProps>`
    ${BodyCss}
    color: ${props => getColorFromVariant(props.color || "textColor1")};
    display: block;

    ${sharedStyles}
`

export const Small = styled.p<TypographyProps>`
    font-family: ${({theme}) => theme.font.body?.family};
    font-size: 14px;
    color: ${props => getColorFromVariant(props.color || "textColor1")};

    ${sharedStyles}
`

export const XS = styled.p<TypographyProps>`
    font-family: ${({theme}) => theme.font.body?.family};
    font-size: 12px;
    color: ${props => props.background ? decideTextColor(props.background) : getColorFromVariant(props.color || "textColor1")};
    ${sharedStyles}
`
// TODO - Rename to what design wants
export const XXS = styled.p<TypographyProps>`
    font-family: ${({theme}) => theme.font.body?.family};
    font-size: 8px;
    color: ${props => props.background ? decideTextColor(props.background) : getColorFromVariant(props.color || "textColor1")};
    ${sharedStyles}
`

export const mapTextSizeToTypography = {
    'xs' : XS,
    'sm' : Small,
    'bigbody' : BigBody,
    'title' : Title,
}

// Unordered Bullet List Wrapper Styles
export const UL = styled.ul`
    list-style-position: outside;
    list-style-type: disc;
    text-indent: 0rem;
    padding: 0 0 0 1rem;
`
export const OL = styled.ul`
    list-style-position: outside;
    list-style-type: decimal;
    text-indent: 0rem;
    padding: 0 0 0 1rem;
`

// Here we are extending the props of the TypographyProps type
type TextBubbleProps = {
    status?: 'selected' | 'deselected' | 'disabled'
} & TypographyProps

type TextBubbleContainerProps = {
    wrap?: string
    spacing?: string
    readOnly?: boolean
}

// SECTION: Styles not defined by design but used here
/* margin: 2px; */
export const TextBubbleContainer = styled.div<TextBubbleContainerProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: ${props => props.wrap ?? 'wrap'};
    gap: ${props => props.spacing ?? '0.5rem'};

    ${props => props.readOnly && css`pointer-events: none;`}
`
export const TextBubble = styled(XS)<TextBubbleProps>`
    ${sharedStyles}
    display: flex;
    align-items: center;
    height: 24px;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'}; //bigger size made demographic targeting look squished so setting as 10 for now
    background-color: ${props => props.theme.color.accentColor2};
    color: ${props => decideTextColor(props.theme.color.accentColor2)};
    border-radius: 20px;
    padding: .5rem;
    white-space: nowrap;

    ${({status}) => status === 'disabled' && css`
        cursor: pointer;
        background-color: ${props => hexToRgba(props.theme.color.textColor1, 0.6)};
        color: ${props => props.theme.color.textColor3};
    `}
    ${({status}) => status === 'selected' && css`
        cursor: pointer;
        background-color: ${props => props.theme.color.accentColor2};
        color: ${props => decideTextColor(props.theme.color.accentColor2)};
    `}
    ${({status}) => status === 'deselected' && css`
        cursor: pointer;
        background-color: ${props => props.theme.color.accentColor4};
        color: ${props => decideTextColor(props.theme.color.accentColor4)};
        ${fontWeightRegular};
    `}
`

type StatusBubbleProps = {
    variant: undefined | 'active' | 'draft' | 'inactive'
} & TypographyProps
export const StatusBubble = styled(XS)<StatusBubbleProps>`
    border: 1px solid ${props => props.theme.color.backgroundColor2};
    color: ${props => props.theme.color.accentColor2};
    border: 0.5px;
    border-radius: 54px;
    padding: 2px 8px;

    ${({variant}) => variant === 'active' && css`
        color: ${props =>props.theme.color.activeButtonColor};
        border: 1px solid ${props => props.theme.color.activeButtonColor};
    `}
    ${({variant}) => variant === 'draft' && css`
        color: ${props => hexToRgba(props.theme.color.textColor1, 0.6)};
        border: 1px solid ${props => hexToRgba(props.theme.color.textColor1, 0.6)};
    `}
    ${({variant}) => variant === 'inactive' && css`
        color: ${props => props.theme.color.errorNegativeButtonColor};
        border: 1px solid ${props => props.theme.color.errorNegativeButtonColor};
    `}
`

export const ButtonTextCss = css`
    font-family: ${({theme}) => theme.font.button?.family};
    font-size: 16px;
    letter-spacing: 2%;
    color: ${props => getColorFromVariant(props.color || "textColor1")};
    ${sharedStyles}
`

// Here we are extending the props of the TypographyProps type
type LinkProps = {
    nodecoration?: boolean
} & TypographyProps

//TODO Possibly rename this component to avoid name clash with 'react-router-dom's Link component
export const Link = styled.a<LinkProps>`
    ${fontWeightSemiBold}
    text-decoration: ${props => props.nodecoration ? "none" : "underline"};
    color: ${props => getColorFromVariant(props.color || "accentColor2")};
    cursor: pointer;

    ${sharedStyles}
`

// Here we are extending the props of the TypographyProps type
type SpanProps = {
    applyBodyStyles?: boolean
} & TypographyProps

//* use Span when you need nested elements to have different styles right now it supports only sharedStyles
export const Span = styled.span<SpanProps>`
    ${props => props.applyBodyStyles && BodyCss}
    color: ${props => getColorFromVariant(props.color || "textColor1")};
    ${sharedStyles}
`

type HRProps = {
    spacing?: number
    width?: string
}
export const HR = styled.hr<HRProps>`
    border: none;
    height: ${props => props.width ?? '1px'};
    background-color: ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
    width: 100%;
    margin: ${props => `${props.spacing ?? '1.5'}rem 0`};
`
export type CustomFontProps = {
    font: EulerityFont | undefined
} & React.HTMLAttributes<HTMLDivElement>

export const CustomFont = (props: CustomFontProps) => {
    return (
        <div
            {...props}
            style={{
                fontFamily: props?.font?.family,
                fontWeight: props?.font?.bold ? 'bold' : 'normal',
                fontStyle: props?.font?.italic ? 'italic' : 'normal',
                ...props.style
            }}
        >
            {props.children}
        </div>
    )
}
