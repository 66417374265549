import styled, { css } from 'styled-components'
import { decideTextColor, hexToRgba } from '../helpers/colors.util'
import ReactSelect, { StylesConfig, components } from 'react-select'
import Creatable from 'react-select/creatable'
import { BodyCss } from './typography'
import { createBoxShadow } from './card'

export const StyleInputRange = styled.input`
    &[type='range'] {
        -webkit-appearance: none;
        background-color: ${(props) => props.theme.color.backgroundColor2};
    }
    &[type='range']:focus {
        outline: none;
    }
    &[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: ${(props) =>
            hexToRgba(props.theme.color.accentColor2, 0.4)};
        border-radius: 18px;
    }
    &[type='range']::-webkit-slider-thumb {
        box-shadow: 0px 0px 2px
            ${(props) => hexToRgba(props.theme.color.accentColor2, 0.5)};
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background: ${(props) => props.theme.color.accentColor2};
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
    }
    &[type='range']:focus::-webkit-slider-runnable-track {
        background: ${(props) => hexToRgba(props.theme.color.accentColor2)};
    }
    &[type='range']::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;

        background: ${(props) =>
            hexToRgba(props.theme.color.accentColor2, 0.5)};
        border-radius: 18px;
    }
    &[type='range']::-moz-range-thumb {
        box-shadow: 0px 0px 2px
            ${(props) => hexToRgba(props.theme.color.accentColor2, 0.5)};
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background: ${(props) => props.theme.color.accentColor2};
        cursor: pointer;
    }
    &[type='range']::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;

        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    &[type='range']::-ms-fill-lower {
        background: ${(props) => hexToRgba(props.theme.color.accentColor2)};
        border-radius: 36px;
    }
    &[type='range']::-ms-fill-upper {
        background: ${(props) =>
            hexToRgba(props.theme.color.accentColor2, 0.4)};
        border-radius: 36px;
    }
    &[type='range']::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 2px
            ${(props) => hexToRgba(props.theme.color.accentColor2, 0.5)};
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background: ${(props) => props.theme.color.accentColor2};
        cursor: pointer;
    }
    &[type='range']:focus::-ms-fill-lower {
        background: ${(props) => hexToRgba(props.theme.color.accentColor2)};
    }
    &[type='range']:focus::-ms-fill-upper {
        background: ${(props) =>
            hexToRgba(props.theme.color.accentColor2, 0.4)};
    }
`

const CreateNewLabelStyles = css`
    font-weight: 600;
    color: ${props => props.theme.color.accentColor2} !important;
    text-decoration: underline;
    cursor: pointer !important;
`

export const CustomOption = styled(components.Option)`
    ${props => props.data.__isNew__ && CreateNewLabelStyles}
`;

//* React-Select custom styling
type BaseReactSelectProps = {
    maxHeight?: number;
    isMulti?: boolean;
    value?: any[];
    expandHeight?: boolean;
    fullWidthLabel?: boolean;
}

const BaseReactSelectStyles = css<BaseReactSelectProps>`
    flex-grow: 1;
    font-family: ${props =>  props.theme.font?.body?.family};

    .Select__control {
        background-color: transparent;
        border-color: ${(props) =>
            hexToRgba(props.theme.color.accentColor3, 0.8)};
        box-shadow: none;
        ${(props) => {
            if (props.maxHeight) {
            return css`
                max-height: ${props.maxHeight};
                overflow-y: scroll;
            `;
            }
        }}

        &:hover {
            border-color: ${(props) => props.theme.color.accentColor2};
        }

        &--is-disabled {
            border: 1px solid ${props => props.theme.color.greyAccent1};
            background-color: ${props => props.theme.color.greyAccent2};

            .Select__dropdown-indicator {
                color: ${props => props.theme.color.greyAccent1};
            }

            .Select__indicator-separator {
                background-color: ${props => props.theme.color.greyAccent1};
            }

            .Select__value-container {
                background-color: ${props => props.theme.color.greyAccent2};
            }

            .Select__single-value {
                color: ${props => hexToRgba(props.theme.color.textColor1, 0.6)};
            }
        }
    }

    .Select__indicators {
        max-height: inherit;
        position: sticky;
        top: 0;
    }

    .Select__clear-indicator,
    .Select__dropdown-indicator {
        color: ${(props) => hexToRgba(props.theme.color.accentColor2, 0.8)};

        &:hover {
            color: ${(props) => props.theme.color.accentColor2};
        }
    }

    .Select__input {
        color: ${props => props.theme.color.textColor2};
    }

    .Select__input input {
        font-family: ${(props) => props.theme.font?.body?.family};
    }

    .Select__group-heading {
        color: ${(props) => props.theme.color.textColor2};
    }

    .Select__indicator-separator {
        background-color: ${(props) => props.theme.color.accentColor2};
    }

    .Select__value-container {
        ${({ isMulti, value, expandHeight }) =>
            isMulti && value?.length && !expandHeight
            ? 'max-height: 100px; overflow-y: scroll;'
            : ''}
        background: ${(props) => props.theme.color.backgroundColor2};
        padding: 0 10px;
        .useAccent {
            color: unset;
        }
    }

    .Select__menu {
        background: ${(props) => props.theme.color.backgroundColor2};
        color: ${(props) => props.theme.color.textColor1};
        box-shadow: ${createBoxShadow};
        .useAccent {
            color: ${(props) => props.theme.color.accentColor2};
        }
    }

    .Select__option {
        background-color: ${(props) => props.theme.color.backgroundColor2};
        color: ${(props) =>
            decideTextColor(props.theme.color.backgroundColor2)};
        &--is-focused {
            background-color: ${(props) => props.theme.color.accentColor4};
            color: ${(props) =>
            decideTextColor(props.theme.color.accentColor4)};
        }
        &--is-selected {
            background-color: ${(props) => props.theme.color.accentColor3};
            color: ${(props) =>
            decideTextColor(props.theme.color.accentColor3)};
        }
        &--is-disabled {
            opacity: 0.35;
            background-color: ${(props) => props.theme.color.greyAccent2};
            color: ${(props) => decideTextColor(props.theme.color.greyAccent2)};
        }
        :active {
            background-color: ${(props) => props.theme.color.accentColor3};
            color: ${(props) =>
            decideTextColor(props.theme.color.accentColor3)};
        }
    }

    .Select__single-value {
        color: ${(props) => props.theme.color.textColor1};
    }

    .Select__multi-value {
        ${(props) => props.fullWidthLabel && 'width: 100%;'}
        ${({ theme }) =>
            `background-color: ${theme.color.accentColor2};
            color: ${theme.color.textColor3};`}
    }

    .Select__multi-value__label {
        ${(props) => props.fullWidthLabel && 'width: 100%;'}
        ${({ theme }) => `color: ${theme.color.textColor3};`}
    }
`;

//* Docs for styling fixed label options: https://react-select.com/home#fixed-options
export const customCreatableStyles: StylesConfig<{ label: string, value: string, isFixed?: boolean}, true> = {
    multiValue: (base, state) => {
        const theme = state.theme as unknown as { color: { greyAccent2: string } }
        return state.data.isFixed ? {
        ...base,
        backgroundColor: `${theme.color.greyAccent2} !important`,
        } : base;
    },
    multiValueLabel: (base, state) => {
        const theme = state.theme as unknown as { color: { textColor1: string } }
        return state.data.isFixed
        ? { ...base, color: `${theme.color.textColor1} !important`, opacity: '0.6', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

const CustomSelect = styled(ReactSelect).attrs({
    classNamePrefix: 'Select'
})`
    ${BaseReactSelectStyles}
`

//* For creatable react select component
const CustomCreatableSelect = styled(Creatable).attrs({
    classNamePrefix: 'Select'
})`
    ${BaseReactSelectStyles}
`

export const LeftJoinedCustomSelect = styled(CustomSelect)<{
	hideJoinedSideBorder?: boolean
}>`
	.Select__control {
        height: 3rem;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
        overflow: hidden;

		${(props) =>
			props.hideJoinedSideBorder
				? css`
						border-right: none;
				  `
				: ''}
	}

    .Select__value-container {
        height: 100%;
    }

    .Select__indicator-separator {
        margin-top: .35rem;
        margin-bottom: .35rem;
    }

	.Select__control--is-focused {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		${(props) =>
			props.hideJoinedSideBorder
				? css`
						border-right: none;
				  `
				: ''}
	}

    .Select__group {
        padding: 0px;
    }

    .Select__menu-list {
        padding: 0px;
    }
`

export const RightJoinedCustomSelect = styled(CustomSelect)<{
	hideJoinedSideBorder?: boolean
}>`
	.Select__control {
        height: 3rem;
        overflow: hidden;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		${(props) =>
			props.hideJoinedSideBorder
				? css`
						border-left: none;
				  `
				: ''}
	}

    .Select__value-container {
        height: 100%;
    }

    .Select__indicator-separator {
        margin-top: .35rem;
        margin-bottom: .35rem;
    }

	.Select__control--is-focused {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		${(props) =>
			props.hideJoinedSideBorder
				? css`
						border-left: none;
				  `
				: ''}
	}

    .Select__group {
        padding: 0px;
    }

    .Select__menu-list {
        padding: 0px;
    }
`

export const sharedInputsStyles = css`
    background: transparent;
    color: ${(props) => props.theme.color.textColor1};
    border: 1px solid ${(props) => props.theme.color.accentColor3};
    border-radius: 7px;
    padding: 0.5rem;
`

const StyleInput = styled.input`
    ${sharedInputsStyles}
`

export const inputStyles = css<{
    invalid?: boolean
    readOnly?: boolean
    height?: string
    width?: string
    disabled?: boolean
}>`
    all: unset;
    background: ${(props) => props.theme.color.backgroundColor2};
    border: solid 1px ${(props) => props.theme.color.accentColor3} !important;
    padding: 0.75rem;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

    ${({ theme, invalid }) =>
        invalid &&
        `color: ${theme.color.errorNegativeButtonColor}; border-color: ${theme.color.errorNegativeButtonColor} !important;`}
    ${({ theme, readOnly }) => readOnly && `color: ${theme.color.greyAccent1};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${BodyCss}

  &:focus {
        box-shadow: ${createBoxShadow};
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

//! NEW IMPLEMENTATION
const Input = styled.input`
    ${inputStyles};
`

const Textarea = styled.textarea<{
    height?: string
    width?: string
}>`
    all: unset;
    background: ${(props) => props.theme.color.backgroundColor2};
    border: solid 1px ${(props) => props.theme.color.accentColor3} !important;
    padding: 0.75rem !important;
    border-radius: 6px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    ${(props) => props.height && `height: ${props.height};`}
    ${(props) => props.width && `width: ${props.width};`}

    &:focus {
        box-shadow: ${createBoxShadow};
    }

    // need to add body css
`
// ${BodyCss}

// styled the react-phone-input-2 component
const PhoneInputWrap = styled.div`
    .form-control {
        ${inputStyles};
        margin-left: 4rem;
        width: calc(100% - 4rem);
    }

    .flag-dropdown {
        background-color: ${(props) => props.theme.color.backgroundColor2};
        border: solid 1px ${(props) => props.theme.color.accentColor3};
        padding: 0px 0.5rem;
        border-radius: 6px;
    }

    .flag-dropdown.open,
    .flag-dropdown:hover {
        background-color: ${(props) => props.theme.color.backgroundColor1};
    }

    .selected-flag.open,
    .selected-flag:hover {
        background-color: ${(props) =>
            props.theme.color.backgroundColor1} !important;
    }

    .country-list {
        background-color: ${(props) => props.theme.color.backgroundColor2};
    }

    .country.active {
        background-color: ${(props) =>
            props.theme.color.backgroundColor2} !important;
    }

    .country:hover,
    .country.highlight {
        background-color: ${(props) =>
            props.theme.color.backgroundColor1} !important;
    }
`

export { CustomSelect, CustomCreatableSelect, StyleInput, Textarea, Input, PhoneInputWrap }
export default Input
