import styled, {css} from "styled-components";
import { Card } from "../../styles/card";
import { BTStyles } from "./types";
export const StyleJobIndicator = styled(Card)<BTStyles>`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 394px;
    min-height: 64px;
    border-radius: 5px;
    z-index: 999;
    display: grid;
    ${p => !p.visible && css`display: none;`}

    .bj-indicators_icon {
        margin-top: 3px;
        transition: all 0.3s;
        min-width: 15px;
        svg {
            margin-top: 5px;
        }
    }
`

export const StyleJobIndicatorHeader = styled(Card)`
    height: 64px;
    display: flex;
    align-items: center;
    color: black;
    justify-content: space-between;
    padding: 10px;

    .bj-indicators,
    .bj-actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .bj-actions {
        &>div {
            cursor: pointer;
            width: 25px;
            height: 25px;
            display: grid;
            place-content: center;
            border-radius: 100%;
            transition: all 0.3s;
        }
        &>div:hover {
            background-color: ${prop => prop.theme.color.accentColor4}
        }
    }

`

export const StyleJobIndicatorJobs = styled.div`
  width:  394px;
  height: 200px;
  cursor: pointer;
`

export const StyleJobIndicatorContent = styled.div`
    visibility: hidden;
    height: max-content;
    width: 100%;
    border-radius: 0;
    padding: 10px;
`


export const StyleJob = styled.div`
    width: 100%;
    height: 65px;
    transition: all 0.4s;
    display: flex;
    justify-content: space-between;
    padding: 3px;
   &:hover {
    background-color: ${prop => prop.theme.color.accentColor4}
   }

   .job-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
   }
   .job-status_group {
    display: flex;
    align-items: center;
    gap: 7px;
   }

`