import styled, {css} from 'styled-components';
import { decideTextColor, hexToRgba } from '../helpers/colors.util';
import { ButtonTextCss, textDisabled } from './typography';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
// because those css rules may be able to just be added here globally

const sharedButtonStyles = css`
    ${ButtonTextCss}
    height: 38px;
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 9px;
    border:none;
    padding: 8px 24px;
    letter-spacing: 0.02em;
    transition: background-color 1s linear;
    ${({width}) =>
        width && css `
           width: ${props => props.width}
    `}
`

const primaryDisabledButtonStyles = css`
    ${({disabled}) =>
        disabled && css `
            background-color: ${(props) => props.theme.color.greyAccent2} !important;
            ${textDisabled}
    `}
`

const secondaryDisabledButtonStyles = css`
    ${({disabled}) =>
        disabled && css `
           opacity: 0.5;
    `}
`

// container
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.column ? 'column' : 'row'};
    justify-content: center;
    margin: 24px auto;
    gap: 1.5rem;
    button, a {
        min-width: 160px;
    }
`

//primary cta btn #

const primaryBtnStyles = css`
     ${sharedButtonStyles}
     background: ${(props) => hexToRgba(props.theme.color.accentColor1, 0.85)};
     color: ${props => decideTextColor(props.theme.color.accentColor1)};

     ${primaryDisabledButtonStyles}
     &:hover {
        background: ${props => props.theme.color.accentColor1};
    }
`

export const PrimaryCTABtn = styled.button`
    ${primaryBtnStyles}
`

export const PrimaryCTALink = styled.a`
    ${primaryBtnStyles}
`

//outline btn

const outlineBtnSyles = css`
    ${sharedButtonStyles}
    background: none;
    color:${(props) => hexToRgba(props.color ?? props.theme.color.textColor1, 0.85)};
    border: 1px solid ${props => props.color ?? props.theme.color.textColor1};
    ${secondaryDisabledButtonStyles}
    &:hover {
        background: ${props => hexToRgba(props.color ?? props.theme.color.textColor1, .1)};
    }
`

export const OutlineBtn = styled.button`
    ${outlineBtnSyles}
`

export const OutlineBtnLink = styled.a`
    ${outlineBtnSyles}
`

//confirm/resume
export const ConfirmBtn = styled.button`
    ${sharedButtonStyles}
    background: ${(props) => hexToRgba(props.theme.color.activeButtonColor, 0.85)};
    color: ${props => decideTextColor(props.theme.color.activeButtonColor)};
    ${primaryDisabledButtonStyles}
    &:hover {
        background: ${props => props.theme.color.activeButtonColor};
    }
`

//delete/error
export const DangerBtn = styled.button`
    ${sharedButtonStyles}
    background: ${(props) => hexToRgba(props.theme.color.errorNegativeButtonColor, 0.85)};
    color: ${props => decideTextColor(props.theme.color.errorNegativeButtonColor)};
    ${primaryDisabledButtonStyles}
    &:hover {
        background: ${props => props.theme.color.errorNegativeButtonColor};
    }
`

export const BackgroundBtn = styled.button`
    ${sharedButtonStyles}
    background: ${(props) => props.theme.color.backgroundColor1};
    color: ${props => decideTextColor(props.theme.color.backgroundColor1)};
    border: 1px solid ${props => props.theme.color.textColor1};
    ${secondaryDisabledButtonStyles}
`

export const IconBtn = styled.button`
  ${sharedButtonStyles}
  background-color: unset;
  padding: 0.59rem;
`

export const EditIcon = styled(FiEdit3)`
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.color.greyAccent1};
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme.color.textColor1};
  }
`
export const ReactDeleteIcon = styled(FiTrash2)`
    color: ${props => props.theme.color.accentColor2};
    cursor: pointer;
`


