import React, { useRef } from 'react';
import { useTooltip } from './useTooltip';
import useEventListener from '../../hooks/useEventListener';

type TooltipTriggerProps = {
  tooltipContent: React.ReactNode;
  children: React.ReactElement;
};

export const TooltipTrigger = ({
  tooltipContent,
  children,
}: TooltipTriggerProps) => {
  const { showTooltip, hideTooltip } = useTooltip();
  const ref = useRef<HTMLElement>(null);

  // * In a case where the tooltip is clicked we will hide it (such as having it in a dropdown)
  useEventListener('click', hideTooltip)

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (ref.current) {
      showTooltip(tooltipContent, ref.current);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.stopPropagation();
    hideTooltip();
  };

  return React.cloneElement(children, {
    ref,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  });
};
