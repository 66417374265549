// * Depending on the tab the user is in, we will display a wiki link in the footer.
// Users have access to only a subset of what managers have access to.
// So not all routes will have a userWiki to display.

type PageInfo ={
    managerWiki: string;
    userWiki: string;
    descriptionText: string;
}

const pageInfo = {
    '/summary': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/MDCb1FW5',
        userWiki: '',
        descriptionText: "Summary Tab"
    },
    '/locations': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/51w1NS0q',
        userWiki: '',
        descriptionText: "Business & Campaigns Tab"
    },
    '/insights': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/wPSf1Avs',
        userWiki: '',
        descriptionText: 'Insights Tab'
    },
    '/keywords': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/oBoXfNaq',
        userWiki: '',
        descriptionText: 'Keywords Tab'
    },
    '/labels': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/xSCXU6Z4',
        userWiki: '',
        descriptionText: 'Labels Tab'
    },
    '/audiences': {
        managerWiki: 'https://eulerity.atlassian.net/l/c/mVkSm1Pp',
        userWiki: '',
        descriptionText: 'Audiences Tab'
    },
    '/calendar': {
        managerWiki: 'https://eulerity.atlassian.net/wiki/spaces/ZW/pages/420675634/Calendar+Tab',
        userWiki: '',
        descriptionText: 'Calendar Tab'
    },
} satisfies Record<string, PageInfo>;

type PageRoute = keyof typeof pageInfo;

export { pageInfo, PageRoute };
