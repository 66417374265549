import _ from "lodash"
import { Network, Ad, Post } from "../../../features/creatives/creative.types"
import { CreativeKind } from "../../../helpers/creative.util"
import { EulerityLocation } from "../../../types/location"
import { CollaboratorType } from "./steps/CreativeStepInstagramCollaborators"

type FieldsToCopy<T> = (keyof T)[]

// *TEMPLATE : First copy fields from an ad or post that cannot be modified by the Accept Flow
export const copyFromTemplateCreative = (creativeTemplate: Ad | Post): Record<string, any> => {
    const creative: Record<string, any> = {}

    // * Because we are making a new creative, there are only some fields which we want to copy over
    // ! These are fields which cannot be edited in this Accept Flow
    const fieldsToCopy = [
        // All Creatives
        'kind',
        'template',
        'premade',
        'extendedText',
        'preview',
        // Ad Fields
        'cta',
        'searchHeadlines',
        'searchDescriptions',
        'keywordGroup',
        // Image Creatives
        'header',
        'description',
        'image',
        'logo',
        'logoBox',
        'useLocationLogoOnClone',
        // Video Creatives
        'youTubeId',
        'media',
    ] as FieldsToCopy<Ad | Post>

    fieldsToCopy.forEach(field => {
        if (creativeTemplate.hasOwnProperty(field)) creative[field] = _.cloneDeep(creativeTemplate[field])
    })

    return creative
}

export type AcceptFormData = {
    kind: CreativeKind
    selectedLocations: EulerityLocation[] | null
    targetNetworks: Network[] | null
    landingPage: string
    start: Date | null
    end: Date | null
    peakStartDate: string | null
    instagramCollaborators?: CollaboratorType[]
}

type AddAcceptFormTemplateFieldsProps = {
    creative: ReturnType<typeof copyFromTemplateCreative>, // A creative has been partially defined
    source: AcceptFormData // the source we need to grab the rest of the fields from
    proceedWithUrl: boolean
}

// * TEMPLATE : Secondly, add the rest from the form flow if they are truthy values
export const addRequiredTemplateFields = ({creative, source, proceedWithUrl}: AddAcceptFormTemplateFieldsProps): Partial<Ad | Post> => {
    if (source.start) creative.start = source.start.toISOString()
    if (source.end) creative.end = source.end.toISOString()

    // * Post Fields
    if (source.targetNetworks) creative.targetNetworks = _.cloneDeep(source.targetNetworks)
    if (source.peakStartDate) creative.peakStartDate = _.cloneDeep(source.peakStartDate)
    if (source.instagramCollaborators) creative.instagramCollaborators = source.instagramCollaborators.map(collaborator => collaborator.name)

    // * Ad Fields
    if (source.landingPage) {
        creative.landingPage = source.landingPage
        creative.skipWebsiteValidation = proceedWithUrl
    }

    // The campaigns to push this to
    creative.userLocations = source.selectedLocations?.map(opt => opt.value)
    return creative
}

//* MAGIC: For magics, the only fields needed in the payload are updatedFields
export type UpdatedAcceptFields = Partial<{
    targetNetworks: Network[],
    landingPage: string
    start: Date | null
    end: Date | null
    peakStartDate: string
    instagramCollaborators: CollaboratorType[]
}>

type AcceptPayload = Omit<UpdatedAcceptFields, 'start' | 'end' | 'instagramCollaborators'> & Partial<{
    start: string | null
    end: string | null
    skipWebsiteValidation: boolean
    instagramCollaborators: string[]
}>

export const getUpdatedAcceptFields = (updatedFields: UpdatedAcceptFields, proceedWithUrl: boolean): AcceptPayload => {
    const payload: AcceptPayload = {}
    if (updatedFields.hasOwnProperty('targetNetworks')) payload.targetNetworks = updatedFields.targetNetworks
    if (updatedFields.hasOwnProperty('landingPage')) {
        payload.landingPage = updatedFields.landingPage
        payload.skipWebsiteValidation = proceedWithUrl
    }
    if (updatedFields.hasOwnProperty('start') && !updatedFields.peakStartDate) payload.start = updatedFields.start?.toISOString() ?? null
    if (updatedFields.hasOwnProperty('end')) payload.end = updatedFields.end?.toISOString() ?? null
    if (updatedFields.hasOwnProperty('peakStartDate')) payload.peakStartDate = updatedFields.peakStartDate
    if (updatedFields.hasOwnProperty('instagramCollaborators')) payload.instagramCollaborators = updatedFields.instagramCollaborators?.map(collaborator => collaborator.name)
    return payload
}