import React from 'react'
import { useCreativeContext } from '../CreativeModalV2'
import { GrStatusWarning } from 'react-icons/gr'
import { BigBody } from '../../../../styles/typography'
import { Small } from '../../../../styles/typography'
import { DisclaimerContainer, NetworkStatusWrap } from '../styles'
import {
    checkAllPostsFailed,
    isPostEntity
} from '../../../../helpers/creative.util'
import { getFutureDate } from '../../../../helpers/date.util'
import { Show } from '../../../../components/reusableUI/components/Show'
import NetworkStatusItem from './NetworkStatusItem'

const PostNetworkStats = () => {
    const { creative } = useCreativeContext()

    if (!isPostEntity(creative)) {
        throw new Error(
            'PostNetworkStats.tsx component can only be used with Post entities'
        )
    }

    const { targetNetworks } = creative

    return (
        <NetworkStatusWrap>
            <BigBody semibold>Platform Status</BigBody>
            {targetNetworks.map(key => {
                return <NetworkStatusItem post={creative} key={key} network={key} />
            })}
            <Show
                when={ checkAllPostsFailed(creative) }
            >
                <DisclaimerContainer>
                    <GrStatusWarning size={30} />
                    <Small>{`Because this post failed to post, this post will automatically be deleted on ${getFutureDate(
                        creative?.postedDate,
                        30
                    )}.`}</Small>
                </DisclaimerContainer>
            </Show>
        </NetworkStatusWrap>
    )
}

export default PostNetworkStats
