import { decideTextColor, hexToRgba } from '../helpers/colors.util'
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToast = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
 })`

  /* Toast Container */
  width: 60%;
  border-radius: 9px;
  font-size: 16px;

  /* Toast Primary -> Default styles (info)*/
  .Toastify__toast {
    border-radius: 0.5625rem;
    background: ${props => props.theme.color.backgroundColor2};
    border: 1px solid;
    border-color: ${props => props.theme.color.greyAccent1};
    color:${props => props.theme.color.textColor1};
    overflow: hidden;
    z-index: 5;
    .progress{
      background-color:${props => hexToRgba(props.theme.color.greyAccent1, 0.30)};
      opacity: 0.25;
      height: 100%;
      z-index: 1;
    }
    .body {
      z-index: 2;
      color:${props => decideTextColor(props.theme.color.backgroundColor2)};
    }
    .Toastify__close-button{
      color: ${props => props.theme.color.greyAccent1};
      display: flex;
      align-self: center;
      svg{
        height: 25px;
        width: 25px;
      }
    }
    .Toastify__toast-icon {
      div, svg {
        transform: scale(1.5);
        fill: ${({theme}) => theme.color.greyAccent1};
      }
    }
  }

  .Toastify__toast--success {
    background: ${props => props.theme.color.backgroundColor2};
    border-color: ${props => props.theme.color.activeButtonColor};
    .progress{
      background-color:${props => hexToRgba(props.theme.color.activeButtonColor, 0.25)};
    }
    .Toastify__close-button{
      color: ${props => props.theme.color.activeButtonColor};
    }
    .Toastify__toast-icon {
      div, svg {
        fill: ${({theme}) => theme.color.activeButtonColor};
      }
    }
  }

  .Toastify__toast--warning {
    background: ${props => props.theme.color.backgroundColor2};
    border-color: ${props => props.theme.color.warningButtonColor};
    .progress{
      background-color:${props => hexToRgba(props.theme.color.warningButtonColor, 0.25)};
    }
    .Toastify__close-button{
      color: ${props => props.theme.color.warningButtonColor};
    }
    .Toastify__toast-icon {
      div, svg {
        fill: ${({theme}) => theme.color.warningButtonColor};
      }
    }
  }

  .Toastify__toast--error {
    background: ${props => props.theme.color.backgroundColor2};
    border-color: ${props => props.theme.color.errorNegativeButtonColor};
    .progress{
      background-color:${props => hexToRgba(props.theme.color.errorNegativeButtonColor, 0.25)};
    }
    .Toastify__close-button{
      color: ${props => props.theme.color.errorNegativeButtonColor};
    }
    .Toastify__toast-icon {
      div, svg {
        fill: ${({theme}) => theme.color.errorNegativeButtonColor};
      }
    }
  }
`;

export { StyledToast }