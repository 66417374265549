import styled from "styled-components";

export const BackButtonContainer = styled.div`
  border-radius: 9px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: auto;
    path {
      stroke: ${({ theme }) => theme.color.accentColor2};
    }
  }
`;