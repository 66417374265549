import queue, { Identifiable } from "./Queue"

// This is a priority queue that allows you to assign a priority to each item in the queue.
// Items with higher priority are dequeued first.
// The priority is assigned by the assignPriority function, which you must provide.

// Example usage:
// const q = priorityQueue((item) => item.priority)
// q.enqueue({ getKey: () => '1', priority: 1 })
// q.enqueue({ getKey: () => '2', priority: 2 })
// q.enqueue({ getKey: () => '3', priority: 3 })
// q.enqueue({ getKey: () => '4', priority: 4 })
// console.log(q.dequeue()) // { priority: 1 }

const priorityQueue = function<T extends Identifiable>(assignPriority: (item: T) => number) {
	const _queue = queue<T>()

	return {
		..._queue,
		enqueue(item: T): void {
			if (_queue.has(item)) return
			const priority = assignPriority(item)
			const i = _queue.values().findIndex((value) => assignPriority(value) > priority)
			_queue.enqueueAt(item, i === -1 ? _queue.size() : i)
		}
	}
}
export default priorityQueue