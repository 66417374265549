import { CATEGORY_TYPES } from "../advancedFilterV2/constants"
import { OBJECTIVE } from "../../helpers/objectives"

export const TAB_TYPES = {
    CAMPAIGNS: 'campaigns',
    FOLDERS: 'folders',
    BLUEPRINTS: 'blueprints'
}

export const OPTION_CATEGORY = {
    PRIMARY_CAMPAIGNS: 'Primary Campaigns',
    SECONDARY_CAMPAIGNS: 'Secondary Campaigns',
    CREATIVE_FOLDERS: 'Creative Folders',
    BLUEPRINTS: 'Blueprints'
}

export const isCampaign = (category) => {
    return category === OPTION_CATEGORY.PRIMARY_CAMPAIGNS || category === OPTION_CATEGORY.SECONDARY_CAMPAIGNS
}

export const isFolder = (category) => {
    return category === OPTION_CATEGORY.CREATIVE_FOLDERS
}

export const isBlueprint = (category) => {
    return category === OPTION_CATEGORY.BLUEPRINTS
}

export const configureTabPrep = (options) => {
    const campaignArr = []
    const folderArr = []
    const blueprintArr = []

    options.forEach(option => {
        if (isCampaign(option?.category)) campaignArr.push(option)
        if (isFolder(option?.category)) folderArr.push(option)
        if (isBlueprint(option?.category)) blueprintArr.push(option)
    })

    return {
        campaigns: {
            options: campaignArr,
            optionsLength: campaignArr?.length
        },
        folders: {
            options: folderArr,
            optionsLength: folderArr?.length
        },
        blueprints: {
            options: blueprintArr,
            optionsLength: blueprintArr?.length
        }
    }
}


export const doesLocationMatch = (option, filters) => {
    let foundMatch = true
    let matchesLabels = false
    const hasNoLabelsSelected = !filters[CATEGORY_TYPES.LABEL]?.length;
    const hasUnlabeledSelected = filters[CATEGORY_TYPES.LABEL].includes("(Unlabeled)");
    const onlyUnlabeledSelected = hasUnlabeledSelected && filters[CATEGORY_TYPES.LABEL].length === 1

    if (hasNoLabelsSelected) {
        matchesLabels = true;
    } else if (onlyUnlabeledSelected) {
        matchesLabels = !option?.labels?.length
    } else if (hasUnlabeledSelected) {
        matchesLabels = option?.labels?.some(label => filters[CATEGORY_TYPES.LABEL].includes(label)) || !option?.labels?.length
    } else  {
        matchesLabels = option?.labels?.some(label => filters[CATEGORY_TYPES.LABEL].includes(label))
    }

    foundMatch = foundMatch && matchesLabels;

    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.TYPE]?.length || filters[CATEGORY_TYPES.TYPE].includes(option?.category))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.USER]?.length || filters[CATEGORY_TYPES.USER].includes(option?.email))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.STATUS]?.length || filters[CATEGORY_TYPES.STATUS].includes(option?.status))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.OBJECTIVE]?.length || filters[CATEGORY_TYPES.OBJECTIVE].includes(OBJECTIVE[option?.objective.toUpperCase()].LABEL))

    return foundMatch
}

export const doesBlueprintMatch = (option, filters) => {
    let foundMatch = true

    // * Blueprints do not contain these categories as properties, if any have selections within them we should filter it out
    const invalidCategories = [
        CATEGORY_TYPES.LABEL,
        CATEGORY_TYPES.USER,
        CATEGORY_TYPES.STATUS,
        CATEGORY_TYPES.OBJECTIVE,
    ]

    for (const invalidCategoryKey of invalidCategories) {
        if (filters[invalidCategoryKey]?.length) {
            return false;
        }
    }

    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.TYPE].length || filters[CATEGORY_TYPES.TYPE].includes(option?.category))

    return foundMatch
}

export const doesDefaultLocationMatch = (option, filters) => {
    let foundMatch = true

    // * The default location does not contain these properties, if any of them have selections we filter it out
    const invalidCategories = [
        CATEGORY_TYPES.LABEL,
        CATEGORY_TYPES.USER,
        CATEGORY_TYPES.STATUS,
        CATEGORY_TYPES.OBJECTIVE,
    ]

    for (const invalidCategoryKey of invalidCategories) {
        if (filters[invalidCategoryKey]?.length) {
            return false;
        }
    }

    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.TYPE].length ||
        (
            option.category === 'default-campaign-setup' ? filters[CATEGORY_TYPES.TYPE].includes('Blueprints') :
                filters[CATEGORY_TYPES.TYPE].includes(option?.category)
        )
    )

    return foundMatch
}