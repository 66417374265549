import { gmtToLocaleString } from "../../../../../../../helpers"
import columnBuilder from "../../../columnBuilder"

const EARLIEST_DATE = -86400000

const generalCategory = columnBuilder({
    category: 'performance',
    subCategory: 'General'
})

const generalColumns = [
    generalCategory
        .name('Activation Date')
        .id('activation-date')
        .makeRow(data => {
            //* Subscriptions[0].created is a string, and subscriptions[] could also be an empty array
            //* We need to return a date object for the column filter to work correctly
            const subscriptions = data.input.location.subscriptions

            return subscriptions.length > 0 ? new Date(subscriptions[0].created) : new Date(EARLIEST_DATE)
        })
        .toString(subscription => {
            if (subscription.getTime() === new Date(EARLIEST_DATE).getTime()) return "-"

            return gmtToLocaleString(subscription.toString(), 'date')
        })
]

export default generalColumns