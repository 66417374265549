import { CREATIVE_KIND } from "../../../helpers/creative.util"
import { FORKS as _F } from "../../../constants/fork"
import { PATH } from "../../../constants/urlPath"
import { hexToRgba } from "../../../helpers/colors.util"
import { Body } from "../../../styles/typography"

export const magicForkHeader = (fork) => {
    switch(fork) {
        case _F.SOCIAL_IMAGE: return `How would you like to deploy your Image Post?`
        case _F.DYNAMIC: return `How would you like to deploy your dynamic ad?`
        case _F.SOCIAL_VIDEO: return `How would you like to deploy your Video Post?`
        case _F.DYNAMIC_VIDEO: return `How would you like to deploy your video ad?`
        default: return ""
    }
}

export const linkController = (fork) => {
    return fork.includes('video') ? PATH.CREATE_VIDEO : PATH.CREATE_IMAGE
}


export const typesHeaderText = (fork) => {
    const creativeType = fork.includes(_F.DYNAMIC) ? "Dynamic Ad" : "Image Post"
    let videoType = fork.includes(_F.DYNAMIC) ? "Video Ad" : "Video Post"
    return `Would you like to create an ${creativeType} or a ${videoType} `
  }


const videoDescriptionText = {
  optVideoAdYoutube:"Our Algorithms use a video you have posted on YouTube for advertising on Facebook, YouTube, and other top websites. This smart technique will automatically process your video to fit all platforms and provide you with solutions for longer videos to be used as ads. Your Video Ad will be deployed as an Optional Ad for your campaign(s) to choose from.",
  optVideoAdUpload: "Our Algorithms automatically process the media you upload to fit all platforms and provide you with solutions for longer videos to be used as ads. An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail. Your Video Ad will be deployed as an Optional Ad for your campaign(s) to choose from.<br/> <strong> Please note uploaded videos will not be run on YouTube.</strong> ",
  autVideoAdYoutube:"Our Algorithms use a video you have posted on YouTube for advertising on Facebook, YouTube, and other top websites. This smart technique will automatically process your video to fit all platforms and provide you with solutions for longer videos to be used as ads. Your Video Ad will automatically publish at your desired start time to the campaigns you select in the next steps.",
  autVideoAdUpload: "Our Algorithms automatically process the media you upload to fit all platforms and provide you with solutions for longer videos to be used as ads. An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail. Your Video Ad will automatically publish at your desired start time to the campaigns you select in the next steps.<br/> <strong> Please note uploaded videos will not be run on YouTube.</strong>",

  optVideoPostYoutube:"Our Algorithms use the videos you have already posted on Youtube for posting. Your custom post can go live immediately or you can schedule your post. Your post will be deployed as an Optional Post for your campaign(s) to choose from.",
  optVideoPostUpload: "Our Algorithms use the media you upload for posting on Facebook, Instagram, and X. Your custom post can go live immediately or you can schedule your post. An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail. Your post will be deployed as an Optional Post for your campaign(s) to choose from.",
  autVideoPostYoutube:"Our Algorithms use the videos you have already posted on Youtube for posting. Your custom post can go live immediately or you can schedule your post. Your video post will automatically publish at your desired start time to the campaigns you select in the next steps.",
  autVideoPostUpload: "Our Algorithms use the media you upload for posting on Facebook, Instagram, and X. Your custom post can go live immediately or you can schedule your post. An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail. Your video post will automatically publish at your desired start time to the campaigns you select in the next steps."
}

const OPT_V_AD_PREW = "Optional Video Ad Preview"
const AUT_V_AD_PREW = "Automatic Video Ad Preview"

const OPT_V_POST_PREV = "Optional Video Post Preview"
const AUT_V_POST_PREW = "Automatic Video Post Preview"


export const creativeTypeHeader = (isMember, creationType, isVideoPost) => {
  if(isMember)  return `Video  ${isVideoPost ? "Post" : "Ad"} Preview`;
  if(creationType === _F.AUTOMATIC_CREATIVE) return isVideoPost ? AUT_V_POST_PREW : AUT_V_AD_PREW
  if(creationType === _F.OPTIONAL_CREATIVE)  return isVideoPost ?  OPT_V_POST_PREV : OPT_V_AD_PREW
}

 export const videoTextController = ({isMember, isVideoPost, creationType, youtubeOrUpload}) => {
    let TEXT = videoDescriptionText
    if(isMember) {
        return !isVideoPost ?
          youtubeOrUpload === _F.YOUTUBE_LINK ? <p>Our Algorithms use a video you have posted on YouTube for advertising on Facebook, YouTube, and other top websites. This smart technique will automatically process your video to fit all platforms and provide you with solutions for longer videos to be used as ads.</p> :  <p>Our Algorithms automatically process the media you upload to fit all platforms and provide you with solutions for longer videos to be used as ads. An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail.<br/> <strong> Please note uploaded videos will not be run on YouTube.</strong></p>
          :
          youtubeOrUpload === _F.YOUTUBE_LINK ?  <p>Our Algorithms use the videos you have already posted on Youtube for posting. Your custom post can go live immediately or you can schedule your post.</p> : <p>Our Algorithms use the media you upload for posting on Facebook. Your custom post can go live immediately or you can schedule your post.  An image thumbnail will be generated automatically from your media. Use the progress bar to scrub through your media and select a still to use for your thumbnail.</p>
    }

        let magic = isVideoPost ? (youtubeOrUpload === _F.YOUTUBE_LINK ? TEXT.autVideoPostYoutube : TEXT.autVideoPostUpload) : (youtubeOrUpload === _F.YOUTUBE_LINK ? TEXT.autVideoAdYoutube : TEXT.autVideoAdUpload);
        let creative = isVideoPost ? (youtubeOrUpload === _F.YOUTUBE_LINK ? TEXT.optVideoPostYoutube : TEXT.optVideoPostUpload) : (youtubeOrUpload === _F.YOUTUBE_LINK ? TEXT.optVideoAdYoutube : TEXT.optVideoAdUpload);

        return creationType === 'creative' ?  <span dangerouslySetInnerHTML={{__html: magic}}></span> : <span dangerouslySetInnerHTML={{__html: creative}}></span>
   }

export const creativeType = (fork) => fork.includes('social') ? _F.POST : _F.AD

export const forkColorController = (props) =>  {
  const isHover = props.isHover;
  let colorType = (linear, color) => (linear ? `linear-gradient(180deg, #F1F1F1 0%, ${color} 100%);` : color)
  colorType = colorType.bind(null, props.linear);
  const color = props.theme.color;
  switch (props.creationType) {
    case _F.AD:
      return isHover ? colorType(color.creativeColor1) : hexToRgba(color.creativeColor1, 0.6);
    case _F.POST:
      return isHover ? colorType(color.creativeColor2) : hexToRgba(color.creativeColor2, 0.6);
    case _F.SMS:
      return isHover ? colorType(color.creativeColor3) : hexToRgba(color.creativeColor3, 0.6);
    case _F.EMAIL:
      return isHover ? colorType(color.creativeColor4) : hexToRgba(color.creativeColor4, 0.6);
    default:
      return isHover ? colorType(color.accentColor2) : hexToRgba(color.accentColor2, 0.6);
  }
}



export const magicDescriptionText = {
    dynamicOptional: `You can deploy an ad to be opted-into by users. Once the ad is accepted or opted into, it will begin running per the scheduled start date.`,
    dynamicAutomatic: `Ads deployed as "Auto" will automatically publish as scheduled.`,
    postOptional: `You can deploy a post to be opted-into by users. Once the post is accepted or opted into, it will be posted per the scheduled date.`,
    postAutomatic: `Posts deployed as "Auto" will automatically published as scheduled.`
 };

export const videoOptionDescriptions = {
    youtube: `Build a video ad quickly by providing us with a YouTube Video URL. Your YouTube URL must be for a public video (not a YouTube Channel or a private video). `,
    upload: `Build a video ad quickly by uploading a video from your computer. `,
    youtubePost: `Build a video post quickly by providing us with a YouTube Video URL. Your YouTube URL must be for a public video (not a YouTube Channel or a private video).`,
    uploadPost: `Build a video post quickly by uploading a video from your computer. `,
    dynamicHeaderText: <p>Choose how to create your <strong>Video Ad</strong></p>,
    socialHeaderText: <p>Choose how to create your <strong>Video Post</strong></p>
}


export const typesOptionDescriptions = {
    dynamic: <Body><strong>Dynamic Ads</strong> are multi-channel paid ads that are automatically resized and reformatted for Facebook, Instagram, Google Display Network (top thousands of websites on the internet), and Google Search.</Body>,
    socialImagePost: <Body><strong>Image Posts</strong> are organic static image and text media that are only shown to followers of your social media pages. </Body>,
    video: <Body><strong>Video Ads</strong> are paid video media that are run on Facebook, Instagram, and sometimes YouTube.</Body>,
    socialVideoPost: <Body><strong>Video Posts</strong> are organic video media that are only shown to followers of your social media pages. </Body>,
  };


export const creativeOptionsDescriptions = {
    custom: `You can quickly create a custom dynamic ad in the next step using our state-of-the-art builder. Our smart adaptive technology will fit your ad for social, search and banners for thousands of the top websites and apps. `,
    premade: `Choose this option if you already have an image you'd like to make an ad. Your ad will be displayed on social, search, and banners on thousands of the top websites and apps. Your Premade Image will NOT be adapted to fit different sizes on social, search, and display. By uploading a Premade Image, some features in the image builder will be unavailable.`,
    customPost: `You can quickly create a custom image post in the next step using our state-of-the-art builder. `,
    premadePost: `Choose this option if you already have an image you'd like to post. By using a Premade Image, some features in the image builder will be unavailable.`,
    dynamicHeaderText: `Would you like to create a custom dynamic ad? Or would you like to use your own image to create an ad?`,
    socialHeaderText: `Would you like to create a custom social image post? Or would you like to use your own image to create a social image post?`
}

export const forkHeaderText = (fork) => {
  switch(fork) {
    case _F.SOCIAL_IMAGE: return 'Build an Image Post'
    case _F.DYNAMIC: return 'Build a Dynamic Ad'
    case _F.DYNAMIC_VIDEO: return 'Build a Video Ad'
    case _F.SOCIAL_VIDEO: return "Build a Video Post"
    case _F.SOCIAL_TYPE: return "Build a Post"
    case _F.DYNAMIC_TYPE: return "Build an Ad"
    case _F.FORKS: return "Build Creatives"
    default: return ''
  }
}


//* maps all the fork names to user friendly verbiage
export const mapForksToDisplayName = (fork, exactName) => {
  switch(fork) {
      case _F.FORKS:
      case _F.BUILD_SELECTOR: return 'Build from Scratch'
      case _F.LOCATIONS: return 'Choose a Locations'
      case _F.DYNAMIC_TYPE: return "Build an Ad";
      case _F.SOCIAL_TYPE: return 'Build a Post'
      case _F.DYNAMIC: return 'Dynamic Ad';
      case _F.DYNAMIC_VIDEO: return 'Video Ad';
      case _F.SOCIAL_IMAGE: return 'Image Post'
      case _F.SOCIAL_VIDEO: return 'Video Post'
      case _F.CUSTOM_CREATIVE:  return exactName ? 'Custom': 'Deployment'
      case _F.PREMADE_CREATIVE: return exactName ? 'Premade': 'Deployment'
      case _F.YOUTUBE_LINK: return exactName ? 'Youtube URL' : 'Deployment'
      case _F.VIDEO_UPLOAD:  return exactName ? 'Upload Video': 'Deployment'
      default: return ''
  }
}

const mapPathToDisplayName = (fullPath, locationNickname) => {
  //* Maps the first breadcrumbs to reflect the origin where we came form.
  let path = fullPath?.split('/')
  if(!path) return 'Creative Selection'
  path = path[path.length-1]
  switch(path) {
    case 'locations' : return `Campaigns [${locationNickname}]`
    case 'creativefolders': return `Creative Folders`
    case 'calendar': return `Calendar [${locationNickname || 'Creative Folder'}]`
    case 'build': return `Creative Selection [${locationNickname}]`
    default: return `${path} [${locationNickname}]`
  }
}
   //* Creates a breadcrumb path from any tab that allows editing creatives
   //* Note: there are two views that we have to consider here the Zor and Zee, Depending on which the verbiage will change
export const createBreadCrumbsFromEdit = (data) => {
  let pathConstructor = []
  let constructorFun = (memberCase) =>({
    0: {forkName: data.isMember ? _F.BUILD_SELECTOR : _F.FORKS , displayName: mapPathToDisplayName(data.origin, data.nickname)},
    1: {forkName:  _F.BUILD_SELECTOR, displayName: 'Build from Scratch'},
    2: {forkName:  _F.DYNAMIC_TYPE, displayName: 'Build an Ad'},
    3: {forkName:  _F.SOCIAL_TYPE, displayName: 'Build a Post'},
    4: {forkName:  _F.DYNAMIC, displayName: 'Dynamic Ad'},
    5: {forkName:  _F.DYNAMIC_VIDEO, displayName: 'Video Ad'},
    6: {forkName:  _F.SOCIAL_IMAGE, displayName: 'Image Post'},
    7: {forkName:  _F.SOCIAL_VIDEO, displayName: 'Video Post'},
    8: {forkName:  _F.CUSTOM_CREATIVE, displayName: memberCase ? 'Custom': 'Deployment'},
    9: {forkName:  _F.PREMADE_CREATIVE, displayName: memberCase ? 'Premade': 'Deployment'},
    10:{forkName:  _F.YOUTUBE_LINK, displayName: memberCase ? 'Youtube URL': 'Deployment'},
    11:{forkName:  _F.VIDEO_UPLOAD, displayName: memberCase ? 'Upload Video': 'Deployment'},
  })

  const imageTypeDuplicates = () => {
    data.premade ?  pathConstructor.push(constructorFun(data.isMember)[9]) : pathConstructor.push(constructorFun(data.isMember)[8])
    if(!data.isMember) data.premade ?  pathConstructor.push(constructorFun(true)[9]) : pathConstructor.push(constructorFun(true)[8])
  }
  const videoTypeDuplicates = () => {
    data.youTubeId ? pathConstructor.push(constructorFun(data.isMember)[10]) : pathConstructor.push(constructorFun(data.isMember)[11])
    if(!data.isMember) data.youTubeId ? pathConstructor.push(constructorFun(true)[10]) : pathConstructor.push(constructorFun(true)[11])
  }
  let constructor = constructorFun(false)
  pathConstructor.push(constructor[0])
  if(data.kind === CREATIVE_KIND.CREATIVE) {
    pathConstructor.push(constructor[2])
    pathConstructor.push(constructor[4])
    imageTypeDuplicates()
  }

  if(data.kind === CREATIVE_KIND.VIDEO_CREATIVE) {
      pathConstructor.push(constructor[2])
      pathConstructor.push(constructor[5])
      videoTypeDuplicates()
  }

  if(data.kind === CREATIVE_KIND.POST) {
    pathConstructor.push(constructor[3])
    pathConstructor.push(constructor[6])
    imageTypeDuplicates()
  }

  if(data.kind === CREATIVE_KIND.VIDEO_POST) {
    pathConstructor.push(constructor[3])
    pathConstructor.push(constructor[7])
    videoTypeDuplicates()
  }
  return pathConstructor
}


export const discardEditedModalText = `Are you sure you want to discard the creative you’re working on? This action will permanently discard all the edits you’ve made to this creative.`

