import { CLEAR_MEMBER_SUMMARIES, LOAD_MEMBER_SUMMARIES } from '../actions/types';

const initialState = {
    memberSummaries: [],
};

const summariesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_MEMBER_SUMMARIES:
            return {
                ...state,
                memberSummaries: action.payload,
            };
        case CLEAR_MEMBER_SUMMARIES:
            return {
                ...state,
                memberSummaries: [],
            };
        default:
            return state;
    }
};

export default summariesReducer;
