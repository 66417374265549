import { BackendCombinedTheme } from "../../features/themeProvider/types"

type FooterLink = {
    name: string
    url: string
    'blob-icon'?: string
}

const defaultFooterTheme: FooterTheme = {
    address: '',
    phone: '',
    other: '',
    logo: '',
    links: []
}

export const buildFooterObject = (themeObject?: BackendCombinedTheme): FooterTheme => {
    const footerThemeObject = themeObject?.['json-footer']
    if (!footerThemeObject) return defaultFooterTheme
    const footerTheme = Object.keys(footerMapper).reduce((acc, key) => {
        const backendFooterKey = key as keyof BackendFooterTheme
        return {
            ...acc,
            [footerMapper[backendFooterKey]]: footerThemeObject[backendFooterKey]
        }
    }, {} as FooterTheme)
    return footerTheme
}

const footerMapper = {
    'address': 'address',
    'phone': 'phone',
    'otherNote': 'other',
    'blob-logo': 'logo',
    'json-links': 'links'
} as const

type FooterMapper = typeof footerMapper

export type BackendFooterTheme = {
    [key in keyof FooterMapper]: key extends 'json-links' ? FooterLink[] : string
}

export type FooterTheme = {
    [key in FooterMapper[keyof FooterMapper]]: key extends 'links' ? FooterLink[] : string
}