import styled, { css } from "styled-components";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { breakWord } from "../../styles/typography";
import { createBoxShadow } from "../../styles/card";
import searchPageBg from "../../assets/images/search-page-2x.png";

// Container
export const PreviewSlideWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 10px;
`;

export const PreviewSlideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  min-height: 20rem;
  color:${props => props.theme.color.textColor1};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 10px;
`;

export const PreviewContainer = styled.div`
  height: 100%;
  min-width: 100%;
  transition: 0.2s;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  ${({ x }) => {
    return `
    transform: translateX(${x}%)
    `;
  }}
`;

export const PreviewMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffffff;
  border-radius: 9px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.textColor1};
  margin-top: 10px;
  min-height: 30px;
  font-size: 6px;
  font-weight: 600;
  color: black;
  font-family: ${props => props.theme.font?.body?.family};

  img {
    width: 20px;
    height: 20px;
  }

  p {
    width: 80%;
  }
`;

// Navigation
export const LeftSlideButton = styled(FaAngleLeft)`
  transform: scale(3);
  margin: auto 0px;
  cursor: pointer;
  color: ${props => props.theme.color.accentColor2};
`;

export const RightSlideButton = styled(FaAngleRight)`
  transform: scale(3);
  margin: auto 0px;
  cursor: pointer;
  color: ${props => props.theme.color.accentColor2};
`;

// General Styles
export const CreativeContainer = styled.div`
  position: relative;
  ${({isThumbnail}) => isThumbnail && css `
    overflow: hidden;
    img {
      margin: -10% 0;
    }
  `}
  .visit_advertiser {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 10px;
  }
`;

// * Shared Social Styles
export const SocialWrap = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SocialPreviewCopyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  img {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const sharedPreviewContentStyles = css`
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: ${createBoxShadow};
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #000000;
`

// * Facebook Preview
export const FacebookContentWrap = styled.div`
  ${sharedPreviewContentStyles}

  .top-section {
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
    align-items: center;
  }

  .logo-container {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
  }

  .placeholder-img {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
  }

  .page-info {
    display: flex;
    flex-direction: column;
    font-size: 0.5rem;
    strong {
      color: #000000;
    }

    span {
      color: #646464;
      font-size: 0.5rem;
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
  }

  .text-content {
    padding: 0 .75rem;
    font-size: 0.5rem;
    line-height: 0.65rem;
    ${breakWord}
  }

  .selected-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    margin-top: 0.3rem;
  }

  .learn-more-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    font-size: 0.5rem;
    background-color: #F7F8FA;
  }

  .web-text-content {
      display: flex;
      flex-direction: column;
      line-height: 0.65rem;
    }

    .learn-more {
      font-weight: bold;
      background-color: #E4E6EB;
      padding: 6px;
      border-radius: 5px;
      min-width: 100px;
      text-align: center;
    }
`
// * Instagram Preview
export const InstagramContentWrap = styled.div`
  ${sharedPreviewContentStyles}

  .top-section {
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
    align-items: center;
  }

  .logo-container {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
  }

  .placeholder-img {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
    
  }
  .ig-img-preview {
    max-height: 300px;
    object-fit: contain;
  }

  .page-info {
    display: flex;
    flex-direction: column;
    font-size: 0.5rem;

    strong {
      color: #000000;
    }

    span {
      color: #646464;
      font-size: 0.5rem;
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
  }

  .selected-image {
    width: 100%;
    max-height: 300px;
    margin-top: 0.3rem;
    object-fit: contain;
  }

  .learn-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.75rem 0.2rem;
    font-weight: bold;
    font-size: 0.6rem;
  }

  .seperator {
    border-bottom: 1px solid #c4c4c4;
    margin: 0px 0.75rem;
  }

  .text-content {
    font-size: 0.5rem;
    padding: 0 0.3rem;
    margin-bottom: 0.3rem;
    ${breakWord}
  }
`

// * Twitter Preview
export const TwitterContentWrap = styled.div`
  ${sharedPreviewContentStyles}


  .selected-image {
    max-height: 300px;
    object-fit: contain;
  }

  .twitter-box {
    display: flex;
    padding: 0.75rem;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }

  .thread-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .thread-line {
    width: 2px;
    flex: 1;
    margin: auto;
    background: #c4c4c4;
  }

  .logo-container {
    height: 25px;
    width: 25px;
    overflow: hidden;
  }

  .placeholder-img {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
  }

  .twitter-content {
    display: flex;
    flex-direction: column;
  }

  .top-section {
    margin-bottom: 15px;
    display: flex;
    gap: 0.5rem;
  }

  .page-info {
    font-size: 0.6rem;
    color: #566370;
    margin-left: 4px;
  }

  .text-content {
    font-size: 0.6rem;
    ${breakWord}
  }

  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
  }

  .twitter-icons {
    display: flex;
    justify-content: space-around;
    margin: 5px 0;

    img {
      height: 10px;
      width: 10px;
    }
  }

  .threadText {
    color: #1C9CEF;
    font-size: 0.6rem;
  }
`

// Google My Business Preview
export const StyledGoogleMyBusinessPreview = styled.div`
  ${sharedPreviewContentStyles}

  .header {
    display: flex;
    padding: 10px;
    align-items: center;

    img {
      height: 22px;
      width: 22px;
      overflow: hidden;
    }
  }

  .placeholder-pic {
    height: 25px;
    width: 25px;
    background-color: #C4C4C4;
    border-radius: 50%
  }

  .profile-text {
    display: flex;
    flex-direction: column;
    margin-left: 6px;

    strong {
      font-size: 0.5rem;
    }

    .sub-text {
      font-size: 6px;
      color: #666666;
    }
  }

  .actions {
    margin-left: auto;
  }

  .extended-text {
    font-size: 10px;
    padding: 0 16px;
    margin-top: 10px;
    line-height: 16px;
  }
`

//Website Preview
export const StyledWebsitePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledWebsiteWrapper = styled.div`
  position: relative;
  .website_ad_image {
    position: absolute;
    width: 150px;
    top: 30%;
    left: 50%;
  }
  .disclaimer_image {
    width: 270px
  }
`;

// Search Preview
export const StyledSearchPreview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSearchBg = styled.div`
  background-image: url(${searchPageBg});
  background-size: contain;
  box-shadow: 0px 0px 20px rgba(205, 214, 246, 0.2), 4px 0px 4px rgba(0, 0, 0, 0.04), 2px 2px 2px rgba(0, 0, 0, 0.04), 2px 2px 18px rgba(135, 162, 255, 0.2);
  width: 330px;
`

export const StyledSearchAd = styled.div`
  margin-top: 38px;
  font-family: Arial;
  display: flex;
  flex-direction: column;
  width: 330px;
  min-height: 184px;
  border-radius: 5px;
  background: #DDD;
  box-shadow: 2px 2px 18px 0px rgba(135, 162, 255, 0.20), 2px 2px 2px 0px rgba(0, 0, 0, 0.04), 4px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 20px 0px rgba(205, 214, 246, 0.20);
  padding: 7px;
  gap: 9px;

  .phone-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #3C4043;
    margin-left: 8px;

    svg {
      color: #80868A;
    }
  }
`;

export const SearchAdInfo = styled.div`
  width: 316px;
  min-height: 146px;
  background: #FFF;
  padding: 16px 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    height: 32px;
    width: 32px;
    background: #E2EEFF;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .url-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const LinkedInContentWrap = styled.div`
  ${sharedPreviewContentStyles}
  .linkedin-img {
    max-height: 300px;
    object-fit: contain;
  }
    .header {
        display: flex;
        align-items: center;
        padding: 0.75rem;
        gap: 0.5rem;

        .profile-pic-placeholder {
            background-color: #C4C4C4;
            height: 32px;
            width: 32px;
        }

        .profile-text {
            font-size: 0.5rem;
            line-height: 0.6rem;

            .post-time {
                display: flex;
                align-items: center;
                gap: 2px;
            }

            > strong {
                color: #191919;
            }

            > *:not(strong) {
                color: #666666;
            }
        }

        .actions {
          display: flex;
          align-items: center;
          margin-left: auto;
          margin-bottom: auto;

          .ellipsis {
            color: #666666;
          }
        }
    }

    .extended-text {
        font-size: 0.6rem;
        line-height: 0.6rem;
        color: #191919;
        padding: 0px 0.75rem;
        ${breakWord}
    }

    > img {
        margin-top: 0.75rem;
    }

    .footer {
        height: 3.5rem;
        position: relative;
        padding: 0px 0.75rem;

        > img {
            max-width: 175px;
            margin-top: 30px;
        }
    }
`

//Reusable Styles
export const OptionalPreviews = styled.div`
  width: 300px;
  text-align: center;
  margin-bottom: 10px;
  .optional-prev-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .optional-prev-image {
    margin-right: 10px;
    width: 30px;
  }
  .disclaimer-wrapper {
    text-align: start;
  }
`;

export const StyledYoutubePreview = styled.div`
  padding-top: 10px;
  width: 300px;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${createBoxShadow};
`;

export const YoutubeTopSection = styled.div`

  background-color: white;
  color: black;

  .youtube-ad-icon {
    width: 30px;
    margin-left: 10px;
  }
  .youtube-ad-dots {
    width: 6px;
    margin-right: 10px;
  }
  .youtube-ad-skip {
    width: 100px;
    position: absolute;
    right: 5px;
    top: -40px;
  }
`;
export const StyleDisclaimerWarning = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 270px;
  margin-top: 1rem;
`