import React, { ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acknowledgeTOS } from '../../actions/userActions'
import { BackgroundBtn } from '../../styles/buttons'
import { BannerCarouselIndicator, BannerContainer, BannerIcon, BannerInformation, BannerLayout } from './styles'
import { BigBody, Body, Link } from '../../styles/typography'
import { EulerityLocation } from '../../types/location'
import useVersionData from '../../hooks/useVersionData'
import { useTheme } from 'styled-components'
import { getSrvPath } from '../../helpers'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

export type BannerType = {
    text?: string
    link?: string
    image?: Blob
    // Custom Fields for TOS
    title?: string
    textContent?: ReactNode
    buttonContent?: ReactNode
}

export const useBanner = () => {
    const user = useSelector((state) => state.user)
    const locations = useSelector((state) => state.franchise.locations) as EulerityLocation[]
    const showTosBanner = !user.tosAccepted && locations?.filter((l) => l.user?.raw.name === user.id).some((l) => l.subscriptions?.length > 0) // Show TOS Banner for subscribed members that have not accepted TOS

    return {
        showTosBanner,
    };
};

const Banner = () => {
    const user = useSelector((state) => state.user)
    const { showTosBanner } = useBanner();
    const dispatch = useDispatch()
    let { banners: _banners } = useVersionData()
    const theme = useTheme()

    const [currentBannerIdx, setCurrentBannerIdx] = useState(0)

    const banners = useMemo(() => {
        // Hardcoded Banner
        const tosBanner = {
            title: 'Terms of Service',
            textContent: (
                <Body color="textColor3">
                    To continue, you must acknowledge the{' '}
                    <Link
                        href={user.tosLink ? user.tosLink : 'https://www.eulerity.com/tos'}
                        target='_blank'
                        rel='noreferrer'
                        color='textColor3'
                        regular
                    >
                        Service Agreement
                    </Link>
                    . By acknowledging
                    this agreement, you acknowledge that they apply for all current and new
                    subscriptions made by this user.
                </Body>
            )
            ,
            buttonContent: (
                <BackgroundBtn onClick={() => dispatch(acknowledgeTOS(user.id))}>
                    Acknowledged
                </BackgroundBtn>
            ),
        }

        // Add to Rest of Banners
        const listOfBanners: BannerType[] = []
        if (showTosBanner) {
            listOfBanners.push(tosBanner)
        }
        return listOfBanners.concat(_banners)
    }, [_banners, showTosBanner, user.tosLink, dispatch, user.id])

    const hasMultipleBanners = banners?.length > 1

    const currentBanner = useMemo(() => {
        return banners[currentBannerIdx]
    }, [banners, currentBannerIdx])

    const goNext = () => {
        if (currentBannerIdx === banners.length-1) return
        setCurrentBannerIdx(currentBannerIdx+1)
    }
    const goPrev = () => {
        if (currentBannerIdx === 0) return
        setCurrentBannerIdx(currentBannerIdx-1)
    }

    if (currentBanner) {
        return (
            <BannerContainer>
                <BannerLayout hasMultiple={hasMultipleBanners}>
                    {
                        hasMultipleBanners &&
                       <FiChevronLeft style={{cursor: 'pointer', opacity: currentBannerIdx === 0 ? '0.4' : '1'}} size={24} onClick={goPrev}>Back</FiChevronLeft>
                    }
                    <BannerInformation>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {
                                currentBanner.image &&
                                <BannerIcon>
                                    <svg data-src={getSrvPath(currentBanner.image)}></svg>
                                </BannerIcon>
                            }
                            <div className='banner-text'>
                                <BigBody bold color="textColor3">{currentBanner.title}</BigBody>
                                <Body color='textColor3' style={{flexGrow: '1'}}>{currentBanner.text}</Body>
                                {currentBanner.textContent}
                            </div>
                        </div>
                        {
                            currentBanner.link &&
                            <BackgroundBtn
                                onClick={() => window.open(currentBanner.link)}>Learn More
                            </BackgroundBtn>
                        }
                        {
                            currentBanner.buttonContent
                        }
                    </BannerInformation>
                    {
                        hasMultipleBanners &&
                        <FiChevronRight style={{cursor: 'pointer', opacity: currentBannerIdx === banners.length-1 ? '0.4' : '1' }} size={24} onClick={goNext}>Next</FiChevronRight>
                    }
                </BannerLayout>
                {
                    hasMultipleBanners &&
                    <BannerCarouselIndicator>
                        {
                            banners.map(b => {
                                return <span key={b.text} style={{backgroundColor: theme.color.backgroundColor2, opacity: b.text === currentBanner.text ? '1' : '0.4'}}/>
                            })
                        }
                    </BannerCarouselIndicator>
                }
            </BannerContainer>
        )
    }

    return null
};

export default Banner;
