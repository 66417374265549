import { toast } from 'react-toastify';
import { bytesToMegaBytes } from '.';

export const rgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
const templateObject = (temp, isLogo) => {
  // For Logo
  if (isLogo) {
    return {
      backgroundColor: [
        parseInt(temp.color.r),
        parseInt(temp.color.g),
        parseInt(temp.color.b),
      ],
      backgroundOpacity: temp.color.a / 255,
      box: temp,
    };
  }
  // For Header & Description
  return {
    box: temp.box,
    textColor: rgbToHex(temp.color.r, temp.color.g, temp.color.b),
    textOpacity: parseFloat(temp.color.a / 255),
    backgroundColor: [
      parseInt(temp.box.color.r),
      parseInt(temp.box.color.g),
      parseInt(temp.box.color.b),
    ],
    backgroundOpacity: temp.box.color.a / 255,
    fontSize: temp.fontSize,
    justify: temp.justify,
    fontFamily: temp.type, //fontFamily is set to string here from loadCreativeResources (edit flows/load template)
    style: temp.style,
  };
};

export const loadCreativeResources = ( {
  header,
  description,
  image,
  logoBox,
  logo,
  name,
  isEdited,
  extendedText,
  premade
}
) => {
  const placeHolder = {
    color: { r: 255, g: 255, b: 255, a: 0 },
    height: 60,
    left: 120,
    top: 0,
    width: 60,
    zIndex: 0,
  };

  let customCreativeProperies = {}
  if(!premade) {
    customCreativeProperies  = {
      headerBox:  templateObject(header),
      headerDescriptionBox: templateObject(description),
      descriptionText: description.text,
      headerText: header.text,
    }
  }
  return {
    logoFilters: templateObject(logoBox || placeHolder, "logo"),
    image: image,
    logo: isEdited ? logo : "",
    templateName: name,
    extendedText: extendedText ? extendedText : '',
    ...customCreativeProperies
  };
};

export const extractFloats = (str, type) => {
  var regex = /[+-]?\d+(\.\d+)?/g;
  var floats = str.match(regex).map(function (v) {
    return parseFloat(v);
  });
  return parseFloat(floats.join(""));
};

export const removeDuplicates = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

function isImage(fType) {
  return fType === "jpeg" || fType === "png";
}
function isVideoOrGif(fType) {
  return fType === "mp4" || fType === 'quicktime' ||fType === 'gif'
}
const validateImage = (file, type, imageResObj, callback) => {
  const _URL = window.URL || window.webkitURL;
  let suggestedWidth, suggestedHeight, maxWidth, maxHeight;

  switch (type) {
    case "image": {
      suggestedWidth = imageResObj?.suggImgRes[0];
      suggestedHeight = imageResObj?.suggImgRes[1];
      maxWidth = imageResObj?.maxImgRes[0];
      maxHeight = imageResObj?.maxImgRes[1];
      break;
    }
    case "logo": {
      suggestedWidth = imageResObj?.suggLogoRes[0];
      suggestedHeight = imageResObj?.suggLogoRes[1];
      maxWidth = imageResObj?.maxLogoRes[0];
      maxHeight = imageResObj?.maxLogoRes[1];
      break;
    }
    default: {
      break;
    }
  }

    let tempImg = new Image();
  tempImg.onload = function () {
    const greaterThanSuggested =
      this.width > suggestedWidth || this.height > suggestedHeight;
    const greaterThanMax = this.width > maxWidth || this.height > maxHeight;

    const doWarnUser = greaterThanSuggested && !greaterThanMax;
    const isGifFile = file.type === 'image/gif'
    const doBlockUpload = (greaterThanSuggested && greaterThanMax) || isGifFile;
    if (doBlockUpload) {
      callback(`${file.name} (${this.width}x${this.height})`, "failure");
    } else if (doWarnUser) {
      callback(`${file.name} (${this.width}x${this.height})`, "warning");
    } else {
      callback(`${file.name} (${this.width}x${this.height})`, "success");
    }
  };
  tempImg.onerror = () =>  toast.error("This file is corrupted, please use a different file.");
  tempImg.src = _URL.createObjectURL(file);
};

//Upload Image for Members Only;
export const readImageURL = (
  input,
  imageResObj,
  setSelectedImage,
  getSelectedLogo,
  selectedFilter,
  getTempLogoFile,
  memberRef,
  callback,
  onClose
) => {
  if (input.files && input.files[0]) {
    const type = input.files[0].type;
    const isImg = isImage(type.split("/")[1]);
    var reader = new FileReader();

    validateImage(input.files[0], selectedFilter, imageResObj, (filename, status) => {

      if(status !== 'failure') {
        if (isImg && selectedFilter === "logo") {
          getTempLogoFile(input.files[0], false);
        }
      }

      if (status === "success") {
        reader.onload = function (e) {
          if (selectedFilter === "logo") {
            getSelectedLogo(e.target.result);
          } else if (selectedFilter === "image") {
            setSelectedImage(e.target.result);
          }
          memberRef.current.value = null;
          onClose?.()
        };
      } else {
        callback(reader, status, filename);
      }

      reader.readAsDataURL(input.files[0]);
    });
  }
};

export const uploadLogoURL = ({input, imageSpecObj, setTempState, onSuccess, ref, setUploadWarning, onWarnOrError}) => {
  // If there is no file to handle, don't do anything
  if (!input.files || !input.files[0]) return

  const type = input.files[0].type
  const isImg = isImage(type.split("/")[1])
  var reader = new FileReader()

  // Validate a file of this logo type according to defined suggested/maximum resolutions (imageSpecObj) and do stuff
  validateImage(input.files[0], 'logo', imageSpecObj,(filename, status) => {
    // If this is an uploaded image, we want to save it somewhere temporarily
    // and ONLY upload it to blobstore when we are ready to make the api call (else its a waste)
    if (isImg && status !== 'failure') setTempState(input.files[0])

    if (status === "success") {
      reader.onload = function (e) {
        onSuccess({Url: e.target.result, filename})
        if (ref?.current) ref.current.value = null
        setUploadWarning(false)
      }
    } else {
      onWarnOrError(reader, status, filename)
    }
    reader.readAsDataURL(input.files[0])
  })
}

export const bytesToSize = (bytes) =>  {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i]
}

export const uploadMedia = (input, ref ) => {
  if (input.files && input.files[0]) {
    let file = input.files[0]
    const type = file.type;
    const isAcceptedType = isVideoOrGif(type.split("/")[1]);
    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      const absoluteSize = bytesToSize(file.size)
      const sizeInMB = bytesToMegaBytes(file.size)
      const limitInMB = 200
      if (sizeInMB > limitInMB) reject({message: `This file has ${absoluteSize} our maximum allowance is 200 MB `})
      const videoPlayer = document.createElement('video');

      reader.onerror = function(e) {
        reject({message: 'Something went wrong!'})
      }
      reader.onload = function(e) {
        let buffer = e.target.result
        let fileBlob = new Blob([new Uint8Array(buffer)], { type });
        let fileUrl = window.URL.createObjectURL(fileBlob);
        videoPlayer.setAttribute('src', fileUrl);
        videoPlayer.addEventListener('loadedmetadata', () => {
          videoPlayer.duration > 120
            ? reject({message: 'This video is longer than 2 minutes, please provide shorter video'})
            : resolve({ fileUrl, fileBlob, fileName: file.name, fileType: type})
          ref.current.value = null;
        })
     }
     if( isAcceptedType ) reader.readAsArrayBuffer(file);
        else reject({message: 'File not supported'})
    })

  }
}

export const seekedVidCov = (self) => {
  const canvas = document.createElement("canvas");
    canvas.width = self.videoWidth;
    canvas.height = self.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(self, 0, 0, canvas.width, canvas.height);
    return new Promise((resolve, reject) => {
      ctx.canvas.toBlob(
        blob => {
          if(!blob) reject()
            resolve(blob);
              },
              "image/jpeg",
                0.75
          );
    })
}




export const  getVideoCover = (file) => {
  return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', file);
      videoPlayer.load();
      videoPlayer.addEventListener('error', (err) => {
          reject(err);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
          setTimeout(() => {
            videoPlayer.currentTime = Math.floor(videoPlayer.duration / 2) - 10;
          }, 200);

          videoPlayer.addEventListener('seeked', () => {
              const canvas = document.createElement("canvas");
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              ctx.canvas.toBlob(
                  blob => {
                      resolve(blob);
                  },
                  "image/jpeg",
                  0.75
              );
          });
      });
  });
}


export const buildMemberLocation = (location) => {
  if (!location) return null
  const { id, nickname, name, user, subscriptions} = location

  return {
    label: `(${nickname}) ${name}`,
    value: `${user.raw.name}_${id}`,
    type: 'location',
    isActive: subscriptions?.length && subscriptions[0].status === 'ACTIVE'
  }
}


// tests if the dimensions of an image is larger than a given max dimension threshold
export const checkImageValidation = (imageWidth, imageHeight, maxDimensionsArray) => {
  const maxWidth = maxDimensionsArray?.[0]; // Max allowed image width
  const maxHeight = maxDimensionsArray?.[1] // Max allowed image height
  return imageWidth > maxWidth || imageHeight > maxHeight;
}