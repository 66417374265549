import React from "react"
import { IoEllipsisHorizontal } from "react-icons/io5"
import { LinkedInContentWrap, SocialPreviewCopyHeader, SocialWrap } from "./styles"
import linkedInIcon from "../../assets/images/linkedin-icon.svg"
import linkedInUserButtons from "../../assets/images/linkedin-user-buttons.png"
import { H2 } from "../../styles/typography"
import { getPreviewImageUrl } from "../../helpers/creative.util"
import  StringUtil  from "../../helpers/string.util"
import { PREVIEW_PLACEHOLDER_TEXT } from "../../helpers/constants"

const LinkedInPreview = ({ creative, previewText }) => {
    const { preview, youTubeId } = creative
    return (
        <SocialWrap>
            <SocialPreviewCopyHeader>
                <img src={linkedInIcon} alt="LinkedIn" />
                <H2>LinkedIn Preview</H2>
            </SocialPreviewCopyHeader>
            <LinkedInContentWrap>
                <div className="header">
                    <div className="profile-pic-placeholder" />
                    <div className="profile-text">
                        <strong>{PREVIEW_PLACEHOLDER_TEXT.linkedinPage}</strong>
                        <div>{PREVIEW_PLACEHOLDER_TEXT.followers}</div>
                    </div>
                    <div className="actions">
                        <IoEllipsisHorizontal className="ellipsis" />
                    </div>
                </div>
                <div className="extended-text">{previewText?.linkedInDescription.length > 510 ? StringUtil.truncateString(previewText?.linkedInDescription, 509) : previewText?.linkedInDescription || ""}</div>
                <img
                  className="linkedin-img"
                  src={getPreviewImageUrl({preview, youTubeId})}
                  alt="linkedin-img"
                />
                <div className="footer">
                    <img src={linkedInUserButtons} alt="LinkedIn User Buttons" />
                </div>
            </LinkedInContentWrap>
        </SocialWrap>
    )
}

export default LinkedInPreview