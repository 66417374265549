import { useSelector } from "react-redux"
import { useAccess } from "../../../hooks/useAccess"
import useCreativeActions from "../../../hooks/useCreativeActions"
import { Creative, CreativeMetadataFull, CreativeWithMetadata } from "../creative.types"
import { getCreativeMetadata } from "../utils"
import { doLocationUsersMatch } from "../../../helpers/locations.utils"
import { getLocationKey } from "../../../helpers"
import { useMemo } from "react"

export const useGetCreativeMetadata = () => {
    const defaultLocation = useSelector(state => state.franchise.defaultLocation)
    const { getCreativeLocation, getCreativeUser } = useCreativeActions()
    const { canManageFranchise } = useAccess()

    return ({ creative } : { creative: Creative }) : CreativeMetadataFull => {
        // This is the stuff we need redux state to derive
        let location = getCreativeLocation({ creative })
        const isFolder = canManageFranchise() && doLocationUsersMatch(location, defaultLocation)
        const isDefaultLocation = getLocationKey(location) === getLocationKey(defaultLocation)
        const user = getCreativeUser({ creative })

        // if we don't get a location just set it to undefined
        if (Object.keys(location).length === 0) {
            location = undefined
        }

        return {
            ...getCreativeMetadata(creative),
            // inject some values here that are relevant, but need state to be defined
            user,
            isFolder,
            location,
            isDefaultLocation,
        }
    }
}

export const useCreativeArrayMetadata = <T extends Creative>(creatives: T[]) : CreativeWithMetadata[] => {
    const getCreativeMetadata = useGetCreativeMetadata()
    const injectedCreatives = useMemo(() => creatives.map((creative) => {
        return {
            ...creative,
            metadata: getCreativeMetadata({ creative })
        }
    }), [creatives, getCreativeMetadata])
    return injectedCreatives
}

export const useCreativeMetadata = <T extends Creative>(creative: T) : CreativeMetadataFull  => {
    const getCreativeMetadata = useGetCreativeMetadata()
    return getCreativeMetadata({ creative })
}