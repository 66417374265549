import { gmtToLocaleString } from "."
import { DiscountRates, FeatureDataProps, FeatureFeeData, SubscriptionInformation } from "../pages/corporateSettings/payments/types"
import { Franchise } from "../types/franchise"
import { IntervalCount, PaymentSource } from "../types/subscription"
import CurrencyUtil, { CurrencyCode, StripeCurrency } from "./currency.util"
import NumberUtil from "./number.util"
import { calculatePeriodEnd } from "./paymentHelpers"

export const STATUS_COPY = {
    OPT_IN: 'OPT IN',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    FREE_TRIAL: 'FREE TRIAL',
} as const

export const INTERVAL_COPY : Record<number, string> = {
    1: 'monthly',
    3: 'quarterly',
    6: 'bi-annually',
    12: 'annually'
} as const

export const FEATURE_NAME_COPY = {
    ADVERTISING: 'Advertising Suite',
    SOCIAL_POSTING: 'Social Posting',
    EMAIL_MARKETING: 'Email Marketing',
    SMS_MARKETING: 'SMS Marketing',
    EULERITY_AI: 'Eulerity AI',
    REPUTATION_MANAGEMENT: 'Reputation Management'
} as const

export type FeatureName = typeof FEATURE_NAME_COPY[ keyof typeof FEATURE_NAME_COPY]

export const FEATURE_QUERY_PARAM = {
    [FEATURE_NAME_COPY.SOCIAL_POSTING]: 'social',
    [FEATURE_NAME_COPY.EMAIL_MARKETING]: 'email',
    [FEATURE_NAME_COPY.SMS_MARKETING]: 'sms',
    [FEATURE_NAME_COPY.EULERITY_AI]: 'chatGpt',
    [FEATURE_NAME_COPY.REPUTATION_MANAGEMENT]: 'reputationManagement'
} as const

export const FEATURE_ENABLED_FLAG = {
    [FEATURE_NAME_COPY.SOCIAL_POSTING]: 'featureSocialPostingEnabled',
    [FEATURE_NAME_COPY.EMAIL_MARKETING]: 'featureEmailCreativeEnabled',
    [FEATURE_NAME_COPY.SMS_MARKETING]: 'featureSmsCreativeEnabled',
    [FEATURE_NAME_COPY.EULERITY_AI]: 'featureChatGptEnabled',
    [FEATURE_NAME_COPY.REPUTATION_MANAGEMENT]: 'featureReputationManagementEnabled'
} as const

// * Returns the discount to apply to monthlySaas for given interval
export const getSubscriptionDiscount = (intervalCount : IntervalCount, discountRates : DiscountRates) => {
    const discountMap: Record<IntervalCount, number> = {
        1: 0,
        3: discountRates.quarterlySaasDiscount,
        6: discountRates.semiAnnuallySaasDiscount,
        12: discountRates.yearlySaasDiscount
    }

    return discountMap[intervalCount] || 0
}

// * Returns the sum of ALL enabled features to add on top of current subscription
const getFeatureFee = (featureData : FeatureFeeData) => {
    let featureCost = 0

    const { featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled } = featureData

    if (featureEmailCreativeCost && featureEmailCreativeEnabled) featureCost += featureEmailCreativeCost
    if (featureSmsCreativeCost && featureSmsCreativeEnabled) featureCost += featureSmsCreativeCost
    if (featureSocialPostingCost && featureSocialPostingEnabled) featureCost += featureSocialPostingCost
    if (featureChatGptCost && featureChatGptEnabled) featureCost += featureChatGptCost
    if (featureReputationManagementCost && featureReputationManagementEnabled) featureCost += featureReputationManagementCost

    return featureCost
}

type GenerateCurrentSubscription = {
    franchise: Franchise,
    currencies: StripeCurrency[]
    selectedCurrency: CurrencyCode
}

// * Takes current franchise and derives data to reflect current subscription
export const getCurrentSubscriptionData = ({franchise, currencies, selectedCurrency} : GenerateCurrentSubscription) : SubscriptionInformation => {
    // * This is the set up for a new subscription object - none of the data below would even exist
    if (!franchise?.subscription) return { subscriptionStatus: 'INACTIVE' }
    if (franchise.subscription.state === 'Paused') return { subscriptionStatus: 'PAUSED'}

    const { monthlySaas, quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount, featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled } = franchise
    const { intervalCount, currency, status, periodEnd, last4 } = franchise?.subscription
    const discountRate = getSubscriptionDiscount(intervalCount, { quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount })
    const featureCost = getFeatureFee({featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled})
    const renewalDate = gmtToLocaleString(new Date(periodEnd).toString(), 'date')

    const monthlyTotal = monthlySaas + featureCost
    const discount = monthlyTotal * discountRate
    const amount = (monthlyTotal - discount) * intervalCount

    const convertedAmount = CurrencyUtil.convertCurrency(selectedCurrency, amount, currencies)
    const formatSettings = {
        maximumFractionDigits: 0
    }

    return {
        amount,
        periodEnd: renewalDate,
        intervalCopy: INTERVAL_COPY[intervalCount],
        last4,
        currency,
        subscriptionStatus: status,
        amountCopy: convertedAmount !== null ? NumberUtil.formatCurrencyNumber(convertedAmount, selectedCurrency, formatSettings) : null
    }
}

type UpdateSubscriptionDataProps = {
    franchise: Franchise,
    intervalCount: IntervalCount,
    selectedCurrency: CurrencyCode,
    selectedSource: PaymentSource | null,
    selectedFeature: FeatureDataProps | null,
    currencies: StripeCurrency[]
}

export const getUpdatedSubscriptionData = ({franchise, intervalCount, selectedCurrency, selectedSource, selectedFeature, currencies} : UpdateSubscriptionDataProps) : SubscriptionInformation => {
    const { monthlySaas, quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount, featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled } = franchise

    const discountRate = getSubscriptionDiscount(intervalCount, { quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount })
    const featureCost = getFeatureFee({featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled})
    const renewalDate = calculatePeriodEnd(franchise, intervalCount)

    const monthlyTotal = monthlySaas + featureCost
    const discount = monthlyTotal * discountRate
    let amount = (monthlyTotal - discount) * intervalCount

    if (selectedFeature) amount = amount + (selectedFeature?.cost || 0)

    const convertedAmount = CurrencyUtil.convertCurrency(selectedCurrency, amount, currencies)

    const formatSettings = {
        maximumFractionDigits: 0
    }

    return {
        amount: selectedFeature ? amount + (selectedFeature?.cost || 0) : amount,
        periodEnd: gmtToLocaleString(new Date(renewalDate).toString(), 'date'),
        intervalCopy: INTERVAL_COPY[intervalCount],
        last4: selectedSource?.last4,
        currency: selectedCurrency,
        subscriptionStatus: 'ACTIVE', // * We make this ACTIVE ourselves for the sake of UI - the subscription will be active AFTER they confirm the subscription
        amountCopy: convertedAmount !== null ? NumberUtil.formatCurrencyNumber(convertedAmount, selectedCurrency, formatSettings) : null
    }
}

// * This will return the fee a client will pay per month and for the full interval (USD - we'll convert it to the given currency elsewhere)
export const calculateMonthlyFee = ({franchise, intervalCount} : {franchise: Franchise, intervalCount: IntervalCount}) => {
    let amountPerMonth = 0;

    const { monthlySaas, quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount, featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled } = franchise
    const discountRate = getSubscriptionDiscount(intervalCount, { quarterlySaasDiscount, semiAnnuallySaasDiscount, yearlySaasDiscount })
    const featureCost = getFeatureFee({featureEmailCreativeEnabled, featureEmailCreativeCost, featureSmsCreativeEnabled, featureSmsCreativeCost, featureSocialPostingEnabled, featureSocialPostingCost, featureChatGptCost, featureChatGptEnabled, featureReputationManagementCost, featureReputationManagementEnabled})
    amountPerMonth = (monthlySaas + featureCost) * (1 - discountRate)
    return amountPerMonth
}

// * Returns cost of feature AFTER discount is applied for the given interval
export const calculateFeatureFee = ({featureCost, intervalCount, discountRate } : {featureCost: number, intervalCount: IntervalCount, discountRate: number}) => {
    return (featureCost * intervalCount) * (1 - discountRate)
}