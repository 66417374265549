import React from 'react'
import { StyledModalContent } from './styles';

const ModalContent = ({children}) => {
    return (
        <StyledModalContent>
            { children }
        </StyledModalContent>
    )
}

export default ModalContent
