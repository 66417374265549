
import {isEqual} from 'lodash'
// * Function checks if two arrays have the same length (if not returns false)
export const areAllStringsEqual = <T extends []>(elementsA : T, elementsB : T) => {
    if(elementsA.length !== elementsB.length) return false
    for(let i=0; i < elementsA.length ;i++){
      const elemA = elementsA[i];
      const elemB = elementsB[i];
       if(!isEqual(elemA, elemB)) {
        return false
       }
    }
    return true
  }

// Finds a string from an array of strings - e.g. labels
export const findString = (arr: string[] = [], str: string, isCaseSensitive: boolean) => {
  return arr?.find(s => isCaseSensitive ?
                        s.trim() === str.trim() :
                        s.trim().toLowerCase() === str?.trim().toLowerCase())
}

// * A function that swap columns and rows with a given data set
export const swapColumnsAndRows = <T>(data: T[][]) => {
  const swappedData: T[][] = [];

  const maxColumnLength = Math.max(...data.map(row => row.length));
  const numColumns = maxColumnLength;
  const numRows = data.length;
  for (let i = 0; i < numColumns; i++) {
    swappedData[i] = [];
    for (let j = 0; j < numRows; j++) {
      swappedData[i][j] = data[j][i];
    }
  }
  return swappedData;
}

export const keyMap = <T extends object>(obj: T) => {
  return Object.keys(obj) as (keyof T)[]
};

// * A function 'rotates' a 2D array by 90 degrees
// * e.g.
// * [[1, 2, 3],
// *  [4, 5, 6],
// *  [7, 8, 9]]
// *   =>
// * [[7, 4, 1],
// *  [8, 5, 2],
// *  [9, 6, 3]]
export const transposeArray = <T>(array: T[][]) => {
    if (array.length === 0 || array[0].length === 0) return []
    return array[0].map((_, colIndex) => array.map(row => row[colIndex]))
}
