import React from 'react'

type LinkedInIconProps = {
	invert?: boolean
} & React.SVGProps<SVGSVGElement>
export const LinkedInIcon = (props: LinkedInIconProps) => {
	return (
		<svg
			width={props.width || '20'}
			height={props.height || '21'}
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 0.357422C4.4775 0.357422 0 4.83492 0 10.3574C0 15.8799 4.4775 20.3574 10 20.3574C15.5225 20.3574 20 15.8799 20 10.3574C20 4.83492 15.5225 0.357422 10 0.357422ZM6.24667 4.85992C6.94833 4.85992 7.41583 5.32742 7.41583 5.95076C7.41583 6.57409 6.94833 7.04159 6.16917 7.04159C5.4675 7.04242 5 6.57409 5 5.95076C5 5.32742 5.4675 4.85992 6.24667 4.85992ZM7.5 14.5241H5V7.85742H7.5V14.5241ZM15.8333 14.5241H13.48V10.8808C13.48 9.87326 12.8525 9.64076 12.6175 9.64076C12.3825 9.64076 11.5975 9.79576 11.5975 10.8808C11.5975 11.0358 11.5975 14.5241 11.5975 14.5241H9.16667V7.85742H11.5983V8.78742C11.9117 8.24492 12.5392 7.85742 13.7158 7.85742C14.8925 7.85742 15.8333 8.78742 15.8333 10.8808V14.5241Z"
				fill={props.invert ? 'white' : '#0077B5'}
			/>
		</svg>
	)
}
