// Function will take a date and add the specified number of days to it (returns in month day, year format - (June 03, 2022))
import moment from "moment"
import { MILLIS_PER_DAY, MILLIS_PER_SECOND, SECONDS_PER_MINUTE } from "./constants"
import { DateHelper } from "."

export const getFutureDate = (date, addDays) => {
    let futureDate = new Date(date)
    futureDate.setDate(futureDate.getDate() + addDays)
    return futureDate.toLocaleDateString('en', {month: 'long', day:'2-digit', year:'numeric'})
}

//* Takes a date object and format (mm/dd/yyyy, yyyy/mm/dd, etc)
export const formatDate = (date, format) => {
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with zero
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month and pad with zero
    const year = date.getFullYear(); // Get full year

    // Replace the format placeholders (MM, DD, YYYY) with the actual values
    return format
      .replace(/MM/, month)
      .replace(/DD/, day)
      .replace(/YYYY/, year);
}

//* Returns a date object with added days to it
export const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const nDaysAgo = (n, fromDate = new Date()) => {
    return new Date(fromDate.getTime() - 24 * 60 * 60 * 1000 * n);
};

export const nMonthsAgo = (n, fromDate = new Date()) => {
    return new Date(fromDate.getFullYear(), fromDate.getMonth() - n, fromDate.getDate());
};

export const moreThanNDaysAgo = (date, nDays) => {
    const daysInMillis = nDays * MILLIS_PER_DAY;
    const dateInMillis = new Date(gmtDateString(date)).getTime();
    const currDateInMillis = new Date().getTime();

    const timePassedInMillis = currDateInMillis - dateInMillis;
    return timePassedInMillis > daysInMillis;
}

export const oneYearAgo = (fromDate) => {
    const date = new Date(fromDate);
    date.setFullYear(date.getFullYear() - 1);
    return date;
}

export const gmtDateString = datestr => datestr?.includes('GMT') ? datestr : datestr + ' GMT'
export const gmtDate = datestr => new Date(gmtDateString(datestr))


export const toBeginningOfDay = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
};

export const toEndOfDay = (date) => {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    return d;
};

export const isSameDay = (date1, date2) => {
    return (
        date1?.getMonth?.() === date2?.getMonth() &&
        date1?.getDate() === date2?.getDate() &&
        date1?.getFullYear() === date2?.getFullYear()
    )
}

// * Takes a date in YYYY-MM-DD format and returns the month, day, and year in numerical values (removes potential leading 0's)
export const extractDateData = (date) => {
    if (!date) return
    const [year, month, day] = date.split('-')
    return {
        year: Number(year),
        month: Number(month) - 1, // * In JS months begin their index at 0, we must adjust it so Jan (1) is understood as 0
        day: Number(day)
    }
}

// converts a date string to a date object
// dateStr: string (format: 'YYYYMMDD')
export function stringToDate(dateStr) {
    if(!dateStr) return

    return moment(dateStr, 'YYYYMMDD').toDate()
  }

// converts a dat string to a date object
// dateStr: string (format: 'MON DD, YYYY HH:MM:SS AM/PM')
export function stringToDateTime(dateStr) {
    return moment(dateStr, 'MMM DD, YYYY hh:mm:ss A').toDate()
}

// checking the instance of date is not enough to confirm that the object is a date Invalid date return true if we use instance of date
// this function guarantees that the object is date

export const isDateObject = (d) => !isNaN(d?.getTime?.())

export const dynamicNDaysAgo = (arrayData, timePeriodChunk=6) => arrayData.length > timePeriodChunk ? timePeriodChunk : arrayData.length - 1

export const findDateMinMax = (dateList) => {
    let MIN,MAX
    if(dateList[0]?.date) {
        MIN = dateList[0].date;
        MAX = dateList[0].date;
    } else {
        MIN = dateList[0];
        MAX = dateList[0];
    }
    for(let i=1;i<dateList.length;i++) {
        let currentDate = dateList[i]?.date ? dateList[i].date : dateList[i];
        if(stringToDate(currentDate).getTime() > stringToDate(MAX).getTime()) MAX=currentDate
        if(stringToDate(currentDate).getTime() < stringToDate(MIN).getTime()) MIN=currentDate
    }
    return {
        endDate: MIN,
        startDate: MAX
    }
}

export const toStartOfDay = date => {
    date = date instanceof Date ? date : new Date(date)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export function howManyDaysHavePassed(past, future=new Date()) {
    //* this handles if we pass the date as string
    if(!isDateObject(past)) past = stringToDate(past)
    if(!isDateObject(future)) future = stringToDate(future)
    past = toStartOfDay(past)
    future = toStartOfDay(future)
    const diffInMs = future.getTime() - past.getTime()
    return Math.round(Math.abs(diffInMs/MILLIS_PER_DAY))
}

export const getDaysInMonth = date => {
    const d = new Date(date.getFullYear(), date.getMonth()+1, 0)
    return d.getDate()
}

// Formats a date to the dateString format returned from backend reports
export const dayToStr = (date) => {
    let yearToStr = date.getFullYear().toString();
    let monthToStr = date.getMonth() + 1;
    let dateToStr = date.getDate().toString();

    monthToStr = monthToStr.toString();
    if (monthToStr.length === 1) monthToStr = `0${monthToStr}`;
    if (dateToStr.length === 1) dateToStr = `0${dateToStr}`;
    return yearToStr + monthToStr + dateToStr;
}

export const isPastDate = date => {
    let now = new Date()
    return date.getTime() < now.getTime()
}

//* The function takes a Date object (date) and returns a string that represents the date and time, formatted in a human-readable way.
export const getReadableDateTime = (date) => {
    return date instanceof Date ? `${date.toLocaleDateString('en', {year:'numeric', month:'long', day:'numeric'})} at ${date.toLocaleTimeString('en', {hour:'numeric', minute:'numeric'})}` : ""
}

export const formatSecondsToMinutes = (numSeconds) => {
    if (numSeconds === 0) {
        return "0sec"
    }

    const numMinutes = Math.floor(numSeconds / 60);
    const remainderNumSeconds = numSeconds - (numMinutes * 60);

    let str = ""

    if (numMinutes > 0) {
        str += numMinutes + "min"
    }

    if (remainderNumSeconds > 0) {
        str += " " + remainderNumSeconds + "sec"
    }

    return str
}

/**
 * Processes data to ensure a continuous date range between startDate and endDate.
 * Fills gaps with the specified emptyDataFill value (default: 0).
 * Returns an array with 'YYYYMMDD'-formatted date-data pairs.
 */



export function fillMissingDates(data, startDate, endDate, emptyDataFill=0) {

    //* Convert data into a map for easie lookup
    const dataMap = new Map(data);

    let currentDate = new Date(startDate);
    const result = [];

    while (currentDate <= endDate) {
        const dateString = DateHelper.getStringFromDate(currentDate, 'YYYYMMDD');
        if (dataMap.has(dateString)) {
            result.push([dateString, dataMap.get(dateString)]);
        } else {
            result.push([dateString, emptyDataFill]);
        }
        //* Move to next day
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return result;
  }

export const getReadableDate = (date) => {
    return date instanceof Date ? `${date.toLocaleDateString('en', {year:'numeric', month:'long', day:'numeric'})}` : ""
}

export const getDateFromUNIXTimestamp = (timestamp) => new Date(timestamp * 1000);

// takes an instance in time and returns a date that represents that selection, but as if the user is in GMT
export const shiftToGMT = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * SECONDS_PER_MINUTE * MILLIS_PER_SECOND);
}

export const rollBackYears = (date, n = 1) => {
    const d = new Date(date);

    d.setFullYear(d.getFullYear() - n);

    return d;
}

export const createDateRange = (startDate, endDate) => {
    const dateRange = []
    let currentDate = new Date(startDate)
    while (currentDate <= endDate) {
        dateRange.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return dateRange
}