import { API } from '../constants/api'
import { makeRequest } from '../helpers/make-request'
import { editBlueprint } from './blueprintActions'
import { loadCreativeFoldersForCalendar } from './calendarActions'
import { REPO_SELECT_OPTION, REPO_LIST, REPO_CREATIVE_LIST, BLUEPRINTS_LIST, CLEAR_REPO, CREATE_REPO, UPDATE_REPO, DELETE_REPO_FOLDER } from './types'

export const selectRepoOption = option => dispatch => (
    dispatch({
        type: REPO_SELECT_OPTION,
        payload: option
    })
)

export const getRepoList = (franchiseName, callback) => dispatch => {
    try {
        if (franchiseName) {
            window.eulerity.makeApiCall(`/api/franchise/getCreativeRepos?franchise=${encodeURIComponent(franchiseName)}`, 'GET', null, function(resp) {
                dispatch({
                    type: REPO_LIST,
                    payload: resp
                })
                callback?.(resp);
            })
        }
    }
    catch (err) {
        console.error('Unable to load repos list');
    }
}

export const repoCreativeList = (defaultUid, repoId) => (dispatch, getState) => {

    const state = getState()

    loadCreativeFoldersForCalendar(state.franchise.name, state.franchise.defaultLocation)(dispatch)

    try {
        if (!defaultUid || !repoId) return;
        const list = [];
        window.eulerity.makeBatchedApiCall({
            url: `/api/creative/list?uid=${defaultUid}&lid=${repoId}`,
            dataCallback: function (resp) {
                resp.forEach(c => list.push(c))
            },
            doneCallback: function () {

                dispatch({
                    type: REPO_CREATIVE_LIST,
                    payload: list
                });
            }
        })
    }
    catch (err) {
        console.error('Unable to retrieve creative repos');
    }
}

export const getBlueprintsList = (franchiseName, cb) => dispatch => {
    if (!franchiseName) return;
    window.eulerity.makeApiCall(`${API.BLUEPRINTS.GET_ALL}?franchise=${encodeURIComponent(franchiseName)}`, 'GET', null, blueprints => {
        dispatch({
            type: BLUEPRINTS_LIST,
            payload: blueprints
        })

        cb?.()
    }, {
        1000: () => {
            console.error('Unable to retrieve blueprints')
        }
    })
}

export const attachRepo = ({currBlueprint, selectedRepo, detach, callback}) => dispatch => {
    dispatch(editBlueprint({
        id: currBlueprint.id,
        updates: {
            creativeRepo: detach ? null : selectedRepo?.websafe
            // "repo" property on blueprints is deprecated, does not exist on ProfileTemplate(Blueprint) object in backend
        },
        callback
    }))
}

export const createRepo = ({ uid, lid, nickname }) => _D => {
    if (uid && lid && nickname) {
        return new Promise((resolve, reject) => {
            makeRequest()
                .url(API.LOCATION.CLONE)
                .param("uid", uid)
                .param("lid", lid)
                .param("nickname", nickname)
                .retry(3)
                .post()
                .then((response) => {
                    _D({
                        type: CREATE_REPO,
                        payload: response
                    })
                    resolve()
                })
                .catch((err) => {
                    console.error('Unable to create new creative folder')
                    reject(err)
                })
        })
    }
}

export const editRepo = ({lid, uid, nickname}) => dispatch => {
    return new Promise((resolve, reject) => {
        makeRequest()
        .url(API.LOCATION.UPDATE)
        .param("uid", uid)
        .param("lid", lid)
        .body({ nickname })
        .post()
        .then((response) => {
            dispatch({
                type: UPDATE_REPO,
                payload: response
            })
            resolve()
        })
        .catch((err) => {
            console.error('Unable to rename new creative folder')
            reject(err)
        })
    })
}


export const deleteRepo = (lid, uid, franchiseName) => dispatch => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/location/delete?uid=${uid}&lid=${lid}&franchise=${encodeURIComponent(franchiseName)}`,
            method: 'POST',
            callback: () => {
                dispatch({
                    type: DELETE_REPO_FOLDER,
                    payload: { lid }
                })
                resolve()
            },
            errorCallbacks: {
                1000: reject
            },
            retry: 3
        })
    })
}

export const clearRepo = () => dispatch => dispatch({ type: CLEAR_REPO })