import React from 'react'
import {
    AD_NETWORK,
    POST_NETWORK,
    VIDEO_POST_NETWORK,
} from '../../../helpers/creative.util'
import { ReactComponent as PlayButton } from '../../../assets/svg/PlayButton.svg'
import { ReactComponent as YoutubeIcon } from '../../../assets/svg/Youtube.svg'
import { Span } from '../../../styles/typography'
import { BsWindow } from 'react-icons/bs'
import TwitterXIcon from '../../../assets/icons/TwitterXIcon'
import { FacebookIcon } from '../../../assets/icons/FacebookIcon'
import { GoogleIcon } from '../../../assets/icons/GoogleIcon'
import { InstagramIcon } from '../../../assets/icons/InstagramIcon'
import { LinkedInIcon } from '../../../assets/icons/LinkedInIcon'

// Ideally, when we want to use this component we should pass in one of these
export const NETWORK_ICON_NETWORKS = {
    FACEBOOK: POST_NETWORK.FACEBOOK,
    TWITTER: POST_NETWORK.TWITTER,
    INSTAGRAM: POST_NETWORK.INSTAGRAM,
    LINKEDIN: POST_NETWORK.LINKEDIN,
    GOOGLE: POST_NETWORK.GMB,
    YOUTUBE: VIDEO_POST_NETWORK.YOUTUBE,
    DISPLAY: 'DISPLAY',
    RESIZED: 'RESIZED',
    VIDEO: 'VIDEO',
    SEARCH: 'SEARCH',
} as const

const NETWORK_ICONS = {
    [NETWORK_ICON_NETWORKS.GOOGLE]: <GoogleIcon />,
    [NETWORK_ICON_NETWORKS.FACEBOOK]: <FacebookIcon />,
    [NETWORK_ICON_NETWORKS.TWITTER]: <TwitterXIcon />,
    [NETWORK_ICON_NETWORKS.INSTAGRAM]: <InstagramIcon />,
    [NETWORK_ICON_NETWORKS.LINKEDIN]: <LinkedInIcon />,
    [NETWORK_ICON_NETWORKS.YOUTUBE]: <YoutubeIcon />,
    [NETWORK_ICON_NETWORKS.DISPLAY]: (
        <Span xs regular>
            Web
        </Span>
    ),
    [NETWORK_ICON_NETWORKS.RESIZED]: <BsWindow size={18} />, // * Place holder
    [NETWORK_ICON_NETWORKS.VIDEO]: <PlayButton />,
} as const

const NETWORK_ICON_MAP = {
    [NETWORK_ICON_NETWORKS.GOOGLE]: NETWORK_ICON_NETWORKS.GOOGLE,
    [NETWORK_ICON_NETWORKS.FACEBOOK]: NETWORK_ICON_NETWORKS.FACEBOOK,
    [NETWORK_ICON_NETWORKS.TWITTER]: NETWORK_ICON_NETWORKS.TWITTER,
    [NETWORK_ICON_NETWORKS.INSTAGRAM]: NETWORK_ICON_NETWORKS.INSTAGRAM,
    [NETWORK_ICON_NETWORKS.LINKEDIN]: NETWORK_ICON_NETWORKS.LINKEDIN,
} as const

const POST_NETWORK_MAP = {
    [POST_NETWORK.FACEBOOK]: NETWORK_ICON_NETWORKS.FACEBOOK,
    [POST_NETWORK.TWITTER]: NETWORK_ICON_NETWORKS.TWITTER,
    [POST_NETWORK.INSTAGRAM]: NETWORK_ICON_NETWORKS.INSTAGRAM,
    [POST_NETWORK.LINKEDIN]: NETWORK_ICON_NETWORKS.LINKEDIN,
    [POST_NETWORK.GMB]: NETWORK_ICON_NETWORKS.GOOGLE,
    [VIDEO_POST_NETWORK.YOUTUBE]: NETWORK_ICON_NETWORKS.YOUTUBE,
} as const

const AD_NETWORK_MAP = {
    [AD_NETWORK.SEARCH]: NETWORK_ICON_NETWORKS.GOOGLE,
    [AD_NETWORK.DISPLAY]: NETWORK_ICON_NETWORKS.DISPLAY,
    [AD_NETWORK.RESIZED]: NETWORK_ICON_NETWORKS.RESIZED,
    [AD_NETWORK.VIDEO]: NETWORK_ICON_NETWORKS.VIDEO,
} as const

// This allows us to support the previous network names, in case we are passing in from POST_NETWORK or AD_NETWORK instead of the preferred NETWORK_ICON_NETWORKS
const NETWORK_MAP = {
    ...NETWORK_ICON_MAP,
    ...POST_NETWORK_MAP,
    ...AD_NETWORK_MAP,
} as const

type NetworkIconProps = {
    network: keyof typeof NETWORK_MAP
    invert?: boolean
} & React.SVGProps<SVGSVGElement>

const NetworkIcon = (props: NetworkIconProps) => {
    const element = NETWORK_ICONS[NETWORK_MAP[props.network]]
    if (!React.isValidElement(element)) return null
    return React.cloneElement(element, props) ?? null
}

export default NetworkIcon
