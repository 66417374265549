import StringUtil from "../../../../../../helpers/string.util"


export const filterFieldNameOptions = [
    { value: 'hostName', label: 'Hostname' },
    { value: 'pagePath', label: 'Page Path' },
    { value: 'pageTitle', label: 'Page Title' }
]

export const filterTypeOptions = [
    { value: 'contains', label: 'contains' },
    { value: 'exact', label: 'exactly match' },
    { value: 'begins', label: 'begin with' },
    { value: 'ends', label: 'end with' },
    { value: 'full regexp', label: 'exactly matches regex'},
    { value: 'partial regexp', label: 'partially matches regex'}
]

export const getDataFilterCopy = (dataFilter) => {
    const { fieldName, type, value } = dataFilter

    const matchedOption = filterFieldNameOptions.find(opt => opt.value === fieldName)
    const fieldNameValue = matchedOption?.label
    return `${fieldNameValue || fieldName} ${StringUtil.capitalizeFirstLetter(type)} ${value}`
}

export const sortGA4Filters = (a,b) => {
    const fieldNameCompareResult = a.fieldName.toLowerCase().localeCompare(b.fieldName.toLowerCase())
    const typeCompareResult = a.type.toLowerCase().localeCompare(b.type.toLowerCase())
    const valueCompare = a.value.toLowerCase().localeCompare(b.value.toLowerCase())
    return fieldNameCompareResult || typeCompareResult || valueCompare
}