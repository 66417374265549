import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Body } from '../../styles/typography';
import { LoadingIndicator } from './styles';

type LoadingDotsProps = HTMLAttributes<HTMLDivElement> & {
    text?: string
}

const LoadingDots = (props: LoadingDotsProps) => {
    return (
        <LoadingContainer style={props.style}>
            <Body>{props.text}</Body>
            <LoadingIndicator />
        </LoadingContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    margin: 20px 0;
`

export default LoadingDots
