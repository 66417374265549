/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useRef } from 'react'
import gsap from "gsap";


//* The useFade hook is a custom React hook designed to apply a smooth fade-in animation to a component, enhancing the user experience by providing visually appealing transitions when components appear on the screen.
//* It takes an optional dependency array as an argument and returns a ref

const useFade = (dependency=[]) => {
    const ref = useRef(null)
    useLayoutEffect(() => {
        gsap.timeline().fromTo(ref?.current, { opacity: 0 }, { opacity: 1, ease: "linear" });
        gsap.config({ nullTargetWarn: false });
      }, dependency );


    return ref
}
export default useFade