
import { useDispatch } from 'react-redux';


const useFilterUtilFunctions = ({ filters, setFilters, isLocalFilter }) => {
    const _D = useDispatch()

    let selectAllFiltersHandler = ({selectedCategory, searchValue, filterItems}) => {
        const fItem = filterItems.findIndex((item) => item.filterCategory.includes(selectedCategory));
        const fLabels = filterItems[fItem].labels.filter((label) => label.toLowerCase().includes(searchValue));
        // * If no filter is selected in given category - add currently displayed filters
        if (!filters[selectedCategory].length) {
            if(isLocalFilter) setFilters({...filters, [selectedCategory]: fLabels });
            else _D(setFilters({...filters, [selectedCategory]: fLabels }));
          return;
        }

        const remainingFilters = fLabels.filter(fLabel => filters[selectedCategory].every(label => !label.includes(fLabel)))
        const updatedFilters = [...filters[selectedCategory], ...remainingFilters]
        if(isLocalFilter) setFilters(({ ...filters, [selectedCategory]: updatedFilters }))
        else _D(setFilters(({ ...filters, [selectedCategory]: updatedFilters })))
    }

    let deselectAllFiltersHandler = ({selectedCategory, searchValue}) => {
        let updatedFilters = filters[selectedCategory].filter((label) => !label.toLowerCase().includes(searchValue));
        // * Reset entire filter category if no searchTerm exists
        if (!searchValue.trim().length) {
          if(isLocalFilter) setFilters({ ...filters, [selectedCategory]: [] })
          else _D(setFilters({ ...filters, [selectedCategory]: [] }));
          return;
        }
        if(isLocalFilter) setFilters({ ...filters, [selectedCategory]: updatedFilters })
        else _D(setFilters({ ...filters, [selectedCategory]: updatedFilters }));
      };

      const selectedFiltersHandler = ({filterCategory, filterLabel}) => {
        let updatedFilters = filters;
        if (filters[filterCategory].includes(filterLabel)) {
          updatedFilters = filters[filterCategory].filter((f) => f !== filterLabel);
        } else if (!filters[filterCategory].includes(filterLabel)) {
          updatedFilters = filters[filterCategory];
          updatedFilters.push(filterLabel);
        }
        if(isLocalFilter) setFilters({ ...filters, [filterCategory]: updatedFilters });
        else _D(setFilters({ ...filters, [filterCategory]: updatedFilters }));
      };

    return {selectAllFiltersHandler, deselectAllFiltersHandler, selectedFiltersHandler}
}


export default useFilterUtilFunctions