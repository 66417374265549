import styled from "styled-components";
import { decideTextColor, hexToRgba } from "../../helpers/colors.util";
import DatePicker from "react-datepicker";
import React from "react";

const DatePickerWrapper = styled.div`

    .react-datepicker__navigation {
        margin: 0;
    }

    //* Disabled states for invalid dates
    .react-datepicker__day--disabled {
        color: ${(props) => hexToRgba(props.theme.color.textColor1, 0.6)} !important;
        pointer-events: none;
    }

    .react-datepicker {
        border: none;
        box-shadow: ${({theme}) => hexToRgba(theme.color.textColor1, 0.1)} 0px 4px 12px;
        color: ${(props) => props.theme.color.textColor1};
        background-color: ${(props) => props.theme.color.accentColor4};
        font-family: ${({theme}) => theme.font.body.family};
    }
    .react-datepicker__header {
        border: none;
        color: ${(props) => props.theme.color.textColor1};
        background-color: ${(props) => props.theme.color.accentColor4};
    }

    .react-datepicker__current-month {
        color: ${(props) => decideTextColor(props.theme.color.accentColor4)};
    }

    .react-datepicker__day-name {
        color: ${(props) => decideTextColor(props.theme.color.accentColor4)};
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__day {
        color: ${(props) => props.theme.color.textColor1};
        font-family: ${({theme}) => theme.font.body.family};
        &:hover {
            background-color: ${(props) => props.theme.color.accentColor3};
            color: ${(props) => decideTextColor(props.theme.color.accentColor3)};
        }
        &--selected {
            background-color: ${(props) => props.theme.color.accentColor2};
            color: ${(props) => decideTextColor(props.theme.color.accentColor2)};
        }
    }

    .react-datepicker__month-text {
        padding: 0.5rem;
        font-family: ${({theme}) => theme.font.body.family};
        &:hover {
            background-color: ${(props) => props.theme.color.accentColor3};
            color: ${(props) => decideTextColor(props.theme.color.accentColor3)};
        }
        &--selected {
            background-color: ${(props) => props.theme.color.accentColor2};
            color: ${(props) => decideTextColor(props.theme.color.accentColor2)};
        }
    }
`

export const StyledDatePicker = (props: React.ComponentProps<typeof DatePicker>) => {
    return (
        <DatePickerWrapper>
            <DatePicker {...props} />
        </DatePickerWrapper>
    )
}