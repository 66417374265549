import React, { useCallback, useState } from 'react';
import { BsSliders } from 'react-icons/bs';

import useEventListener from '../../../hooks/useEventListener';
import { SuperTableColumnFilterWrap, SuperTableCustomFilterCloseTarget } from '../style';

const DeprecatedSuperTableColumnFilterDoNotUse = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = useCallback((e) => {
        setIsOpen(false);
    }, []);

    useEventListener('click', onClick);

    return (
        <>
            {isOpen && <SuperTableCustomFilterCloseTarget onClick={onClick} />}
            <div onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                <BsSliders onClick={() => setIsOpen(!isOpen)} size="14px" />
                <SuperTableColumnFilterWrap
                    className="supertable-column-filter"
                    style={{
                        display: isOpen ? 'block' : 'none',
                    }}
                >
                    {children}
                </SuperTableColumnFilterWrap>
            </div>
        </>
    );
};

export default DeprecatedSuperTableColumnFilterDoNotUse;
