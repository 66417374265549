import React, { createContext, useEffect, useState, useContext } from 'react'
import Modal from '../../../components/modal/Modal'
import { CopyCreativeID } from '../../../components/reusableUI/components/CopyCreativeID'
import { getCreativeMetadataFull } from '../utils'
import { Ad, CreativeContext as CreativeContextType, Post } from '../creative.types'
import { useCreativeMetadata } from '../hooks/useCreativeMetadata'
import { CreativeOverview } from './CreativeModalContent'
import ManageClones from './ManageClones'

const initialState: CreativeContextType = {
    isCloneModalOpen: false,
    setIsCloneModalOpen: () => {},
    metadata: getCreativeMetadataFull(),
    refreshCreatives: () => {},
    onClose: () => {},
    creative: undefined,
    needsApproval: false,
    view: 'overview' as CreativeModalView,
    setView: () => {}
}

const CreativeContext = createContext(initialState)

// add more states here that the modal can be in
export type CreativeModalView = 'overview' | 'manage'

const useCreativeModalState = () => {
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
    const [view, setView] = useState<CreativeModalView>('overview')

    return {
        isCloneModalOpen,
        setIsCloneModalOpen,
        view,
        setView
    }
}

const CreativeModalV2 = (props: {
    onClose: () => void
    creative: Ad | Post
    refreshCreatives: () => void
    needsApproval?: boolean
}) => {

    const { onClose, creative, refreshCreatives, needsApproval } = props

    // Modal State
    const { isCloneModalOpen, setIsCloneModalOpen, view, setView } = useCreativeModalState()

    // * Creative Metadata
    const metadata = useCreativeMetadata(creative)

    useEffect(() => {
        console.log('Opened Creative Details', creative) // ! Keep this logging here for debugging on production
    }, [])

    // * Creative Context Values
    const creativeContextValues: CreativeContextType = {
        metadata,
        creative,
        refreshCreatives,
        onClose,
        needsApproval: needsApproval || false,
        isCloneModalOpen,
        setIsCloneModalOpen,
        view,
        setView
    }

    return (
        <Modal
            width='1140px'
            onClose={onClose}
            footerContent={view === 'overview' && <CopyCreativeID creative={creative}/>}
        >
            <CreativeContext.Provider value={creativeContextValues}>
                {
                    view === 'overview' && <CreativeOverview />
                }
                {
                    view === 'manage' && <ManageClones />
                }
            </CreativeContext.Provider>
        </Modal>
    )
}

const isValidContextState = (context: CreativeContextType): context is CreativeContextType & {
    creative: Ad | Post,
} => {
    return !!context.creative
}

export const useCreativeContext = () => {
    const context = useContext(CreativeContext)

    if (isValidContextState(context)) {
        return context
    } else {
        throw new Error('Invalid context state')
    }
}
export default CreativeModalV2