import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../../../components/modal/Modal'
import useInitializeOptions, { POST_LOCATION_FILTERS } from '../../advancedFilter/hooks/useInitializeOptions'
import EntityResultsSelector from '../../entityResultsSelector/EntityResultsSelector'
import { ButtonContainer, OutlineBtn, PrimaryCTABtn } from '../../../styles/buttons'
import CloneStatusModal from './CloneStatusModal'
import { API } from '../../../constants/api'
import { useDispatch, useSelector } from 'react-redux'
import { hideOverlay, showOverlay } from '../../../actions/overlayActions'
import { getRepoList } from '../../../actions/repoActions'
import CloneCreativeDetails from './CloneCreativeDetails'
import BulkCreativesSelector from './BulkCreativesSelector'
import { useAccess } from '../../../hooks/useAccess'
import { makeRequest } from '../../../helpers/make-request'
import { getCreativeLocationID, getCreativeUserID, isPostEntity } from '../../../helpers/creative.util'
import { isSecondaryLocation } from '../../../types/location'
import { toast } from 'react-toastify'
import useExperiment from '../../../hooks/useExperiment'
import { EXPERIMENTS } from '../../../helpers/experimentHelpers'

const CloneCreativeModal = ({ creatives, onClose, onBack}) => {
    // * Extract creative from array (single clone flow will always have 1 creative, bulk flow we will select the first creative)
    const creative = creatives[0]
    const isSingleCreative = creatives?.length === 1

    const _D = useDispatch()

    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedCreatives, setSelectedCreatives] = useState([])
    const [successfulClones, setSuccessfulClones] = useState([])
    const [failedClones, setFailedClones] = useState([])
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [repos, setRepos] = useState([])
    const blockPostsOnSecondariesExp = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)

    const areAllPosts = useMemo(() => {
        return creatives.every(c => isPostEntity(c))
    }, [creatives])

    const franchiseName = useSelector(s => s.franchise.name)
    const { canManageFranchise } = useAccess()
    const isManager = canManageFranchise()

    const { locationOptions, repoOptions, defaultLocationOptions } = useInitializeOptions(blockPostsOnSecondariesExp.active && areAllPosts ? POST_LOCATION_FILTERS : {})

    useEffect(() => {
        if (isManager) _D(getRepoList(franchiseName, (resp) => setRepos(resp)));
    }, [isManager, franchiseName])

    // * Depending on the flow the disabled btn logic can vary
    const isCloneButtonDisabled = useMemo(() => {
        // * If there is only a single creative to clone all we care about is if the user selected at least 1 target destination to clone too.
        if (isSingleCreative) return !selectedOptions.length
        // * In bulk cloning we want to ensure that there are at least a creative to clone, as well as ensuring the user selects targets to clone too as well as creatives to clone.
        return !creatives.length || !selectedOptions.length || !selectedCreatives.length
    }, [isSingleCreative, selectedOptions, selectedCreatives, creatives])


    // * Clone selected creative(s) to given target(s)
    const cloneCreative = (targetOption, currCreative) => {
        const lid = targetOption?.id
        const uid = targetOption?.user?.raw?.name

        // * Get lid and uid of selected creative (single select)
        const creativeLocationId = getCreativeLocationID(currCreative)
        const creativeUserId = getCreativeUserID(currCreative)

        return new Promise(async (resolve, reject) => {
            return await makeRequest()
                .url(API.CREATIVE.CLONE_CREATIVE)
                .param('lid', creativeLocationId)
                .param('uid', creativeUserId)
                .param('targetLid', lid)
                .param('targetUid', uid)
                .param(currCreative?.qsParam, currCreative?.id)
                .retry(10)
                .post()
                .then(resp => {
                    resolve(resp)
                }).catch(e => {
                    console.error(e)
                    reject(e)
                })
        })
    }

    // * Handle Clone Api Call (selectedCreatives is meant for bulk cloning)
    const handleCloneToLocations = async () => {
        const hasSelectedPost = isSingleCreative ? isPostEntity(creative) : selectedCreatives.some(c => isPostEntity(c))
        const hasSelectedSecondary = selectedOptions.some(opt => isSecondaryLocation(opt))
        if (blockPostsOnSecondariesExp.active && hasSelectedPost && hasSelectedSecondary) {
            return toast.error("Social post creatives can only be cloned to primary campaigns. Please adjust your selections and try again.")
        }

        let completedCalls = 0;
        _D(showOverlay('Attempting to clone creative(s) to campaign(s)/folder(s)'))

        // * Check to see if user is bulk cloning
        if (selectedCreatives?.length) {
            // * For EACH SELECTED CREATIVE - attempt to clone it to EACH SELECTED TARGET
            for (let i = 0; i < selectedCreatives?.length; i++) {
                for (let j = 0; j < selectedOptions?.length; j++) {
                    const response = await cloneCreative(selectedOptions[j], selectedCreatives[i])
                    if (response?.status === 'success') setSuccessfulClones(prev => [...prev, { targetDestination: selectedOptions[j], creative: selectedCreatives[i] }])
                    else setFailedClones(prev => [...prev, { targetDestination: selectedOptions[j], creative: selectedCreatives[i] }])
                    completedCalls++
                    _D(showOverlay(`Cloning ${completedCalls} creatives to ${selectedOptions?.length} campaign(s)/folder(s)`))
                }
            }
        } else {
            // * FOR CLONING SINGLE CREATIVES
            for (let i = 0; i < selectedOptions?.length; i++) {
                const response = await cloneCreative(selectedOptions[i], creative)
                if (response?.status === 'success') setSuccessfulClones(prev => [...prev, { targetDestination: selectedOptions[i], creative }])
                else setFailedClones(prev => [...prev, { targetDestination: selectedOptions[i], creative}])
                completedCalls++
                _D(showOverlay(`Cloning ${completedCalls} creatives to ${selectedOptions?.length} campaign(s)/folder(s)`))
            }
        }

        _D(hideOverlay())
        setIsConfirmationModalOpen(true)
        return
    }

    const options = useMemo(() => {
        let repoList = []
        if (isManager) repoList = [...repoOptions(repos), ...defaultLocationOptions()]
        return [
            ...locationOptions.filter(loc => loc?.websafe !== creative?.location?.websafe),
            ...repoList.filter(repo => repo?.websafe !== creative?.location?.websafe)
        ]
    }, [locationOptions, repoOptions, defaultLocationOptions, isManager, creative, repos])

    return (
        <Modal
            width='1140px'
            backText='Clone Information'
            onBack={onBack}
            onClose={onClose}
        >
            <div>
                {
                    isSingleCreative ?
                        <CloneCreativeDetails creative={creative} />
                        :
                        <BulkCreativesSelector
                            selectedCreatives={selectedCreatives}
                            setSelectedCreatives={setSelectedCreatives}
                            creatives={creatives}
                        />
                }
                <div style={{ marginTop: '20px' }}>
                    <EntityResultsSelector
                        onSelect={setSelectedOptions}
                        options={options}
                    />
                </div>
                <ButtonContainer>
                    <OutlineBtn onClick={onClose}>Cancel</OutlineBtn>
                    <PrimaryCTABtn disabled={isCloneButtonDisabled} onClick={() => handleCloneToLocations()}>Clone to Selected Campaigns</PrimaryCTABtn>
                </ButtonContainer>
            </div>
            {
                isConfirmationModalOpen &&
                <CloneStatusModal
                    onClose={onClose}
                    successfulClones={successfulClones}
                    failedClones={failedClones}
                    creative={isSingleCreative && creative}
                />
            }
        </Modal>
    )
}

export default CloneCreativeModal