import { LabelItem } from './styles';
import { ReactComponent as XIcon } from '../../../../assets/images/x-icon.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus-icon.svg'
import { Small } from '../../../../styles/typography';

// * text - text of label
// * handleClick - function to fire off when "X" or component is clicked
// * shrink - this flag will control if you wish to not have white-space wrap (exclude for no-wrap)
// * isPost, isAd - passed to control if you wish to use creativeColors over accentColor2

export const ActiveFilterLabel = ({ text, onRemove, isAd, isPost, shrink }) => {
  return (
    <LabelItem className='label-item' shrink={shrink} isAd={isAd} isPost={isPost}>
      <Small>{text}</Small>
      <XIcon onClick={onRemove} />
    </LabelItem>
  );
};

// * This Label is meant for the suggested text option - seeing how they toggle between selected/unselected rather than being active we enable click events via the whole component rather than the icons

// * option - value of current label
// * selectedOptions - list of currently selected options


export const SelectableOptionLabel = ({ text, handleClick, isAd, isPost, shrink, option, selectedOptions, isTyping}) => {
  return (
    <LabelItem isTyping={isTyping} onClick={isTyping ? null : handleClick } shrink={shrink} option={option} selectedOptions={selectedOptions} isAd={isAd} isPost={isPost}>
      <Small colorInherit>{text}{isTyping && <span className="cursor">|</span>}</Small>
      {selectedOptions.includes(option) ? <XIcon /> : <PlusIcon />}
    </LabelItem>
  )
}
