import React from 'react'
import { useTheme } from 'styled-components'
import TwinkleIcon from '../../../../../assets/icons/TwinkleIcon'
import { Small, Span } from '../../../../../styles/typography'
import { StyledAlertWrapper } from './styles'

const PrimeTimeAlert = () => {
  const theme = useTheme()

  return (
    <StyledAlertWrapper>
      <div>
        <TwinkleIcon color={theme.color.accentColor2} />
      </div>
      <Small>Your <Span bold>prime time post</Span> will be published based on peak user engagement time.</Small>
    </StyledAlertWrapper>
  )
}

export default PrimeTimeAlert