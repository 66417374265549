import React, {useMemo, useState } from 'react'

import useDropDownAnimation from '../../../hooks/useDropDownAnimation';
import { IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Body } from '../../../styles/typography';
import { useTheme } from 'styled-components';
import { FiAlertTriangle } from "react-icons/fi";
import { IoCheckmarkCircle } from "react-icons/io5";
import { callAndDecompressJob, isJobModalType } from '../utils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { hideOverlay, showOverlay } from '../../../actions/overlayActions';
import { Job, JobModalType, JobTasksResponse } from '../types';
import JobModalManager from './jobModals/JobModalManager';

import { useJob } from '..';
import { StyleJobIndicator, StyleJobIndicatorContent, StyleJobIndicatorHeader, StyleJobIndicatorJobs } from "../styles";
import CurrentJob from './Job';


type DropdownAnimationProps = {
    height: string,
}

const JobIndicator = () => {
    const {jobs, visible, jobsCompleted, jobListDescription, closeJobsComponent} = useJob()
    const theme = useTheme()

    const options: DropdownAnimationProps = {height: 'min-content'}
    const { handleDropDownAnimation, status } = useDropDownAnimation("task-main", "task-children", options);

    const [jobModal, setJobModal] = useState<boolean>(false)
    const [jobResponse, setJobResponse] = useState<JobTasksResponse[]>([]);
    const [jobType, setJobType] = useState<JobModalType | null>(null);

    const _D = useDispatch()

    const getSelectedJob = async (job: Job) => {
        try {
            _D(showOverlay())
            const decompressedResponse = await callAndDecompressJob(job.id);
            setJobResponse(decompressedResponse)

            if(!job.description) throw new Error(`No Job description provided`)
            if(!isJobModalType(job.description)) throw new Error(`Job description ${job.description} not registered`)

            setJobType(job.description)
            setJobModal(true)
        } catch (error) {
            toast.error("Something went wrong")
        } finally {
            _D(hideOverlay())
        }
    }

    const JobsCompletionStatusIcon = useMemo(() => {
        const CODE_COLORS: Record<'SUCCESS' | 'PARTIAL' | 'ERROR', string> = {
            SUCCESS: theme.color.activeButtonColor,
            PARTIAL: theme.color.warningButtonColor,
            ERROR:   theme.color.errorNegativeButtonColor,
        };
        let allCompleted = true;
        let allFailed = true;

        for (const job of (jobs || [])) {
          if (job.numberOfSuccess !== job.numberOfTasks) {
            allCompleted = false;
          }
          if (job.numberOfFailure !== job.numberOfTasks) {
            allFailed = false;
          }

          if (!allCompleted && !allFailed) return <FiAlertTriangle color={CODE_COLORS.PARTIAL} />;
        }

        if (allCompleted) {
          return <IoCheckmarkCircle size={25} color={CODE_COLORS.SUCCESS} />;
        }

        if (allFailed) {
          return <FiAlertTriangle color={CODE_COLORS.ERROR} />;
        }

      }, [jobs, theme.color.activeButtonColor, theme.color.errorNegativeButtonColor, theme.color.warningButtonColor]);


    return (
        <StyleJobIndicator visible={visible}>
            <StyleJobIndicatorHeader>
                <div className="bj-indicators" >
                   {jobsCompleted && <div className='bj-indicators_icon'>{JobsCompletionStatusIcon}</div>}
                    <Body semibold className='bj-indicators_jobs'>{jobListDescription}</Body>
                </div>

                <div className="bj-actions">
                    <div onClick={handleDropDownAnimation}><IoIosArrowDown color={theme.color.accentColor2} size={18} style={{transform: `rotate(${status ? "0deg": "180deg"})`}} /></div>
                    {jobsCompleted && <div onClick={closeJobsComponent}><IoClose color={theme.color.accentColor2} /></div>}
                </div>
            </StyleJobIndicatorHeader>

            <StyleJobIndicatorJobs className="task-main">
                <StyleJobIndicatorContent className="task-children">
                    {jobs.map(job => <CurrentJob key={job.id} job={job} getSelectedJob={getSelectedJob} />)}
                </StyleJobIndicatorContent>
            </StyleJobIndicatorJobs>
            { jobModal && <JobModalManager onClose={() => setJobModal(false)} jobResponse={jobResponse} type={jobType}/>}
        </StyleJobIndicator>
    )
}

export default JobIndicator