import { ageTargetCopy } from "../../../../../../../helpers/demographics/ageTargets";
import columnBuilder from "../../../columnBuilder";
import { reportLoader } from '../../../loaders';

const ageDistributionReportCategory = columnBuilder()
	.category('distribution')
	.subCategory('Age')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.groupName} ${meta.name}`
	})

const ageDistributionFormatter = (value: number) => {
	return `${value.toFixed(0)}%`
}

const ageColumns = [
	...Object.values(ageTargetCopy).map((ageRange) => {
		return ageDistributionReportCategory
			.groupId('age-distribution-clicks')
			.groupName('Clicks')
			.id(`age-distribution-clicks-${ageRange}`)
			.name(`(${ageRange})`)
			.makeRow((data) => {
				return data.row.age.clicks_pct[ageRange]
			})
			.toString(ageDistributionFormatter)
	}),
	...Object.values(ageTargetCopy).map((ageRange) => {
		return ageDistributionReportCategory
			.groupId('age-distribution-impressions')
			.groupName('Impressions')
			.id(`age-distribution-impressions-${ageRange}`)
			.name(`(${ageRange})`)
			.makeRow((data) => {
				return data.row.age.impressions_pct[ageRange]
			})
			.toString(ageDistributionFormatter)
	}),
]

export default ageColumns