import { AgeTarget } from "../helpers/demographics/ageTargets"
import { GenderTarget } from "../helpers/demographics/genderTargets"
import { GeoTarget } from "../helpers/demographics/geoRadius"
import { IncomeTarget } from "../helpers/demographics/incomeTarget"
import { ParentTarget } from "../helpers/demographics/parentTargets"
import { Analytics4QueryFilter } from "./googleAnalytics"
import { FocusOption } from "../components/selector/layouts/LocationFocusLayout"
import { Audience, BlobKey, Email, Keyword, LocationConnections, LocationId, UserId, Websafe, WeightMap } from "./primitives"
import { Subscription } from "./subscription"
import { CALL_FORWARDING_LEASE_STATUS_TYPE } from "../pages/locations/components/CallForwardingSetup"
import { LinkedinTarget } from "../features/linkedInTargets/types"

export type LocationSummary = any

export type Objective = "traffic" | "leads" | "reach"
export type LocationType = "primary" | "secondary"

type LocationFields = {
    subscription: Subscription
    adSchedule: any
    ageTargets: AgeTarget[]
    allAdwordsAdAccounts: any[]
    analytics4Account?: {
        name: string
        displayName: string
        regionCode: string
    }
    analytics4Property?: {
        account: string
        currencyCode: string
        displayName: string
        name: string
        parent: string
        propertyType: string
        serviceLevel: string
        timeZone: string
    }
    analytics4QueryFilters: Analytics4QueryFilter[]
    analytics4PreferredEventName?: string
    categories: any[]
    connections: LocationConnections
    countryCode: string
    created: string
    displayName: string
    doGoogleTVTargets: boolean
    email: Email
    enableCPGView: boolean
    enableEulerityGoalAttribution: boolean
    facebookAccessGranted: boolean
    facebookCanPost: boolean
    facebookGeoTargetType: string
    facebookInterestTargets: any[]
    facebookSpecialAdCategory?: string
    facebookLandingPage: string
    facebookLeadFormId?: string
    facebookLeadFormName?: string
    facebookPageId?: string
    facebookPageName: string
    facebookTargetingOptimization: boolean
    franchiseName?: string
    franchiseUser: `${string}_${UserId}`
    ga4Events: any[]
    ga4EventsReporting: any[]
    geoTargets?: GeoTarget[]
    genderTargets: GenderTarget[]
    googleGeoTargetType: string
    googleSpecialAdCategory?: string
    googleUserInterests: any[]
    hasFacebookAuthToken: boolean
    hasUAorGA4: boolean
    id: LocationId
    ideasKeywords: string[]
    incomeTargets: IncomeTarget[]
    instagramId?: string
    instagramUsername?: string //! DO NOT EXPOSE THIS FIELD TO USER
    businessInstagramUsername?: string, //The username reflecting IG Posting
    isActive: boolean
    keywords: Keyword[]
    label: string
    labels: string[]
    latitude: number
    linkedInAccessGranted?: boolean
    linkedInGeoTargetType: string
    linkedInOrgId?: string
    linkedInOrgName?: string
    linkedInRefreshTokenExpiryMs: number
    linkedInTargets: LinkedinTarget[]
    linkedInUserId: string
    linkedInUsername: string
    logo?: BlobKey
    longitude: number
    hasGmbRefreshToken?: boolean
    hasSquareRefreshToken?: boolean
    hasAnalyticsRefreshToken?: boolean
    hasFacebookOAuthToken?: boolean
    maxCapacity: number
    name: string
    nameForDisplay: string
    networks: string[]
    nickname: string
    objective: Objective
    parentTargets: ParentTarget[]
    phone: string
    phoneE164?: string
    phoneFormatted: string
    phoneForwarding: string
    phoneForwardingLeaseStatus: typeof CALL_FORWARDING_LEASE_STATUS_TYPE[keyof typeof CALL_FORWARDING_LEASE_STATUS_TYPE]
    phoneFormatOverride?: string
    placesId: string
    shortCode: string
    shouldHide: boolean
    squareLocations: any[]
    squarePermissions: any[]
    status: string
    streetAddress: string
    subscriptions: Subscription[]
    suggestedBudget: number
    suggestedCategories: any[]
    suppressBusinessNameKeyword: boolean
    suppressCampaignSyncing: boolean
    suppressOptimization: boolean
    tosLink?: string
    trackingCodes: any
    twitterHandle: string
    twitterPageId: string
    type: string
    uaGoals: any[]
    uaGoalsReporting: any[]
    updated: string
    user: {
        websafe: Websafe;
        raw: {
            kind: "User",
            name: string;
        }
    }
    value: string
    variableValues: any
    visibleToFranchisees: boolean
    websafe: Websafe
    website: string
    weight?: {
        map: WeightMap
    }
    weightSource?: any
    gmbRefreshToken?: string
    gmbAccountName?: string
    gmbAccountDisplayName?: string
    gmbLocationName?: string
    gmbLocationDisplayName?: string
    focuses: FocusOption['value'][]
    hideGA4DataForLocation?: boolean
    hideBrandAwarnessDataForLocation?: boolean

    // we add these manually later
    audiencesLoaded?: boolean
    audiences?: Audience[]
}

type ProfileFields = {
    primaryLocation: {
        raw: any
        websafe: Websafe
    }
}

export type PrimaryLocation = LocationFields
export type Profile = LocationFields & ProfileFields
export type EulerityLocation = Profile | PrimaryLocation

export const isPrimaryLocation = (location: EulerityLocation): location is PrimaryLocation => {

    if (!location) {
        return false
    }

    return !("primaryLocation" in location)
}

export const isSecondaryLocation = (location: EulerityLocation): location is Profile => {

    if (!location) {
        return false
    }

    return "primaryLocation" in location
}