import React, { useEffect, useMemo, useState } from 'react'
import LoadingDots from '../../../components/loading/LoadingDots'
import { CREATIVE_KIND } from '../../../helpers/creative.util'
import useAdPreviewSlides from '../../../hooks/useAdPreviewSlides'
import useFade from '../../animations/useFade';
import usePreviewText from '../hooks/usePreviewText'
import DisplayMedia from './DisplayMedia'
import NetworkIcon from './NetworkIcon'
import { CreativeDisplayContainer, NetworkPreviewItem } from './styles'

const DisplayMediaV2 = ({ creative, location }) => {
    const isVideo = creative?.kind === CREATIVE_KIND.VIDEO_CREATIVE || creative?.kind === CREATIVE_KIND.VIDEO_POST
    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const [currIntervalId, setCurrIntervalId] = useState(null);
    const [isIntervalRunning, setIsIntervalRunning] = useState(true);
    const ref = useFade([selectedNetwork]);
    const { previewText } = usePreviewText(creative)
    const { previewData } = useAdPreviewSlides(creative, creative?.targetNetworks, location);

    const previewDataCustom = useMemo(() => {
        const previews = [...previewData]

        if (isVideo) {
            previews.push({ type: 'video', Component: DisplayMedia})
        }

        return previews
    }, [previewData, creative])

    const previewToRender = useMemo(() => {
        return previewDataCustom.find(preview => preview.type.includes(selectedNetwork))
    }, [previewDataCustom, selectedNetwork])

    const networkButtons = useMemo(() => {
        const buttons = previewData?.map(preview => preview.type)
        if (isVideo) buttons.push('video')

        return buttons
    }, [isVideo, previewData])

    useEffect(() => {
        setSelectedNetwork(networkButtons[0])
    }, [previewDataCustom])

    // Initially cycle through the previews until the user clicks on a particular one
    useEffect(() => {
        let intervalId;
        if (isIntervalRunning) {
            intervalId = setInterval(() => {
                const currIdx = networkButtons.indexOf(selectedNetwork);
                const nextIdx = (currIdx + 1) % networkButtons?.length;
                setSelectedNetwork(networkButtons[nextIdx]);
                setCurrIntervalId(intervalId);
            }, 3000)
        }

        return () => {
            clearInterval(intervalId);
        }
    }, [networkButtons, selectedNetwork]);

    const handleClick = network => {
        clearInterval(currIntervalId);
        setSelectedNetwork(network);
        setIsIntervalRunning(false);
    }

    return (
        <CreativeDisplayContainer>
            {!previewText ? <LoadingDots />:
                <div className='preview-container'>
                    <div className='preview' ref={ref}>
                        {previewToRender && <previewToRender.Component creative={creative} location={location} previewText={previewText} />}
                    </div>
                    <div className='network-container'>
                        {
                            networkButtons?.map(network => {
                                return <NetworkPreviewItem onClick={() => handleClick(network)} key={network} isSelected={network === selectedNetwork}>
                                    <NetworkIcon network={network} />
                                </NetworkPreviewItem>
                            })
                        }
                    </div>
                </div>
            }
        </CreativeDisplayContainer>
    )
}

export default DisplayMediaV2;