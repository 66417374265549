import { useSelector } from 'react-redux';
import { PERMISSIONS, Restriction } from '../constants/restrictions'
import { toast } from 'react-toastify';
import { useCallback } from 'react';

/*
    This hook allows for clearer use of handling feature access.
    We can more easily determine the accessLevel that a user has and handle accordingly.
    Including any restriction logic that may be applied to users/franchisees.


    Example Use Case:
    const Component = (props) => {
      const { isRestricted } = useAccess()

      if (isRestricted(RESTRICTION.sample)) {
        handle restricted logic
      } else {
        handle unrestricted logic
      }
    }



    Other functions are also made available to avoid the following pattern where we always need to use useSelector per component this logic is needed in:

    const user = useSelector(state => state.user)
    const isUserMember = isMember(user)

    if (isUserMember) { memberOnlyFunction() }



    Instead, we can write code as
    const { isMember } = useAccess()

    if (isMember()) { memberOnlyFunction() }

*/

export const useAccess = () => {
    const user = useSelector(s => s.user)

    // returns true if the logged in user is an admin
    const isAdmin = useCallback(() => PERMISSIONS.ADMIN.includes(user.accessLevel), [user.accessLevel])

    // returns true if the logged in user is a member
    const isMember = useCallback(() => PERMISSIONS.MEMBER.includes(user.accessLevel), [user.accessLevel])

    // returns true if the logged in user can select a franchise
    const canSelectFranchise = useCallback(() => PERMISSIONS.FRANCHISE_SELECT.includes(user.accessLevel), [user.accessLevel])

    // returns true if the logged in user can manage a franchise
    const canManageFranchise = useCallback(() => PERMISSIONS.FRANCHISE_MANAGEMENT.includes(user.accessLevel), [user.accessLevel])

    // Returns true if the user has stated restriction
    // Possible restrictions are listed in src/constants/restrictions.js
    const isRestricted = useCallback((restriction: Restriction) => {
      if (!isMember()) return false
      return user.disabledClientPermissions?.includes(restriction)
    }, [isMember, user.disabledClientPermissions])

    const showRestrictedMessage = () => {
        toast.error('This feature has been disabled by your corporate admin')
    }

    return {
        isRestricted,
        isAdmin,
        isMember,
        canSelectFranchise,
        canManageFranchise,
        showRestrictedMessage,
    }
};
