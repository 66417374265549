import styled, {css} from 'styled-components';

// * TOGGLE BODY
const TOGGLE_BODY_MEDIUM = css`
    width: 65px;
    height: 34px;
`
const TOGGLE_BODY_SMALL = css`
    width: 45px;
    height: 24px;
`
export const StyledCreativeStatusToggle = styled.div`
    background-color: ${ ({ theme, isOn }) => isOn ? theme.color.greyAccent1 : theme.color.activeButtonColor};;
    border-radius: 60px;
    cursor: pointer;
    overflow: hidden; /* Prevents lock and unlock from being seen */
    position: relative;
    transition: all 0.25s linear;
    ${({ size }) => {
        switch(size) {
            case 'md': {
                return TOGGLE_BODY_MEDIUM
            }
            case 'sm': {
                return TOGGLE_BODY_SMALL
            }
            default: {
                return TOGGLE_BODY_MEDIUM
            }
        }
    }}
`

// * TOGGLE CIRCLE BUTTON
const TOGGLE_CIRCLE_MEDIUM = css`
    position: absolute;
    top: 7px;
    left: 7px;
    height: 20px;
    width: 20px;
`
const TOGGLE_CIRCLE_SMALL = css`
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
`

const OFFSET_SMALL = css`
    left: 26px;
`
const OFFSET_MEDIUM = css`
    left: 38px;
`
export const CreativeStatusToggleCircle = styled.div`
    background: ${props => props.theme.color.textColor3};
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 ${props => props.theme.color.textColor1},
    0 1px 1px 0 ${props => props.theme.color.textColor1} inset; /*Adds top and bottom shadow*/
    transition: all 0.3s cubic-bezier(0.43, 1.3, 0.86, 1);

    ${({ size }) => {
        switch(size) {
            case 'md': {
                return TOGGLE_CIRCLE_MEDIUM
            }
            case 'sm': {
                return TOGGLE_CIRCLE_SMALL
            }
            default: {
                return TOGGLE_CIRCLE_MEDIUM
            }
        }
    }}
    ${({ isOn }) => isOn && `
       left: 38px;
    `}

    ${({ isOn, size }) => {
        if (!isOn) return ''
        switch(size) {
            case 'md': {
                return OFFSET_MEDIUM
            }
            case 'sm': {
                return OFFSET_SMALL
            }
            default: {
                return OFFSET_MEDIUM
            }
        }
    }}
`