import { LOCATION_FILTERS,  CLEAR_FILTERS } from "../actions/types";
import { CATEGORY_TYPES } from "../features/advancedFilter/constants";

//Helpers
function setFiltersToDefault (filters) {
  let copy = {...filters}
  for(let key in copy) {
    copy[key] = []
  }
  return copy
}


const initialState = {
  locationFilters: {
    [CATEGORY_TYPES.LABEL]: [],
    [CATEGORY_TYPES.STATUS]: [],
    [CATEGORY_TYPES.USER]: [],
    [CATEGORY_TYPES.TYPE]: [],
    [CATEGORY_TYPES.OBJECTIVE]: [],
  }
};

const advancedFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_FILTERS: {
        return {...state, locationFilters: action.payload}
    }
    case CLEAR_FILTERS: {
      return {...state, locationFilters: setFiltersToDefault(state.locationFilters)}
    }
    default: {
      return { ...state };
    }
  }
};

export default advancedFiltersReducer;
