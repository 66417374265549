import styled, { css } from "styled-components";
import { hexToRgba } from "../../helpers/colors.util";

const sharedSearchStyles = css`
    background: white;
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, .6)};
`

export const StyledSearchOnlyCard = styled.div`
    ${sharedSearchStyles};
    position: relative;
    height: 148px;
    width: 100%;
    border-radius: 6px;
    padding: 8px 16px;

    .main-text {
        height: 118px;
        overflow: hidden;
    }
`

export const StyledSearchOnlyBpCard = styled.div`
    ${sharedSearchStyles};
    height: 104px;
    width: 104px;
    border-radius: 8px;
    padding: 8px;

    img {
        height: 16px;
        width: 16px;
        margin-left: auto;
    }
`

export const StyledSearchOnlyCloneCard = styled.div`
    ${sharedSearchStyles};
    height: 164px;
    width: 164px;
    border-radius: 8px;
    padding: 8px;
`

export const StyledSearchOnlyTableCard = styled.div`
    ${sharedSearchStyles};
    height: 100px;
    width: 100px;
    border-radius: 8px;
    padding: 8px;
`