import columnBuilder from "../../../columnBuilder";
import { getDayReport, reduceNumberArray } from "../shared";
import { reportLoader } from '../../../loaders';
import { calculateCTR } from "../../../../../../../helpers";

const ctrReportCategory = columnBuilder()
	.category('performance')
	.subCategory('Click Through Rate')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `CTR: ${meta.name}`
	})

type Ctr = {
	impressions: number | undefined
	clicks: number | undefined
}

const ctrAggregator = (one: Ctr | undefined, two: Ctr | undefined) => {
	if (one === undefined && two === undefined) return undefined
	if (one === undefined) return one
	if (two === undefined) return two

	let impressions;

	if (one.impressions === undefined) {
		impressions = two.impressions
	} else if (two.impressions === undefined) {
		impressions = one.impressions
	} else {
		impressions = one.impressions + two.impressions
	}

	let clicks;

	if (one.clicks === undefined) {
		clicks = two.clicks
	} else if (two.clicks === undefined) {
		clicks = one.clicks
	} else {
		clicks = one.clicks + two.clicks
	}

	return {
		impressions,
		clicks
	}
}

const ctrFormatter = (value: Ctr | undefined) => {
	if (value === undefined) return "N/A"
	const ctr = calculateCTR(value.impressions, value.clicks)
	return `${ctr.toFixed(2)}%`
}

const ctrColumns = [
	ctrReportCategory
		.id('ctr-total')
		.name('Total')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)

			if (!targetDate || !targetDate.impressions) return

			const clicks = reduceNumberArray(Object.values(targetDate.channelMetrics || {}).map(channel => channel.clicks))

			return {
				impressions: targetDate.impressions || undefined,
				clicks
			}
		})
		.aggregateRow(ctrAggregator)
		.toString(ctrFormatter),
	ctrReportCategory
		.id('ctr-by-display')
		.name('Display')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			const channelMetric = targetDate?.channelMetrics?.display

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				clicks: channelMetric.clicks
			}
		})
		.aggregateRow(ctrAggregator)
		.toString(ctrFormatter),
	ctrReportCategory
		.id('ctr-by-search')
		.name('Search')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			const channelMetric = targetDate?.channelMetrics?.search

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				clicks: channelMetric.clicks
			}
		})
		.aggregateRow(ctrAggregator)
		.toString(ctrFormatter),
	ctrReportCategory
		.id('ctr-by-social')
		.name('Social')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			const channelMetric = targetDate?.channelMetrics?.social

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				clicks: channelMetric.clicks
			}
		})
		.aggregateRow(ctrAggregator)
		.toString(ctrFormatter),
	ctrReportCategory
		.id('ctr-by-video')
		.name('Video')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			const channelMetric = targetDate?.channelMetrics?.video

			if (!channelMetric) {
				return
			}

			return {
				impressions: channelMetric.impressions,
				clicks: channelMetric.clicks
			}
		})
		.aggregateRow(ctrAggregator)
		.toString(ctrFormatter),
]

export default ctrColumns