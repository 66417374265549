import React, { useContext, useEffect } from 'react';
import { StyledModalNavV2 } from '../styles';
import { FiX } from 'react-icons/fi'
import BackButton from '../../../backButton/BackButton';
import { ModalContext } from '../context/ModalContextProvider';
import { ModalNavProps } from '../types';
import { useTheme } from 'styled-components';

//* Usage: Use this component inside the ModalWrapper
//* onBack and onClose should be passed to ModalWrapper,
//* and this component will handle the position of the back and close buttons

const ModalNav = ({
  backText = "Back"
} : ModalNavProps)  => {
  const theme = useTheme()

  const {
    onClose : handleClose,
    onBack : handleBack,
    setHasNav,
    modalNavRef
  } = useContext(ModalContext);

  useEffect(() => {
    setHasNav(true)
  }, [setHasNav])

  return (
    <StyledModalNavV2 ref={modalNavRef}>
      {handleBack && <BackButton onBack={handleBack} text={backText}/>}
      {
        handleClose &&
        <span className="close-btn" onClick={handleClose}>
          <FiX size={24} color={theme.color.accentColor2}/>
        </span>
      }
    </StyledModalNavV2>
  );
};

export default ModalNav;
