import {
  UPDATE_RESTRICTIONS,
  UPDATE_FRANCHISOR_SETTINGS, LOAD_FRANCHISE_SETTINGS,
  LOAD_RESTRICTIONS
} from "../actions/types";
import { LOCATION_ACCESS_LEVEL_FIELD, RESTRICTIONS } from "../constants/restrictions";

const initialState = {
  subscriptionErrors: {},
  franchisorSettings: {},
  corporateRestriction: [
    {
    selector: "Configuration",
    key: "configuration",
    restrictions: [
      {
        restrictionName: "disableStockImages",
        header: "Stock Images",
        description: "Allow users to access Eulerity’s stock image library for ad & post creation?",
        status: false
      },
      {
        restrictionName: "disableStockLogos",
        header: "Found Logos",
        description: "Allow users to access Eulerity’s intelligent logo finder for ad & post creation?",
        status: false
      },
      {
        restrictionName: "disableStockFonts",
        header: "Stock Fonts",
        description: "Allow users to access Eulerity’s stock font library for ad & post creation?",
        status: false
      },
      {
        restrictionName: "enableCreativeApproval",
        header: "System Manager Approvals",
        description: `Allow System Managers to approve all ads before distributing them to ad networks?
        Please note, Eulerity will still review ads before distribution with this feature turned off.`,
        status: false,
        toBeEnabled: true,

      },
    ],
  }
],

  restrictionsData: [
      {
        selector: "Creative Restrictions",
        key: "creative",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.CREATIVE_EDIT,
            header: "Edit Ad Restriction",
            description: "Allow users to build and edit ads from the app?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.AD_ASSET_RESTRICTION,
            header: "Upload Asset Restriction",
            description: "Allow users to upload image assets for ad creation?",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.CREATIVE_STATUS,
            header: "Play / Pause / Delete Ad Restriction",
            description: "Allow users to play / pause / delete ads from the app?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.CREATIVE_LANDING_PAGE,
            header: "Landing Page Restriction",
            description: "Allow users to make ads using any landing page they want? If restricted, users can only use existing landing pages or their default website.",
            status: false,
          }
        ],
      },
      {
        selector: "Post Restrictions",
        key: "post",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.POST_EDIT,
            header: "Build / Edit Posts Restriction",
            description: "Allow users to build and edit posts from the app?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.POST_ASSET_RESTRICTION,
            header: "Upload Asset Restriction",
            description: "Allow users to upload image assets for post creation?",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.POST_DELETE,
            header: "Delete Posts Restriction",
            description: "Allow users to delete posts from the app?",
            status: false
          },
        ],
      },
      {
        selector: "Location Restrictions",
        key: "location",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.KEYWORD_SETTING,
            header: "Keywords Restriction",
            description: "Allow users to add / delete keywords used for advertising in Google Search?",
            status: false,
            locationAccessLevelFieldKey: [ LOCATION_ACCESS_LEVEL_FIELD.KEYWORDS ]
          },
          {
            restrictionName: RESTRICTIONS.DEMO_SETTING,
            header: "Demographic Targeting Restriction",
            description: "Allow users to edit demographic targeting (age, gender, income and parental status) for their ads?",
            status: false,
            locationAccessLevelFieldKey:[
              LOCATION_ACCESS_LEVEL_FIELD.AGE_TARGETS,
              LOCATION_ACCESS_LEVEL_FIELD.GENDER_TARGETS,
              LOCATION_ACCESS_LEVEL_FIELD.INCOME_TARGETS,
              LOCATION_ACCESS_LEVEL_FIELD.PARENT_TARGETS
            ]
          },
          {
            restrictionName: RESTRICTIONS.GEOTARGET_SETTING,
            header: "GeoTargeting Restriction",
            description: "Allow users to edit geotargeting for their ads?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.GOOGLE_AUDIENCE_SETTING,
            header: "Google Audience Segments Restriction",
            description: "Allow users to add / modify Google Audience Segments?",
            status: false,
            locationAccessLevelFieldKey: [ LOCATION_ACCESS_LEVEL_FIELD.GOOGLE_USER_INTERESTS ]
          },{
            restrictionName: RESTRICTIONS.LINKEDIN_TARGET_SETTING,
            header: "LinkedIn Targeting Restriction",
            description: "Allow users to add/delete LinkedIn Targets for their campaigns?",
            status: false,
            locationAccessLevelFieldKey: [ LOCATION_ACCESS_LEVEL_FIELD.LINKEDIN_TARGETS ]
          },
          {
            restrictionName: RESTRICTIONS.LANDING_PAGE_SETTING,
            header: "Landing Page Restriction",
            description: "Allow users to edit the default landing page on their campaigns?",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.MACROS_SETTING,
            header: "Variable Restriction",
            description: "Allow users to manage variables for their campaigns?",
            status: false,
          }
        ],
      },
      {
        selector: "Subscription Restrictions",
        key: "subscription",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.VIEW_SUBSCRIPTION,
            header: "View Subscription and Fee Amount Restriction",
            description: "Allow users to see the monthly subscription and fee amounts set for them from the app?",
            descriptionBold: "This will only affect corporate paid subscriptions. User paid subscriptions will still be visible to users.", //We can change this implementation if the bolded text appears differently in other restriction
            status: false
          },
          {
            restrictionName: RESTRICTIONS.VIEW_SUGGESTED_BUDGET,
            header: "View Suggested Budget Restriction",
            description: "Allow users to see the suggested budget determined by our platform's intelligent analysis of competition for their businesses?",
            status: false
          },
        ],
      },
      {
        selector: "Reputation Management Restrictions",
        key: "reputation",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.RMI_EULERITY_AI_REVIEW,
            header: "Generate Responses for Reviews via Eulerity AI Restriction",
            description: "Allow users to generate a response to a review via Eulerity AI",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.RMI_EULERITY_AI_COMMENT,
            header: "Generate Responses for Comments via Eulerity AI Restriction",
            description: "Allow users to generate a response to a comment via Eulerity AI",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.RMI_LINKEDIN_ACCESS,
            header: "LinkedIn Restriction",
            description: "Allow users to view your LinkedIn page analytics in Reputation Management",
            status: false
          },
        ],
      },
      {
        selector: "Other Restrictions",
        key: "other",
        restrictions: [
          {
            restrictionName: RESTRICTIONS.FACEBOOK_SETTING,
            header: "Facebook Restriction",
            description: "Allow users to attach / modify Facebook pages used for advertising on Facebook?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.FACEBOOK_LEAD_FORMS,
            header: "Facebook Lead Form Restriction",
            description: "Allow users to update their Facebook lead forms?",
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.POS_SETTING,
            header: "Point of Sale Restriction",
            description: "Allow users to attach their POS to the app?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.GOOGLE_ANALYTICS,
            header: "Google Analytics Restriction",
            description: "Allow users to attach / modify Google Analytics 4 connections?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.GOOGLE_MY_BUSINESS,
            header: "Google Business Profile",
            description: "Allow users to attach / modify Google Business Profile Connections?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.TWITTER_SETTING,
            header: "X Restriction",
            description: "Allow users to attach / modify X connections?",
            status: false
          },
          {
            restrictionName: RESTRICTIONS.LINKEDIN_SETTING,
            header: 'LinkedIn Restriction',
            description: 'Allow users to attach / modify LinkedIn connections?',
            status: false,
          },
          {
            restrictionName: RESTRICTIONS.CHAT_GPT_SUGGESTIONS,
            header: "Eulerity AI Restriction",
            description: "Allow users to access Eulerity AI suggested text to include in promotional copy in ads and/or posts?",
            status: false
          },
        ],
      },
  ],
};

const corporateSettingsReducer = (state = initialState, action) => {
  switch (action.type) {

    case LOAD_FRANCHISE_SETTINGS: {
      return {...state, franchisorSettings: {...action.payload}}
    }

    case LOAD_RESTRICTIONS: {
      const restrictionType = action.restrictionType === 'corporate' ? "corporateRestriction" : "restrictionsData"
      state[restrictionType].forEach(obj => {
        obj.restrictions.forEach(restriction => {
          if(action.payload.includes(restriction.restrictionName)) {
            restriction.status = restriction?.toBeEnabled ? false : true
          } else {
            restriction.status = restriction?.toBeEnabled ? true : false
          }

        })
      })
      const newData =  [...state[restrictionType]]
      return {...state, [restrictionType]:  newData}
    }

    case UPDATE_RESTRICTIONS: {
      let corporateRestrictions = ['configuration']
      let settingsType = corporateRestrictions.includes(action.selectedField) ? 'corporateRestriction' : 'restrictionsData'
      state[settingsType].forEach(restriction => {
        restriction.restrictions.forEach(res => {
           if(res.restrictionName === action.payload){
            res.status = action.status
           }
        })
     })
      return state
    }

    case UPDATE_FRANCHISOR_SETTINGS: {
      if(action.field) {
        return {...state, franchisorSettings: {...state.franchisorSettings, [action.field]: action.payload } }
      } else {
        return {...state, franchisorSettings: {...state.franchisorSettings, ...action.payload}}
      }
    }
    default: {
      return state;
    }
  }
};

export default corporateSettingsReducer;
