import React from 'react'
import { XS } from '../../styles/typography'

type ToastMessageProps = {
  title?: string,
  message?: string
}

/**
 * Pass this component to toast.success/info/warning to include a title / message
 * @param {string} title - Bolded display at the top of the toast
 * @param {string} message - Displays under title
 * @returns {ReactNode}
 */
const ToastMessage = ({ title, message } : ToastMessageProps) => {
  return (
    <>
      {title && <XS bold>{title}</XS>}
      {message && <XS>{message}</XS>}
    </>
  )
}

export default ToastMessage