/*
    The naming convention here should be as follows:
    SERVLET_NAME: {
        ENDPOINT_NAME: String
    }
    where the endpoint is formatted as
    /api/servletname/endpoint
*/

export const API = {
    // useful for testing error state
    FAKE: {
        THROW: '/api/fake/throw'
    },
    FRANCHISE: { // Generally only accessible by admins or system managers
        ACTIVATE_CREATIVE: '/api/franchise/activateCreative',
        DEACTIVATE_CREATIVE: '/api/franchise/deactivateCreative',
        LIST_MEMBER_SUMMARIES: '/api/franchise/listMemberSummaries',
        UPDATE_FRANCHISE: '/api/franchise/updateFranchise',
        UPDATE: '/api/franchise/update', //TODO we shouldn't use this
        UPDATE_SETTINGS: '/api/franchise/updateSettings',
        GET_STRIPE_BALANCE: '/api/franchise/getStripeBalance',
        ATTACH_SOURCE: '/api/franchise/attachSource',
        GET_CREATIVE_REPOS: '/api/franchise/getCreativeRepos',
        INVITE_FRANCHISEE: '/api/franchise/inviteFranchisee',
        CORPORATE_BALANCE_TRANSACTIONS: '/api/franchise/listStripeBalanceTransactions',
        GET_FULL: '/api/franchise/getFull',
        GET_ALL: '/api/franchise/getAll',
        GET_REPORT: '/api/franchise/getReport',
        GET_REPORT_DETAILS: '/api/franchise/listReportDetails',
        REVIEW: '/api/franchise/review',
        GET_DATA_FILE: '/api/franchise/getDataFile',
        LIST_DOCUMENTS: '/api/franchise/listDocuments',
        ADD_DOCUMENT: '/api/franchise/addDocument',
        UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE: '/api/franchise/updateSubscriptionEmailTemplate',
        GET_FRANCHISE: '/api/franchise/getFranchise',
        CREATE_FRANCHISEE: '/api/franchise/createFranchisee',
        CREATE_SUBSCRIPTION: '/api/franchise/createSubscription',
        UPDATE_SUBSCRIPTION: '/api/franchise/updateSubscription',
        CREATE_AD: '/api/franchise/createAd',
        CREATE_POST: '/api/franchise/createPost',
        ADD_IMAGE: '/api/franchise/addImage',
        ADD_LOGO: '/api/franchise/addLogo',
        GET_IMAGES: '/api/franchise/getImages',
        GET_LOGOS: '/api/franchise/getLogos',
        GET_COLORS: '/api/franchise/getColors',
        REMOVE_COLOR: '/api/franchise/removeColor',
        ADD_COLOR: '/api/franchise/addColor',
        ADD_FONT: '/api/franchise/addFont',
        REMOVE_FONT: '/api/franchise/removeFont',
        UPDATE_IMAGE: '/api/franchise/updateImage',
        UPDATE_LOGO: '/api/franchise/updateLogo',
        GET_SUGGESTIONS: `/api/franchise/getSuggestions`,
        LIST_FRANCHISE_KEYWORD_COUNTS: `/api/franchise/listFranchiseKeywordCounts`,
        LIST_USERS: '/api/franchise/listUsers',
        LIST_LOCATIONS: '/api/franchise/listLocations',
        GENERATE_CREDITS: '/api/franchise/generateCredits',
        DISTRIBUTE_CREDITS: '/api/franchise/distributePartialCredit',
        DELETE_IMAGE: '/api/franchise/removeImage',
        DELETE_LOGO: '/api/franchise/removeLogo',
        LIST_NETWORK_LOGINS: '/api/franchise/listNetworkLogins',
    },

    ACTIVITYLOG: {
        GET_ACTIVITY_HISTORY: `/api/activitylog/listFranchiseActivityHistory`,
    },


    INSIGHT: { // Insights reporting data
        GET_TOTAL_ACTIVE_ADS: '/api/insight/getTotalActiveAds',
        GET_MAGICS: '/api/insight/getMagics',
        GET_LAST_LOGIN: '/api/insight/getLastLogin',
        GET_CTR: '/api/insight/getCtr',
        GET_SPEND: '/api/insight/getSpend',
        GET_LOCATION_PERFORMANCE: '/api/insight/getLocationPerformance',
        LIST_TOP_CREATIVES: '/api/insight/listTopCreatives',
        GET_DAILY_BUDGET: '/api/insight/getDailyBudget',
        GET_SOCIAL_POSTING: '/api/insight/socialPosting',
        GET_REPUTATION_METRICS: '/api/insight/getReputationMetrics',
        GET_GMB_REVIEWS: '/api/insight/getGMBReviews',
        GET_FB_REVIEWS: '/api/insight/getFBReviews',
        GET_SOCIAL_POSTING_PERFORMANCE: '/api/insight/socialPostingPerformance',
        GET_SOCIAL_POSTING_PUBLISHING: '/api/insight/socialPostingPublishing',
        REPLY_TO_GMB_REVIEW: '/api/insight/replyToGMBReview',
        REPLY_TO_FB_REVIEW: '/api/insight/replyToFBReview',
        GET_FB_BRAND_AWARENESS_METRICS: '/api/insight/getFbBrandAwarenessMetrics',
        GET_GOOGLE_BRAND_AWARENESS: '/api/insight/getGoogleBrandAwarenessMetrics',
        LIST_USER_SOCIAL_POSTING_PERFORMANCE: '/api/insight/listUserSocialPostingPerformance',
        GET_IG_COMMENT_REPLIES: '/api/insight/getIGCommentReplies',
        GET_FB_COMMENT_REPLIES: '/api/insight/getFBCommentReplies',
        GET_IG_POSTS_AND_COMMENTS: '/api/insight/getIGPostsAndComments',
        GET_FB_POSTS_AND_COMMENTS: '/api/insight/getFBPostsAndComments',
        REPLY_TO_IG_MEDIA: '/api/insight/replyToIGMedia',
        REPLY_TO_FB: '/api/insight/replyToFB',
    },

    TOKEN: {
        REVOKE_USER_TOKENS: '/api/token/revokeAllUser'
    },

    LOCATION: {
        CREATE: '/api/location/create',
        CLONE: '/api/location/clone',
        GENERATE_FACEBOOK_OAUTH_REQUEST: '/api/location/generateFacebookOAuthRequest',
        CLEAR_FACEBOOK_CONNECTION: '/api/location/clearFacebookConnection',
        GENERATE_TWITTER_OAUTH_REQUEST: '/api/location/generateTwitterOAuthRequest',
        GENERATE_ANALYTICS_4_OAUTH_REQUEST: '/api/location/generateAnalytics4OAuthRequest',
        SET_ANALYTICS: '/api/location/setAnalytics',
        CLEAR_ANALYTICS4_CONNECTION: '/api/location/clearAnalytics4Connection',
        REVOKE_TWITTER_ACCESS: '/api/location/revokeTwitterAccess',
        GENERATE_LINKEDIN_OAUTH_REQUEST: '/api/location/generateLinkedInOAuthRequest',
        UPDATE: '/api/location/update',
        GET: '/api/location/get',
        DELETE: '/api/location/delete',
        GET_USER_INTEREST: '/api/location/getUserInterest',
        GENERATE_GOOGLE_BUSINESS_REQUEST: '/api/location/generateGmbOAuthRequest',
        CLEAR_GOOGLE_BUSINESS_CONNECTION: '/api/location/clearGmbConnection',
        VALIDATE_URL: '/api/location/validateUrl',
        PREP: '/api/location/prep',
        LIST_CALL_RECORDINGS: '/api/location/listCallRecordings',
        ENABLE_CALL_FORWARDING: '/api/location/enableCallForwarding',
        GET_REPLY_SUGGESTIONS: '/api/location/getReplySuggestions',
    },

    JOBS: {
        LIST_ACTIVE_JOBS: '/api/job/listActiveJobs',
        CREATE: '/api/job/create',
        LIST_TASKS: '/api/job/listTasks',
        DELETE: '/api/job/delete'
    },

    SUBSCRIPTION: {
        GET: '/api/subscription/get',
        CREATE: '/api/subscription/create',
        UPDATE: '/api/subscription/update',
        PREP: '/api/subscription/prep',
        GET_STRIPE_SOURCES: '/api/subscription/getStripeSources',
        GET_STRIPE_INVOICES: '/api/subscription/getStripeInvoices',
        CHECK_CAN_CREATE: '/api/subscription/checkCanCreate',
        CREATE_CHECKOUT_SESSION: "/api/subscription/createCheckoutSession",
    },

    REPORT: {
        GET: '/api/report/get',
        GET_CREATIVES: '/api/report/getCreatives',
        GOOGLE_ANALYTICS_4: '/api/report/getAnalytics4AdHoc',
        GET_TOP_TEXTS_V2: '/api/report/getTopTextsV2',
        GET_KEYWORD_REPORT: '/api/report/getKeywordsReport',
        GET_FB_LEADS_FORMS: '/api/report/getFacebookLeadsForms',
        GET_KEYWORDS_SEARCH_VOLUME: '/api/report/getKeywordSearchVolumeMetrics',
        GET_SEARCH_TERM_REPORT: '/api/report/getSearchTermReport',
        LIST_DAILY_SUMMARIES: '/api/report/listDailySummaries',
        LIST_FRANCHISE_CONVERSIONS_REPORTS: '/api/report/listFranchiseConversionReports',
        LIST_FRANCHISE_CONVERSIONS_REPORT_LINES: '/api/report/listFranchiseConversionReportLines',
    },

    USER: {
        GET: '/api/user/get',
        GET_FRANCHISE_LIST: '/api/user/getManagedFranchises',
        GET_FULL: '/api/user/getFull',
        GET_SUMMARY: '/api/user/getSummary',
        ATTACH_SOURCE: '/api/user/attachSource',
        UPDATE: '/api/user/update',
        CLIENT_API_KEYS: "/api/user/getClientApiKeys",
        LIST_NETWORK_LOGIN_CONNECTIONS: '/api/user/listNetworkLoginConnections',
        MARK_FOR_DELETION: '/api/user/markForDeletion',
        EMAIL_AVAILABLE: '/api/user/emailAvailable',
        REQUEST_ZOR_ACCESS: '/api/user/requestZorAccess'
    },

    CREATIVE: {
        BUILD_PREVIEW_TEXT: '/api/creative/buildPreviewText',
        LIST: '/api/creative/list',
        CREATE: "/api/creative/create",
        CREATE_SMS: '/api/creative/createSMS',
        CREATE_EMAIL: '/api/creative/createEmail',
        CREATE_VIDEO: '/api/creative/createVideo',
        CREATE_POST: '/api/creative/createPost',
        CREATE_VIDEO_POST: '/api/creative/createVideoPost',
        ACCEPT_MAGIC: '/api/creative/acceptMagic',
        DELETE: '/api/creative/delete',
        PAUSE: '/api/creative/pause',
        RESUME: '/api/creative/resume',
        GENERATE_SAMPLE: '/api/creative/generateSample',
        GET_CLONES: '/api/creative/getClones',
        PREP: '/api/creative/prep',
        LIST_TEMPLATE: '/api/creative/listTemplateCreatives',
        CLONE_CREATIVE: '/api/creative/clone',
        GET_YOUTUBE_SUGGESTIONS: '/api/creative/getYoutubeHeadlineDescriptionSuggestions',
        GET: '/api/creative/get',
       
    },

    PAUSE: {
        GET_ALL: '/api/pause/getAll'
    },

    PROMO: {
        VALIDATE: '/api/promo/validate'
    },

    CATEGORY: {
        SUGGEST: '/api/category/suggest'
    },

    AUDIENCE: {
        GET_UPLOAD: '/api/audience/getUpload',
        LIST: '/api/audience/list',
        CREATE: "/api/audience/create",
        UPLOAD_MEMBERS: "/api/audience/uploadMembers",
        UPDATE: '/api/audience/update',
        CLEAR: '/api/audience/clear',
        LIST_INVALID_LINES_FOR_UPLOAD: '/api/audience/listInvalidLinesForUpload',
        LIST_CATEGORIES: '/api/audience/listAudienceCategories'
    },

    LOGO: {
        GET: '/api/logo/get',
    },

    SRV: {
        GET_UPLOAD_URL: '/srv/getUploadUrl',
        URL_TO_BLOBSTORE: '/srv/urlToBlobstore',
    },

    VERSION: {
        GET_VERSION: '/version'
    },
    TEMPLATE: {
        GET_ALL: '/api/template/getAll'
    },
    FONT: {
        GET_ALL: '/api/font/getAll'
    },

    BLUEPRINTS: {
        GET: '/api/blueprints/get',
        GET_ALL: '/api/blueprints/getAll',
        CREATE: '/api/blueprints/create',
        DELETE: '/api/blueprints/delete',
        UPDATE: '/api/blueprints/update',
    },
    LINKEDIN: {
        GET_ALL_FACETS: '/api/linkedin/getAllFacets',
        GET_ALL_TARGETS: '/api/linkedin/getAllTargets',
        QUERY_TARGET: '/api/linkedin/queryTargets'
    },
    VARIABLES: {
        CREATE_VARIABLE: '/api/variable/create',
        UPDATE_VARIABLE: '/api/variable/update',
    },
    FACEBOOK: {
        QUERY_INTEREST: '/api/facebook/queryInterests',
        GET_LEAD_FORMS: '/api/facebook/getPageLeadForms'
    },
    NOTIFICATION: {
        GET_ALL: "/api/notification/getAll",
        MARK_READ: "/api/notification/markRead",
        MARK_DELETED: "/api/notification/markDeleted"
    },
    FEATURE_PREVIEW: {
        GET_USER: '/api/featurepreview/getUser'
    }
} as const