import { TOKEN_DESCRIPTION, TOKEN_SCOPES, TOKEN_APIKEY, CLEAR_TOKEN_TAB } from "../actions/types";

const initialState = {
    description: '',
    scopes: '/api/audience/, ALL\n/api/location/list, GET',
    apiKey: ''
}

const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOKEN_DESCRIPTION: {
            return { ...state, description: action.payload }
        }
        case TOKEN_SCOPES: {
            return { ...state, scopes: action.payload }
        }
        case TOKEN_APIKEY: {
            return { ...state, apiKey: action.payload }
        }
        case CLEAR_TOKEN_TAB: {
            return initialState;
        }
        default: {
            return { ...state }
        }
    }
}


export default tokenReducer 