import { INTERVAL, Subscription } from "../../types/subscription"

const SubscriptionUtil = {
    isCorporateManagedSub: (subscription: Subscription) : boolean => {
        return Boolean(subscription?.managingUserKey)
    },
    isValidIntervalCount: (interval: number) : boolean => {
        const supportedValues = Object.values(INTERVAL) as number[]
        return supportedValues.includes(interval)
    }
}

export default SubscriptionUtil