import React from 'react';

import { InputIcon, InputWrap } from './style';

/*
    Behaves exactly the same as any regular input, with the added benefit
    of being able to pass an "icon" prop. This is then displayed to the left
    of the text. Especially useful for inputs that represent a search
*/

const InputWithIcon = (props) => {
    const { fullWidth, rightIcon, onIconClick, ...fullProps } = props
    return (
        <InputWrap {...props}>
            {
                !rightIcon &&
                <InputIcon onClick={onIconClick}>{props.icon}</InputIcon>
            }
            <input {...fullProps} />
            {
                rightIcon &&
                <InputIcon onClick={onIconClick}>{props.icon}</InputIcon>
            }
        </InputWrap>
    );
};

export default InputWithIcon;