// * Advanced Filter Hook

import { useMemo, useState } from "react";

const useAdvancedFilter = ({ selectedFilters, setSelectedFilters, categories}) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.categoryName)
  const [options, setOptions] = useState(categories[0]?.options);
  const [searchQuery, setSearchQuery] = useState('')
  const categoryName = selectedCategory?.toLowerCase();

  const filteredOptions = useMemo(() => {
    return options.sort().filter(option => option?.toLowerCase().includes(searchQuery?.toLowerCase()))
  }, [options, searchQuery])

  // * Check if all filteredOptions are selected
  const allOptionsSelected = filteredOptions?.every(opt => selectedFilters[categoryName]?.includes(opt))
  const someOptionsSelected = selectedFilters[categoryName]?.some(opt => filteredOptions?.includes(opt))


  // * Handle Select Category
  const handleSelectCategory = (category) => {
    setSelectedCategory(category?.categoryName)
    setOptions(category?.options)
  }

  // * Single selection/deselect
  const handleSelectOption = (optionValue) => {
    const categoryValues = selectedFilters[categoryName]

    // * If option is already selected remove it
    if (selectedFilters[categoryName].includes(optionValue)) {
      setSelectedFilters({
        ...selectedFilters,
        [categoryName]: categoryValues.filter(opt => opt !== optionValue)
      })
      return
    }
    setSelectedFilters({
      ...selectedFilters,
      [categoryName]: [...categoryValues, optionValue]
    })
  }

  // * Handle Select All Options
  const handleSelectAll = () => {
    const remainingOptions = filteredOptions.filter(val => !selectedFilters[categoryName].includes(val))

    setSelectedFilters({
      ...selectedFilters,
      [categoryName]: [...selectedFilters[categoryName], ...remainingOptions]
    })
  }

  // * Handle Deselect All Options
  const handleDeSelectAll = () => {
    const updatedSelectedOptions = selectedFilters[categoryName].filter(val => !filteredOptions.includes(val))

    setSelectedFilters({
      ...selectedFilters,
      [categoryName]: [...updatedSelectedOptions]
    })
  }

  // * Returns count for selected items (for given category)
  const countSelectedOptions = () => {
    if(!selectedFilters[categoryName]?.length) return
    return `${selectedFilters[categoryName]?.length} Selected`
  }

  // * Checks to see if option is checked
  const isChecked = (optionValue) => {
    return selectedFilters[categoryName]?.includes(optionValue) || false
  }

  // * Handles filtering option list when searching with input
  const handleUpdateQuery = (query) => {
    setSearchQuery(query)
  }

  return {
    selectedCategory,
    filteredOptions,
    allOptionsSelected,
    someOptionsSelected,
    handleSelectCategory,
    handleSelectOption,
    handleSelectAll,
    handleDeSelectAll,
    countSelectedOptions,
    isChecked,
    handleUpdateQuery
  }
}

export default useAdvancedFilter