import React from 'react'

const GoogleAnalytics = ({ size = 15 }: { size?: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3.375" cy="13.124" r="1.75" fill="#E37400" />
            <rect x="6" y="6.12402" width="3.5" height="8.75" rx="1.75" fill="#E37400" />
            <rect x="10.375" y="0.874023" width="3.5" height="14" rx="1.75" fill="#F9AB00" />
        </svg>
    )
}

export default GoogleAnalytics