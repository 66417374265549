import styled from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 40vh;
`;

export const LoadingSpinner = styled.img`
    height: 5rem;
    width: 5rem;
`
export const OverlayContent = styled.div`
    height: fit-content;
    display: flex;
    align-items: center;
`
