import React from 'react'

import { StyledFormContent, StyledStep } from './styles';

/**
 * This shows the component for the current step
 * and hide the other steps
 */
const FormContent = ({ steps, currentStep }) => {
    return (
        <StyledFormContent>
            {
                steps.map((step, idx) => {
                    const { component, id } = step;
                    return (
                        <StyledStep show={idx === currentStep} key={id}>
                            {component}
                        </StyledStep>
                    )
                })
            }
        </StyledFormContent>
    )
}

export default FormContent
