import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    CampaignOrBlueprintOptionWrap,
    CampaignOrBlueprintLocationStatus,
} from '../../../../components/LocationnAndBlueprintSelector/style'
import { LOCATION_STATUS, getLocationStatus } from '../../../../features/locations/util'
import { isLocationMatch } from '../../../../helpers'
import useDynamicSetting from '../../../../hooks/useDynamicSetting'
import useMember from '../../../../hooks/useMember'
import { CustomSelect } from '../../../../styles/inputs'
import { XS, Body, Small } from '../../../../styles/typography'
import { EulerityLocation, isPrimaryLocation } from '../../../../types/location'

const LocationOption = (location: EulerityLocation) => {
    const { member } = useMember(location?.user?.raw?.name)
    const { allowInactivePosting } = useDynamicSetting()
    const franchise = useSelector((s) => s.franchise)
    const isDefault = useMemo(() => {
        if (franchise.loading || !franchise.defaultLocation) return false
        return isLocationMatch(location, franchise.defaultLocation)
    }, [location, franchise])

    const optionType = useMemo(
        () =>
            `${isPrimaryLocation(location) ? 'Primary' : 'Secondary'} Campaign`,
        [location]
    )

    const name = useMemo(() => {
        return isDefault
            ? 'Default Campaign Setup'
            : (location.nickname as string)
    }, [location, isDefault])

    const locationStatus = useMemo(() => {
        return getLocationStatus(location)
    }, [location])

    return (
        <CampaignOrBlueprintOptionWrap>
            <div>
                <XS className='useAccent'>{optionType}</XS>
                <Body semibold ellipsis colorInherit>
                    {name}
                </Body>
            </div>
            <div>
                {!isDefault && (
                    <>
                        <div>{member?.name || 'N/A'}</div>
                        <div>{member?.email || 'N/A'}</div>
                    </>
                )}
            </div>
            {!allowInactivePosting && (
                <CampaignOrBlueprintLocationStatus
                    // @ts-ignore
                    isActive={locationStatus === LOCATION_STATUS.ACTIVE}
                >
                    {locationStatus}
                </CampaignOrBlueprintLocationStatus>
            )}
        </CampaignOrBlueprintOptionWrap>
    )
}

const formatOptionLabel = (props: any) => {
    return <LocationOption {...props} />
}

// these are the fields that need to be defined for this to work with the CustomSelect
export type SelectableLocation = EulerityLocation & {
    category: 'Secondary Campaigns' | 'Primary Campaigns'
    displayName: string
    email: string
    isActive: boolean
    label: string
    status: string
    type: 'location'
    value: string
}

export type SelectLocationState = {
    loading: boolean
    locationSelectorController: SelectLocationsProps
}

type SelectLocationsProps = {
    locationOptions: SelectableLocation[]
    selectedLocations: SelectableLocation[]
    setSelectedLocations: (items: SelectableLocation[]) => void
    handleSelectAllLocations: () => void
    handleSelectAllActiveLocations: () => void
    handleClearLocations: () => void
    isLocationSelected: (location: SelectableLocation) => boolean
}

export const SelectLocations = <T extends SelectLocationState>({
    state,
}: {
    state: T
}) => {
    const allowInactivePosting = useSelector(
        (s) => s.franchise.allowInactivePosting
    )

    const { locationSelectorController } = state

    if (state.loading) return <></>

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '0.5rem',
                zIndex: 2, // this has to do with the chevrons
                position: 'relative',
            }}
        >
            <CustomSelect
                maxHeight="15rem"
                options={locationSelectorController.locationOptions}
                onChange={locationSelectorController.setSelectedLocations}
                value={locationSelectorController.selectedLocations}
                classNamePrefix={'Select'}
                formatOptionLabel={formatOptionLabel}
                expandHeight
                isMulti
                isClearable
                isSearchable
            />
            <div
                style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem', position: 'absolute', top: 'calc(100% + 12px)'}}
            >
                <Small
                    color="accentColor3"
                    underline
                    onClick={locationSelectorController.handleSelectAllLocations}
                >
                    Select All
                </Small>
                <Small
                    color="accentColor3"
                    underline
                    onClick={locationSelectorController.handleSelectAllActiveLocations}
                >
                    {allowInactivePosting
                        ? 'Select All Active Advertising'
                        : 'Select All Active'}
                </Small>
                <Small
                    color="accentColor3"
                    underline
                    onClick={locationSelectorController.handleClearLocations}
                >
                    Clear
                </Small>
            </div>
        </div>
    )
}
