import { useCallback, useMemo, useState } from "react"
import { NoInfer } from "../types/custom"

// This makes it really easy to make a selectable list
// const _example = () => {
//   const members = useMemberList()

//   //* define the function to get the items id
//   const getMemberId = (member: Member) => member.id
//   //* get the list of items and a method to toggle an items id
//   const { selectedItems, toggleItem } = useSelectableList(members, getMemberId)
// }

export function useSelectableList<T, J>(items: T[], extractor: (target: T) => J, initialSelection: NoInfer<T>[] = []) {
    const [selectedIds, setSelectedIds] = useState<J[]>(initialSelection.map(extractor))

    const isSelected = useCallback((item: T): boolean => {
        return selectedIds.includes(extractor(item))
    }, [selectedIds, extractor])

    const selectedItems: T[] = useMemo(() => {
        return items.filter(item => isSelected(item))
    }, [isSelected, items])

    const deselectItem = useCallback((item: T) => {
        setSelectedIds(selectedIds.filter(id => id !== extractor(item)))
    }, [selectedIds, extractor])

    const selectItem = useCallback((item: T) => {
        setSelectedIds([...selectedIds, extractor(item)])
    }, [selectedIds, extractor])

    const selectAllItems = useCallback(() => {
        setSelectedIds(items.map(extractor))
    }, [items, extractor])

    const deselectAllItems = useCallback(() => {
        setSelectedIds([])
    }, [])

    const toggleItem = useCallback((item: T) => {
        if (isSelected(item)) {
            deselectItem(item)
        } else {
            selectItem(item)
        }
    }, [isSelected, deselectItem, selectItem])

    const setSelectedItems = useCallback((items: T[]) => {
        setSelectedIds(items.map(extractor))
    }, [extractor])

    const selectSingleItem = useCallback((item: T | undefined) => {

        if (!item) {
            setSelectedIds([])
            return
        }

        setSelectedItems([item])
    }, [setSelectedItems])

    const firstSelectedItem: T | undefined = selectedItems[0]

    return {
        items,
        selectedIds,
        selectedItems,
        firstSelectedItem,
        isSelected,
        selectSingleItem,
        selectAllItems,
        setSelectedItems,
        toggleItem,
        selectItem,
        deselectItem,
        deselectAllItems,
    }
}