import React from "react"
import { Redirect } from "react-router-dom"
import { PATH } from "../../../../constants/urlPath"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { useAccess } from "../../../../hooks/useAccess"

const REDIRECT_PATH = {
  NONE: '',
  CAMPAIGN_CREATE: `${PATH.CAMPAIGNS}/create`,
}

const CampaignCreationRedirect = () => {
  const { canManageFranchise } = useAccess()

  const franchiseName = useSelector(state => state.franchise.name)
  const franchiseLoading = useSelector(state => state.franchise.loading)
  const franchiseLocations = useSelector(state => state.franchise.locations)

  const redirectPath = useMemo(() => {
    if (canManageFranchise() || !franchiseName || franchiseLoading) return REDIRECT_PATH.NONE

    // No locations have been created
    if (franchiseLocations?.length === 0) {
      return REDIRECT_PATH.CAMPAIGN_CREATE
    }

    return REDIRECT_PATH.NONE
  }, [canManageFranchise, franchiseName, franchiseLoading, franchiseLocations?.length])

  if (!redirectPath) return <></>
  return <Redirect to={redirectPath} />
}

export { CampaignCreationRedirect }