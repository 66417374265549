import React from 'react';

const DisplayMedia = ({ creative, ...props }) => {
     if(!creative) return null
    const { id, youTubeId, preview, previewUrl, media} = creative;
    const isYoutubeVideo = youTubeId;
    const isUploadedVideo = media && (media !== preview)

    // Videos use iframe or native video depending on if its a youtube or uploaded vided
    if (isYoutubeVideo)  {
      return <iframe className='vid-display' src={`https://www.youtube.com/embed/${youTubeId}`} frameBorder={0} allowFullScreen height={"170px"} title={`creative: ${id}`} {...props} />
    }
    if (isUploadedVideo) {
      return <video className='vid-display' height='220' src={`/srv/${media}`} controls {...props} />
    }

    // Everything else display using the preview field (gifs, ad preview, etc.)
    if (preview) {
      return <img src={`/srv/${preview}`} alt={`creative: ${id}`} {...props} />
    }

    return <img src={previewUrl} alt={`creative: ${id}`} {...props} />
}

export default DisplayMedia
