import { useSelector } from "react-redux";

// * This hook is to retrieve data in the version servlet for image resolutions and char limits.
const useVersionData = () => {
    const suggestedImageResolution = useSelector(s => s.ui.suggestedImageResolution);
    const suggestedLogoResolution = useSelector(s => s.ui.suggestedLogoResolution);
    const maxImageResolution = useSelector(s => s.ui.maxImageResolution);
    const maxLogoResolution = useSelector(s => s.ui.maxLogoResolution);
    const adsExtendedTextMaxLength = useSelector(s => s.ui.adsExtendedTextMaxLength);
    const postsExtendedTextMaxLength = useSelector(s => s.ui.postsExtendedTextMaxLength);
    const minAdDuration = useSelector(s => s.ui.minAdDuration);
    const banners = useSelector(s => s.ui.banners);
    const ctas = useSelector(s => s.ui.ctas)
    const defaultCta = useSelector(s => s.ui.defaultCta)
    const defaultPhoneFormats = useSelector(s => s.ui.defaultPhoneFormats)
    const dropboxClientAppKey = useSelector(s => s.ui.dropboxClientAppKey)

    // * Returns char limit for extended texts
    const getExtendedTextLimit = isPost => isPost ? postsExtendedTextMaxLength : adsExtendedTextMaxLength

    // * Image and logo resolution data in array form - used for warnings typically
    const maxImgRes = maxImageResolution?.split("x")?.map((str) => parseInt(str)); // 3240x3240 [width, height]
    const suggImgRes = suggestedImageResolution?.split("x")?.map((str) => parseInt(str)); // 1080x1080 [width, height]
    const suggLogoRes = suggestedLogoResolution?.split("x")?.map((str) => parseInt(str)); // 100x100 [width, height]
    const maxLogoRes = maxLogoResolution?.split("x")?.map((str) => parseInt(str)); // 1080x1080 [width, height]

    // * Object of resolution data as arrays - used for size validaiton
    const imageResolutionObject = {
        maxImgRes,
        suggImgRes,
        maxLogoRes,
        suggLogoRes
    }

    // * Resolution data in string format (obj) - used to display text
    const imageResolutionStrings = {
        suggestedImageResolution,
        suggestedLogoResolution,
        maxImageResolution,
        maxLogoResolution
    }

    return {
        getExtendedTextLimit,
        imageResolutionObject,
        imageResolutionStrings,
        minAdDuration,
        ctas,
        defaultCta,
        defaultPhoneFormats,
        dropboxClientAppKey,
        banners
    }
}

export default useVersionData