import { useContext, useEffect, useRef } from 'react';

import { createID } from '../../../helpers';
import DeprecatedSuperTableContextDoNotUse from '../contexts/SuperTableContext';

// when defining a custom filter for a SuperTable, this hook
// allows you to pass a function(please use useCallback) that is used
// to filter each item
const useDeprecatedDefineSuperTableFilterDoNotUse = (func, filterState) => {
    const { updateFilterFunction, updateFilterState } = useContext(DeprecatedSuperTableContextDoNotUse);

    const key = useRef(createID());

    useEffect(() => {
        updateFilterFunction(func, key.current);
    }, [func, key, updateFilterFunction]);

    useEffect(() => {
        for(const key of Object.keys(filterState || {})) {
            updateFilterState(key, filterState[key]);
        }
    }, [filterState, updateFilterState])
};

export default useDeprecatedDefineSuperTableFilterDoNotUse;
