import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import SignOut from '../../helpers/signout';
import { PrimaryCTABtn } from '../../styles/buttons';
import { BigBody } from '../../styles/typography';
import Modal from '../modal/Modal';
import { Wrap } from './styles';

const DeletedAccountPopup = () => {
    return (
        <Modal title=''>
            <Wrap>
                <AiOutlineExclamationCircle size={90} />
                <BigBody center>
                    Your account has been scheduled to be deleted and is inaccessible at this time.
                    If you would like to cancel your account deletion request, please email{' '}
                    <a href='mailto:support@eulerity.com'>support@eulerity.com.</a>
                </BigBody>
                <PrimaryCTABtn onClick={() => SignOut()}>Ok</PrimaryCTABtn>
            </Wrap>
        </Modal>
    );
};

export default DeletedAccountPopup;
