export type IncomeTarget = "l" | "m" | "h"
export type IncomeTargetLabel = "Modest" | "Comfortable" | "Affluent"

export const incomeTargetCopy: {
    [key in IncomeTarget]: IncomeTargetLabel
} = {
    l: "Modest",
    m: "Comfortable",
    h: "Affluent"
} as const

export const getIncomeTargets = (incomeTargets: IncomeTarget[]): IncomeTarget[] => {
    if (!incomeTargets || !incomeTargets.length) {
        return Object.keys(incomeTargetCopy) as IncomeTarget[]
    }

    return incomeTargets
}

export const getIncomeTargetLabel = (target: IncomeTarget) => {
    return incomeTargetCopy[target]
}