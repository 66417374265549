import { SerializedKey } from './apiResponses'
import { LocationId, Websafe } from './primitives'

export const SubscriptionStates = {
    PENDING_INACTIVE: 'Pending Inactive',
    PENDING_ACTIVE: 'Pending Active',
    DECLINED: 'Declined',
    CANCELED: 'Canceled',
    PAUSED: 'Paused',
    ACTIVE: 'Active',
} as const

export type SubscriptionState =
    (typeof SubscriptionStates)[keyof typeof SubscriptionStates]

export type Subscription = {
    id: string
    stripeId: string
    created: string
    location: LocationId
    status: 'ACTIVE' | 'INACTIVE'
    state: SubscriptionState
    defaultCurrency: string
    currency: string
    monthlySpend: number
    intervalCount: IntervalCount
    periodEnd: string
    last4?: string
    token?: string
    websafe?: Websafe
    daysActive: number
    billingPercentageComplete: number
    fee?: number
    managingUserKey?: SerializedKey
    declineReason?: string
    declineDate?: string

    promo?: string
    promoExpiry?: string
    oldPromos: any[]
    impressions: number
    chargedMonthlyUsd: number
    pausedMonthlySpend: number
    pausedChargedMonthlyUsd: number
    pausedReason?: string
    pausedComment?: string
    pausedDate?: string
    cancelDate?: string
    cancelAtPeriodEnd?: boolean
    spend: number
    freeTrialEnd?: string
    scheduledDate?: string
    scheduledReason?: string
    scheduledComment?: string
    scheduledAmount?: number

    pendingDate?: string
    billingCycleStart: number
    lastSubscription?: string
}

export type ExchangeRate = {
    conversion: number
    created: string
    currency: string
    updated: string
}

export type SubscriptionPrepCall = {
    amount: number
    cpc: number
    cpm: number
    currency: string
    exchangeRates: ExchangeRate[]
    interval: number
    max: number
    min: number
    radius: number
    subscription?: Subscription
    subscriptionMinDurationAmount: number
    subscriptionMinDurationUnit: 'd' | 'm' | 'y'
}

export type PaymentSource = {
    id: string
    last4: string
    brand: string
    type: 'card' | 'bankaccount'
    expiry: string
}

export type Currency = {
    name: string
    symbol: string
    conversion: number
}

export const INTERVAL = {
    MONTHLY: 1,
    QUARTERLY: 3,
    BI_ANNUALLY: 6,
    ANNUALLY: 12
} as const

export type IntervalCount = typeof INTERVAL[keyof typeof INTERVAL]