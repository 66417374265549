import styled from "styled-components"
import { hexToRgba } from "../../../helpers/colors.util"
import { DangerBtn, OutlineBtn } from "../../../styles/buttons"

export const CreativeTypeHeader = styled.div`
  margin-bottom: 24px;
`

export const CreativeModalBody = styled.div`
  display: flex;
  gap: 56px;
`

export const CreativeModalLeftContent = styled.div`
  width: 400px;

  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;

    // * Videos/Iframes cannot adjust height from what it seems (video iteself won't scale). This is to enable video posts/ads to fit the container they sit in.
    video, iframe {
      width: 304px;
    }
  }

  .action-btn-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .pause-action-container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: flex-end;
  }
`

export const CreativeModalRightContent = styled.div`
  width: 664px;
  overflow: hidden;
  overflow-y: auto;
`

export const CreativeDataContainer = styled.div`
  margin-bottom: 40px;

  table {
    border-collapse: separate;
    border-spacing: 0 12px;
  }

  th {
    min-width: 188px;
    text-align: left;
    margin-bottom: auto;
  }

  td {
    padding-left: 8px;
  }

  .searchTextList {
    color: ${props => props.theme.color.creativeColor1};
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
  }
`

// * Ad Rankings --- Ads
export const AdRanks = styled.div`
  .header {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 16px;
  }
`

// * Disclaimer --- Ads
export const DisclaimerContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.color.warningButtonColor};
  border-radius: 6px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: fit-content;

  svg {
    color: ${props => props.theme.color.warningButtonColor};

    path {
      stroke: ${props => props.theme.color.warningButtonColor};
    }
  }
`;

// * Network Status --- POSTS
export const NetworkStatusWrap = styled.div`
  margin-top: 40px;
`

export const NetworkStatusItemWrap = styled.div`  display: flex;
  display: grid;
  grid-template-columns: 6fr 9fr 1fr;
  flex: 1;
  align-items: flex-start;
  padding: 14px;
  gap: 20px;

  .ad-metrics-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
    justify-content: space-between;
  }

  .ad-metric-value {
    flex: 1;
    text-align: end;
  }

  .external-svg, .info-svg {
    color: ${props => props.theme.color.accentColor2};
    margin-left: auto;
    cursor: pointer;
  }

  .value {
    flex: 3.5;
  }
`

export const NetworkItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  img {
    width: 24px;
    height: 24px;
    padding: 1px;
    margin-right: 8px;
  }
`;

// * Network Metrics --- POSTS
export const MetricSummary = styled.div`
  margin-top: 16px;
`

export const MetricsLifeTime = styled.div`
  margin-top: 16px;

  .ad-metric-header {
    display: flex;
    justify-content: space-between;
    text-align: end;
    padding: 14px;
    gap: 20px;
  }

  .header {
    flex: 1;
  }
`

// * Reusable
export const MetricItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const TrophyCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  background: ${props => props.isSelected && props.theme.color.accentColor4};
  border-radius: 8px;
  width: 205px;

  .trophy-img {
    height: 30px;
    width: auto;
  }
`

export const MetricItemCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  background: ${props => props.isSelected && props.theme.color.accentColor4};
  border-radius: 8px;

  .trophy-img {
    height: 50px;
    width: auto;
  }

  .name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &:hover {
    cursor: pointer;
    background: ${props => hexToRgba(props.theme.color.accentColor4, 0.6)};

    .title {
      color: ${props => props.theme.color.accentColor2};
    }
  }
`

// * Rejections Modal
export const RejectionHeader = styled.div`
  margin-bottom: 10%;
  .rejectionTitle{
    font-weight: 300;
    font-size: 30px;
    margin-bottom:5%;
    color:${props => props.theme.color.textColor2};
  }
  .rejectionSubTitle{
    font-size: 18px;
    line-height: 25px;
    color: ${props => props.theme.color.textColor1};
    margin-bottom: 5%;
  }
`;

export const RejectionFormContainer = styled.form`
  padding:0px 40px;
  textarea, select {
    margin-bottom: 10%;
    height: 34px;
    border: 1px solid ${props => props.theme.color.accentColor3};
    background: transparent;
    border-radius: 9px;
    opacity: 0.7;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    color: ${props => props.theme.color.textColor1};
    &::placeholder{
      color: ${props => props.theme.color.textColor1}
    }
    option {
      background: ${props => props.theme.color.backgroundColor2};
    }
  }

  label {
    font-size: 24px;
    line-height: 1.5;
    color: ${props => props.theme.color.textColor2};
    font-weight: 300;
    margin-left: 2%;
  }
  button {
    margin-right: 5px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 1177px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const RejectBtn = styled(DangerBtn)`
  width: 180px;
  height: 40px;
  margin-bottom: 5%;
  font-size: 18px;

  @media (max-width: 1177px) {
      width: 70%;
  }
`

export const CancelBtn = styled(OutlineBtn)`
  width: 180px;
  height: 40px;
  margin-bottom: 5%;
  font-size: 18px;

  @media (max-width: 1177px) {
      width: 70%;
  }
`


// * Creative Display
export const CreativeDisplayContainer = styled.div`
  width: 100%;
  height: 100%;

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .preview {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(.75);
  }

  .network-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 30px;
    div {
      width: 30px;
    }
  }
`

export const NetworkPreviewItem = styled.div`
    cursor: pointer;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props?.isSelected && props.theme.color.accentColor4};
    border: ${props => props?.isSelected && `1px solid ${props.theme.color.accentColor2}`};
    border-radius: 4px;

    svg {
      mix-blend-mode: ${props => !props.isSelected && 'luminosity'};
    }

  &:hover {
    transition: background-color 0.3s ease;
    background: ${props => props.theme.color.accentColor4};
    border: 1px solid ${props => props.theme.color.accentColor2};
    border-radius: 4px;
  }
`