import styled from "styled-components";
import { createBoxShadow } from "../../../styles/card";
import { decideTextColor, hexToRgba } from "../../../helpers/colors.util";

export const getColorScheme = (props) => {
    const { colorScheme, theme } = props
    if (colorScheme === 'ad') return theme.color.creativeColor1;
    if (colorScheme === 'post') return theme.color.creativeColor2;
    else return theme.color.accentColor2;
}

export const FilterContainer = styled.div`
    z-index: 100;
    position: absolute;
    height: 456px;
    width: 360px;
    background-color: ${props => props.theme.color.backgroundColor2};
    box-shadow: ${createBoxShadow};
    padding: 24px;
    margin-top: 5px;
    border-radius: 9px;
    right: 0;
    display: flex;
    flex-direction: column;

    // * Body of filter
    .filter-content {
        display: flex;
        flex-direction: column;
    }

    .category-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .chevron-container {
        display: flex;
        align-items: center;

        svg {
            cursor: pointer;
        }
    }
`
// * Category Options Wrapper
export const CategoryOptionsWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    gap: 16px;
    overflow: scroll;

    // * Hide scrollbar
    ::-webkit-scrollbar {
        display: none;
    }
`

// * Category Item
export const CategoryItem = styled.div`
    cursor: pointer;
    font-weight: ${props => props.isSelected ? 400 : 300};

    .category-text {
        border-bottom: ${props => props.isSelected && `2px solid ${getColorScheme(props)}`};
    }
`

// * Input Container
export const SearchContainer = styled.div`
    margin-top: 16px;
`

// * Counter Container
export const CounterContainer = styled.div`
    margin-top: 6px;
    border-radius: 6px;
    background-color: ${props => hexToRgba(getColorScheme(props), 0.75)};
    padding: 4px 12px;
    font-size: 10px;
    font-weight: 300;
    height: 24px;
    color: ${props => decideTextColor(getColorScheme(props))};
`

// * Quick Actions
export const QuickActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    gap: 8px;
    text-decoration: underline;
`

// * Filter Option Container
export const FilterOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    margin-top: 16px;
    flex: 1;
`

// * Filter Option
export const FilterOption = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    input[type=checkbox] {
        height: 16px;
        width: 16px;
        accent-color: ${props => getColorScheme(props)};
        cursor: pointer;
    }
`