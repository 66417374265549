import React, {useState} from 'react'
import useSteps from '../../../hooks/useSteps';
import Modal from '../../../components/modal/Modal';
import MultiStepForm from '../../../components/forms/MultiStepForm';
import { BuildHeader } from './styles';
import { MdImage } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import CreativeStepPreview from './steps/CreativeStepPreview';
import CreativeStepLanding from './steps/CreativeStepLanding';
import CreativeStepDates, { getInitialAdDates } from './steps/CreativeStepDates';
import CreativeStepReview from './steps/CreativeStepReview';
import { hideOverlay, showOverlay } from '../../../actions/overlayActions';
import { toast } from 'react-toastify';
import { CREATIVE_KIND, mapKindToType } from '../../../helpers/creative.util';
import CreativeStepNetworks, { IMAGE_POST_NETWORKS, VIDEO_POST_NETWORKS } from './steps/CreativeStepNetworks';
import GaWarningModal from './GaWarningModal';
import { FORKS as _F } from '../../../constants/fork';
import { isMember } from '../../../helpers/permissionsHelper';
import useCreativeActions from '../../../hooks/useCreativeActions';
import { OutlineBtn, PrimaryCTABtn } from '../../../styles/buttons';
import CreativeStepLocations from './steps/CreativeStepLocations';
import useFormState from '../../../hooks/useFormState';
import usePreviewText from '../../../features/creatives/hooks/usePreviewText';
import useLandingPage from '../../../features/landingPage/useLandingPage';
import InvalidURLWarning from '../../../features/landingPage/InvalidURLWarning';
import usePrimeTimePosting from '../hooks/usePrimeTimePosting';
import CreativeStepPostDate, { getInitialPostDates, validateDateStep } from './steps/PeakPostTime';
import { createCreative } from '../../../requests/creative';
import { addRequiredTemplateFields, copyFromTemplateCreative, getUpdatedAcceptFields } from './acceptmodal.util';
import CreativeStepInstagramCollaborators from './steps/CreativeStepInstagramCollaborators';
import Button from '../../../components/button/Button';
import useCollaboratorStep from '../hooks/useCollaboratorStep';
import { uniqueId } from 'lodash';
import { POST_LOCATION_FILTERS } from '../../../features/advancedFilter/hooks/useInitializeOptions';
import useExperiment from '../../../hooks/useExperiment';
import { EXPERIMENTS } from '../../../helpers/experimentHelpers';

export const AcceptFormContext = React.createContext()

const AcceptModal = ({sample, onClose, locations, closeMainModal, isBulk = false, onFinish = () => {}, refreshCreatives }) => {
    const user = useSelector(s => s.user)
    const magicOrTemplate = useSelector(s => s.build.magicOrTemplateFork)
    const _D = useDispatch()

    const [isLandingWarningOpen, setIsLandingWarningOpen] = useState(false)

    const { validateURL, showInvalidWarning, setShowInvalidWarning, proceedWithUrl, setProceedWithUrl } = useLandingPage()
    const blockPostsOnSecondariesExp = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)

    const { currentStep, goNext, goPrev } = useSteps()
    const { acceptMagic } = useCreativeActions()
    const { previewText } = usePreviewText(sample)

    const {type, text} = mapKindToType(sample)
    const isPost = type === _F.POST
    const isAd = type === _F.AD

    const FORM_CONTEXT = useFormState({
        kind: sample?.kind,
        selectedLocations: locations || [],
        targetNetworks: isPost ? sample?.targetNetworks?.filter(n => (sample?.kind === CREATIVE_KIND.POST ? IMAGE_POST_NETWORKS : VIDEO_POST_NETWORKS).includes(n)) || [] : null, // Either this field or landingPage field depending on ad or post
        landingPage: sample?.landingPage || '',
        start: isPost ? getInitialPostDates(sample).start : getInitialAdDates(sample).start,
        end: isAd ? getInitialAdDates(sample).end : null,
        peakStartDate: isPost ? getInitialPostDates(sample)?.peakStartDate : null,
        instagramCollaborators: sample?.instagramCollaborators?.map(collaborator =>  ({ name: collaborator, id: uniqueId() }))
    })

    const { formData, updateFormData, updatedFields, revertField } = FORM_CONTEXT
    const primeTimePostingData = usePrimeTimePosting({formData})

    const { hasDuplicates, hasEmptyCollaborators, hasInvalidHandle } = useCollaboratorStep(formData)

    const onSubmit = () => {
        // 1. USE CREATIVE FOLDER CREATIVE AS A TEMPLATE (MAKE A NEW ONE)
        // ZEE is "accepting" to use a creative from a folder as a template (if that folder is exposed to them)
        // so we make it look like the Accept flow but in reality they are making a new creative
        // so we have to copy a bunch of fields from the old creative
        if (sample.repoName) {
            const newCreative = addRequiredTemplateFields({ creative: copyFromTemplateCreative(sample), source: formData, proceedWithUrl})
            createCreative({ kind: newCreative.kind, creative: newCreative}).then(() => {
                refreshCreatives?.()
                closeMainModal?.()
                onClose?.()
                toast.success("Accepted successfully.")
            }).catch(() => {
                toast.error("Something went wrong, try again...")
            })
            return
        }

        // 2. ACCEPT MAGIC - Payload only contains updated fields from the form flow
        const payload = getUpdatedAcceptFields(updatedFields, proceedWithUrl)

        if (isBulk) {
            return onFinish(payload)
        }

        acceptMagic({
            creative: sample,
            data: payload
        }).then(() => {
            refreshCreatives?.()
            closeMainModal?.()
            onClose?.()
            toast.success("Accepted successfully.")
        })
    }


    const handleLandingPageRestriction = (finalUrl) => {
        if (isMember(user.accessLevel) && formData.landingPage === sample?.landingPage) {
            updateFormData('landingPage', finalUrl)
            goNext()
        } else {
            setIsLandingWarningOpen(true)
        }
    }

    let steps = [
        {
            id: 1,
            title: "QUICK PREVIEW",
            component:  <CreativeStepPreview sampleCreative={sample} previewText={previewText} networks={formData?.targetNetworks} location={locations?.length === 1 ? locations[0] : null}/>,
            buttons: <>
                <PrimaryCTABtn onClick={goNext}> Confirm </PrimaryCTABtn>
            </>
        },
        {
            id: 2,
            title: "CAMPAIGN SELECTION",
            component: <CreativeStepLocations intendedCreative={sample} context={AcceptFormContext} filters={blockPostsOnSecondariesExp.active && isPost ? POST_LOCATION_FILTERS : {}}/>,
            buttons: <>
                <OutlineBtn onClick={goPrev}>Go Back</OutlineBtn>
                <PrimaryCTABtn onClick={() => {
                    if (!formData?.selectedLocations?.length) return toast.error("Must select a location!")
                    goNext()
                }}>Confirm</PrimaryCTABtn></>,
            shouldHide: magicOrTemplate !== 'template' // conditionally add this step if we are working with templates
        },
        {
            id: 3,
            title: isPost ? "SELECT NETWORKS" : "LANDING PAGE",
            component: isPost ? <CreativeStepNetworks context={AcceptFormContext} />
                              : <CreativeStepLanding context={AcceptFormContext} creative={sample} />,
            buttons: <>
                <OutlineBtn onClick={goPrev}>Go Back</OutlineBtn>
                <PrimaryCTABtn onClick={async () => {
                    if (isPost) {
                        if (!formData?.targetNetworks?.length) {
                            return toast.error('Please select at least one network')
                        }
                        goNext()
                    } else {
                        const { landingPage } = formData
                        if (landingPage?.length) {
                            _D(showOverlay('Checking Landing Page'))
                            try {
                                const finalUrl = await validateURL(landingPage)
                                handleLandingPageRestriction(finalUrl)
                            } catch {
                                setShowInvalidWarning(true)
                            } finally {
                                _D(hideOverlay())
                            }
                        } else {
                            goNext()
                        }
                    }
                }}>Confirm</PrimaryCTABtn>
            </>
        },
        {
            id: 4,
            title: "ADD COLLABORATORS",
            component: <CreativeStepInstagramCollaborators context={AcceptFormContext} />,
            buttons: <>
                <Button variant="outline" onClick={goPrev}>Back</Button>,
                <Button onClick={goNext} disabled={hasDuplicates || hasEmptyCollaborators || hasInvalidHandle}>Confirm</Button>
            </>,
            shouldHide: !isPost || !formData.targetNetworks.includes('instagram')
        },
        {
            id: 5,
            title: "SET DATES",
            component: isPost ? <CreativeStepPostDate primeTimePostingData={primeTimePostingData} context={AcceptFormContext} /> : <CreativeStepDates isPost={isPost} context={AcceptFormContext} />,
            buttons: <>
                <OutlineBtn onClick={goPrev}>Go Back</OutlineBtn>
                <PrimaryCTABtn onClick={() => {
                    // * Old flow - we want to ensure that the Dynamic variant of this still uses the old flow
                    if (!isPost) {
                        goNext()
                        return
                    }

                    const { publishMethod, scheduleMethod, selectedDate, selectedTime, adjustedDate, formattedPrimeDate } = primeTimePostingData
                    const error = validateDateStep(selectedDate, selectedTime, publishMethod, scheduleMethod)
                    if (error) return toast.error(error.message)

                    // We have to make sure to send ONLY the appropriate field, but not both
                    if (publishMethod === 'ASAP') {
                        updateFormData('start', null)
                        revertField('peakStartDate')
                    } else if (publishMethod === 'Future') {
                        if (scheduleMethod === 'Prime') {
                            updateFormData('peakStartDate', formattedPrimeDate)
                            revertField('start')
                        } else if (scheduleMethod === 'Custom') {
                            updateFormData('start', adjustedDate)
                            revertField('peakStartDate')
                        }
                    }
                    goNext()
                }}>Confirm</PrimaryCTABtn>
            </>
        },
        {
            id: 6,
            title: "FINAL REVIEW",
            component: <CreativeStepReview isPost={isPost} isAd={!isPost} previewText={previewText} context={AcceptFormContext} sampleCreative={sample} />,
            buttons: <>
                <OutlineBtn onClick={goPrev}>Go Back</OutlineBtn>
                <PrimaryCTABtn onClick={onSubmit}>Submit</PrimaryCTABtn>
            </>
        },
    ].filter(step => !step.shouldHide)

    return (
        <Modal onClose={onClose} minHeight={'700px'}>
            <AcceptFormContext.Provider value={FORM_CONTEXT}>
                <BuildHeader fork={_F.DYNAMIC} creationType={type}> <MdImage size="40px"/> {text} Review </BuildHeader>
                <MultiStepForm
                    creationType={type}
                    steps={steps}
                    currentStep={currentStep}
                />
                { isLandingWarningOpen && <GaWarningModal setIsLandingWarningOpen={setIsLandingWarningOpen} goNext={goNext}/> }
                {
              showInvalidWarning &&
              <InvalidURLWarning
                  url={formData?.landingPage}
                  onClose={() => setShowInvalidWarning(false)}
                  onConfirm={() => {
                      handleLandingPageRestriction(formData?.landingPage)
                      setShowInvalidWarning(false)
                      setProceedWithUrl(true)
                  }}
              />
        }
            </AcceptFormContext.Provider>
        </Modal>
    )
}
export default AcceptModal