export type GenderTarget = 'm' | 'f'
export type GenderTargetLabel = 'Male' | 'Female'

export const genderTargetCopy: {
    [key in GenderTarget]: GenderTargetLabel
} = {
    m: 'Male',
    f: 'Female',
} as const

export const getGenderTargets = (genderTargets: GenderTarget[]): GenderTarget[] => {
    if (!genderTargets || !genderTargets.length) {
        return Object.keys(genderTargetCopy) as GenderTarget[]
    }

    return genderTargets
}

export const getGenderTargetLabel = (target: GenderTarget) => {
    return genderTargetCopy[target]
}