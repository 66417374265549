import styled from "styled-components";

export const StyledIdleModalContent = styled.div`
    display: flex;
    flex-direction: column;

    .idle__message__container {
        text-align: center;
        margin-bottom: 10px;
    }

    .idle__image__container {
        width: 396px;
        height: 264px;
        margin: auto;
        margin-bottom: 40px;
    }
`