import styled from 'styled-components';

export const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    gap: 1rem;

    svg {
        color: ${(props) => props.theme.color.accentColor2};
    }

    button {
        font-family: inherit;
        font-weight: bold;
        font-size: 18px;
        margin-top: 3rem;
    }
`;