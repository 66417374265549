import { CREATIVE_STATUS, getCreativeKindLabel, isMagicEntity } from "../../../helpers/creative.util"
import { isMember } from "../../../helpers/permissionsHelper"
import { Small } from "../../../styles/typography"

export const getCloneStatusLabelText = (creative) => {
    const { fStatus } = creative
    const result = [CREATIVE_STATUS.ACTIVE, CREATIVE_STATUS.UNREVIEWED].includes(fStatus) ? 'Enabled' : 'Disabled'
    return result
}

export const getCloneStatusLabel = (creative, colorMap) => {
    const result = getCloneStatusLabelText(creative)
    return (
        <tr>
            <th><Small semibold>Cloning Status</Small></th>
            <td><Small style={{color: colorMap[result.toUpperCase()]}}>{result}</Small></td>
        </tr>
    )
}

export const magicAwaitingMessage = (accessLevel, lowerCase) => {
    let requires = lowerCase ? "Requires Your Review" : "REQUIRES YOUR REVIEW"
    let awaiting = lowerCase ? "Awaiting User’s Review" : "AWAITING USER’S REVIEW"
        return isMember(accessLevel) ? requires : awaiting
}

export const getMagicDescription = (creative, user) => {
    const awaitingMessage = magicAwaitingMessage(user?.accessLevel)
    const creativeType = getCreativeKindLabel(creative, true)
    if (!isMagicEntity(creative)) return '';
    switch(creativeType) {
        case 'Optional Dynamic Ad': {
          return <Small>
                     {`${creativeType}s are Dynamic Ads that are created by the System Manager for the Campaign Owner to opt into running. `} <br/>
                     {`Your ${creativeType} is `}
                      <strong>{awaitingMessage}</strong>
                      {` which means you can `}
                      <em>approve</em>
                      {` the ${creativeType} to become a Dynamic Ad, `}
                      <em>delete</em>
                      {` the ad, or `}
                      <em>edit</em>
                      {` the ad before making it a Dynamic Ad.`}
                </Small>
        }
        case 'Optional Video Ad': {
            return <Small>
                {`${creativeType}s are Video Ads that are created by the System Manager for the Campaign Owner to opt into running. `} <br/>
                {`Your ${creativeType} is `}
                <strong>{awaitingMessage}</strong>
                {` which means you can `}
                <em>approve</em>
                {` the ${creativeType} to become a Video Ad, `}
                <em>delete</em>
                {` the ad, or `}
                <em>edit</em>
                {` the ad before making it a Video Ad.`}
            </Small>
        }
        case 'Optional Image Post': {
            return <Small>
                {`${creativeType}s are Image Posts that are created by the System Manager for the Campaign Owner to opt into posting. `} <br/>
                {`Your ${creativeType} is `}
                <strong>{awaitingMessage}</strong>
                {` which means you can `}
                <em>approve</em>
                {` the ${creativeType} to become an Image Post, `}
                <em>delete</em>
                {` the post, or `}
                <em>edit</em>
                {` the post before making it an Image Post.`}
            </Small>
        }
        case 'Optional Video Post': {
            return <Small>
                {`${creativeType}s are Video Posts that are created by the System Manager for the Campaign Owner to opt into posting. `} <br/>
                {`Your ${creativeType} is `}
                <strong>{awaitingMessage}</strong>
                {` which means you can `}
                <em>approve</em>
                {` the ${creativeType} to become a Video Post, `}
                <em>delete</em>
                {` the post, or `}
                <em>edit</em>
                {` the post before making it a Video Post.`}
            </Small>
        }
        default: {
            return <p></p>
        }
    }
  }