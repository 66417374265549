import React, { useCallback, useEffect, useMemo } from 'react'
import Slider from '../../../../components/reusableUI/components/slider'
import { NavItem } from '../../../../components/reusableUI/styles/selectors'
import { OutlineBtn, PrimaryCTABtn } from '../../../../styles/buttons'
import { CustomSelect } from '../../../../styles/inputs'
import { H2, Body, XS } from '../../../../styles/typography'
import { ButtonContainer } from '../../../creativerepo/styles'
import { useInsightsReportBuilderContext } from '../InsightsReportBuilder'
import {
    InsightReportCategory,
    InsightReportSubCategory,
} from '../core/reportTypes'
import {
    InsightBuilderReportCategory
} from './SelectReportType'
import { useTheme } from 'styled-components'
import {
    ColumnGroupOption,
    columnCategoryMap,
    insightReportColumnOptions,
} from '../core/reportColumns'
import useLastValue from '../../../../hooks/useLastValue'
import { useAccess } from '../../../../hooks/useAccess'

export type SelectableInsightsDataPoint = {
    [T in InsightReportCategory | 'default']: {
        label: string
        value: string
        category: T
        subcategory: InsightReportSubCategory<T>
    }
}[InsightReportCategory | 'default']

const createGlobalSearchItems = (item: ColumnGroupOption) => {
    return {
        groupId: item.groupId,
        label: `${item.subCategory}: ${item.groupName}`,
        value: item.groupId,
    }
}

const createSearchItem = (item: ColumnGroupOption) => {
    return {
        groupId: item.groupId,
        label: item.groupName,
        value: item.groupId,
    }
}

export const SelectReportDataPoints = () => {
    const theme = useTheme()

    const {
        goNext,
        goPrev,
        isReportColumnSelected,
        selectedReportColumns,
        setSelectedReportColumns,
        selectedReportCategoryIds,
        selectedReportCategories,
    } = useInsightsReportBuilderContext()

    const { isMember } = useAccess()
    const franchiseeColumnFilter = (item : ColumnGroupOption) => {
        if (!isMember()) return true

        const restrictedColumns = ['last-login-date', 'last-login-days']

        return !restrictedColumns.includes(item.groupId)
    }

    const [selectedTab, setSelectedTab] =
        React.useState<InsightBuilderReportCategory['id']>()

    const lastSelectedReportType = useLastValue(selectedReportCategories)

    useEffect(() => {
        if (lastSelectedReportType.length === selectedReportCategories.length) return
        const first = selectedReportCategories.find(item => item.id)
        if (!first) return
        setSelectedTab(first.id)
    }, [selectedReportCategories, lastSelectedReportType])

    const subcategoryOptions = useMemo(() => {
        if (!selectedTab) return undefined
        return columnCategoryMap.get(selectedTab)
    }, [selectedTab])

    const setSubcategorySelection = useCallback(
        (
            subcategory: InsightReportSubCategory<InsightReportCategory>,
            selected: ColumnGroupOption[]
        ) => {
            const unaffected = selectedReportColumns.filter(
                (item) =>
                    item.subCategory !== subcategory ||
                    item.category !== selectedTab
            )
            setSelectedReportColumns([...unaffected, ...selected])
        },
        [setSelectedReportColumns, selectedReportColumns, selectedTab]
    )

    const availableInsightReportDataPoints = insightReportColumnOptions
        .filter((item) =>
            selectedReportCategoryIds.some((id) => id === item.category)
        )
        .filter(franchiseeColumnFilter)

        return (
        <div>
            <H2>Select Data</H2>
            <Body
                style={{
                    marginBottom: '24px',
                }}
            >
                Select the data you would like to see in your reports.
            </Body>

            <CustomSelect
                placeholder="Search for the data you like to see in your report..."
                value={selectedReportColumns.map(createGlobalSearchItems)}
                onChange={setSelectedReportColumns}
                options={availableInsightReportDataPoints.map(
                    createGlobalSearchItems
                )}
                isMulti={true}
                style
            />

            <div
                style={{
                    border: '1px solid',
                    borderColor: theme.color.greyAccent1,
                    borderRadius: '8px',
                    padding: '48px',
                    margin: '24px 0px',
                }}
            >
                <div
                    style={{
                        marginBottom: '24px',
                        marginLeft: '-55px',
                        marginRight: '-55px',
                    }}
                >
                    <Slider
                        justifyTabs="flex-start"
                        width="100%"
                        useChevrons={false}
                    >
                        {selectedReportCategories.map((category) => (
                            <NavItem
                                className="carousel-item"
                                key={category.id}
                                type={category.id}
                                onClick={() => setSelectedTab(category.id)}
                                selectedField={selectedTab ?? 'none'}
                                noUpperCase
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <Body>
                                        {category.label} (
                                        {
                                            selectedReportColumns.filter(
                                                (dataPoint) =>
                                                    dataPoint.category ===
                                                    category.id
                                            ).length
                                        }
                                        )
                                    </Body>
                                </div>
                            </NavItem>
                        ))}
                    </Slider>
                </div>
                {subcategoryOptions &&
                    Array.from(subcategoryOptions.entries()).map(
                        ([subcategory, items]) => {
                            return (
                                <div
                                    key={subcategory}
                                    style={{
                                        alignItems: 'center',
                                        gap: '1rem',
                                        marginBottom: '1rem',
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 3fr',
                                    }}
                                >
                                    <Body>{subcategory}</Body>
                                    <CustomSelect
                                        placeholder="Select the data that you like to see in this report..."
                                        value={items
                                            .filter(isReportColumnSelected)
                                            .filter(franchiseeColumnFilter)
                                            .map(createSearchItem)}
                                        options={[
                                            {
                                                label: subcategory,
                                                options: [...items.filter(franchiseeColumnFilter).map(
                                                    createSearchItem
                                                ), {
                                                    label: '',
                                                    value: 'control'
                                                }]
                                                ,
                                            },
                                        ]}
                                        isOptionDisabled={(option: ReturnType<typeof createSearchItem>) => {
                                            return option.value === 'control'
                                        }}
                                        onChange={(
                                            options: ColumnGroupOption[]
                                        ) =>
                                            setSubcategorySelection(
                                                subcategory,
                                                options
                                            )
                                        }
                                        isMulti={true}
                                        formatGroupLabel={() => {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignContent: 'flex-start',
                                                    gap: '1rem'
                                                }}>
                                                    <XS color="accentColor2" onClick={() => {
                                                        setSubcategorySelection(
                                                            subcategory,
                                                            items.filter(franchiseeColumnFilter)
                                                        )
                                                    }}>Select all</XS>
                                                    <XS color="accentColor2" onClick={() => {
                                                        setSubcategorySelection(
                                                            subcategory,
                                                            []
                                                        )
                                                    }}>Deselect all</XS>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            )
                        }
                    )}
            </div>

            <ButtonContainer
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <OutlineBtn onClick={goPrev}>Back</OutlineBtn>
                <PrimaryCTABtn onClick={goNext} disabled={selectedReportColumns.length === 0}>
                    Select Data
                </PrimaryCTABtn>
            </ButtonContainer>
        </div>
    )
}
