import styled from 'styled-components';
import { createBoxShadow } from '../../styles/card';

export const StyledSidebar = styled.aside<{
    sidebarHeight: number
}>`
  position: sticky;
  ${(props) => {
    return `top: calc(100vh - ${props.sidebarHeight}px);`
  }}
  min-height: 100vh;
  height: min-content;
  min-width: 225px;
  width: 225px;
  overflow-x: hidden;
  padding: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  background-color: ${props => props.theme.color.navbarBackgroundColor};
  box-shadow:${createBoxShadow};
  overflow-y: auto;
  color: ${props => props.theme.color.navbarTextColor};
  svg {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  .providerImg {
    width: 15px;
    height: 15px;
  }
  .current p {
    color: ${props => props.theme.color.navbarActiveTextColor};
    font-weight: 700;
  }
`

export const StyledSidebarV2 = styled.aside<{
  sidebarHeight: number
}>`
  display: flex;
  flex-direction: column;
  position: sticky;
  ${(props) => {
    return `top: calc(100vh - ${props.sidebarHeight}px);`
  }}
  height: 100vh;
  min-width: 225px;
  max-width: 255px;
  overflow-x: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  background-color: ${props => props.theme.color.navbarBackgroundColor};
  box-shadow:${createBoxShadow};
  overflow-y: auto;
  color: ${props => props.theme.color.navbarTextColor};
  svg {
    width: 16px;
    height: 16px;
  }

  .providerImg {
    width: 15px;
    height: 15px;
  }
  .current p {
    color: ${props => props.theme.color.navbarActiveTextColor};
    font-weight: 700;
  }
`

export const SidebarSection = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none; // hides the scrollbar
`

export const LinkStyle = {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '12px',
    fontSize: '12px',
}

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

export const BrandingLogo = styled.img`
    display: block;
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    margin: 20px auto;
`

export const BreakPoint = styled.div`
	  width: 100%;
    border-bottom: 1px solid ${props => props.theme.color.greyAccent2};
`;

export const StyleDevelopmentTools = styled.div`
  cursor: pointer;
  text-align: center;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  gap: 10px;
`
