import defaultColumns from "./default";
import generalColumns from "./general";
import subscriptionColumns from "./subscription";
import targetingColumns from "./targeting";

const businessAndCampaigns = [
	...defaultColumns,
	...generalColumns,
	...subscriptionColumns,
	...targetingColumns,
]

export default businessAndCampaigns