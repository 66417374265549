import styled, {css} from 'styled-components';
import { ALERT } from './util';
import { hexToRgba } from '../../helpers/colors.util';

type StyledAlertType = {
    type?: String;
}

const infoStyles = css`
    border: 1px solid ${props => props.theme.color.greyAccent1};
`
const warningStyles = css`
    border: 1px solid ${props => props.theme.color.warningButtonColor};
    svg {
        fill: ${props => props.theme.color.warningButtonColor};
    }
`
const successStyles = css`
    border: 1px solid ${props => props.theme.color.activeButtonColor};
    svg {
        fill: ${props => props.theme.color.activeButtonColor};
    }
`
const alertStyles = css`
    border: 1px solid ${props => props.theme.color.errorNegativeButtonColor};
    background-color: ${props => hexToRgba(props.theme.color.errorNegativeButtonColor, 0.15)};

    svg {
        fill: ${props => props.theme.color.errorNegativeButtonColor};
    }
`

const learnMoreStyles = css`
    border: 1px solid ${props => props.theme.color.accentColor2};
    background-color: ${props => props.theme.color.accentColor2};

    .messageContainer > * {
        color: ${props => props.theme.color.textColor3};
    }

    button {
        color: ${props => props.theme.color.accentColor2};
    }
`

export const StyledAlert = styled.div<StyledAlertType>`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    gap: 12px;
    background-color: ${props => props.theme.color.backgroundColor2};
    .messageContainer {
        display: flex;
        flex-direction: column;
    }

    ${infoStyles}
    ${props => props.type === ALERT.WARNING.TYPE && warningStyles}
    ${props => props.type === ALERT.SUCCESS.TYPE && successStyles}
    ${props => props.type === ALERT.ALERT.TYPE && alertStyles}
    ${props => props.type === ALERT.LEARN_MORE.TYPE && learnMoreStyles}
    ${props => props.type === ALERT.INFO.TYPE && `
        svg {
            fill: none;
        }
    `}
`
