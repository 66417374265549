import React, { useState } from 'react';
import { AccordionWrap, TitleWrap } from './style';
import AnimatedCollapse from '../AnimatedCollapse/AnimatedCollapse';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useTheme } from 'styled-components';

type AccordionProps = {
    title: React.ReactNode
    children: React.ReactNode
    openIcon?: React.ReactNode;
    closeIcon?: React.ReactNode;
    openByDefault?: boolean;
    style?: React.CSSProperties;
    duration?: string;
    easing?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>

const Accordion = ({
    title,
    children,
    openByDefault = false,
    duration = '0.25s',
    easing = 'ease-in',
    openIcon: _openIcon,
    closeIcon: _closeIcon,
    ...rest
} : AccordionProps) => {
    const theme = useTheme()
    const [isOpen, setIsOpen] = useState(openByDefault);

    const { style, ...restWithoutStyle } = rest;

    const openIcon = _openIcon ?? <FaPlus color={theme.color.accentColor2} />
    const closeIcon = _closeIcon ?? <FaMinus color={theme.color.accentColor2} />

    return (
        <AccordionWrap style={style} {...restWithoutStyle}>
            <TitleWrap
                isOpen={isOpen}
                duration={duration}
                easing={easing}
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
                <div style={{ marginLeft: 'auto' }}>{isOpen ? closeIcon : openIcon}</div>
            </TitleWrap>
            <AnimatedCollapse isOpen={isOpen} duration={duration} easing={easing}>
                {children}
            </AnimatedCollapse>
        </AccordionWrap>
    );
};

export default Accordion;
