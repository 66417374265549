import { useCallback, useState } from 'react'
import reportBuilderFormatPresets, { ReportBuilderFormatPreset } from '../InsightsReportBuilder/core/aggregationOptions'
import {
	InsightReportBuilderPartialColumnFilter,
	InsightReportBuilderPartialIndex,
	InsightReportFormats,
	ReportRowInput,
} from '../InsightsReportBuilder/core/reportBuilder'
import { ColumnOption } from '../InsightsReportBuilder/core/reportColumns'
import { InsightBuilderReportCategory } from '../InsightsReportBuilder/Steps/SelectReportType'

export const generateGetRowIndex = (
	partialIndex: InsightReportBuilderPartialIndex[]
) => {
	return (input: ReportRowInput) => {
		return partialIndex.reduce((acc, fn) => {
			return acc + fn(input)
		}, '')
	}
}

export const generateColumnFilter = (
	partialColumnFilters: InsightReportBuilderPartialColumnFilter[]
) => {
	return (col: ColumnOption) => {
		return partialColumnFilters.every((fn) => fn(col))
	}
}

export const makeReportFormat = (
	formats: InsightReportFormats,
	isItemSelected: InsightReportBuilderPartialColumnFilter,
	extraColumnFilters?: InsightReportBuilderPartialColumnFilter[]
) => {
	return Object.fromEntries(
		Object.entries(formats).map(([key, value]) => {
			return [
				key,
				{
					...value,
					partialColumnFilters: [
						...value.partialColumnFilters,
						...extraColumnFilters ?? [],
						(col: ColumnOption) =>
							col.category === 'default' ||
							(col.category === key && isItemSelected(col)),
					],
				},
			]
		})
	)
}

export type InsightReportFormatPresetMap = {
	[key in InsightBuilderReportCategory['id']]: ReportBuilderFormatPreset[]
}

export const useReportFormats = () => {

	const [reportFormats, setReportFormats] = useState<InsightReportFormatPresetMap>({
		'business-and-campaigns-settings': [reportBuilderFormatPresets.businessAndCampaignsSettingsPreset],
		distribution: [reportBuilderFormatPresets.distributionPreset],
		performance:
			[
				reportBuilderFormatPresets.aggregateDate,
				reportBuilderFormatPresets.expandLocation,
			]
		,
		'platform-connections': [reportBuilderFormatPresets.platformConnectionsPreset],
	})

	const updateReportFormat = useCallback(
		(key: InsightBuilderReportCategory['id'], format: ReportBuilderFormatPreset[]) => {
			setReportFormats((prev) => ({
				...prev,
				[key]: format,
			}))
		},
		[setReportFormats]
	)

	return {
		reportFormats,
		updateReportFormat,
	}
}
