import styled, { css } from "styled-components";
import { OutlineBtn } from '../../../styles/buttons';
import { forkColorController } from "../newForks/fork.util";
import { decideTextColor } from "../../../helpers/colors.util";
import { Body, breakWord, H1 } from "../../../styles/typography";

// Texts: Extended Text, Header/Description (if premade ad)
export const StyledStepText = styled.div`
  margin: auto;
`;

export const TextFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? height : "100px")};
  margin-top: 20px;
`;

export const TextFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 100%;
  width: 500px;
  border: 1px solid ${props => props.isPost ? props.theme.color.creativeColor2 : props.theme.color.creativeColor1};
  border-radius: 9px;
  padding: 16px;
  .suggestions-wrapper {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
  .suggestions-button_wrapper {
    display: flex;
    gap: 0.3rem;
    align-items: center;
  }

  textarea {
    height: 290px;
    resize: none;
    background: transparent;
    color:${props => props.theme.color.textColor1};
    border-radius: 8px;
  }

  textarea.premade {
    height: 100px;
  }

  textarea.searchText {
    height: 72px;
    margin-bottom: 10px;
  }
`;

export const SuggestedTextContainer = styled.div`
  padding: 5px;
  overflow: overlay;
  min-width: 200px;
  position: relative;
  max-width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chatGPT-suggestions-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    opacity: 0.6;
  }

  .suggestion-inner-wrapper {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    overflow-y: scroll;
  }

  ${props => (!props.hasGPTsuggestions ) && props.isChatGPTSelected && css`
    .suggestion-inner-wrapper {
      display: grid;
      place-items: center;
      margin-top: 3rem;
      text-align: center;
    }
  `}
  ::-webkit-scrollbar {
    width: 2px;
  }
`;

// StyledSearchTextStep
export const StyledSearchTextStep = styled.div`
  margin: auto;
  width: 100%;
`;
export const SearchTextCountContainer = styled.div`
  background: ${props => props.theme.color.creativeColor1};
  color: ${props => decideTextColor(props.theme.color.creativeColor1)};
  height: 40px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  .searchText-count {
    margin-right: 10px;
    opacity: 1;
    margin-left: auto;
  }

  .searchText-required {
    margin-left: 10px;
  }
`
export const InputsContainer = styled.div`
  color: ${props => props.theme.color.textColor1};
  display: flex;
  flex-direction: column;
  height: 400px;
  padding-right: 10px; // this allows for space between container and the scrollbar itself
  overflow-y: scroll;

  .input-warning {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }
`

export const AddFieldButton = styled(OutlineBtn)`
    margin-top: auto;
    align-self: center;
    border-radius: 9px;
    font-size: 14px;
    font-weight: 600;
    width: 200px;

    svg {
      max-height: 12px;
      max-width: 12px;
      margin-right: 5px;

      path {
        stroke: ${props => props.theme.color.textColor1};
      }
  }
`
// Headline and Desc Modal List
export const ListModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  .item-list-container {
    max-height: 400px;
    margin-bottom: 20px;
    overflow-y: scroll;
  }

  button {
    background: transparent;
    border: 1px solid ${props => props.theme.color.textColor1};
    color: ${props => props.theme.color.textColor1};
    border-radius: 9px;
    font-size: 14px;
    width: 200px;
    height: 40px;
    align-self: center;
  }
`

// Landing
export const StyledLandingStep = styled.div`
  max-width: 800px;
  margin: 24px auto;
`;

export const LandingPageFieldGroup = styled.div`
  width: 100%;
  margin: 20px auto;
`;

export const LandingPageWarningContent= styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px 40px;

  h3 {
    align-self: center;
    font-size: 18px;
    font-weight: 700 bold;
  }

  h2 {
    font-size: 30px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }

  svg {
    fill: ${props => props.theme.color.warningButtonColor};;
    align-self: center;
  }
`
// Dates

export const StyledStepDates = styled.div`
  max-width: 600px;
  margin: 40px auto;
`;

export const DateTimeField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin: 20px auto;

    input {
      font-family: ${props => props.theme.font?.body?.family};
      background: transparent;
      color:${props => props.theme.color.textColor1};
      border: 1px solid ${props => props.theme.color.accentColor3};
      border-radius: 9px;
      height: 34px;
    }
`;

export const MinDurationWarning = styled(Body)`
  color: ${props => props.theme.color.errorNegativeButtonColor};
  margin: 20px 0;
`;

// Locations
export const StyledStepLocations = styled.div`
  margin: 20px auto;
`;

// Review
export const StyledStepReview = styled.div`
  width: 100%;
  justify-content: center;
`;

export const StepReviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CreativeSummaryTable = styled.table`
  margin: 40px 0px;
  max-height: 400px;
  overflow: auto;
  border-spacing: 10px;
  width: 100%;

  tr {
    vertical-align: bottom;
  }

  th {
    vertical-align: top;
    text-align: left;
  }

  .locationsList {
    max-height: 180px;
    overflow-y: auto;
  }

  .searchTextList {
    color: ${props => props.theme.color.creativeColor1};
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
  }

  .social-text {
    ${breakWord}
  }
`;

export const BuildHeader = styled(H1)`
  margin: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  color:  ${props => props.theme.color.textColor2 };
  svg {
    margin-right: 10px;
      fill: ${props => forkColorController({...props,  isHover: true})};
  }
`;

//video Ad or post info modal styles
export const VideoInfoContainer = styled.div`
    padding:0 40px;
`;

export const VideoInfoImage= styled.img`
  width:250px;
  height:250px;
  display: flex;
  text-align: center;
  margin:5% auto;
`;

export const VideoInfo= styled.div`
  font-size: 18px;
   p:last-child{
     margin-top:2%;
   }
`;

export const StyleUploadAssetsModal = styled.div`
  color: ${props => props.theme.color.textColor2};
  .uploadAsset_buttons {
    margin-top: 30px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    column-gap: 2rem;
  }
`

// EditedCreativeModal.js
export const EditCreativeInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .searchTextList {
    color: ${props => props.theme.color.creativeColor1};
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
  }
`

export const CreativeInfo = styled.div`
  margin: 20px 40px;
  .previewWrapper {
    video, iframe {
      width: 100%;
      height: 100%;
    }
    width: 200px;
    margin: 0px auto;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }
  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
    text-align: center;
  }
  table {
    text-align: left;
    margin: 20px 0;
    width: 300px;
  }
`

export const VariableKeyTableWrap = styled.div`
  border: solid 1px ${props => props.theme.color.accentColor3};
  border-radius: 0.5rem;
`

export const LandingPageDisplay = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 2.5rem;
  display: block;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;

  a {
    text-decoration: underline;
  }
`
export const DisclaimerContainer = styled.div`
  border: 1px solid ${props => props.theme.textColor2};
  border-radius: 0.5rem;
  display: flex;
  gap: 8px;
  padding: 8px;
  flex-wrap: nowrap;
  overflow: visible;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: center;
  width: 100%;
`;