import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { canSelectFranchise } from '../../helpers/permissionsHelper';
import { loadManageFranchises, selectFranchise } from '../../actions/franchiseListActions';
import { setLoading } from '../../actions/franchiseActions';
import { toggleTheme } from '../../actions/UIActions';
import { CustomSelect } from '../../styles/inputs';
import { toast } from 'react-toastify';


const FranchiseSelector = () => {
    const user = useSelector(s => s.user)

    const franchiseList = useSelector(s => s.franchiseList.franchiseList)
    const selectedFranchise = useSelector(s => s.franchiseList.selectedFranchise)
    const franchiseLoading = useSelector(state => state.franchise.loading)
    const _D = useDispatch();

    const hasLoaded = useRef(false)

    useEffect(() => {
        if(hasLoaded.current) return

        hasLoaded.current = true

        if(!selectedFranchise && canSelectFranchise(user?.accessLevel)){
            _D(loadManageFranchises())
                .then(() => _D(setLoading(false)))
                .catch(() => toast.error("Unable to load franchise list"))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loadManageFranchises, selectedFranchise])

    // Look for franchise name in query string
    useEffect(() => {
        if (!franchiseList.length || selectedFranchise) return;
        if (window.location.search) {
            let parts = window.location.search.substr(1).split('&');
            parts.forEach(part => {
                if (part.startsWith('franchise=')) {
                    const franchiseName = decodeURIComponent(part.split('=')[1]);
                    const foundFranchiseOption = franchiseList.find(opt => opt.name === franchiseName);
                    if (foundFranchiseOption) {
                        _D(selectFranchise(foundFranchiseOption))
                    } else {
                        console.error('Could not find franchise name specified in search query')
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [franchiseList])

    const handleSelect = (e) => {
        _D(toggleTheme('light'))
        _D(selectFranchise({name: e.value, displayName: e.label}))
    }

    const franchiseOptionList = () => (
        franchiseList.map(franchise => ({ value: franchise.name, label: franchise.displayName }))
    )

    return (
        <CustomSelect
            classNamePrefix={"Select"}
            placeholder="Select a Command Center"
            onChange={handleSelect}
            options={franchiseOptionList()}
            value={{value: null, label: selectedFranchise?.displayName ??  "Select a franchise"}}
            menuPosition={'fixed'}
            isDisabled={franchiseLoading}
        />
    )
}

export default FranchiseSelector
