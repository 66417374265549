import { LOAD_COUNTRIES, LOAD_CURRENCIES, LOAD_TEMPLATE, LOAD_REJECTION_REASONS } from '../actions/types';

const initialState = {
    countries: [],
    currencies: [],
    template: null,
    rejectionReasons: [],
}

const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_COUNTRIES: {
            return {
                ...state,
                countries: action.payload
            }
        }
        case LOAD_CURRENCIES: {
            return {
                ...state,
                currencies: action.payload
            }
        }
        case LOAD_TEMPLATE: {
            return { 
                ...state, 
                template: action.payload
            }
        }
        case LOAD_REJECTION_REASONS: {
            return { 
                ...state, 
                rejectionReasons: action.payload 
            }
        }
        default: return state;
    }
}

export default resourceReducer;