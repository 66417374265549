import styled from 'styled-components'
import { hexToRgba } from '../../../../helpers/colors.util'

export const AccordionWrap = styled.div`
    border: solid 1px ${(props) => props.theme.color.accentColor3};
    border-radius: 0.5rem;
    padding: 1.25rem;
    background-color: ${(props) =>
        hexToRgba(props.theme.color.accentColor4, 0.2)};
`

type TitleWrapProps = {
    isOpen: boolean
    duration: string
    easing: string
    onClick: () => void
}

export const TitleWrap = styled.div<TitleWrapProps>`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    transition: margin-bottom ${(props) => props.duration} ${(props) => props.easing};

    ${(props) => props.isOpen && 'margin-bottom: 1.25rem'};
`
