import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../components/modal/Modal';
import { FaRegPaperPlane } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { Body, H2, Link, XS } from '../../styles/typography';
import { OutlineBtn, PrimaryCTABtn } from '../../styles/buttons';
import { toast } from 'react-toastify';
import { firebase } from '../../services/fire.js';
import Input from '../../styles/inputs';
import styled from 'styled-components';
import useFormState from '../../hooks/useFormState';
import Regex from '../../constants/regex';
import { makeRequest } from '../../helpers/make-request';
import { API } from '../../constants/api';

const FormWrap = styled.div`
    label {
        display: block;
        margin-bottom: 0.5rem;
    }

    label:not(:first-of-type) {
        margin-top: 1rem;
    }
`;

type UpdateEmailModalProps = {
    existingEmail: string;
    onClose: () => void;
    onUpdate: (newEmail: string, password: string) => Promise<void>;
};

const UpdateEmailModal = (props: UpdateEmailModalProps) => {
    const { formData, handleFieldChange } = useFormState({
        newEmail: '',
        reenterNewEmail: '',
        password: '',
    });

    const [hasInvalidPassword, setHasInvalidPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const doEmailsMatch = useMemo(() => {
        if (formData.newEmail === '' || formData.reenterNewEmail === '') {
            return true;
        }

        return formData.newEmail === formData.reenterNewEmail;
    }, [formData.newEmail, formData.reenterNewEmail]);

    const isEmailValid = useMemo(() => {
        return !formData.newEmail.trim() || Regex.EMAIL.test(formData.newEmail);
    }, [formData.newEmail]);

    const validatePassword = () => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(props.existingEmail, formData.password)
                .then(resolve)
                .catch(reject);
        });
    };

    // this get request will throw an error if the email is already in use
    const checkIsAvailable = (email: string) => {
        return makeRequest().param('email', email).url(API.USER.EMAIL_AVAILABLE).get();
    };

    const handleSave = async () => {
        if (!doEmailsMatch || !isEmailValid) {
            return;
        }

        setIsLoading(true);

        try {
            await checkIsAvailable(formData.newEmail);
        } catch (error) {
            toast.error('Email is already in use.');
            setIsLoading(false);
            return;
        }

        try {
            await validatePassword();
        } catch (error) {
            setHasInvalidPassword(true);
            setIsLoading(false);
            return;
        }

        props.onUpdate(formData.newEmail, formData.password).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Modal width='600px'>
            <H2>Change Email Address</H2>
            <FormWrap style={{ marginTop: '2rem' }}>
                <label>
                    <Body>Current Email</Body>
                </label>
                <div>
                    <Input type='text' readOnly defaultValue={props.existingEmail} />
                </div>
                <label>
                    <Body>New Email *</Body>
                </label>
                <div>
                    <Input
                        type='text'
                        placeholder='Enter new email...'
                        value={formData.newEmail}
                        onChange={handleFieldChange('newEmail')}
                    />
                    {!isEmailValid && (
                        <XS color={COLORS.BRAND.ERROR}>Please enter a valid email address.</XS>
                    )}
                </div>
                <label>
                    <Body>Re-enter New Email *</Body>
                </label>
                <div>
                    <Input
                        type='text'
                        placeholder='Re-enter new email...'
                        value={formData.reenterNewEmail}
                        onChange={handleFieldChange('reenterNewEmail')}
                    />
                    {!doEmailsMatch && <XS color={COLORS.BRAND.ERROR}>New emails don't match.</XS>}
                </div>
                <label>
                    <Body>Password *</Body>
                </label>
                <div>
                    <Input
                        type='password'
                        placeholder='Enter password'
                        value={formData.password}
                        onChange={(e) => {
                            setHasInvalidPassword(false);
                            handleFieldChange('password')(e);
                        }}
                    />
                    {hasInvalidPassword && <XS color={COLORS.BRAND.ERROR}>Invalid password.</XS>}
                </div>
            </FormWrap>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                    marginTop: '2rem',
                }}
            >
                <OutlineBtn onClick={props.onClose}>Cancel</OutlineBtn>
                <PrimaryCTABtn
                    disabled={!(formData.newEmail + formData.reenterNewEmail).trim() || !isEmailValid || !doEmailsMatch || hasInvalidPassword || isLoading}
                    onClick={handleSave}
                >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                </PrimaryCTABtn>
            </div>
        </Modal>
    );
};

export default function EmailVerificationModal() {
    const theme = useTheme();

    const franchiseName = useSelector(s => s.franchise.name);
    // checks that this is an "email" account, as opposed to google or facebook
    const isPasswordProvider = useSelector((state) => state.user.providerId === 'password');
    const email = useSelector((state) => state.user.email);
    const emailVerified = useSelector((state) => state.user.emailVerified);

    const [isDismissed, setIsDismissed] = useState(false);
    const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);

    const hasSeenThisSession = useMemo(() => {
        return sessionStorage.getItem('hasSeenEmailVerificationModal') === 'true';
    }, []);

    const handleDismissal = () => {
        setIsDismissed(true);
        sessionStorage.setItem('hasSeenEmailVerificationModal', 'true');
    };

    const handleResendEmail = () => {
        firebase
            .auth()
            .currentUser?.sendEmailVerification()
            .then(() => {
                toast.success('Verification email sent!');
                handleDismissal();
            })
            .catch(() => {
                toast.error('Failed to send verification email');
            });
    };

    const handleUpdateEmail = async (newEmail: string, password: string) => {
        await makeRequest()
            .url(API.USER.UPDATE)
            .body({
                email: newEmail,
            })
            .post();

        await firebase.auth().signInWithEmailAndPassword(newEmail, password);

        await firebase.auth().currentUser?.sendEmailVerification();
    };

    if (isDismissed || emailVerified || hasSeenThisSession || !franchiseName) {
        return null;
    }

    return (
        <>
            {!isUpdatingEmail && (
                <Modal width='600px' title='Verify Your Email'>
                    <FaRegPaperPlane
                        size={100}
                        style={{
                            margin: '2rem auto',
                            color: theme.color.accentColor2,
                        }}
                    />
                    <div style={{ padding: '0px 1.5rem', marginBottom: '1rem' }}>
                        <Body>
                            We sent an email to {email}. Please verify your email so we can stay
                            connected.
                        </Body>
                    </div>
                    {isPasswordProvider && (
                        <Body style={{ margin: '0 auto', marginBottom: '1rem' }} small>
                            Not your email?{' '}
                            <Link
                                small
                                semibold
                                onClick={() => {
                                    setIsUpdatingEmail(true);
                                }}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                Change email.
                            </Link>
                        </Body>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '1rem',
                            marginTop: '1rem',
                        }}
                    >
                        <OutlineBtn onClick={handleResendEmail}>Resend Email</OutlineBtn>
                        <PrimaryCTABtn onClick={handleDismissal}>Dismiss</PrimaryCTABtn>
                    </div>
                </Modal>
            )}
            {isUpdatingEmail && (
                <UpdateEmailModal
                    existingEmail={email}
                    onClose={() => {
                        setIsUpdatingEmail(false);
                    }}
                    onUpdate={async (email, password) => {
                        try {
                            await handleUpdateEmail(email, password);
                            toast.success('Email updated successfully!');
                            setIsUpdatingEmail(false);
                            setIsDismissed(true)
                        } catch (e) {
                            toast.error('Failed to update email');
                        }
                    }}
                />
            )}
        </>
    );
}
