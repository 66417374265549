import styled, { css } from "styled-components";
import { hexToRgba } from "../../helpers/colors.util";

export const SelectorContainer = styled.div<{showContainer?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ${props => props.showContainer && css`
        padding: 1.5rem 0.75rem;
        border: .1rem solid ${props => props.theme.color.accentColor3};
        border-radius: 0.5rem;
    `}
`
export const SelectorItem = styled.div<{ selected: boolean, disabled?: boolean }>`
    cursor: pointer;
    border-color: ${props => props.theme.color.accentColor3};
    border-style: solid;
    border-width: 1px;
    border-radius: 0.3rem;
    padding: 1.5rem;
    display: flex;
    grid-template-columns: 0fr auto;
    gap: 1.5rem;
    background-color: ${props => props.selected ? props.theme.color.accentColor4 : props.theme.color.backgroundColor2};
    ${props => props.selected && css`
        box-shadow: 0px 0px 9px 0px ${hexToRgba(props.theme.color.accentColor3, 0.5)};
    `}

    ${props => props.disabled && css`
        background-color: ${props => hexToRgba(props.theme.color.greyAccent2, 0.25)} !important;
        border-color: ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)} !important;
        :not(.cta) {
            pointer-events: none;
        }
        .cta {
            pointer-events: auto;
        }
    `}
`