import { SerializedKey } from "../../types/apiResponses";

//* If you creating a new job make sure to add the job description here
export const SUPPORTED_JOBS = ['Pause Creatives', 'Delete Creatives', 'Accept Creatives' , 'Resume Creatives', 'Bulk Subscription Changes'] as const

type SupportedDescriptions = typeof SUPPORTED_JOBS[number]
export type JobModalType = SupportedDescriptions

export type Job = {
    id: number;
    user: SerializedKey
    description: SupportedDescriptions
    numberOfTasks: number;
    numberOfSuccess: number;
    numberOfFailure: number;
    franchise: SerializedKey
    completionTime: string
    created: string;
    updated: string;
};

export type CreateJobSchema = {
    franchise?: string
    description: SupportedDescriptions
    tasks: TaskParams[]
}

export type TaskParams = {
    url: string;
    queryParams?: Record<string, string>;
    method: string;
    body: Record<string, string | number>;
    description?: string;
};

export type TaskStateContext = {
    visible: boolean
    jobsCompleted: boolean,
    jobs: Job[]
    jobListDescription: string
    createJob: (job: CreateJobSchema) => void
    closeJobsComponent: () => void
}

export type JobTasksResponse = {
    body: string
    completionTime: string
    failureMessage: string
    created: string
    gzipped?: Uint8Array
    decodedResponse: any
    description: string
    endpoint: string
    id: number
    jobEntityKey: SerializedKey
    method: string
    queryParams: string
    reviewed: boolean
    statusCode: number
    updated: string
}

export type BTFilter = Pick<JobTasksResponse, 'statusCode' | 'failureMessage'>;

export type BTStyles = Pick<TaskStateContext, 'visible'>;
