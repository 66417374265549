import React, { ReactNode } from 'react';
import { Body, Link, Small } from "../../../styles/typography"
import { useAccess } from '../../../hooks/useAccess';
import useDynamicSetting from '../../../hooks/useDynamicSetting';
import { useFranchiseCsm } from '../../../pages/summaryPage/hooks/useFranchiseCsm';
import { makeMailToUrl } from '../../../helpers/email';
import { EULERITY_SUPPORT_EMAIL } from '../../../helpers/constants';
import { CampaignsIcon, ReputationManagementInsightsIcon, SocialPostingPageIcon } from '../../navigation/icons';
import { PATH } from '../../../constants/urlPath';
import useExperiment from '../../../hooks/useExperiment';
import { EXPERIMENTS } from '../../../helpers/experimentHelpers';

export const LOCATION_FOCUS = {
  ADVERTISING: 'ADVERTISING',
  POSTING: 'POSTING',
  REPUTATION_MANAGEMENT: 'REPUTATION_MANAGEMENT'
} as const

export type Focus = 'ADVERTISING' | 'POSTING' | 'REPUTATION_MANAGEMENT'
export type FocusOption = {
  name: string
  icon: ReactNode
  cta: ReactNode
  description: string
  value: Focus
}

export const hasAdvertisingFocus = (focuses: Focus[]) => focuses?.includes(LOCATION_FOCUS.ADVERTISING)
export const hasPostingFocus = (focuses: Focus[]) => focuses?.includes(LOCATION_FOCUS.POSTING)
export const hasRepManagementFocus = (focuses: Focus[]) => focuses?.includes(LOCATION_FOCUS.REPUTATION_MANAGEMENT)
export const isPostOnlyFocused = (focuses: Focus[]) => focuses?.includes(LOCATION_FOCUS.POSTING) && focuses.length === 1
export const isRepMgmtOnlyFocused = (focuses: Focus[]) => focuses?.includes(LOCATION_FOCUS.REPUTATION_MANAGEMENT) && focuses.length === 1

export const getRedirectFromFocuses = (focuses: Focus[]) => {
  // Prioritize Advertising
  if (hasAdvertisingFocus(focuses)) return PATH.ADVERTISING
  if (hasPostingFocus(focuses)) return PATH.SOCIAL_POSTING
  if (hasRepManagementFocus(focuses)) return PATH.REPUTATION_MANAGEMENT
  return PATH.CAMPAIGNS
}

export const useFocusOptions = () => {
    const { canManageFranchise } = useAccess()
    const { socialPostingFeatureEnabled, reputationManagementFeatureEnabled } = useDynamicSetting()
    const isFranchisorOrAdmin = canManageFranchise()
    const csmRequest = useFranchiseCsm()
    const clickHandler = (e?: React.MouseEvent<HTMLAnchorElement>) => e?.stopPropagation()
    const reputationListingsCopy = useExperiment(EXPERIMENTS.REPUTATION_LISTINGS_COPY)

    const focusOptions = [
      {   //Advertising is by default available when the franchise subscribes
          name: 'Advertising',
          icon: <CampaignsIcon size='20px' $active/>,
          description: 'Create and deploy ads across various platforms.',
          value: LOCATION_FOCUS.ADVERTISING,
      },
      {
          name: 'Posting',
          icon: <SocialPostingPageIcon $active size='20px' strokeWidth='4'/>,
          cta: isFranchisorOrAdmin && !socialPostingFeatureEnabled ? <Link regular small className='cta' onClick={clickHandler} href={makeMailToUrl({
            to:
              csmRequest.data ??
              EULERITY_SUPPORT_EMAIL,
            subject: 'Activate Social Posting',
          })}>Learn More</Link> : null,
          description: 'Create and deploy organic posts across your preferred social platforms.',
          value: LOCATION_FOCUS.POSTING,
          disabled: !socialPostingFeatureEnabled
      },
      {
          name: reputationListingsCopy.decide('Reputation & Listings', 'Reputation Management'),
          icon: <ReputationManagementInsightsIcon size='20px' $active/>,
          cta: isFranchisorOrAdmin && !reputationManagementFeatureEnabled ? <Link regular small className='cta' onClick={clickHandler} href={makeMailToUrl({
            to:
              csmRequest.data ??
              EULERITY_SUPPORT_EMAIL,
            subject: `Activate ${reputationListingsCopy.decide('Reputation & Listings', 'Reputation Management')}`,
          })}>Learn More</Link> : null,
          description: 'Build your social reputation by managing your social platform interactions and insights.',
          value: LOCATION_FOCUS.REPUTATION_MANAGEMENT,
          disabled: !reputationManagementFeatureEnabled
      }
  ]
  return focusOptions
}

const FocusContentLayout = ({ item }: { item: FocusOption }) => {

  return (
    <div style={{width: '100%'}}>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.5rem'
    }}>
      <Body semibold style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        {item.name} {item.icon}
      </Body>
      {item.cta}
    </div>
    <Small disabled>{item.description}</Small>
  </div>
  )
}

const FocusLayout = {
  layout: FocusContentLayout,
  idField: "name"
}

export { FocusLayout }