import React, {ComponentType} from "react";
import CreativesJobModal from "./CreativesJobModal";
import { JobModalType, JobTasksResponse } from "../../types";
import BulkSubscriptionJobModal from "../../../../pages/bulkSettings/components/BulkSubscriptionJobModal";

//* Based on the description that you provide when creating a job, you can create a custom modal. All modals will inherit the decoded job response.


const JobModalManager = ({ jobResponse, onClose,  type }: {jobResponse: JobTasksResponse[], onClose: () => void, type: JobModalType | null}) => {
  let ModalComponent: ComponentType<{ jobResponse: JobTasksResponse[], onClose: () => void, type: JobModalType | null }> | null = null;

  switch (type) {
    case 'Pause Creatives':
    case 'Delete Creatives':
    case 'Accept Creatives':
    case 'Resume Creatives':
      ModalComponent = CreativesJobModal;
      break;
    case 'Bulk Subscription Changes':
      ModalComponent = BulkSubscriptionJobModal;
      break;
  }

  return (
    <>
      {ModalComponent && <ModalComponent jobResponse={jobResponse} onClose={onClose} type={type}/>}
    </>
  );
};

export default JobModalManager;