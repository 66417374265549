import React from 'react'
import CloneItem from './CloneItem';
import { StyledCloneList } from './styles';
const ClonesList = ({ clones, selectedClones, bulkEditAction, selectClone, deselectClone, creativeType }) => {
    const selectableList = clones.filter(c => !c.isUnselectable);
    const unselectableList = clones.filter(c => c.isUnselectable)

    return (
        <StyledCloneList>
            {
                selectableList.map((c) => {
                    let isSelected = false;
                    let isEdited = false;
                    if (selectedClones.length){
                        let item = selectedClones.find(clone => clone.id === c.id && clone.locationData.id === c.locationData.id);
                        if (item) isSelected=true;
                    }
                    return <CloneItem  key={c.id} clone={c} bulkEditAction={bulkEditAction} isSelected={isSelected} isEdited={isEdited} selectClone={selectClone} deselectClone={deselectClone} creativeType={creativeType}/>
                 })
            }
            {
                unselectableList.map(c => <CloneItem  key={c.id} clone={c} bulkEditAction={bulkEditAction} selectClone={selectClone} deselectClone={deselectClone} creativeType={creativeType}/>)
            }
        </StyledCloneList>
    )
}

export default ClonesList;