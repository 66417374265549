// This hook is responsible for generating preview text to be used in Ad/Post Previews
// It can either:
// 1) Take in a creative that is already made
// 2) Take in a JSON object such as when building a creative and wanting to see a preview

import { useEffect, useState } from "react"
import { API } from "../../../constants/api"
import { getCreativeLocationID, getCreativeUserID } from "../../../helpers/creative.util"
import { makeRequest } from "../../../helpers/make-request"
import { toast } from "react-toastify"
import { ERROR } from "../../../constants/text"

const usePreviewText = (creative) => {
    const [previewText, setPreviewText] = useState(null)

    // Generate for Existing Creative
    useEffect(() => {
      if (!creative) return

      (async() => {
        try {
          const generatedText = await generatePreviewFromCreative(creative)
          setPreviewText(generatedText)
        } catch (error) {
          toast.error(ERROR.TRY_AGAIN)
        }
      })()

    }, [creative])


    // Generate from Existing Creative
    const generatePreviewFromCreative = async(creative) => {
     return  makeRequest()
          .url(API.CREATIVE.BUILD_PREVIEW_TEXT)
          .param('uid', getCreativeUserID(creative))
          .param('lid', getCreativeLocationID(creative))
          .param(creative.qsParam, creative.id)
          .retry(3)
          .post()
  };

  // Generate From JSON
  const generatePreviewFromJSON = ({ location, json, callback }) => {
      makeRequest()
          .url(API.CREATIVE.BUILD_PREVIEW_TEXT)
          .param('uid', location?.user?.raw?.name)
          .param('lid', location?.id)
          .body(json)
          .retry(3)
          .post()
          .then((generatedText) => {
              setPreviewText(generatedText);
              callback?.();
          })
          .catch(() => console.error('Something went wrong.'));
  };

  return {
    previewText,
    generatePreviewFromJSON
  }
}

export default usePreviewText