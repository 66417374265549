import React from 'react'
import { StyledModalContentV2 } from '../styles'
import { ModalContentProps } from '../types'

//* Usage: Use this wrapper component inside the ModalWrapper
//* Wrap this around the contents of the modal
//* Handles the overflowY scroll

const ModalContent = ({
  styles,
  onScroll: handleScroll,
  children
} : ModalContentProps ) => {
  return (
    <StyledModalContentV2 style={styles} onScroll={handleScroll}>
      {children}
    </StyledModalContentV2>
  )
}

export default ModalContent