import {
    InsightReportBuilderFormat,
    ReportRowInput
} from '../reportBuilder'

export type ReportBuilderFormatPreset = {
    id: string,
    type?: 'aggregate' | 'expand',
    field?: keyof ReportRowInput
    format: Readonly<InsightReportBuilderFormat>
    displayName?: string
}

const aggregateDate = {
    id: 'aggregate-date',
    type: 'aggregate',
    field: 'date',
    displayName: 'Total',
    format: {
        partialColumnFilters: [(col) => col.id !== 'date'],
        partialRowIndexGenerators: [() => ""],
    }
} satisfies ReportBuilderFormatPreset

const expandDate = {
    id: 'expand-date',
    type: 'expand',
    field: 'date',
    displayName: 'Daily',
    format: {
        partialColumnFilters: [],
        partialRowIndexGenerators: [(input) => input.date.toISOString()],
    }
} satisfies ReportBuilderFormatPreset

const expandLocation = {
    id: 'expand-location',
    type: 'expand',
    field: 'location',
    displayName: 'Campaign',
    format: {
        partialColumnFilters: [(col) => col.id !== 'business'],
        partialRowIndexGenerators: [(input) => `${input.location.user.raw.name}-${input.location.id}`],
    }
} satisfies ReportBuilderFormatPreset

const aggregateLocation = {
    id: 'aggregate-location',
    type: 'aggregate',
    field: 'location',
    displayName: 'Business',
    format: {
        partialColumnFilters: [(col) => col.id !== 'campaign'],
        partialRowIndexGenerators: [(input) => {
            if ('primaryLocation' in input.location) {
                return input.location.primaryLocation.websafe
            } else {
                return input.location.websafe
            }
        }],
    }
} satisfies ReportBuilderFormatPreset

const businessAndCampaignsSettingsPreset = {
    id: 'business-and-campaigns-settings',
    displayName: 'Business and Campaigns Settings',
    format: {
        partialColumnFilters: [(col) => col.id !== 'date'],
        partialRowIndexGenerators: [(input) => `${input.location.user.raw.name}-${input.location.id}`],
    }
} satisfies ReportBuilderFormatPreset

const distributionPreset = {
    id: 'distribution',
    displayName: 'Distribution',
    format: {
        partialColumnFilters: [(col) => col.id !== 'date'],
        partialRowIndexGenerators: [(input) => `${input.location.user.raw.name}-${input.location.id}`],
    }
} satisfies ReportBuilderFormatPreset

const platformConnectionsPreset = {
    id: 'platform-connections',
    displayName: 'Platform Connections',
    format: {
        partialColumnFilters: [(col) => col.id !== 'date'],
        partialRowIndexGenerators: [(input) => `${input.location.user.raw.name}-${input.location.id}`],
    }
} satisfies ReportBuilderFormatPreset

const reportBuilderFormatPresets = {
    aggregateDate,
    expandDate,
    expandLocation,
    aggregateLocation,
    businessAndCampaignsSettingsPreset,
    distributionPreset,
    platformConnectionsPreset
} as const

export default reportBuilderFormatPresets