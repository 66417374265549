import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Link } from "react-router-dom";

// Components
import Login from "./components/login/Login.js";
import Sidebar from "./components/navigation/SidebarV2";
import FranchiseSelector from "./features/franchiseSelector/FranchiseSelector";
import Overlay from './components/overlay'
import Banner, { useBanner } from "./components/banner/Banner";
import IdleWrapper from "./components/idleWrapper/IdleWrapper";
import IdleModal from "./components/idleWrapper/IdleModal";
import ThemeWrapper from "./features/themeProvider/ThemeWrapper";
import { CampaignCreationRedirect } from "./features/locations/create/components/CampaignCreationRedirect";
import "react-datepicker/dist/react-datepicker.css"

// Routes
import Routes from "./routes/Routes";

// Redux Store
import { useSelector } from "react-redux";

// Helpers
import { isMember, canSelectFranchise } from './helpers/permissionsHelper';

// Packages
import { Slide } from "react-toastify";

// Styles
import "react-toastify/dist/ReactToastify.css";
import { StyledHeader, MainContainer, AppLayout, ContentDisplay, disabledAppStyles } from "./styles";
import { GlobalStyles } from './styles/globalStyles';
import { StyledToast } from './styles/toastStyles';
import DeletedAccountPopup from "./components/deletedAccountPopup/deletedAccountPopup";
import SelectFranchiseModal from "./features/franchiseSelector/SelectFranchiseModal";
import { H1 } from "./styles/typography";
import { BackgroundJobsContextProvider } from "./features/backgroundJobs";

import EmailVerificationModal from "./features/emailVerification/EmailVerificationModal";
import JobIndicator from "./features/backgroundJobs/components/JobIndicator";
import Alert from "./components/alert/Alert";
import { ALERT } from "./components/alert/util";
import { BackgroundBtn } from "./styles/buttons";
import { PAYMENTS } from "./constants/urlPath";
import { SubscriptionStates } from "./types/subscription";
import BrandThemeLiveUpdater from "./features/BrandThemeLiveBuilder";
import useInitializeFranchise from "./hooks/useInitializeFranchise";
import { getToastIcon } from "./components/reusableUI/components/ToastIcon";
import { TooltipProvider } from "./features/tooltip/TooltipProvider";
import { Tooltip } from "./features/tooltip/Tooltip";
import useExperiment from "./hooks/useExperiment";
import { EXPERIMENTS } from "./helpers/experimentHelpers";

function App() {
  const user = useSelector(state => state.user)
  const selectedFranchise = useSelector(state => state.franchiseList.selectedFranchise)
  const franchiseName = useSelector(state => state.franchise.displayName)
  const franchiseSubState = useSelector(state => state.franchise.subscription?.state)
  const { isAuthenticated, accessLevel } = user
  const [isIdle, setIsIdle] = useState(false)
  const { showTosBanner } = useBanner();
  const brandThemeToolExp = useExperiment(EXPERIMENTS.BRAND_THEME_TOOL)


  //Initialize Franchise State
  useInitializeFranchise()

  return (
    <Router>
      <IdleWrapper isIdle={isIdle} setIsIdle={setIsIdle}>
      {!isAuthenticated ? (
        <Login />
      ) : (
        <>
          <Overlay />
          <CampaignCreationRedirect />
          <ThemeWrapper>
          <TooltipProvider>
          <BackgroundJobsContextProvider>
            <EmailVerificationModal />
            { user.isMarkedForDeletion && <DeletedAccountPopup /> }
            <GlobalStyles />
            <AppLayout>
              <Sidebar isDisabled={showTosBanner}/>
              <MainContainer>
                <Banner />
                <ContentDisplay style={showTosBanner ? disabledAppStyles : {}}>
                  <StyledHeader>
                    {canSelectFranchise(accessLevel) && ( selectedFranchise ? <FranchiseSelector /> : <SelectFranchiseModal/>)}
                  </StyledHeader>
                  <H1 center>{isMember(accessLevel) ? "Command Center" : `${franchiseName} Command Center`}</H1>
                  {
                    franchiseSubState === SubscriptionStates.DECLINED &&
                    <Alert
                      type={ALERT.ALERT.TYPE}
                      title={"Credit card declined for your platform subscription."}
                      message={"Please go to Payments under Corporate Settings to update your payment method"}
                    >
                      <Link to={PAYMENTS.PAYMENT} style={{marginLeft: "auto"}}>
                        <BackgroundBtn>
                            Go to Corporate Settings
                        </BackgroundBtn>
                      </Link>
                    </Alert>
                  }

                  <div className='display'>
                    <Routes />
                  </div>

                  <StyledToast
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    transition={Slide}
                    icon={({ type }) => getToastIcon(type)}
                    pauseOnHover />
                </ContentDisplay>
              </MainContainer>
            </AppLayout>
              {
                isIdle && <IdleModal setIsIdle={setIsIdle}/>
              }
              {
                brandThemeToolExp.ifActive (
                  <BrandThemeLiveUpdater />
                )
              }
             <JobIndicator />
             <Tooltip />
            </BackgroundJobsContextProvider>
            </TooltipProvider>
          </ThemeWrapper>
        </>
        )}
      </IdleWrapper>
    </Router>

  );
}

export default App
