import { useEffect, useMemo, useRef, useState } from "react"
import { Job, TaskStateContext } from "./types"
import { makeRequest } from "../../helpers/make-request";
import { API } from "../../constants/api";
import { CreateJobSchema } from "./types"

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getActiveJobsList } from "./utils";

//* Use useJob() as a hook instead of useBackgroundTasks
const useBackgroundTasks = () => {
    const [jobs, setJobs] = useState<Job[] | null>(null);
    const [visible, setVisibility] = useState<boolean>(false)

    const [intentionalCloseJobIndicator, setIntentionalCloseJobIndicator] = useState<boolean>(false)
    const [newJobId, setNewJobId] = useState<number>(0);
    const listJobsErrorCount = useRef<number>(0)
    const franchise = useSelector(s => s.franchise.name)

    type JobCreated = Pick<Job, 'created'>

    //? If the API.JOBS.LIST_ACTIVE_JOBS fails once, we shouldn't stop the interval.
    //? We should have an error count, which I've currently set at 5. So, if we have 5 consecutive API call failures, we should stop the interval.
    const DEFAULT_ERROR_COUNT = 5

    useEffect(() => setIntentionalCloseJobIndicator(false), [franchise])

    useEffect(() => {
        if(!franchise) return

        const intervalId = setInterval(async () => {
            try {
                let currentJobs = await getActiveJobsList(franchise)
                listJobsErrorCount.current = 0

                if(!currentJobs.length) {
                    clearInterval(intervalId)
                    setVisibility(false)
                    setJobs(null)
                    return
                }

                if(currentJobs.length && !intentionalCloseJobIndicator && !visible) {
                    setVisibility(true)
                }

                if (currentJobs?.every(job => Boolean(job.completionTime))) {
                    clearInterval(intervalId)
                }
                setJobs(currentJobs);

            } catch (error) {
                if ((++listJobsErrorCount.current) === DEFAULT_ERROR_COUNT) {
                    clearInterval(intervalId);

                    toast.error('The background job has encountered an issue. Please try again.');
                    return
                }
            }
        }, 2000);

        return () => {
            clearInterval(intervalId)
        };
    }, [franchise, visible, newJobId, intentionalCloseJobIndicator]);

    const createJob = async (job: CreateJobSchema) => {
        try {
            const jobCreated = await makeRequest().url(API.JOBS.CREATE)
                .body({franchise, ...job})
                .post();
            listJobsErrorCount.current = 0
            setIntentionalCloseJobIndicator(false)
            setNewJobId(jobCreated.id)
        } catch (error) {
            toast.error('Something went wrong creating a job')
        }

        try {
            const currentJobs = await getActiveJobsList(franchise)
            setJobs(currentJobs);
            if(currentJobs.length) setVisibility(true)
        } catch (error) {
            toast.error('Something went wrong getting jobs')
        }
    }

    const jobsCompleted = useMemo(() => {
        return jobs?.every(job => Boolean(job.completionTime)) || false;
    }, [jobs]);

    const activeJobCount = useMemo(() => {
        return jobs?.filter(job => (job.numberOfSuccess + job.numberOfFailure) < job.numberOfTasks).length ?? 0
    }, [jobs]);

    const taskPlurality = activeJobCount === 1 ? 'task' : 'tasks'
    const jobListDescription = jobsCompleted
        ? `${jobs?.length}/${jobs?.length} ${taskPlurality} completed`
        : `${activeJobCount} ${taskPlurality} in progress`


    const state: TaskStateContext = {
        visible,
        jobs: jobs?.sort((a: JobCreated, b: JobCreated) => new Date(a.created).getTime() - new Date(b.created).getTime()) || [],
        jobsCompleted,
        jobListDescription,
        createJob,
        closeJobsComponent: () => {
            setVisibility(false)
            setIntentionalCloseJobIndicator(true)
        },
    }
    return state
}

export default useBackgroundTasks;
