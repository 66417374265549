import styled from 'styled-components';
import { decideTextColor } from '../../../../helpers/colors.util';
import { BodyCss } from '../../../../styles/typography';

export const Wrap = styled.div`
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    background-color: ${props => props.theme.color.backgroundColor2};
    overflow-x: hidden;
    // This is so we dont get jumpy UI when the scrollbar appears
    overflow-y: scroll;

    @media screen and (min-width: 2000px) {
        min-width: 450px;
    }
`;

export const DatePickerWrapper = styled.div`
    width: ${props => props.fullWidth ? '100% !important' : ''};
    display: flex;
    align-items: center;

    svg {
        margin-left: -25px;
        color: ${props => props.theme.color.accentColor2};
    }

    .react-datepicker-wrapper {
        width: ${props => props.fullWidth ? '100% !important' : ''};
    }

    .react-datepicker__input-container input {
        background: transparent;
        color:${props => props.theme.color.textColor1};
        box-shadow: inset 0px 0px 4px rgba(37, 109, 254, 0.05), inset 0px 0px 2px rgba(16, 16, 16, 0.1);
        border: 1px solid ${props => props.theme.color.accentColor3};
        border-radius: 9px;
        ${BodyCss}
    }
    .react-datepicker__header {
        background-color: ${(props) => props.theme.color.accentColor2};
        color: ${(props) => decideTextColor(props.theme.color.accentColor2)} !important;
        border-bottom-color: ${(props) => props.theme.color.greyAccent1};
    }
    .react-datepicker {
        background-color: ${(props) => props.theme.color.backgroundColor2};
        border-color: ${(props) => props.theme.color.greyAccent1};

    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        left: 42px !important;
        border-top: none;
        border-bottom-color: ${(props) => props.theme.color.greyAccent1} !important; // here the triangle is next to the header, so make it the same color
        ::before {
            border-bottom-color: ${(props) => props.theme.color.greyAccent1} !important;
        }
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
        left: 42px !important;
        border-bottom: none;
        border-top-color: ${(props) => props.theme.color.greyAccent1} !important;
        ::before {
            border-top-color: ${(props) => props.theme.color.greyAccent1} !important;
        }
    }
    .react-datepicker__navigation--next {
        border-left-color: ${(props) => props.theme.color.backgroundColor2} !important;
    }
    .react-datepicker__navigation--previous {
        border-right-color: ${(props) => props.theme.color.backgroundColor2} !important;
    }
    .react-datepicker__day, .react-datepicker__time-list-item {
        color: ${(props) => decideTextColor(props.theme.color.backgroundColor2)};
        :hover {
            background-color: ${(props) => props.theme.color.greyAccent2} !important;
            color: ${(props) => decideTextColor(props.theme.color.greyAccent2)};
        }
    }
    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__time-list-item--selected, .react-datepicker__time-list-item--keyboard-selected {
        background-color: ${(props) => props.theme.color.accentColor2} !important;
        color: ${(props) => decideTextColor(props.theme.color.accentColor2)} !important;
    }
    .react-datepicker__day-name, .react-datepicker__current-month {
        color: ${(props) => decideTextColor(props.theme.color.accentColor2)};
    }
    .react-datepicker__time-container{
        border-color: ${(props) => props.theme.color.greyAccent2};
    }
    .react-datepicker-time__header {
        background-color: ${(props) => props.theme.color.accentColor2};
        color: ${(props) => decideTextColor(props.theme.color.accentColor2)} !important;
        border-bottom-color: ${(props) => props.theme.color.greyAccent2};
    }
    .react-datepicker__time-list {
        background-color: ${(props) => props.theme.color.backgroundColor2};
        color: ${(props) => props.theme.color.textColor1};
        border-right-width: 7px;
        border-color: ${(props) => props.theme.color.greyAccent2};
            li:hover {
        }
    }
    .react-datepicker__close-icon {
        ::after {
            background-color: ${props => props.theme.color.accentColor2};
            color: ${props => props.theme.color.backgroundColor2};
        }
    }
`
