import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyledStepLocations } from '../styles';
import { setLastEdited } from '../../../../actions/buildActions';
import { getCreativeClones } from '../../../../helpers/bulkEdits';
import { useDispatch } from 'react-redux';
import { isAdEntity } from '../../../../helpers/creative.util';
import useInitialSelection from '../../../../hooks/useInitialSelection';
import { H2 } from '../../../../styles/typography';
import { DefaultValueWarning } from '../../../../features/variables';
import useInitializeOptions from '../../../../features/advancedFilter/hooks/useInitializeOptions';
import { getRepoList } from '../../../../actions/repoActions';
import { useAccess } from '../../../../hooks/useAccess';
import EntityResultsSelector from '../../../../features/entityResultsSelector/EntityResultsSelector';
import { COLORS } from '../../../../constants/colors';

const CreativeStepLocations = ({ intendedCreative, context, filters = {} }) => {
    const _D = useDispatch()
    const { canManageFranchise } = useAccess()
    const { formData, updateFormData } = React.useContext(context)

    const isManager = canManageFranchise();

    const isAd = isAdEntity(intendedCreative)

    const franchiseName = useSelector(state => state.franchise.name)
    const lastEdited = useSelector(state => state.build.lastEdited)

    const isTemplateFork = lastEdited?.repoName
    const dispatch = useDispatch();
    const selectedRepoKey = useSelector((state) => state.build.selectedRepoKey);
    const [initLocations, setInitLocations] = useState(null)
    const [repos, setRepos] = useState([])
    const { getInitialSelection } = useInitialSelection(filters)

    useEffect(() => {

        // * Case 1 - Not Editing Or is a Template Creative
        const isNotEditing = lastEdited?.kind !== intendedCreative?.kind || lastEdited?.isMagic !== intendedCreative?.isMagic //Either the kinds or isMagic fields dont match
        if ((isNotEditing && !selectedRepoKey) || isTemplateFork) {
            return setInitLocations([]) // reset initial selection
        }

        // * Case 2 - Pre-Selecting RepoId Location
        if (selectedRepoKey) {
            const initialSelection = getInitialSelection([selectedRepoKey]);
            return setInitLocations(initialSelection)
        }

        // * Case 3 - Editing
        // If we're editing a creative but we don't know the locations to pre-populate with, find the clones
        if (!lastEdited.targetLocations) {
            getCreativeClones({
                creative: lastEdited,
                franchiseName,
                callback: response => {
                    const cloneLocationKeys = response.map(c => `${c.location.raw.parentKey.name}_${c.location.raw.id}`)
                    dispatch(
                        setLastEdited({
                            ...lastEdited,
                            targetLocations: cloneLocationKeys
                        })
                    )
                }
            })
        } else if (lastEdited.targetLocations?.length) {
            const initialSelection = getInitialSelection(lastEdited.targetLocations);
            setInitLocations(initialSelection);
            onSelect(initialSelection);
        }
    }, [lastEdited, selectedRepoKey, intendedCreative.kind, intendedCreative.isMagic]);

    const textsWithVariables = useMemo(() => {
        const texts = [];
        if (!formData) return texts;

        texts.push(formData.extendedText);

        return texts.filter((x) => !!x);
    }, [formData]);

    const onSelect = locationOptions => updateFormData('selectedLocations', locationOptions)


    const {locationOptions, repoOptions, defaultLocationOptions}  = useInitializeOptions(filters)

    useEffect(() => {
        if (isManager) _D(getRepoList(franchiseName, (resp) => setRepos(resp)));
    }, [isManager, franchiseName])

    const options = useMemo(() => {
        let repoList = []
        if (isManager) repoList = [...repoOptions(repos)]
        return [...locationOptions, ...defaultLocationOptions(), ...repoList]
    }, [repos, isManager, locationOptions, defaultLocationOptions, repoOptions])

    return (
        <StyledStepLocations>
            <H2 color={COLORS.BRAND.TEXT_COLOR_1} style={{marginBottom: '2rem'}}>Select Campaigns</H2>
            <EntityResultsSelector
                initialLocations={initLocations}
                onSelect={onSelect}
                isAd={isAd}
                options={options}
                colorScheme={isAd ? 'ad' : 'post'}
            />
            <br />
            <DefaultValueWarning locationsToCheck={formData?.selectedLocations} textsWithVariables={textsWithVariables} />
        </StyledStepLocations>
    );
};

export default CreativeStepLocations;
