import {
    LOAD_LOCATION_SMS, REMOVE_SMS_MEDIA, UPDATE_SMS_HISTORY_SEARCH_VAL,
    UPDATE_SMS_PREVIEW, UPLOAD_SMS_MEDIA, CLEAR_SMS_TAB
} from "./types";
import {getLocationByKey, getLocationKeyRaw} from "../helpers";
import {toast} from "react-toastify";
import { API } from "../constants/api";

export const clearSmsTab = () => dispatch => {
    dispatch({
        type: CLEAR_SMS_TAB
    })
}

export const loadLocationSms = (location) => (dispatch) => {
    console.log("Loading SMS messages")
    if (!location || !location.audiencesLoaded) return;
    let uid = location.user.raw.name;
    let lid = location.id;
    let creatives = [];
    window.eulerity.makeBatchedApiCall({
        url: '/api/creative/list?uid=' + uid + '&lid=' + lid + '&filter=sid',
        dataCallback: function(resp) {
            creatives = creatives.concat(resp);
        },
        doneCallback: function() {
            location.smsCreatives = creatives;
            location.smsCreativesLoaded = true;
            dispatch({
                type: LOAD_LOCATION_SMS,
                payload: location
            });
        }});
}

export const createSmsCreative = ({fields, callback, defaultLoc, franchiseName, allLocations}) => dispatch => {
    let uid = defaultLoc.user.raw.name;
    let lid = defaultLoc.id;
    let url = `${API.CREATIVE.CREATE_SMS}?franchise=${encodeURIComponent(franchiseName)}&uid=${uid}&lid=${lid}`;
    window.eulerity.makeApiCall(url, 'POST', fields, (resp) => {
        callback?.(resp);
        resp.creatives.forEach(creative => {
            let location = getLocationByKey(allLocations, getLocationKeyRaw(creative.location.raw));
            dispatch(loadLocationSms(location));
        })
    })
}

export const deleteSmsCreative = ({franchiseName, creative, allLocations}) => dispatch => {
    window.eulerity.makeApiCall(`${API.CREATIVE.DELETE}?franchise=${encodeURIComponent(franchiseName)}&location=${creative.location.websafe}&${creative.qsParam}=${creative.id}`, 'POST', null, function () {
        toast.success(`Deleted SMS creative.`)
        let location = getLocationByKey(allLocations, getLocationKeyRaw(creative.location.raw));
        dispatch(loadLocationSms(location))
    }, {  //Error handling
        1000: error => {
            toast.error('Failed to delete: ' + error.response);
        }
    });
}

export const setSmsHistorySearchVal = (searchVal) => dispatch => {
    dispatch({
        type: UPDATE_SMS_HISTORY_SEARCH_VAL,
        payload: searchVal
    })
}

export const uploadSmsMedia = ({blobKey, callback}) => dispatch => {
    dispatch({
        type: UPLOAD_SMS_MEDIA,
        payload: {
            blobKey: blobKey,
        }
    })
    callback();
}

export const removeSmsMedia = () => dispatch => {
    dispatch({
        type: REMOVE_SMS_MEDIA
    })
}

export const updateSmsPreview = (previewKey) => dispatch => {
    dispatch({
        type: UPDATE_SMS_PREVIEW,
        payload: previewKey
    })
}
