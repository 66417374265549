import { API } from '../constants/api';
import { batchifyRequest, makeRequest } from '../helpers/make-request';
import { getUserAccessLevel, USER_ACCESS } from '../helpers/user.util';
import { LOAD_USER, CLEAR_USER, ACKNOWLEDGE_TOS, TOGGLE_EXPERIMENT, LOAD_NETWORK_LOGIN_CONNECTIONS, SET_NETWORK_LOGIN_LOAD_STATUS } from './types';
import { loadBrandTheme } from './UIActions';

export const loadUser = params => dispatch => {
    const { user, notFoundCallback } = params;
    try {
        window.eulerity.makeApiCall('/api/user/get', 'GET', null, response => {
            dispatch({
                type: LOAD_USER,
                payload: response
            })
            const { adminPermissions, managedFranchises, theme } = response
            let accessLevel = getUserAccessLevel({ adminPermissions, managedFranchises })
            if(accessLevel === USER_ACCESS.MEMBER && theme) dispatch(loadBrandTheme(theme))
        },
        {
            404: () => {
                notFoundCallback?.(user);
            }
        });
    } catch (err) {
        console.error('Unable to load user');
    }
}

export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    })
}

export const acknowledgeTOS = (uid) => dispatch => {
    try {
        if (uid) {
            window.eulerity.makeApiCall(`/api/user/acceptTos?uid=${uid}`, 'POST', null, response => {
                dispatch({
                    type: ACKNOWLEDGE_TOS,
                    payload: response.tosAccepted
                })
            },
            {
                403: () => {
                    console.error("Unable to acknowledge Terms of Service");
                }
            })
        }
    }
    catch {
        console.error("Unable to acknowledge Terms of Service");
    }
}

export const toggleExperiment = exp => dispatch => {
    dispatch({
        type: TOGGLE_EXPERIMENT,
        payload: exp
    })
}

export const setNetworkLoginConnections = (networkLoginConnections) => dispatch => {
    dispatch({
        type: LOAD_NETWORK_LOGIN_CONNECTIONS,
        payload: networkLoginConnections
    })
}

export const setNetworkLoginLoadStatus = (status) => dispatch => {
    dispatch({
        type: SET_NETWORK_LOGIN_LOAD_STATUS,
        payload: status
    })
}

export const loadNetworkLoginConnections = () => (dispatch, getState) => {
    if (getState().user.networkLoginLoadStatus !== 'unloaded') return;

    dispatch(setNetworkLoginLoadStatus('loading'));

    batchifyRequest(makeRequest().url(API.USER.LIST_NETWORK_LOGIN_CONNECTIONS))
        .run('GET')
        .then((data) => {
            dispatch(setNetworkLoginConnections(data));
        })
        .finally(() => {
            dispatch(setNetworkLoginLoadStatus('loaded'));
        });
};
