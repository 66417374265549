import styled from 'styled-components';

export const InputWrap = styled.div`
    display: flex;
    align-items: center;
    border: solid 1px ${props => props.theme.color.greyAccent1};;
    border-radius: 6px;
    padding: 13px 12px;
    ${props => props.fullWidth ? "width: 100%;" : ""};

    input {
        font-family: ${props => props.theme.font?.body?.family};
        font-size: 14px;
        font-weight: normal;
        border: none;
        outline: none;
        padding: 0px;

        background-color: transparent;
        color: ${props => props.readOnly ? props.theme.color.greyAccent1 : props.theme.color.textColor1};
        ${props => props.fullWidth ? "width: 100%;" : ""};
    }
`;

export const InputIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};

    svg {
        font-size: 16px;
        color: ${(props) => props.theme.color.accentColor2};
    }
`;
