import React from "react";
import {
  PreviewMessage,
  SocialWrap,
  SocialPreviewCopyHeader,
  InstagramContentWrap,
} from "./styles";
import { FaEllipsisH } from "react-icons/fa";
import instagramActions from "../../assets/images/instagram-actions.png";
import iGIcon from "../../assets/images/instagram-logo.png";
import { BsChevronRight } from 'react-icons/bs';
import { getPreviewImageUrl, isAdEntity, isPostEntity } from "../../helpers/creative.util";
import { H2, XS } from "../../styles/typography";
import useVersionData from "../../hooks/useVersionData";
import  StringUtil  from "../../helpers/string.util";
import { PREVIEW_PLACEHOLDER_TEXT } from "../../helpers/constants";
import { DisclaimerContainer } from "../../pages/build/modals/styles";
import NetworkIcon, { NETWORK_ICON_NETWORKS } from "../../features/creatives/CreativeModalV2/NetworkIcon";

const InstagramPreview = ({ creative, previewText, hasInstagramCollaborators }) => {
  const { preview, youTubeId, cta } = creative;
  const {
    instagramDescription,
    facebookDescription,
  } = previewText;

  const { defaultCta } = useVersionData()
  const showDisclaimer = isPostEntity(creative) && (creative?.instagramCollaborators?.length > 0 || hasInstagramCollaborators)

  return (
    <SocialWrap>
      <SocialPreviewCopyHeader>
        <img src={iGIcon} alt='ig-icon' />
        <H2>Instagram Preview</H2>
      </SocialPreviewCopyHeader>
      <InstagramContentWrap>
        <div className="top-section">
          <div className="logo-container">
            <div className="placeholder-img" />
          </div>
          <div className="page-info">
            <strong>{PREVIEW_PLACEHOLDER_TEXT.instagramAccountName}</strong>
          </div>
          <div className="actions">
            <FaEllipsisH size={"10px"} style={{ "margin": "auto" }} />
          </div>
        </div>
        <img className="ig-img-preview" alt="ig-img-preview" src={getPreviewImageUrl({preview, youTubeId})} />
        {
          isAdEntity(creative) &&
          <div>
            <div className="learn-more">{StringUtil.displaySnakeStr(cta || defaultCta)}<BsChevronRight size={12}/></div>
            <div className="seperator" />
          </div>
        }
        <img style={{padding: '0 0.3rem'}} alt="ig-actions" src={instagramActions} />
        <div className="text-content">
          <strong>{PREVIEW_PLACEHOLDER_TEXT.instagramAccountName}</strong>
          {
            // Only try to use instagramDescription if its a post
            isPostEntity(creative) ?
            <span style={{ fontWeight: 400 }}> {instagramDescription.length > 510 ? StringUtil.truncateString(instagramDescription, 509) : facebookDescription || ""}</span>
            :
            <span style={{ fontWeight: 400 }}> {facebookDescription}</span>
          }
        </div>
      </InstagramContentWrap>
      {
        isAdEntity(creative) &&
        <PreviewMessage>
          <img src={iGIcon} alt="ig-icon"/>
          <p style={{ fontSize: '.45rem' }}>Your ad will be reformatted for Instagram Story ads & more...</p>
        </PreviewMessage>
      }
      {
        showDisclaimer && (
          <DisclaimerContainer id="test-instagram-preview">
            <NetworkIcon network={NETWORK_ICON_NETWORKS.INSTAGRAM}/>
            <XS semibold>Post collaborators are not featured in previews.</XS>
          </DisclaimerContainer>
        )
      }
    </SocialWrap>
  );
};

export default InstagramPreview;
