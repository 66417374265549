import { DateHelper } from "../../../../../../../helpers";
import { nDaysAgo } from "../../../../../../../helpers/date.util";
import { isPrimaryLocation } from "../../../../../../../types/location";
import columnBuilder from "../../../columnBuilder";
import { userColumnLoader } from "../../../loaders";
import { arrayToString } from "../targeting";

const generalReportCategory = columnBuilder({
	category: 'business-and-campaigns-settings',
	subCategory: 'General',
})

const generalColumns = [
	generalReportCategory
		.name('Campaign Type')
		.id('campaign-type')
		.makeRow((data) => {
			return isPrimaryLocation(data.input.location) ? 'Primary' : 'Secondary'
		}),
	generalReportCategory
		.name('Default Landing Page')
		.id('default-landing-page')
		.makeRow((data) => {
			return data.input.location.website
		}),
	generalReportCategory
		.name('Labels')
		.id('labels')
		.makeRow((data) => {
			return data.input.location.labels
		})
		.toString(arrayToString((value) => value)),
	generalReportCategory
		.name('Address')
		.id('address')
		.makeRow((data) => {
			return data.input.location.streetAddress
		}),
	generalReportCategory
		.loadColumn(userColumnLoader)
		.name('Last Login Date')
		.id('last-login-date')
		.makeRow((data) => {
			const user = data.column.find(user => data.input.location.user.raw.name === user.id)

			if (!user) {
				return
			}

			return nDaysAgo(user.lastLoginDays)
		})
		.toString(date => {
			if (!date) return 'N/A'
			return DateHelper.getStringFromDate(date, "MDY", "/")
		}),
	generalReportCategory
		.loadColumn(userColumnLoader)
		.name('Last Login Days')
		.id('last-login-days')
		.makeRow((data) => {
			const user = data.column.find(user => data.input.location.user.raw.name === user.id)

			return user?.lastLoginDays
		})
		.toString((days) => {
			if (days === undefined) return 'N/A'
			return days.toString()
		})
]

export default generalColumns