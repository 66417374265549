import { useCallback } from "react"

// typically paired with a target="_blank" link, simply make onClick of the <a> tag call the waitForReturn function
// <a href="..." target="_blank" onClick={waitForReturn}>...</a>
// cb is the function that is called when the user returns to the page
const useRunOnReturn = (cb) => {
    const onReturnToPage = useCallback(() => {
        window.removeEventListener('focus', onReturnToPage);

        cb();
    }, [cb])

    const waitForReturn = useCallback(() => {
        window.addEventListener('focus', onReturnToPage);

        return () => {
            window.removeEventListener('focus', onReturnToPage);
        }
    }, [onReturnToPage])

    return {
        waitForReturn,
    }
}

export default useRunOnReturn