import React, { useMemo } from 'react'
import { Body } from '../../styles/typography'
import { StyledContainerHeader, StyledLocationOptions, StyledTabContainer, TabItem } from './styles'
import EntityOption from './EntityOption'
import { TAB_TYPES, configureTabPrep } from './filters.utils'

const SelectedOptions = ({ resolveDeselect, selectedOptions, isAd, colorScheme, tabsToDisplay, selectedTab, setSelectedTab }) => {
    const selectedOptionsToDisplay = useMemo(() => {
        return configureTabPrep(selectedOptions)
    }, [selectedOptions])

    return (
        <div>
            <StyledContainerHeader>
                <Body semibold>Selected</Body>
            </StyledContainerHeader>
            <StyledLocationOptions selectedOptions={selectedOptions}>
            <StyledTabContainer>
                    {
                        tabsToDisplay?.hasCampaigns &&
                        <TabItem onClick={() => setSelectedTab(TAB_TYPES.CAMPAIGNS)} colorScheme={colorScheme} isSelected={TAB_TYPES.CAMPAIGNS === selectedTab}>
                            <Body>Campaigns ({selectedOptionsToDisplay[TAB_TYPES.CAMPAIGNS]?.optionsLength})</Body>
                        </TabItem>
                    }
                    {
                        tabsToDisplay?.hasFolders &&
                        <TabItem className='tab-item' onClick={() => setSelectedTab(TAB_TYPES.FOLDERS)} colorScheme={colorScheme} isSelected={TAB_TYPES.FOLDERS === selectedTab}>
                            <Body>Folders ({selectedOptionsToDisplay[TAB_TYPES.FOLDERS]?.optionsLength})</Body>
                        </TabItem>
                    }
                    {
                        tabsToDisplay?.hasBlueprints &&
                        <TabItem className='tab-item' onClick={() => setSelectedTab(TAB_TYPES.BLUEPRINTS)} colorScheme={colorScheme} isSelected={TAB_TYPES.BLUEPRINTS === selectedTab}>
                            <Body>Blueprints ({selectedOptionsToDisplay[TAB_TYPES.BLUEPRINTS]?.optionsLength})</Body>
                        </TabItem>
                    }
                </StyledTabContainer>
                <div className='options-list'>
                    {
                        selectedOptionsToDisplay[selectedTab]?.options.map(option => <EntityOption
                            onSelectOption={resolveDeselect}
                            isAd={isAd}
                            option={option} // * Entire option object
                            key={option.value}
                            colorScheme={colorScheme}
                        />)
                    }
                </div>
            </StyledLocationOptions>
        </div>
    )
}

export default SelectedOptions
