import React from 'react'
import { Small, XS } from '../../styles/typography'
import { COLORS } from '../../constants/colors'
import { StyledSearchOnlyCloneCard } from './styles'
import  StringUtil  from '../../helpers/string.util'


const SearchOnlyCloneCard = ({ creative }) => {
    const { searchHeadlines, searchDescriptions, searchHeadlinePreview, searchDescriptionPreview } = creative

    return (
        <StyledSearchOnlyCloneCard>
            <Small style={{color: COLORS.SEARCH_HEADLINE}} bold>{StringUtil.truncateString(searchHeadlinePreview, 50)}</Small>
            <XS style={{color: COLORS.SEARCH_DESC}}>{StringUtil.truncateString(searchDescriptionPreview, 65)}</XS>
            <XS style={{ fontSize: '8px', marginTop: 'auto', marginLeft: 'auto', color: COLORS.SEARCH_COUNT}} bold>{searchHeadlines?.length} Headlines, {searchDescriptions?.length} Descriptions</XS>
        </StyledSearchOnlyCloneCard>
    )
}

export default SearchOnlyCloneCard