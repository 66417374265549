import { useCallback, useEffect, useMemo, useState } from 'react'
import { ApiPath, MakeRequest, HTTPMethod, ApiPromise } from '../types/api'
import { NoInfer } from '../types/custom'
import useLastValue from './useLastValue'

export type BackendError = any
export type MakeRequestState = 'idle' | 'loading' | 'error' | 'success'

export type Loadable<T> = (
    | {
          loading: true
          error: undefined
          data: undefined
          state: 'loading'
      }
    | {
          loading: false
          error: Error
          data: undefined
          state: 'error'
      }
    | {
          loading: false
          error: undefined
          data: T
          state: 'success'
      }
    | {
          loading: true
          error: undefined
          data: undefined
          state: 'idle'
      }
)

export type ApiRequest<T> = Loadable<T> & {
    fetch: () => void
}

type MakeRequestConfig<T, J = T> = {
    enabled: boolean
    method: HTTPMethod
    transform?: (data: T) => J
}

/*
  usage:

  // memoize the request so it doesn't get recreated on every render
  const request = useMemo(() => makeRequest().url(API.FRANCHISE.GET).param("franchise", franchiseName), [franchiseName])

  options:
    - autoFetch: whether or not to automatically fetch the data on mount
    - method: GET or POST

  // use the hook
  const { loading, error, data, fetch } = useRequest(
    request,
    { autoFetch: false, method: 'GET' }
  )

  check the loading, error, and data values to see what the state of the request is

  if (!loading && !error) {
    // data is ready to use
    functionThatUsesData(data)
  }

*/

export const useRequest = <T extends ApiPath, J = ApiPromise<T>>(
    request: MakeRequest<T>,
    options: Partial<MakeRequestConfig<ApiPromise<NoInfer<T>>, J>> = {}
): ApiRequest<J> => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<J>()
    const [error, setError] = useState<BackendError>()

    const lastRequest: MakeRequest<T> = useLastValue(request)

    const enabled = options.enabled ?? true
    const method = options.method ?? 'GET'
    const transformer = useMemo(() => options.transform ?? ((x: ApiPromise<T>) => x as J), [options.transform])

    const isValid = useMemo(() => {
        const opt = request._getOptions()
        const { searchParams } = opt

        const paramValues = searchParams?.entries()

        if (!paramValues) {
            return false
        }

        const values = Array.from(paramValues)

        return values.every(
            ([key, value]) =>
                !!value && value.length > 0 && !!key && key.length > 0
        )
    }, [request])

    const fetch = useCallback(async () => {
        if (!isValid) return
        let aborted = false
        setLoading(true)
        try {
            const dataFetcher =
                method === 'GET' ? request.get : request.post
            const resp = await dataFetcher()
            if (aborted) return

            const data = transformer(resp)

            setData(data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }

        return () => {
            aborted = true
        }
    }, [request, method, isValid, transformer])

    useEffect(() => {
        const startedInitialFetch = !loading && !data && !error
        if (!enabled) return
        if (request.equals(lastRequest) && !startedInitialFetch) return

        fetch()
    }, [fetch, enabled, request, lastRequest, loading, data, error])


    if (loading) {
        return {
            loading: true,
            error: undefined,
            data: undefined,
            fetch,
            state: 'loading',
        }
    }

    if (error) {
        return {
            loading: false,
            error: {
                message: error.message || 'Something went wrong',
                name: error.name || 'Error',
            },
            data: undefined,
            fetch,
            state: 'error',
        }
    }

    if (data) {
        return {
            loading: false,
            error: undefined,
            data,
            fetch,
            state: 'success',
        }
    }

    return {
        loading: true,
        error: undefined,
        data: undefined,
        fetch,
        state: 'idle',
    }
}
