import { toast } from "react-toastify";
import { loadCountries, loadCurrencies, loadRejectionReasons } from "../actions/resourceActions";
import { LOAD_FRANCHISE_LABELS } from "../actions/types";

const { useEffect, useState } = require("react");
const { selectFranchise, loadAndSelectManagedFranchises } = require("../actions/franchiseListActions");
const { isMember, canSelectFranchise, canManageFranchise } = require("../helpers/permissionsHelper");
const { useSelector, useDispatch } = require("react-redux");
const { clearFranchise, loadAllFonts, loadFranchise, getFranchiseImages, getFranchiseLogos, loadAssetColors, updateMembers, loadApprovals, loadUsersAndLocations, setLoading, loadMemberList, fetchTopTextSuggestions } = require("../actions/franchiseActions");
const { clearBuildTab } = require("../actions/buildActions");
const { clearImageBuilder, loadTemplate } = require("../actions/buildImageActions");
const { clearTokenTab } = require("../actions/tokenActions");
const { clearSmsTab } = require("../actions/smsActions");
const { clearRepo, getBlueprintsList } = require("../actions/repoActions");
const { clearCalendar } = require("../actions/calendarActions");
const { clearMemberSummaries, loadMemberSummaries } = require("../actions/summariesActions");
const { clearAdvancedFilters } = require("../actions/advancedFiltersActions");
const { clearBrandTheme, getClientApiKeys } = require("../actions/UIActions");

const useInitializeFranchise = () => {
    const user = useSelector(state => state.user)
    const members = useSelector(state => state.franchise.members)

    const selectedFranchise = useSelector(state => state.franchiseList.selectedFranchise)
    const memberList = useSelector(state => state.franchise.memberList)
    const { isAuthenticated, accessLevel } = user
    const approvalsEnabled = useSelector(state => state.franchise.approvals)
    const [hasNoFranchise, setHasNoFranchise] = useState(false)
    const _D = useDispatch()

    const statesToClear = [clearFranchise, clearBuildTab, clearImageBuilder,
        clearTokenTab, clearSmsTab, clearRepo, clearCalendar,
        clearMemberSummaries, clearAdvancedFilters,
     ]
    useEffect(() => {
        if (!isAuthenticated || canSelectFranchise(accessLevel)) return;

        if (!isMember(accessLevel)) {
          _D(loadAndSelectManagedFranchises())
            .catch(() => {
                toast.error('Unable to load franchise list')
            })
          return
        }

         if(!user.franchise) {
           setHasNoFranchise(true)
         } else {
           _D(selectFranchise(user.franchise ? {name: user.franchise, displayName: user.franchiseDisplayName || user.franchise}  : null)) //Member
         }

      }, [isAuthenticated, accessLevel, user.franchise, user.franchiseDisplayName, _D]);


      useEffect(() => {
         // Reset Redux States
        statesToClear.forEach(fun => _D(fun()))
        if (!isMember(accessLevel)) _D(clearBrandTheme()) // * We don't want to clear the theme if a user is a member
        if (!selectedFranchise && !hasNoFranchise) return;

        const franchiseName = selectedFranchise?.name
        // Available to all users
        _D(loadAllFonts(franchiseName))
        if(!isMember(accessLevel)) _D(fetchTopTextSuggestions({franchiseName}))
        _D(loadTemplate(isMember(accessLevel)))
        _D(getClientApiKeys())
        // Managers and Admins
        if (canManageFranchise(accessLevel)) {
          _D(loadFranchise(franchiseName))
          _D(getFranchiseImages(franchiseName))
          _D(getFranchiseLogos(franchiseName))
          _D(loadAssetColors(franchiseName))
          _D(getBlueprintsList(franchiseName))
          return;
        }
        // Franchisees
        if (isMember(accessLevel)) {
          _D(updateMembers(user))
          return;
        }
      }, [selectedFranchise, hasNoFranchise]);


    //When franchise object is loaded, load the members and their locations EXCEPT alternate users
    useEffect(() => {
        if (memberList.length !== 0) return;

        const handleLowPriorityCalls = () => {
        console.log('Done loading member data. Now making other api calls');
        if (approvalsEnabled && canManageFranchise(accessLevel)) _D(loadApprovals(selectedFranchise?.name))
    }

    if (members.length && canManageFranchise(accessLevel)) {
      _D(loadUsersAndLocations(() => {
        _D({
          type: LOAD_FRANCHISE_LABELS
        })
		_D(setLoading(false))
        handleLowPriorityCalls()
      }))
    } else {
      _D(loadMemberList(members, handleLowPriorityCalls))
    }
    _D(loadMemberSummaries())
  }, [members, loadMemberList, loadMemberSummaries, accessLevel]);

  //Load Resources
  useEffect(() => {
    if (!isAuthenticated) return;
    _D(loadCountries())
    _D(loadCurrencies())
    _D(loadRejectionReasons())
  }, [isAuthenticated, loadCountries, loadCurrencies, loadRejectionReasons]);

}

export default useInitializeFranchise