import React from "react";
import {
  StyledWebsitePreview,
  StyledWebsiteWrapper,
  OptionalPreviews,
} from "./styles";
import webWrapperImage from "../../assets/images/website-template.png";
import { H2 } from "../../styles/typography";
import { getPreviewImageUrl } from "../../helpers/creative.util";
const WebsitePreview = ({ creative }) => {
  const { preview, youTubeId } = creative;

  return (
    <StyledWebsitePreview>
      <OptionalPreviews>
        <div className="optional-prev-wrapper">
          <H2>Website Preview</H2>
        </div>
      </OptionalPreviews>
      <StyledWebsiteWrapper>
        <img
          className="website_ad_template"
          src={`${webWrapperImage}`}
          alt="web-template"
        />
        <img
          className="website_ad_image"
          alt="logo"
          src={getPreviewImageUrl({preview, youTubeId})}
        />
      </StyledWebsiteWrapper>
    </StyledWebsitePreview>
  );
};

export default WebsitePreview;
