import React from 'react'
import googleIcon from "../../assets/images/gicon.png";
import { SocialPreviewCopyHeader, SocialWrap, StyledGoogleMyBusinessPreview } from './styles'
import { H2 } from '../../styles/typography';
import { IoEllipsisVertical, IoShareSocialOutline } from 'react-icons/io5';
import { getPreviewImageUrl } from '../../helpers/creative.util';
import  StringUtil  from '../../helpers/string.util';
import { PREVIEW_PLACEHOLDER_TEXT } from '../../helpers/constants';

const GoogleBusinessPreview = ({ creative, previewText }) => {
    const { preview, youTubeId } = creative

    return (
        <SocialWrap>
            <SocialPreviewCopyHeader>
                <img src={googleIcon} alt='google-icon' />
                <H2 center>Google Business Profile Preview</H2>
            </SocialPreviewCopyHeader>
            <StyledGoogleMyBusinessPreview>
                <div className='header'>
                    <div className="placeholder-pic" />
                    <div className='profile-text'>
                        <strong>{PREVIEW_PLACEHOLDER_TEXT.gmbBusinessName}</strong>
                    </div>
                    <div className='actions'>
                        <IoEllipsisVertical />
                    </div>
                </div>
                <img
                    src={getPreviewImageUrl({ preview, youTubeId })}
                    alt="google-img"
                />
                <div className='extended-text'>
                    {
                        previewText?.gmbDescription?.length > 510 ? StringUtil.truncateString(previewText?.gmbDescription, 509) : previewText?.gmbDescription
                    }
                </div>
                <div style={{ marginLeft: 'auto', padding: '0 16px' }}>
                    <IoShareSocialOutline size={20} />
                </div>
            </StyledGoogleMyBusinessPreview>
        </SocialWrap>
    )
}

export default GoogleBusinessPreview