import { API } from '../constants/api';
import { loadCreativeResources } from '../helpers/build.util'
import {
    CLEAR_IMAGE_BUILDER, SELECTED_FILTER, TOGGLE_HEADER_FILTER, TOGGLE_DESCRIPTION_FILTER,
    HEADER_TEXT, DESCRIPTION_TEXT, TOGGLE_LOGO_FILTER, GET_SELECTED_IMAGE,
    GET_SELECTED_LOGO,
    GET_CANVAS, ZOOM_IN_AND_OUT, INITIAL_TEMPLATE, IMAGE_FILTERS,
    CLEAR_IMAGE_FILTERS, CANVAS_DIMENSIONS, CANVAS_BORDERS, CANVAS_DESCRIPTION_FONT_SIZE,
    CANVAS_HEADER_FONT_SIZE, GET_Z_INDEX, IS_Z_INDEX_SELECTED, GET_IN_MEMORY_IMG, CLEAR_SELECTED_ASSET,
    LOAD_VIDEO_EXTENDED_TEXT, TOGGLE_LOGO_DYNAMIC
} from './types';

export const clearImageBuilder = () => dispatch => dispatch({ type: CLEAR_IMAGE_BUILDER })

export const SelectedFilter = (value) => dispatch => (
    dispatch({
        type: SELECTED_FILTER,
        payload: value
    })
)

export const getToggleHeaderFilter = (value, filter) => dispatch => (
    dispatch({
        type: TOGGLE_HEADER_FILTER,
        payload: value,
        filter
    })
)

export const getToggleDescriptionFilter = (value, filter) => dispatch => (
    dispatch({
        type: TOGGLE_DESCRIPTION_FILTER,
        payload: value,
        filter
    })
)
export const getToggleLogoFilter = (value, filter) => dispatch => (
    dispatch({
        type: TOGGLE_LOGO_FILTER,
        payload: value,
        filter
    })
)

export const getToggleLogoDynamic = (value) => dispatch => {
    dispatch({
        type: TOGGLE_LOGO_DYNAMIC,
        payload: value
    })
}

export const setHeaderText = (value) => dispatch => {
    dispatch ({
        type: HEADER_TEXT,
        payload: value
    })
}

export const setDescriptionText = (value) => dispatch => {
    dispatch ({
        type: DESCRIPTION_TEXT,
        payload: value
    })
}

export const getCorrectFontSize = (header, description) => dispatch => {
    dispatch({
        type: CANVAS_HEADER_FONT_SIZE,
        payload: header,
    })
    dispatch({
        type: CANVAS_DESCRIPTION_FONT_SIZE,
        payload: description,
    })
}

export const getSelectedImage = (blobKey) => dispatch => (
    dispatch({
        type: GET_SELECTED_IMAGE,
        payload: blobKey
    })
)

export const getSelectedLogo = (blobKey) => dispatch => {
    dispatch({
        type: GET_SELECTED_LOGO,
        payload: blobKey
    })
}


export const clearSelectedAssets = (type) => dispatch => {
    return dispatch({
        type: CLEAR_SELECTED_ASSET,
        payload: type
    })
}

export const getCurrentCanvas = (canvas) => dispatch => (
    dispatch({
        type: GET_CANVAS,
        payload: canvas
    })
)

export const getZooming = (option) => dispatch => (
    dispatch({
        type: ZOOM_IN_AND_OUT,
        payload: option
    })
)

export const getImageFilters = (references) => dispatch => {
    let filters = ''
    references.forEach(input => {
        filters += input.dataSet + '(' + input.value;
        if (input.dataType === 'length')
            filters += 'px';
        else if (input.dataType === 'angle')
            filters += 'deg';
        filters += ') ';

    })
    dispatch({
        type: IMAGE_FILTERS,
        payload: filters
    })
}
export const clearImageFilters = () => dispatch => (
    dispatch({
        type: CLEAR_IMAGE_FILTERS,
    })
)


export const getCanvasDimensions = (dimensions) => dispatch => (
    dispatch(
        {
            type: CANVAS_DIMENSIONS,
            payload: dimensions
        }
    )
)

export const loadTemplate = (isMember) => dispatch => {
    window.eulerity.makeApiCall(API.TEMPLATE.GET_ALL, 'GET', null, function (resp) {
        let template = resp[0];
        for (let tmpl in resp) {
            if (resp[tmpl].industry)
                continue;
            template = resp[tmpl];
            break;
        }
        const { headerBox, descriptionBox, image, logoBox, logo, name} = template
        const imageUrl = isMember ? `/srv/${image}` : image;
        headerBox.justify = headerBox.justify.toLowerCase()
        descriptionBox.justify = descriptionBox.justify.toLowerCase()
        dispatch({
            type: INITIAL_TEMPLATE,
            payload: loadCreativeResources({
                header: headerBox,
                description: descriptionBox,
                image: imageUrl,
                logoBox,
                logo,
                name,
            })
        })
    })
}


export const getVdeoLoadCreative = (creative) => dispatch => {
    const { extendedText } = creative
    dispatch({
        type: LOAD_VIDEO_EXTENDED_TEXT,
        payload: extendedText
    })
}

export const getLoadCreative = (creative) => dispatch => {
    const { header, description, image, logoBox, logo, template, extendedText, premade } = creative

    if(!premade) {
        header.justify = header.justify?.toLowerCase()
        description.justify = description.justify?.toLowerCase()
    }

    dispatch({
        type: INITIAL_TEMPLATE,
        payload: loadCreativeResources(
            {
                header,
                description,
                image: `/srv/${image}`,
                logoBox,
                logo,
                name: template,
                isEdited: true,
                extendedText,
                premade
            },
            )
    })
}

export const deffineCanvasBorders = (bool) => dispatch => (
    dispatch({
        type: CANVAS_BORDERS,
        payload: bool
    })
)


export const getCurrentDraggableZindex = (refs) => dispatch => {

    dispatch({
        type: GET_Z_INDEX,
        payload: refs,
    })
}

export const isZindexSelected = () => dispatch => (
    dispatch({
        type: IS_Z_INDEX_SELECTED
    })
)

export const getTempFile = (file, clearFileOption) => dispatch => {
    if(clearFileOption) {
        dispatch({
            type: GET_IN_MEMORY_IMG,
            payload: null
        })
    } else {
        dispatch({
            type: GET_IN_MEMORY_IMG,
            payload: file
        })
    }
}