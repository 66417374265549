import React, {useEffect} from "react";
import ReactDOM from "react-dom";

import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import { ModalOverlay, StyledModal } from "./styles";
import { ExitIcon } from "../../styles/icons";
import BackButton from "../backButton/BackButton";

type ModalProps = {
  children: React.ReactNode;
  onBack?: () => void;
  backText?: string;
  onClose?: (e: React.MouseEvent) => void;
  title?: string;
  buttons?: React.ReactNode;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  onSubmit?: (data: any) => void;
  styles?: React.CSSProperties;
  footerContent?: React.ReactNode;
  overlayStyles?: React.CSSProperties;
  hidden?: boolean;
}

/**
 * @deprecated This component is on the process of deprecation. Use ModalV2 instead.
 */
export default function Modal(props : ModalProps ) {

  useEffect(() => {
    document.body.style.overflowY = "hidden"
    return () =>{
      document.body.style.overflowY = ""
    }
  }, [])


  return ReactDOM.createPortal(
    <>
      <ModalOverlay  style={{...props.overlayStyles, display: props.hidden ? 'none' : 'flex'}}>
      <StyledModal
        style={{ ...props.styles }}
        width={props.width}
        maxWidth={props.maxWidth}
        minHeight={props.minHeight}
        id="modal-ref"
        >
        {
          props.onClose &&
          <span className="close-btn" onClick={e => {
            e.stopPropagation(); // stops onClose from being called twice
            props.onClose?.(e)
          }}>
            <ExitIcon />
          </span>
        }
        {props.onBack && (
          <BackButton onBack={props.onBack} text={props.backText} />
        )}
        <ModalHeader title={props.title} />
        <ModalContent children={props.children} />
        <ModalFooter buttons={props.buttons} onSubmit={props.onSubmit} footerContent={props.footerContent} />
      </StyledModal>
      </ModalOverlay>

    </>,
    document.getElementById("modal") as HTMLDivElement
  );
}
