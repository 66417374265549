export type GeoLocation = {
    canonicalName: string
    countryCode: string
    geoLocationId: number
    name: string
    targetType: string
}

export type GeoRadius = {
    geoRadius: {
        latitude: number
        longitude: number
        radius: number
    }
}

export type GeoTarget = GeoLocation | GeoRadius

export const isGeoLocation = (geoTarget: GeoTarget): geoTarget is GeoLocation => {
    return (geoTarget as GeoLocation).geoLocationId !== undefined
}

export const isGeoRadius = (geoTarget: GeoTarget): geoTarget is GeoRadius => {
    return !isGeoLocation(geoTarget)
}