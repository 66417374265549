import React, { useEffect, useRef } from 'react';

import { handlePercentChange } from '../../utils';
import { useTheme } from 'styled-components';
import { StyleCircularProgressBar } from './style';

const CircularProgressBar = ({progress}:{progress:number}) => {
  const ref = useRef<SVGCircleElement>(null)
  const theme = useTheme();

  useEffect(() => {
    if(!ref.current) return
    handlePercentChange(progress, ref.current, theme.color.accentColor2)
  },[progress, theme.color.accentColor2])

  return (
    <StyleCircularProgressBar>
      <div id="cp-bar">
        <svg id="svg" width="50" height="50" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle r="50" cx="100" cy="100" fill="transparent" strokeDasharray="314.16" strokeDashoffset="0"></circle>
          <circle id="bar" ref={ref} r="50" cx="100" cy="100" fill="transparent" strokeDasharray="314.16" strokeDashoffset="0"></circle>
        </svg>
      </div>
    </StyleCircularProgressBar>
  );
};

export default CircularProgressBar;
