import { MakeRequestCache, HTTPMethod, ApiPathPaginated } from './../types/api'
import {
    ApiPath,
    ApiPromise,
    MakeRequest,
    MakeRequestOptions,
    BatchableMakeRequest,
    BatchifyRequest,
    ListableResponseTypes,
    DataHandler,
} from '../types/api'
import { getEnvironmentType } from '../features/environment/util'
/*

    makeRequest()
        .url("/foo/bar")
        .param("a", 1)
        .param("b", 2)
        .param("c", 3)
        .body({
            ben: "was here",
        })

    *************************************************************************

    Note that each time you chain a call, you create a new object. This means
    you can safely do things like:

    const base = makeRequest().param("uid", UID).param("lid", LID)

    const r1 = base.url(API.LOCATION.GET)
    const r2 = base.url(API.LOCATION.UPDATE)

    This way, common request flows can be built and then reused, without fear
    of their data interfering

    *************************************************************************

    You actually execute the requests by calling .get() or .post(). These
    will make the requests for you, and automatically insert the relevant
    request details.

    These requests will return promises, which will obviously resolve or
    reject depending on the success of the request.

*/

// We can specify some options or have them be all uninitalized
export const makeRequest = function <T extends ApiPath = undefined>(
    options?: MakeRequestOptions
): MakeRequest<T> {
    let url = options?.url
    let searchParams = options?.searchParams ?? new URLSearchParams()
    let body = options?.body ?? null
    let retry = options?.retry ?? 0
    let cache = options?.cache ?? null
    let mock = options?.mock ?? null

    const getURLWithParams = () => {
        const hasSearchParams = Array.from(searchParams.entries()).length > 0

        return url + (hasSearchParams ? `?${searchParams.toString()}` : '')
    }

    return {
        _getOptions() {
            return {
                url: url,
                searchParams: new URLSearchParams(searchParams),
                body: structuredClone(body) ?? undefined,
                retry,
                cache,
                mock,
            } as MakeRequestOptions
        },
        async get() {

            const defaultEnableMockData = () => {
                return getEnvironmentType() !== 'production'
            }

            if (!!mock && (options?.enableMockData ?? defaultEnableMockData)()) {
                return Promise.resolve(mock as ApiPromise<T>)
            }
            if (cache?.isInCache(this)) {
                return cache.get(this)
            }

            const promise = new Promise<ApiPromise<T>>((resolve, reject) => {
                window.eulerity.makeApiCall(
                    getURLWithParams(),
                    'GET',
                    null,
                    (data) => {
                        if (cache) {
                            cache.set(this, data)
                        }
                        resolve(data as ApiPromise<T>)
                    },
                    {
                        1000: reject,
                    },
                    null,
                    retry
                )
            })

            cache?.set(this, promise)

            return promise
        },
        post() {
            return new Promise((resolve, reject) => {
                window.eulerity.makeApiCall(
                    getURLWithParams(),
                    'POST',
                    body,
                    (data) => resolve(data as ApiPromise<T>),
                    {
                        1000: (error : Error) => reject(error),
                    },
                    null,
                    retry
                )
            })
        },
        url(_url: ApiPath) {
            return makeRequest({
                ...this._getOptions(),
                url: _url,
            })
        },
        param(key: string, value: string | number | boolean) {
            const newParams = new URLSearchParams(searchParams)

            newParams.set(key, value.toString())

            return makeRequest({
                ...this._getOptions(),
                searchParams: newParams,
            })
        },
        params(object: Record<string, string | number | boolean>) {
            const newParams = new URLSearchParams(searchParams)

            for (const key in object) {
                newParams.set(key, object[key].toString())
            }

            return makeRequest({
                ...this._getOptions(),
                searchParams: newParams,
            })
        },
        body(obj: Record<string, any>) {
            return makeRequest({
                ...this._getOptions(),
                body: obj,
            })
        },
        retry(n: number) {
            return makeRequest({
                ...this._getOptions(),
                retry: n,
            })
        },
        withCache(cache: MakeRequestCache) {
            return makeRequest({
                ...this._getOptions(),
                cache,
            })
        },
        mock(data: ApiPromise<T>) {
            return makeRequest({
                ...this._getOptions(),
                mock: data,
            })
        },
        _log() {
            console.log({
                url: url,
                body,
                searchParams: Array.from(searchParams.entries()).reduce(
                    (obj, entry) => ({ ...obj, [entry[0]]: entry[1] }),
                    {}
                ),
                retry: retry,
            })

            return makeRequest({
                ...this._getOptions(),
            })
        },
        equals: <J extends ApiPath>(other: MakeRequest<J>) => {
            const otherOptions = other._getOptions()

            let doParamsMatch = true

            for (const [key, value] of searchParams.entries()) {
                if (otherOptions?.searchParams?.get(key) !== value) {
                    doParamsMatch = false
                    break
                }
            }
            return otherOptions.url === url && doParamsMatch
        },
        getKey() {
            return getURLWithParams()
        },
    }
}

/*

    This function takes a request generated from above, and then allows you to iterate through it, similar to batchedApiCall

    It returns an object with two methods:
    run(): This method begins the actual network requests, it returns a promise that will resolve with all data once all network requests are complete
    setDataHandler(callback): This is optional. callback will be called with two params, data, which is the data that has been received, and next, which is a function to be called when we want to load the next batch of data.
    It has two uses:
        First, it allows you to do things once data is received without having to wait for all of the data to be received. This is useful when trying to show loading progress for example.
        Second, it allows you to specify when to begin the next network request. This is useful when we want to only load the next batch when the user, for example, clicks a load more button. You can do this by saving the "next" method from inside of the data handler to state, and then calling next whenever the load more button is clicked.
*/

export const batchifyRequest = <T extends keyof ListableResponseTypes>(
    request: BatchableMakeRequest<T>
): BatchifyRequest<T> => {
    let dataHandler: DataHandler<T> = (_, next) => {
        next()
    }

    return {
        run(method: HTTPMethod = 'GET') {
            return new Promise((resolve, reject) => {
                (method === 'GET' ? request.get() : request.post())
                    .then((response) => {
                        dataHandler(
                            response.paginatedData,
                            async () => {
                                if (!!response.cursor) {
                                    const nextRequest = batchifyRequest(
                                        request.param('cursor', response.cursor)
                                    )

                                    nextRequest.setDataHandler(dataHandler)

                                    const nextData = await nextRequest.run(
                                        method
                                    )
                                    const combinedData = [
                                        ...response.paginatedData,
                                        ...nextData,
                                    ] as ListableResponseTypes[T]['paginatedData']

                                    resolve(combinedData)
                                } else {
                                    resolve(response.paginatedData)
                                }
                            },
                            !response.cursor
                        )
                    })
                    .catch(reject)
            })
        },
        setDataHandler(cb: DataHandler<T>) {
            dataHandler = cb
        },
        getKey() {
            const { searchParams: _searchParams, url } = request._getOptions()
            const searchParams = _searchParams ?? new URLSearchParams()
            const hasSearchParams = Array.from(searchParams.entries()).length > 0

            return url + (hasSearchParams ? `?${searchParams.toString()}` : '')
        },
        equals<J extends ApiPathPaginated>(other: BatchifyRequest<J>) {
            return other.getKey() === this.getKey()
        },
    }
}
