import styled from 'styled-components';

export const StyledMultiStepForm = styled.div`
    width: 100%;
    height: fit-content;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

export const ProgressBarContainer = styled.div`
    width: 111%;
    margin-bottom: 20px;
`

export const StyledProgressBar = styled.ul`
    counter-reset: step;
    display: flex;
    justify-content: center;
    text-indent: 0em;
`
export const StyledFormContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const StyledStep = styled.div<{show: boolean}>`
    display: ${ ({ show }) => show ? 'block' : 'none' };
    min-width: 50%;
    min-height: 400px;
`