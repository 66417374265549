import styled, { css } from "styled-components";
import { decideTextColor, hexToRgba } from "../../../../helpers/colors.util";

export const LabelItem = styled.div`
  border-radius: 9px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 3px 8px;
  word-break: break-all;
  width: fit-content;
  flex-basis: 1;
  cursor: pointer;
  display: flex;
  min-height: 33px;
   ${({isTyping}) => isTyping && css`
     opacity: 0.8;
  ` }

  background-color: ${props => {
    if(props.isPost) {
      return props.selectedOptions?.includes(props.option) ? props.theme.color.creativeColor2 : hexToRgba(props.theme.color.creativeColor2, 0.4);
    } else if(props.isAd) {
      return props.selectedOptions?.includes(props.option) ? props.theme.color.creativeColor1 : hexToRgba(props.theme.color.creativeColor1, 0.4);
    } else return props.selectedOptions?.includes(props.option) ? props.theme.color.accentColor2 : hexToRgba(props.theme.color.accentColor2, 0.4);
  }};

  color: ${props => {
    if(props.isPost) {
      return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.creativeColor2) : props.theme.color.textColor1;
    } else if(props.isAd) {
      return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.creativeColor1) : props.theme.color.textColor1;
    } else return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.accentColor2) : props.theme.color.textColor1;
  }};

  border: ${props => {
    if(props.isPost) {
      return `1px solid ${props.theme.color.creativeColor2}`;
    } else if(props.isAd) {
      return `1px solid ${props.theme.color.creativeColor1}`;
    } else return `1px solid ${props.theme.color.accentColor2}`;
  }};

  white-space: ${({shrink}) => shrink ? "" : 'nowrap'};

  svg {
    margin-left: 8px;
    align-self: center;
    min-height: 10px;
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;

    path {
      stroke: ${props => {
        if(props.isPost) {
          return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.creativeColor2) : props.theme.color.textColor1;
        } else if (props.isAd) {
          return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.creativeColor1) : props.theme.color.textColor1;
        } else {
          return props.selectedOptions?.includes(props.option) ? decideTextColor(props.theme.color.accentColor2) : props.theme.color.accentColor2;
        }
      }}
    }
  }
      @keyframes cursorPulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .cursor {
      line-height: 1.1;
      animation: cursorPulse 1s infinite;
    }
`;