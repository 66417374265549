import React, { useMemo } from 'react'
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { toggleTheme } from '../../actions/UIActions';
import { ThemeOptions } from './types';
import useEnvironment from '../environment/useEnvironment';

const ThemeWrapper = (props: {
    children: React.ReactNode
}) => {
    // * Variables and other Selectors
    const _D = useDispatch();
    const user = useSelector(s => s.user);
    const environment = useEnvironment()

    // * Themes
    const theme: ThemeOptions = useSelector(s => s.ui.theme);
    const lightTheme = useSelector(s => s.ui.lightTheme);
    const darkTheme = useSelector(s => s.ui.darkTheme); // * backend considers this "default"
    const brandTheme = useSelector(s => s.ui.brandTheme);
    const themeList: ThemeOptions[] =  useMemo(() => brandTheme ? ['light', 'dark', 'brand'] : ['light', 'dark'], [brandTheme])

    // !-- Handle Keyboard Shortcuts for Developers
    const handleKeyPress = useCallback((event: { ctrlKey: boolean; code: string; }) => {
        let currThemeIndex = themeList.indexOf(theme)
        let nextThemeIndex = currThemeIndex === themeList.length - 1 ? 0 : currThemeIndex + 1

        if (!environment.isDevelopment && !environment.isTest) return
        if (event.ctrlKey) {
            // Theme Toggle Shortcut
            if (event.code === 'KeyB') {
                _D(toggleTheme(themeList[nextThemeIndex]))
                console.log('toggled!', theme) // * note this will print the previous theme you were on
            }
        }
    }, [theme]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    // * initialize data theme
    useEffect(() => {
        try {
            let fccTheme = window.localStorage.getItem('data-theme') as ThemeOptions;

            if (!fccTheme) return

            if (themeList.includes(fccTheme)) {
                _D(toggleTheme(fccTheme))
                return;
            }

            if (user.preferences.fcctheme && user.preferences?.fcctheme !== fccTheme) {
                fccTheme = user.preferences.fcctheme || 'light';
                _D(toggleTheme(fccTheme))
                if (window.localStorage) window.localStorage.setItem('data-theme', fccTheme!);
            }
        // any since this an error that has to do with local storage not being defined for a browser
        } catch (e: any) {
            console.error(e.message)
            _D(toggleTheme(user.preferences.fcctheme || 'light'))
        }
    }, [user.preferences.fcctheme])

    useEffect(() => {
        // * This is used for global styles defined in index.css
        document.body.setAttribute("data-theme", theme)
    }, [theme])

    return (
        <ThemeProvider
            theme={
                theme === 'brand' ? brandTheme! :
                theme === 'light' ? lightTheme : darkTheme}>
            {props.children}
        </ThemeProvider>
    )
}

export default ThemeWrapper