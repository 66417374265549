import React, { ReactNode, HTMLAttributes } from 'react';
import { TooltipTrigger } from './TooltipTrigger';
import { IconSize, InfoIcon } from '../../components/tooltip/styles';
import styled from 'styled-components';

interface InfoTooltipProps extends HTMLAttributes<HTMLDivElement> {
  tooltipContent: string | ReactNode;
  iconSize?: IconSize // * In cases we want to have the info icon be a different size we can pass it in (by default it'll be the SMALL size)
}

const InfoTooltip = ({ tooltipContent, iconSize = IconSize.XS, style, ...props }: InfoTooltipProps) => {
  return (
    <TooltipTrigger tooltipContent={tooltipContent} {...props}>
      <Container style={{...style }}>
        <InfoIcon size={iconSize} />
      </Container>
    </TooltipTrigger>
  );
};

export default InfoTooltip;


const Container = styled.div`
    display: grid;
    place-content: center;
    width:  min-content;
    height: min-content;
`