//* For future bulk edit features, add CSV header mapping here

export const SUBSCRIPTION_HEADERS = {
  LID: "LID",
  UID: "UID",
  BUSINESS_NAME: "Business Name",
  CAMPAIGN_NAME: "Campaign Name",
  CURRENCY: "Currency",
  OLD_AMOUNT: "Old Amount",
  NEW_AMOUNT: "New Amount",
  SCHEDULED_DATE: "Scheduled Date",
  STATUS: "Status",
} as const