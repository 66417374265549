import { useMemo } from 'react';
import { FormDataType } from '../modals/steps/CreativeStepInstagramCollaborators';
import StringUtil from '../../../helpers/string.util';

const useCollaboratorStep = (formData: FormDataType) => {
  const MAX_COLLABORATORS = 3
  const collaborators = useMemo(() => formData?.instagramCollaborators || [], [formData])

  const hasReachedCollaboratorLimit = useMemo(() => collaborators.length >= MAX_COLLABORATORS, [collaborators]);

  const hasDuplicates = useMemo(() => {
    const uniqueNames = new Set(collaborators.map(c => c.name.toLowerCase()));
    return collaborators.length !== uniqueNames.size;
  }, [collaborators]);

  const hasEmptyCollaborators = useMemo(() => {
    return collaborators.length > 0 && collaborators.some(c => c.name.trim().length === 0);
  }, [collaborators]);

  const hasInvalidHandle = useMemo(() => {
    return collaborators.length > 0 && collaborators.some(c => !StringUtil.isValidInstagramHandle(c.name));
  }, [collaborators]);

  return {
    hasReachedCollaboratorLimit,
    hasDuplicates,
    hasEmptyCollaborators,
    hasInvalidHandle
  };
};

export default useCollaboratorStep;
