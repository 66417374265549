export const METERS_PER_MILE = 1609.344
export const MILLIS_PER_DAY = (24 * 60 * 60 * 1000)
export const MILLIS_PER_MINUTE = (1000 * 60)
export const MILLIS_PER_SECOND = 1000
export const MILLIS_PER_HOUR = (MILLIS_PER_MINUTE * 60)
export const LOWEST_VALUE_CPG = 0.0001
export const SECONDS_PER_MINUTE = 60

export const DAYS_PER_YEAR = 365

export const EULERITY = {
    LATITUDE: 40.725979,
    LONGITUDE: -74.008011,
    TOS: 'https://www.eulerity.com/tos'
} as const

export const FACEBOOK_SPECIAL_AD_CATEGORIES = {
    NONE: 'NONE',
    HOUSING: 'HOUSING',
    FINANCIAL_PRODUCTS_SERVICES: 'FINANCIAL_PRODUCTS_SERVICES',
    EMPLOYMENT: 'EMPLOYMENT',
    ISSUES_ELECTIONS_POLITICS: 'ISSUES_ELECTIONS_POLITICS',
    ONLINE_GAMBLING_AND_GAMING: 'ONLINE_GAMBLING_AND_GAMING'
} as const

type FacebookSpecialAdCategories = typeof FACEBOOK_SPECIAL_AD_CATEGORIES[keyof typeof FACEBOOK_SPECIAL_AD_CATEGORIES];

export const FACEBOOK_SPECIAL_AD_CATEGORIES_NAMES = {
    [FACEBOOK_SPECIAL_AD_CATEGORIES.NONE]: "None",
    [FACEBOOK_SPECIAL_AD_CATEGORIES.HOUSING]: "Housing",
    [FACEBOOK_SPECIAL_AD_CATEGORIES.EMPLOYMENT]: "Employment",
    [FACEBOOK_SPECIAL_AD_CATEGORIES.ISSUES_ELECTIONS_POLITICS]: "Issues, Elections, or Politics",
    [FACEBOOK_SPECIAL_AD_CATEGORIES.ONLINE_GAMBLING_AND_GAMING]: "Online Gambling and Gaming",
    [FACEBOOK_SPECIAL_AD_CATEGORIES.FINANCIAL_PRODUCTS_SERVICES]: "Financial Products Services"
};

type FacebookSpecialAdCategoriesNames = typeof FACEBOOK_SPECIAL_AD_CATEGORIES_NAMES[keyof typeof FACEBOOK_SPECIAL_AD_CATEGORIES_NAMES];

export const FACEBOOK_SPECIAL_AD_CATEGORIES_OPTIONS = Object.entries(
    FACEBOOK_SPECIAL_AD_CATEGORIES_NAMES
).reduce((arr, entry) => {
    arr.push({
        value: entry[0] as FacebookSpecialAdCategories,
        label: entry[1] as FacebookSpecialAdCategoriesNames
    })
    return arr
}, [] as { value: FacebookSpecialAdCategories, label: FacebookSpecialAdCategoriesNames }[]);

export const DEMOGRAPHIC_TARGETING: Record<string, Record<string, string>> = {
    parentTargets: {
        t: 'Parents',
        f: 'Non-Parents'
    },
    ageTargets: {
        a: '18-24',
        b: '25-34',
        c: '35-44',
        d: '45-54',
        e: '55-64',
        f: '65+'
    },
    incomeTargets: {
        l: 'Modest',
        m: 'Comfortable',
        h: 'Affluent'
    },
    genderTargets: {
        m: 'Male',
        f: 'Female'
    }
} as const

// for a location object, these are all of the keys that store lists of creatives
export const CREATIVE_CATEGORY_KEYS = ['creatives', 'magics', 'posts', 'videoCreatives', 'videoMagics', 'videoPosts'] as const

export const EULERITY_SUPPORT_EMAIL = 'support@eulerity.com'

export const PREVIEW_PLACEHOLDER_TEXT = {
    followers: "[Your Followers]",
    linkedinPage: "[Your LinkedIn Page]",
    businessPlaceholder: "[Your Business Name]",
    domainPlaceholder: "[Your Website]",
    instagramAccountName: "[Your Instagram Account]",
    facebookPageName: "[Your Facebook Page]",
    locationPhone: "[Your Phone Number]",
    XHandler: "[X Handle]",
    XPageName: "[Your X Handle]",
    gmbBusinessName: "[Your Google Profile]"
}