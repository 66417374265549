import React from 'react'
import { Loader } from '../../../../components/waitScreens/Loader'
import { useCreativeReports } from '../../hooks/useCreativeReports'
import { useCreativeContext } from '../CreativeModalV2'
import AdDetailsTable from './AdDetailsTable'
import AdMetrics from './AdMetrics'
import AdRankings from './AdRankings'

const AdDetailsView = () => {
  const {  needsApproval, metadata } = useCreativeContext()
  const { isFolder, isDeployedAd, location } = metadata
  const shouldShowAdReports = isDeployedAd && !needsApproval && !isFolder //This is an ad on a campaign
  const { data, loading } = useCreativeReports({uid: location?.user?.raw?.name, lid: location.id });

  return (
    <div>
      <AdDetailsTable />
      {
        shouldShowAdReports &&
        loading ? <Loader /> :
        <>
          <AdRankings creativeReports={data} />
          <AdMetrics creativeReports={data} />
        </>
      }
    </div>
  )
}

export default AdDetailsView