import styled, { keyframes } from "styled-components"

const DotPulseBefore = keyframes`
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
`
const DotPulseAfter = keyframes`
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
`
const DotPulse = keyframes`
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
`

export const LoadingIndicator = styled.div`
position: relative;
left: -9999px;
width: 10px;
height: 10px;
border-radius: 5px;
background-color: ${props => props.theme.color.accentColor2};
color: ${props => props.theme.color.accentColor2};
box-shadow: 9999px 0 0 -5px;
animation: ${DotPulse} 1s infinite linear;
animation-delay: 0.25s;
::before, ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.color.accentColor2};
    color: ${props => props.theme.color.accentColor2};
}
::before {
    box-shadow: 9984px 0 0 -5px;
    animation: ${DotPulseBefore} 1s infinite linear;
    animation-delay: 0s;
}
::after {
    box-shadow: 10014px 0 0 -5px;
    animation: ${DotPulseAfter} 1s infinite linear;
    animation-delay: 0.5s;
}
`
