import styled, { css } from "styled-components";
import { BigBody } from "../../styles/typography";

type TileContainerProps = {
  backgroundImage?: string
  isSelected?: boolean
  isDisabled?: boolean
  isPending?: boolean
}

//* Main Tile Wrapper and its states
export const TileContainer = styled.div<TileContainerProps>`
  position: relative;
  display: flex;
  height: 200px;
  width: 200px;
  padding: 8px;
  flex-direction: column;
  border: 2px solid ${props => props.theme.color.accentColor3};
  border-radius: 4px;
  background: ${props => props.theme.color.backgroundColor2};

  //* used in Assets tile
  ${({ backgroundImage }) => backgroundImage && css`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
  `}

  ${({ isSelected }) => isSelected && css`
    border: 2px solid ${props => props.theme.color.accentColor2};
  `}

  &:hover {
    transition: background ease-in-out 0.25s;
    cursor: pointer;

    ${({ backgroundImage })  => !backgroundImage && css`
      background: ${props => props.theme.color.accentColor4};
    `}
  }

  &:active {
    ${({ backgroundImage })  => !backgroundImage && css`
      background: ${props => props.theme.color.accentColor4};
    `}
  }

  ${({ isDisabled }) => isDisabled && css`
    border: 1px solid ${props => props.theme.color.greyAccent1};
    opacity: 60%;
    pointer-events: none;
    user-select: none;
    cursor: auto;

    &:hover {
      pointer-events: none;
      cursor: auto;
    }
  `}

  ${({ isPending }) => isPending && css`
    border: 1px solid ${props => props.theme.color.greyAccent1};
    background: ${props => props.theme.color.greyAccent2};
  `}
`

//* Commonly used as the first child container for icons and buttons
export const TileIconContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg, button {
        position: relative;
        margin: 0;
        fill: ${props => props.disabled ? props.theme.color.greyAccent1 : props.theme.color.accentColor2};
    }
`

//* Used for center content (Insights, Creative Folder, and Blueprints)
export const TileContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 16px;
`

//* Wrap this around the title for two line clamping
export const TileName = styled(BigBody)`
    // BigBody with two line clamp
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    text-align: center;
`