import React from 'react'
import ModalV2 from '../../../../components/modal/ModalV2/ModalV2'
import { Selector, SELECTOR_CONTROL_TYPE } from '../../../../components/selector/Selector'
import { useSelectableList } from '../../../../hooks/useSelectableList'
import { PrimaryCTABtn } from '../../../../styles/buttons'
import { H2, Small, Span, XS } from '../../../../styles/typography'
import reportBuilderFormatPresets, { ReportBuilderFormatPreset } from '../core/aggregationOptions'
import { useInsightsReportBuilderContext } from '../InsightsReportBuilder'
import { useFinalReviewContext } from '../Steps/FinalReview'

type PerformanceViewSelectorProps = {
	isViewSelectorOpen: boolean
	setIsViewSelectorOpen: (isOpen: boolean) => void
}

const dateRangeOptions = [
	{
		...reportBuilderFormatPresets.aggregateDate,
		value: reportBuilderFormatPresets.aggregateDate.id,
		label: () => {
			return <Small semibold>Total</Small>
		},
	},
	{
		...reportBuilderFormatPresets.expandDate,
		value: reportBuilderFormatPresets.expandDate.id,
		label: () => {
			return <Small semibold>Day by Day</Small>
		},
	},
]

const dataSourceOptions = [
	{
		...reportBuilderFormatPresets.aggregateLocation,
		value: reportBuilderFormatPresets.aggregateLocation.id,
		label: () => {
			return (
				<div>
					<Small semibold>By Business</Small>
					<XS>
						<Span disabled>Please note:</Span> this selection will show data across
						all campaigns for your selected businesses.
					</XS>
				</div>
			)
		},
	},
	{
		...reportBuilderFormatPresets.expandLocation,
		value: reportBuilderFormatPresets.expandLocation.id,
		label: () => {
			return <Small semibold>By Campaign</Small>
		},
	},
]

const PerformanceViewSelector = (props: PerformanceViewSelectorProps) => {
	const { setAggregationText } = useFinalReviewContext()
	const { updateReportFormat, setViews, reportFormats } = useInsightsReportBuilderContext()
	const { isViewSelectorOpen, setIsViewSelectorOpen } = props

	const AggregationTypeLayout = {
		idField: 'id',
		layout: ({
			item,
		}: {
			item:
				| (typeof dateRangeOptions)[number]
				| (typeof dataSourceOptions)[number]
		}) => {
			return item.label()
		},
	}

	const {
		isSelected: isDateRangeOptionSelected,
		setSelectedItems: setSelectedDateRangeOptions,
		selectedItems: selectedDateRangeOptions,
	} = useSelectableList<ReportBuilderFormatPreset, string>(dateRangeOptions, (item) => item.id, reportFormats.performance)
	const selectedDateRangeOption = selectedDateRangeOptions[0]
	const {
		isSelected: isDataSourceOptionSelected,
		setSelectedItems: setSelectedDataSourceOptions,
		selectedItems: selectedDataSourceOptions,
	} = useSelectableList<ReportBuilderFormatPreset, string>(dataSourceOptions, (item) => item.id, reportFormats.performance)
	const selectedDataSourceOption = selectedDataSourceOptions[0]

	if (!isViewSelectorOpen) return null

	return (
		<ModalV2
			size="full"
			onClose={() => {
				setSelectedDataSourceOptions(reportFormats.performance)
				setSelectedDateRangeOptions(reportFormats.performance)
				setIsViewSelectorOpen(false)
			}}
			buttons={[
				<PrimaryCTABtn
					disabled={
						!selectedDataSourceOption || !selectedDateRangeOption
					}
					onClick={() => {
						updateReportFormat('performance', [
							selectedDateRangeOption,
							selectedDataSourceOption,
						])
						setAggregationText(
							`${selectedDateRangeOption.displayName} Performance By ${selectedDataSourceOption.displayName}`
						)
						setIsViewSelectorOpen(false)
						setViews(null)
					}}
				>
					Confirm
				</PrimaryCTABtn>,
			]}
		>
			<H2>Performance View Type</H2>
			<Small
				style={{
					marginBottom: '1rem',
				}}
			>
				Select how to view your data over a selected date range.
			</Small>
			<Selector
				items={dateRangeOptions}
				isItemSelected={isDateRangeOptionSelected}
				onItemSelected={(item: (typeof dateRangeOptions)[number]) =>
					setSelectedDateRangeOptions([item])
				}
				ContentLayout={AggregationTypeLayout}
				showContainer={false}
				style={{
					marginBottom: '1.5rem',
				}}
				controlType={SELECTOR_CONTROL_TYPE.NONE}
			/>
			<Small
				style={{
					marginBottom: '1rem',
				}}
			>
				Select how to view your data by source.
			</Small>
			<Selector
				items={dataSourceOptions}
				isItemSelected={isDataSourceOptionSelected}
				onItemSelected={(item: (typeof dataSourceOptions)[number]) =>
					setSelectedDataSourceOptions([item])
				}
				ContentLayout={AggregationTypeLayout}
				showContainer={false}
				controlType={SELECTOR_CONTROL_TYPE.NONE}
			/>
		</ModalV2>
	)
}

export default PerformanceViewSelector
