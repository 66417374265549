import { CountryCode } from "libphonenumber-js"
import { EulerityLocationInfo } from "../features/creatives/creative.types"
import { SerializedKey } from "./apiResponses"
import { EulerityLocation } from "./location"

export type UserId = string
export type LocationId = number
export type Email = string
export type Websafe = string
export type BlobKey = string

export type KeywordMatch = "EXACT" | "PHRASE" | "BROAD"
export type AudienceStats = "totalCount" | "phoneCount" | "firstCount" | "lastCount"

export type Keyword = {
    word: string
    matchType: KeywordMatch
    isNegative: boolean
}

export type Audience = {
    id: number
    location: EulerityLocationInfo
    name: string
    statsMap: {
        user?: Record<AudienceStats, number>
    }
    isTest: boolean
    created: string
    updated: string
    websafe: Websafe
    uploads: Upload[]
    audienceCategory?: AudienceCategory
}

export type AudienceCategory = {
    id: number
    name: string
    description: string
    countryCode: CountryCode
    created: string
    raw: {
        id: number
        kind: string
        websafe: Websafe
    }
    nameIndexed: string
    updated: string
    websafe: Websafe
}

export type ColumnErrors = "missing-audience" | "missing-email" | "bad-email" | "bad-country"

export type InvalidMembersUploadLines = {
    index: number //* 0-indexed, not counting the header
    errorColumns: ColumnErrors[]
    upload: Upload
}

//* Backend Class CustomAudienceMemberUpload
export type Upload = {
    audience: {
        websafe: string,
        raw: {
            id: number
            kind: string
            parentKey: {
                id: number
                kind: string
                parentKey: {
                    kind: string
                    id: number
                    name: string
                }
            }
        }
    }
    blobKey?: string
    countryIdx: number
    created: string
    done: boolean
    emailIdx: number
    error?: string
    firstIdx: number
    hasInvalids: boolean
    id: number
    invalids: string[]  //! Might be deprecated
    invalidLines: number[]  //! Deprecated in the backend
    lastIdx: number
    location: SerializedKey
    phoneIdx: number
    processed: number
    saved: number
    updated: string
    zipIdx: number
}

export type Label = any

export const LOAD_STATUS = {
    UNLOADED: 'unloaded',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
} as const

export type LoadStatus = typeof LOAD_STATUS[keyof typeof LOAD_STATUS]

export type WeightMap = {
    AdwordsDisplayCampaign?: number;
    AdwordsDisplayRemarketingCampaign?: number;
    AdwordsSearchCampaign?: number;
    AdwordsVideoCampaign?: number;
    FacebookNewsCampaign?: number;
    FacebookNewsRemarketingCampaign?: number;
    FacebookRightCampaign?: number;
    FacebookVideoCampaign?: number;
    FacebookVideoRemarketingCampaign?: number;
    LinkedInCampaign?: number;
    LinkedInVideoCampaign?: number;
};

export type LocationConnections = {
    FACEBOOK_ADVERTISING: {
        status: LocationConnectionStatus
    }
    FACEBOOK_POSTING: {
        status: LocationConnectionStatus
    }
    GOOGLE_ANALYTICS_4: {
        status: LocationConnectionStatus
    }
    GOOGLE_BUSINESS: {
        status: LocationConnectionStatus
    }
    INSTAGRAM_ADVERTISING: {
        status: LocationConnectionStatus
    }
    INSTAGRAM_POSTING: {
        status: LocationConnectionStatus
    }
    LINKEDIN_ADVERTISING: {
        status: LocationConnectionStatus
    }
    LINKEDIN_POSTING: {
        status: LocationConnectionStatus
    }
    TWITTER: {
        status: LocationConnectionStatus
    }
}


export type LocationConnectionStatus = "CONNECTED" | "NOT_CONNECTED" | "NEEDS_CONFIG" | "PENDING"
export type GoogleSpecialAdCategories = "PERSONAL_FINANCE" | "NONE";
export type WeightMapKey = keyof WeightMap
export type FacebookSpecialAdCategories = "HOUSING" | "EMPLOYMENT" | "FINANCIAL_PRODUCTS_SERVICES" | "ISSUES_ELECTIONS_POLITICS" | "ONLINE_GAMBLING_AND_GAMING" | "NONE";

//* Current values for PermissionableLocationField: "ageTargets" | "parentTargets" | "genderTargets" | "incomeTargets" | "linkedInTargets" | "googleUserInterests" | "googleSpecialAdCategory" | "facebookSpecialAdCategory"
//* Typed loosely for future added PermissionableLocationField names
export type PermissionableLocationField = keyof EulerityLocation
export type LocationFieldAccessLevel = "READ_ONLY" | "HIDDEN"