import React, { useMemo } from 'react'
import { AdRanks, DisclaimerContainer, MetricItemWrapper, TrophyCard } from '../styles'
import { BigBody, Body, Small } from '../../../../styles/typography'
import { useCreativeContext } from '../CreativeModalV2'
import { getRanks } from "../../../../helpers/creative.util"
import { getRankedTrophy, getTrophyRank } from '../../../../components/trophy/trophy.util'
import NumberUtil from '../../../../helpers/number.util'
import InfoTooltip from '../../../tooltip/InfoTooltip'
import { BsHourglassBottom } from 'react-icons/bs'

const AdRankings = ({creativeReports}) => {
    const { creative, metadata } = useCreativeContext()
    const { creativeKindLabel } = metadata

    const RANK_TYPE = {
        CTR: 'ctr',
        CLICKS: 'clicks',
        IMPRESSIONS: 'impressions'
    }

    const ctrRanks = getRanks(creativeReports, creative, RANK_TYPE.CTR);
    const clicksRanks = getRanks(creativeReports, creative, RANK_TYPE.CLICKS);
    const impressionsRanks = getRanks(creativeReports, creative, RANK_TYPE.IMPRESSIONS);

    const ctrRank = getTrophyRank(creative, ctrRanks);
    const clickRank = getTrophyRank(creative, clicksRanks);
    const impressionRank = getTrophyRank(creative, impressionsRanks);

    const rankCards = [
        {
            name: 'CTR',
            rank: ctrRank,
            competitors: ctrRanks?.length || 0,
        },
        {
            name: 'Clicks',
            rank: clickRank,
            competitors: clicksRanks?.length || 0,
        },
        {
            name: 'Impressions',
            rank: impressionRank,
            competitors: impressionsRanks?.length || 0,
        },
    ]

    const ranksNotNumbers = useMemo(() => {
        return [ctrRank, clickRank, impressionRank].every(value => !NumberUtil.isNumber(value));
    }, [ctrRank, clickRank, impressionRank]);

    return (
        <AdRanks>
            <div className='header'>
            <BigBody semibold>{creativeKindLabel} Rankings</BigBody>
            <InfoTooltip tooltipContent="Ranks are provided for ads by comparing performance from the past 7 days. Some ads may not be ranked or included in rank count if we don't have sufficient performance data from the past 7 days."/>
            </div>

            {
                ranksNotNumbers ?
                <DisclaimerContainer>
                    <BsHourglassBottom size={30} />
                    <Small>No sufficient performance data from the past 7 days are available.</Small>
                </DisclaimerContainer> :
                <MetricItemWrapper>
                    {
                        rankCards.map((item, idx) => {
                            return <TrophyCard key={idx}>
                                <div className='name-wrapper'>
                                    <Small className='title' semibold>{item.name}</Small>
                                </div>
                                {getRankedTrophy(item?.rank)}
                                <Body bold>#{item?.rank} of {item?.competitors}</Body>
                            </TrophyCard>
                        })
                    }
                </MetricItemWrapper>
            }
        </AdRanks>
    )
}

export default AdRankings