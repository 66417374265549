import React from "react";
import FranchiseSelector from "./FranchiseSelector";
import { ModalContent, ModalTitle, ModalWrapper } from "../../components/modal/ModalV2/components"

const SelectFranchiseModal = () => {
  return (
    <ModalWrapper size={"sm"}>
      <ModalTitle title={"Select a Command Center"}/>
      <ModalContent>
        <FranchiseSelector />
      </ModalContent>
    </ModalWrapper>
  )
}

export default SelectFranchiseModal;
