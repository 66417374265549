import styled from 'styled-components';

export const RenderValuesWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const RenderValueWrap = styled.div`
    display: flex;
    padding: 0.5rem 0.75rem;
    margin: -0.25rem;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    transition: 0.2s;
    border-radius: 9px;

    &:hover {
        background-color: ${props => props.theme.color.accentColor4};
        cursor: pointer;
    }
`;
