import { firebase } from "../services/fire.js";
import { clearFranchiseList } from "../actions/franchiseListActions";
import { clearUser } from "../actions/userActions";
import { connect } from "react-redux";

const SignOut = () => {
    firebase.auth().signOut();
    clearFranchiseList();
    clearUser();
    window.location.reload()
};

connect({clearUser,clearFranchiseList})
export default SignOut;