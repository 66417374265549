import styled from 'styled-components';

export const StyledConfirm = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : 'fit-content'};
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .templateImg {
        max-height: 100px;
        max-width: 100px;
    }
`