import { Small } from "../../../../styles/typography"
import React from "react"
import { AdWithMetadata, Ad } from "../../creative.types"
import { getCreativeLocationType, useCreativeInfo } from "../../utils"
import { getStatusColor } from "../../../../hooks/useThemeControl"
import { sortByCampaignName } from "../../../../pages/locations/helpers/sorters/postSorters/sortByName"
import { sortByUserEmail } from "../../../../pages/locations/helpers/sorters/postSorters/sortByUserEmail"
import { tooltipTextRender } from "../../../../components/reusableUI/components/tooltip/util"
import { useTheme } from "styled-components"
import { hexToRgba } from "../../../../helpers/colors.util"
import { Theme } from "../../../../types/theme"
import getSuperTableComponents from "../../../../components/SuperTable/getSuperTableComponents"
import { isDeployedDynamicAd, isOptionalDynamicAd } from "../../../../helpers/creative.util"
import { useManageClonesContext } from "../ManageClones/useManageClones"
import { displayCampaignName } from "../ManageClones/util"
import { TooltipTrigger } from "../../../tooltip/TooltipTrigger"

const { Wrap, Column } = getSuperTableComponents<AdWithMetadata>()

export const AdInformation = ({ creative }: { creative: Ad }) => {
    const { extendedText, header, description } = creative

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '11.75rem 1fr', height: 'min-content', rowGap: '0.5rem' }}>
            {
                header && header.text?.length > 0 &&
                <>
                    <Small semibold>Header</Small>
                    <Small>{header.text}</Small>
                </>
            }
            {
                description && description.text?.length > 0 &&
                <>
                    <Small semibold>Description</Small>
                    <Small>{description.text}</Small>
                </>
            }
            <Small semibold>Social Text</Small>
            <Small>{extendedText || "No social text."}</Small>
        </div>
    )
}

export const AdCloneTable = ({ onCSVDataUpdated }: { onCSVDataUpdated: (data: string[][]) => void }) => {
    const { creative, clones } = useManageClonesContext()
    const theme: Theme = useTheme()
    const { getCreativeStatus, getCreativeStatusCopy } = useCreativeInfo()
    const showSearchColumns = isDeployedDynamicAd(creative) || isOptionalDynamicAd(creative)

    return (
        <>
            <div style={{ height: '270px', overflow: 'scroll', boxShadow: `inset 0 10px 6px -6px ${hexToRgba(theme.color.accentColor3, 0.2)}` }}>
                <div style={{width: showSearchColumns ? '1500px' : '1000px', height: '270px', position: 'relative'}}>
                    <Wrap items={clones as AdWithMetadata[]} onCSVDataUpdated={onCSVDataUpdated}>
                        <Column
                            title="Type"
                            size="1"
                            filter={getCreativeLocationType}
                            csvData={getCreativeLocationType}
                            >
                            {(item: AdWithMetadata) => {
                                return <Small ellipsis>{getCreativeLocationType(item)}</Small>
                            }}
                        </Column>
                            <Column
                                title='Name'
                                size={2}
                                sort={sortByCampaignName}
                                csvData={item => displayCampaignName(item)}
                                >
                                {(item: AdWithMetadata) => {
                                    return <Small>{displayCampaignName(item)}</Small>
                                }}
                            </Column>
                            <Column
                                title='User'
                                sort={sortByUserEmail}
                                csvData={item => item.metadata.user?.email}
                                >
                                    {(item: AdWithMetadata) => (
                                        <TooltipTrigger
                                            tooltipContent={item.metadata.user?.email}
                                        >
                                            <Small ref={tooltipTextRender} ellipsis>
                                                {item.metadata.user?.email}
                                            </Small>
                                        </TooltipTrigger>
                                    )}
                            </Column>
                            <Column title='Social Text' csvData={item => item.extendedText || 'N/A'} size={2}>
                                {(item: AdWithMetadata) =>
                                    <Small
                                        ref={tooltipTextRender}
                                        ellipsis data-tooltip-id="ad-clone-tooltip"
                                        >
                                        {item.extendedText || 'N/A'}
                                    </Small>}
                            </Column>
                            {
                                showSearchColumns &&
                                <Column title='Search Headlines' csvData={item => item.searchHeadlines.join(',')} size={2}>
                                    {(item: AdWithMetadata) =>
                                        <Small
                                            ref={tooltipTextRender}
                                            ellipsis data-tooltip-id="ad-clone-tooltip"
                                            >
                                            {item.searchHeadlines.join(',')}
                                        </Small>}
                                </Column>
                            }
                            {
                                showSearchColumns &&
                                <Column title='Search Descriptions' csvData={item => item.searchDescriptions.join(',')} size={2}>
                                    {(item: AdWithMetadata) =>
                                        <Small
                                            ref={tooltipTextRender}
                                            ellipsis data-tooltip-id="ad-clone-tooltip"
                                            >
                                            {item.searchHeadlines.join(',')}
                                        </Small>}
                                </Column>
                            }
                            <Column
                                title='Ad Status'
                                filter={getCreativeStatusCopy}
                                csvData={item => getCreativeStatusCopy(item)}
                                >
                                {(item: AdWithMetadata) => {
                                    const status = getCreativeStatus(item)

                                    const statusCopy = getCreativeStatusCopy(item)
                                    const statusColor = getStatusColor(status)

                                    return <Small color={statusColor}>{statusCopy}</Small>
                                }}
                            </Column>
                    </Wrap>
                </div>
            </div>
        </>
    )
}