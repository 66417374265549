import React, { useState } from 'react'
import {
	POST_NETWORK_NAME
} from '../../../../../helpers/creative.util'
import { HR, Small } from '../../../../../styles/typography'
import { Network, Post } from '../../../creative.types'
import NetworkIcon from '../../NetworkIcon'
import {
	NetworkStatusItemWrap
} from '../../styles'
import AnimatedCollapse from '../../../../../components/reusableUI/components/AnimatedCollapse/AnimatedCollapse'
import { NetworkStatusItemActionButton } from './NetworkStatusItemActionButton'
import { NetworkStatusItemDetail } from './NetworkStatusItemDetail'
import { NetworkStatusItemText } from './NetworkStatusItemText'

export const NetworkStatusItem = (props: { post: Post; network: Network }) => {
	const [isDetailExpanded, setIsDetailExpanded] = useState(false)

	return (
		<>
			<NetworkStatusItemWrap>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
					<NetworkIcon network={props.network} />
					<Small semibold>{POST_NETWORK_NAME[props.network]}</Small>
				</div>
				<NetworkStatusItemText
					post={props.post}
					network={props.network}
				/>
				<NetworkStatusItemActionButton
					post={props.post}
					network={props.network}
					isDetailExpanded={isDetailExpanded}
					setIsDetailExpanded={setIsDetailExpanded}
				/>
			</NetworkStatusItemWrap>
			<HR
				style={{
					margin: 0,
					opacity: isDetailExpanded ? 0 : 1,
				}}
			/>
			<AnimatedCollapse isOpen={isDetailExpanded}>
				<NetworkStatusItemDetail
					post={props.post}
					network={props.network}
					setIsDetailExpanded={setIsDetailExpanded}
				/>
			</AnimatedCollapse>
		</>
	)
}
