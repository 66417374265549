import { DateHelper } from '../../../../../../helpers';
import { LocationReport } from '../../../../../../hooks/useLocationReport';

export const getDayReport = (report: LocationReport, date: Date) => {
	return report.data.find(
		(dailyReport) => dailyReport.date === DateHelper.dateToYYMMDD(date, 'YMD')
	);
};

export const numberAggregator = (
	one: number | undefined,
	two: number | undefined
) => {
	if (one === undefined && two === undefined)
		return undefined;
	if (one === undefined)
		return two;
	if (two === undefined)
		return one;

	return one + two;
};

export const numberFormatter = (value: number | undefined) => {
	if (!value)
		return 'N/A';

	return value.toFixed(2);
};

export const reduceNumberArray = (array: (number | undefined)[]) : number | undefined => {
	return array.reduce<number | undefined>((acc, value) => {
		if (value === undefined) return acc;
		if (acc === undefined) return value;
		return acc + value;
	}, undefined);
}
