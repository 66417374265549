import { API } from '../constants/api'
import { BackendTheme } from '../features/themeProvider/types'
import { makeRequest } from './make-request'

export type SidebarStates = 'full' | 'collapsed' | 'hidden'
export const getSidebarState = (oldState: SidebarStates): SidebarStates => {
    if (oldState === 'collapsed' || oldState === 'hidden') return 'full'
    // only explicitly return to the hidden state
    return 'collapsed'
}

export type VersionAPIResponse = {
    ['backend-version']: string
    ['ios-min']: string
    ['android-min']: string
    tosLink: string
    freeDemoLink: string
    helpLink: string
    facebookPermissions: string
    suggestedImageResolution: string
    suggestedLogoResolution: string
    maxLogoResolution: string
    adsExtendedTextMaxLength: number
    postsExtendedTextMaxLength: number
    isProduction: boolean
    firebase_JS: string
    maxCreativesPerLocation: number
    minAdDuration: number
    magicAdAutoDeletionDays: number
    maxImageResolution: string
    dynamicLinkDomain: string
    whitelistedSearchSymbols: string
    dropboxClientAppKey: string
    searchOnlyBlob?: string

    objectives: string[]
    ctas: string[]
    defaultCta: string
    settingsBanner?: {
        text: string
        link: string
        image?: string
    }

    themes: BackendTheme
    defaultPhoneFormats: Record<string, string>
}

export const loadVersionData = () => {
    return makeRequest().url(API.VERSION.GET_VERSION).get()
}
