import { USER_ACCESS } from '../helpers/user.util';
// List of Restricitons
export const RESTRICTIONS = {
    FACEBOOK_SETTING: 'facebook-setting',
    DEMO_SETTING: 'demo-setting',
    POST_DELETE: 'post-delete',
    GOOGLE_ANALYTICS: 'google-analytics',
    CREATIVE_STATUS: 'creative-status',
    KEYWORD_SETTING: 'keyword-setting',
    POST_EDIT: 'post-edit',
    CREATIVE_EDIT: 'creative-edit',
    VIEW_SUBSCRIPTION: 'view-subscription',
    POS_SETTING: 'pos-setting',
    TWITTER_SETTING: 'twitter-setting',
    VIEW_SUGGESTED_BUDGET: 'view-suggested-budget',
    GEOTARGET_SETTING: 'geotarget-setting',
    GOOGLE_AUDIENCE_SETTING: 'google-audience-segments-modify',
    LANDING_PAGE_SETTING: 'landing-page-setting',
    CREATIVE_LANDING_PAGE: 'creative-landing-page',
    LINKEDIN_SETTING: 'linkedin-setting',
    CHAT_GPT_SUGGESTIONS: 'chatgpt-suggestions',
    LINKEDIN_TARGET_SETTING: 'linkedin-target-setting',
    MACROS_SETTING: 'macros-setting',
    FACEBOOK_LEAD_FORMS: 'facebook-lead-form',
    GOOGLE_MY_BUSINESS: 'gmb-setting',
    RMI_LINKEDIN_ACCESS: 'rmi-linkedin-access',
    AD_ASSET_RESTRICTION: 'ad-asset-restriction',
    POST_ASSET_RESTRICTION: 'post-asset-restriction',
    RMI_EULERITY_AI_REVIEW: 'rmi-eulerity-ai',
    RMI_EULERITY_AI_COMMENT: 'rmi-eulerity-ai-reply',

} as const

export type Restriction = typeof RESTRICTIONS[keyof typeof RESTRICTIONS]

// List of Role Access
export const PERMISSIONS = {
    ADMIN: [USER_ACCESS.ADMIN],
    MEMBER: [USER_ACCESS.MEMBER],
    FRANCHISE_SELECT: [USER_ACCESS.ADMIN, USER_ACCESS.MULTI_FRANCHISE_MANAGER],
    FRANCHISE_MANAGEMENT: [USER_ACCESS.ADMIN, USER_ACCESS.MULTI_FRANCHISE_MANAGER, USER_ACCESS.MANAGER]
}

//* List of locationAccessLevelFieldKeys
export const LOCATION_ACCESS_LEVEL_FIELD = {
    AGE_TARGETS: 'ageTargets',
    PARENT_TARGETS: 'parentTargets',
    GENDER_TARGETS: 'genderTargets',
    INCOME_TARGETS: 'incomeTargets',
    KEYWORDS: 'keywords',
    LINKEDIN_TARGETS: 'linkedInTargets',
    GOOGLE_USER_INTERESTS: 'googleUserInterests',
    GOOGLE_SPECIAL_AD_CATEGORY: 'googleSpecialAdCategory',
    FACEBOOK_SPECIAL_AD_CATEGORY: 'facebookSpecialAdCategory',
} as const

export const LOCATION_FIELD_ACCESS_LEVEL = {
    HIDDEN: 'HIDDEN',
    READ_ONLY: 'READ_ONLY'
} as const