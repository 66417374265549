import React from 'react'
import { LoadingIndicator } from '../components/loading/styles'

const PageLoading = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20vh'}}>
        <LoadingIndicator />
    </div>
  )
}

export default PageLoading