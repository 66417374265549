import React from 'react'
import { StyledModalFooterV2 } from '../styles'
import { ModalFooterProps } from '../types'
//* Usage: Use this wrapper component at the bottom of the ModalWrapper
//* Primarily used to contain the CTA buttons at the bottom of modal
//* Will always be justified to the bottom of the modal
//* Handles the spacing from the ModalContent

const ModalFooter = ({
  styles,
  children
} : ModalFooterProps) => {
  return (
    <StyledModalFooterV2 style={styles}>
      {children}
    </StyledModalFooterV2>
  )
}

export default ModalFooter