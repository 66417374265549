type MakeMailToOptions = {
	to: string
	subject?: string
	body?: string
}

export const makeMailToUrl = (options: MakeMailToOptions) => {
	const { to, subject, body } = options
	const toStr = `mailto:${to}`
	const subjectStr = subject ? `?subject=${encodeURIComponent(subject)}` : ''
	const bodyStr = body ? `&body=${encodeURIComponent(body)}` : ''
	return `${toStr}${subjectStr}${bodyStr}`
}