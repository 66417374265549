export const COLORS = {
    // Hard coded colors often associated with a 3rd party brand
    FACEBOOK_BLUE: "#1877F2",
    INSTAGRAM_PURPLE: "#8F3AA8",
    X_ICON: "#1E1E1E",
    LINKEDIN_BLUE: "#0077B5",
    GOOGLE_ANALYTICS_ORANGE: "#F57C00",
    GOOGLE_BUSINESS_BLUE: "#4285F4",
    GOOGLE_TV_GREEN: "#34A853",
    SEARCH_HEADLINE: "#1A0DAB",
    SEARCH_DESC: "#000000",
    SEARCH_COUNT: '#334D6E',
    YOUTUBE_RED: '#FF0000',
    BRAND: {
        // Use: Typography color prop
        ACCENT_COLOR_1: 'accentColor1',
        ACCENT_COLOR_2: 'accentColor2',
        ACCENT_COLOR_3: 'accentColor3',
        ACCENT_COLOR_4: 'accentColor4',
        CREATIVE_COLOR_1: 'creativeColor1',
        CREATIVE_COLOR_2: 'creativeColor2',
        CREATIVE_COLOR_3: 'creativeColor3',
        CREATIVE_COLOR_4: 'creativeColor4',
        TEXT_COLOR_1: 'textColor1',
        TEXT_COLOR_2: 'textColor2',
        TEXT_COLOR_3: 'textColor3',
        WARNING: 'warningButtonColor',
        ACTIVE: 'activeButtonColor',
        ERROR: 'errorNegativeButtonColor',
        BACKGROUND_1: 'backgroundColor1',
        BACKGROUND_2: 'backgroundColor2',
        GREY_ACCENT_1: 'greyAccent1',
        GREY_ACCENT_2: 'greyAccent2',
        NAV_BACKGROUND: 'navbarBackgroundColor',
        NAV_TEXT: 'navbarTextColor',
        NAV_ACTIVE_TEXT: 'navbarActiveTextColor',
    }
} as const