import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Body, XS } from '../../styles/typography';
import { ModalNav, ModalWrapper } from '../modal/ModalV2/components';

type Size = {
    size: number;
    unit: 'KB' | 'MB';
}

type  LargestStateResult = {
    largestKey: string | null;
    largestSize: string;
    totalSize: string;
}

const loadScript = (src: string, id: string): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (document.getElementById(id)) {
			resolve();
			return;
		}
		const script = document.createElement('script');
		script.src = src;
		script.id = id;
		script.onload = () => resolve();
		script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
		document.body.appendChild(script);
	});
};


const loadStyle = (href: string, id: string): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (document.getElementById(id)) {
			resolve();
			return;
		}
		const link = document.createElement('link');
		link.href = href;
		link.rel = 'stylesheet';
		link.id = id;
		link.onload = () => resolve();
		link.onerror = () => reject(new Error(`Failed to load style: ${href}`));
		document.head.appendChild(link);
	});
};



function sizeOfObject(obj: any): Size {
    const jsonString = JSON.stringify(obj);
    const sizeInBytes = jsonString.length * 2;
    const sizeInKB = sizeInBytes / 1024;

    if (sizeInKB < 1000) {
        return { size: sizeInKB, unit: 'KB' };
    } else {
        const sizeInMB = sizeInKB / 1024;
        return { size: sizeInMB, unit: 'MB' };
    }
}

function findLargestState(obj: Record<string, any>): LargestStateResult {
    let largestKey: string | null = null;
    let largestSize: Size = { size: 0, unit: 'KB' };
    let totalSizeInBytes = 0;

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const currentSize = sizeOfObject(obj[key]);

            const currentSizeInBytes = currentSize.unit === 'KB' ? currentSize.size * 1024 : currentSize.size * 1024 * 1024;
            totalSizeInBytes += currentSizeInBytes;

            const largestSizeInBytes = largestSize.unit === 'KB' ? largestSize.size * 1024 : largestSize.size * 1024 * 1024;
            if (currentSizeInBytes > largestSizeInBytes) {
                largestKey = key;
                largestSize = currentSize;
            }
        }
    }

    let totalSize: Size = { size: totalSizeInBytes / 1024, unit: 'KB' };
    if (totalSize.size >= 1000) {
        totalSize = { size: totalSize.size / 1024, unit: 'MB' };
    }

    return {
        largestKey: largestKey,
        largestSize: `${largestSize.size.toFixed(2)} ${largestSize.unit}`,
        totalSize: `${totalSize.size.toFixed(2)} ${totalSize.unit}`
    };
}

function detectCyclicalState (obj: any) {
	const keys = []
	for(let key in obj) {
		try {
			JSON.stringify(obj[key])
		} catch (error) {
			keys.push(key)
		}
	}

	return keys
}

const JsonEditorWrap = styled.div`
	max-height: 68vh;
	.jsoneditor-tree {
		background-color: ${props => props.theme.color.accentColor4};
	}

	.jsoneditor-field, .jsoneditor-value, .jsoneditor-readonly {
		color: ${props => props.theme.color.textColor1}
	}

 	.jsoneditor-frame input {
		width: unset;
		display: unset;
	}
`

const ReduxStateModal = ({ onClose }: { onClose: () => void }) => {
	const state = useSelector(s => s)
	const [stateSize, setStateSize] = useState<LargestStateResult>();
	const [cyclicalReducersState, setCyclicalReducersState] = useState<string[]>([]);

	useEffect(() => {
		const loadDependencies = async () => {
			try {
				await loadStyle('https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.5.6/jsoneditor.min.css', 'jsoneditor-css');
				await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.5.6/jsoneditor.min.js', 'jsoneditor-js');
				await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.5.6/jsoneditor-minimalist.min.js', 'jsoneditor-minimalist-js');

				const jsonViewer = (jsonData: any, id: string) => {
					const jsonv = document.getElementById(id);
					if (!jsonv) return;

					const options = {
						mode: 'view' as 'view' | 'code',
						modes: ['view', 'code'],
						readOnly: true,

					};
					// @ts-ignore
					const editor = new JSONEditor(jsonv, options);
					if (!editor) return;
					editor.set(jsonData || {});
				};

				const cyclicalStates = detectCyclicalState(state);
				const removeCyclicalStates = cyclicalStates.reduce((obj: Record<string, any>, el: string) => {
					obj[el] = {};
					return obj;
				}, {} as Record<string, any>);

				let copiedState = structuredClone({...state, ...removeCyclicalStates})
				setCyclicalReducersState(cyclicalStates);
				setStateSize(findLargestState(copiedState))
				jsonViewer(copiedState, 'json-editor-container');

			} catch (error) {
				console.error('Error loading JSON editor dependencies', error);
			}
		};
		loadDependencies();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ModalWrapper size="full" onClose={onClose} styles={{height: '90vh', maxHeight: '90vh', maxWidth: '90wh'}}>
			<ModalNav/>
			<Body style={{marginBottom: '5px'}}>Total State Size <strong>{stateSize?.totalSize+ " "}</strong>
			 --  Largest Reducer <strong>{stateSize?.largestKey + " " + stateSize?.largestSize}</strong>
			</Body>
			<Body style={{marginBottom: '5px'}}>Current Cyclical Reducers: <strong>{cyclicalReducersState.join(",")}</strong></Body>
			{cyclicalReducersState.length && <XS style={{marginBottom: '10px'}}><strong>Note: </strong>Cyclical reducers are removed from the copied state and will not affect development.</XS>}
			{cyclicalReducersState.length && <XS style={{marginBottom: '10px'}}>In Redux, cyclical state is problematic because it prevents state serialization, causes unpredictable behavior, makes debugging difficult, and can lead to infinite loops or stack overflows during state updates. </XS>}
			<JsonEditorWrap id="json-editor-container"/>
		</ModalWrapper>
	);
};

export default ReduxStateModal;