import React from 'react'

type GoogleIconProps =  {
    invert?: boolean
} & React.SVGProps<SVGSVGElement>

export const GoogleIcon = (props: GoogleIconProps) => {
	return (
		<svg
			width={props.width || "20"}
			height={props.height || "20"}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.1984 20.4544C39.1984 19.0363 39.0711 17.6726 38.8348 16.3635H19.9984V24.0999H30.762C30.2984 26.5999 28.8893 28.7181 26.7711 30.1363V35.1544H33.2348C37.0166 31.6726 39.1984 26.5453 39.1984 20.4544Z"
				fill={props.invert ? 'white' : "#4285F4"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.9967 40C25.3967 40 29.924 38.2091 33.2331 35.1546L26.7695 30.1364C24.9786 31.3364 22.6877 32.0455 19.9967 32.0455C14.7877 32.0455 10.3786 28.5273 8.80584 23.8H2.12402V28.9819C5.41493 35.5182 12.1786 40 19.9967 40Z"
				fill={props.invert ? 'white' : "#34A853"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.80909 23.8001C8.40909 22.6001 8.18182 21.3183 8.18182 20.0001C8.18182 18.6819 8.40909 17.4001 8.80909 16.2001V11.0183H2.12727C0.772727 13.7183 0 16.7729 0 20.0001C0 23.2274 0.772727 26.2819 2.12727 28.9819L8.80909 23.8001Z"
				fill={props.invert ? 'white' : "#FBBC05"}

			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.9967 7.95455C22.9331 7.95455 25.5695 8.96364 27.6422 10.9455L33.3786 5.20909C29.9149 1.98182 25.3877 0 19.9967 0C12.1786 0 5.41493 4.48182 2.12402 11.0182L8.80584 16.2C10.3786 11.4727 14.7877 7.95455 19.9967 7.95455Z"
				fill={props.invert ? 'white' : "#EA4335"}
			/>
		</svg>
	)
}
