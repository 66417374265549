import React from 'react'
import ModalV2 from '../../../components/modal/ModalV2/ModalV2'
import { Body, Small } from '../../../styles/typography'
import Button from '../../../components/button/Button'
import { StyledTable } from '../BulkEditConfirmation'
import { ApiData, SubscriptionHeaders } from '../hooks/useBulkSubscription'
import getSuperTableComponents from '../../../components/SuperTable/getSuperTableComponents'

const SubscriptionWarningModal = ({
  handleCloseModal,
  handleSubmit,
  subscriptionStatusChanges,
  headers,
} : {
  handleCloseModal: () => void
  handleSubmit: () => void
  subscriptionStatusChanges: ApiData[]
  headers: SubscriptionHeaders[]
}) => {
  const { Wrap, Column } = getSuperTableComponents<ApiData>()

  return (
    <ModalV2
      size={"full"}
      title={"Are you sure you want to continue submitting these changes?"}
      buttons={[
        <Button variant="outline" onClick={handleCloseModal}>Keep Editing</Button>,
        <Button onClick={() => {handleSubmit(); handleCloseModal();}}>Submit</Button>
      ]}
    >
      <Body style={{ marginBottom: '2rem' }}>The following campaigns will be paused or resumed by this bulk action.</Body>
      <StyledTable boldNthLastRow={2}>
        <Wrap items={subscriptionStatusChanges}>
          {headers.map(header => {
            return (
              <Column key={header} title={header}>
                {lineItem => <Small>{lineItem.rowData[header]}</Small>}
              </Column>
            )
          })}
        </Wrap>
      </StyledTable>
    </ModalV2>
  )
}

export default SubscriptionWarningModal