import React from 'react'
import { Theme } from '../../types/theme'
import { useTheme } from 'styled-components'

export const FirstPlaceTrophy = () => {
  const theme: Theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.68 1.44H19.92V0.24C19.92 0.108 19.812 0 19.68 0H4.32C4.188 0 4.08 0.108 4.08 0.24V1.44H1.32C0.969914 1.44 0.634167 1.57907 0.386619 1.82662C0.139071 2.07417 0 2.40991 0 2.76V7.2C0 9.651 1.8 11.688 4.146 12.06C4.608 15.546 7.41 18.294 10.92 18.675V21.831H5.04C4.509 21.831 4.08 22.26 4.08 22.791V23.76C4.08 23.892 4.188 24 4.32 24H19.68C19.812 24 19.92 23.892 19.92 23.76V22.791C19.92 22.26 19.491 21.831 18.96 21.831H13.08V18.675C16.59 18.294 19.392 15.546 19.854 12.06C22.2 11.688 24 9.651 24 7.2V2.76C24 2.40991 23.8609 2.07417 23.6134 1.82662C23.3658 1.57907 23.0301 1.44 22.68 1.44ZM4.08 9.828C2.967 9.471 2.16 8.427 2.16 7.2V3.6H4.08V9.828ZM21.84 7.2C21.84 8.43 21.033 9.474 19.92 9.828V3.6H21.84V7.2Z" fill={theme.color.accentColor2} />
      <path d="M13.6963 13.0947H11.4331V6.89844C11.4331 6.72754 11.4355 6.51758 11.4404 6.26855C11.4453 6.01465 11.4526 5.75586 11.4624 5.49219C11.4722 5.22363 11.4819 4.98193 11.4917 4.76709C11.438 4.83057 11.3281 4.94043 11.1621 5.09668C11.001 5.24805 10.8496 5.38477 10.708 5.50684L9.47754 6.49561L8.38623 5.1333L11.8359 2.38672H13.6963V13.0947Z" fill="white" />
    </svg>
  )
}

export const SecondPlaceTrophy = () => {
  const theme: Theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.68 1.44H19.92V0.24C19.92 0.108 19.812 0 19.68 0H4.32C4.188 0 4.08 0.108 4.08 0.24V1.44H1.32C0.969914 1.44 0.634167 1.57907 0.386619 1.82662C0.139071 2.07417 0 2.40991 0 2.76V7.2C0 9.651 1.8 11.688 4.146 12.06C4.608 15.546 7.41 18.294 10.92 18.675V21.831H5.04C4.509 21.831 4.08 22.26 4.08 22.791V23.76C4.08 23.892 4.188 24 4.32 24H19.68C19.812 24 19.92 23.892 19.92 23.76V22.791C19.92 22.26 19.491 21.831 18.96 21.831H13.08V18.675C16.59 18.294 19.392 15.546 19.854 12.06C22.2 11.688 24 9.651 24 7.2V2.76C24 2.40991 23.8609 2.07417 23.6134 1.82662C23.3658 1.57907 23.0301 1.44 22.68 1.44ZM4.08 9.828C2.967 9.471 2.16 8.427 2.16 7.2V3.6H4.08V9.828ZM21.84 7.2C21.84 8.43 21.033 9.474 19.92 9.828V3.6H21.84V7.2Z" fill={theme.color.accentColor2} />
      <path d="M15.5859 13.0947H8.10059V11.52L10.7886 8.80273C11.3306 8.24609 11.7651 7.78223 12.0923 7.41113C12.4243 7.03516 12.6636 6.69336 12.8101 6.38574C12.9614 6.07812 13.0371 5.74854 13.0371 5.39697C13.0371 4.97217 12.9175 4.65479 12.6782 4.44482C12.4438 4.22998 12.1289 4.12256 11.7334 4.12256C11.3184 4.12256 10.9155 4.21777 10.5249 4.4082C10.1343 4.59863 9.72656 4.86963 9.30176 5.22119L8.07129 3.76367C8.37891 3.5 8.70361 3.25098 9.04541 3.0166C9.39209 2.78223 9.79248 2.59424 10.2466 2.45264C10.7056 2.30615 11.2549 2.23291 11.8945 2.23291C12.5977 2.23291 13.2007 2.35986 13.7036 2.61377C14.2114 2.86768 14.6021 3.21436 14.8755 3.65381C15.1489 4.08838 15.2856 4.58154 15.2856 5.1333C15.2856 5.72412 15.1685 6.26367 14.9341 6.75195C14.6997 7.24023 14.3579 7.72363 13.9087 8.20215C13.4644 8.68066 12.9272 9.21045 12.2974 9.7915L10.9204 11.0879V11.1904H15.5859V13.0947Z" fill="white" />
    </svg>

  )
}

export const ThirdPlaceTrophy = () => {
  const theme: Theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.68 1.44H19.92V0.24C19.92 0.108 19.812 0 19.68 0H4.32C4.188 0 4.08 0.108 4.08 0.24V1.44H1.32C0.969914 1.44 0.634167 1.57907 0.386619 1.82662C0.139071 2.07417 0 2.40991 0 2.76V7.2C0 9.651 1.8 11.688 4.146 12.06C4.608 15.546 7.41 18.294 10.92 18.675V21.831H5.04C4.509 21.831 4.08 22.26 4.08 22.791V23.76C4.08 23.892 4.188 24 4.32 24H19.68C19.812 24 19.92 23.892 19.92 23.76V22.791C19.92 22.26 19.491 21.831 18.96 21.831H13.08V18.675C16.59 18.294 19.392 15.546 19.854 12.06C22.2 11.688 24 9.651 24 7.2V2.76C24 2.40991 23.8609 2.07417 23.6134 1.82662C23.3658 1.57907 23.0301 1.44 22.68 1.44ZM4.08 9.828C2.967 9.471 2.16 8.427 2.16 7.2V3.6H4.08V9.828ZM21.84 7.2C21.84 8.43 21.033 9.474 19.92 9.828V3.6H21.84V7.2Z" fill={theme.color.accentColor2} />
      <path d="M15.1685 4.78174C15.1685 5.2749 15.0659 5.70215 14.8608 6.06348C14.6558 6.4248 14.3774 6.72021 14.0259 6.94971C13.6792 7.1792 13.2886 7.34766 12.854 7.45508V7.49902C13.7134 7.60645 14.3652 7.87012 14.8096 8.29004C15.2588 8.70996 15.4834 9.27148 15.4834 9.97461C15.4834 10.5996 15.3296 11.1587 15.022 11.6519C14.7192 12.145 14.2505 12.5332 13.6157 12.8164C12.981 13.0996 12.1631 13.2412 11.1621 13.2412C10.5713 13.2412 10.0195 13.1924 9.50684 13.0947C8.99902 13.002 8.52051 12.8579 8.07129 12.6626V10.7363C8.53027 10.9707 9.01123 11.1489 9.51416 11.271C10.0171 11.3882 10.4858 11.4468 10.9204 11.4468C11.731 11.4468 12.2974 11.3076 12.6196 11.0293C12.9468 10.7461 13.1104 10.3506 13.1104 9.84277C13.1104 9.54492 13.0347 9.29346 12.8833 9.08838C12.7319 8.8833 12.4683 8.72705 12.0923 8.61963C11.7212 8.51221 11.2012 8.4585 10.5322 8.4585H9.71924V6.72266H10.5469C11.2061 6.72266 11.7065 6.66162 12.0483 6.53955C12.395 6.4126 12.6294 6.2417 12.7515 6.02686C12.8784 5.80713 12.9419 5.55811 12.9419 5.27979C12.9419 4.89893 12.8247 4.60107 12.5903 4.38623C12.356 4.17139 11.9653 4.06396 11.4185 4.06396C11.0767 4.06396 10.7642 4.10791 10.481 4.1958C10.2026 4.27881 9.95117 4.38135 9.72656 4.50342C9.50195 4.62061 9.3042 4.73535 9.1333 4.84766L8.08594 3.2876C8.50586 2.98486 8.99658 2.7334 9.55811 2.5332C10.1245 2.33301 10.7983 2.23291 11.5796 2.23291C12.6831 2.23291 13.5571 2.45508 14.2017 2.89941C14.8462 3.34375 15.1685 3.97119 15.1685 4.78174Z" fill="white" />
    </svg>

  )
}

export const GenericTrophy = () => {
  const theme: Theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.68 1.44H19.92V0.24C19.92 0.108 19.812 0 19.68 0H4.32C4.188 0 4.08 0.108 4.08 0.24V1.44H1.32C0.969914 1.44 0.634167 1.57907 0.386619 1.82662C0.139071 2.07417 0 2.40991 0 2.76V7.2C0 9.651 1.8 11.688 4.146 12.06C4.608 15.546 7.41 18.294 10.92 18.675V21.831H5.04C4.509 21.831 4.08 22.26 4.08 22.791V23.76C4.08 23.892 4.188 24 4.32 24H19.68C19.812 24 19.92 23.892 19.92 23.76V22.791C19.92 22.26 19.491 21.831 18.96 21.831H13.08V18.675C16.59 18.294 19.392 15.546 19.854 12.06C22.2 11.688 24 9.651 24 7.2V2.76C24 2.40991 23.8609 2.07417 23.6134 1.82662C23.3658 1.57907 23.0301 1.44 22.68 1.44ZM4.08 9.828C2.967 9.471 2.16 8.427 2.16 7.2V3.6H4.08V9.828ZM21.84 7.2C21.84 8.43 21.033 9.474 19.92 9.828V3.6H21.84V7.2Z" fill={theme.color.accentColor2} />
    </svg>
  )
}
