import styled, { css } from 'styled-components';
import { ColorTheme } from '../../helpers/colors.util';

type WrapProps = {
    useAlternateStyles?: boolean;
};

type WrapGridProps = {
    useAlternateStyles?: boolean;
    selectable?: boolean;
    columnCount: number;
    columnWidths?: string[];
};

export const WrapGrid = styled.div<WrapGridProps>`
    ${(props) =>
        !props.useAlternateStyles &&
        css`
        display: grid;
        grid-template-columns: ${props.selectable ? `min-content repeat(${props.columnCount -1}, 1fr)` : `repeat(${props.columnCount}, 1fr)`};
        grid-auto-rows: min-content;

        height: 100%;
        overflow: auto;

        position: relative;

        .supertable-header-row-data {
            position: sticky;
            top: 0;

            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
            text-align: left;
            white-space: nowrap;

            border-bottom: solid 1px ${props.theme.color.accentColor2};
            background-color: ${props.theme.color.backgroundColor2};
            padding: 1rem;
        }

        .supertable-row-data {
            width: 100%;
            height: auto;
            transition: 0.2s;

            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            font-size: 14px;
            padding: 1rem;
        }
    `}
`;

export const RowSeparator = styled.div<{ color?: keyof ColorTheme }>`
    grid-column: 1 / -1;
    border-bottom: solid 1px ${props => props.color ? props.theme.color[props.color] : props.theme.color.greyAccent2};
`

export const Wrap = styled.div<WrapProps>`
    ${(props) =>
        !props.useAlternateStyles &&
        `
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;

        .supertable-header {
            padding: 0.5rem 2rem;
            border-bottom: solid 1px ${props.theme.color.accentColor3};
            flex-shrink: 0;

            overflow: visible !important;
        }

        .supertable-header-row {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .supertable-header-row-data {
            text-align: left;

            display: flex;
            justify-content: flex-start;
        }

        .supertable-body {
            flex-grow: 1;

            &::-webkit-scrollbar {
                display:none !important;
            }
        }

        .supertable-row {
            padding: 0.5rem 2rem;
            border-bottom: solid 1px ${props.theme.color.greyAccent1};
            transition: 0.2s;
        }

        /* Since adding the footer, this is how we access the actual row component */
        .supertable-row > div {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .supertable-row--selected {
            background-color: ${props.theme.color.accentColor4};
        }

        .supertable-row:last-of-type {
            border-bottom: none;
        }

        .supertable-row-data {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            font-size: 14px;
        }
    `}
`;

export const Row = styled.div`
    display: flex;
    width: 100%;

    .supertable-header-row-data {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
            color: ${(props) => props.theme.color.textColor1};
            cursor: pointer;
        }
    }

    .clickable {
        cursor: pointer;
    }
`;

export const SuperTableColumnFilterWrap = styled.div`
    position: absolute;
    left: 0px;
    top: calc(100% + 4px);
    background-color: ${props => props.theme.color.backgroundColor2};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
    padding: 1rem;
    min-width: 150px;
    z-index: 1000;
`

export const SuperTableCustomFilterCloseTarget = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
`