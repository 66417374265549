import React from 'react'
import { CloneItem } from './styles'
import DisplayMedia from '../CreativeModalV2/DisplayMedia'
import { Body } from '../../../styles/typography'
import { CREATIVE_KIND } from '../../../helpers/creative.util'
import SearchOnlyTableCard from '../../searchOnlyCard/SearchOnlyTableCard'
import { isSearchOnlyCampaign } from '../../locations/util'

const CloneTableItem = ({ creative, targetDestination }) => {
    const { category, nickname } = targetDestination;

    return (
        <CloneItem>
            <div className='media'>
                {
                    (isSearchOnlyCampaign(targetDestination) && creative?.kind === CREATIVE_KIND.CREATIVE) ?
                        <SearchOnlyTableCard creative={creative} /> :
                        <DisplayMedia creative={creative} />
                }
            </div>
            <div className='clone-info'>
                <Body bold>{nickname}</Body>
                <Body>{category}</Body>
            </div>
        </CloneItem>
    )
}

export default CloneTableItem