import styled, { css, keyframes } from 'styled-components'

interface LoadingBoxProps {
    loading: boolean,
    duration: string,
    easing: string,
}

export const LoadingBox = styled.div<LoadingBoxProps>`
    animation: ${({ loading }) =>
    loading
      ? css`
          ${opacityAnimation} 0.7s linear forwards
        `
      : css`
          ${updateAnimation} 0.7s linear forwards
        `};
`

const opacityAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`

const updateAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`