import React from 'react'
import { Link } from 'react-router-dom'
import { POST, PostFailureReason } from '../../../../../constants/creative'
import { PATH } from '../../../../../constants/urlPath'
import { POST_NETWORK_TO_CONNECTION } from '../../../../../features/connections/constants'
import { Network, Post, PostWithMetadata } from '../../../../../features/creatives/creative.types'
import { getLocationConnections } from '../../../../../features/locations/util'
import { getNetworkName } from '../../../../../helpers/creative.util'
import { XS } from '../../../../../styles/typography'

const isConnectionIssue = (reason: PostFailureReason) => {
	return (
		reason === POST.REASONS.PAGE_ID ||
		reason === POST.REASONS.ACCOUNT ||
		reason === POST.REASONS.ACCESS
	) ?? false
}

/**
 *
 * @param post the post to check, must have location attached via metadata
 * @param network the network in question
 * @returns true if the target network failed due to a connection issue AND that network still is not connected
 */
export const isCurrentConnectionIssue = (post: PostWithMetadata, network: Network) => {
	const connections = getLocationConnections(post.metadata.location)
	const isCurrentConnectionIssue = connections[POST_NETWORK_TO_CONNECTION[network]] === 0
	const reason = post.postedNetworks?.[network]?.reason

	if (!reason) return false

	if (isConnectionIssue(reason) && isCurrentConnectionIssue) {
		return true
	}

	return false
}

type PostFailureDetail = {
	reason: PostFailureReason | undefined
	isPersistent: boolean
	network: Network
}

export const getPostFailureDetail = (
	post: PostWithMetadata,
	network: Network,
	reason?: PostFailureReason
) : PostFailureDetail => {

	if (!reason) {
		return {
			reason,
			network,
			isPersistent: false
		}
	}

	const isPersistentConnectionIssue = isCurrentConnectionIssue(post, network)

	if (reason === POST.REASONS.PAGE_ID) {
		return {
			reason: POST.REASONS.PAGE_ID,
			network,
			isPersistent: isPersistentConnectionIssue
		}
	}

	if (reason === POST.REASONS.ACCOUNT) {
		return {
			reason: POST.REASONS.ACCOUNT,
			network,
			isPersistent: isPersistentConnectionIssue
		}
	}

	if (reason === POST.REASONS.ACCESS) {
		return {
			reason: POST.REASONS.ACCESS,
			network,
			isPersistent: isPersistentConnectionIssue
		}
	}

	if (reason === POST.REASONS.SUBSCRIPTION) {
		return {
			reason: POST.REASONS.SUBSCRIPTION,
			network,
			isPersistent: !post.metadata.location?.subscriptions?.length
		}
	}

	return {
		reason,
		network,
		isPersistent: false
	}
}

export const getPostFailureDetailCopy = (
	detail: PostFailureDetail
): string => {
	if (!detail.reason) {
		return `Couldn't post to ${getNetworkName(detail.network)}.`
	}

	if (detail.reason === POST.REASONS.PAGE_ID) {
		if (!detail.isPersistent) {
			return `Couldn't post to ${getNetworkName(
				detail.network
			)}- user page previously disconnected.`
		}

		return `Couldn't post to ${getNetworkName(
			detail.network
		)}- user page disconnected.`
	}

	if (detail.reason === POST.REASONS.ACCOUNT) {
		if (!detail.isPersistent) {
			return `Couldn't post to ${getNetworkName(
				detail.network
			)}- user account previously disconnected.`
		}

		return `Couldn't post to ${getNetworkName(
			detail.network
		)}- user account disconnected.`
	}

	if (detail.reason === POST.REASONS.ACCESS) {
		if (!detail.isPersistent) {
			return `Couldn't post to ${getNetworkName(
				detail.network
			)}- account access previously not granted.`
		}

		return `Couldn't post to ${getNetworkName(
			detail.network
		)}- account access needed.`
	}

	if (detail.reason === POST.REASONS.SUBSCRIPTION) {
		if (detail.isPersistent) {
			return `Couldn't post to ${getNetworkName(
				detail.network
			)}- campaign was previously not subscribed.`
		}

		return `Couldn't post to ${getNetworkName(
			detail.network
		)}- campaign is not subscribed.`
	}

	return `Couldn't post to ${getNetworkName(detail.network)}.`
}


const getPostFailureReasonElement = (
	post: PostWithMetadata,
	network: Network,
	reason?: PostFailureReason,
) => {
	const detail = getPostFailureDetail(post, network, reason)
	const networkName = getNetworkName(network)

	if (!reason || !isConnectionIssue(reason)) {
		return <div>{getPostFailureDetailCopy(detail)}</div>
	}

	// special handling for this only
	if (isCurrentConnectionIssue(post, network)) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<XS bold color="errorNegativeButtonColor">
					{networkName} Disconnected
				</XS>
				<XS>
					Posting access not granted. Connect {networkName} to publish
					posts.
				</XS>
				<Link to={`${PATH.SOCIAL_POSTING}/settings/connections`}>
					<XS color="errorNegativeButtonColor">Connect Platform</XS>
				</Link>
			</div>
		)
	}
}

const getCommonPostFailureReason = (posts: Post[]): {
	commonReason: PostFailureReason | null
	issues: { reason: PostFailureReason | undefined; network: Network }[]
} => {
	const issues = posts
		.map((post) =>
			Object.entries(post.postedNetworks || {}).map(
				([network, networkStatus]) => ({
					reason: networkStatus?.reason,
					network: network as Network,
				})
			)
		)
		.flat()

	// no issues at all
	if (issues.length === 0) {
		return {
			commonReason: null,
			issues: [],
		}
	}

	let commonReason: PostFailureReason | null = issues[0].reason ?? null

	for (const issue of issues) {
		if (commonReason !== issue.reason) {
			commonReason = null
			break
		}
	}

	return {
		commonReason,
		issues,
	}
}

export const getCommonPostOverallFailureReason = (posts: PostWithMetadata[] = []) => {
	const { commonReason, issues } = getCommonPostFailureReason(posts)

	if (!commonReason) {
		// generic message
		return 'We could not post to some of the networks. Click a post to learn more about why it failed.'
	}

	if (
		isConnectionIssue(commonReason) &&
		issues.every(({ network }) => network === issues[0].network)
	) {
		const failureDetail = {
			reason: commonReason,
			isPersistent: true,
			network: issues[0].network,
		}
		return getPostFailureDetailCopy(failureDetail)
	}

	if (isConnectionIssue(commonReason)) {
		return 'Posting access not granted. Confirm your connected platforms to publish.'
	}

	if (commonReason === POST.REASONS.SUBSCRIPTION) {
		return 'We could not post to some networks because this campaign was not subscribed at the desired post time.'
	}
}

export const getCommonPostOverallFailureReasonElement = (posts: PostWithMetadata[] = []): React.ReactNode => {
	const { commonReason, issues } = getCommonPostFailureReason(posts)
	const copy = getCommonPostOverallFailureReason(posts)

	if (!issues.length) {
		return null
	}

	if (!commonReason || !isConnectionIssue(commonReason)) {
		return <div>{copy}</div>
	}

	// all issues are connection issues
	if (isConnectionIssue(commonReason)) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<XS bold color="errorNegativeButtonColor">
					Check Your Platform Connections
				</XS>
				<XS>{copy}</XS>
				<Link to={`${PATH.SOCIAL_POSTING}/settings/connections`}>
					<XS color="errorNegativeButtonColor">
						Connect Your Platforms
					</XS>
				</Link>
			</div>
		)
	}
}

export const getCommonPostFailureReasonElement = (
	posts: PostWithMetadata[] = [],
	selectedNetwork: Network | 'total',
) => {
	if (selectedNetwork === 'total') {
		return getCommonPostOverallFailureReasonElement(posts)
	}
	const reasons = posts
		.map((post) =>
			Object.entries(post.postedNetworks || {}).map(
				([network, networkStatus]) => ({
					reason: networkStatus?.reason,
					network: network as Network,
				})
			)
		)
		.flat()
		.filter((reason) => reason.network === selectedNetwork)

	// there are no failed posts for this network
	if (reasons.length === 0) {
		return null
	}

	// if they all have the same reason, show that reason
	if (reasons.every(({ reason }) => reason === reasons[0].reason)) {
		return getPostFailureReasonElement(
			posts[0],
			reasons[0].network,
			reasons[0].reason
		)
	}

	// show generic message for the network
	return getPostFailureReasonElement(posts[0], selectedNetwork)
}
