import React from 'react'
import { OutlineBtn } from '../../styles/buttons'
import { Body, H2 } from '../../styles/typography'
import ModalV2 from '../../components/modal/ModalV2/ModalV2'

const TEXT = {
    SETTINGS: {
        header: 'Doublecheck Your Landing Page',
        cancel_btn: 'Keep editing',
        confirm_btn: 'Save anyways',
    },
    CREATE: {
        header: 'Is Your Landing Page Valid?',
        cancel_btn: 'No, keep editing',
        confirm_btn: 'Yes, continue',
    }
}

type InvalidURLWarningProps = {
    url: string
    onClose: () => void
    onConfirm: () => void
    isSettings?: boolean
}
const InvalidURLWarning = ({ url, onClose, onConfirm, isSettings }: InvalidURLWarningProps) => {
    const TEXT_COPY = isSettings ? TEXT.SETTINGS : TEXT.CREATE

    return (
        <ModalV2
            size='sm'
            onClose={onClose}
            buttons={[
                <OutlineBtn onClick={onClose}>{TEXT_COPY.cancel_btn}</OutlineBtn>,
                <OutlineBtn onClick={onConfirm}>{TEXT_COPY.confirm_btn}</OutlineBtn>
            ]}>
                <H2 center>{TEXT_COPY.header}</H2>
                <Body center style={{margin: '24px auto'}}>
                    Our system was not able to validate your landing page URL. Please make sure your landing page leads to an active page.
                </Body>
                <Body center underline color='accentColor2'>
                    <a href={url} target='_blank' rel='noreferrer'  style={{wordBreak: 'break-all'}}>
                        {url}
                    </a>
                </Body>
        </ModalV2>
    )
}

export default InvalidURLWarning