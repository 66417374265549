import React from 'react';
import { PrimaryCTABtn, OutlineBtn, ConfirmBtn, DangerBtn, BackgroundBtn } from '../../styles/buttons';
import styled, { StyledComponent } from 'styled-components';

type ButtonVariant = 'primary' | 'outline' | 'confirm' | 'danger' | 'background';

type ButtonProps = {
  variant?: ButtonVariant;
  isLoading?: boolean;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

type ButtonComponentProps = React.HTMLProps<HTMLButtonElement> & {
  styles?: React.CSSProperties;
}

type ButtonComponentType = StyledComponent<'button', any, ButtonComponentProps, never>

const buttonMap: Record<ButtonVariant, ButtonComponentType> = {
  "primary": PrimaryCTABtn,
  "outline": OutlineBtn,
  "confirm": ConfirmBtn,
  "danger": DangerBtn,
  "background": BackgroundBtn,
};

const LoadingSpinner = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: ${props => `0.25rem solid ${props.theme.color.navbarTextColor}`};
  border-top-color: ${props => props.theme.color.accentColor2};
  border-radius: 50%;
  animation: loading 0.75s ease infinite;

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({
  variant = "primary",
  isLoading = false,
  disabled = false,
  children,
  ...rest
} : ButtonProps) => {
  const ButtonComponent = buttonMap[variant];

  return (
    <ButtonComponent disabled={isLoading || disabled} {...rest}>
      {isLoading ? <LoadingSpinner /> : children}
    </ButtonComponent>
  );
};

export default Button;
