import React from "react";
import styled, { useTheme } from "styled-components";
import { FiSettings, FiSliders, FiActivity, FiFolder, FiLock, FiLogOut, FiCalendar, FiPhoneForwarded } from "react-icons/fi";
import { useThemeControl } from "../../hooks/useThemeControl";

export type IconProps = {
    color?: string;
    $active?: boolean; // note: prefix with a $ for transient props, this means they wont be passed along to the underlying DOM element
    size?: string;
    strokeWidth?: string,
}

//* Custom Icons
export const CampaignsIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 7L21 3V19L15.5 17M8 7H5C4 7 3 8 3 9C3 9 3 11 3 12C3 13 4 14 5 14C6 14 8 14 8 14M8 7V14M8 14L10 15M10 15L8.5 18.5L13.5 20.5L15.5 17M10 15L15.5 17"
                stroke={color} strokeWidth="2" strokeLinejoin="round"/>
        </svg>
    )
}

export const BlueprintsIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5242_21973)">
                <path
                    d="M19 6V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1L3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3L1 17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H6M19 14V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H14"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M12.7175 12.7175L19.435 6.00003L22.9706 9.53556L9.53553 22.9706L6 19.4351L9.35876 16.0763M12.7175 12.7175L14.1317 14.1318M12.7175 12.7175L9.35876 16.0763M16.253 9.18201L17.6673 10.5962M9.35876 16.0763L10.773 17.4905"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="5" cy="5" r="1" fill={color}/>
                <circle cx="5" cy="10" r="1" fill={color}/>
                <circle cx="5" cy="15" r="1" fill={color}/>
                <circle cx="10" cy="5" r="1" fill={color}/>
                <circle cx="15" cy="5" r="1" fill={color}/>
                <circle cx="10" cy="10" r="1" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_5242_21973">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const CreativeFoldersIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 13V8C21 7.46957 20.7893 6.96086 20.4142 6.58579C20.0391 6.21071 19.5304 6 19 6H10L8 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M4.00005 13H23L19.9142 20.4142C19.5391 20.7892 19.0304 20.9999 18.4999 20.9999L3.00006 21C2.46962 21 1.96089 20.7893 1.58582 20.4142L4.00005 13Z" fill="none"
                stroke={color} strokeWidth="2" strokeLinejoin="round"
            />
        </svg>
    )
}

export const ApprovalsIcon = (props : IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M9 10L11 12L15 8" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>

    )
}

export const KeywordsIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M7 8H17"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M7 12H17"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M7 16H12"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    )
}

export const LabelsIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 12L3 19L15 19L21 12L15 5L3 5L8 12Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    )
}

export const BuildCreativesIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5242_22021)">
                <path
                    d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
                <path
                    d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
                <path
                    d="M19 13L14 8L3 19"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
                <rect
                    width={props.size ?? 24}
                    height={props.size ?? 24} transform="translate(13 13)" fill={theme.color.navbarBackgroundColor}
                />
                <path
                    d="M19 15.5V22.5"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
                <path
                    d="M15.5 19H22.5"
                    stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5242_22021">
                    <rect width={props.size ?? 24} height={props.size ?? 24} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const AudiencesIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M12.4471 16.3801C10.847 16.3513 9.48264 16.259 8.15199 15.8438C7.71967 15.7054 7.7309 15.642 7.66914 15.1749C7.55685 14.3734 7.93864 13.9352 8.58993 13.6411C9.03348 13.4393 9.48264 13.2432 9.90934 13.001C10.2462 12.8108 10.555 12.5628 10.8582 12.3149C10.9368 12.2457 10.9986 12.0496 10.9649 11.9516C10.8694 11.6806 10.7515 11.4038 10.5831 11.1789C10.2462 10.7234 10.0553 10.2621 10.2181 9.6739C10.2911 9.39712 10.2406 9.0915 10.2631 8.79742C10.3641 7.51154 11.8183 6.6293 12.9693 7.15403C13.0928 7.20593 13.2219 7.27512 13.3511 7.29819C13.8002 7.36162 14.0192 7.6557 14.0978 8.07087C14.1539 8.39955 14.1764 8.73399 14.2157 9.06844C14.255 9.34522 14.2831 9.622 14.3224 9.90455C14.3898 10.3889 14.1876 10.781 13.9181 11.1616C13.7497 11.398 13.6262 11.6806 13.5195 11.9573C13.4858 12.0438 13.5251 12.2168 13.5925 12.2745C13.8844 12.5224 14.1764 12.7819 14.502 12.9665C15.0074 13.2548 15.552 13.4739 16.0685 13.7449C16.7872 14.1197 17.0398 14.7309 16.7872 15.5036C16.7422 15.6362 16.557 15.7804 16.4166 15.8092C15.5969 15.988 14.7659 16.1667 13.935 16.2878C13.3735 16.3686 12.7952 16.357 12.4471 16.3801Z"
                fill={color}
            />
            <path
                d="M14.7102 12.6206C14.873 12.5053 15.0022 12.4303 15.1201 12.3438C15.5412 12.0209 15.5917 11.8306 15.2436 11.4097C14.699 10.7465 14.8955 10.0258 15.1088 9.36263C15.3278 8.66491 16.1195 8.45732 16.7988 8.64761C17.4164 8.82636 17.5961 9.20117 17.6073 9.7778C17.6185 10.0373 17.7084 10.2968 17.6859 10.5505C17.6578 10.7927 17.5792 11.0579 17.4501 11.2655C16.9785 12.0382 17.0346 12.0382 17.714 12.488C18.0733 12.7301 18.5 12.8628 18.8762 13.0704C19.331 13.3183 19.4882 13.7335 19.331 14.2178C19.3029 14.3043 19.1962 14.4024 19.112 14.4197C18.4719 14.5696 17.8263 14.6964 17.1301 14.8464C17.1638 13.8949 16.563 13.549 15.9342 13.226C15.5412 13.0242 15.1369 12.8282 14.7102 12.6206Z"
                fill={color}
            />
            <path
                d="M9.77435 12.6206C9.31396 12.8339 8.87041 13.0184 8.44932 13.2376C8.15737 13.3875 7.87664 13.5605 7.61276 13.7565C7.30396 13.9872 7.13552 14.2986 7.17482 14.7541C6.51792 14.7137 5.88909 14.6272 5.28833 14.3851C5.24342 14.3678 5.1985 14.3216 5.17604 14.2813C5.03568 14.0333 5.1592 13.3644 5.40062 13.2203C5.73188 13.0127 6.09121 12.8455 6.4337 12.6609C6.59652 12.5802 6.76496 12.511 6.91094 12.4072C7.43309 12.0497 7.46116 11.8364 7.06814 11.3232C6.55161 10.6427 6.7425 9.29919 7.42747 8.81482C7.82049 8.53804 8.24158 8.46307 8.68513 8.67643C8.76374 8.71102 8.84234 8.74562 8.92656 8.75715C9.26343 8.82058 9.39818 9.05123 9.43748 9.36838C9.48801 9.76625 9.56662 10.1641 9.56662 10.562C9.56662 10.7811 9.48801 11.0348 9.36449 11.2194C8.84795 12.0036 8.91533 12.0382 9.66206 12.5341C9.68452 12.5514 9.71259 12.5687 9.77435 12.6206Z"
                fill={color}
            />
        </svg>
    )
}

export const VariablesIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
	const color = props.color ?? currentColor

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V7H21V5C21 3.89543 20.1046 3 19 3Z"
                fill={color} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M14 12L10 16"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M10 12L14 16"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M16 18C17.1743 17.5556 18 16.4546 18 14C18 11.5454 17.1743 10.4444 16 10"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M8 18C6.82572 17.5556 6 16.4546 6 14C6 11.5454 6.82572 10.4444 8 10"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    )
}

export const ReputationManagementInsightsIcon = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
    const color = props.color ?? currentColor
    // parse the px value from the size prop
    const size = props.size?.includes("px") ? parseInt(props.size ?? "24") : 24

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2L13.545 5.29127L17 5.82229L14.5 8.38275L15.09 12L12 10.2913L8.91 12L9.5 8.38275L7 5.82229L10.455 5.29127L12 2Z"
                stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M19 13L20.236 15.633L23 16.0578L21 18.1062L21.472 21L19 19.633L16.528 21L17 18.1062L15 16.0578L17.764 15.633L19 13Z"
                stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M5 13L6.236 15.633L9 16.0578L7 18.1062L7.472 21L5 19.633L2.528 21L3 18.1062L1 16.0578L3.764 15.633L5 13Z"
                stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    );
};

export const SocialPostingPageIcon = (props: IconProps) => {

    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
    const color = props.color ?? currentColor

    // parse the px value from the size prop
    const size = props.size?.includes("px") ? parseInt(props.size ?? "24") : 24

    return (
        <svg
            width={size || 50}
            height={size || 50}
            viewBox="0 0 38 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 19.4616H35C36.1046 19.4616 37 20.357 37 21.4616V42.8462M19 19.4616H3C1.89543 19.4616 1 20.357 1 21.4616V42.8462M19 19.4616L16.6 15.7693H9C7.89543 15.7693 7 14.8738 7 13.7693V3C7 1.89543 7.89543 1 9 1H29C30.1046 1 31 1.89543 31 3V13.7693C31 14.8738 30.1046 15.7693 29 15.7693H21.4L19 19.4616ZM1 42.8462V47.0001C1 48.1046 1.89543 49.0001 3 49.0001H35C36.1046 49.0001 37 48.1046 37 47.0001V42.8462M1 42.8462L6.77963 35.9304C7.53955 35.0211 8.919 34.9683 9.74627 35.8168L13 39.1539M16.6 42.8462L13 39.1539M13 39.1539L23.4346 29.522C24.2827 28.7391 25.6156 28.8346 26.3434 29.7304L37 42.8462M23 4.99994H25.4V11.9999M15.8 11.9999C19.1412 10.5007 19.551 9.505 19.913 7.88347C20.2036 6.58189 19.2618 5.10835 17.9362 5.255C17.1216 5.34512 16.4077 5.60746 15.8 6.23071C15.1529 5.56697 14.5057 5.26118 13.6704 5.1203C12.3529 4.89811 11.2794 6.30856 11.4866 7.62844C11.7465 9.28402 12.2459 10.5278 15.8 11.9999Z"
                stroke={color || theme.color.textColor1}
                strokeWidth={props.strokeWidth || "2"}
                strokeLinecap="round"
            />
        </svg>
    )
}

export const SocialPostingPageIconWithFill = (props: IconProps) => {
    const theme = useTheme()
    const { isDarkTheme } = useThemeControl()
    const bgFillColor = isDarkTheme ? 'transparent' : 'white'

    const height = props.size || 43
    const width = (Number(props.size) - 2) || 41
    return (
        <svg width={width} height={height} viewBox="0 0 41 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.8333 41.5001H7.16667C6.24619 41.5001 5.5 40.7539 5.5 39.8334V36.3719V18.5513C5.5 17.6308 6.24619 16.8846 7.16667 16.8846H20.5L18.5 13.8077H12.1667C11.2462 13.8077 10.5 13.0615 10.5 12.141V3.16667C10.5 2.24619 11.2462 1.5 12.1667 1.5H28.8333C29.7538 1.5 30.5 2.24619 30.5 3.16667V12.141C30.5 13.0615 29.7538 13.8077 28.8333 13.8077H22.5L20.5 16.8846H33.8333C34.7538 16.8846 35.5 17.6308 35.5 18.5513V36.3719V39.8334C35.5 40.7539 34.7538 41.5001 33.8333 41.5001Z" fill={theme.color.creativeColor2} fillOpacity="0.25"/>
          <path d="M20.5 16.8846H33.8333C34.7538 16.8846 35.5 17.6308 35.5 18.5513V36.3719M20.5 16.8846H7.16667C6.24619 16.8846 5.5 17.6308 5.5 18.5513V36.3719M20.5 16.8846L18.5 13.8077H12.1667C11.2462 13.8077 10.5 13.0615 10.5 12.141V3.16667C10.5 2.24619 11.2462 1.5 12.1667 1.5H28.8333C29.7538 1.5 30.5 2.24619 30.5 3.16667V12.141C30.5 13.0615 29.7538 13.8077 28.8333 13.8077H22.5L20.5 16.8846ZM5.5 36.3719V39.8334C5.5 40.7539 6.24619 41.5001 7.16667 41.5001H33.8333C34.7538 41.5001 35.5 40.7539 35.5 39.8334V36.3719M5.5 36.3719L10.3164 30.6087C10.9496 29.8509 12.0992 29.8069 12.7886 30.514L15.5 33.2949M18.5 36.3719L15.5 33.2949M15.5 33.2949L24.1955 25.2683C24.9023 24.6159 26.013 24.6955 26.6195 25.442L35.5 36.3719M23.8333 4.83328H25.8333V10.6666M17.8333 10.6666C20.6177 9.41724 20.9591 8.5875 21.2609 7.23623C21.503 6.15158 20.7181 4.92362 19.6135 5.04583C18.9347 5.12093 18.3397 5.33955 17.8333 5.85892C17.294 5.30581 16.7548 5.05098 16.0586 4.93358C14.9608 4.74842 14.0661 5.9238 14.2388 7.0237C14.4554 8.40335 14.8716 9.43984 17.8333 10.6666Z" stroke={theme.color.creativeColor2}  strokeWidth="1.66667" strokeLinecap="round"/>
          <path d="M33.8333 41.5001H7.16667C6.24619 41.5001 5.5 40.7539 5.5 39.8334V36.9766C5.5 36.5859 5.63726 36.2076 5.8878 35.9078L10.3164 30.6087C10.9496 29.8509 12.0992 29.8069 12.7886 30.514L15.5 33.2949L24.1955 25.2683C24.9023 24.6159 26.013 24.6955 26.6195 25.442L35.1269 35.9126C35.3682 36.2097 35.5 36.5808 35.5 36.9636V39.8334C35.5 40.7539 34.7538 41.5001 33.8333 41.5001Z" fill={bgFillColor}/>
          <path d="M21.2608 7.23623C20.9591 8.5875 20.6177 9.41724 17.8333 10.6666C14.8716 9.43984 14.4554 8.40335 14.2388 7.0237C14.0661 5.9238 14.9608 4.74842 16.0586 4.93358C16.7548 5.05098 17.294 5.30581 17.8333 5.85892C18.3397 5.33955 18.9347 5.12093 19.6135 5.04583C20.7181 4.92362 21.503 6.15158 21.2608 7.23623Z" fill={bgFillColor}/>
          <path d="M18.5 36.3719L15.5 33.2949M15.5 33.2949L12.7886 30.514C12.0992 29.8069 10.9496 29.8509 10.3164 30.6087L5.8878 35.9078C5.63726 36.2076 5.5 36.5859 5.5 36.9766V39.8334C5.5 40.7539 6.24619 41.5001 7.16667 41.5001H33.8333C34.7538 41.5001 35.5 40.7539 35.5 39.8334V36.9636C35.5 36.5808 35.3682 36.2097 35.1269 35.9126L26.6195 25.442C26.013 24.6955 24.9023 24.6159 24.1955 25.2683L15.5 33.2949ZM17.8333 10.6666C20.6177 9.41724 20.9591 8.5875 21.2609 7.23623C21.503 6.15158 20.7181 4.92362 19.6135 5.04583C18.9347 5.12093 18.3397 5.33955 17.8333 5.85892C17.294 5.30581 16.7548 5.05098 16.0586 4.93358C14.9608 4.74842 14.0661 5.9238 14.2388 7.0237C14.4554 8.40335 14.8716 9.43984 17.8333 10.6666Z" stroke={theme.color.creativeColor2} strokeWidth="1.66667" strokeLinecap="round"/>
        </svg>

    )
}

export const CallTrackingIconWithFill = (props: IconProps) => {
    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
    const color = props.color ?? currentColor
    const size = props.size?.includes("px") ? parseInt(props.size ?? "24") : 24

    return (
        <svg width={size + 1} height={size} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6138 0.5L20.9319 3.84732L17.6138 7.19465" stroke={color} strokeWidth="1.0042" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.2954 3.84723H20.9317" stroke={color} strokeWidth="1.0042" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.20876 8.43005C7.88266 10.698 11.795 15.2118 14.053 15.1231L11.3129 17.8972C9.00364 15.999 4.32469 11.7359 3.82617 10.168" stroke={color} strokeWidth="0.836998"/>
            <path d="M2.89183 0.878595C-0.884293 3.01182 1.85337 7.95758 3.69421 10.1638C4.56162 11.2852 7.31227 14.4004 11.3756 17.8899C16.4547 22.2517 20.0935 20.281 20.8228 18.2052C21.5521 16.1294 19.9633 15.5513 17.2805 13.8171C15.1342 12.4297 14.1634 14.0188 13.9463 14.9868C12.0042 14.9722 8.15894 10.5274 6.47907 8.30681C8.14051 6.8212 7.95807 5.30695 7.65918 4.73553L5.2519 0.878595C4.79877 0.192923 3.48971 0.592898 2.89183 0.878595Z" fill={theme.color.accentColor4} stroke={color} strokeWidth="0.836998"/>
        </svg>
    )
}

export const BulkSubscriptionManagementIcon = (props: IconProps) => {

    const theme = useTheme()
    const currentColor = props.$active ? theme.color.navbarActiveTextColor : theme.color.navbarTextColor
    const color = props.color ?? currentColor

    const size = props.size?.includes("px") ? parseInt(props.size ?? "24") : 24

    return (
        <svg width={size || 50} height={size || 50} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_16823_28181)">
                <rect x="10.5" y="2" width="13" height="13" rx="1" stroke={color} strokeWidth="2"/>
                <rect x="6.5" y="6" width="13" height="13" rx="1" fill={theme.color.navbarBackgroundColor}/>
                <rect x="6.5" y="6" width="13" height="13" rx="1" stroke={color} strokeWidth="2"/>
                <rect x="1.5" y="10" width="13" height="13" rx="1" fill={theme.color.navbarBackgroundColor}/>
                <rect x="1.5" y="10" width="13" height="13" rx="1" stroke={color} strokeWidth="2"/>
                <path d="M11.5 14L6.6875 19L4.5 16.7273" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_16823_28181">
                    <rect width={size || 50} height={size || 50} fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const BlueprintPreviewIcon = (props: IconProps) => {

    const theme = useTheme()
    const color = theme.color.textColor1

    return (
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.41574 12.2547C3.41574 8.75162 6.25553 5.91183 9.75859 5.91183H22.7186C26.2217 5.91183 29.0615 8.75162 29.0615 12.2547V21.8547C29.0615 25.3577 26.2217 28.1975 22.7186 28.1975H4.95859C4.1065 28.1975 3.41574 27.5068 3.41574 26.6547V12.2547Z"
                stroke={color}
                strokeWidth="1.71429"/>
            <path
                d="M12.5985 18.0755C13.2171 18.0755 13.7186 17.6196 13.7186 17.0573C13.7186 16.4949 13.2171 16.0391 12.5985 16.0391C11.98 16.0391 11.4785 16.4949 11.4785 17.0573C11.4785 17.6196 11.98 18.0755 12.5985 18.0755Z"
                fill={color}
                stroke={color}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M19.1591 18.0755C19.7777 18.0755 20.2791 17.6196 20.2791 17.0573C20.2791 16.4949 19.7777 16.0391 19.1591 16.0391C18.5405 16.0391 18.0391 16.4949 18.0391 17.0573C18.0391 17.6196 18.5405 18.0755 19.1591 18.0755Z"
                fill={color}
                stroke={color}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

    )
}

//* Feather Icons
export const SummaryIcon = styled(FiSliders)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
    transform: rotate(90deg);
`

export const CallTrackingIcon = styled(FiPhoneForwarded)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const InsightsIcon = styled(FiActivity)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const AssetsIcon = styled(FiFolder)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const CalendarIcon = styled(FiCalendar)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const CorporateSettingsIcon = styled(FiLock)<IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const UserSettingsIcon = styled(FiSettings) <IconProps>`
    stroke: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`

export const LogoutIcon = styled(FiLogOut) <IconProps>`
    color: ${props => props.$active ? props.theme.color.navbarActiveTextColor : props.theme.color.navbarTextColor};
`