import { AdWithMetadata, PostWithMetadata } from "../../creative.types"

export const displayCampaignName = (item: AdWithMetadata | PostWithMetadata): string => {
    const { metadata } = item
    const { isFolder, location, isDefaultLocation } = metadata

    if (!location) {
        return 'N/A'
    }

    const campaignName = (isDefaultLocation && isFolder) ? 'Default Campaign Creative Folder' : location.nickname
    return isFolder ? campaignName : `${campaignName} - ${location.name}`
}