import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { Hex, getHighestContrastColor, isHexLight } from "../helpers/colors.util";
import { CONNECTION_STATUS, LOCATION_STATUS } from "../features/locations/util";
import { CREATIVE_STATUS, CREATIVE_STATUS_COPY_MAP, isCreativeStatus, POST_STATUS } from "../helpers/creative.util";

export const useThemeControl = () => {
    const currTheme = useSelector(state => state.ui.theme)
    const theme  = useTheme()
    const isDarkTheme = !isHexLight(theme.color.backgroundColor2)

    // * Takes in a creative kind and returns its assocaited theme color
    const getCreativeKindColor = (kind: string) => {
        if (kind.includes('Post')) return theme.color.creativeColor2;
        return theme.color.creativeColor1;
    }

    const getSubscriptionStatusColor = (status: keyof typeof LOCATION_STATUS) => {
        switch (status) {
            case LOCATION_STATUS.ACTIVE:
                return theme.color.activeButtonColor
            case LOCATION_STATUS.DECLINED:
                return theme.color.errorNegativeButtonColor
            case LOCATION_STATUS.PAUSED:
            case LOCATION_STATUS.PENDING:
                return theme.color.warningButtonColor
            default:
                return theme.color.textColor1
        }
    }

    // * This is for the creative status text colors
    const creativeStatusColorMap = {
        "ACTIVE": theme.color.activeButtonColor,
        "ENABLED": theme.color.activeButtonColor,
        "PAUSED": theme.color.warningButtonColor,
        "UNREVIEWED": theme.color.warningButtonColor,
        [POST_STATUS.REJECTED]: theme.color.errorNegativeButtonColor,
        [POST_STATUS.PENDING]: theme.color.warningButtonColor,
        [POST_STATUS.SCHEDULED]: theme.color.warningButtonColor,
        [POST_STATUS.POSTED]: theme.color.activeButtonColor,
        [POST_STATUS.FAILURE]: theme.color.errorNegativeButtonColor,
        "EXPIRED": theme.color.errorNegativeButtonColor,
        "DISABLED": theme.color.errorNegativeButtonColor,
        [POST_STATUS.PARTIALLY_POSTED]: theme.color.warningButtonColor,
        "AWAITING-APPROVAL": theme.color.warningButtonColor,
        "NOSUBSCRIPTION": theme.color.greyAccent1,
        "INACTIVE": theme.color.greyAccent1
    } as const

    const graphColors = {
        totalColor: theme.color.accentColor2,
        displayColor: theme.color.accentColor3,
        searchColor: theme.color.creativeColor1,
        socialColor: theme.color.creativeColor2,
        videoColor: theme.color.creativeColor3,
        otherColor: theme.color.creativeColor4
    }

	const getAccessibleFontColor = (bgColor: Hex) => {
		if (!bgColor) throw new Error("No color defined")
		const { color } = theme
		const { textColor1, textColor2, textColor3 } = color
		const options = [textColor1, textColor2, textColor3]
		const highestContrastColor = getHighestContrastColor(bgColor, options)
		return highestContrastColor
	}

    const connectionStatusColorMap = {
        [CONNECTION_STATUS.CONNECTED]: theme.color.activeButtonColor,
        [CONNECTION_STATUS.PENDING]: theme.color.warningButtonColor,
        [CONNECTION_STATUS.NEEDS_CONFIG]: theme.color.textColor1,
        [CONNECTION_STATUS.NOT_CONNECTED]: theme.color.errorNegativeButtonColor
    }

    return {
        currTheme,
        isDarkTheme,
        getCreativeKindColor,
        getSubscriptionStatusColor,
        creativeStatusColorMap,
        graphColors,
        activeColor: theme.color.activeButtonColor,
        errorColor: theme.color.errorNegativeButtonColor,
		getAccessibleFontColor,
        connectionStatusColorMap,
    }
}

export const getStatusColor = (status: string) => {

    if (!isCreativeStatus(status)) {
        return 'textColor1'
    }

    // map a status to the color it should be based on the copy
    const statusCopy = CREATIVE_STATUS_COPY_MAP[status]

    const errorList = [
        CREATIVE_STATUS_COPY_MAP[CREATIVE_STATUS.EXPIRED],
        CREATIVE_STATUS_COPY_MAP[CREATIVE_STATUS.REJECTED],
        CREATIVE_STATUS_COPY_MAP[POST_STATUS.CANT_POST],
    ]

    if (errorList.some(errorCopy => errorCopy === statusCopy)) return 'errorNegativeButtonColor'
    return 'textColor1'
}