import React from 'react'
import Modal from '../modal/Modal'
import IdleModalImage from '../../assets/images/idleModalImage.png'
import { StyledIdleModalContent } from './styles'
import { Body, H2 } from '../../styles/typography'
import { ButtonContainer, PrimaryCTABtn } from '../../styles/buttons'

const IdleModal = ({ setIsIdle }) => {
  return (
    <Modal width='635px' minHeight="520px" onClose={() => setIsIdle(false)}>
        <StyledIdleModalContent>
            <H2 center style={{marginBottom: '20px'}}>Refresh Page</H2>
            <div className='idle__message__container'>
                <Body>Your data has not been updated recently. Refresh to see your latest data.</Body>
            </div>
            <div className='idle__image__container'>
                <img src={IdleModalImage} alt='idle image'/>
            </div>
            <ButtonContainer>
                <PrimaryCTABtn onClick={() => window.location.reload()}>Refresh Data</PrimaryCTABtn>
            </ButtonContainer>
        </StyledIdleModalContent>
    </Modal>
  )
}

export default IdleModal