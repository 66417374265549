import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { EXPERIMENTS } from "../helpers/experimentHelpers"
import { useExperiment } from '../hooks/useExperiment';
import { INSIGHTS, PATH } from "../constants/urlPath";
import { Loader } from "../components/waitScreens/Loader";
import PageLoading from "./PageLoading";
import { useAccess } from "../hooks/useAccess";
import Footer from "../components/footer/Footer";
import InsightsReportBuilder from "../pages/insights/InsightsReportBuilder/InsightsReportBuilder";
import { Card } from "../styles/card";
import useDynamicSetting from "../hooks/useDynamicSetting";

const Locations = lazy(() => import("../pages/locations/Locations"))
const Blueprints = lazy(() => import("../pages/blueprints/Blueprints"))
const CreativeRepoRouter = lazy(() => import("../pages/creativerepo/CreativeRepoRouter"))
const Approvals = lazy(() => import("../pages/approvals/Approvals"))
const Build = lazy(() => import("../pages/build/Build"))
const Keywords = lazy(() => import("../pages/keywords/Keywords"))
const Labels = lazy(() => import("../pages/labels/Labels"))
const Assets = lazy(() => import("../pages/assets/Assets"))
const CorporateSettings = lazy(() => import("../pages/corporateSettings/CorporateSettings"))
const EmailMarketing = lazy(() => import("../pages/emailmarketing/EmailMarketing"))
const SmsMarketing = lazy(() => import("../pages/smsmarketing/SmsMarketing"))
const Audiences = lazy(() => import("../pages/audiencesmanagement/Audiences"))
const Insights = lazy(() => import("../pages/insights/Insights"))
const NewLocationSetup = lazy(() => import("../pages/newlocationsetup/NewLocationSetup"))
const Calendar = lazy(() => import("../pages/calendar/Calendar"))
const UserSettings = lazy(() => import("../pages/userSettings/UserSettingsPage"))
const CallFowardingLoader = lazy(() => import("../features/callForwarding/components/CallFowardingLoader"))
const ReputationManagementPageLoader = lazy(() => import("../features/reputationManagement/ReputationManagementLoader"))
const Variables = lazy(() => import("../pages/variables"))
const NotFound = lazy(() => import("../pages/notfound/NotFound"))
const BulkSettings =  lazy(() => import(("../pages/bulkSettings/BulkSettings")))

const RequestDeletion = lazy(() => import("../pages/requestDeletion/RequestDeletion"))
const SocialPosting = lazy(() => import("../pages/socialPosting"))

const Summary = lazy(() => import("../pages/summaryPage"))
const Advertising = lazy(() => import("../pages/advertising"))

const Routes = () => {
    const smsMarketingExperiment = useExperiment(EXPERIMENTS.SMS_MARKETING)
    const emailMarketingExperiment = useExperiment(EXPERIMENTS.EMAIL_MARKETING)

    const { zeeInsightReportBuilderAccess } = useDynamicSetting()

    const isFranchiseLoading = useSelector(state => state.franchise.loading)
    const { canManageFranchise } = useAccess()

    // MANAGERS & ADMINS
    if (canManageFranchise()) {
      return (
        <Suspense fallback={<PageLoading />}>
          <Switch>
            <Route exact path={PATH.APP}><Redirect to={`${PATH.SUMMARY}${window.location.search}`} /></Route>
            <Route exact path={PATH.SUMMARY} component={Summary} />
            <Route path={PATH.CAMPAIGNS} component={Locations} />
            <Route path={PATH.SOCIAL_POSTING} component={SocialPosting} />
            <Route exact path={PATH.BLUEPRINTS} component={Blueprints} />
            <Route path={PATH.CREATIVE_FOLDERS} component={CreativeRepoRouter} />
            <Route exact path={PATH.APPROVALS} component={Approvals} />
            <Route path={PATH.BUILD} component={Build} />
            <Route path={PATH.KEYWORDS} component={Keywords} />
            <Route path={PATH.LABELS} component={Labels} />
            <Route path={PATH.ASSETS} component={Assets} />
            <Route path={PATH.CORPORATE_SETTINGS} component={CorporateSettings} />
            {emailMarketingExperiment.ifActive(<Route exact path={PATH.EMAIL_MARKETING} component={EmailMarketing} />)}
            {smsMarketingExperiment.ifActive(<Route exact path={PATH.SMS_MARKETING} component={SmsMarketing} />)}
            <Route path={PATH.AUDIENCES} component={Audiences} />
            <Route path={PATH.INSIGHTS} component={Insights} />
            <Route exact path={PATH.BLUEPRINTS_DEFAULT} component={NewLocationSetup} />
            <Route exact path={PATH.CALENDAR} component={Calendar} />
            <Route exact path={PATH.BULK_SETTINGS} component={BulkSettings} />
            <Route path={PATH.USER_SETTINGS} component={UserSettings} />
            <Route path={PATH.CALL_TRACKING} component={CallFowardingLoader}/>
            <Route path={PATH.REPUTATION_MANAGEMENT} component={ReputationManagementPageLoader} />
            {isFranchiseLoading ? <Loader /> : <Route path={PATH.VARIABLES} component={Variables} />}
            <Route path={PATH.ADVERTISING} component={Advertising}/>
            <Route exact path="*" component={NotFound} />
          </Switch>
          <Footer />
        </Suspense>
    );
  }

    // MEMBERS
    return (
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route exact path={PATH.APP}><Redirect to={PATH.CAMPAIGNS} /></Route>
          <Route exact path={PATH.SUMMARY} component={Summary} />
          <Route path={PATH.CAMPAIGNS} component={Locations} />
          <Route path={PATH.SOCIAL_POSTING} component={SocialPosting} />
          <Route path={PATH.KEYWORDS} component={Keywords} />
          <Route path={PATH.BUILD} component={Build} />
          <Route path={PATH.AUDIENCES} component={Audiences} />
          <Route exact path={PATH.CALENDAR} component={Calendar} />
          <Route exact path={PATH.BULK_SETTINGS} component={BulkSettings} />
          <Route path={PATH.USER_SETTINGS} component={UserSettings} />
          <Route exact path={PATH.REQUEST_DELETE} component={RequestDeletion} />
          <Route path={PATH.REPUTATION_MANAGEMENT} component={ReputationManagementPageLoader} />
          <Route path={PATH.ADVERTISING} component={Advertising}/>
          <Route path={PATH.CALL_TRACKING} component={CallFowardingLoader} />
          {
            zeeInsightReportBuilderAccess &&
            <Route exact path={INSIGHTS.REPORT_BUILDER}>
              <Card width="70rem" customStyle={"insightsCard"}>
                <InsightsReportBuilder />
              </Card>
            </Route>
          }
          <Route exact path="*" component={NotFound} />
        </Switch>
        <Footer />
      </Suspense>
    )
}

export default Routes;
