import { LOCATION_CONNECTIONS } from '../../../../../../../features/connections/constants'
import {
    CONNECTION_STATUS_COPY,
    getLocationConnections
} from '../../../../../../../features/locations/util'
import columnBuilder from '../../../columnBuilder'

const gmbReportCategory = columnBuilder()
    .category('platform-connections')
    .subCategory('Google Business Profile')
    .makeHeader((meta) => {
        return `Google Business Profile: ${meta.name}`
    })

const gmbColumns = [
    gmbReportCategory
        .id('gmb-connection-status')
        .name('Connection Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return connections[LOCATION_CONNECTIONS.GOOGLE_BUSINESS]
        })
        .toString((data) => CONNECTION_STATUS_COPY[data]),
    gmbReportCategory
        .id('gmb-profile-id')
        .name('Profile ID')
        .makeRow((data) => {
            return data.input.location.gmbLocationDisplayName
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
]

export default gmbColumns
