import React, { useCallback, useEffect, useMemo } from 'react'

import SuperTableColumnFilter from './components/SuperTableColumnFilter'
import { Row, RowSeparator, Wrap, WrapGrid } from './style'
import { H4, Span } from '../../styles/typography'
import { BsSortDown } from 'react-icons/bs'
import TypedSuperTableGenericTextFilter from './components/TypedSuperTableGenericTextFilter'
import CheckBox from '../checkbox/CheckBox'
import _ from 'lodash'

// Introducing the new and improved (and now typed) SuperTable!

// Example usage:
// const items = [
//     {
//         name: 'Ben',
//         age: 21,
//         location: 'New York City',
//         fullname: {
//             first: 'Ben',
//             last: 'Schoelkopf',
//         },
//     },
//     {
//         name: 'Bob',
//         age: 15,
//         location: 'Oklahoma',
//         fullname: {
//             first: 'Bob',
//             last: 'Smith',
//         },
//     },
//     {
//         name: 'Henri',
//         age: 45,
//         location: 'Paris',
//         fullname: {
//             first: 'Henri',
//             last: 'Leblanc',
//         },
//     },
// ];

// // This is a filter component that filters by age range
// // Item (aka T) must have a property called age that is a number
// const AgeRangeFilter = <T extends { age: number }>(props: FilterComponentProps<T>) => {
//     const { setFilterFunction } = props;

//     const [minAge, setMinAge] = React.useState<number | null>(null);
//     const [maxAge, setMaxAge] = React.useState<number | null>(null);

//     const filterFunction = React.useCallback(
//         (item: T) => {
//             const age = item.age;

//             if (minAge !== null && age < minAge) return false;

//             if (maxAge !== null && age > maxAge) return false;

//             return true;
//         },
//         [minAge, maxAge]
//     );

//     React.useEffect(() => {
//         setFilterFunction(filterFunction);
//     }, [filterFunction, setFilterFunction]);

//     return (
//         <div>
//             <input
//                 type='number'
//                 placeholder='Min Age'
//                 value={minAge ?? ''}
//                 onChange={(e) => {
//                     const val = e.target.value;

//                     if (val === '') {
//                         setMinAge(null);
//                         return;
//                     }

//                     const num = Number(val);

//                     if (isNaN(num)) {
//                         console.error('Min Age was not a number');
//                         return;
//                     }

//                     setMinAge(num);
//                 }}
//             />
//             <input
//                 type='number'
//                 placeholder='Max Age'
//                 value={maxAge ?? ''}
//                 onChange={(e) => {
//                     const val = e.target.value;

//                     if (val === '') {
//                         setMaxAge(null);
//                         return;
//                     }

//                     const num = Number(val);

//                     if (isNaN(num)) {
//                         console.error('Max Age was not a number');
//                         return;
//                     }

//                     setMaxAge(num);
//                 }}
//             />
//         </div>
//     );
// };

// const { Wrap, Column, Footer } = getSuperTableComponents<typeof items[number]>();

// export const TableTesterWrap = () => {
//     const [hideUnder21, setHideUnder21] = React.useState(false);
//
//     return (
//         <>
//             <Wrap items={items} filter={(item) => !hideUnder21 || item.age >= 21}>
//                 <Column title='Name'>
//                     {(item) => {
//                         return <div>{item.name}</div>;
//                     }}
//                 </Column>
//                 <Column title='Age' sortKey='age' filter={AgeRangeFilter}>
//                     {(item) => {
//                         return <div>{item.age}</div>;
//                     }}
//                 </Column>
//                 <Column title='Location' filter='location'>
//                     {(item) => {
//                         return <div>{item.location}</div>;
//                     }}
//                 </Column>
//                 <Footer>
//                     {(item) => {
//                         return (
//                             'This is the footer - ' + item.fullname.first + ' ' + item.fullname.last
//                         );
//                     }}
//                 </Footer>
//             </Wrap>
//             <button
//                 onClick={() => {
//                     setHideUnder21(!hideUnder21);
//                 }}
//             >
//                 {hideUnder21 ? 'Show All' : 'Hide Under 21'}
//             </button>
//         </>
//     );
// };

type RenderFunction<T> = (item: T, index: number) => React.ReactNode

export type FilterComponentProps<T> = {
	items: T[]
	// filterKey?:
	filterVal?:
		| {
				[P in keyof T]: T[P] extends string | number ? P : never
		  }[keyof T]
		| ((item: T) => string)
	setFilterFunction: (func: (item: T) => boolean) => void
}

type FilterComponent<T> = React.ComponentType<FilterComponentProps<T>>

type BaseColumnProps<T> = {
	children: RenderFunction<T>
	title: React.ReactNode
	csvHeader?: string
	csvData?: (item: T) => string
	size?: number | string
	useAsDefaultSort?: never
	sort?: never
	sortKey?: never
	filterComponent?: FilterComponent<T>
	filter?:
		| {
				[P in keyof T]: T[P] extends string | number ? P : never
		  }[keyof T]
		| ((item: T) => string)
}

type ColumnProps<T> =
	| BaseColumnProps<T>
	| (Omit<BaseColumnProps<T>, 'sort' | 'useAsDefaultSort'> & {
			sort: (item1: T, item2: T) => number
			useAsDefaultSort?: boolean
	  })
	| (Omit<BaseColumnProps<T>, 'sortKey' | 'useAsDefaultSort'> & {
			sortKey: {
				[P in keyof T]: T[P] extends string | number | boolean | Date
					? P
					: never
			}[keyof T]
			useAsDefaultSort?: boolean
	  })

type CompleteColumnProps<T> = Omit<ColumnProps<T>, 'children'> & {
	render: ColumnProps<T>['children']
}

type FooterProps<T> = {
	children: RenderFunction<T>
}

type BaseSuperTableConfig<T> = {
	items: T[]
	filter?: (item: T, index: number) => boolean
	useAlternateStyles?: boolean
	onRowClicked?: (item: T, index: number) => void
	selectable?: never
	areItemsEqual?: never
	onItemsSelected?: never
	onScrolledToBottom?: () => void
	onCSVDataUpdated?: (data: string[][]) => void
	disableScrolling?: boolean
	children?: React.ReactNode
	asGrid?: boolean
}

type SuperTableConfig<T> =
	| BaseSuperTableConfig<T>
	| (Omit<
			BaseSuperTableConfig<T>,
			'selectable' | 'areItemsEqual' | 'onItemsSelected'
	  > & {
			selectable: true
			areItemsEqual: (item1: T, item2: T) => boolean
			onItemsSelected?: (items: T[]) => void
			initialSelection?: T[]
	  })

function compare(a: any, b: any): number {
	if (typeof a === 'string' && typeof b === 'string') {
		return a.localeCompare(b)
	} else if (typeof a === 'number' && typeof b === 'number') {
		return a - b
	} else if (typeof a === 'boolean' && typeof b === 'boolean') {
		return a === b ? 0 : a ? 1 : -1
	} else if (a instanceof Date && b instanceof Date) {
		return a.getTime() - b.getTime()
	} else {
		console.error('sortKey is not a string or number')
		return 0
	}
}

// This is just a stub to make the typechecker and intellisense happy
const Column = <T,>(props: ColumnProps<T>) => {
	return null
}

const Footer = <T,>(props: FooterProps<T>) => {
	return null
}

const getSortFunctionForColumn = <T,>(column: CompleteColumnProps<T>) => {
	if ('sort' in column && !!column.sort) {
		return column.sort
	} else if ('sortKey' in column) {
		return (a: T, b: T) => {
			if (column.sortKey === undefined) {
				// We should never hit this... but typescript doesn't know that
				return 0
			}

			return compare(a[column.sortKey], b[column.sortKey])
		}
	} else {
		console.error('Column does not have a sort or sortKey')
		return (a: T, b: T) => {
			return 0
		}
	}
}

const WrapComponent = <T,>(props: SuperTableConfig<T>) => {
	const [selectedItems, setSelectedItems] = React.useState<T[]>(
		props.selectable ? props.initialSelection ?? [] : []
	)

	const [sortData, setSortData] = React.useState<{
		sortColumn: string | null
		shouldReverseSort: boolean
		sortFunction: (item1: T, item2: T) => number
	}>({
		sortColumn: null,
		shouldReverseSort: false,
		sortFunction: (a: T, b: T): number => {
			return 0
		},
	})

	const [columnDefinedFilterFunctions, setColumnDefinedFilterFunctions] =
		React.useState<{
			[key: string]: (item: T) => boolean
		}>({})

	const isAtBottom = React.useRef(false)

	const isItemSelected = useCallback(
		(item: T) => {
			if (!('selectable' in props && props.selectable)) {
				return false
			}

			return !!selectedItems.find((otherItem) =>
				props.areItemsEqual(otherItem, item)
			)
		},
		[selectedItems, props]
	)

	const handleBodyScroll = useCallback(
		(e: React.UIEvent<HTMLDivElement>) => {
			const { scrollTop, scrollHeight, clientHeight } = e.currentTarget

			if (scrollTop + clientHeight >= scrollHeight - 10) {
				if (!isAtBottom.current) props.onScrolledToBottom?.()

				isAtBottom.current = true
			} else {
				isAtBottom.current = false
			}
		},
		[props]
	)

	const columns = useMemo(() => {
		const columns: CompleteColumnProps<T>[] = React.Children.toArray(
			props.children as unknown as React.ReactElement[]
		)
			.filter((child: any) => {
				return child.type === Column
			})
			.map((_child) => {
				const child = _child as React.ReactElement
				// We can safely cas the props to ColumnProps<T> because we've filtered out all the other children
				const childProps = child.props as ColumnProps<T>

				return {
					...childProps,
					render: childProps.children,
					size: childProps.size || 1,
				}
			})

		if ('selectable' in props && props.selectable) {
			columns.unshift({
				size: '0.1',
				title: (
					<CheckBox
						size="large"
						checked={
							selectedItems.length === props.items.length &&
							props.items.length !== 0
						}
						onClick={() => {
							if (selectedItems.length === props.items.length) {
								setSelectedItems([])
							} else {
								setSelectedItems(props.items)
							}
						}}
					/>
				),
				render: (item) => {
					return (
						<CheckBox
							size="large"
							checked={isItemSelected(item)}
							onClick={(e) => {
								e.stopPropagation()

								setSelectedItems((prev) => {
									const index = prev.findIndex((otherItem) =>
										props.areItemsEqual(otherItem, item)
									)

									if (index === -1) {
										return [...prev, item]
									} else {
										const copy = [...prev]
										copy.splice(index, 1)
										return copy
									}
								})
							}}
						/>
					)
				},
			})
		}

		let numDefaultSortColumns = 0
		for (const column of columns) {
			if (column.useAsDefaultSort) {
				numDefaultSortColumns++
			}
		}

		if (numDefaultSortColumns > 1) {
			throw new Error('You can only use one column as default sort')
		}

		return columns
	}, [isItemSelected, selectedItems.length, setSelectedItems, props])

	const footers = useMemo(() => {
		return React.Children.toArray(
			props.children as unknown as React.ReactElement[]
		)
			.filter((child: any) => {
				return child.type === Footer
			})
			.map((_child) => {
				const child = _child as React.ReactElement
				// We can safely cas the props to ColumnProps<T> because we've filtered out all the other children
				const childProps = child.props as FooterProps<T>

				return {
					...childProps,
					render: childProps.children,
				}
			})
	}, [props.children])

	const itemsFilteredAndSorted = useMemo(() => {
		let items = _.cloneDeep(props.items)

		if (props.filter) {
			items = items.filter(props.filter)
		}

		for (const columnDefinedFilterFunction of Object.values(
			columnDefinedFilterFunctions
		)) {
			items = items.filter(columnDefinedFilterFunction)
		}

		return items
			.sort(sortData.sortFunction)
			[sortData.shouldReverseSort ? 'reverse' : 'slice']()
	}, [sortData, columnDefinedFilterFunctions, props.filter, props.items])

	useEffect(() => {
		if ('onItemsSelected' in props && props.onItemsSelected) {
			props.onItemsSelected(selectedItems)
		}
	}, [selectedItems, props])

	useEffect(() => {
		// we don't really care about the csvHeader and csvData properties unless we're actually trying to compile the csv data
		if (!props.onCSVDataUpdated) return

		const data: string[][] = []

		// Header
		const header: string[] = []
		for (const column of columns) {
			if (column.csvHeader) {
				header.push(column.csvHeader)
			} else if (typeof column.title === 'string') {
				header.push(column.title)
			} else {
				throw new Error(
					'Column has a non-string title and no csvHeader property'
				)
			}
		}

		data.push(header)

		// Body
		for (let i = 0; i < props.items.length; i++) {
			const item = props.items[i]
			const row: string[] = []

			for (const column of columns) {
				let val = column.csvData
					? column.csvData(item)
					: column.render(item, i)

				if (val === null || val === undefined) {
					val = ''
				}

				if (typeof val !== 'string') {
					console.log(column.title, val)
					throw new Error('CSV data must be a string')
				}

				row.push(val)
			}

			data.push(row)
		}

		props.onCSVDataUpdated?.(data)
	}, [props, columns, itemsFilteredAndSorted])

	if (columns.length === 0 && footers.length === 0) {
		return null
	}

	if (props.asGrid) {
		return (
			<WrapGrid
				selectable={props.selectable}
				onScroll={handleBodyScroll}
				className="supertable-wrap"
				useAlternateStyles={props.useAlternateStyles}
				columnCount={columns.length}
			>
				{columns.map((column, columnIndex) => {
					const columnKey = `${column.title}-${columnIndex}`

					let FilterComponent: FilterComponent<T> | null = null

					if (column.filterComponent) {
						FilterComponent = column.filterComponent
					} else if (typeof column.filter === 'string') {
						FilterComponent = TypedSuperTableGenericTextFilter<T>
					} else if (typeof column.filter === 'function') {
						FilterComponent = TypedSuperTableGenericTextFilter<T>
					}

					if (
						column.useAsDefaultSort &&
						sortData.sortColumn === null
					) {
						setSortData({
							sortColumn: columnKey,
							shouldReverseSort: false,
							sortFunction: getSortFunctionForColumn(column),
						})
					}

					return (
						<>
							<div
								key={columnKey}
								id={columnKey}
								className="supertable-header-row-data"
							>
								{FilterComponent && (
									<SuperTableColumnFilter>
										<FilterComponent
											items={props.items.filter(
												(item, index) => {
													if (!props.filter)
														return true

													// If the user passes a filter to the config, then those values "don't exist" for the filter component
													// However, all of the other filters shouldn't apply here, otherwise the second you select one option
													// and narrow the results, the other options disappear from the filter itself.
													return props.filter(
														item,
														index
													)
												}
											)}
											filterVal={column.filter}
											setFilterFunction={(func) => {
												if (
													columnDefinedFilterFunctions[
														columnKey
													] === func
												) {
													return
												}

												setColumnDefinedFilterFunctions(
													{
														...columnDefinedFilterFunctions,
														[columnKey]: func,
													}
												)
											}}
										/>
									</SuperTableColumnFilter>
								)}

								{props.useAlternateStyles ? (
									<div>{column.title}</div>
								) : (
									<H4>{column.title}</H4>
								)}

								{'sort' in column && (
									<BsSortDown
										onClick={() => {
											if (!column.sort) return

											setSortData({
												sortColumn: columnKey,
												shouldReverseSort:
													sortData.sortColumn ===
													columnKey
														? !sortData.shouldReverseSort
														: false,
												sortFunction: column.sort,
											})
										}}
									/>
								)}

								{'sortKey' in column && (
									<BsSortDown
										onClick={() => {
											setSortData({
												sortColumn: columnKey,
												shouldReverseSort:
													sortData.sortColumn ===
													columnKey
														? !sortData.shouldReverseSort
														: false,
												sortFunction: (a: T, b: T) => {
													if (
														column.sortKey ===
														undefined
													) {
														// We should never hit this... but typescript doesn't know that
														return 0
													}

													return compare(
														a[column.sortKey],
														b[column.sortKey]
													)
												},
											})
										}}
									/>
								)}
							</div>
						</>
					)
				})}
				{itemsFilteredAndSorted.map((item, itemIndex) => {
					const SpanWithBodyStyles = ({
						children,
						...props
					}: any) => {
						return (
							<Span applyBodyStyles {...props}>
								{children}
							</Span>
						)
					}

					const RenderComponent = props.useAlternateStyles
						? 'div'
						: SpanWithBodyStyles

					return (
						<>
							{columns.map((column, columnIndex) => (
								<>
									<RenderComponent
										style={{ flex: column.size }}
										key={`${itemIndex}-${columnIndex}`}
										className={`supertable-row-data ${
											props.onRowClicked
												? 'clickable'
												: ''
										}`}
									>
										{column.render(item, itemIndex)}
									</RenderComponent>
									{columnIndex === columns.length - 1 && (
										<RowSeparator />
									)}
								</>
							))}
						</>
					)
				})}
			</WrapGrid>
		)
	}

	return (
		<Wrap
			className="supertable-wrap"
			useAlternateStyles={props.useAlternateStyles}
		>
			<div
				className="supertable-header"
				style={{
					overflowY: props.disableScrolling ? 'hidden' : 'scroll',
				}}
			>
				<Row className="supertable-header-row">
					{columns.map((column, columnIndex) => {
						const columnKey = `${column.title}-${columnIndex}`

						let FilterComponent: FilterComponent<T> | null = null

						if (column.filterComponent) {
							FilterComponent = column.filterComponent
						} else if (typeof column.filter === 'string') {
							FilterComponent =
								TypedSuperTableGenericTextFilter<T>
						} else if (typeof column.filter === 'function') {
							FilterComponent =
								TypedSuperTableGenericTextFilter<T>
						}

						if (
							column.useAsDefaultSort &&
							sortData.sortColumn === null
						) {
							setSortData({
								sortColumn: columnKey,
								shouldReverseSort: false,
								sortFunction: getSortFunctionForColumn(column),
							})
						}

						return (
							<div
								style={{ flex: column.size }}
								key={columnKey}
								className="supertable-header-row-data"
								id={columnKey}
							>
								{FilterComponent && (
									<SuperTableColumnFilter>
										<FilterComponent
											items={props.items.filter(
												(item, index) => {
													if (!props.filter)
														return true

													// If the user passes a filter to the config, then those values "don't exist" for the filter component
													// However, all of the other filters shouldn't apply here, otherwise the second you select one option
													// and narrow the results, the other options disappear from the filter itself.
													return props.filter(
														item,
														index
													)
												}
											)}
											filterVal={column.filter}
											setFilterFunction={(func) => {
												if (
													columnDefinedFilterFunctions[
														columnKey
													] === func
												) {
													return
												}

												setColumnDefinedFilterFunctions(
													{
														...columnDefinedFilterFunctions,
														[columnKey]: func,
													}
												)
											}}
										/>
									</SuperTableColumnFilter>
								)}

								{props.useAlternateStyles ? (
									<div>{column.title}</div>
								) : (
									<H4>{column.title}</H4>
								)}

								{'sort' in column && (
									<BsSortDown
										onClick={() => {
											if (!column.sort) return

											setSortData({
												sortColumn: columnKey,
												shouldReverseSort:
													sortData.sortColumn ===
													columnKey
														? !sortData.shouldReverseSort
														: false,
												sortFunction: column.sort,
											})
										}}
									/>
								)}

								{'sortKey' in column && (
									<BsSortDown
										onClick={() => {
											setSortData({
												sortColumn: columnKey,
												shouldReverseSort:
													sortData.sortColumn ===
													columnKey
														? !sortData.shouldReverseSort
														: false,
												sortFunction: (a: T, b: T) => {
													if (
														column.sortKey ===
														undefined
													) {
														// We should never hit this... but typescript doesn't know that
														return 0
													}

													return compare(
														a[column.sortKey],
														b[column.sortKey]
													)
												},
											})
										}}
									/>
								)}
							</div>
						)
					})}
				</Row>
			</div>
			<div
				className="supertable-body"
				onScroll={handleBodyScroll}
				style={{
					overflowY: props.disableScrolling ? 'hidden' : 'scroll',
				}}
			>
				{itemsFilteredAndSorted.map((item, itemIndex) => {
					const SpanWithBodyStyles = ({
						children,
						...props
					}: any) => {
						return (
							<Span applyBodyStyles {...props}>
								{children}
							</Span>
						)
					}

					const RenderComponent = props.useAlternateStyles
						? 'div'
						: SpanWithBodyStyles

					return (
						<div
							key={itemIndex}
							className={`supertable-row ${
								isItemSelected(item)
									? 'supertable-row--selected'
									: ''
							}`}
						>
							<Row
								onClick={() =>
									props.onRowClicked?.(item, itemIndex)
								}
							>
								{columns.map((column, columnIndex) => (
									<RenderComponent
										style={{ flex: column.size }}
										key={`${itemIndex}-${columnIndex}`}
										className={`supertable-row-data ${
											props.onRowClicked
												? 'clickable'
												: ''
										}`}
									>
										{column.render(item, itemIndex)}
									</RenderComponent>
								))}
							</Row>
							{footers.map((footer, footerIndex) => {
								return (
									<React.Fragment key={footerIndex}>
										{footer.render(item, itemIndex)}
									</React.Fragment>
								)
							})}
						</div>
					)
				})}
			</div>
		</Wrap>
	)
}

export const getSuperTableComponents = <T,>() => {
	return {
		Wrap: WrapComponent<T>,
		Column: Column<T>,
		Footer: Footer<T>,
	}
}

export default getSuperTableComponents
