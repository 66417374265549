import React from 'react'
import { ModalV2Props } from './types'
import { ModalWrapper, ModalNav, ModalTitle, ModalContent, ModalFooter } from './components'

//* Usage: This will be the modal that we use for most cases (with nav)
//* Pass array of buttons to be rendered
//! There is an optional styles prop to override the modal design specs
//! This is only for cases where there's an outdated design that's being refactored into ModalV2
//! ex. Deprecated modal is converted to ModalV2 and there's now excessive overflowY
const ModalV2 = ({
  size = "md",
  title,
  icon,
  buttons,
  styles,
  onClose: handleClose,
  onBack: handleBack,
  onContentScroll: handleContentScroll,
  children
} : ModalV2Props) => {
  return (
    <ModalWrapper size={size} onClose={handleClose} onBack={handleBack} styles={styles?.wrapper}>
      <ModalNav/>
      <ModalTitle title={title} icon={icon}/>
      {
        children && (
          <ModalContent styles={styles?.content} onScroll={handleContentScroll}>
            {children}
          </ModalContent>
        )
      }
      {buttons && (
        <ModalFooter styles={styles?.footer}>
            {buttons.map((button, id) =>
              <React.Fragment key={id}>{button}</React.Fragment>)}
        </ModalFooter>)
      }
    </ModalWrapper>
  )
}

export default ModalV2