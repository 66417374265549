import { TOKEN_DESCRIPTION, TOKEN_SCOPES, CLEAR_TOKEN_TAB } from "./types"

export const updateTokenDescription = description => dispatch => (
    dispatch({
        type: TOKEN_DESCRIPTION,
        payload: description
    })
)

export const updateTokenScopes = scopes => dispatch => (
    dispatch({
        type: TOKEN_SCOPES,
        payload: scopes
    })
)

export const clearTokenTab = () => dispatch => (
    dispatch({
        type: CLEAR_TOKEN_TAB,
    })
)

