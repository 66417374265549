import React from "react"
import { FiTrendingUp } from "react-icons/fi"
import { useTheme } from "styled-components"
import { hexToRgba } from "../../../helpers/colors.util"
import { XS, Span, mapTextSizeToTypography } from "../../../styles/typography"

type IncreaseBadgeProps = {
    change: number, //What the percent change should be. Typically when comparing metrics across a date range to metrics across another date range (YoY)
    changeLabel?: string, //Additional label information
    size: 'xs' | 'sm' //This impacts the typography that will be used
    style?: React.CSSProperties
}

export const IncreaseBadge = (props: IncreaseBadgeProps) => {

    const theme = useTheme()
    const Typography = mapTextSizeToTypography[props.size]
    return (
        <XS
            color="activeButtonColor"
            style={{
                backgroundColor: hexToRgba(theme.color.activeButtonColor, 0.1),
                padding: "0.25rem 0.5rem",
                borderRadius: "0.25rem",
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
                whiteSpace: "normal",
                ...props.style
            }}
        >
            <FiTrendingUp size='12' />
            <Span
                color="activeButtonColor"
                semibold
                style={{ marginLeft: '2px' }}
            >
                <Typography colorInherit>
                    {Math.abs(props.change).toFixed(1)}%
                    {props.changeLabel && ` ${props.changeLabel}`}
                </Typography>
            </Span>
        </XS>
    )
}
