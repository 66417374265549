import React from 'react'
import { COLORS } from '../../constants/colors'

export const FacebookIcon = ({ invert }: { invert?: boolean}) => {
    const backgroundColor = invert ? 'white' : COLORS.FACEBOOK_BLUE
    const logoColor = invert ? COLORS.FACEBOOK_BLUE : 'white'
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill={backgroundColor}/>
            <g clipPath="url(#clip0_126_4753)">
                <path
                    fill={logoColor}
                    d="M14.1761 12.8069L14.6439 9.83508H11.7626V7.9034C11.7626 7.09079 12.1649 6.29676 13.4512 6.29676H14.7795V3.76608C14.0059 3.64272 13.2243 3.57598 12.4408 3.56641C10.0694 3.56641 8.52124 4.99195 8.52124 7.56907V9.83508H5.89258V12.8069H8.52124V19.995H11.7626V12.8069H14.1761Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_126_4753">
                    <rect width="16.4286" height="16.4286" fill="white" transform="translate(1.78613 3.57227)"/>
                </clipPath>
            </defs>
        </svg>
    )
}