import React, { useState, useEffect, useRef } from "react";
import {CarouselButton, CarouselGradient, CarouselWrapper, StyleCarousel} from './style'
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { NavItem } from "../../styles/selectors";
import { Body } from "../../../../styles/typography";


// Note: useChevrons is only used for GACards case where all children are same width.
// It is possible to determine x to slide another way by getting the widths of all children
// as opposed to using the wrapper width and estimating but it may be overkill for now
const Slider = ({ children, width, style={}, displayButtons=true, expandWidth=false, useChevrons=false, justifyTabs="" }) => {
  const wrapper = useRef() // The "window" that the currently visible options show through
  const slider = useRef() // Holds the children (options) which slide in and out of view

  const [showLeftBtn, setShowLeftBtn] = useState(false)
  const [showRightBtn, setShowRightBtn] = useState(false)

  const x = useRef(0) // The position the slider has been offset horizontally
  const offsetToSlide = parseInt(width) - (useChevrons ? 0 : 100) || 100

  // * This resets the slider when changing location's that may have additonal reports
  const resetSlider = () => {
    setShowRightBtn(true)
    slider.current.style.transform = 'translateX(0px)';
    x.current = 0;
  }

  const updateSlider = () => {
    resetSlider();
    setShowLeftBtn(false)
    const children = Array.from(slider.current.children);
    if (slider?.current?.clientWidth <= wrapper?.current?.clientWidth)  setShowRightBtn(false)

    children.forEach((child) => {
      child.classList.add("carousel-item");
    });
  }

  // If the number of children changes, rebuild the slider
  useEffect(() => {
    updateSlider()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.length]);

  const isAtRightLimit = val => Math.abs(val) + offsetToSlide >= slider.current.clientWidth
  const isAtLeftLimit = val => Math.abs(val) <= 0

  const handleRightBtn = () => {
    // Unhide the other button
    setShowLeftBtn(true)

    // Are we at the end? Then dont do anything
    if (isAtRightLimit(x.current)) return

    // Slide
    x.current -= offsetToSlide
    slider.current.style.transform = `translateX(${x.current}px)`;

    // Reached limit (right) so hide this button now
    if (isAtRightLimit(x.current)) setShowRightBtn(false)
  };

  const handleLeftBtn = () => {
    // Unhide the other button
    setShowRightBtn(true)

    // Are we at the beginning? Then dont do anything
    if(isAtLeftLimit(x.current)) return

    // Slide
    x.current += offsetToSlide
    slider.current.style.transform = `translateX(${x.current}px)`;

    // Reached limit (left) so hide this button now
    if(isAtLeftLimit(x.current)) setShowLeftBtn(false)
  };

  return (
    <StyleCarousel style={style}>

      <CarouselButton displayButtons={displayButtons} show={showLeftBtn} useChevrons={useChevrons}>
        {
          useChevrons ? <BsChevronLeft onClick={handleLeftBtn} /> :
            <FiArrowLeftCircle size="30" onClick={handleLeftBtn} />
        }
      </CarouselButton>

      <CarouselWrapper ref={wrapper} width={width} useChevrons={useChevrons} justifyTabs={justifyTabs}>
        <CarouselGradient direction='left' x={x} show={!useChevrons && showLeftBtn && displayButtons} />
        <div ref={slider} className="carousel-slider" style={expandWidth ? {width: '100%', justifyContent: children?.length <= 2 ? 'center' :'space-between'} : {}}>
          {children}
        </div>
        <CarouselGradient direction='right' x={x} show={!useChevrons && showRightBtn && displayButtons} />
      </CarouselWrapper>

      <CarouselButton displayButtons={displayButtons} show={showRightBtn} useChevrons={useChevrons}>
        {
          useChevrons ? <BsChevronRight onClick={handleRightBtn} /> :
            <FiArrowRightCircle size="30" onClick={handleRightBtn} />
        }
      </CarouselButton>

    </StyleCarousel>
  );
};

export const createNavItem = ({ title, type, selectedField, useExactMatch = false, onClick, Typography = Body, noUpperCase = false }) => {
  return (
    <span key={type}>
      <NavItem exact={useExactMatch} selectedField={selectedField} type={type} onClick={onClick} noUpperCase={noUpperCase}>
        <Typography>{title}</Typography>
      </NavItem>
    </span>
  )
}

export default Slider;