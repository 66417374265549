import { SHOW_OVERLAY, HIDE_OVERLAY } from '../actions/types';


const initialState = {
    overlayShowing: false,
    message: ''
}


const overlayReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_OVERLAY: {
            return { ...state, overlayShowing: true, message: action.payload || '' }
        }
        case HIDE_OVERLAY: {
            return initialState
        }
        default: {
            return { ...state }
        }
    }
}


export default overlayReducer 