import React, { useState } from 'react'
import { Body, H2, Small } from '../../../styles/typography'
import { StyledCreativeDetails } from './styles'
import DisplayMedia from '../CreativeModalV2/DisplayMedia'
import { NetworkItem } from '../CreativeModalV2/styles'
import NetworkIcon from '../CreativeModalV2/NetworkIcon'
import { CREATIVE_KIND, getCreativeKindLabel, getNetworkName, isAdEntity, isPostEntity } from '../../../helpers/creative.util'
import SearchTextModal from '../../../pages/build/modals/SearchTextModal'
import SearchOnlyCloneCard from '../../searchOnlyCard/SearchOnlyCloneCard'
import useFindLocation from '../../../hooks/useFindLocation'
import { isSearchOnlyCampaign } from '../../locations/util'
import useExperiment from '../../../hooks/useExperiment'
import { EXPERIMENTS } from '../../../helpers/experimentHelpers'

const CloneCreativeDetails = ({ creative }) => {
    const [showSearchText, setShowSearchText] = useState(false)

    const creativeKindLabel = getCreativeKindLabel(creative)
    const isAd = isAdEntity(creative)
    const isPost = isPostEntity(creative)
    const blockPostsOnSecondariesExp = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)

    const { extendedText, location } = creative
    const { matchedLocation } = useFindLocation(location?.websafe)

    return (
        <div>
            <H2>Clone {creativeKindLabel}</H2>
            <Body>Select all campaigns or blueprints you want to clone your creative to. {blockPostsOnSecondariesExp.active && isPost ? "Keep in mind: Social post creatives can only be applied to primary campaigns. Please double check your selections before proceeding." : ""}</Body>
            <StyledCreativeDetails>
                <div className='media'>
                    {
                        (isSearchOnlyCampaign(matchedLocation) && creative?.kind === CREATIVE_KIND.CREATIVE) ?
                            <SearchOnlyCloneCard creative={creative} /> :
                            <DisplayMedia creative={creative} />
                    }
                </div>
                <div className='creative-data'>
                    {
                        isAd &&
                        <div className='data-item'>
                            <Small className='header' bold>Search Headlines & Descriptions</Small>
                            <Small color='accentColor2' underline onClick={() => setShowSearchText(true)}>View List</Small>
                        </div>
                    }
                    <div className='data-item'>
                        <Small className='header' bold>Social Text</Small>
                        <Small>{extendedText}</Small>
                    </div>
                    {
                        isPost &&
                        <div className='data-item'>
                            <Small className='header' bold>Networks</Small>
                            <div>
                                {
                                    creative?.targetNetworks.map(network => {
                                        return (
                                            <NetworkItem key={network} style={{ display: 'flex', gap: '8px' }}>
                                                <NetworkIcon network={network} /> <Small>{getNetworkName(network)}</Small>
                                            </NetworkItem>)
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </StyledCreativeDetails>
            {
                showSearchText && <SearchTextModal creative={creative} onClose={() => setShowSearchText(false)} />
            }
        </div>
    )
}

export default CloneCreativeDetails