import { buildFooterObject } from './../components/footer/footer.util';
import { CLEAR_BRAND_THEME, SET_ANIMATION, LOAD_BRAND_THEME, TOGGLE_THEME, LOAD_DEFAULTS, GET_CLIENT_API_KEYS, TOGGLE_SIDEBAR, UPDATE_BRAND_THEME_COLOR} from "../actions/types";
import { buildColorObject, ColorTheme } from "../helpers/colors.util";
import { buildFontObject } from "../helpers/font.utils";
import { SidebarStates, getSidebarState } from "../helpers/ui.utils";
import { FCCTheme, ThemeOptions } from "../features/themeProvider/types";
import { ResponseTypes } from '../types/apiResponses';
import { API } from '../constants/api';
import { BannerType } from '../components/banner/Banner';


const initialState = {
    theme: 'light' as ThemeOptions,
    sidebar: 'full' as SidebarStates,
    darkTheme: {
        color: buildColorObject(),
        font: buildFontObject(),
        footer: buildFooterObject()
    } as FCCTheme,
    lightTheme: {
        color: buildColorObject(),
        font: buildFontObject(),
        footer: buildFooterObject()
    } as FCCTheme,
    brandTheme: undefined as FCCTheme | undefined,
    adsExtendedTextMaxLength: 510,
    postsExtendedTextMaxLength: 2010,
    maxImageResolution: "3240x3240",
    maxLogoResolution: "1080x1080",
    suggestedImageResolution: "1080x1080",
    suggestedLogoResolution: "100x100",
    minAdDuration:  7,
    googleClientApiKey: null as string | null,
    ctas: [],
    banners: [] as BannerType[],
    defaultCta: "",
    defaultPhoneFormats: {},
    dropboxClientAppKey: null as string | null,
}

type UIState = typeof initialState & {
    collapseAnimation?: gsap.core.Timeline
    hideAnimation?: gsap.core.Timeline
}

type UIActionToggleTheme = {
    type: typeof TOGGLE_THEME
    payload: ThemeOptions
}

type UIActionLoadDefaults = {
    type: typeof LOAD_DEFAULTS
    payload: any
}

type UIActionLoadBrandTheme = {
    type: typeof LOAD_BRAND_THEME
    payload: any
}

type UIActionUpdateBrandThemeColor = {
    type: typeof UPDATE_BRAND_THEME_COLOR
    payload: ColorTheme
}

type UIActionClearBrandTheme = {
    type: typeof CLEAR_BRAND_THEME
}

type UIActionGetClientApiKeys = {
    type: typeof GET_CLIENT_API_KEYS
    payload: ResponseTypes[typeof API.USER.CLIENT_API_KEYS]
}

type UIActionSetAnimation = {
    type: typeof SET_ANIMATION
    payload: {
        hide: gsap.core.Timeline
        collapse: gsap.core.Timeline
    }
}

type UIActionToggleSidebar = {
    type: typeof TOGGLE_SIDEBAR
    payload?: SidebarStates
}

type UIAction =
    | UIActionToggleTheme
    | UIActionLoadDefaults
    | UIActionLoadBrandTheme
    | UIActionUpdateBrandThemeColor
    | UIActionClearBrandTheme
    | UIActionGetClientApiKeys
    | UIActionSetAnimation
    | UIActionToggleSidebar

const UIReducer = (state: UIState = initialState, action: UIAction): UIState => {
    switch (action.type) {
        case TOGGLE_THEME: {
            return { ...state, theme: action.payload }
        }
        case LOAD_DEFAULTS: {
            const uiObj = action.payload ?? {}
            return {
                ...state,
                darkTheme: {
                    ...state.darkTheme,
                    color: uiObj.darkThemeColor,
                    font: uiObj.darkThemeFont,
                    footer: uiObj.darkThemeFooter
                },
                lightTheme: {
                    ...state.lightTheme,
                    color: uiObj.lightThemeColor,
                    font: uiObj.lightThemeFont,
                    footer: uiObj.lightThemeFooter
                },
                adsExtendedTextMaxLength: uiObj.adsExtendedTextMaxLength,
                postsExtendedTextMaxLength: uiObj.postsExtendedTextMaxLength,
                maxImageResolution: uiObj.maxImageResolution,
                maxLogoResolution: uiObj.maxLogoResolution,
                suggestedImageResolution: uiObj.suggestedImageResolution,
                suggestedLogoResolution: uiObj.suggestedLogoResolution,
                minAdDuration: uiObj.minAdDuration,
                ctas: uiObj.ctas,
                defaultCta: uiObj.defaultCta,
                defaultPhoneFormats: uiObj.defaultPhoneFormats,
                dropboxClientAppKey: uiObj.dropboxClientAppKey,
                banners: uiObj.banners
            }
        }
        case LOAD_BRAND_THEME: {
            return {
                ...state,
                brandTheme: {
                    ...state.brandTheme,
                    color: action.payload.brandTheme,
                    font: action.payload.fontTheme,
                    footer: action.payload.footerTheme
                }
            }
        }
        case UPDATE_BRAND_THEME_COLOR: {

            if (!state.brandTheme) {
                return {
                    ...state,
                    brandTheme: {
                        ...(state.theme === "light" ? state.lightTheme : state.darkTheme),
                        color: action.payload
                    }
                }
            }

            return {
                ...state,
                brandTheme: {
                    ...state.brandTheme,
                    color: action.payload
                }
            }
        }
        case CLEAR_BRAND_THEME: {
            return {
                ...state,
                brandTheme: undefined
            }
        }
        case GET_CLIENT_API_KEYS: {
            return {
                ...state,
                googleClientApiKey: action.payload.googleClientApiKey,
            }
        }
		case SET_ANIMATION: {
		  if (!action.payload) return state
		  const { hide, collapse } = action.payload
		  return {
			...state,
			hideAnimation: hide,
			collapseAnimation: collapse,
		  }
		}
		case TOGGLE_SIDEBAR: {
		  const newState = action.payload ?? getSidebarState(state.sidebar as SidebarStates)
		  return {
			...state,
			sidebar: newState
		  }
		}
        default: {
            return { ...state }
        }
    }
}


export default UIReducer
