import React, { useState, useEffect, useMemo, useRef } from 'react';

import Modal from '../Modal';
import Confirm from '../../confirm/Confirm';
import ClonesList from './ClonesList';
import { OutlineBtn } from '../../../styles/buttons';

import {
    StyledBulkEditModal,
    StyleEditBulkHeader,
    EnhancePrimaryButton,
    StyledHeaderBulkActionsWrap,
    StyledActionsWrap,
} from './styles';
import { toast } from 'react-toastify';
import { Body, H2, H4 } from '../../../styles/typography';
import useCreativeActions from '../../../hooks/useCreativeActions';
import { BULK_ACTION } from '../../../helpers/bulkEdits';
import AcceptModal from '../../../pages/build/modals/AcceptModal';
import { useTheme } from 'styled-components';
import { decideTextColor } from '../../../helpers/colors.util';
import { useJob } from '../../../features/backgroundJobs';

const BulkEditModal = ({ onClose, creative, bulkEditor, refreshCreatives }) => {
    const { options } = bulkEditor
    const theme = useTheme()

    const {createJob} = useJob();
    const {jobPauseCreatives, jobDeleteCreatives, jobResumeCreative, jobAcceptCreative } = useCreativeActions();

    const [selectedClones, setSelectedClones] = useState([]);
    const selectedCloneLocations = useMemo(() => selectedClones?.map(c => c.locationData),[selectedClones])
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);


    const [isGettingAcceptData, setIsGettingAcceptData] = useState(false);


    const acceptDataRef = useRef({});
    const creativeType = creative?.kind?.includes('Post') ? 'Post' : 'Ad';

    // all clones that are selectable and editable
    // need a useMemo hook as initial useEffect can only run once
    const selectableAndEditableClones = useMemo(() => {
        return options.clones.filter((c) => !c.isUnselectable && c.canEdit);
    }, [options.clones]);

    useEffect(() => {
        setSelectedClones(selectableAndEditableClones);
    }, [selectableAndEditableClones]);

    // Helpers
    const selectClone = (clone) => {
        const newSelection = [...selectedClones, clone];
        setSelectedClones(newSelection);
    };

    const modalButtonData = (opt, hasTxt, creativeType) => {
        if (opt.action === BULK_ACTION.PAUSE)
            return hasTxt ? `Pause Ads` : { backgroundColor: theme.color.warningButtonColor, color: decideTextColor(theme.color.warningButtonColor, [theme.color.textColor1, theme.color.textColor3]) };
        else if (opt.action === BULK_ACTION.RESUME)
            return hasTxt ? 'Resume Ads' : { backgroundColor: theme.color.activeButtonColor, color: decideTextColor(theme.color.activeButtonColor, [theme.color.textColor1, theme.color.textColor3]) };
        else if (opt.action === BULK_ACTION.DELETE)
            return hasTxt
                ? `Delete ${creativeType}s`
                : { backgroundColor: theme.color.errorNegativeButtonColor, color: decideTextColor(theme.color.errorNegativeButtonColor, [theme.color.textColor1, theme.color.textColor3]) };
        else if (opt.action === BULK_ACTION.ACCEPT)
            return hasTxt
                ? `Accept ${creativeType}s`
                : { backgroundColor: theme.color.activeButtonColor, color: decideTextColor(theme.color.activeButtonColor, [theme.color.textColor1, theme.color.textColor3]) };
    };

    const deselectClone = (clone) => {
        const newSelection = selectedClones.filter(
            (c) => c.id !== clone.id && c.locationData?.id !== clone.locationData?.id
        );
        setSelectedClones([...newSelection]);
    };

    const selectAll = () => setSelectedClones(selectableAndEditableClones);
    const deselectAll = () => setSelectedClones([]);

    // Do the Delete/Pause/Resume Action
    const handleSubmit = () => {
        if (!selectedClones.length) {
            toast.info(`Please select ads you want to ${options.action}`);
            return;
        }

        setIsConfirmOpen(false);

        const actions = {
            [BULK_ACTION.PAUSE]: {
                jobDescription: "Pause Creatives",
                jobFunction: jobPauseCreatives
            },
            [BULK_ACTION.DELETE]: {
                jobDescription: "Delete Creatives",
                jobFunction: jobDeleteCreatives
            },
            [BULK_ACTION.ACCEPT]: {
                jobDescription: "Accept Creatives",
                jobFunction: ({creative}) => jobAcceptCreative({ creative, data: acceptDataRef.current})
            },
            [BULK_ACTION.RESUME]: {
                jobDescription: "Resume Creatives",
                jobFunction: jobResumeCreative
            }
        };

        let apiCalls = [];
        const currentAction = actions[options.action];
        selectedClones.forEach(clone => {
            const processTask = currentAction.jobFunction({ creative: clone })
            if (processTask) {
                apiCalls.push(processTask);
            }
        });

        createJob({
            description: currentAction.jobDescription,
            tasks: apiCalls
        });
        onClose()
    };

    const BulkHeader = ({ title, action, paragraph, creativeType }) => {
        return (
            <StyleEditBulkHeader>
                <H2 style={{ marginBottom: '1rem' }}>
                    {title}
                </H2>
                <H4>{`Select ${
                    creativeType === 'Post' ? creativeType + 's' : 'Ads'
                } to ${action}`}</H4>
                {creativeType === 'Ad' && <Body>{paragraph}</Body>}
            </StyleEditBulkHeader>
        );
    };


    return (
        <Modal onClose={onClose} minHeight={'700px'} styles={{ width: '80vw' }}>
            <StyledBulkEditModal>
                {options.action === BULK_ACTION.DELETE && (
                    <>
                        <StyledHeaderBulkActionsWrap>
                            <BulkHeader
                                title='Bulk Delete'
                                action='Delete'
                                paragraph='(Greyed out ads are not deletable as their campaigns have 4 or fewer creatives.)'
                                creativeType={creativeType}
                            />
                            <StyledActionsWrap>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={selectAll}>
                                    Select All
                                </OutlineBtn>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={deselectAll}>
                                    Deselect All
                                </OutlineBtn>
                            </StyledActionsWrap>
                        </StyledHeaderBulkActionsWrap>
                        <ClonesList
                            clones={options.clones}
                            selectedClones={selectedClones}
                            bulkEditAction={options.action}
                            selectClone={selectClone}
                            deselectClone={deselectClone}
                            creativeType={creativeType}
                        />
                        <p>Selected: {selectedClones.length}</p>
                        <EnhancePrimaryButton
                            style={{ width: '10rem' }}
                            onClick={() => setIsConfirmOpen(true)}
                        >
                            Delete {creativeType}s
                        </EnhancePrimaryButton>
                    </>
                )}
                {options.action === BULK_ACTION.PAUSE && (
                    <>
                        <StyledHeaderBulkActionsWrap>
                            <BulkHeader
                                title='Bulk Pause'
                                action='Pause'
                                paragraph='Greyed out ads are not pauseable as they are not currently active or will cause the campaign to have less than the recommended number of active ads.'
                                creativeType={creativeType}
                            />
                            <StyledActionsWrap>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={selectAll}>
                                    Select All
                                </OutlineBtn>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={deselectAll}>
                                    Deselect All
                                </OutlineBtn>
                            </StyledActionsWrap>
                        </StyledHeaderBulkActionsWrap>
                        <ClonesList
                            clones={options.clones}
                            selectedClones={selectedClones}
                            bulkEditAction={options.action}
                            selectClone={selectClone}
                            deselectClone={deselectClone}
                            creativeType={creativeType}
                        />
                        <p>Selected: {selectedClones.length}</p>
                        <EnhancePrimaryButton onClick={() => setIsConfirmOpen(true)}>
                            Pause {creativeType}s
                        </EnhancePrimaryButton>
                    </>
                )}
                {options.action === BULK_ACTION.RESUME && (
                    <>
                        <StyledHeaderBulkActionsWrap>
                            <BulkHeader
                                title='Bulk Resume'
                                action='Resume'
                                paragraph='(Greyed out ads are not resumable as they are currently active.)'
                                creativeType={creativeType}
                            />
                            <StyledActionsWrap>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={selectAll}>
                                    Select All
                                </OutlineBtn>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={deselectAll}>
                                    Deselect All
                                </OutlineBtn>
                            </StyledActionsWrap>
                        </StyledHeaderBulkActionsWrap>
                        <ClonesList
                            clones={options.clones}
                            selectedClones={selectedClones}
                            bulkEditAction={options.action}
                            selectClone={selectClone}
                            deselectClone={deselectClone}
                            creativeType={creativeType}
                        />
                        <p>Selected: {selectedClones.length}</p>
                        <EnhancePrimaryButton onClick={() => setIsConfirmOpen(true)}>
                            Resume {creativeType}s
                        </EnhancePrimaryButton>
                    </>
                )}
                {options.action === BULK_ACTION.ACCEPT && (
                    <>
                        <StyledHeaderBulkActionsWrap>
                            <BulkHeader
                                title='Bulk Accept'
                                action='Accept'
                                paragraph='(Greyed out ads are not acceptable)'
                                creativeType={creativeType}
                            />
                            <StyledActionsWrap>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={selectAll}>
                                    Select All
                                </OutlineBtn>
                                <OutlineBtn style={{ whiteSpace: 'nowrap' }} onClick={deselectAll}>
                                    Deselect All
                                </OutlineBtn>
                            </StyledActionsWrap>
                        </StyledHeaderBulkActionsWrap>
                        <ClonesList
                            clones={options.clones}
                            selectedClones={selectedClones}
                            bulkEditAction={options.action}
                            selectClone={selectClone}
                            deselectClone={deselectClone}
                            creativeType={creativeType}
                        />
                        <p>Selected: {selectedClones.length}</p>
                        <EnhancePrimaryButton
                            disabled={!selectedClones.length}
                            onClick={() => setIsGettingAcceptData(true)}
                        >
                            Accept {creativeType}s
                        </EnhancePrimaryButton>
                    </>
                )}
            </StyledBulkEditModal>
            {isConfirmOpen && (
                <Confirm
                    buttonColor={modalButtonData(options, false, creativeType)}
                    onClose={() => setIsConfirmOpen(false)}
                    text={`Are you sure you want to ${options.action} these creative clones?`}
                    confirm={{
                        text: modalButtonData(options, true, creativeType),
                        callback: () => {
                            handleSubmit();
                        },
                    }}
                    cancel={{
                        text: 'Cancel',
                        callback: () => {
                            setIsConfirmOpen(false);
                        },
                    }}
                />
            )}
            {isGettingAcceptData && (
                <AcceptModal
                    sample={creative}
                    locations={selectedCloneLocations}
                    isBulk
                    onClose={() => setIsGettingAcceptData(false)}
                    onFinish={(data) => {
                        acceptDataRef.current = data;
                        handleSubmit();
                        setIsGettingAcceptData(false);
                    }}
                />
            )}
        </Modal>
    );
};

export default BulkEditModal;
