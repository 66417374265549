import { useMemo } from "react";
import { useSelector } from "react-redux"

// * This hook will take a websafe and will return the location that matches its given websafe.
const useFindLocation = (websafe) => {
    const locations = useSelector(state => state.franchise.locations);

    const matchedLocation = useMemo(() => {
        return locations?.find(location => location?.websafe === websafe)
    }, [websafe, locations])

    return {
      matchedLocation
    }
}

export default useFindLocation