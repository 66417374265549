import styled from "styled-components";
import { LoadingIndicator } from "../loading/styles";

const LoaderWrap = styled.div`
  display: grid;
  place-items: center;
`

export const Loader = ({style = {}}) => {
    return (
    <LoaderWrap style={style}>
        <LoadingIndicator />
     </LoaderWrap>
    )
}