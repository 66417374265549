import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useCreativeActions from '../../../hooks/useCreativeActions';
import { OutlineBtn } from '../../../styles/buttons';
import { ButtonsContainer, CancelBtn, RejectBtn, RejectionFormContainer, RejectionHeader } from './styles';
import Modal from '../../../components/modal/Modal';

const ModalApproval = ({ creative, onClose, refreshCreatives }) => {

   const rejectionList = useSelector(s => s.resources.rejectionReasons)
   const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
   const [textValue, setTextValue] = useState('');
   const [option, setOption] = useState("");
   const { reviewCreative } = useCreativeActions()

   const openRejectModal = () => {
      setIsRejectModalOpen(true);
   };

   const closeRejectModal = (e) => {
      e?.stopPropagation();
      setIsRejectModalOpen(false);
  };

   const rejectionOptions = () => {
      return rejectionList.map(rejection => {
         return <option value={rejection.id} key={rejection.id}>{rejection.title}</option>
      })
   }

   const handleSubmit = e => {
      e.preventDefault()

      reviewCreative({
         creative,
         approved: false,
         reasonId: option,
         description: textValue
      }).then(() => {
         toast.success('Your creative has successfully been rejected.')
         refreshCreatives?.();
         closeRejectModal()
         onClose()
      })
   }

   const rejectionForm = () => {
      return (
         <RejectionFormContainer onSubmit={handleSubmit}>
            <RejectionHeader>
               <h2 className="rejectionTitle">{`Reject ${creative.kind === 'Creative' ? 'Dynamic': creative.kind} Ad`}</h2>
               <p className="rejectionSubTitle">Are you sure you want to reject this ad? This action will permanently reject this ad.</p>
               <h4> Please provide a reason for rejection to proceed.</h4>
            </RejectionHeader>
            <label>
               Reason
               <select value={option} onChange={e => setOption(e.target.value)}>
                  <option value="">Select a reason to continue.</option>
                  {rejectionOptions()}
               </select>
            </label>

            <label>
               Description
               <textarea value={textValue} onChange={(e) => setTextValue(e.target.value)} placeholder="Optional" />
            </label>

            <ButtonsContainer>
               <CancelBtn type="button" onClick={closeRejectModal}> Cancel </CancelBtn>
               <RejectBtn disabled={!option} type="submit"> Reject </RejectBtn>
            </ButtonsContainer>
         </RejectionFormContainer>
      )
   }

   return (
      <>
         <OutlineBtn onClick={openRejectModal}>Reject</OutlineBtn>
         {isRejectModalOpen &&
            <Modal
               onClose={closeRejectModal}
               maxWidth={'656px'}
            >
               {rejectionForm()}
            </Modal>}
      </>
   )
}

export default ModalApproval