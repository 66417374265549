import {
    UPDATE_SMS_HISTORY_SEARCH_VAL,
    UPDATE_SMS_PREVIEW,
    UPLOAD_SMS_MEDIA,
    REMOVE_SMS_MEDIA,
    CLEAR_SMS_TAB
} from '../actions/types'

const initialState = {
    smsMediaBlob: null,
    smsPreviewOpen: false,
    smsHistorySearchVal: ''
}

const smsReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPLOAD_SMS_MEDIA: {
            return {...state, smsMediaBlob: action.payload.blobKey}
        }
        case REMOVE_SMS_MEDIA: {
            return {...state, smsMediaBlob: null}
        }
        case UPDATE_SMS_HISTORY_SEARCH_VAL: {
            return {...state, smsHistorySearchVal: action.payload}
        }
        case UPDATE_SMS_PREVIEW: {
            return {...state, smsPreviewOpen: action.payload}
        }
        case CLEAR_SMS_TAB: {
            return initialState;
        }
        default: {
            return { ...state }
        }
    }
}


export default smsReducer
