
export const sortByName = (a,b) => {
    const lowercaseA = a.name.toLowerCase()
    const lowercaseB = b.name.toLowerCase()
    return lowercaseA.localeCompare(lowercaseB)
}

export const sortAlphabetically = (a, b) => {
    const lowercaseA = a.toLowerCase()
    const lowercaseB = b.toLowerCase()
    return lowercaseA.localeCompare(lowercaseB)
}

export const sortByRecentDate = (a, b) => b.date.getTime() - a.date.getTime()


export function sortObjectOnIndex(obj, index) {
    if(!obj) return []
    // convert object to array
    const objArray = Object.entries(obj);
    // sort array based on the value at the specified index
    return objArray.sort((a, b) => parseInt(b[1][index]) - parseInt(a[1][index]));
}