import StringUtil from "../../../../../../../helpers/string.util";
import columnBuilder from "../../../columnBuilder";
import { reportLoader } from '../../../loaders';

const deviceReportCategory = columnBuilder()
	.category('distribution')
	.subCategory('Device')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.groupName} ${meta.name}`
	})

const deviceTypes = ['desktop', 'mobile', 'tablet'] as const

const deviceDistributionFormatter = (value: number) => {
	return `${value.toFixed(0)}%`
}

const deviceColumns = [
	...deviceTypes.map((deviceType) => {
		return deviceReportCategory
			.groupId('device-distribution-clicks')
			.groupName('Clicks')
			.id(`device-distribution-clicks-${deviceType}`)
			.name(`(${StringUtil.capitalizeFirstLetter(deviceType)})`)
			.makeRow((data) => {
				return data.row.device.clicks_pct[deviceType]
			})
			.toString(deviceDistributionFormatter)
	}),
	...deviceTypes.map((deviceType) => {
		return deviceReportCategory
			.groupId('device-distribution-impressions')
			.groupName('Impressions')
			.id(`device-distribution-impressions-${deviceType}`)
			.name(`(${StringUtil.capitalizeFirstLetter(deviceType)})`)
			.makeRow((data) => {
				return data.row.device.impressions_pct[deviceType]
			})
			.toString(deviceDistributionFormatter)
	}),
]

export default deviceColumns