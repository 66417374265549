import { startCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FiDownload, FiEdit3 } from 'react-icons/fi'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import Slider from '../../../../components/reusableUI/components/slider'
import { NavItem } from '../../../../components/reusableUI/styles/selectors'
import getSuperTableComponents from '../../../../components/SuperTable/getSuperTableComponents'
import ReportsDatePicker from '../../../../features/reportsDatePicker/ReportsDatePicker'
import { downloadFile } from '../../../../helpers/file.utils'
import { OutlineBtn, PrimaryCTABtn } from '../../../../styles/buttons'
import { Body, H2, Small, XS } from '../../../../styles/typography'
import { ButtonContainer } from '../../../creativerepo/styles'
import { useInsightsReportBuilderContext } from '../InsightsReportBuilder'
import { StyledOverlay } from '../../../../components/overlay/styles'
import ProgressBar from '../../../../components/progressbar'
import { BuiltInsightReportCategory, BuiltInsightReportCell } from '../core/reportBuilder'
import PerformanceViewSelector from '../components/PerformanceViewSelector'
import { InsightBuilderReportCategory } from './SelectReportType'
import { hexToRgba } from '../../../../helpers/colors.util'
import { isIdentifiable } from '../../../../helpers/structures/Queue'

const TableWrap = styled.div<{
    hasData: boolean
}>`
    height: 40rem;

    .supertable-header-row-data {
        background-color: ${({ theme }) => theme.color.backgroundColor2};
    }

    .supertable-row-data {
        text-overflow: ellipsis;
        overflow: hidden;

        min-width: 5rem;
        max-width: 10rem;
    }
`

const InsightReportDownloader = () => {
    const { views } = useInsightsReportBuilderContext()

    const escapeCellStringForCsv = (cell: BuiltInsightReportCell) => {
        return `"${cell.string.replace(/"/g, `""`)}"`
    }

    const downloadReport = useCallback(async () => {
        if (!views) return
        for (const [reportType, report] of Object.entries(views)) {
            if (!report) continue

            const mappedReport = report.map((row) => row.map(escapeCellStringForCsv))

            const headers = mappedReport[0]

            downloadFile(
                startCase(reportType),
                [headers, ...mappedReport.slice(1)].join('\n')
            )
        }
    }, [views])

    return (
        <div>
            <PrimaryCTABtn disabled={!views} onClick={downloadReport}>
                <FiDownload />
                Download CSVs
            </PrimaryCTABtn>
        </div>
    )
}

function isComparable(value: any): boolean {
    try {
        compare(value, value)
        return true
    } catch {
        return false
    }
}

function compare(a: any, b: any): number {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b)
    } else if (typeof a === 'number' && typeof b === 'number') {
        return a - b
    } else if (typeof a === 'boolean' && typeof b === 'boolean') {
        return a === b ? 0 : a ? 1 : -1
    } else if (a instanceof Date && b instanceof Date) {
        return a.getTime() - b.getTime()
    } else if (isIdentifiable(a) && isIdentifiable(b)) {

        const aSortKey = a.getKey()
        const bSortKey = b.getKey()

        return compare(aSortKey, bSortKey)
    }
    throw new Error('Cannot compare value')
}

const { Wrap, Column } = getSuperTableComponents<BuiltInsightReportCategory[number]>()

type FinalReportTableProps = {
    view?: BuiltInsightReportCategory
}

const FinalReportTable = (props: FinalReportTableProps) => {
    const { view } = props
    const VIRTUAL_INDEX_STEP = 100

    const [virtualizedIndex, setVirtualizedIndex] =
        React.useState(VIRTUAL_INDEX_STEP)

    if (!view || view.length < 2) {
        return (
            <div
                style={{
                    margin: '48px 0px',
                }}
            >
                <Body semibold center>
                    Press Generate Report to Review Your Custom CSVs
                </Body>
                <Small color="textColor2" center>
                    Any date changes made require a new click on the Report
                    Generation CTA
                </Small>
            </div>
        )
    }

    const typeSampler = view[1]

    return (
        <TableWrap
            hasData={view.slice(1).length > 1}
            style={{
                marginBottom: '48px',
            }}
        >
            <Wrap
                asGrid
                items={view.slice(1, virtualizedIndex)}
                onScrolledToBottom={() => {
                    setVirtualizedIndex((prev) => prev + VIRTUAL_INDEX_STEP)
                }}
            >
                {view[0].map((header, index) => {
                    const sort = isComparable(typeSampler[index].value)
                        ? {
                              sort: (a: any, b: any) => {
                                  return compare(a[index].value, b[index].value)
                              },
                          }
                        : {}

                    return (
                        <Column
                            key={index}
                            title={header.string}
                            size="1 0 100px"
                            {...sort}
                        >
                            {(item) => {
                                return (
                                    <div>
                                        {item[index].html}
                                    </div>
                                )
                            }}
                        </Column>
                    )
                })}
            </Wrap>
        </TableWrap>
    )
}

export const FinalReviewHeader = () => {

    const theme = useTheme()

    const [isViewSelectorOpen, setIsViewSelectorOpen] = useState(false)

    const { datePickerData, build, progress, visibleReportCategories } =
        useInsightsReportBuilderContext()
    const { selectedTab, setSelectedTab, aggregationText } = useFinalReviewContext()

    const [showProgress, setShowProgress] = React.useState(false)

    const generateReport = useCallback(async () => {
        try {
            setShowProgress(true)
            await build()
        } catch {
            toast.error('Failed to generate report')
        } finally {
            setShowProgress(false)
        }
    }, [build])

    const dateEnabled = useMemo(() => {
        return selectedTab === 'performance' || !selectedTab
    }, [selectedTab])

    const progressPercent = useMemo(() => {
        if (progress.total === 0) return 0
        return progress.completed / progress.total
    }, [progress])

    return (
        <div>
            {showProgress && (
                <StyledOverlay
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                        paddingTop: 0,
                    }}
                >
                    <H2 color="textColor3">Generating Report</H2>
                    <div
                        style={{
                            width: '400px',
                        }}
                    >
                        <ProgressBar
                            progressPercent={`${progressPercent * 100}%`}
                        />
                    </div>
                </StyledOverlay>
            )}
            <PerformanceViewSelector
                isViewSelectorOpen={isViewSelectorOpen}
                setIsViewSelectorOpen={setIsViewSelectorOpen}
            />
            <div>
                <H2>Final Review</H2>
                <Body>
                    Preview your custom report below. Download all tabs as
                    individual CSVs through the download button.
                </Body>
            </div>

            <div
                style={{
                    margin: '2rem 0',
                    display: 'flex',
                    flex: 'flex-row',
                    gap: '1rem',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: 'min-content',
                    }}
                >
                    <XS
                        style={{
                            position: 'absolute',
                            top: '-20px',
                            left: '12px',
                            opacity: dateEnabled ? 1 : 0.3,
                            transition: 'opacity 0.3s',
                            pointerEvents: dateEnabled ? 'auto' : 'none',
                        }}
                    >
                        Start Date
                    </XS>
                    <XS
                        style={{
                            position: 'absolute',
                            top: '-20px',
                            left: 'calc(50% + 12px)',
                            opacity: dateEnabled ? 1 : 0.3,
                            transition: 'opacity 0.3s',
                            pointerEvents: dateEnabled ? 'auto' : 'none',
                        }}
                    >
                        End Date
                    </XS>
                    <ReportsDatePicker
                        datePickerData={datePickerData}
                        wrapStyle={{
                            minWidth: '250px',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            opacity: dateEnabled ? 1 : 0.3,
                            transition: 'opacity 0.3s',
                            pointerEvents: dateEnabled ? 'auto' : 'none',
                        }}
                        buttonStyle={{
                            margin: 0,
                        }}
                    />
                    <XS
                        style={{
                            position: 'absolute',
                            top: 'calc(100% + 0.25rem)',
                            left: '0',
                            width: '100%',
                            textAlign: 'center',
                            opacity: selectedTab === 'performance' ? 1 : 0,
                            transition: 'opacity 0.3s',
                        }}
                    >
                        Date filtering only applies to the performance CSV.
                    </XS>
                    <XS
                        style={{
                            position: 'absolute',
                            top: 'calc(100% + 0.25rem)',
                            left: '0',
                            width: '100%',
                            textAlign: 'center',
                            opacity: selectedTab === 'distribution' ? 1 : 0,
                            transition: 'opacity 0.3s',
                        }}
                    >
                        Distribution data is based on the last 10 days of data.
                    </XS>
                </div>
                <OutlineBtn onClick={generateReport}>
                    Generate Report
                </OutlineBtn>
            </div>
            <div
                style={{
                    backgroundColor: theme.color.accentColor4,
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '16px',
                }}
            >
                <Slider
                    justifyTabs="flex-start"
                    width="100%"
                    useChevrons={false}
                >
                    {visibleReportCategories.map((category) => (
                        <NavItem
                            className="carousel-item"
                            key={category.id}
                            type={category.id}
                            onClick={() => setSelectedTab(category.id)}
                            selectedField={selectedTab ?? 'none'}
                            noUpperCase
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                }}
                            >
                                <Body>{category.label}</Body>
                            </div>
                        </NavItem>
                    ))}
                </Slider>
            </div>
            { selectedTab === 'performance' && (
                <div
                    style={{
                        cursor: 'pointer',
                        marginBottom: '1rem',
                    }}
                    onClick={() => {
                        setIsViewSelectorOpen(true)
                    }}
                >
                    <XS
                        style={{ color: hexToRgba(theme.color.textColor1, 0.6) }}
                    >
                        Change Selected View
                    </XS>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}>
                        <Small semibold>
                            {aggregationText}
                        </Small>
                        <FiEdit3 size={16} />
                    </div>
                </div>
            )}
        </div>
    )
}

const FinalReviewContext = React.createContext<{
    selectedTab?: InsightBuilderReportCategory['id']
    setSelectedTab: (tab: InsightBuilderReportCategory['id']) => void
    aggregationText: string
    setAggregationText: React.Dispatch<React.SetStateAction<string>>
} | null>(null)

export const useFinalReviewContext = () => {
    const context = React.useContext(FinalReviewContext)

    if (!context) {
        throw new Error(
            'useFinalReviewContext must be used within FinalReviewProvider'
        )
    }

    return context
}

export const FinalReview = () => {
    const { setViews, goPrev, visibleReportCategories, views } =
        useInsightsReportBuilderContext()

    const [selectedTab, setSelectedTab] =
        React.useState<InsightBuilderReportCategory['id'] | undefined>()

    const [aggregationText, setAggregationText] = useState('Total Performance By Campaign')

    const first = visibleReportCategories[0]?.id

    useEffect(() => {
        setSelectedTab(first)
    }, [first])

    return (
        <FinalReviewContext.Provider
            value={{
                selectedTab,
                setSelectedTab,
                aggregationText,
                setAggregationText,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '400px',
                }}
            >
                <FinalReviewHeader />
                {visibleReportCategories.map((category) => (
                    <div
                        key={category.id}
                        style={{
                            display:
                                category.id === selectedTab ? 'block' : 'none',
                        }}
                    >
                        <FinalReportTable view={ views?.[category.id] } />
                    </div>
                ))}
                <ButtonContainer
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <OutlineBtn
                        onClick={() => {
                            goPrev()
                            setViews(null)
                        }}
                    >
                        Back
                    </OutlineBtn>
                    <InsightReportDownloader />
                </ButtonContainer>
            </div>
        </FinalReviewContext.Provider>
    )
}
