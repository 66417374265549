import React, { useMemo } from 'react'
import { ApprovalsIcon, AssetsIcon, AudiencesIcon, BlueprintsIcon, BuildCreativesIcon, BulkSubscriptionManagementIcon, CalendarIcon, CallTrackingIcon, CampaignsIcon, CorporateSettingsIcon, CreativeFoldersIcon, IconProps, InsightsIcon, KeywordsIcon, LabelsIcon, ReputationManagementInsightsIcon, SocialPostingPageIcon, SummaryIcon, VariablesIcon } from '../components/navigation/icons'
import { INSIGHTS, PATH } from '../constants/urlPath'
import { useAccess } from './useAccess'
import { useSelector } from 'react-redux'
import useDynamicSetting from './useDynamicSetting'
import { StyledComponent } from 'styled-components'
import useExperiment from './useExperiment'
import { EXPERIMENTS } from '../helpers/experimentHelpers'
import useLocationFieldAccess from './useLocationFieldAccess'
import { LOCATION_ACCESS_LEVEL_FIELD } from '../constants/restrictions'

export type Folder = {
	name: FolderName
	tabs: Tab[]
	isFolder: boolean
	shouldHide?: boolean
}

export type Tab = {
	name: string
	path: string
	icon: React.ComponentType<IconProps> | StyledComponent<'svg', any, IconProps, never>;
	isFolder?: boolean
    shouldHide?: boolean
}

export type FolderName = "Content" | "Ads" | "Posts" | "Corporate Team" | `Corporate Team ${string}`

const useSidebarPages = () => {
    const { canManageFranchise, isMember } = useAccess()
    const { isHiddenField } = useLocationFieldAccess()
    const fork = useSelector((s) => s.build.fork)
    const creationType = useSelector((s) => s.build.creationType)
    const approvalsEnabled = useSelector((s) => s.franchise.approvals)
    const approvalsList = useSelector((s) => s.franchise.approvalsList)

    const { showTimelineView, zeeInsightReportBuilderAccess } = useDynamicSetting()
    const reputationListingsCopy = useExperiment(EXPERIMENTS.REPUTATION_LISTINGS_COPY)

	const isBuildVisited = useMemo(() => {
		if (fork.includes('video') && creationType) return PATH.CREATE_VIDEO
		return creationType ? PATH.CREATE_IMAGE : PATH.BUILD
	}, [fork, creationType])

    const CONTENT_FOLDER = [
		{
            name: isMember() ? 'Creatives' : 'Build Creatives',
            icon: BuildCreativesIcon,
            path: isBuildVisited
        },
		{
            name: 'Creative Folders',
            icon: CreativeFoldersIcon,
            path: PATH.CREATIVE_FOLDERS,
            shouldHide: !canManageFranchise()
        },
		{
            name: 'Assets',
            icon: AssetsIcon,
            path: PATH.ASSETS,
            shouldHide: !canManageFranchise()
        },
		{
            name: 'Variables',
            icon: VariablesIcon,
            path: PATH.VARIABLES,
            shouldHide: !canManageFranchise()
        }
	].filter(tab => !tab.shouldHide)

	const ADS_FOLDER = [
		{
            name: 'Advertising',
            icon: CampaignsIcon,
            path: PATH.ADVERTISING,
        },
		{
            name: 'Keywords',
            icon: KeywordsIcon,
            path: PATH.KEYWORDS,
            shouldHide: isHiddenField(LOCATION_ACCESS_LEVEL_FIELD.KEYWORDS)
        },
		{
            name: 'Audiences',
            icon: AudiencesIcon,
            path: PATH.AUDIENCES
        }
	].filter(tab => !tab.shouldHide)

	const POSTS_FOLDER = [
		{
            name: 'Social Posting',
            icon: SocialPostingPageIcon,
            path: PATH.SOCIAL_POSTING
        },
		{
            name: 'Calendar',
            icon: CalendarIcon,
            path: PATH.CALENDAR,
            shouldHide: !showTimelineView }
	].filter(tab => !tab.shouldHide)

	const CORPORATE_TEAM_FOLDER = [
		{
            name: 'Corporate Settings',
            icon: CorporateSettingsIcon,
            path: PATH.CORPORATE_SETTINGS, shouldHide: !canManageFranchise()
        },
		{
            name: `Approvals ${approvalsList ? `(${approvalsList.length})` : ''}`,
            icon: ApprovalsIcon,
            path: PATH.APPROVALS,
            shouldHide: !canManageFranchise() || !approvalsEnabled
        },
		{
            name: 'Blueprints',
            icon: BlueprintsIcon,
            path: PATH.BLUEPRINTS,
            shouldHide: !canManageFranchise()
        },
		{
            name: 'Labels', icon: LabelsIcon,
            path: PATH.LABELS,
            shouldHide: !canManageFranchise()
        }
	].filter(tab => !tab.shouldHide)

    const PRODUCT_PAGES: (Folder | Tab)[] = [
        {
            name: 'Dashboard',
            icon: SummaryIcon,
            path: PATH.SUMMARY,
            isFolder: false
        } as Tab,
        {
            name: 'Insights',
            icon: InsightsIcon,
            path: isMember() ? INSIGHTS.REPORT_BUILDER : PATH.INSIGHTS,
            isFolder: false,
            shouldHide: isMember() && !zeeInsightReportBuilderAccess
        } as Tab,
        {
            name: isMember() ? 'Creatives' : 'Build Creatives',
            icon: BuildCreativesIcon,
            path: isBuildVisited,
            isFolder: false,
            shouldHide: canManageFranchise()
        } as Tab,
        {
            name: 'Content',
            tabs: CONTENT_FOLDER,
            isFolder: true,
            shouldHide: !canManageFranchise()
        } as Folder,
        {
            name: 'Ads',
            tabs: ADS_FOLDER,
            isFolder: true
        } as Folder,
        {
            name: 'Posts',
            tabs: POSTS_FOLDER,
            isFolder: true
        } as Folder,
        {
            name: reputationListingsCopy.decide('Reputation & Listings', 'Reputation Management'),
            icon: ReputationManagementInsightsIcon,
            path: PATH.REPUTATION_MANAGEMENT,
            isFolder: false
        } as Tab,
        {
            name: 'Call Tracking',
            icon: CallTrackingIcon,
            path: PATH.CALL_TRACKING,
            isFolder: false
        } as Tab,
    ].filter(tab => !tab.shouldHide)

    const MANAGEMENT_PAGES: (Folder | Tab)[] = [
        {
            name: `Corporate Team ${approvalsList ? `(${approvalsList.length})` : ''}`,
            tabs: CORPORATE_TEAM_FOLDER,
            isFolder: true,
            shouldHide: !canManageFranchise()
        } as Folder,
        {
            name: 'Bulk Actions',
            icon: BulkSubscriptionManagementIcon,
            path: PATH.BULK_SETTINGS,
            isFolder: false
        } as Tab
    ].filter(tab => !tab.shouldHide)

    return ({
        PRODUCT_PAGES,
        MANAGEMENT_PAGES
    })
}

export default useSidebarPages