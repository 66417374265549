import React, { ReactNode } from 'react'
import FormProgress from './FormProgress'
import FormContent from './FormContent'
import { StyledMultiStepForm } from './styles';
import {ButtonContainer} from '../../styles/buttons';

export type FormStep = {
    id: number | string
    component: ReactNode | React.JSX.Element
    title?: string
    buttons?: ReactNode
}

export type MultiStepFormProps = {
    steps: FormStep[]
    currentStep: number
    disableProgressBar: boolean
    creationType?: string
}

const MultiStepForm = ({ steps, currentStep, disableProgressBar, creationType }: MultiStepFormProps) => {
    return (
        <StyledMultiStepForm>
            {
                !disableProgressBar &&
                <FormProgress steps={steps} currentStep={currentStep} creationType={creationType} />
            }

            <FormContent steps={steps} currentStep={currentStep} />

            {
                steps[currentStep]?.buttons &&
                <ButtonContainer>
                    {
                        steps[currentStep]?.buttons
                    }
                </ButtonContainer>
            }

        </StyledMultiStepForm>
    )
}

export default MultiStepForm;
