import React, { useState } from 'react'
import { useCreativeContext } from '../CreativeModalV2'
import { CreativeDataContainer } from '../styles'
import { gmtToLocaleString } from '../../../../helpers/index'
import useVersionData from '../../../../hooks/useVersionData'
import SearchTextModal from '../../../../pages/build/modals/SearchTextModal'
import { Small } from '../../../../styles/typography'
import { useThemeControl } from '../../../../hooks/useThemeControl'
import { getCloneStatusLabel } from '../utils'
import StringUtil from '../../../../helpers/string.util'
import { Show } from '../../../../components/reusableUI/components/Show'
import { useCreativeInfo } from '../../utils'

const AdDetailsTable = () => {
    const { creativeStatusColorMap } = useThemeControl()
    const [showSearchText, setShowSearchText] = useState(false)
    const { creative, metadata  } = useCreativeContext()
    const { getCreativeStatusCopy } = useCreativeInfo()

    // Fields that are derived off of this creative
    const { isFolder, location, creativeDateText, user } = metadata
    const { created, extendedText, header, description, cta, searchHeadlines, searchDescriptions, keywordGroup, landingPage, youtubeHeadline, youtubeDescription } = creative
    const { defaultCta } = useVersionData()
    const hasSearchText = [...(searchHeadlines || []), ...(searchDescriptions || [])].length > 0


    return (
        <CreativeDataContainer>
            <table>
                <tbody>
                    <tr>
                        <th><Small semibold>Created</Small></th>
                        <td><Small>{gmtToLocaleString(created)}</Small></td>
                    </tr>
                    {
                        header?.text?.length > 0 && header?.color?.a > 1 &&
                        <tr>
                            <th><Small semibold>Header</Small></th>
                            <td><Small>{header?.text}</Small></td>
                        </tr>
                    }
                    {
                        description?.text?.length > 0 && description?.color?.a > 1 &&
                        <tr>
                            <th><Small semibold>Description</Small></th>
                            <td><Small>{description?.text}</Small></td>
                        </tr>
                    }
                    <tr>
                        <th><Small semibold>Social Text</Small></th>
                        <td><Small>{extendedText || "--not provided--"}</Small></td>
                    </tr>


                    {
                        youtubeHeadline &&
                        <tr>
                            <th><Small semibold>Youtube Headline</Small></th>
                            <td><Small>{youtubeHeadline}</Small></td>
                        </tr>
                    }

                    {
                        youtubeDescription &&
                        <tr>
                            <th><Small semibold>Youtube Description</Small></th>
                            <td><Small>{youtubeDescription}</Small></td>
                        </tr>
                    }

                    <Show when={keywordGroup?.name}>
                        <tr>
                            <th><Small semibold>Ad Category</Small></th>
                            <td><Small>{keywordGroup?.name}</Small></td>
                        </tr>
                    </Show>
                    <tr>
                        <th><Small semibold>CTA</Small></th>
                        <td><Small>{StringUtil.displaySnakeStr(cta || defaultCta)}</Small></td>
                    </tr>
                    <tr>
                        <th><Small semibold>Start</Small></th>
                        <td><Small>{creativeDateText?.start}</Small></td>
                    </tr>
                    <tr>
                        <th><Small semibold>End</Small></th>
                        <td><Small>{creativeDateText?.end}</Small></td>
                    </tr>
                    <tr>
                        <th><Small semibold>User</Small></th>
                        <td><Small>{user?.email}</Small></td>
                    </tr>
                    {!isFolder &&
                        <>
                            <tr>
                                <th><Small semibold>Business Name</Small></th>
                                <td><Small>{location?.name}</Small></td>
                            </tr>
                            <tr>
                                <th><Small semibold>Campaign Name</Small></th>
                                <td><Small>{location?.nickname}</Small></td>
                            </tr>
                        </>
                    }
                    <tr>
                        <th><Small semibold>Landing Page</Small></th>
                        <td style={{ wordBreak: 'break-all' }}>
                            <Small>
                                <a href={landingPage || ( isFolder ? null : location?.website)} target={"_blank"} rel={"noreferrer"}>
                                    {landingPage || ( isFolder ? `Campaigns's Website` : location?.website)}
                                </a>
                            </Small>
                        </td>
                    </tr>
                    {
                        metadata.isDeployedAd &&
                        <tr>
                            <th><Small semibold>Status</Small></th>
                            <td><Small>{getCreativeStatusCopy(creative)}</Small></td>
                        </tr>
                    }
                    {
                        hasSearchText &&
                        <tr>
                            <th><Small semibold>Search Headlines & Descriptions</Small></th>
                            <td className='searchTextList' onClick={() => setShowSearchText(true)}>
                                <Small colorInherit>View List</Small>
                            </td>
                        </tr>
                    }

    
                    {
                        isFolder && getCloneStatusLabel(creative, creativeStatusColorMap)
                    }
                </tbody>
            </table>
            {showSearchText && <SearchTextModal creative={creative} onClose={() => setShowSearchText(false)} />}
        </CreativeDataContainer>
    )
}

export default AdDetailsTable