

export const FORKS = {
   FORKS: 'forks',
   DYNAMIC_TYPE:  'dynamic-type',
   DYNAMIC: 'dynamic',
   DYNAMIC_VIDEO: 'video',

   BUILD_SELECTOR: 'build-selector',
   LOCATIONS: 'locations',

   SOCIAL_TYPE:  'social-image-post-type',
   SOCIAL_IMAGE: 'social-image-post',
   SOCIAL_VIDEO: 'social-video-post',

   VIDEO_UPLOAD: 'upload',
   YOUTUBE_LINK: 'youtube',


   PREMADE_CREATIVE: 'premade',
   CUSTOM_CREATIVE: 'custom',

   CUSTOM_POST_CREATIVE: 'customPost',
   PREMADE_POST_CREATIVE: 'premadePost',


   AUTOMATIC_CREATIVE: 'creative',
   OPTIONAL_CREATIVE: 'magic',

   AD: 'ad',
   POST: 'post',
   EMAIL: 'email',
   SMS: 'sms',
}