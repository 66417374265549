import { NoInfer } from "../../types/custom"

export type Identifiable = {
    getKey: () => string
}

export const isIdentifiable = <T extends object>(
    data: T
): data is T & Identifiable => {
    return 'getKey' in data && typeof data.getKey === 'function'
}

export const makeIdentifiable = <T extends object>(
    data: T,
    keyExtractor: (data: NoInfer<T>) => string
): T & Identifiable => {
    return {
        ...data,
        getKey: () => keyExtractor(data),
    }
}

// This is a queue data structure that allows for enqueuing and dequeuing items.
// The queue is based on a Set to allow for quick lookups of items.
// The queue also maintains an order of items that are enqueued.
// The queue is a FIFO queue.

// Example usage:
// const q = queue<Identifiable>()
// q.enqueue({ getKey: () => '1' })
// q.enqueue({ getKey: () => '2' })
// q.enqueue({ getKey: () => '3' })
// q.enqueue({ getKey: () => '4' })
// console.log(q.dequeue()) // { getKey: () => '1' }
// console.log(q.dequeue()) // { getKey: () => '2' }

function queue<T extends Identifiable>() {
    const set = new Set<string>()
    let order: T[] = []

    return {
        enqueue(item: T): void {
            if (!set.has(item.getKey())) {
                set.add(item.getKey())
                order.push(item)
            }
        },
        enqueueAt: (item: T, index: number): void => {
            if (set.has(item.getKey())) {
                const oldIndex = order.findIndex((value) => value.getKey() === item.getKey())
                order.splice(oldIndex, 1)
            }
            set.add(item.getKey())
            order.splice(index, 0, item)
        },
        dequeue(): T | undefined {
            const item = order.shift()
            if (item !== undefined) {
                set.delete(item.getKey())
            }
            return item
        },

        peek(): T | undefined {
            return order[0]
        },

        isEmpty(): boolean {
            return order.length === 0
        },

        size(): number {
            return order.length
        },

        clear(): void {
            set.clear()
            order = []
        },

        has(obj: T): boolean {
            return set.has(obj.getKey())
        },

        remove(obj: T): void {
            set.delete(obj.getKey())
            order = order.filter((item) => item !== obj)
        },

        values() {
            return order
        },
    }
}

export default queue
