type UrlWithProtocol = `http://${string}` | `https://${string}`

export const UrlUtil = {
    /**
     * Tests if a URL has a protocol
     * @param url string
     * @returns boolean
     */
    hasProtocol: (url: string): url is UrlWithProtocol =>
        url?.includes('http://') || url?.includes('https://'),
    /**
     * Optionally attaches a protocol to a URL if it doesn't already have one
     * @param url string
     * @returns UrlWithProtocol
     */
    attachProtocol: (url: string): UrlWithProtocol =>
        UrlUtil.hasProtocol(url) ? url : `https://${url}`,
}
