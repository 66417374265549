import { Small } from "../../../../styles/typography"
import React from "react"
import { POST_NETWORK_NAME } from "../../../../helpers/creative.util"
import NetworkIcon from "../NetworkIcon"
import { Post, PostWithMetadata } from "../../creative.types"
import { useCreativeContext } from "../CreativeModalV2"
import { formatDate, getCreativeLocationType, useCreativeInfo } from "../../utils"
import { getStatusColor } from "../../../../hooks/useThemeControl"
import { sortByPostDate } from "../../../../pages/locations/helpers/sorters/postSorters/sortByPostDate"
import { sortByCampaignName } from "../../../../pages/locations/helpers/sorters/postSorters/sortByName"
import { sortByUserEmail } from "../../../../pages/locations/helpers/sorters/postSorters/sortByUserEmail"
import { tooltipTextRender } from "../../../../components/reusableUI/components/tooltip/util"
import StringUtil from "../../../../helpers/string.util"
import { hexToRgba } from "../../../../helpers/colors.util"
import { Theme } from "../../../../types/theme"
import { useTheme } from "styled-components"
import { useManageClonesContext } from "../ManageClones/useManageClones"
import getSuperTableComponents from "../../../../components/SuperTable/getSuperTableComponents"
import { displayCampaignName } from "../ManageClones/util"
import { TooltipTrigger } from "../../../tooltip/TooltipTrigger"

const { Wrap, Column } = getSuperTableComponents<PostWithMetadata>()

export const PostInformation = <T extends Post,>({ creative }: { creative: T }) => {
    const { extendedText, targetNetworks } = creative

    const statusItems = targetNetworks?.map((network) => {
        return {
            name: POST_NETWORK_NAME[network],
            icon: <NetworkIcon network={network} />,
        }
    })

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '11.75rem 1fr', height: 'min-content', rowGap: '0.5rem', columnGap: '1rem' }}>
            <Small semibold>Social Text</Small>
            <Small>{extendedText || "No social text."}</Small>
            <Small semibold>Networks</Small>
            <div>
                {statusItems.map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem', marginBottom: '0.5rem' }}>
                        {item.icon}
                        <Small>{item.name}</Small>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const PostCloneTable =  ({ onCSVDataUpdated }: { onCSVDataUpdated: (data: string[][]) => void }) => {
    const { clones } = useManageClonesContext()
    const theme: Theme = useTheme()
    const { creative } = useCreativeContext()
    const { targetNetworks } = creative as Post
    const { getCreativeStatus, getCreativeStatusCopy } = useCreativeInfo()

    const getImpressions = (item: PostWithMetadata) => {
        if (item.totalImpressions !== undefined) {
            return item.totalImpressions.toString();
        }
        return 'No data';
    }

    const getEngagement = (item: PostWithMetadata) => {
        if (item.totalEngagedUsers !== undefined) {
            return item.totalEngagedUsers.toString();
        }
        return 'No data';
    }

    const getReach = (item: PostWithMetadata) => {
        if (item.totalImpressionsUnique !== undefined) {
            return item.totalImpressionsUnique.toString();
        }
        return 'No data';
    }
    const getVideoViews = (item: PostWithMetadata) => {
        if (item.totalVideoViews !== undefined) {
            return item.totalVideoViews.toString();
        }
        return 'No data';
    }
    const baseWidth = 1000
    const widthFromNetworkColumns = targetNetworks.reduce((prev,) => prev+100, 0)
    const tableWidthPx = `${baseWidth + widthFromNetworkColumns}px`

    return (
        <>
            <div style={{ height: '400px', overflow: 'scroll', boxShadow: `inset 0 10px 6px -6px ${hexToRgba(theme.color.accentColor3, 0.2)}` }}>
                <div style={{ width: tableWidthPx, height: '100%', position: 'relative'}}>
                    <Wrap items={clones as PostWithMetadata[]} onCSVDataUpdated={onCSVDataUpdated}>
                        <Column
                            title="Type"
                            size="1"
                            filter={getCreativeLocationType}
                            csvData={getCreativeLocationType}
                            >
                            {(item: PostWithMetadata) => {
                                return <Small ellipsis>{getCreativeLocationType(item)}</Small>
                            }}
                        </Column>
                        <Column
                            title='Name'
                            size={2}
                            sort={sortByCampaignName}
                            csvData={item => displayCampaignName(item)}
                            >
                            {(item: PostWithMetadata) => {
                                return <Small>{displayCampaignName(item)}</Small>
                            }}
                        </Column>
                        <Column
                            title='User'
                            sort={sortByUserEmail}
                            csvData={item => item.metadata.user?.email}
                            >
                            {(item: PostWithMetadata) => (
                                    <TooltipTrigger
                                        tooltipContent={item.metadata.user?.email}
                                    >
                                        <Small ref={tooltipTextRender} ellipsis>
                                            {item.metadata.user?.email}
                                        </Small>
                                    </TooltipTrigger>
                                )}
                        </Column>
                        <Column
                            title='Posted Date' //TODO fix gmt handling
                            sort={sortByPostDate}
                            csvData={item => formatDate(item.postedDate) || 'Not posted' }
                            >
                            {(item: PostWithMetadata) =>
                                <Small>{formatDate(item.postedDate) || 'Not posted' }</Small>}
                        </Column>
                        {
                            targetNetworks?.map((network) => {
                                return (
                                    <Column
                                        key={network}
                                        title={StringUtil.capitalizeFirstLetter(POST_NETWORK_NAME[network])}
                                        filter={item => getCreativeStatusCopy(item, network)}
                                        csvData={item => getCreativeStatusCopy(item, network)}
                                        >
                                            {(item: PostWithMetadata) => {
                                                const postStatus = getCreativeStatus(item, network)
                                                const statusCopy = getCreativeStatusCopy(item, network)
                                                const statusColor = getStatusColor(postStatus)

                                                return <Small color={statusColor}>{statusCopy}</Small>
                                            }}
                                    </Column>
                                )
                            })
                        }
                        <Column
                            title='Total Impressions'
                            csvData={item => getImpressions(item)}
                            >
                            {(item: PostWithMetadata) => {
                                return <Small>{getImpressions(item)}</Small>
                            }}
                        </Column>
                        <Column
                            title='Total Engagement'
                            csvData={item => getEngagement(item)}
                            >
                            {(item: PostWithMetadata) => {
                                return <Small>{getEngagement(item)}</Small>
                            }}
                        </Column>
                        <Column
                            title='Total Reach'
                            csvData={item => getReach(item)}
                            >
                            {(item: PostWithMetadata) => {
                                return <Small>{getReach(item)}</Small>
                            }}
                        </Column>
                        {clones.some(item => item.kind === 'VideoPost') && (
                            <Column
                                title='Total Video Views'
                                csvData={item => getVideoViews(item)}
                            >
                                {(item: PostWithMetadata) => {
                                    return <Small>{getVideoViews(item)}</Small>
                                }}
                            </Column>
                        )}
                    </Wrap>
                </div>
            </div>
        </>
    )
}
