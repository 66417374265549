import { API } from "../constants/api"
import { makeRequest } from "../helpers/make-request"


const createRequest = ({franchiseName, creative, endpoint}) => (
    makeRequest()
        .url(endpoint)
        .param('franchise', franchiseName)
        .body(creative)
        .post()
)


export const createAd = ({franchiseName, creative}) => {
    return createRequest({franchiseName, creative, endpoint: API.FRANCHISE.CREATE_AD})
}

export const createPost = ({franchiseName, creative}) => {
    return createRequest({franchiseName, creative, endpoint: API.FRANCHISE.CREATE_POST})
}

/**
 * This function sends a POST request to create a creative of a specified kind.
 * It uses the provided `creative` object, extracting the primary location ID (`lid`) and any additional
 * location IDs to be cloned (`clonedLids`). The request is sent to the appropriate endpoint based on `kind`.
 */

export const createCreative = async ({ kind, creative }) => {
    const [lid, ...clonedLids] = creative.userLocations.map(loc => loc.split('_')[1]);

    const ENDPOINTS = {
        'Creative':      API.CREATIVE.CREATE,
        'VideoCreative': API.CREATIVE.CREATE_VIDEO,
        'Post':          API.CREATIVE.CREATE_POST,
        'VideoPost':     API.CREATIVE.CREATE_VIDEO_POST
    };

    let request;
    const baseRequest = makeRequest().param('lid', lid)

    if(clonedLids.length) {
        request = baseRequest.param("cloneLids", clonedLids.toString())
    }

    return (request || baseRequest)
            .url(ENDPOINTS[kind])
            .body(creative)
            .retry(3)
            .post()
};

