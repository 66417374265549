
const APP_PATH = '/app'

export const PATH = {
    APP:         APP_PATH,
    SUMMARY:    `${APP_PATH}/summary`,
    CAMPAIGNS:  `${APP_PATH}/campaigns`,
    INSIGHTS:   `${APP_PATH}/insights`,
    BLUEPRINTS: `${APP_PATH}/blueprints`,
    APPROVALS:  `${APP_PATH}/approvals`,
    ASSETS:     `${APP_PATH}/assets`,
    CALENDAR:   `${APP_PATH}/calendar`,
    KEYWORDS:   `${APP_PATH}/keywords`,
    LABELS:     `${APP_PATH}/labels`,
    AUDIENCES:  `${APP_PATH}/audiences`,
    BUILD:      `${APP_PATH}/build`,
    USER_SETTINGS: `${APP_PATH}/user-settings`,
    REQUEST_DELETE: `${APP_PATH}/user/reqDel`,
    CORPORATE_SETTINGS:    `${APP_PATH}/corporate-settings`,
    CREATIVE_FOLDERS:      `${APP_PATH}/creativefolders`,
    EMAIL_MARKETING:       `${APP_PATH}/emailmarketing`,
    SMS_MARKETING:         `${APP_PATH}/smsmarketing`,
    VARIABLES:             `${APP_PATH}/variables`,
    REPUTATION_MANAGEMENT:       `${APP_PATH}/reputation-management`,
    CALL_TRACKING:       `${APP_PATH}/call-tracking`,
    BULK_SETTINGS: `${APP_PATH}/bulk-settings`,
    ADVERTISING: `${APP_PATH}/advertising`,
    //* NESTED PATHS
    CREATE_VIDEO:       `${APP_PATH}/build/create-video`,
    CREATE_IMAGE:       `${APP_PATH}/build/create-image`,
    BLUEPRINTS_DEFAULT: `${APP_PATH}/blueprints/default`,
    RECONNECT: `${APP_PATH}/campaigns/reconnect`,
    SOCIAL_POSTING: `${APP_PATH}/social-posting`,
    SOCIAL_POSTING_RECONNECT: `${APP_PATH}/social-posting/reconnect`,
    ADVERTISING_RECONNECT: `${APP_PATH}/advertising/reconnect`
}

export const NAV = {
  RETURN_TO_CALLER: 'rtc',
}

// Constants for PATH.CORPORATE_SETTINGS sub routes
// email-templates, franchisee-restrictions, tags, documents, payments, token-management, about, search-text, configuration, call-setup
export const CORPORATE_SETTINGS = {
  EMAIL_TEMPLATES: `${PATH.CORPORATE_SETTINGS}/email-templates`,
  FRANCHISEE_RESTRICTIONS: `${PATH.CORPORATE_SETTINGS}/franchisee-restrictions`,
  TAGS: `${PATH.CORPORATE_SETTINGS}/tags`,
  DOCUMENTS: `${PATH.CORPORATE_SETTINGS}/documents`,
  PAYMENTS: `${PATH.CORPORATE_SETTINGS}/payments`,
  TOKEN_MANAGEMENT: `${PATH.CORPORATE_SETTINGS}/token-management`,
  ABOUT: `${PATH.CORPORATE_SETTINGS}/about`,
  SEARCH_TEXT: `${PATH.CORPORATE_SETTINGS}/search-text`,
  CONFIGURATION: `${PATH.CORPORATE_SETTINGS}/configuration`,
  CALL_SETUP: `${PATH.CORPORATE_SETTINGS}/call-setup`,
  FUNDING:{
    MAIN: `${PATH.CORPORATE_SETTINGS}/funding`,
    // * NESTED PATHS
    GENERATE: `${PATH.CORPORATE_SETTINGS}/funding/generate`,
    DISTRIBUTE: `${PATH.CORPORATE_SETTINGS}/funding/distribute`,
    SET_RECIPIENTS: `${PATH.CORPORATE_SETTINGS}/funding/distribute/recipients`,
    SET_AMOUNT: `${PATH.CORPORATE_SETTINGS}/funding/distribute/amount`,
  },


}

export const CREATIVE_FOLDER_ROUTE = {
  SELECTED_REPO: `${PATH.CREATIVE_FOLDERS}/:repoId`
}

export const PAYMENTS = {
  PAYMENT: `${CORPORATE_SETTINGS.PAYMENTS}/subscription`,
  INVOICES: `${CORPORATE_SETTINGS.PAYMENTS}/history`,
  // Nested
  PAYMENT_ACTIVATE: `${CORPORATE_SETTINGS.PAYMENTS}/subscription/activate`,
  PAYMENT_UPDATE: `${CORPORATE_SETTINGS.PAYMENTS}/subscription/update`,
  PREMIUM_FEATURE: `${CORPORATE_SETTINGS.PAYMENTS}/subscription/feature`,
}

export const FRANCHISEE_RESTRICTIONS = {
  CREATIVE: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/creative`,
  POST: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/post`,
  LOCATION: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/location`,
  SUBSCRIPTION: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/subscription`,
  REPUTATION: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/reputation`,
  OTHER: `${CORPORATE_SETTINGS.FRANCHISEE_RESTRICTIONS}/other`,
}

export const ASSET_PAGE = {
  IMAGES: `${PATH.ASSETS}/images`,
  LOGOS: `${PATH.ASSETS}/logos`,
  COLORS: `${PATH.ASSETS}/colors`,
  FONTS: `${PATH.ASSETS}/fonts`,
}

export const CAMPAIGN_CONNECTION_SETTINGS = {
  META: `${PATH.CAMPAIGNS}/settings/connections/meta`,
  GA4: `${PATH.CAMPAIGNS}/settings/connections/ga4`,
  TWITTER: `${PATH.CAMPAIGNS}/settings/connections/x`,
  LINKEDIN: `${PATH.CAMPAIGNS}/settings/connections/linkedin`,
  GMB: `${PATH.CAMPAIGNS}/settings/connections/google-business-profile`,
}

// * Social Posting Connections for POSTING page
export const SOCIAL_POSTING_CONNECTION_SETTINGS = {
  META: `${PATH.SOCIAL_POSTING}/settings/connections/meta`,
  TWITTER: `${PATH.SOCIAL_POSTING}/settings/connections/x`,
  LINKEDIN: `${PATH.SOCIAL_POSTING}/settings/connections/linkedin`,
  GMB: `${PATH.SOCIAL_POSTING}/settings/connections/google-business-profile`,
}

// * Social Posting Connections for ADVERTISING page
export const ADVERTISING_CONNECTION_SETTINGS = {
  META: `${PATH.ADVERTISING}/settings/connections/meta`,
  GA4: `${PATH.ADVERTISING}/settings/connections/ga4`,
  LINKEDIN: `${PATH.ADVERTISING}/settings/connections/linkedin`,
}

export const LOCATION_CREATION = {
  MAIN: `${PATH.CAMPAIGNS}/create`
}

export const LOCATION_SETUP = {
  MAIN: `${PATH.CAMPAIGNS}/setup`,
  SUBSCRIPTION: `${PATH.CAMPAIGNS}/setup/subscription`,
  SOCIAL_POSTING_PROMPT: `${PATH.CAMPAIGNS}/setup/social-posting`,
  SOCIAL_POSTING_CONNECTIONS: `${PATH.CAMPAIGNS}/setup/social-posting/connections`,
  SOCIAL_POSTING_CONNECTIONS_META: `${PATH.CAMPAIGNS}/setup/social-posting/connections/meta`,
  SOCIAL_POSTING_CONNECTIONS_LINKEDIN: `${PATH.CAMPAIGNS}/setup/social-posting/connections/linkedin`,
  SOCIAL_POSTING_CONNECTIONS_TWITTER: `${PATH.CAMPAIGNS}/setup/social-posting/connections/x`,
  SOCIAL_POSTING_SUCCESS: `${PATH.CAMPAIGNS}/setup/social-posting/success`,
  ADVERTISING_SUCCESS: `${PATH.CAMPAIGNS}/setup/advertising/success`,
  ADVERTISING_PENDING: `${PATH.CAMPAIGNS}/setup/advertising/pending`,
}

export const USER_SETTINGS = {
  THEME: `${PATH.USER_SETTINGS}/theme`,
  PLATFORM_CONNECTIONS: `${PATH.USER_SETTINGS}/platform-connections`,
  EMAIL_NOTIFICATIONS: `${PATH.USER_SETTINGS}/email-notifications`
}

export const INSIGHTS = {
  LAST_LOGIN: `${PATH.INSIGHTS}/last-login`,
  TOTAL_ACTIVE_ADS: `${PATH.INSIGHTS}/total-active-ads`,
  CTR: `${PATH.INSIGHTS}/click-through-rate`,
  SPEND: `${PATH.INSIGHTS}/monthly-subscription`,
  LOCATION_PERFORMANCE: `${PATH.INSIGHTS}/cpc-cpm`,
  PENDING_OPTIONAL: `${PATH.INSIGHTS}/pending-optional-creatives`,
  TOP_CREATIVES: `${PATH.INSIGHTS}/top-active-ads`,
  DAILY_BUDGET: `${PATH.INSIGHTS}/daily-spend`,
  GEOLOCATION_OVERLAP: `${PATH.INSIGHTS}/geolocation-overlap`,
  GEORADIUS_OVERLAP: `${PATH.INSIGHTS}/georadius-overlap`,
  CPC: `${PATH.INSIGHTS}/cost-per-click`,
  AGGREGATE: `${PATH.INSIGHTS}/insight-summary-report`,
  KEYWORD_PERFORMANCE: `${PATH.INSIGHTS}/keyword-performance`,
  SOCIAL_POSTING: `${PATH.INSIGHTS}/social-posting`,
  SOCIAL_POSTING_PERFORMANCE: `${PATH.INSIGHTS}/social-posting-performance`,
  NLS_INSIGHT_REPORT: `${PATH.INSIGHTS}/nls-insight-report`,
  REPORT_BUILDER: `${PATH.INSIGHTS}/report-builder`,
  SEARCH_TERM_REPORT: `${PATH.INSIGHTS}/search-term-report`,
  GA_CONVERSIONS_REPORT: `${PATH.INSIGHTS}/conversion-reports`
}