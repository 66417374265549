/**
 * A utility object to perform various operations related to currency.
 * @namespace CurrencyUtil
 */

// * TYPES
// * Our currency from redux is a bit different than the standard Currency type (this is stripes)
export type StripeCurrency = {
    currency: string,
    conversion: number,
    created: string,
    updated: string,
    symbol: string
}

// * List of Zero Decimal Currency
export const zeroDecimalCurrencies = {
    bif: 'bif',
    clp: 'clp',
    mga: 'mga',
    djf: 'djf',
    gnf: 'gnf',
    jpy: 'jpy',
    kmf: 'kmf',
    krw: 'krw',
    pyg: 'pyg',
    rwf: 'rwf',
    ugx: 'ugx',
    vnd: 'vnd',
    vuv: "vuv",
    xaf: "xaf",
    xof: "xof",
    xpf: "xpf"
} as const

// * All currency ISO's - can help ensure currency functions that take in a string format are strict
export type CurrencyCode =
    'usd'
    | 'eur'
    | 'gbp'
    | 'aed'
    | 'afn'
    | 'all'
    | 'amd'
    | 'ang'
    | 'aoa'
    | 'ars'
    | 'aud'
    | 'awg'
    | 'azn'
    | 'bam'
    | 'bbd'
    | 'bdt'
    | 'bgn'
    | 'bif'
    | 'bmd'
    | 'bnd'
    | 'bob'
    | 'brl'
    | 'bsd'
    | 'bwp'
    | 'bzd'
    | 'cad'
    | 'cdf'
    | 'chf'
    | 'clp'
    | 'cny'
    | 'cop'
    | 'crc'
    | 'cve'
    | 'czk'
    | 'djf'
    | 'dkk'
    | 'dop'
    | 'dzd'
    | 'egp'
    | 'etb'
    | 'fjd'
    | 'fkp'
    | 'gel'
    | 'gip'
    | 'gmd'
    | 'gnf'
    | 'gtq'
    | 'gyd'
    | 'hkd'
    | 'hnl'
    | 'hrk'
    | 'htg'
    | 'huf'
    | 'idr'
    | 'ils'
    | 'inr'
    | 'isk'
    | 'jmd'
    | 'jpy'
    | 'kes'
    | 'kgs'
    | 'khr'
    | 'kmf'
    | 'krw'
    | 'kyd'
    | 'kzt'
    | 'lak'
    | 'lbp'
    | 'lkr'
    | 'lrd'
    | 'lsl'
    | 'mad'
    | 'mdl'
    | 'mga'
    | 'mkd'
    | 'mmk'
    | 'mnt'
    | 'mop'
    | 'mro'
    | 'mur'
    | 'mvr'
    | 'mwk'
    | 'mxn'
    | 'myr'
    | 'mzn'
    | 'nad'
    | 'ngn'
    | 'nio'
    | 'nok'
    | 'npr'
    | 'nzd'
    | 'pab'
    | 'pen'
    | 'pgk'
    | 'php'
    | 'pkr'
    | 'pln'
    | 'pyg'
    | 'qar'
    | 'ron'
    | 'rsd'
    | 'rub'
    | 'rwf'
    | 'sar'
    | 'sbd'
    | 'scr'
    | 'sek'
    | 'sgd'
    | 'shp'
    | 'sll'
    | 'sos'
    | 'srd'
    | 'std'
    | 'svc'
    | 'szl'
    | 'thb'
    | 'tjs'
    | 'top'
    | 'try'
    | 'ttd'
    | 'twd'
    | 'tzs'
    | 'uah'
    | 'ugx'
    | 'uyu'
    | 'uzs'
    | 'vnd'
    | 'vuv'
    | 'wst'
    | 'xaf'
    | 'xcd'
    | 'xof'
    | 'xpf'
    | 'yer'
    | 'zar'
    | 'zmw';

const CurrencyUtil = {
    /**
        Converts given currency to a desired currency
        @function convertCurrency
        @memberof CurrencyUtil
        @param { string } currency - name of currency (abbrevation)
        @param { number } amountInUsd - dollar amount of given currency
        @param { StripeCurrency[] } currencies - array of currencies (redux) strucutured in the fromat returned via Stripe
    */

    convertCurrency: (currency: CurrencyCode, amountInUsd: number = 0, currencies: StripeCurrency[]) => {
        // * Find matching currency data
        let currencyObj = currencies.find((c: StripeCurrency) => c.currency === currency);

        // * Return if nothing is found
        if (!currencyObj) return null

        let amount = currencyObj.conversion * amountInUsd

        return zeroDecimalCurrencies.hasOwnProperty(currency) ? Math.ceil(amount) : amount
    }
}

export default CurrencyUtil