import {UPLOAD_AUDIENCE_CSV, RESET_AUDIENCE_BLOB} from '../actions/types'

const initialState = {
    audienceBlob: null,
    audienceFile: null
}

const audienceReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_AUDIENCE_CSV: {
            console.log('Uploaded csv file: ' + action.payload.fileName + ' with blob: ' + action.payload.blobKey)
            return { ...state, audienceBlob: action.payload.blobKey, audienceFile: action.payload.fileName }
        }
        case RESET_AUDIENCE_BLOB: {
            return {...state, audienceBlob: null, audienceFile: null}
        }
        default: {
            return {...state}
        }
    }
}


export default audienceReducer