import React from 'react'
import { LoadingBox } from './styles'
import AnimatedCollapse from '../AnimatedCollapse/AnimatedCollapse'
import { LoadingIndicator } from '../../../loading/styles'

type Props =  {
    loading: boolean,
    children: React.ReactNode,
    duration?: string,
    easing?: string,
    loadingHeight?: string,
    childrenStyle?: React.CSSProperties
} & React.HTMLAttributes<HTMLDivElement>

// Use this is make any component that needs to load look good
// You just have to estimate the height of the content to be loaded so that the transition looks good
// Example usage:
//
// const request = makeRequest().url(API)
// const { data, loading, error } = useRequest(request.get)
//
// <AnimatedLoadingBox loading={loading} loadingHeight="90px">
//     <div>{data}</div>
// </AnimatedLoadingBox>

const AnimatedLoadingBox = ({ loading, children, loadingHeight = "10rem", duration = "0.5s", easing = "ease-in-out", childrenStyle, ...rest }: Props) => {
    return (
        <LoadingBox loading={loading} duration={duration} easing={easing} {...rest}>
            <AnimatedCollapse isOpen={loading}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: loadingHeight }}>
                    <LoadingIndicator />
                </div>
            </AnimatedCollapse>
            <AnimatedCollapse isOpen={!loading} openStyle={childrenStyle}>
                {children}
            </AnimatedCollapse>
        </LoadingBox>
    )
}

export default AnimatedLoadingBox
