import styled from 'styled-components';
import { createBoxShadow } from '../../styles/card';

type LinkGroupLayoutProps = {
    noIcons?: boolean;
};

export const Wrap = styled.footer`
    background-color: ${props => props.theme.color.backgroundColor2};
    box-shadow: ${createBoxShadow};
    border-radius: 9px;
    padding: 40px 50px;
    margin-top: 2rem;
`;

export const Top = styled.div`
    padding: 0px 2rem;
    padding-bottom: 3rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px ${props => props.theme.color.accentColor3};
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    #questionMark path {
        fill: ${props => props.theme.color.accentColor2};
    }
`;

export const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .logo {
        width: 100%;
        height: auto;
        max-width: 200px;
        min-width: 200px;
        filter: opacity(0.5);
    }

    .link-section {
        flex: 1;
        max-width: 550px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 50px;
    }

    .location-section {
        text-align: right;
        max-width: 220px;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        gap: 5px;
    }
`;

export const LinkGroupLayout = styled.div<{ noIcons?: boolean;}>`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link {
        cursor: pointer;
        display: grid;
        grid-template-columns: ${props => props.noIcons ? '1fr' : "14px 1fr"};
        grid-auto-flow: row;
        column-gap: 4px;
        max-width: 130px;
    }

    .link-icon {
        display: flex;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;

            path {
                fill: ${props => props.theme.color.textColor2};
            }
        }
    }

    .link-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
`;