import styled from 'styled-components';

type AnimatedCollapseWrapProps = {
    isOpen: boolean
    duration: string
    easing: string
}
export const AnimatedCollapseWrap = styled.div<AnimatedCollapseWrapProps>`
    display: grid;
    grid-template-rows: ${props => props.isOpen ? '1fr' : '0fr'};
    transition: grid-template-rows ${(props) => props.duration} ${(props) => props.easing};
`;

export const AnimatedCollapseContent = styled.div`
    overflow: hidden;
`;
