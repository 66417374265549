import { LOAD_FRANCHISE_LIST, CLEAR_FRANCHISE_LIST, SELECT_FRANCHISE } from '../actions/types';

const initialState = {
    franchiseList: [],
    selectedFranchise: null,
}

const franchiseListReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_FRANCHISE_LIST: {
            return {
                ...state,
                franchiseList: action.payload,
            }
        }

        case SELECT_FRANCHISE: {
            return {
                ...state,
                selectedFranchise: action.payload
            }
        }
        case CLEAR_FRANCHISE_LIST:
            return initialState
        default: return state;
    }
}

export default franchiseListReducer;