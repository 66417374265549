import { toast } from "react-toastify"
import { API } from "../../constants/api"
import { makeRequest } from "../../helpers/make-request"
import { useEffect, useState } from "react"
import { prependProtocol } from "../locations/util"

const useLandingPage = (url, showToast=true) => {
    const [ showInvalidWarning, setShowInvalidWarning ] = useState(false)
    const [ proceedWithUrl, setProceedWithUrl ] = useState(false) // Remember that the User gave permission to proceed with invalid url anyway

    // If url ever changes, we should reset
    useEffect(() => {
        setProceedWithUrl(false)
    }, [url])

    const getFinalUrl = (response, url) => {
        return new Promise(async (resolve, reject) => {
            const { finalUrl } = response
            if (!finalUrl) {
                reject()
            } else {
                let delay = 0
                if (finalUrl !== url) {
                    delay = 2000
                    console.log("URL has been updated to its redirect")
                    showToast && toast.info('URL has been updated to its redirect')
                }
                setTimeout(() => {
                    resolve(finalUrl)
                }, delay)
            }
        })
    }

    const validateURL = (url) => {

        return new Promise(async (resolve, reject) => {
            makeRequest()
            .url(API.LOCATION.VALIDATE_URL)
            .param("url", prependProtocol(url))
            .retry(3)
            .get()
            .then(response => getFinalUrl(response, url))
            .then(finalUrl => {
                setProceedWithUrl(false) // Reset because we were able to validate a url
                resolve(finalUrl)
            })
            .catch(err => reject(err))
        })
    }

  return {
    validateURL,
    showInvalidWarning, setShowInvalidWarning,
    proceedWithUrl, setProceedWithUrl
  }
}

export default useLandingPage