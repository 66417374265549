import React from 'react'

const TwitterXIcon = ({ size = 20 } : {
  size?: number
}) => {
  // design: use this icon for both light and dark mode
  return (
    <svg width={size} height={size} viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="38" rx="8" fill="#1E1E1E"/>
      <path d="M26.9014 8H30.5816L22.5415 17.319L32
               30H24.5941L18.7935 22.309L12.1563
               30H8.47393L17.0736 20.0323L8 8H15.5939L20.8372
               15.0298L26.9014 8ZM25.6098 27.7662H27.649L14.4859
               10.1165H12.2976L25.6098 27.7662Z"
            fill="white"
      />
    </svg>

  )
}

export default TwitterXIcon;
