import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { hasActiveSubscription } from '../../helpers'
import {
    isOptionalPost,
    isMagicEntity,
    isAdEntity,
    isPostEntity,
    isVideoPost,
    isDeployedAd,
    isDeployedPost,
    creativeDatesText,
    getCreativeKindLabel,
    CREATIVE_STATUS_COPY_MAP,
    isExpiredPost,
    CREATIVE_STATUS,
    POST_STATUS,
    POST_NETWORK,
    isVideoCreative,
} from '../../helpers/creative.util'
import useDynamicSetting from '../../hooks/useDynamicSetting'
import {
    Creative,
    CreativeMetadataFull,
    AdWithMetadata,
    PostWithMetadata,
    CreativeMetadata,
    FolderStatus,
    Network,
    CreativeWithMetadata,
    FOLDER_STATUS,
    NetworkStatus,
} from './creative.types'
import { useGetCreativeMetadata } from './hooks/useCreativeMetadata'
import { gmtDate, gmtDateString } from '../../helpers/date.util'
import { magicAwaitingMessage } from './CreativeModalV2/utils'
import { FiAlertTriangle, FiClock, FiPause, FiPlayCircle, FiRefreshCw } from 'react-icons/fi'
import { AwaitingReviewIcon, ExpiredIcon, PartiallyPostedIcon } from '../../assets/icons/creativeIcons'

// This is stateless metadata we can get directly off of the creative
export const getCreativeMetadata = (creative?: Creative): CreativeMetadata => {
    if (!creative) {
        return {
            isMagicPost: false,
            isMagicEntity: false,
            isAdEntity: false,
            isPostEntity: false,
            isVideoPost: false,
            isDeployedAd: false,
            isDeployedPost: false,
            creativeDateText: {
                start: '',
                end: '',
            },
            creativeKindLabel: '',
        }
    }
    return {
        isMagicPost: isOptionalPost(creative),
        isMagicEntity: isMagicEntity(creative),
        isAdEntity: isAdEntity(creative),
        isPostEntity: isPostEntity(creative),
        isVideoPost: isVideoPost(creative),
        isDeployedAd: isDeployedAd(creative),
        isDeployedPost: isDeployedPost(creative),
        creativeDateText: creativeDatesText(creative),
        creativeKindLabel: getCreativeKindLabel(creative),
    }
}

export const getCreativeMetadataFull = (
    creative?: Creative
): CreativeMetadataFull => {
    return {
        ...getCreativeMetadata(creative),
        user: undefined,
        isFolder: false,
        location: undefined,
        isDefaultLocation: false,
    }
}

export const formatDate = (date: string | undefined) => {
    if (!date) return
    const dateObj = new Date(gmtDateString(date))
    const now = new Date()
    if (dateObj.getFullYear() === now.getFullYear()) {
        return `${dateObj.toLocaleDateString('en', {
            month: 'short',
            day: 'numeric',
        })} at ${dateObj.toLocaleTimeString('en', {
            hour: 'numeric',
            minute: 'numeric',
        })}`
    }
    return `${dateObj.toLocaleDateString('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })} at ${dateObj.toLocaleTimeString('en', {
        hour: 'numeric',
        minute: 'numeric',
    })}`
}

export const gmbStatusOverride = (network: Network, networkStatus?: NetworkStatus) => {
    const PENDING_STATUS_SUPPORT_DATE = new Date('2024-04-01')

    if (network !== POST_NETWORK.GMB) return false
    if (!networkStatus) return false

    return (
        networkStatus.postedDate &&
        gmtDate(networkStatus.postedDate).getTime() < PENDING_STATUS_SUPPORT_DATE.getTime()
    )

}

export const getCreativeLocationType = (
    creative: AdWithMetadata | PostWithMetadata
) => {
    const { isFolder } = creative.metadata
    return isFolder ? 'Folder' : 'Campaign'
}

// *** CREATIVE STATUS SECTION ***

const hasSuccessfulStatus = (network?: NetworkStatus) => {
    if (!network) return false
    return network.postStatus === POST_STATUS.POSTED
}

const hasRejectedStatus = (network?: NetworkStatus) => {
    if (!network) return false
    return network.postStatus === POST_STATUS.CANT_POST || network.postStatus === CREATIVE_STATUS.REJECTED
}

// ** POST STATUS **

const getPostStatus = (item: PostWithMetadata, allowInactivePosting: boolean, network: Network) => {
    const { postedNetworks } = item
    const hasActiveSub = !!item.metadata.location && hasActiveSubscription(item.metadata.location)
    if (!hasActiveSub && !allowInactivePosting) {
        return CREATIVE_STATUS.NOSUBSCRIPTION
    }

    if (
        gmbStatusOverride(network, postedNetworks?.[POST_NETWORK.GMB])
    ) {
        return CREATIVE_STATUS.SENT_TO_NETWORK
    }

    if (!postedNetworks) return POST_STATUS.SCHEDULED

    if (!postedNetworks[network]) return POST_STATUS.SENT_TO_NETWORK

    const networkStatus = postedNetworks[network]?.postStatus ?? POST_STATUS.PENDING

    return networkStatus
}

const getPostOverallStatus = (item: PostWithMetadata, allowInactivePosting: boolean) => {

    const hasActiveSub = !!item.metadata.location && hasActiveSubscription(item.metadata.location)
    if (!hasActiveSub && !allowInactivePosting) {
        return CREATIVE_STATUS.NOSUBSCRIPTION
    }

    const { postedNetworks } = item

    if (!postedNetworks) return POST_STATUS.SCHEDULED

    const filteredNetworks = Object.keys(postedNetworks).filter(network => !(gmbStatusOverride(network as Network, item.postedNetworks?.[network as Network])))

    if (!filteredNetworks.length) return POST_STATUS.SCHEDULED
    if (Object.values(postedNetworks).some(network => network?.postStatus === POST_STATUS.PENDING)) return POST_STATUS.PENDING

    if (Object.values(postedNetworks).every(hasSuccessfulStatus)) return POST_STATUS.POSTED
    if (Object.values(postedNetworks).every(hasRejectedStatus)) return POST_STATUS.FAILURE

    return POST_STATUS.PARTIALLY_POSTED
}

// ** AD STATUS **

const getAdStatus = (item: AdWithMetadata) => {
    const hasActiveSub = !!item.metadata.location && hasActiveSubscription(item.metadata.location)
    if (!hasActiveSub) {
        return CREATIVE_STATUS.NOSUBSCRIPTION
    }

    return item.status
}

// ** CREATIVE OVERALL STATUS **

// Throw any creative at this function and it will return the overall status
export const _getCreativeStatus = (item: CreativeWithMetadata, allowInactivePosting: boolean, network?: Network) => {
    if (item.metadata.isFolder) return getFolderCreativeStatus(item)

    if (item.isMagic) return CREATIVE_STATUS.AWAITING_APPROVAL

    // specific for networks
    if (isPostEntity(item) && network) return getPostStatus(item, allowInactivePosting, network)
    if (isPostEntity(item)) return getPostOverallStatus(item, allowInactivePosting)

    return getAdStatus(item as AdWithMetadata)
}

export const _getCreativeStatusCopy = (item: CreativeWithMetadata, allowInactivePosting: boolean, approvals: boolean, network?: Network) => {
    const status = _getCreativeStatus(item, allowInactivePosting,  network)

    if (status !== 'UNREVIEWED') return CREATIVE_STATUS_COPY_MAP[status]

    if (item.isMagic) return "Awaiting User Review"
    if (item.fStatus === "UNREVIEWED" && approvals) return "Awaiting Corporate Review"
    return "Awaiting Network Review"
}

const getFolderCreativeStatus = <T extends Creative>(
    creative: T
): FolderStatus => {
    const { fStatus, status } = creative

    if (isExpiredPost(creative)) return FOLDER_STATUS.EXPIRED
    if (fStatus === CREATIVE_STATUS.REJECTED || status === CREATIVE_STATUS.REJECTED) return FOLDER_STATUS.REJECTED

    // Whether it is disabled or enabled for cloning to new campaigns
    if (fStatus === CREATIVE_STATUS.PAUSED) return FOLDER_STATUS.INACTIVE
    if (fStatus === CREATIVE_STATUS.ACTIVE || fStatus === CREATIVE_STATUS.UNREVIEWED) return FOLDER_STATUS.ACTIVE
    return fStatus as FolderStatus
}

const _getCreativeOverlayStatus = (item: CreativeWithMetadata, creativeStatus: ReturnType<typeof _getCreativeStatus>) => {
    if (creativeStatus === POST_STATUS.POSTED || creativeStatus === CREATIVE_STATUS.ACTIVE) return

    if (
        creativeStatus !== CREATIVE_STATUS.AWAITING_APPROVAL &&
        creativeStatus !== CREATIVE_STATUS.UNREVIEWED &&
        creativeStatus !== POST_STATUS.PARTIALLY_POSTED
    ){
        return creativeStatus
    }

    if (creativeStatus === CREATIVE_STATUS.AWAITING_APPROVAL) return magicAwaitingMessage(item.metadata.user.accessLevel)
    if (creativeStatus === CREATIVE_STATUS.UNREVIEWED) return 'UNDER NETWORK REVIEW'
    if (creativeStatus === POST_STATUS.PARTIALLY_POSTED) return 'PARTIALLY POSTED'
}

const _getCreativeOverlayIcon = (item: CreativeWithMetadata, creativeStatus: ReturnType<typeof _getCreativeStatus>) => {
    if (isVideoCreative(item) && (creativeStatus === POST_STATUS.POSTED || creativeStatus === CREATIVE_STATUS.ACTIVE)) return FiPlayCircle

    switch (creativeStatus) {
        case POST_STATUS.SCHEDULED:
            return FiClock
        case POST_STATUS.PENDING:
            return FiRefreshCw
        case CREATIVE_STATUS.PAUSED:
            return FiPause
        case CREATIVE_STATUS.AWAITING_APPROVAL:
        case CREATIVE_STATUS.UNREVIEWED:
            return AwaitingReviewIcon
        case POST_STATUS.PARTIALLY_POSTED:
            return PartiallyPostedIcon
        case CREATIVE_STATUS.REJECTED:
        case POST_STATUS.FAILURE:
            return FiAlertTriangle
        case CREATIVE_STATUS.INACTIVE:
        case CREATIVE_STATUS.EXPIRED:
            return ExpiredIcon
        default:
            return
    }
}

export const useCreativeInfo = () => {
    const { allowInactivePosting } = useDynamicSetting()
    const franchiseApprovalsEnabled = useSelector(s => s.franchise.approvals)

    const getCreativeMetadata = useGetCreativeMetadata()

    const getCreativeStatus = useCallback((item: Creative, network?: Network) => {
        const metadata = getCreativeMetadata({ creative: item })
        return _getCreativeStatus({ ...item, metadata }, allowInactivePosting, network)
    }, [allowInactivePosting, getCreativeMetadata,])

    const getCreativeStatusCopy = useCallback((item: Creative, network?: Network) => {
        const metadata = getCreativeMetadata({ creative: item })
        return _getCreativeStatusCopy({ ...item, metadata }, allowInactivePosting, franchiseApprovalsEnabled, network)
    }, [allowInactivePosting, franchiseApprovalsEnabled, getCreativeMetadata])

    return {
        getCreativeStatus,
        getCreativeStatusCopy,
    }
}

export const useCreativeOverlay = () => {
    const { allowInactivePosting } = useDynamicSetting()
    const getCreativeMetadata = useGetCreativeMetadata()

    const getCreativeOverlayStatusCopy = useCallback((item: Creative) => {
        const metadata = getCreativeMetadata({ creative: item })
        const creativeWithMetadata = { ...item, metadata }
        let creativeStatus = _getCreativeStatus(creativeWithMetadata, allowInactivePosting)
        return _getCreativeOverlayStatus(creativeWithMetadata, creativeStatus)
    }, [allowInactivePosting, getCreativeMetadata])

    const getCreativeOverlayStatusIcon = useCallback((item: Creative) => {
        const metadata = getCreativeMetadata({ creative: item })
        const creativeWithMetadata = { ...item, metadata }
        let creativeStatus = _getCreativeStatus(creativeWithMetadata, allowInactivePosting)
        return _getCreativeOverlayIcon(creativeWithMetadata, creativeStatus)
    }, [allowInactivePosting, getCreativeMetadata])

    return {
        getCreativeOverlayStatusCopy,
        getCreativeOverlayStatusIcon,
    }
}