import styled, { css } from 'styled-components';
import { decideTextColor } from '../../helpers/colors.util';

export const EnvironmentIndicator = styled.span<{
	environment: string
}>`
    display: block;
    border: 1px solid ${props => props.theme.color.greyAccent1};
    border-radius: 10px;
    font-size: 0.7rem;
    text-transform: uppercase;
    padding: 4px 12px;
    margin: 0.5rem auto;
    width: fit-content;

    ${({environment}) =>
        environment === 'test' && css `
            background-color: ${props => props.theme.color.activeButtonColor};
            color: ${props => decideTextColor(props.theme.color.activeButtonColor)};
            border: 1px solid transparent;
    `}

    ${({environment}) =>
        environment === 'staging' && css `
            background-color: ${props => props.theme.color.warningButtonColor};
            color: ${props => decideTextColor(props.theme.color.warningButtonColor)};
            border: 1px solid transparent;
    `}
`