/**
 * Recalculate the amount charged for a given franchise and subscription object,
 * or return null if the objects are incompatible
 *
 * if overIntervalCount is provided, the amount returned is periodized for the given interval count
 * @param franchise
 * @param subscription
 */
import { useSelector } from "react-redux";
import {canManageFranchise} from "./permissionsHelper";
import NumberUtil from "./number.util";
import { Franchise } from "../types/franchise";
import { IntervalCount } from "../types/subscription";
import { LocationId, UserId } from "../types/primitives";
import { CurrencyCode } from "./currency.util";

export const calculatePeriodEnd = (franchise: Franchise, newInterval: IntervalCount) => {
    let now = new Date();
    if (!franchise.subscription || !franchise.subscription.periodEnd || franchise.subscription.periodEnd < Date.now()) {
        now = new Date(now.setMonth(now.getMonth() + newInterval));
    } else {
        now = new Date(franchise.subscription.periodEnd);
        now = new Date(now.setMonth(now.getMonth() + newInterval - franchise.subscription.intervalCount));
    }
    return now.getTime();
}

export const FEATURE_STATUS = {EXPAND_MARKETING: 1, CAN_USE: 2, FRANCHISOR_MUST_ENABLE: 3}
export const FEATURE_NAME = {SOCIAL: 1, EMAIL: 2, SMS: 3}

export const useEnabledFeatures = () => {
    const user = useSelector(s => s.user);
    const franchise = useSelector(s => s.franchise);

    const getFeatureStatus = (feature: typeof FEATURE_NAME[keyof typeof FEATURE_NAME]) => {
        if (feature === FEATURE_NAME.SOCIAL && ((franchise?.monthlySaas && franchise?.featureSocialPostingEnabled) || user.paidFeatures?.includes('socialPosting'))) return FEATURE_STATUS.CAN_USE;
        if (feature === FEATURE_NAME.EMAIL && ((franchise?.monthlySaas && franchise?.featureEmailCreativeEnabled) || user.paidFeatures?.includes('emailCreative'))) return FEATURE_STATUS.CAN_USE;
        if (feature === FEATURE_NAME.SMS && ((franchise?.monthlySaas && franchise?.featureSmsCreativeEnabled) || user.paidFeatures?.includes('smsCreative'))) return FEATURE_STATUS.CAN_USE;
        return canManageFranchise(user.accessLevel) ? FEATURE_STATUS.EXPAND_MARKETING : FEATURE_STATUS.FRANCHISOR_MUST_ENABLE;
    }

    return {
        [FEATURE_NAME.SOCIAL]: getFeatureStatus(FEATURE_NAME.SOCIAL),
        [FEATURE_NAME.EMAIL]: getFeatureStatus(FEATURE_NAME.EMAIL),
        [FEATURE_NAME.SMS]: getFeatureStatus(FEATURE_NAME.SMS),
    }
}

export const loadReceipts = (uid: UserId) => {
    return new Promise((resolve, reject) => {
        if (!uid) {
            reject('Error loading receipts. No uid.');
            return;
        }
        window.eulerity.makeApiCall('/api/user/getCharges?uid=' + uid, 'GET', null, receiptHistory => {
            resolve(receiptHistory);
        },{1000: reject});
    })
}

export const loadInvoices = (uid: UserId, lid: LocationId) => {

    return new Promise((resolve, reject) => {
        if (!uid || !lid) {
            reject('Error loading invoices. No uid or lid.');
            return;
        }
        window.eulerity.makeApiCall(`/api/subscription/getStripeInvoices?uid=${uid}&lid=${lid}`, 'GET', null, resp => {
            resolve(resp);
        }, {1000: reject})
    })
}

export const isZeroDecimalCurrency = (currency: CurrencyCode) => {
    const zeroDecimalCurrencies = ["bif", "clp", "mga", "djf", "gnf", "jpy", "kmf", "krw", "pyg", "rwf", "ugx", "vnd", "vuv", "xaf", "xof", "xpf"];
	if (typeof currency !== 'string') return false
	return zeroDecimalCurrencies.includes(currency.toLowerCase());
}

export const formatFromPennies = (pennies: number, currency: CurrencyCode = 'usd') => {
    const isZDC = isZeroDecimalCurrency(currency)
    const amount = isZDC ? pennies : pennies / 100;
    return amount
}

export const formatToPennies = (amount: number, currency: CurrencyCode = 'usd') => {
	const isZDC = isZeroDecimalCurrency(currency)
	return isZDC ? amount : amount * 100;
}

// STRIPE HELPER
export const StripeHelper = {
    formatAmount: (currency: CurrencyCode = 'usd', amountInCents: number) => {
        // Stripe amount field is in cents so ensure to convert off of cents if needed before displaying
        const amount = isZeroDecimalCurrency(currency) ? amountInCents : amountInCents / 100;
        return NumberUtil.formatCurrencyNumber(amount, currency)
    },
    formatCreatedDate:  (obj: any) => {
        const { created } = obj;
        let dateObj = new Date(created * 1000);
        let date = `${dateObj.getUTCMonth() + 1}/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}`
        return date;
    },
}