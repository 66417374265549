import styled from "styled-components";
import { createBoxShadow } from "../../styles/card";

// ROOT MODAL STYLES
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 1000; // Changed to 1000 since the two cases where we interact with reactjs-popup, we want the modal to be over top

  display: flex;
  align-items: center;
  justify-content: center;

  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: ${props => props.theme.color.greyAccent2};
  }
`;

type StyledModalProps = {
  width?: string,
  maxWidth?: string,
  minHeight?: string,
  overflowY?: string,
}

export const StyledModal = styled.div<StyledModalProps>`
  position: relative;
  background-color:${props => props.theme.color.backgroundColor2};
  z-index: 1000;
  width: ${(props) => props.width || "1100px"};
  max-width: ${(props) => props.maxWidth || null};
  min-height: ${(props) => props.minHeight || null};
  max-height: 95vh;
  overflow-y: ${(props) => props.overflowY || "auto"};
  padding: 60px 55px 30px 55px;
  box-shadow:${createBoxShadow};
  border-radius: 8px;

  .close-btn {
    cursor: pointer;
    svg {
      width: 24px;
      height: auto;
    }
    svg path {
      stroke: ${props => props.theme.color.accentColor2};
    }
    display: flex;
    position: absolute;
    top: 25px;
    right: 25px;
    line-height: 1;
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  button {
    margin: 10px;
    font-size: 1.3rem;
  }

  .footerContent {
    width: 100%;
  }
`;