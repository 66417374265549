export type AgeTarget = 'a' | 'b' | 'c' | 'd' | 'e' | 'f'
export type AgeTargetLabel = '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+'

export const ageTargetCopy: {
    [key in AgeTarget]: AgeTargetLabel
} = {
    a: "18-24",
    b: "25-34",
    c: "35-44",
    d: "45-54",
    e: "55-64",
    f: "65+"
} as const

export const getAgeTargets = (ageTargets: AgeTarget[]): AgeTarget[] => {
    if (!ageTargets || !ageTargets.length) {
        return Object.keys(ageTargetCopy) as AgeTarget[]
    }

    return ageTargets
}

export const getAgeTargetLabel = (target: AgeTarget) => {
    return ageTargetCopy[target]
}