import React, { useMemo } from 'react'
import ModalV2 from '../../../components/modal/ModalV2/ModalV2'
import Button from '../../../components/button/Button';
import { Body, Small } from '../../../styles/typography';
import { JobTasksResponse } from '../../../features/backgroundJobs/types';
import { StyledTable } from '../BulkEditConfirmation';
import { useSelector } from 'react-redux';
import { EulerityLocation } from '../../../types/location';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../../constants/urlPath';
import { SUBSCRIPTION_HEADERS } from '../csvHeaderConstants';
import getSuperTableComponents from '../../../components/SuperTable/getSuperTableComponents';

const BulkSubscriptionJobModal = ({
  onClose: handleClose,
  jobResponse,
} : {
  onClose: () => void;
  jobResponse: JobTasksResponse[]
}) => {
  const history = useHistory()
  const locations = useSelector(s => s.franchise.locations)
  const headers = [SUBSCRIPTION_HEADERS.BUSINESS_NAME, SUBSCRIPTION_HEADERS.CAMPAIGN_NAME, SUBSCRIPTION_HEADERS.STATUS]
  const failedJobs = jobResponse.map(job => ({ ...JSON.parse(job.queryParams) }))
  const failedLocations = useMemo(() =>
    failedJobs.map(job => locations.find((l: EulerityLocation) => l.id === Number(job.lid) && l.user.raw.name === job.uid)),
  [ failedJobs, locations ])

  const { Wrap, Column } = getSuperTableComponents<EulerityLocation>()

  return (
    <ModalV2
      size="md"
      title={"Do you want to resubmit these bulk edits?"}
      onClose={handleClose}
      buttons={[
        <Button variant="outline" onClick={handleClose}>Close</Button>,
        <Button onClick={() => {history.push(PATH.BULK_SETTINGS); handleClose()}}>Resubmit Bulk Edit</Button>
      ]}
    >
      <Body style={{ marginBottom: "2rem" }}>Some of your campaign subscription updates didn’t go through.</Body>
      <StyledTable boldNthLastRow={0}>
        <Wrap items={failedLocations}>
          {headers.map(header => {
            return (
              <Column key={header} title={header}>
                {lineItem => {
                  if (header === SUBSCRIPTION_HEADERS.BUSINESS_NAME) return <Small>{lineItem?.name || "Business Not Found"}</Small>
                  if (header === SUBSCRIPTION_HEADERS.CAMPAIGN_NAME) return <Small>{lineItem?.nickname || "Campaign Not Found"}</Small>
                  if (header === SUBSCRIPTION_HEADERS.STATUS) return <Small semibold color="errorNegativeButtonColor">Update Failed</Small>
                }}
              </Column>
            )
          })}
        </Wrap>
      </StyledTable>
    </ModalV2>
  )
}

export default BulkSubscriptionJobModal