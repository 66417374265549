import { getGenderTargetLabel } from '../../../../../../../helpers/demographics/genderTargets'
import columnBuilder from '../../../columnBuilder'
import { reportLoader } from '../../../loaders'

const genderDistributionReportCategory = columnBuilder()
    .category('distribution')
    .subCategory('Gender')
    .loadRow(reportLoader)
    .makeHeader((meta) => {
        return `${meta.groupName} ${meta.name}`
    })

const genderTypes = ['m', 'f'] as const

const deviceDistributionFormatter = (value: number) => {
	return `${value.toFixed(0)}%`
}

const genderColumns = [
    ...genderTypes.map((genderType) =>
        genderDistributionReportCategory
            .groupId('gender-distribution-clicks')
            .groupName('Clicks')
            .id(`gender-distribution-clicks-${genderType}`)
            .name(`(${getGenderTargetLabel(genderType)})`)
            .makeRow((data) => {
                return data.row.gender.clicks_pct[genderType]
            })
            .toString(deviceDistributionFormatter)
    ),
    ...genderTypes.map((genderType) =>
        genderDistributionReportCategory
            .groupId('gender-distribution-impressions')
            .groupName('Impressions')
            .id(`gender-distribution-impressions-${genderType}`)
            .name(`(${getGenderTargetLabel(genderType)})`)
            .makeRow((data) => {
                return data.row.gender.clicks_pct[genderType]
            })
            .toString(deviceDistributionFormatter)
    ),
]

export default genderColumns
