// export all of the exports from the files in here

import { InsightReportCategory, InsightReportSubCategory } from '../reportTypes'
import businessAndCampaigns from './businessAndCampaigns'
import distributionColumns from './distribution'
import performanceColumns from './performance'
import platformConnectionColumns from './platformConnections'

const insightReportBuilderColumns = [
    ...businessAndCampaigns,
    ...distributionColumns,
    ...performanceColumns,
    ...platformConnectionColumns,
].map((column) => column._build())

export default insightReportBuilderColumns

export type ColumnOption = (typeof insightReportBuilderColumns)[number]

// Most groups are just one column
// but some groups have multiple columns (distribution reports generate multiple columns per category)
// ie device -> desktop, mobile, tablet columns are made
// so we need to group them together
export type ColumnGroupOption = Pick<
ColumnOption,
'groupId' | 'groupName' | 'category' | 'subCategory'
>

export const insightReportColumnOptions = Array.from(insightReportBuilderColumns.reduce((acc, column) => {
    const { groupId, groupName, category, subCategory } = column

    if (!acc.has(groupId)) {
        acc.set(groupId, { groupId, groupName, category, subCategory })
    }

    return acc
}, new Map<string, ColumnGroupOption>()).values())

export const columnGroupIdMap = insightReportBuilderColumns.reduce((acc, column) => {
    const { groupId, id } = column
    if (!acc.has(id)) {
        acc.set(id, groupId)
    }

    return acc
}, new Map<string, string>())

// map of column category to column subcategory to columnGroupOption
export const columnCategoryMap = insightReportBuilderColumns.reduce((acc, column) => {
    const { category, subCategory } = column

    if (!acc.has(category)) {
        acc.set(category, new Map())
    }

    const categoryMap = acc.get(category)!

    if (!categoryMap.has(subCategory)) {
        categoryMap.set(subCategory, [])
    }

    const columnGroupOptions = categoryMap.get(subCategory)!

    if (columnGroupOptions.some((columnGroupOption) => columnGroupOption.groupId === column.groupId)) return acc

    columnGroupOptions.push(column)

    return acc
}, new Map<InsightReportCategory, Map<InsightReportSubCategory<InsightReportCategory>, Array<ColumnGroupOption>>>())