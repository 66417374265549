import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasActiveSubscription } from '../../../helpers';
import { getLocationUserEmail } from '../../locations/util';
import { canManageFranchise } from '../../../helpers/permissionsHelper';
import { isPrimaryLocation } from '../../../types/location';

// use Initialize Options is a internal hook that initializes the data for the filter
// We create set of options here and inject fields which we need for UI purposes

// filters should be a map of filters to apply to each set of options
// repoOptions --> repoFilter
// locationOptions --> locationFilter
// blueprintOptions --> blueprintFilter

export const POST_LOCATION_FILTERS = {
  locationFilter: (location) => isPrimaryLocation(location)
}

const useInitializeOptions = (filters = {}) => {
  const defaultLocation = useSelector((state) => state.franchise.defaultLocation);
  const locations = useSelector((state) => state.franchise.locations);
  const blueprints = useSelector((state) => state.repo.blueprints);
  const loggedInUser = useSelector((state) => state.user);
  const isManager = canManageFranchise(loggedInUser.accessLevel);
  const memberList = useSelector((state) => state.franchise.memberList);

  // * Init Repos
  const repoOptions = (repos) => {
    if (repos?.length) {
      return repos.map((repo) => {
        return {
          ...repo,
          category: "Creative Folders",
          displayName: repo.nickname,
          label: repo.nickname,
          type: "repo",
          value: `${defaultLocation.user.raw.name}_${repo.id}`,
        };
      });
    } else return [];
  };

  // * Init Locations
  const locationOptions = useMemo(() => {
    const filteredLocations = filters.locationFilter ? locations.filter(filters.locationFilter) : locations
    return filteredLocations.map((location) => {
      const { id, user, nickname, name, subscriptions, labels } = location;
      return {
        ...location,
        category: location?.primaryLocation ? "Secondary Campaigns" : "Primary Campaigns",
        displayName: nickname,
        email: getLocationUserEmail(location, memberList),
        isActive: hasActiveSubscription(location),
        label: `${nickname} (${name})`,
        status: subscriptions.length ? subscriptions[0]?.status : "DRAFT",
        labelText: labels.join(','),
        type: "location",
        value: `${user.raw.name}_${id}`,
      };
    });
  }, [locations, memberList]);

  // * Init Default (only if user has access)
  const defaultLocationOptions = (showAsLocation = false) => {
    if (isManager) {
      if (showAsLocation) {
        return [
          {
            ...defaultLocation,
            category: "default-campaign-setup",
            displayName: "Default Campaign Setup",
            value: defaultLocation?.websafe,
            isDefault: true,
          }
        ]
      } else {
        return [
          {
            ...defaultLocation,
            category: "Creative Folders",
            displayName: "Default Campaign Creative Folder",
            label: "Default Campaign Creative Folder",
            type: "repo",
            value: `${defaultLocation.user.raw.name}_${defaultLocation.id}`,
            _advancedFilterType: "repo"
          },
        ];
      }
    }
    return [];
  };

  const blueprintOptions = () => {
    const franchiseBlueprints = blueprints.filter(blueprint => !!blueprint.franchise).map(blueprint => ({
      ...blueprint,
      category: "Blueprints",
      value: blueprint.id,
      displayName: blueprint.name
    }))

    return [...franchiseBlueprints]
  }

  return { repoOptions, locationOptions, defaultLocationOptions, blueprintOptions }
}

export default useInitializeOptions