import {
    UPDATE_EMAIL_HISTORY_SEARCH_VAL,
    UPDATE_EMAIL_PREVIEW,
    UPDATE_SHOW_TEST_EMAILS,
    UPLOAD_EMAIL_HTML
} from '../actions/types'

const initialState = {
    emailBlob: null,
    emailFile: null,
    showTestEmails: false,
    emailPreviewOpen: false,
    emailHistorySearchVal: ''
}

const emailReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPLOAD_EMAIL_HTML: {
            console.log('Uploaded html file: ' + action.payload.fileName + ' with blob: ' + action.payload.blobKey)
            return {...state, emailBlob: action.payload.blobKey, emailFile: action.payload.fileName}
        }
        case UPDATE_SHOW_TEST_EMAILS: {
            return {...state, showTestEmails: action.payload}
        }
        case UPDATE_EMAIL_HISTORY_SEARCH_VAL: {
            return {...state, emailHistorySearchVal: action.payload}
        }
        case UPDATE_EMAIL_PREVIEW: {
            return {...state, emailPreviewOpen: action.payload}
        }
        default: {
            return { ...state }
        }
    }
}


export default emailReducer