/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import fbIcon from "../../assets/images/fbicon.png";
import messengerIcon from "../../assets/images/messengerIcon.png";
import fbShare from "../../assets/images/like_comment_share_facebook.png";
import {
  PreviewMessage,
  SocialWrap,
  SocialPreviewCopyHeader,
  FacebookContentWrap,
} from "./styles";
import { getPreviewImageUrl, isAdEntity } from "../../helpers/creative.util";
import { H2 } from "../../styles/typography";
import useVersionData from "../../hooks/useVersionData";
import  StringUtil  from "../../helpers/string.util";
import { PREVIEW_PLACEHOLDER_TEXT } from "../../helpers/constants";

const FacebookPreview = ({ creative, previewText }) => {
  const { preview, youTubeId, cta } = creative;
  const {
    facebookDescription,
  } = previewText;

  const { defaultCta } = useVersionData()

  return (
    <SocialWrap>
      <SocialPreviewCopyHeader>
        <img src={fbIcon} alt="fb-logo" />
        <H2>Facebook Preview</H2>
      </SocialPreviewCopyHeader>
      <FacebookContentWrap>
        <div className="top-section">
          <div className="logo-container">
            <div className="placeholder-img" />
          </div>
          <div className="page-info">
            <strong>{PREVIEW_PLACEHOLDER_TEXT.facebookPageName}</strong>
          </div>
          <div className="actions">
            <FaEllipsisH size={"10px"} style={{ "marginBottom": "auto" }} />
          </div>
        </div>
        <div className="text-content">{facebookDescription.length > 510 ?  StringUtil.truncateString(facebookDescription, 509) : facebookDescription || ""}</div>
        <img className="selected-image" src={ getPreviewImageUrl({preview, youTubeId})} alt='fb-preview-img'/>
        {
          isAdEntity(creative) &&
          <div className="learn-more-container">
            <div className="web-text-content">
              <p>{PREVIEW_PLACEHOLDER_TEXT.domainPlaceholder}</p>
              <strong>{PREVIEW_PLACEHOLDER_TEXT.businessPlaceholder}</strong>
            </div>
            <div className="learn-more">{StringUtil.displaySnakeStr(cta || defaultCta)}</div>
          </div>
        }
        <img className="fb-share" src={fbShare} alt="fb-share"/>
      </FacebookContentWrap>
      {
        isAdEntity(creative) &&
        <PreviewMessage>
          <img src={messengerIcon} alt="fb-messenger-icon" />
          <p style={{ fontSize: '.45rem' }}>Your ad will be reformatted for Story ads, Messenger ads & more...</p>
        </PreviewMessage>
      }
    </SocialWrap>
  );
};

export default FacebookPreview;
