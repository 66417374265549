import generalColumns from './general'
import clicksColumns from './clicks'
import cpcColumns from './cpc'
import cpmColumns from './cpm'
import ctrColumns from './ctr'
import facebookLeadsColumns from './facebookLeads'
import impressionsColumns from './impressions'
import spendColumns from './spend'

const performanceColumns = [
    ...generalColumns,
    ...clicksColumns,
    ...cpcColumns,
    ...cpmColumns,
    ...ctrColumns,
    ...impressionsColumns,
    ...spendColumns,
    ...facebookLeadsColumns,
]

export default performanceColumns
