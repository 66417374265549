export const EXPERIMENTS = {
    EMAIL_MARKETING: 'be-fcc-show-email-marketing',
    SMS_MARKETING: 'be-fcc-show-sms-marketing',
    // !-- New Experiment prefixes should now just be "fcc-" instead of "be-fcc-"
    // !-- Experiments are ALL LOWERCASE
    SEARCH_TERM_REPORT: 'fcc-search-term-report',
    CPE_EULERITY_ATTRIBUTION: 'fcc-cpe-eulerity-attribution',
    FB_IG_COMMENTS: 'fcc-fb-ig-comments',
    BRAND_THEME_TOOL: "fcc-brand-theme-tool",
    DEMO_RMI_LISTING_MANAGEMENT: 'fcc-demo-rmi-listing-management',
    NEW_GEO_RADII_UI:'fcc-new-geo-radii-ui',
    CORPORATE_PAYMENTS: 'fcc-corporate-payments',
    VISIBILITY_CUE: 'fcc-visibility-cue',
    LEADFORM_TIMEZONE: 'fcc-leadform-timezone',
    REPUTATION_LISTINGS_COPY: 'fcc-reputation-listings-copy',
    BLOCK_POSTS_ON_SECONDARIES: 'fcc-block-posts-on-secondaries',
    LOCATION_PERFORMANCE_REPORT: 'fcc-location-performance-report',
    TOTAL_EVENTS: 'fcc-total-events',
    AGE_TARGETING: 'fcc-age-targeting',
    PRODUCT_SWITCH_SORTING: 'fcc-switch-center-sorting',
    GOOGLE_TV_TARGETING: 'fcc-google-tv-targeting',
    RM_API_CHANGE: 'fcc-rm-api-change',
} as const
// Use the useExperiment hook now for experiment logic

export type ExperimentName = typeof EXPERIMENTS[keyof typeof EXPERIMENTS]
