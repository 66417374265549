import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../../components/modal/Modal';
import Accordion from '../../../components/reusableUI/components/Accordion/Accordion';
import AnimatedCollapse from '../../../components/reusableUI/components/AnimatedCollapse/AnimatedCollapse';
import DeprecatedSuperTableDoNotUse from '../../../components/SuperTable';
import { PATH } from '../../../constants/urlPath';
import useDynamicSetting from '../../../hooks/useDynamicSetting';
import { H2, H4, Link, Small } from '../../../styles/typography';
import { VARIABLE_SHORTCUT_REGEX_QUERY } from '../variables.utils';
import { useAccess } from '../../../hooks/useAccess';
import useRunOnReturn from '../../../hooks/useRefreshOnReturn';
import useRunAudit from '../hooks/useRunAudit';
import { FiExternalLink } from 'react-icons/fi';
import { FORKS } from '../../../constants/fork';

const useDefaultValueWarning = (textsWithVariables, locationsToCheck) => {
    const defaultLocation = useSelector((state) => state.franchise.defaultLocation);
    const locations = useSelector(state => state.franchise.locations);

    const { variables } = useDynamicSetting();

    // List of shortcuts of variables/macros that are found in the creative
    const shortcutsInUse = useMemo(() => {
        const allShortcuts = new Set();

        const shortcutMatcher = new RegExp(VARIABLE_SHORTCUT_REGEX_QUERY, 'g')

        for (const text of textsWithVariables) {
            const matches = text.match(shortcutMatcher);
            if (matches) {
                for (const match of matches) {
                    allShortcuts.add(match);
                }
            }
        }

        return Array.from(allShortcuts);
    }, [textsWithVariables]);

    // List of variables with locations where at least one of the locations is using the default value for that variable
    const outdatedVariables = useMemo(() => {
        const outdated = [];

        for (const shortcut of shortcutsInUse) {
            const variableData = variables.find((v) => v.shortcut === shortcut);

            if (!variableData) {
                continue;
            }

            const outdatedLocations = [];

            for (const location of locationsToCheck) {
                // we want to filter out any folders that might be in this list, as they cannot have variableValues
                if (
                    defaultLocation &&
                    (location.websafe === defaultLocation?.websafe ||
                        location?.primaryLocation?.websafe === defaultLocation?.websafe)
                ) {
                    continue;
                }

                // when we runAudit, the selectedLocations aren't updated in state, so we need to pull them manually here
                const updatedVariableValues = locations.find(loc => loc.websafe === location.websafe)?.variableValues;

                const isUsingDefaultValue = !updatedVariableValues?.[shortcut];

                if (isUsingDefaultValue) {
                    outdatedLocations.push(location);
                }
            }

            if (outdatedLocations.length) {
                outdated.push({
                    variable: variableData,
                    locations: outdatedLocations,
                });
            }
        }

        return outdated;
    }, [shortcutsInUse, locationsToCheck, variables, defaultLocation, locations]);

    return {
        outdatedVariables,
    };
};

// if setSeeMoreShortcut is not passed, we can assume that we want to show all of the campaigns
const DefaultValueWarningTable = ({ outdatedVariables, setSeeMoreShortcut }) => {
    const adOrPostFork = useSelector(s => s.build.adOrPostFork)
    const { isMember } = useAccess();
    const runAudit = useRunAudit();
    const productSwitchPath = adOrPostFork === FORKS.AD ? PATH.ADVERTISING : PATH.SOCIAL_POSTING

    const { waitForReturn } = useRunOnReturn(() => runAudit(false));

    return (
        <>
            <Small style={{ marginBottom: '2.5rem' }}>
                The campaigns you have selected use the "default value" for{' '}
                {outdatedVariables.length === 1 ? 'this variable' : 'these variables'}. Please visit
                the{' '}
                <a onClick={waitForReturn} href={isMember() ? productSwitchPath : PATH.VARIABLES} rel='noreferrer' target='_blank'>
                    <Link>{isMember() ? 'Business & Campaigns' : 'Variables'}</Link> <FiExternalLink />
                </a>{' '}
                page to update {outdatedVariables.length === 1 ? "it's" : 'their'} values.
            </Small>
            <DeprecatedSuperTableDoNotUse items={outdatedVariables}>
                <DeprecatedSuperTableDoNotUse.Column title={<H4>VARIABLE</H4>}>
                    {(item) => {
                        return <Small>{item.variable.shortcut}</Small>
                    }}
                </DeprecatedSuperTableDoNotUse.Column>
                <DeprecatedSuperTableDoNotUse.Column title={<H4>DEFAULT VALUE</H4>}>
                    {(item) => {
                        return <Small>{item.variable.defaultValue}</Small>
                    }}
                </DeprecatedSuperTableDoNotUse.Column>
                <DeprecatedSuperTableDoNotUse.Column title={<H4>CAMPAIGNS</H4>}>
                    {(item) => {
                        const shouldShowAll = !setSeeMoreShortcut;

                        const shouldShowMore = item.locations.length > 2;

                        return (
                            <Small>
                                {item.locations
                                    .slice(0, shouldShowAll ? undefined : 2)
                                    .map((loc) => {
                                        return <div key={loc.websafe}>{loc.nickname}</div>;
                                    })}
                                {shouldShowMore && !shouldShowAll && (
                                    <Link
                                        onClick={() => setSeeMoreShortcut(item.variable.shortcut)}
                                    >
                                        See More
                                    </Link>
                                )}
                            </Small>
                        );
                    }}
                </DeprecatedSuperTableDoNotUse.Column>
            </DeprecatedSuperTableDoNotUse>
        </>
    );
};

const DefaultValueWarning = ({ textsWithVariables, locationsToCheck }) => {
    const { outdatedVariables } = useDefaultValueWarning(textsWithVariables, locationsToCheck);

    const [seeMoreShortcut, setSeeMoreShortcut] = useState(null);

    const seeMoreVariable = useMemo(() => {
        if (!seeMoreShortcut) {
            return null;
        }

        return outdatedVariables.find((item) => item.variable.shortcut === seeMoreShortcut);
    }, [seeMoreShortcut, outdatedVariables])

    return (
        <AnimatedCollapse isOpen={outdatedVariables.length !== 0}>
            <Accordion
                title={<H4 color="textColor2">WARNING - VARIABLES USING DEFAULT VALUE</H4>}
                openByDefault={true}
            >
                {seeMoreShortcut && seeMoreVariable?.locations?.length && (
                    <Modal onClose={() => setSeeMoreShortcut(null)}>
                        <H2 style={{ marginBottom: '2.5rem' }}>Variable Using Default Value</H2>
                        <DefaultValueWarningTable outdatedVariables={[seeMoreVariable]} />
                    </Modal>
                )}
                <DefaultValueWarningTable
                    outdatedVariables={outdatedVariables}
                    setSeeMoreShortcut={setSeeMoreShortcut}
                />
            </Accordion>
        </AnimatedCollapse>
    );
};

export default DefaultValueWarning;
