import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SerializedKey } from '../types/apiResponses'

export type EulerityFont = {
    family: string
    blobKey: string

    bold: boolean
    italic: boolean
    franchise?: SerializedKey
    filename: string
    created: string
    updated: string
}

export const buildFontObject = (themeObject?: BackendFontTheme) => {
    const fontTheme = Object.keys(fontMapper).reduce((acc, key) => {
        const backendFontKey = key as keyof BackendFontTheme
        const backendFont = themeObject?.[backendFontKey] || {} as BackendFontTheme[keyof BackendFontTheme]
        return {
            ...acc,
            [fontMapper[backendFontKey]]: {
                family: backendFont.family || '',
                blobKey: backendFont['blob-fontfile'] || '',
            },
        }
    }, {} as FontTheme)
    return fontTheme
}

export const eulerityFont = (font: EulerityFont) => {
    const fontFace = new FontFace(font.family, `url(/srv/${font.blobKey})`, {
        style: font.italic ? 'italic' : 'normal',
        weight: font.bold ? 'bold' : 'normal',
    })
    return fontFace
}

export const loadFonts = (fonts: EulerityFont[]) => {
    const fontSet = []
    for (const font of fonts) {
        const fontFace = eulerityFont(font)
        fontSet.push(fontFace.load())
        document.fonts.add(fontFace)
    }
    return Promise.all(fontSet)
}

export const addFont = (font: EulerityFont) => {
    const fontFace = eulerityFont(font)
    document.fonts.add(fontFace)
}

export const loadFontsToHtml = (fonts: EulerityFont[]) => {
    fonts.forEach(font => {
        addFont(font)
    })
}

export const mapDuplicatedFonts = (fonts: EulerityFont[]) => (
    Object.values(fonts.reduce((obj, font) => {
        if (!obj[font.family]) {
          obj[font.family] = [font];
        } else {
          obj[font.family] = [...obj[font.family], font];
        }
        return obj;
      }, {} as Record<string, EulerityFont[]>))
)


// TYPE MAPPING

const fontMapper = {
    'font-webtitle': 'title',
    'font-webheader1': 'h1',
    'font-webheader2': 'h2',
    'font-webheader3': 'h3',
    'font-webheader4': 'h4',
    'font-webregular': 'body',
    'font-websemibold': 'semiboldBody',
    'font-webbold': 'boldBody',
    'font-webbutton': 'button',
} as const

type FontMapper = typeof fontMapper

export type BackendFontTheme = {
    [key in keyof FontMapper]: {
        family: string
        'blob-fontfile': string
    }
}

export type FontTheme = {
    [key in FontMapper[keyof FontMapper]]: EulerityFont
}

export const getCanvasFontStyle = (font: EulerityFont) => {
    if (!font) return 'DEFAULT'
    if (font.bold && font.italic) return 'BITALIC'
    if (font.bold) return 'BOLD'
    if (font.italic) return 'ITALIC'
    return 'DEFAULT'
}

export const getCanvasFontStyleFlags = (style: ReturnType<typeof getCanvasFontStyle> | undefined) => {
    let bold = false
    let italic = false

    if (style?.includes("ITALIC")) italic = true
    if (style?.includes("B")) bold = true

    return {
        bold,
        italic
    }
}

export const useFonts = () => {
    const systemFonts = useSelector((s) => s.franchise.allFonts?.filter((font) => !font.franchise))
    const franchiseFonts = useSelector((s) =>
            s.franchise.allFonts?.filter((font) => font.franchise?.raw.name === s.franchise.name)
        )

    const flatFranchiseFonts = useMemo(() => mapDuplicatedFonts(franchiseFonts || []), [franchiseFonts])

    const disableStockFonts = useSelector((s) => s.franchise.disableStockFonts)

    const getUniqueFonts = (fonts: EulerityFont[]) => {
        const fontMap = new Map<string, EulerityFont>()

        for (const font of fonts) {
            const key = `${font.family.toLowerCase()}-${font.italic}-${
                font.bold
            }`

            if (fontMap.has(key)) continue

            fontMap.set(key, font)
        }

        return Array.from(fontMap.values())
    }

    // Remove duplicate fonts
    // We need this since when the backend updated to Java11
    // we set all bold and italic fields to true
    // meaning we could not tell the difference between the variants
    // since the unique identifier of a font is: family + italic + bold
    const uniqueFranchiseFonts = useMemo(() => {
        if (!franchiseFonts) return []

        return getUniqueFonts(franchiseFonts)
    }, [franchiseFonts])

    const uniqueSystemFonts = useMemo(() => {
        if (!systemFonts) return []

        return getUniqueFonts(systemFonts)
    }, [systemFonts])

    const fonts = useMemo(() => {
        if (disableStockFonts) return uniqueFranchiseFonts
        return [...uniqueFranchiseFonts, ...uniqueSystemFonts]
    }, [disableStockFonts, uniqueSystemFonts, uniqueFranchiseFonts])

    return {
        flatFranchiseFonts,
        franchiseFonts,
        systemFonts,
        fonts
    }
}