import React from 'react'
import { ProgressBarContainer, StyledProgressBar } from './styles'
import { XS } from '../../styles/typography';
import { MultiStepFormProps } from './MultiStepForm';
import styled, { css } from 'styled-components';
import { forkColorController } from '../../pages/build/newForks/fork.util';

/**
 * This shows the progress the user has made through the form
 * at the top where completed steps will show as done (steps, currentStep)
 * creationType is used to color this differently if we are in a
 * Build Creative flow
 */

type FormProgressProps = Omit<MultiStepFormProps, 'disableProgressBar'>

const FormProgress = ({ steps, currentStep, creationType }: FormProgressProps) => {

    return (
        <ProgressBarContainer>
            <StyledProgressBar>
                {
                    steps.map((step, idx) => {
                        let {title} = step;
                        return <StyledStepItem
                                    creationType={creationType}
                                    key={title}
                                    done={idx < currentStep}
                                    current={idx === currentStep}
                                >
                                    <XS semibold>{title}</XS>
                                </StyledStepItem>
                    })
                }
            </StyledProgressBar>
        </ProgressBarContainer>
    )
}

type StepItemProps = {
    done: boolean
    current: boolean
    creationType: FormProgressProps['creationType']
}

const StyledStepItem = styled.li<StepItemProps>`
    list-style-type: none;
    width: 16.5%;
    font-size: 16px;
    line-height: 1;
    position: relative;
    z-index: 1; //If you have trouble with the indexes of this styled component in the form progress bar, look into stacking contexts
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: color 200ms ease-out;
    -moz-transition: color 200ms ease-out;
    -o-transition: color 200ms ease-out;
    transition: color 200ms ease-out;
    color: ${props => props.current ? forkColorController({...props, isHover: true }) : props.theme.color.textColor1 };

    // Step Counter and Title Tex
    ${props => props.done && css`
        color: ${props => forkColorController({...props, isHover: true})};
   `}

  &:before {
    font-family: ${({theme}) => theme.font.body?.family};
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    // 2 lines for the circless
    border: ${props => props.current || props.done ? '3px' : '2px'} solid ${props => props.current ? forkColorController({...props, isHover: true }) : props.theme.color.greyAccent1 };
    color: ${props => props.current ? forkColorController({...props, isHover: true }) : props.theme.color.greyAccent1 };
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: ${props => props.theme.color.backgroundColor2};
    font-weight: 600;
    -webkit-transition: border 200ms ease-out;
    -moz-transition: border 200ms ease-out;
    -o-transition: border 200ms ease-out;
    transition: border 200ms ease-out;
   // Circles
    ${({done}) =>
        done && css `
          content: '✓';
          font-weight: bold;
          color: ${props => props.theme.color.backgroundColor2};
          border-color: ${props =>  forkColorController({...props,  isHover: true})};
          background-color: ${props =>  forkColorController({...props,  isHover: true})};
    `}
  }

  &:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: ${props => props.current ? forkColorController({...props}) :
      (props.done ? forkColorController({...props,  isHover: true}) : props.theme.color.greyAccent1)};
    top: 15px;
    left: calc(-50% + 17px);
    z-index: -1;
    -webkit-transition: background-color 200ms ease-out;
    -moz-transition: background-color 200ms ease-out;
    -o-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
  }

  &:first-child:after {
    content: none;
  }

  p {
    color: ${props => props.current ? forkColorController({...props, isHover: true }) : props.theme.color.greyAccent1 };

    ${({done}) => done && css `
        color: ${props => forkColorController({...props, isHover: true})};
    `}
  }
`

export default FormProgress
