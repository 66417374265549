import { LOCATION_CONNECTIONS } from '../../../../../../../features/connections/constants'
import {
    CONNECTION_STATUS_COPY,
    getLocationConnections
} from '../../../../../../../features/locations/util'
import columnBuilder from '../../../columnBuilder'

const linkedinCategoryReport = columnBuilder()
    .category('platform-connections')
    .subCategory('LinkedIn')
    .makeHeader((meta) => {
        return `LinkedIn: ${meta.name}`
    })

const linkedinColumns = [
    linkedinCategoryReport
        .id('linkedin-connection-status')
        .name('Connection Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return connections[LOCATION_CONNECTIONS.LINKEDIN_ADVERTISING]
        })
        .toString((data) => CONNECTION_STATUS_COPY[data]),
    linkedinCategoryReport
        .id('linkedin-connection-status-organization-page-name')
        .name('Organization Page Name')
        .makeRow((data) => {
            return data.input.location.linkedInOrgName
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
]

export default linkedinColumns
