import { MdImage, MdMovieCreation } from 'react-icons/md'
import { FirstPlaceTrophy, GenericTrophy, SecondPlaceTrophy, ThirdPlaceTrophy } from './icons'

export const getRankedTrophy = (rank) => {
    if (rank == 1) return <FirstPlaceTrophy />;
    if (rank == 2) return <SecondPlaceTrophy />;
    if (rank == 3) return <ThirdPlaceTrophy />;
    return <GenericTrophy />;
}

export const renderTrophyType = type => type === 'video' ? <MdMovieCreation size="15px" /> : <MdImage size="15px" />

export const getTrophyRank = (creative, rankArray) => {
    if(!rankArray?.length) return null;
    let idx = rankArray.findIndex(c => c == creative.id)
    let rank = idx >= 0 ? idx + 1 : null;
    return rank;
}
