import { LOCATION_CONNECTIONS } from '../../../../../../../features/connections/constants'
import {
    CONNECTION_STATUS_COPY,
    getLocationConnections,
} from '../../../../../../../features/locations/util'
import { getDataFilterCopy } from '../../../../../../locations/modals/settings/connections/GA4Filters/GAFilters.utils'
import columnBuilder from '../../../columnBuilder'
import { arrayToString } from '../../businessAndCampaigns/targeting'

const ga4ReportCategory = columnBuilder()
    .category('platform-connections')
    .subCategory('GA4')
    .makeHeader((meta) => {
        return `GA4: ${meta.name}`
    })

const ga4Columns = [
    ga4ReportCategory
        .id('ga4-connection-status')
        .name('Connection Status')
        .makeRow((data) => {
            const connections = getLocationConnections(data.input.location)

            return connections[LOCATION_CONNECTIONS.GOOGLE_ANALYTICS_4]
        })
        .toString((data) => CONNECTION_STATUS_COPY[data]),
    ga4ReportCategory
        .id('ga4-default-account-id')
        .name('Account ID')
        .makeRow((data) => {
            return data.input.location.analytics4Account?.name
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
    ga4ReportCategory
        .id('ga4-default-property-id')
        .name('Property ID')
        .makeRow((data) => {
            return data.input.location.analytics4Property?.name
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
    ga4ReportCategory
        .id('ga4-default-property-name')
        .name('Property Name')
        .makeRow((data) => {
            return data.input.location.analytics4Property?.displayName
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
    ga4ReportCategory
        .id('ga4-account-name')
        .name('Account Name')
        .makeRow((data) => {
            return data.input.location.analytics4Account?.displayName
        })
        .toString((value) => {
            return value ?? 'Not connected'
        }),
    ga4ReportCategory
        .id('ga4-data-filters')
        .name('Data Filters')
        .makeRow((data) => {
            return data.input.location.analytics4QueryFilters
        })
        .toString(arrayToString(getDataFilterCopy)),
]

export default ga4Columns
