
import { AiFillCheckCircle, AiFillInfoCircle, AiFillWarning } from "react-icons/ai";
import { MdError } from "react-icons/md";

export const getToastIcon = (type) => {
    switch (type) {
      case 'info':
        return <AiFillInfoCircle />
      case 'error':
        return <MdError />
      case 'warning':
        return <AiFillWarning />
      case 'success':
        return <AiFillCheckCircle />
      default:
        return false;
    }
  }