import { useState } from "react";

const useSteps = () => {

    // Handles Navigating Between Different Steps in a MultiStepForm flow
    const [currentStep, setCurrentStep] = useState(0);
    // Handles disabling Submit button
    const [submitted, setSubmitted] = useState(false)

    const goNext = () => setCurrentStep(currentStep + 1);

    const goPrev = () => setCurrentStep(currentStep - 1);

    const goToStep = (stepNumber) => setCurrentStep(stepNumber);

    return {
        currentStep, setCurrentStep, goNext, goPrev, goToStep,
        submitted, setSubmitted
    }
}

export default useSteps
