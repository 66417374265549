import styled from 'styled-components';

export const StyledProgressBar = styled.div`
    height: 20px;
    margin: 5px 0px;
    border-radius: 10px;
    background-color: ${props => props.theme.color.backgroundColor2};
    border: 1px solid ${props => props.theme.color.accentColor3};
    overflow: clip;

`

export const StyledBar = styled.div`
    height: 100%;
    width: ${ props => props.width ? props.width : '0%'};
    background-color: ${props => props.theme.color.accentColor3};
    ${({width}) => width === "100%" && "border-radius: 10px"}
`