import React, { CSSProperties, HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { createBoxShadow } from "../../styles/card"
import { XS } from "../../styles/typography";
import { FiChevronUp } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import fbIcon from '../../assets/images/fbicon.png'
import googleIcon from '../../assets/images/gicon.png'
import { gsap } from 'gsap'
import { ExperimentName } from "../../helpers/experimentHelpers";
import { useDispatch } from "react-redux";
import { toggleExperiment } from "../../actions/userActions";
import useExperiment from "../../hooks/useExperiment";
import { Folder, Tab } from "../../hooks/useSidebarPages";

//* Navbar Container
const StyledNavbarContainer = styled.nav`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 210px;
    padding-top: 40px;
    position: sticky;
    top: 0;
    min-width: 200px;
    overflow-x: hidden;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    background-color: ${props => props.theme.color.navbarBackgroundColor};
    box-shadow:${createBoxShadow};
    color: ${props => props.theme.color.navbarTextColor};

    .current p {
        color: ${props => props.theme.color.navbarActiveTextColor};
        font-weight: 700;
    }
`

//* Logo Container
const StyledLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

//* Logo
const StyledLogo = styled.img`
    width: 100%;
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
`

//* Section
const StyledSection = styled.div`
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
`

//* Tab
type TabLinkProps = {
    tab: Tab;
    underline?: boolean;
    style?: CSSProperties;
    children?: ReactNode;
} & HTMLAttributes<HTMLElement>

const StyledTabLink = styled(NavLink)`
    display: flex;
    alignItems: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    width: 100%;

    svg {
        width: 16px;
        height: 16px;
    }

    &:hover p {
        color: ${props => props.theme.color.navbarActiveTextColor};
    }
`

const TabLink = ({ tab, underline=false, style, children, ...rest } : TabLinkProps) => {
    const location = useLocation()

    return (
        <StyledTabLink to={tab.path} style={style} activeClassName="current" {...rest}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <tab.icon $active={location.pathname.includes(tab.path)} />
                <XS underline={underline} color='navbarTextColor'>{tab.name}</XS>
            </div>
            {children}
        </StyledTabLink>
    )
}

//* Folder
type CollapsableFolderProps = {
    page: Folder;
    isExpanded: boolean;
    setIsExpanded: () => void;
    style?: CSSProperties;
} & HTMLAttributes<HTMLDivElement>

const StyledCollapsableFolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
`

const StyledFolderTitle = styled(XS)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
`

const StyledDropdown = styled.div<{ isExpanded?: boolean }>`
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${props => props.theme.color.navbarTextColor};
    margin-left: 0.5rem;
`

const CollapsableFolder = ({ page, isExpanded, setIsExpanded, style, ...rest }: CollapsableFolderProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null)
    const tabRef = useRef<HTMLParagraphElement>(null)

    useEffect(() => {
        const timeline = gsap.timeline()
        const dropdown = dropdownRef.current
        const tab = tabRef.current

        if (!dropdown || !tab) return;

        if (isExpanded) {
            timeline
                .to(dropdown, { height: 'auto', duration: 0.25, overflow: 'hidden' }, 0)
                .to(tab, { gap: '0.5rem', duration: 0.25 }, 0)
        } else {
            timeline
                .to(dropdown, { height: 0, duration: 0.25, overflow: 'hidden' }, 0)
                .to(tab, { gap: '0rem', duration: 0.25 }, 0)
        }

        return () => {
            timeline.kill()
        }
    }, [isExpanded]);


    return (
        <StyledCollapsableFolder ref={tabRef} style={style} onClick={setIsExpanded} {...rest}>
            <StyledFolderTitle uppercase semibold color="navbarTextColor">
                {page.name}
                <FiChevronUp style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s ease' }}/>
            </StyledFolderTitle>
            <StyledDropdown isExpanded={isExpanded} ref={dropdownRef}>
                {
                    page.tabs.map((tab: Tab) => (
                        <TabLink
                            style={{ paddingLeft: "1rem"}}
                            onClick={(e) => e.stopPropagation()}
                            key={tab.name}
                            tab={tab}
                        />
                    ))
                }
            </StyledDropdown>
        </StyledCollapsableFolder>
    )
}

//* Network Provider
type NetworkProviderProps = {
    user: any
    style?: CSSProperties
} & HTMLAttributes<HTMLDivElement>

const StyledNetworkProvider = styled.div`
    display: flex;
    gap: 0.5rem;

    img {
        width: 16px;
        height: 16px;
    }
`

const NetworkProviderIcon = ({ user, style, ...rest }: NetworkProviderProps) => {
    return (
        <StyledNetworkProvider style={style} {...rest}>
            {user.providerId === "facebook.com" && <img src={fbIcon} alt="facebookIcon" />}
            {user.providerId === "google.com" && <img src={googleIcon} alt="googleIcon" />}
        </StyledNetworkProvider>
    )
}

//* Experiment Container
const StyledExperimentTab = styled(XS)`
    display: flex;
    alignItems: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
    user-select: none;

    svg {
        width: 16px;
        height: 16px;
    }
`

const ExperimentFolder = ({ experiments } : { experiments: ExperimentName[] }) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const tabRef = useRef<HTMLParagraphElement>(null)

    const handleDropDownAnimation = () => {
        //* we need to animate both the dropdown menu AND the gap that's under the tab container
        const timeline = gsap.timeline()
        const dropdown = dropdownRef.current
        const tab = tabRef.current

        if (!dropdown || !tab) return null;

        if (isExpanded) {
            timeline
                .to(tab, { gap: '0rem', duration: 0.25 }, 0)
                .to(dropdown, { height: 0, duration: 0.25, overflow: 'hidden' }, 0);
        } else {
            timeline
                .to(tab, { gap: '0.5rem', duration: 0.25 }, 0)
                .to(dropdown, { height: 'auto', maxHeight: '250px', duration: 0.25, overflow: 'scroll' }, 0)
        }

        setIsExpanded(prev => !prev)
    }

    return (
        <StyledCollapsableFolder ref={tabRef}>
            <StyledFolderTitle
                onClick={handleDropDownAnimation}
                color="navbarTextColor"
            >
                Experiments
                <FiChevronUp style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s ease' }}/>
            </StyledFolderTitle>
            <StyledDropdown ref={dropdownRef} style={{ maxHeight: '250px', overflow: 'scroll' }}>
                {
                    experiments.map((experiment: ExperimentName) => <ExperimentTab key={experiment} experiment={experiment} />)
                }
            </StyledDropdown>
        </StyledCollapsableFolder>
    )
}

const ExperimentTab = ({ experiment } : { experiment: ExperimentName }) => {
    const _D = useDispatch()
    const exp = useExperiment(experiment)

    return (
        <StyledExperimentTab
            style={{ paddingLeft: '1rem' }}
            color={exp.decide("activeButtonColor", "errorNegativeButtonColor")}
            semibold={exp.active}
            onClick={() => _D(toggleExperiment(experiment))}
        >
            {experiment}
        </StyledExperimentTab>
    )
}

//* DevToolTab
const StyledDevToolTab = styled(StyledExperimentTab)`
    color: ${props => props.theme.color.navbarTextColor};

    &:hover {
        color: ${props => props.theme.color.accentColor2};
    }
`

export const Navbar = {
    Container: StyledNavbarContainer,
    LogoContainer: StyledLogoContainer,
    Logo: StyledLogo,
    Section: StyledSection,
    TabLink,
    CollapsableFolder,
    NetworkProviderIcon,
    ExperimentFolder,
    DevToolTab: StyledDevToolTab
}