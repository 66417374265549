import React, { useState } from 'react'

import Modal from '../modal/Modal'

import { StyledConfirm } from './styles';
import { DangerBtn, ConfirmBtn, OutlineBtn, ButtonContainer } from '../../styles/buttons';
import { Body } from '../../styles/typography';

// so that the implementation can be moer flexible
const Confirm = props => {
    const { width, header, text, img, confirm, cancel, onClose, reject, buttonStyles, buttonColor, svg } = props;
    const [confirmClicked, setConfirmClicked] = useState(false);
    const [rejectClicked, setRejectClicked] = useState(false);

    return (
        <Modal
            title={ header ? header : ''}
            width={ props.children ? width : '500px' }
            onClose={onClose}>
            <StyledConfirm>
                {
                    img && <img className='templateImg' src={img} alt={''}/>
                }
                {
                    svg && svg
                }
                {
                    text && <Body>{text}</Body>
                }
                {
                    props.children && <>{props.children}</>
                }
                <ButtonContainer>
                    {
                        cancel &&
                        <OutlineBtn onClick={cancel.callback ? cancel.callback : onClose} style={{...buttonStyles}}>
                            {cancel.text ? cancel.text : 'Cancel'}
                        </OutlineBtn>
                    }
                    {
                        confirm &&
                        <ConfirmBtn
                            disabled={confirmClicked}
                            onClick={() => {
                                setConfirmClicked(true)
                                confirm.callback?.();
                            }}
                            style={{...buttonStyles ,...buttonColor}}>
                                { confirm.text ? confirm.text : 'Confirm' }
                        </ConfirmBtn>
                    }
                    {
                        reject &&
                        <DangerBtn
                            disabled={rejectClicked}
                            onClick={() => {
                                setRejectClicked(true)
                                reject.callback?.()
                            }}
                            style={{...buttonStyles}}>
                                { reject.text ? reject.text : 'Reject' }
                        </DangerBtn>
                    }
                </ButtonContainer>
            </StyledConfirm>
        </Modal>
    )
}

export default Confirm
