import { API } from '../constants/api';
import Regex from '../constants/regex';
import { ENVIRONMENTS, getEnvironmentType } from '../features/environment/util';
import { findString } from './array.util';
import { checkAllPostsFailed, isAdEntity, isCreativeCloneEnabled, isExpiredPost, isPostEntity } from './creative.util';
import { dayToStr, gmtDateString } from './date.util';

export const isLocalhost = () => {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
}

export const getSrvPath = blobKey => {
  let url = isLocalhost() ? 'https://eulerity-tester.appspot.com/srv/' : '/srv/'
  if (blobKey) url += blobKey;
  return url;
}

export const getImageUrl = blobKey => {
  const environmentType = getEnvironmentType();
  if (environmentType === 'development') return `https://eulerity-tester.appspot.com/srv/${blobKey}`
  return `${window.location.origin}/srv/${blobKey}`
}

export const getPostUrl = uploadUrl => {
  // For Local Environment, use relative path
  let url = isLocalhost() ?  uploadUrl.replace("https://eulerity-tester.appspot.com/", "/") : uploadUrl;
  console.log('post url: ',url);
  return url;
}

export const getBaseUrl = () => {
  const environmentType = getEnvironmentType();
  if (environmentType === ENVIRONMENTS.DEVELOPMENT || environmentType === ENVIRONMENTS.TEST) return 'https://eulerity-tester.appspot.com'
  if (environmentType === ENVIRONMENTS.STAGING) return 'https://eulerity-staging.appspot.com'
  return 'https://lnrd.eulerity.com'
}

export const calculateCTR = (impressions, clicks) => impressions > 0 ? (clicks*100/impressions) : 0; //! THIS IS ALREADY CALCULATED AS A PERCENTAGE
export const calculateCPM = (impressions, cost) => impressions > 0 ? (cost*1000/impressions) : 0;
export const calculateCPC = (clicks, cost) => clicks > 0 ? (cost/clicks) : 0;

/**
 * Takes a location raw key with a parentKey of user
 * @param locationRaw
 * @returns {string}
 */
export const getLocationKeyRaw = (locationRaw) => {
  return `${locationRaw.parentKey.name}-${locationRaw.id}`
}

export const getLocationKey = (location, delim = '-') => {
  if (!location) return null;
  return `${location.user?.raw?.name}${delim}${location.id}`;
};

export const getLocationByKey = (locations, key, defaultLoc) => {
  const foundLocation = (defaultLoc ? locations.concat(defaultLoc) : locations).find(l => {
    return `${l.user.raw.name}-${l.id}` === key || `${l.user.raw.name}_${l.id}` === key
  })
  return foundLocation;
}

export const getAudienceKey = (audience) => {
  return `${audience.location.raw.parentKey.name}-${audience.location.raw.id}-${audience.id}`;
}

export const getAudienceKeyRaw = (audienceRaw) => {
  return `${audienceRaw.parentKey.parentKey.name}-${audienceRaw.parentKey.id}-${audienceRaw.id}`;
}

export const getAudienceByKey = (locations, key, defaultLoc) => {
  let foundAudience = null;
  (defaultLoc? locations.concat(defaultLoc) : locations).forEach(l => {
    if (foundAudience || !l.audiences) return;
    let audience = l.audiences.find(a => {
      let base = getAudienceKey(a);
      let key1 = base.replaceAll('_', '-')
      let key2 = base.replaceAll('-', '_')
      return key1 === key || key2 === key;
    })
    if (audience) foundAudience = audience;
  });
  return foundAudience;
}

export const getCreativeLocationKeyRaw = creativeRaw => `${creativeRaw.parentKey.parentKey.name}-${creativeRaw.parentKey.id}`

export const isLocationMatch = (a, b) => `${a?.user.raw.name}-${a?.id}` === `${b?.user.raw.name}-${b?.id}`
export const isLocationKeyMatch = (key, location) => {
  const { user, id } = location;
  return `${user.raw.name}-${id}` === key || `${user.raw.name}_${id}` === key
}

export const strToCSVArray = (str) => {
  if (!str?.length) return [];

  return str
    .replace(/\n/g, ",") //replace new lines with commas
    .split(",") //separate by commas and turn into an array
    .map(function (str) {
      return str.trim(); //get rid of whitespace in start/end of strings
    })
    .filter(function (str) {
      return str !== ""; //get rid of empty strings
    });
};

export const hasActiveSubscription = location => {
  return (
    location?.subscriptions?.length > 0 &&
    location?.subscriptions[0].status === 'ACTIVE' &&
    location?.subscriptions[0].chargedMonthlyUsd > 0
  )
};

export const hasProfileWithSubscription = (location, allLocations) => {
  const aProfileWithSubscription = allLocations.find(loc => {
    return (
      loc.primaryLocation && //is a profile
      isLocationKeyMatch(getLocationKeyRaw(loc.primaryLocation.raw), location) && // its primary location is this location
      loc.subscriptions.length > 0 // it has had a subscription
    )
  })
  return aProfileWithSubscription;
};

export const validateLabelInput = (
  inputValue,
  currentValues,
  franchiseLabels
) => {
  let illegalChars = /[#$^*;()"{}|<>]/g;
  if (inputValue === "") {
    return "Label cannot be blank";
  } else if (inputValue.trim().length === 0) {
    return "Label may not contain only whitespace";
  } else if (illegalChars.test(inputValue)) {
    return `Labels may not contain the following characters: #$^*();{}|<>"`;
  } else if (currentValues.includes(inputValue)) {
    return "Label already selected";
  } else if (findString(franchiseLabels,inputValue,true)) {
    return "This label is already available";
  }
  return ""; //no errors
};

export const gmtToLocaleString = (datetime, format = 'datetime') => {
  // Use this to display the datetimes from the backend (GMT) in the user's local timezone

  // Check if this datetime has GMT in it
  const dateTimeGMT = gmtDateString(datetime)

  // Create new date object from this
  let date = new Date(dateTimeGMT)

  // Return the date to the user's locale timezone
  let options = { dateStyle: "medium", timeStyle: "long" };
  if (format === 'date') options = { dateStyle: "medium" };
  if (format === 'time') options = { timeStyle: "long" };
  if (format === 'short') options = { month: 'short', day: 'numeric', year: 'numeric'}
  if (format === 'shortTime') options = { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }
  if (format === 'none') options = {} // this is used when passing to Date constructor

  return `${date.toLocaleString("en-US", options)}`;
}

export const b64ToUint8Array = (b64Image) => {
  var img = atob(b64Image.split(",")[1]);
  var img_buffer = [];
  var i = 0;
  while (i < img.length) {
    img_buffer.push(img.charCodeAt(i));
    i++;
  }
  return new Uint8Array(img_buffer);
};

export const getYoutubeID = url => {
  if (!url?.length) return null;
  let videoMatch = url.match(Regex.YOUTUBE_VIDEO_URL);
	if (videoMatch && videoMatch[1]) {
    return videoMatch[1].length < 11 ? undefined : videoMatch[1]
  }
}

export const getBlob = (blobKey, callback) => {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', '/srv/' + blobKey)
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (callback) {
        callback(xhr.responseText);
      }
    }
  }
  xhr.send()
}



export const compareDates = (a, b) => {
  if (a.date < b.date) return -1;
  if (a.date > b.date) return 1;
  return 0;
}

export const getCreativeReportData = (resp, creative) => {
  if (!resp) return [];
  const { id, kind } = creative;
  let creativeReports = kind === 'VideoCreative' ? resp.videoData[id] : resp.data[id];

  if (!creativeReports) return [];
      let nowStr = '';
      if (creativeReports.length > 2) {
        let min = creativeReports[0].date;
        let now = Date.now();

        do {
          now -= (24 * 60 * 60 * 1000);
          let nowDate = new Date(now);
          nowStr = dayToStr(nowDate);

          if (!creativeReports.find(creative => creative.date === nowStr)) {
            creativeReports.push({
              date: nowStr,
              impressions: 0,
              clicks: 0,
              leads: 0
            })
          }
      } while (nowStr > min);
  }

  return creativeReports;
}

export const fillCreativeReports = data => {
  let today = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  let oneWeekAgo = new Date(today);
  let oneMonthAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  oneMonthAgo.setDate(today.getDate() - 30);

  let oneMonthAgoStr = dayToStr(oneMonthAgo);
  let oneWeekAgoStr = dayToStr(oneWeekAgo);

  const creativeObj = {
    ads7Days: 0,
    ads30Days: 0,
    ads90Days: 0,
    clicks7Days: 0,
    clicks30Days: 0,
    clicks90Days: 0,
    CTR7Days: 0,
    CTR30Days: 0,
    CTR90Days: 0,
    leads7Days: 0,
    leads30Days: 0,
    leads90Days: 0
  }

  let weekFlag = false;
  let monthFlag = false;

  for (let i = data.length - 1; i >= 0; i--) {
    creativeObj.ads90Days += data[i].impressions || 0;
    creativeObj.clicks90Days += data[i].clicks || 0;
    creativeObj.leads90Days += data[i].leads || 0;

    if (!weekFlag) {
      creativeObj.ads7Days += data[i].impressions || 0;
      creativeObj.clicks7Days += data[i].clicks || 0;
      creativeObj.leads7Days += data[i].leads || 0;
    }

    if (data[i].date === oneWeekAgoStr) weekFlag = true;

    if (!monthFlag) {
      creativeObj.ads30Days += data[i].impressions || 0;
      creativeObj.clicks30Days += data[i].clicks || 0;
      creativeObj.leads30Days += data[i].leads || 0;
    }

    if (data[i].date === oneMonthAgoStr) monthFlag = true;
  }


  creativeObj.CTR7Days = Number((100 * (creativeObj.clicks7Days / creativeObj.ads7Days)).toFixed(2));
  creativeObj.CTR30Days = Number((100 * (creativeObj.clicks30Days / creativeObj.ads30Days)).toFixed(2));
  creativeObj.CTR90Days = Number((100 * (creativeObj.clicks90Days / creativeObj.ads90Days)).toFixed(2));

  for(const report in creativeObj) {
    if (creativeObj[report]) creativeObj[report] = Number(creativeObj[report]).toLocaleString();
  }

  return creativeObj;
}

export const staggerApiCalls = (calls, delay) => {
  if (!calls.length) return;
  const writeDelay = 500;
	const readDelay = 250; //default

	let callDelay = readDelay;
  if (typeof delay === 'number') callDelay = delay;
  if (delay === 'write') callDelay = writeDelay;
  if (delay === 'read') callDelay = readDelay;

	for (let count = 0; count < calls.length; count++) {
		setTimeout(calls[count].bind(null, count), callDelay * count)
	}
}

export const ChartHelper = {
  //For an array of arrays - hides columns specified from columnIdxArr
  hideDataColumns: (data, columnIdxArr) => {
      if (!data.length) return;
      let numberOfColumns = data[0].length;
      let filteredDataView = data.map(item => {
          let filteredDataRow = [];
          for(let i = 0; i < numberOfColumns; i++){
              if (!columnIdxArr.includes(i)) filteredDataRow.push(item[i])
          }
          return filteredDataRow;
      })
      return filteredDataView;
  },
  getBucketSize: (valuesArray, size = 5) => {
    //workaround for:  google charts bug with single bin having only one value
    //https://github.com/google/google-visualization-issues/issues/2758
    //ignore bucketSize if this is the case
    //because this is apparently still an issue 2 years later ):<
    return [...new Set(valuesArray)].length > 1 ? size : null
  },
  // This will take the filteredDataView array, an manually replace the filteredDataView[i][0] (where i > 0)
  // with the combined name and nickname columns of the data passed in (format --- data[i][0] (data[i][1])).
  // !-- Ensure that the location name and nicknames are in indices 0 and 1 respectively
  hideDataColumnsAndCombineName: function(data, columnIdxArr) {
      if (!data.length) return;
      const NAME_INDEX = 0;
      const NICKNAME_INDEX = 1;
      let filteredDataView = this.hideDataColumns(data, columnIdxArr);
      for (let i = 1; i < filteredDataView.length; i++) {
        filteredDataView[i][NAME_INDEX] = `${data[i][NAME_INDEX]} (${data[i][NICKNAME_INDEX]})`
      }
      return filteredDataView;
  }
}

export const DateHelper = {
  getFullMonth: d => {
    // ensures human readable month number from date object is 2 digits
    return (d.getMonth()+1).toString().length !== 1 ?  d.getMonth()+1 : `0${d.getMonth()+1}`
  },
  getFullDate: d => {
    // ensures human readable date number from date object is 2 digits
    return d.getDate().toString().length !== 1 ?  d.getDate() : `0${d.getDate()}`
  },
  isoToYYMMDD: (isoDate, delim = '-') => {
    // converts 20210826 to 2021-08-26
    const year = isoDate.substring(0,4);
    const month = isoDate.substring(4,6);
    const day = isoDate.substring(6,8);
    return `${year+delim+month+delim+day}`
  },
  dateToYYMMDD: (d, format, delim = '-') => {
    // converts date object to yyyy-mm-dd
    let month = DateHelper.getFullMonth(d)
    let day = DateHelper.getFullDate(d)
    if (format === 'yyyy-mm-dd') {
      return `${d.getFullYear()}${delim}${month}${delim}${day}`
    }
    return `${d.getFullYear()}${month}${day}`
  },
  getStringFromDate: (d, format, delim = '') => {
    const dateItemOrderArray = [...new Set(format.toLowerCase().split(''))]
    const extractors = {
        'y': d.getFullYear(),
        'd': DateHelper.getFullDate(d),
        'm': DateHelper.getFullMonth(d),
    }
    const dateItems = dateItemOrderArray.map(item => extractors[item])
    return dateItems.join(delim)
  },
  getPastDatesArray: (days, format, delim = '-') => {
    let arr = [];
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    for(let i = 0; i < days; i++){
      date.setDate(date.getDate() - 1);
      let d = date;
      let month = DateHelper.getFullMonth(d)
      let day = DateHelper.getFullDate(d)
      if (format === 'yyyy-mm-dd') {
        d = `${d.getFullYear()}${delim}${month}${delim}${day}`
      }
      if (format === 'yyyymmdd') {
        d = `${d.getFullYear()}${month}${day}`
      }
      arr.push(d);
    }
    return arr;
  },
  getStartOfDay: (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  },
  getEndOfDay: (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  },
}

export const getDateLabel = (creative) => {
  let rawDate = creative.created;
  let label = 'Created';

  const isPost = isPostEntity(creative);
  if (isPost) {
    if (checkAllPostsFailed(creative)) {
      rawDate = creative.postedDate
      label = 'Post Attempt'
    } else if (creative.postedDate) {
      rawDate = creative.postedDate
      label = 'Posted'
    } else if (creative.start) {
      rawDate = creative.start;
      label = 'Post';
    }
  }

  return `${label}: ${gmtToLocaleString(rawDate, 'date')}`
};

export const getFolderDateLabel = creative => {
  const { start, postedDate, end } = creative

  if (isPostEntity(creative)) {
    if (isExpiredPost(creative)) {
      return postedDate ? `Published on: ${gmtToLocaleString(postedDate, 'date')}` : ''
    } else if (isCreativeCloneEnabled(creative) && start) {
      return `Publish on: ${gmtToLocaleString(start, 'date')}`
    }
  }

  if (isAdEntity(creative)) {
    if (start) {
      return `Active on: ${gmtToLocaleString(start, 'date')} `
    } else if (end) {
      return `Active until: ${gmtToLocaleString(end, 'date')} `
    }
  }

  return ''
}

export const changeDateTime = (scheduled, newDate, modifyDate) => {
  let date = scheduled ? new Date(scheduled) : new Date();

  if (!newDate || newDate.getTime() < new Date().getTime())
    newDate = new Date();
  if (modifyDate) {
    date.setDate(newDate.getDate());
    date.setMonth(newDate.getMonth());
    date.setFullYear(newDate.getFullYear());
    if (!scheduled) {
      let roundedTimes = roundTime(date, 60);
      date.setHours(roundedTimes[0]);
      date.setMinutes(roundedTimes[1]);
      date.setSeconds(0);
    }
  } else {
    let roundedTimes = roundTime(newDate, 60);
    date.setHours(roundedTimes[0]);
    date.setMinutes(roundedTimes[1]);
    date.setSeconds(0);
  }
  return date.getTime();
}

export const roundTime = (time, increment=15) => {
  let hour = time.getHours();
  let minute = Math.ceil(time.getMinutes() / increment) * increment;
  return [hour, minute];
}

export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const createID = () =>  {
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const isValidLatitude = lat => Math.abs(lat) <= 90;
export const isValidLongitude = lng => Math.abs(lng) <= 180;

export const displayBigNumber = (num) => {
  if(typeof num === 'number') return num.toLocaleString();
  let parsedInt = parseInt(num)
  if(isNaN(parsedInt)) return 0
  return parsedInt.toLocaleString();
}

export const simpleUid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export const delay = (ms, cb) => new Promise(resolve => {
  setTimeout(() => {
    resolve(cb?.())
  }, ms)
})


export const copyToClipboard = (text, cb) => {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
  cb?.();
};

//
export const callUpdateFranchise = (data, franchiseName)  =>  {
  return new Promise((resolve,reject) => {
   window.eulerity.makeApiCall(`${API.FRANCHISE.UPDATE_FRANCHISE}?franchise=` + encodeURIComponent(franchiseName), 'POST', data, response => {
       resolve(response)
   },{1000: reject})
  })

}
export const getEntityCreatedDate = (entity) => {
  const d = gmtToLocaleString(entity.created)

  return d.substring(0, d.lastIndexOf(','))

}
export function selectFromObject(obj, ...keys) {
  return keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});
}

export const bytesToMegaBytes = bytes => bytes / (1024*1024)