import { toast } from 'react-toastify';
import { API } from '../constants/api';
import { ADD_BLUEPRINT, UPDATE_BLUEPRINT } from './types';
import { makeRequest } from '../helpers/make-request';

export const createBlueprint =
    ({ obj, callback }) =>
    (dispatch) => {
        makeRequest()
            .url(API.BLUEPRINTS.CREATE)
            .body(obj)
            .post()
            .then((response) => {
                dispatch({
                    type: ADD_BLUEPRINT,
                    payload: response,
                });

                callback?.(response);
            })
            .catch((err) => {
                console.error(err);
                console.error('Unable to create blueprint', JSON.parse(err.response).message);
            });
    };

export const editBlueprint =
    ({ id, updates, callback, errorCallback }) =>
    (dispatch) => {
        makeRequest()
            .url(API.BLUEPRINTS.UPDATE)
            .param('profileTemplateId', id)
            .body(updates)
            .retry(3)
            .post()
            .then((response) => {
                dispatch({
                    type: UPDATE_BLUEPRINT,
                    payload: response,
                });
                callback?.(response);
            })
            .catch((err) => {
                console.error(err);
                console.error('Unable to update blueprint:', JSON.parse(err.response).message);
                toast.error('Something went wrong. Please try again.');
                errorCallback?.(err);
            });
    };
