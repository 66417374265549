import styled from 'styled-components';
import { decideTextColor } from '../../helpers/colors.util';

export const BannerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.color.accentColor2};
    color: ${props => decideTextColor(props.theme.color.accentColor2, [props.theme.color.textColor1, props.theme.color.textColor3])};
    z-index: 1;
    padding: 1.5rem 2rem;
    gap: 2rem;
`;

export const BannerLayout = styled.div<{hasMultiple?: boolean}>`
    display: flex;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    gap: 1.5rem;
    min-height: 4rem;
    padding: 0px ${props => props.hasMultiple ? '2rem': '3.5rem'};
    svg {
        transition: opacity 0.3s ease-in-out;
    }
`

export const BannerIcon = styled.div`
    width: 40px;
    height: 40px;
    svg {
        width: 100%;
        height: 100%;
    }
    path {
        stroke: ${props => props.theme.color.textColor3};
    }
`

export const BannerInformation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 52px;
    .banner-text {
        margin: 0px 1.5rem 0px 1.5rem;
    }
`

export const BannerCarouselIndicator = styled.div`
    display: flex;
    justify-content: center;
    gap: 4px;
    span {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        transition: opacity 0.3s ease-in-out;
    }
`