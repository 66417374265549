/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import likeIcon from "../../assets/images/likeIcon.png";
import retweetIcon from "../../assets/images/retweetIcon.png";
import uploadIcon from "../../assets/images/uploadIcon.png";
import commentsIcon from "../../assets/images/commentsIcon.png";

import {
  SocialWrap,
  SocialPreviewCopyHeader,
  TwitterContentWrap,
} from "./styles";
import { H2 } from "../../styles/typography";
import { getPreviewImageUrl } from "../../helpers/creative.util";
import TwitterXIcon from "../../assets/icons/TwitterXIcon";
import { PREVIEW_PLACEHOLDER_TEXT } from "../../helpers/constants";

const TwitterPreview = ({ creative, previewText }) => {
  const { preview, youTubeId } = creative;
  const {
    twitterDescription, facebookDescription
  } = previewText;

  return (
    <SocialWrap>
      <SocialPreviewCopyHeader>
        <TwitterXIcon size={24} />
        <H2>X Preview</H2>
      </SocialPreviewCopyHeader>
      <TwitterContentWrap>
        <div className="twitter-box">
          <div className="logo-wrapper">
            <div className="logo-container">
            <div className="placeholder-img" />
            </div>
            {
              twitterDescription.endsWith('...') &&
              <div className="thread-container">
                <div className="thread-line"></div>
                <div className="logo-container" style={{height: '16px', width: '16px', margin: 'auto'}}>
                <div className="placeholder-img" />
                </div>
              </div>
            }
          </div>
          <div className="twitter-content">
            <div className="top-section">
              <div className="page-info">
                {/* backend to change the default twitter page name and handle */}
                <strong>{PREVIEW_PLACEHOLDER_TEXT.XPageName}</strong>
                <span>{PREVIEW_PLACEHOLDER_TEXT.XHandler}</span>
              </div>
              <div className="actions">
                <FaEllipsisH size={"10px"} style={{ marginBottom: "auto" }} />
              </div>
            </div>
            <div className="text-content">{twitterDescription || facebookDescription}</div>
            <img className="selected-image" src={getPreviewImageUrl({preview, youTubeId})} alt="twitter-preview-img"/>
            <div className="twitter-icons">
              <img src={commentsIcon} alt="comment-icon" />
              <img src={retweetIcon} alt="retweet-icon" />
              <img src={likeIcon} alt="like-icon" />
              <img src={uploadIcon} alt="upload-icon" />
            </div>
            {twitterDescription.endsWith('...') &&
              <div className="threadText">Show This Thread</div>
            }
          </div>
        </div>
      </TwitterContentWrap>
    </SocialWrap>
  );
};

export default TwitterPreview;
