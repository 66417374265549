import columnBuilder from "../../../columnBuilder";
import { getDayReport, numberAggregator, reduceNumberArray } from "../shared";
import { reportLoader } from '../../../loaders';

const impressionsReportCategory = columnBuilder()
	.category('performance')
	.subCategory('Impressions')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.subCategory}: ${meta.name}`
	})

const impressionsFormatter = (value: number | undefined) => {
	if (value === undefined) return "N/A"
	return value.toFixed(0)
}

const impressionsColumns = [
	impressionsReportCategory
		.id('impressions-total')
		.name('Total')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			if (!targetDate) return
			const total = reduceNumberArray(Object.values(targetDate.channelMetrics || {}).map(channel => channel.impressions))
			return total
		})
		.aggregateRow(numberAggregator)
		.toString(impressionsFormatter),
	impressionsReportCategory
		.id('impressions-by-display')
		.name('Display')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.display?.impressions
		})
		.aggregateRow(numberAggregator)
		.toString(impressionsFormatter),
	impressionsReportCategory
		.id('impressions-by-search')
		.name('Search')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.search?.impressions
		})
		.aggregateRow(numberAggregator)
		.toString(impressionsFormatter),
	impressionsReportCategory
		.id('impressions-by-social')
		.name('Social')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.social?.impressions
		})
		.aggregateRow(numberAggregator)
		.toString(impressionsFormatter),
	impressionsReportCategory
		.id('impressions-by-video')
		.name('Video')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.video?.impressions
		})
		.aggregateRow(numberAggregator)
		.toString(impressionsFormatter),
]

export default impressionsColumns