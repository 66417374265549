import React from "react"
import { toast } from "react-toastify"
import Modal from "../../../../components/modal/Modal"
import { Show } from "../../../../components/reusableUI/components/Show"
import EntityResultsSelector from "../../../../features/entityResultsSelector/EntityResultsSelector"
import { OutlineBtn, PrimaryCTABtn } from "../../../../styles/buttons"
import { H2, Body } from "../../../../styles/typography"
import { ButtonContainer } from "../../../creativerepo/styles"
import { SelectLocations } from "../../sections/SocialPosting/locationSelector"
import { useInsightsReportBuilderContext } from '../InsightsReportBuilder'

export const SelectCampaigns = () => {

    const _S = useInsightsReportBuilderContext()
    const [isFilterOpen, setIsFilterOpen] = React.useState(false)

    return (
        <div>
            <H2>Select Campaigns</H2>
            <Body>Select all the campaigns you would like to generate data for.</Body>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '48px 0',
                }}
            >
                <div>Campaigns: </div>
                <SelectLocations state={_S} />
                <OutlineBtn
                    onClick={() => setIsFilterOpen(true)}
                >
                    Advanced Filter
                </OutlineBtn>
            </div>

            <Show when={isFilterOpen}>
                <Modal title="Filter">
                    {/* @ts-ignore */}
                    <EntityResultsSelector
                        onSelect={_S.locationSelectorController.setSelectedLocations}
                        options={_S.locationSelectorController.locationOptions}
                        value={_S.locationSelectorController.selectedLocations}
                    />
                    <ButtonContainer style={{
                        marginTop: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <PrimaryCTABtn
                            onClick={() => setIsFilterOpen(false)}
                        >
                            Done
                        </PrimaryCTABtn>
                    </ButtonContainer>
                </Modal>
            </Show>

            <ButtonContainer style={{
                paddingTop: '24px',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <OutlineBtn onClick={_S.goPrev}>Back</OutlineBtn>
                <PrimaryCTABtn onClick={() => {
                    if (_S.locationSelectorController.selectedLocations.length === 0) {
                        toast.error('Please select at least one campaign')
                        return
                    }
                    _S.goNext()
                }}>Confirm</PrimaryCTABtn>
            </ButtonContainer>
        </div>
    )
}