import React, {useMemo, useState} from 'react'

import CreativePreviewSlides from '../../../../components/previews/CreativePreviewSlides';
import { StyledStepReview, CreativeSummaryTable, StepReviewContainer } from '../styles';
import { CREATIVE_KIND, getCreativeKindLabel, getNetworkName } from '../../../../helpers/creative.util';
import SearchTextModal from '../SearchTextModal';
import { H2, Body, Small } from '../../../../styles/typography';
import { useSelector } from 'react-redux';
import { FORKS as _F, FORKS } from '../../../../constants/fork';
import useVersionData from '../../../../hooks/useVersionData';
import StringUtil from '../../../../helpers/string.util';
import PrimeTimeAlert from './PeakPostTime/PrimeTimeAlert';
import { extractDateData } from '../../../../helpers/date.util';
import Alert from '../../../../components/alert/Alert';
import { ALERT } from '../../../../components/alert/util';
import { IG_DISCLAIMER_COPY } from './constants';
import { InstagramIcon } from '../../../../assets/icons/InstagramIcon';

const dateFormatOptions = { dateStyle: 'medium', timeStyle: 'long' };

const CreativeStepReview = ({ isAd, isPost, context, sampleCreative, previewText }) => {
    const { formData } = React.useContext(context)
    const customOrPremade = useSelector(s => s.build.customOrPremade)
    const [showSearchText, setShowSearchText] = useState(false)
    const creationType = useSelector(s => s.build.creationType)

    const { defaultCta } = useVersionData()

    const printNetworks = () => {
        const targetNetworks = formData?.targetNetworks || []
        if (targetNetworks?.length === 0) return '';

        return targetNetworks.map(network => getNetworkName(network)).join(', ')
    }

    const hasVisibleText = textbox => textbox?.color?.a > 1

    const hasInstagram = formData?.targetNetworks?.includes('instagram');

    const postDate = useMemo(() => {
        if (!formData?.start && !formData?.peakStartDate) return 'ASAP'
        // * Check if exp is active AND the selected creative opt'd for prime post (still has not been posted)
        if (formData?.peakStartDate) {
            const { month, year, day } = extractDateData(formData?.peakStartDate)
            const peakDate = new Date(year, month, day)
            return peakDate?.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric'})
        }
        return formData?.start?.toLocaleString('en-US', dateFormatOptions)
    }, [formData])

    return (
        <StyledStepReview>
            <StepReviewContainer>
                {
                    previewText &&
                    <div style={{alignSelf:'flex-start'}}>
                        <CreativePreviewSlides
                            creative={sampleCreative}
                            previewText={previewText}
                            networks={formData?.targetNetworks}
                            location={formData?.selectedLocations?.length === 1 ? formData?.selectedLocations[0] : null}
                            hasInstagramCollaborators={formData?.instagramCollaborators?.length > 0}
                        />
                    </div>
                }
                <div>
                    <H2 regular center>Final Preview</H2>
                    <CreativeSummaryTable valign="top">
                        <tbody>
                            {
                                isAd && sampleCreative?.kind &&
                                <tr>
                                    <th>Ad Type:</th>
                                    <td>{getCreativeKindLabel(sampleCreative)}</td>
                                </tr>
                            }
                            {
                                sampleCreative?.kind === CREATIVE_KIND.CREATIVE &&
                                customOrPremade !== _F.PREMADE_CREATIVE &&
                                <>
                                    {
                                        hasVisibleText(sampleCreative?.header) &&
                                        <tr>
                                            <th>Header:</th>
                                            <td>{sampleCreative?.header?.text}</td>
                                        </tr>
                                    }
                                    {
                                        hasVisibleText(sampleCreative?.description) &&
                                        <tr>
                                            <th>Description:</th>
                                            <td>{sampleCreative?.description?.text}</td>
                                        </tr>
                                    }
                                </>
                            }
                            <tr>
                                <th>Social Text:</th>
                                <td className='social-text'>{sampleCreative?.extendedText || formData?.extendedText}</td>
                            </tr>


                            {
                                previewText?.youtubeHeadline && (
                                    <tr>
                                        <th>Youtube Headline</th>
                                        <td>{previewText?.youtubeHeadline}</td>
                                    </tr>
                                )
                            }

                            {
                                previewText?.youtubeDescription && (
                                    <tr>
                                        <th>Youtube Description</th>
                                        <td>{previewText?.youtubeDescription}</td>
                                    </tr>
                                )
                            }


                            {
                                formData?.keywordGroup?.name &&
                                <tr>
                                    <th>Ad Category:</th>
                                    <td>{formData.keywordGroup.name}</td>
                                </tr>
                            }
                            {
                                isAd &&
                                <tr>
                                    <th>CTA:</th>
                                    <td>{StringUtil.displaySnakeStr(formData?.cta || defaultCta)}</td>
                                </tr>
                            }
                            {
                                isAd &&
                                <tr>
                                    <th>Landing Page:</th>
                                    <td style={{wordBreak: 'break-all'}}>{formData?.landingPage || `[Campaign's Default Page]`}</td>
                                </tr>
                            }
                            <tr>
                                <th>{isAd ? 'Start' : 'Post'}:</th>
                                    <td>
                                        <div>
                                            <Body>{postDate}</Body>
                                            { formData?.peakStartDate && <PrimeTimeAlert />}
                                        </div>
                                    </td>
                            </tr>
                            {
                                isAd &&
                                <tr>
                                    <th>End:</th>
                                    <td>{formData?.end?.toLocaleString('en-US', dateFormatOptions) || 'Never'}</td>
                                </tr>
                            }

                            {
                                sampleCreative?.kind === CREATIVE_KIND.CREATIVE &&
                                <tr>
                                    <th>Search Headlines & Descriptions:</th>
                                    <td className='searchTextList' onClick={() => setShowSearchText(true)}>View List</td>
                                </tr>
                            }
                            {
                                isPost &&
                                <tr>
                                    <th>Platforms:</th>
                                    <td>{printNetworks()}</td>
                                </tr>
                            }
                            <tr>
                                <th>Campaigns:</th>
                                <td>
                                    <div className="locationsList">
                                        {
                                            formData?.selectedLocations?.filter(loc => !!loc)?.map(opt => {
                                                return (
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                                        <Body>
                                                            {
                                                                opt?.nickname ?
                                                                <p key={opt?.value}>{opt?.nickname}</p>
                                                                :
                                                                <p key={opt?.value}>{opt?.label}</p>
                                                            }
                                                        </Body>
                                                            {
                                                                opt?.businessInstagramUsername &&
                                                                <Small disabled style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                    <InstagramIcon width={14} height={14}/> @{opt.businessInstagramUsername}
                                                                </Small>
                                                            }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                            {isPost && hasInstagram && formData?.instagramCollaborators?.length > 0 &&
                                <tr>
                                    <th>Instagram Collaborators:</th>
                                    <td>
                                        {formData?.instagramCollaborators?.filter(collaborator => collaborator?.name?.length > 0).map((collaborator, idx) =>
                                            <Body key={collaborator.id}>
                                                {`@${collaborator.name}${idx !== formData?.instagramCollaborators?.length - 1 ? "," : ""}`}
                                            </Body>
                                        )}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </CreativeSummaryTable>
                    {
                        formData?.instagramCollaborators?.length > 0 && formData?.selectedLocations?.length > 1 &&
                        <Alert
                            type={ALERT.INFO.TYPE}
                            message={creationType === FORKS.OPTIONAL_CREATIVE ?
                                IG_DISCLAIMER_COPY.OPTIONAL_POST
                                :
                                IG_DISCLAIMER_COPY.AUTOMATIC_POST(formData?.selectedLocations?.length)
                            }
                            iconStyle={{ alignSelf: 'center' }}
                        />
                    }
                </div>
            </StepReviewContainer>
            { showSearchText && <SearchTextModal creative={sampleCreative} onClose={() => setShowSearchText(false)} />}
        </StyledStepReview>
    )
}

export default CreativeStepReview
