import { ExperimentName } from '../../../../helpers/experimentHelpers'
import { Franchise } from '../../../../types/franchise'
import { EulerityLocation } from '../../../../types/location'
import { requestManager } from '../../../../helpers/service/RequestManager'
import insightReportBuilderColumns, { ColumnOption } from './reportColumns'
import { isBatchifyRequest, isMakeRequest } from '../../../../types/api'
import priorityQueue from '../../../../helpers/structures/PriorityQueue'
import { InsightBuilderReportCategory } from '../Steps/SelectReportType'
import { transposeArray } from '../../../../helpers/array.util'
import { Experiment } from '../../../../hooks/useExperiment'
import { createDateRange } from '../../../../helpers/date.util'
import { FullUser } from '../../../../types/user'

export type ReportInput = {
    locations: EulerityLocation[]
    franchise: Franchise
    startDate: Date
    endDate: Date
    experiments: Map<ExperimentName, Experiment>
    role: "zor" | "zee"
    user?: FullUser
}

export type ReportRowInput = {
    location: EulerityLocation
    franchise: Franchise
    date: Date
    experiments: Map<ExperimentName, Experiment>
    getKey: () => string
}

export type InsightReportBuilderPartialIndex = (input: ReportRowInput) => string
export type InsightReportBuilderPartialColumnFilter = (column: ColumnOption) => boolean

export type InsightReportFormats = {
    [key in InsightBuilderReportCategory['id']]?: InsightReportBuilderFormat
}

export type InsightReportBuilderFormat = {
	partialColumnFilters: InsightReportBuilderPartialColumnFilter[]
	partialRowIndexGenerators: InsightReportBuilderPartialIndex[]
}

export type InsightReportBuilderGenerationOptions = {
    input: ReportInput
    columnFilter: (column: ColumnOption) => boolean
    makeRowKey: (input: ReportRowInput) => string
}

export type ColumnFilter = (col: ColumnOption) => boolean

const assignPriority = <T>(item: T) => {
    if (isMakeRequest(item)) {
        if (item._getOptions().url?.includes('report')) return 4
        return 1
    }
    if (isBatchifyRequest(item)) {
        return 0
    }
    return 3
}

type ReportBuilderOptions = {
    maxConcurrentRequests?: number
    onTaskAdded?: (task: any) => void
    onTaskComplete?: (task: any) => void
}

export type BuiltInsightReport = Awaited<ReturnType<ReturnType<typeof reportBuilder>['buildReports']>>
export type BuiltInsightReportCategory = NonNullable<BuiltInsightReport[InsightBuilderReportCategory['id']]>
export type BuiltInsightReportCell = BuiltInsightReportCategory[number][number]

export const reportBuilder = (opts?: ReportBuilderOptions) => {

    const manager = requestManager({
        maxConcurrentRequests: opts?.maxConcurrentRequests,
        onTaskAdded: opts?.onTaskAdded,
        onTaskComplete: opts?.onTaskComplete,
        queue: priorityQueue(assignPriority),
    })

    const makeReportRowInputs = (input: ReportInput): ReportRowInput[] => {
        return input.locations
            .map((location) => {
                return createDateRange(input.startDate, input.endDate).map(
                    (date) => {
                        return {
                            location,
                            franchise: input.franchise,
                            date,
                            experiments: input.experiments,
                            getKey: () =>
                                `${location.websafe}-${date.toISOString()}`,
                        }
                    }
                )
            })
            .flat()
    }

    const fetchReportData = async (
        input: ReportInput,
        columns: ColumnOption[],
    ) => {
        const reportInputs = makeReportRowInputs(input)

        const loaderCleanupPromises = []
        for (const column of columns) {
            loaderCleanupPromises.push(column.loadColumn(manager)(input))
            const rowLoader = column.loadRow(manager)
            for (const input of reportInputs) {
                loaderCleanupPromises.push(rowLoader(input))
            }
        }
        await manager.run()
        await Promise.all(loaderCleanupPromises)
    }

    const combinePartialColumnFilters = (
        filters: InsightReportBuilderPartialColumnFilter[],
    ) => {
        return (col: ColumnOption) => {
            return filters.every((fn) => fn(col))
        }
    }

    const buildReport = (input: ReportInput, format: InsightReportBuilderFormat) => {
        const filterColumn = combinePartialColumnFilters(format.partialColumnFilters)
        const getRowIndex = (input: ReportRowInput) => {
            return format.partialRowIndexGenerators
                .map((fn) => fn(input))
                .join('-')
        }

        const inputs = makeReportRowInputs(input)

        const report = []

        for (const column of insightReportBuilderColumns.filter(filterColumn)) {
            report.push([...column.makeColumn(inputs, getRowIndex)])
        }

        return transposeArray(report)
    }

    const buildReports = async (input: ReportInput, formats: InsightReportFormats) => {
        const reports: Partial<Record<InsightBuilderReportCategory['id'], ReturnType<typeof buildReport>>> = {}

        const columnMap = new Map<string, ColumnOption>()

        Object.values(formats).forEach((format) => {
            const filterColumn = combinePartialColumnFilters(format.partialColumnFilters)
            insightReportBuilderColumns.filter(filterColumn).forEach((column) => {
                columnMap.set(column.id, column)
            })
        })

        await fetchReportData(input, Array.from(columnMap.values()))

        Object.entries(formats).forEach(([reportType, format]) => {
            reports[reportType as InsightBuilderReportCategory['id']] = buildReport(input, format)
        })

        return reports
    }

    return {
        buildReports,
    }
}
