import React, { useMemo, useState } from 'react'
import { Body, HR, Small } from '../../styles/typography'
import styled from 'styled-components';
import { hexToRgba } from '../../helpers/colors.util';
import { ButtonContainer } from '../../styles/buttons';
import Button from '../../components/button/Button';
import SubscriptionWarningModal from './components/SubscriptionWarningModal';
import { TaskParams } from '../../features/backgroundJobs/types';
import { API } from '../../constants/api';
import { toast } from 'react-toastify';
import ToastMessage from '../../components/toast/ToastMessage';
import { useJob } from '../../features/backgroundJobs';
import { ApiData, SubscriptionHeaders } from './hooks/useBulkSubscription';
import { SUBSCRIPTION_HEADERS } from './csvHeaderConstants';
import getSuperTableComponents from '../../components/SuperTable/getSuperTableComponents';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
  border-radius: 0.5rem;
  padding: 1.5rem;
`

export const StyledTable = styled.div<{ boldNthLastRow?: number, maxHeight?: number }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
  border-radius: 0.5rem;
  border-collapse: 'collapse';
  ${props => `max-height: ${props.maxHeight || 340}px`};

  .supertable-header {
    border-bottom: 2px solid ${props => props.theme.color.accentColor3};
  }

  .supertable-row:not(:last-child) {
    border-bottom: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
  }

  .supertable-header, .supertable-row {
    padding: 1rem;
  }

  .supertable-row div span:nth-last-child(-n + ${props => props.boldNthLastRow}) {
    font-weight: 600;
  }
`

const BulkEditConfirmation = ({
  headers,
  setStep,
  setBulkAPICalls,
  bulkAPICalls
}: {
  headers: SubscriptionHeaders[];
  setStep : React.Dispatch<React.SetStateAction<"Form" | "Confirmation">>;
  setBulkAPICalls: React.Dispatch<React.SetStateAction<ApiData[] | []>>
  bulkAPICalls: ApiData[]
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false)
  const subscriptionStatusChanges = useMemo(() => bulkAPICalls.filter(api => api.rowData[SUBSCRIPTION_HEADERS.STATUS]), [bulkAPICalls])

  const { createJob } = useJob()

  const handleSubmitJobs = () => {
    const tasks: TaskParams[] = bulkAPICalls.map(call => {
      return ({
        url: API.SUBSCRIPTION.UPDATE,
        queryParams: call.params,
        method: "POST",
        body: call.body
      })
    })

    try {
      createJob({ description: "Bulk Subscription Changes" , tasks })
      toast.success(<ToastMessage title='Bulk edit subscription is in progress.' message='Please check your campaigns in a few minutes to see changes in your subscriptions.' />)
      setStep("Form")
      setBulkAPICalls([])
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpenWarningModal = () => setShowWarningModal(true)
  const { Wrap, Column } = getSuperTableComponents<ApiData>()

  return (
    <>
      <Body semibold>Final Review of your Bulk Subscription Change</Body>
      <HR />
      <TableContainer>
        <Small semibold disabled>Campaign Subscription Updates</Small>
        <HR />
        <StyledTable boldNthLastRow={2}>
          <Wrap items={bulkAPICalls}>
            {headers.map(header => {
              return (
                <Column key={header} title={header}>
                  {lineItem => <Small>{lineItem.rowData[header]}</Small>}
                </Column>
              )
            })}
          </Wrap>
        </StyledTable>
      </TableContainer>
      <ButtonContainer>
        <Button variant='outline' onClick={() =>{setBulkAPICalls([]); setStep("Form")}}>Back</Button>
        <Button onClick={subscriptionStatusChanges.length ? handleOpenWarningModal : handleSubmitJobs}>
          Submit
        </Button>
      </ButtonContainer>
      {showWarningModal &&
        <SubscriptionWarningModal
          subscriptionStatusChanges={subscriptionStatusChanges}
          handleCloseModal={() => setShowWarningModal(false)}
          handleSubmit={handleSubmitJobs}
          headers={[
            SUBSCRIPTION_HEADERS.BUSINESS_NAME,
            SUBSCRIPTION_HEADERS.CAMPAIGN_NAME,
            SUBSCRIPTION_HEADERS.OLD_AMOUNT,
            SUBSCRIPTION_HEADERS.NEW_AMOUNT,
            SUBSCRIPTION_HEADERS.SCHEDULED_DATE,
            SUBSCRIPTION_HEADERS.STATUS
          ]}
        />
      }
    </>
  )
}

export default BulkEditConfirmation
