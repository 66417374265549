import StringUtil from "./string.util"
import { gmtToLocaleString } from "."
import { gmtDate, isPastDate, moreThanNDaysAgo } from "./date.util"
import { RESTRICTIONS } from "../constants/restrictions"
import { isMember, hasRestriction } from "./permissionsHelper"
import { Ad, Creative, Network, Post, VideoCreative, VideoPost } from "../features/creatives/creative.types"
import { FullUser } from "../types/user"

// * 1. CONSTANTS
export const POST_KIND = {
  POST: 'Post',
  VIDEO_POST: 'VideoPost'
} as const

export type PostKind = typeof POST_KIND[keyof typeof POST_KIND]
export const isPostKind = (kind: string): kind is PostKind => Object.values(POST_KIND).some(k => k === kind)

export const AD_KIND = {
  CREATIVE: 'Creative',
  VIDEO_CREATIVE: 'VideoCreative'
} as const

export const CREATIVE_KIND = {
  ...AD_KIND,
  ...POST_KIND
} as const

export type CreativeKind = typeof CREATIVE_KIND[keyof typeof CREATIVE_KIND]

const _CREATIVE_STATUS = {
  CANT_POST: 'CANT_POST', // - status of a post (for networks)
  PAUSED: 'PAUSED',
  ACTIVE: 'ACTIVE'
} as const

export const SUB_TYPE = {
  DEPLOYED: 'Deployed',
  OPTIONAL: 'Optional',
  ARCHIVED: 'Archived'
} as const

export type CreativeSubKind = typeof SUB_TYPE[keyof typeof SUB_TYPE]
export const isCreativeSubKind = (kind: string): kind is CreativeSubKind => Object.values(SUB_TYPE).some(k => k === kind)

export const CREATIVE_STATUS = {
  AWAITING_APPROVAL: 'AWAITING-APPROVAL',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  UNREVIEWED: 'UNREVIEWED',
  REJECTED: 'REJECTED',
  INACTIVE: 'INACTIVE',
  NOSUBSCRIPTION: 'NOSUBSCRIPTION',
  EXPIRED: 'EXPIRED',
  SENT_TO_NETWORK: 'SENT-TO-NETWORK'
} as const

export const POST_STATUS = {
  POSTED: 'POSTED',
  PENDING: 'PENDING',
  PARTIALLY_POSTED: 'PARTIALLY-POSTED',   // Only used on clients as rollup status
  REJECTED: 'REJECTED',
  CANT_POST: 'CANT_POST',
  FAILURE: 'FAILURE',   // Only used on clients as rollup status
  SCHEDULED: 'SCHEDULED', // No post attempts yet. Scheduled to post in the future
  SENT_TO_NETWORK: 'SENT-TO-NETWORK'
} as const

export const POST_NETWORK = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  GMB: 'gmb',
} as const

// Does not exist as a Post Network on the backend
export const VIDEO_POST_NETWORK = {
  YOUTUBE: 'youtube'
} as const

export const AD_NETWORK = {
  SEARCH: 'search',
  DISPLAY: 'display',
  RESIZED: 'resized',
  VIDEO: 'video'
} as const

export const POST_NETWORK_NAME = {
  [POST_NETWORK.FACEBOOK]: 'Facebook',
  [POST_NETWORK.INSTAGRAM]: 'Instagram',
  [POST_NETWORK.TWITTER]: 'X',
  [POST_NETWORK.LINKEDIN]: 'LinkedIn',
  [POST_NETWORK.GMB]: 'Google Business Profile'
} as const

export const CREATIVE_STATUS_COPY_MAP = {
  [CREATIVE_STATUS.UNREVIEWED]: 'Under Network Review',
  [CREATIVE_STATUS.ACTIVE]: 'Active',
  [CREATIVE_STATUS.PAUSED]: 'Paused',
  [POST_STATUS.POSTED]: 'Posted',
  [CREATIVE_STATUS.AWAITING_APPROVAL]: 'Awaiting User Review',
  [CREATIVE_STATUS.REJECTED]: 'Rejected',
  [POST_STATUS.PENDING]: 'Pending',
  [POST_STATUS.SCHEDULED]: 'Scheduled',
  [POST_STATUS.FAILURE]: 'Failure',
  [POST_STATUS.PARTIALLY_POSTED]: 'Partially Posted',
  [POST_STATUS.CANT_POST]: "Couldn't Post",
  [CREATIVE_STATUS.NOSUBSCRIPTION]: 'No Subscription',
  [CREATIVE_STATUS.INACTIVE]: 'Inactive',
  [CREATIVE_STATUS.EXPIRED]: 'Expired',
  [CREATIVE_STATUS.SENT_TO_NETWORK]: 'Sent to Network'
} as const

export type CreativeStatus = keyof typeof CREATIVE_STATUS_COPY_MAP
export type CreativeStatusCopy = typeof CREATIVE_STATUS_COPY_MAP[keyof typeof CREATIVE_STATUS_COPY_MAP]

export const isCreativeStatus = (status: string) : status is CreativeStatus => Object.keys(CREATIVE_STATUS).some(s => s === status)

// the order of the keys in the object should be the correct precedence, but extract into separate variables for readability and consistency's sake
export const CREATIVE_STATUS_PRECEDENCES = Object.keys(CREATIVE_STATUS)
export const POST_STATUS_PRECEDENCES = Object.keys(POST_STATUS)

// * 2. CREATIVE TYPE CHECKS
export const isMagicEntity = (creative: Creative | undefined) => creative?.isMagic ?? false
export const isImageCreative = (creative: Creative | undefined) => [CREATIVE_KIND.CREATIVE, CREATIVE_KIND.POST].some(kind => kind === creative?.kind)
export const isVideoCreative = (creative: Creative | undefined) : creative is VideoCreative => [CREATIVE_KIND.VIDEO_CREATIVE, CREATIVE_KIND.VIDEO_POST].some(kind => kind === creative?.kind)
// ADS
export const isAdEntity = (creative: Creative | undefined) : creative is Ad => [CREATIVE_KIND.CREATIVE, CREATIVE_KIND.VIDEO_CREATIVE].some(kind => kind === creative?.kind)
export const isDeployedAd = (creative: Creative | undefined) => [CREATIVE_KIND.CREATIVE, CREATIVE_KIND.VIDEO_CREATIVE].some(kind => kind === creative?.kind) && !creative?.isMagic
export const isDynamicAd = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.CREATIVE
export const isDeployedDynamicAd = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.CREATIVE && !creative?.isMagic
export const isOptionalDynamicAd = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.CREATIVE && creative?.isMagic
export const isVideoAd = (creative : Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_CREATIVE
export const isDeployedVideoAd = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_CREATIVE && !creative?.isMagic
export const isOptionalVideoAd = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_CREATIVE && creative?.isMagic
// POSTS
export const isPostEntity = (creative: Creative | undefined) : creative is Post | VideoPost => [CREATIVE_KIND.POST, CREATIVE_KIND.VIDEO_POST].some(kind => kind === creative?.kind)
export const isDeployedPost = (creative: Creative | undefined) => [CREATIVE_KIND.POST, CREATIVE_KIND.VIDEO_POST].some(kind => kind === creative?.kind) && (!creative?.isMagic)
export const isOptionalPost = (creative: Creative | undefined) =>  [CREATIVE_KIND.POST, CREATIVE_KIND.VIDEO_POST].some(kind => kind === creative?.kind) && (creative?.isMagic ?? false)
export const isOptionalImagePost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.POST && creative?.isMagic;
export const isVideoPost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_POST && !creative?.isMagic;
export const isOptionalVideoPost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_POST && creative?.isMagic;
export const isAnyImagePost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.POST
export const isAnyVideoPost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_POST
export const isDeployedImagePost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.POST && !creative?.isMagic && !moreThanNDaysAgo(creative.postedDate, 30)
export const isArchivedImagePost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.POST && !creative?.isMagic &&  moreThanNDaysAgo(creative.postedDate, 30)
export const isDeployedVideoPost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_POST && !creative?.isMagic && !moreThanNDaysAgo(creative.postedDate, 30)
export const isArchivedVideoPost = (creative: Creative | undefined) => creative?.kind === CREATIVE_KIND.VIDEO_POST && !creative?.isMagic &&  moreThanNDaysAgo(creative.postedDate, 30)


export const isCreativeCloneEnabled = (creative: Creative | undefined) => {
  if (!creative) return false
  const { fStatus, status } = creative
  if ((fStatus === 'ACTIVE' || fStatus === 'UNREVIEWED') && status !== 'REJECTED') return true
  return false // for any other fStatus, it wont be cloned over to new campaigns. Such as if it is rejected
}

//* Check if every target network for a post has failed
export const checkAllPostsFailed = (creative: Creative) => {
  if (!isPostEntity(creative)) return false
  const { targetNetworks, postedNetworks } = creative
  if (Object.keys(postedNetworks ?? {}).length !== targetNetworks.length) return false
  return Object.values(postedNetworks ?? {}).every(network => network?.postStatus === _CREATIVE_STATUS.CANT_POST)
}

export const isExpiredPost = (creative: Creative) => {
  if (!isPostEntity(creative)) return false
  const { start } = creative
  return isPostEntity(creative) && (!start || isPastDate(gmtDate(start)))
}

// * 3. PARENT ENTITIES
export const getCreativeLocationID = (creative: Creative | undefined) => creative?.location.raw.id;
export const getCreativeUserID = (creative: Creative | undefined) => creative?.location.raw.parentKey.name;

// * 4. SORTERS
// * 5. TEXT HELPERS

// * Decide the start/end text to display for an ad or post
export const creativeDatesText = (creative: Creative) => {
  const {start, end, status, postedDate} = creative

  if(isAdEntity(creative)) {
    if (status === _CREATIVE_STATUS.PAUSED && start) return {start: gmtToLocaleString(start), end: end ? gmtToLocaleString(end) : 'Never'} // Ad Hasn't Started
    if (status === _CREATIVE_STATUS.PAUSED && !start && !end) return {start: 'Date currently unavailable for already started ads', end: 'Date currently unavailable for already paused ads'} // Ad Ran Already
    if(status === _CREATIVE_STATUS.ACTIVE) return {start: 'Date currently unavailable for already started ads', end: end ? gmtToLocaleString(end) : 'Never'} // Ad Started and Is Running
  }

  if(isPostEntity(creative) && postedDate) {
    return {start: gmtToLocaleString(postedDate)}
  }

  return {start: start ? gmtToLocaleString(start) : 'ASAP', end: end ? gmtToLocaleString(end) : 'Never'} // Default Behavior
}

export const getCreativeKindLabel = (creative: Creative) => {
    if (!creative) return '';
    const {kind, isMagic} = creative;
    // OPTIONAL
    if (kind === CREATIVE_KIND.CREATIVE && isMagic ) return 'Optional Dynamic Ad'
    if (kind === CREATIVE_KIND.VIDEO_CREATIVE && isMagic) return  'Optional Video Ad'
    if (kind === CREATIVE_KIND.POST && isMagic) return 'Optional Image Post'
    if (kind === CREATIVE_KIND.VIDEO_POST && isMagic) return 'Optional Video Post'
    // DEPLOYED
    if (kind === CREATIVE_KIND.CREATIVE) return 'Dynamic Ad'
    if (kind === CREATIVE_KIND.VIDEO_CREATIVE) return 'Video Ad'
    if (kind === CREATIVE_KIND.POST) return 'Image Post'
    if (kind === CREATIVE_KIND.VIDEO_POST) return 'Video Post'
}

// Used in the Member View in the Build Tab when viewing magics
export const mapKindToType = (creative: Creative) => {
    let type = "";
    let text = ""
    switch(creative.kind) {
      case CREATIVE_KIND.CREATIVE: {
        text = "Dynamic Ad"
        type = "ad";
        break
      }
      case CREATIVE_KIND.VIDEO_CREATIVE: {
        text = "Video Ad"
        type = "ad";
        break
      }
      case CREATIVE_KIND.VIDEO_POST: {
        text = "Video Post"
        type = "post";
        break
      }
      case CREATIVE_KIND.POST: {
        text = "Image Post"
        type = "post";
        break
      }
      default: return ""
    }
    return {type, text}
  }

export const adOrPostController = (kind: CreativeKind) => {
  return [CREATIVE_KIND.CREATIVE, CREATIVE_KIND.VIDEO_CREATIVE].some(item => item === kind) ? 'ad' : 'post'
}

export const countCreativeKind = (creativeList: Creative[] = [], kind: CreativeKind, type: CreativeSubKind, isMagic: boolean, isArchived: boolean) => {
  if (!creativeList) return 0

  // * Handle count for deployed posts (takes experiment into account)
  if ((kind === CREATIVE_KIND.POST || CREATIVE_KIND.VIDEO_POST) && type === SUB_TYPE.DEPLOYED) {
    return isArchived ?
      creativeList.filter(c => c.kind === kind && !moreThanNDaysAgo(c?.postedDate, 30) && !c.isMagic).length :
      creativeList.filter(c => c.kind === kind && !c.isMagic).length
  }
  // * Handle count for archived posts
  if ((kind === CREATIVE_KIND.POST || kind === CREATIVE_KIND.VIDEO_POST) && type === SUB_TYPE.ARCHIVED) return creativeList.filter(c => c.kind === kind && !c.isMagic && moreThanNDaysAgo(c?.postedDate, 30)).length
  // * Handle count for optional
  if (type === SUB_TYPE.OPTIONAL && isMagic) return creativeList.filter(c => c.kind === kind && c.isMagic).length
  // * Handle deployed
  if (type === SUB_TYPE.DEPLOYED) {
    return creativeList.filter(c => c.kind === kind && !c.isMagic).length
  }
  // * Handle sum of all
  return creativeList.filter(c => c.kind === kind).length
}

export const getPreviewImageUrl = ({preview, youTubeId} : {
  preview: string | undefined,
  youTubeId: string | undefined
}) => {
  return preview ? `/srv/${preview}` : `https://img.youtube.com/vi/${youTubeId}/0.jpg`
}

export const getNetworkName = (network: Network) => {
  if (network.toLowerCase() === "twitter") return "X"

  return network === POST_NETWORK.GMB ? 'Google Business Profile' : StringUtil.capitalizeFirstLetter(network)
}

export const isNetwork = (network: string) : network is Network => Object.values(POST_NETWORK).some(n => n === network)

export const CREATIVE_DISPLAY_VIEW = {
  CAMPAIGN: 'campaign',
  FOLDER: 'folder'
} as const

export type CreativeDisplayView = typeof CREATIVE_DISPLAY_VIEW[keyof typeof CREATIVE_DISPLAY_VIEW]

export const matchesMainCreativeNav = (fieldName: CreativeKind, creative: Creative) => creative.kind === fieldName
export const matchesSubCreativeNav = (subOption: CreativeSubKind, creative: Creative, view: CreativeDisplayView) => {
  if (view === CREATIVE_DISPLAY_VIEW.FOLDER) {
    if (subOption === SUB_TYPE.OPTIONAL) return creative.isMagic === true
    return !creative?.isMagic
  } else if (view === CREATIVE_DISPLAY_VIEW.CAMPAIGN) {
    if (subOption === SUB_TYPE.OPTIONAL) return creative.isMagic === true
    else if (subOption === SUB_TYPE.ARCHIVED) return moreThanNDaysAgo(creative?.postedDate, 30)
    else if (subOption === SUB_TYPE.DEPLOYED && isPostEntity(creative)) return !creative?.isMagic && !moreThanNDaysAgo(creative?.postedDate, 30)
    return !creative?.isMagic
  }
}

type CreativeControllerProps = {
  creativeArray: Creative[],
  option: CreativeKind,
  subOption: CreativeSubKind,
  creativeReports: any,
  sort: any,
  view?: CreativeDisplayView

}

export const creativeController = ({ creativeArray, option, subOption, creativeReports, sort, view = CREATIVE_DISPLAY_VIEW.CAMPAIGN } : CreativeControllerProps) => {
  if (!creativeArray || (!creativeReports && view === CREATIVE_DISPLAY_VIEW.CAMPAIGN)) return []

  return creativeArray.filter(c => {
    return (
      matchesMainCreativeNav(option, c) && // Filter By Main Creative Nav
      matchesSubCreativeNav(subOption, c, view) //Filter by Sub Creative Nav
    )
  })
    .map(creative => ({ ...creative, reportData: getReportData(creative, creativeReports) })) //Append extra data to use in this component
    .sort(sort?.method || (() => { })()) //Sort
}

export const CREATIVE_TYPE_OPTION_LABELS = {
  [CREATIVE_KIND.CREATIVE]: {label: "Dynamic Ad", fieldName: CREATIVE_KIND.CREATIVE},
  [CREATIVE_KIND.VIDEO_CREATIVE]: {label: "Video Ad", fieldName: CREATIVE_KIND.VIDEO_CREATIVE},
  [CREATIVE_KIND.POST]: {label: "Image Post", fieldName: CREATIVE_KIND.POST},
  [CREATIVE_KIND.VIDEO_POST]: {label: "Video Post", fieldName: CREATIVE_KIND.VIDEO_POST},
} as const

type CreativeTypeOptionLabel = typeof CREATIVE_TYPE_OPTION_LABELS[keyof typeof CREATIVE_TYPE_OPTION_LABELS]

export const CREATIVE_STATUS_OPTIONS_LABELS = {
  [SUB_TYPE.DEPLOYED]: { label: SUB_TYPE.DEPLOYED, fieldName: SUB_TYPE.DEPLOYED },
  [SUB_TYPE.OPTIONAL]: { label: SUB_TYPE.OPTIONAL, fieldName: SUB_TYPE.OPTIONAL },
  [SUB_TYPE.ARCHIVED]: { label: SUB_TYPE.ARCHIVED, fieldName: SUB_TYPE.ARCHIVED }
} as const

type CreativeSubOptionLabel = typeof CREATIVE_STATUS_OPTIONS_LABELS[keyof typeof CREATIVE_STATUS_OPTIONS_LABELS]

export const getNoCreativesText = (option: CreativeTypeOptionLabel, subOption: CreativeSubOptionLabel, user: FullUser & {accessLevel: any}) => {
  let header = ''
  let description = ''

  const VIDEO_POST = 'Video Post'
  const IMAGE_POST = 'Image Post'
  const isAd = ['Dynamic Ad', 'Video Ad'].some(adType => adType === option.label)
  const isPost = ['Image Post', 'Video Post'].some(postType => postType === option.label)
  const isOptional = subOption.label === 'Optional'
  const creativeKindLabel = `${isOptional ? 'Optional' : ''} ${option.label}s`
  const shouldHideDescription = (
    isMember(user.accessLevel) && (
      (isAd && hasRestriction(user, RESTRICTIONS.CREATIVE_EDIT)) ||
      (isPost && hasRestriction(user, RESTRICTIONS.POST_EDIT))
    )
  )

  header = `No ${creativeKindLabel} Found`
  if (!shouldHideDescription) description = `You have not created any ${creativeKindLabel} yet. Go to the Build Creatives tab to create ${creativeKindLabel}.`
  if (!shouldHideDescription && (option.label === IMAGE_POST || option.label === VIDEO_POST) && subOption.fieldName === 'Deployed') {
    header = 'No Scheduled or Recently Published Posts Found'
    description = `You have not created any ${option.label === IMAGE_POST ? 'Image' : 'Video'} Posts recently. Go to the Build Creatives Tab to build new posts.`
  }
  if (!shouldHideDescription && (option.label === IMAGE_POST || option.label === VIDEO_POST) && subOption.fieldName === 'Archived') {
    header = 'No Archived Posts Found'
    description = `You do not have any ${option.label === IMAGE_POST ? 'Image' : 'Video'} posts that were published more than 30 days ago.`
  }

  return {
    header,
    description
  }
}

export type Report = {
  data: Record<number, {
    date: string,
    impressions: number,
    clicks: number,
    leads: number,
  }[]>,
  ranks: {
    clicks: string[],
    clicksVals: number[],
    ctr: string[],
    ctrVals: number[],
    impressions: string[],
    impressionsVals: number[],
  },
  videoData: Record<number, {
    date: string,
    impressions: number,
    clicks: number,
    leads: number,
  }[]>,
  videoRanks: {
    clicks: string[],
    clicksVals: number[],
    ctr: string[],
    ctrVals: number[],
    impressions: string[],
    impressionsVals: number[],
  },
}

// CREATIVE - REPORTING
export const getReportData = (creative: Creative, reports: Report) => {
  if (!reports) return {}

  if (Object.keys(reports).length === 0) {
      console.warn('Aborting report data, reports object is empty.')
      return {}
  }

  if (creative.kind !== 'Creative' && creative.kind !== 'VideoCreative')
      return {}

  let rank, clicks, impressions

  if (creative.kind === 'Creative') {
      rank = reports.ranks.ctr.indexOf('' + creative.id) + 1

      const clicksIndex = reports.ranks.clicks.indexOf('' + creative.id)
      if (clicksIndex === -1) clicks = -1
      else clicks = reports.ranks.clicksVals[clicksIndex]

      const impressionsIndex = reports.ranks.impressions.indexOf(
          '' + creative.id
      )
      if (impressionsIndex === -1) impressions = -1
      else impressions = reports.ranks.impressionsVals[impressionsIndex]
  } else if (creative.kind === 'VideoCreative') {
      rank = reports.videoRanks.ctr.indexOf('' + creative.id) + 1

      const clicksIndex = reports.videoRanks.clicks.indexOf('' + creative.id)
      if (clicksIndex === -1) clicks = -1
      else clicks = reports.videoRanks.clicksVals[clicksIndex]

      const impressionsIndex = reports.videoRanks.impressions.indexOf(
          '' + creative.id
      )
      if (impressionsIndex === -1) impressions = -1
      else impressions = reports.videoRanks.impressionsVals[impressionsIndex]
  }

  // if not in the rank array, will put at the end
  if (!rank || rank === -1) rank = 100000

  return {
      rank,
      clicks,
      impressions,
  }
}

export const rankTypes = {
  CTR: "ctr",
  IMPRESSIONS: 'impressions',
  CLICKS: 'clicks'
} as const

export type RankType = typeof rankTypes[keyof typeof rankTypes]

// gets the ranks array from the report that will be used to rank
export const getRanks = (reports: Report, creative: Creative, type?: RankType) => {
  if (
      !creative ||
      !reports ||
      !type ||
      !isAdEntity(creative)
  )
      return []

  const baseObj = isImageCreative(creative) ? reports.ranks : reports.videoRanks

  return baseObj[type]
}
