import React, { CSSProperties, ReactNode } from 'react';
import { AnimatedCollapseContent, AnimatedCollapseWrap } from './style';

type AnimatedCollapseProps = {
    children: ReactNode
    isOpen: boolean
    duration?: string
    easing?: string
    openStyle?: CSSProperties
}
const AnimatedCollapse = ({ children, isOpen, duration = '0.25s', easing = 'ease-in', openStyle={} }: AnimatedCollapseProps) => {
    return (
        <AnimatedCollapseWrap isOpen={isOpen} duration={duration} easing={easing}>
            <AnimatedCollapseContent style={isOpen ? openStyle : {}}>{children}</AnimatedCollapseContent>
        </AnimatedCollapseWrap>
    );
};

export default AnimatedCollapse;