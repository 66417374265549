import styled from "styled-components";
import { createBoxShadow } from "../../../styles/card";
import { getModalSize, getPadding } from "./utils";
import { StyledModalProps } from "./types";

//* MODAL V2
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: ${props => props.theme.color.greyAccent2};
  }
`;

export const StyledModalV2 = styled.div<StyledModalProps>`
  position: relative;
  background-color:${props => props.theme.color.backgroundColor2};
  z-index: 1000;
  box-shadow:${createBoxShadow};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 75vh;

  ${(props) => getModalSize(props.size)}
  ${(props) => getPadding(props.hasNav)}
`;

export const StyledModalNavV2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px 0;

  > * {
    cursor: pointer;
  }

  .close-btn {
    display: grid;
    place-items: center;
    margin-left: auto;
  }
`

export const StyledModalTitleV2 = styled.div`
  margin-bottom: 0.5rem;
`

export const StyledModalContentV2 = styled.div`
  flex: 1;
  overflow-y: auto;
`

export const StyledModalFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  gap: 1.5rem;
  margin-top: 2.5rem;

  button {
    min-width: 160px;
  }
`