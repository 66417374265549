import styled from "styled-components";
import { hexToRgba } from "../../helpers/colors.util";

export const CustomCheckbox = styled.div`
  margin-left: 24px;
  input[type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    width: 1.75ex;
    height: 0.6ex;
    background: rgba(0, 0, 0, 0);
    top: 0.7ex;
    left:0.6ex;
    display: flex;
    border: 2px solid ${props => props.theme.color.accentColor2};
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  input[type="checkbox"] {
    line-height: 2.1ex;
  }

  input[type="radio"],
  input[type="checkbox"] {
      position: absolute;
      display: none;
  }

  input[type="checkbox"] + label {
      position: relative;
      overflow: hidden;
      cursor: pointer;
  }

  input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    vertical-align: -30%;
    height: 24px;
    width: 24px;
    background-color: ${props => props.theme.color.backgroundColor2};
    border: 1px solid ${props => props.theme.color.greyAccent1};
    border-radius: 2px;
    margin-right: 0.6em;
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    display: inline-block;
    vertical-align: -30%;
    height: 24px;
    width: 24px;
    background-color: ${props => props.theme.color.backgroundColor2};
    border: 1px solid ${props => props.theme.color.greyAccent1};
    border-radius: 2px;
    margin-right: 0.6em;
  }
`

export const CheckboxWrap = styled.div<{
  checked?: boolean;
  dashed?: boolean;
  disabled?: boolean;
  size?: "small" | "large";
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in;
  cursor: ${props => props.disabled ? "default" : "pointer"};

  height: ${props => props.size === "small" ? "16px" : "24px"};
  width: ${props => props.size === "small" ? "16px" : "24px"};

  border: solid 1.5px ${props => props.theme.color.greyAccent1};
  border-radius: 2px;
  background-color: ${props => props.theme.color.backgroundColor2};

  svg {
    height: ${props => props.size === "small" ? "10px" : "16px"};
    width: ${props => props.size === "small" ? "10px" : "16px"};
  }

  &:active {
    background-color: ${props => props.theme.color.greyAccent2};
  }

  ${props => props.checked && `
    background-color: ${props.theme.color.accentColor2};
    border-color: ${props.theme.color.accentColor2};

    &:active {
      background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), linear-gradient(${props.theme.color.accentColor2}, ${props.theme.color.accentColor2});
    }

    svg {
      fill: ${props.theme.color.backgroundColor2};
    }
  `}

  ${props => props.dashed && `
    border-color: ${props.theme.color.accentColor2};

    &:active {
      background-color: ${props.theme.color.greyAccent2};
    }

    svg {
      fill: ${props.theme.color.accentColor2};
    }
  `}

  ${props => props.disabled && `
    pointer-events: none;
    border-color: ${hexToRgba(props.theme.color.greyAccent1, 0.75)};
    background-color: ${props.theme.color.greyAccent2};
  `}

`