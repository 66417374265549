import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import CheckBox from '../../../../components/checkbox/CheckBox'
import useLastValue from '../../../../hooks/useLastValue'
import { PrimaryCTABtn } from '../../../../styles/buttons'
import {
    StyledOptionList,
    StyledOptionItem,
    StyleOptionLabel,
} from '../../../../styles/options'
import { H2, Body, Small } from '../../../../styles/typography'
import { useInsightsReportBuilderContext } from '../InsightsReportBuilder'

export const insightBuilderReportTypes = [
    {
        id: 'business-and-campaigns-settings',
        title: 'Business & Campaigns Settings',
        label: 'Business & Campaigns Settings',
        description:
            'This type of report can include general settings, subscription, and targeting details.',
    } as const,
    {
        id: 'performance',
        title: 'Performance Metrics',
        label: 'Performance Metrics',
        description:
            'This type of report can include metrics such as clicks, impressions, click-through-rate, and more by channel. ',
    } as const,
    {
        id: 'platform-connections',
        title: 'Platform Connections',
        label: 'Platform Connections',
        description:
            'This type of report can include connection details from your supported external platforms.',
    } as const,
    {
        id: 'distribution',
        title: 'Distribution',
        label: 'Distribution',
        description:
            'This type of report can include details like click and impression distribution across age groups, genders, channels, etc.',
    } as const,
]

export type InsightBuilderReportCategory =
    (typeof insightBuilderReportTypes)[number]

const SelectableListContainer = styled.div`
    border: 1px solid;
    border-color: ${(props) => props.theme.color.greyAccent1};
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkbox {
        svg {
            margin-right: 0;
        }
    }
`

export const SelectReportType = () => {
    const {
        goNext,
        isReportCategorySelected,
        toggleReportCategorySelected,
        selectedReportCategoryIds,
    } = useInsightsReportBuilderContext()

    const lastSelectedReportTypeIds = useLastValue(selectedReportCategoryIds)

    useEffect(() => {
        if (lastSelectedReportTypeIds === selectedReportCategoryIds) return
        if (selectedReportCategoryIds.length < 2) return
        if (lastSelectedReportTypeIds.length > 1) return

        toast.info('Individual CSVs will be generated for each report type.')
    }, [selectedReportCategoryIds, lastSelectedReportTypeIds])

    return (
        <>
            <H2>Select Custom Report Type</H2>
            <Body
                style={{
                    marginBottom: '24px',
                }}
            >
                Choose the type(s) of CSVs you would like to generate. Multiple
                CSVs will be generated if at least two types are selected.
            </Body>
            <SelectableListContainer>
                <StyledOptionList
                    style={{
                        marginTop: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {insightBuilderReportTypes.map((reportType) => {
                        return (
                            <StyledOptionItem
                                key={reportType.id}
                                selected={isReportCategorySelected(reportType)}
                                onClick={() =>
                                    toggleReportCategorySelected(reportType)
                                }
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingRight: '16px',
                                    width: '100%',
                                }}
                            >
                                <StyleOptionLabel
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Body>{reportType.title}</Body>
                                    <Small>{reportType.description}</Small>
                                </StyleOptionLabel>
                                <CheckBox
                                    className="checkbox"
                                    checked={isReportCategorySelected(reportType)}
                                    size="large"
                                />
                            </StyledOptionItem>
                        )
                    })}
                </StyledOptionList>
                <PrimaryCTABtn disabled={selectedReportCategoryIds.length === 0} onClick={goNext}>Select Reports</PrimaryCTABtn>
            </SelectableListContainer>
        </>
    )
}
