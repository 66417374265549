import React, { ReactElement, ReactNode } from 'react'
import { SelectorContainer, SelectorItem } from './styles'
import CheckBox from '../checkbox/CheckBox'
import styled from 'styled-components'

export const ButtonCircle = styled.div<{selected: boolean}>`
    cursor: pointer;
    height: 1.5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    outline-offset: 0.1875rem;
    outline: 0.0625rem solid ${props => props.theme.color.greyAccent1};
    background-color: ${props => props.selected ? props.theme.color.accentColor2 : 'transparent'};
`

export const SELECTOR_CONTROL_TYPE = {
    CHECKBOX: 'checkbox',
    BUTTON: 'button',
    NONE: 'none'
} as const

type ContentLayout = {
    idField: string
    layout: ({ item, isSelected }: { item: any, isSelected: boolean}) => ReactElement
}
type SharedSelectorProps = {
    isItemSelected: (item: any) => boolean
    onItemSelected: (item: any) => void
    controlType?: typeof SELECTOR_CONTROL_TYPE[keyof typeof SELECTOR_CONTROL_TYPE]
}

type SelectorSingleItemProps = {
    item?: any
    ContentLayout?: ContentLayout
    children?: ReactNode
    style?: React.CSSProperties
    disabled?: boolean
} & SharedSelectorProps
// Specifically if you want to have ONE item
export const SelectorSingleItem = ({ item, isItemSelected, onItemSelected, ContentLayout, children, style = {}, controlType = SELECTOR_CONTROL_TYPE.NONE }: SelectorSingleItemProps) => {
    return (
        <SelectorItem
            disabled={item?.disabled}
            selected={isItemSelected(item)}
            onClick={() => onItemSelected(item)}
            style={style}
        >
            {
                controlType === SELECTOR_CONTROL_TYPE.CHECKBOX && (
                    <div>
                        <CheckBox size='large' checked={isItemSelected(item)} />
                    </div>
                )
            }
            {
                controlType === SELECTOR_CONTROL_TYPE.BUTTON && (
                    <ButtonCircle selected={isItemSelected(item)} />
                )
            }
            { children }
            { ContentLayout && <ContentLayout.layout item={item} isSelected={isItemSelected(item)} /> }
        </SelectorItem>
    )
}


// Props:
// List of items
// Function to see if item is selected
// controlType -> checkbox, button, none
// ContentLayout -> object {
//  layout: JSX that takes in an item and displays it.
//  idField: what should we index to identify this item
// }
// this MUST have an item prop on it
// showContainer whether a container is needed for these options

type SelectorProps = {
    items: any
    style?: React.CSSProperties
    showContainer: boolean
    ContentLayout: ContentLayout
} & SharedSelectorProps

export const Selector = ({ items, ContentLayout, isItemSelected, onItemSelected, style = {}, controlType = SELECTOR_CONTROL_TYPE.BUTTON, showContainer }: SelectorProps) => {
    return (
        <SelectorContainer style={style} showContainer={showContainer}>
            {items.map((item: any, index: number) => (
                <SelectorSingleItem
                    item={item}
                    ContentLayout={ContentLayout}
                    isItemSelected={isItemSelected}
                    onItemSelected={onItemSelected}
                    key={item[ContentLayout.idField] ?? index}
                    controlType={controlType}
                />
            ))}
        </SelectorContainer>
    )
}

