import React from 'react'
import { CloneStatusTable, CloneStatusTableWrapper } from './styles'
import { Body, H2 } from '../../../styles/typography'
import { getCreativeKindLabel } from '../../../helpers/creative.util'
import CloneTableItem from './CloneTableItem'

// * Creative will be undefined when cloning from an entire folder (bulk clone), therefore "creative" itself will be a property inside of a single target
const CloneTable = ({ targets, statusType, creative }) => {

    return (
        <CloneStatusTableWrapper>
            <H2 style={{ padding: '0 30px', marginTop: '70px' }}>{statusType}</H2>
            <CloneStatusTable>
                <div className='headers'>
                    <Body style={{ width: '220px' }}>{getCreativeKindLabel(creative)} Preview</Body>
                    <Body className='header'>Campaigns/Creative Folders</Body>
                </div>
                <div className='creative-content'>
                    {
                        targets.map((target, idx) => {
                            return <CloneTableItem
                                key={idx}
                                creative={target?.creative}
                                targetDestination={target?.targetDestination}
                            />
                        })
                    }
                </div>
            </CloneStatusTable>
        </CloneStatusTableWrapper>
    )
}

export default CloneTable