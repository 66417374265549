import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MultiStepForm from '../../../components/forms/MultiStepForm'
import useInitializeOptions from '../../../features/advancedFilter/hooks/useInitializeOptions'
import {
    getLocationStatus,
    LOCATION_STATUS,
} from '../../../features/locations/util'
import { nDaysAgo } from '../../../helpers/date.util'
import { useSelectableList } from '../../../hooks/useSelectableList'
import useSteps from '../../../hooks/useSteps'
import { Body, H2 } from '../../../styles/typography'
import { EulerityLocation } from '../../../types/location'
import {
    SelectLocationState,
    SelectableLocation,
} from '../sections/SocialPosting/locationSelector'
import { FinalReview } from './Steps/FinalReview'
import { SelectCampaigns } from './Steps/SelectCampaigns'
import { SelectReportDataPoints } from './Steps/SelectReportDataPoints'
import {
    InsightBuilderReportCategory,
    insightBuilderReportTypes,
    SelectReportType,
} from './Steps/SelectReportType'
import {
    ColumnGroupOption, insightReportColumnOptions
} from './core/reportColumns'
import { makeReportFormat, InsightReportFormatPresetMap, useReportFormats } from '../hooks/useReportFormats'
import { BuiltInsightReport, InsightReportBuilderFormat, reportBuilder } from './core/reportBuilder'
import { useHistory } from 'react-router-dom'
import { PATH } from '../../../constants/urlPath'
import { ReportBuilderFormatPreset } from './core/aggregationOptions'
import { ExperimentName } from '../../../helpers/experimentHelpers'
import { isFccExperimentName, makeExperimentObject } from '../../../hooks/useExperiment'
import { useAccess } from '../../../hooks/useAccess'
type InsightReportBuilderStepId = 'report-type' | 'report-data' | 'report-campaigns' | 'report-review'
export type insightReportBuilderContext = {
    build: () => Promise<void>
    views: BuiltInsightReport | null
    setViews: (
        views: BuiltInsightReport | null
    ) => void
    isReportCategorySelected: (item: InsightBuilderReportCategory) => boolean
    toggleReportCategorySelected: (item: InsightBuilderReportCategory) => void
    selectedReportCategories: InsightBuilderReportCategory[]
    visibleReportCategories: InsightBuilderReportCategory[]
    selectedReportCategoryIds: InsightBuilderReportCategory['id'][]
    selectedReportColumns: ColumnGroupOption[]
    isReportColumnSelected: (item: ColumnGroupOption) => boolean
    setSelectedReportColumns: (items: ColumnGroupOption[]) => void
    datePickerData: {
        selectedStartDate: Date
        selectedEndDate: Date
        setStartDate: (date: Date) => void
        setEndDate: (date: Date) => void
        maxInToTheFuture: Date
    }
    progress: {
        completed: number
        total: number
    }
    currentStepId: InsightReportBuilderStepId
    updateReportFormat: (key: InsightBuilderReportCategory['id'], format: ReportBuilderFormatPreset[]) => void
    reportFormats: InsightReportFormatPresetMap
} & ReturnType<typeof useSteps> &
    SelectLocationState

const InsightsReportBuilderContext =
    React.createContext<insightReportBuilderContext | null>(null)

export default function InsightsReportBuilder() {
    const _S = useSteps()

    const franchise = useSelector((s) => s.franchise)
    const user = useSelector(s => s.user)
    const { canManageFranchise } = useAccess()

    const history = useHistory()
    useEffect(() => {
        if (!!franchise.name) return
        history.push(PATH.INSIGHTS)
    }, [franchise.name, history])

    // views that hold built reports
    // ie the 'performance' key holds the performance report in the form of a table (Cell[][])
    const [views, setViews] = useState<BuiltInsightReport | null>(null)

    const [selectedStartDate, setStartDate] = useState<Date>(nDaysAgo(30))
    const [selectedEndDate, setEndDate] = useState<Date>(nDaysAgo(1))

    const [progress, setProgress] = useState({
        completed: 0,
        total: 0,
    })

    const experiments: ExperimentName[] = useSelector((s) => s.user?.experiments ?? [])

    const experimentMap = useMemo(() => {
        return new Map(experiments.filter(isFccExperimentName).map((exp) => [exp, makeExperimentObject(exp, experiments)]))
    }, [experiments])

    const _reportBuilder = useRef(reportBuilder({
        maxConcurrentRequests: 100,
        onTaskAdded: () => {
            setProgress((prev) => ({
                ...prev,
                total: prev.total + 1,
            }))
        },
        onTaskComplete: () => {
            setProgress((prev) => ({
                ...prev,
                completed: prev.completed + 1,
            }))
        },
    }))

    const {
        locationOptions,
    }: {
        locationOptions: SelectableLocation[]
    } = useInitializeOptions()

    const options: SelectableLocation[] = useMemo<SelectableLocation[]>(() => {
        return locationOptions
    }, [franchise])

    const {
        isSelected: isReportCategorySelected,
        toggleItem: toggleReportCategorySelected,
        selectedIds: selectedReportCategoryIds,
        selectedItems: selectedReportCategories,
    } = useSelectableList(insightBuilderReportTypes, (item) => item.id)

    const selectableReportColumns = useMemo(() => {
        return insightReportColumnOptions.filter((item) => {
            if (item.category === 'default') return false
            return selectedReportCategories.some(
                (category) => category.id === item.category
            )
        })
    }, [selectedReportCategories])

    const {
        selectedItems: selectedReportColumns,
        setSelectedItems: setSelectedReportColumns,
        isSelected: isReportColumnSelected,
    } = useSelectableList(selectableReportColumns, (item) => item.groupId)

    const { reportFormats, updateReportFormat } = useReportFormats()

    const {
        selectedItems: selectedLocations,
        setSelectedItems: setSelectedLocations,
        deselectAllItems: handleClearLocations,
        selectAllItems: handleSelectAllLocations,
        isSelected: isLocationSelected,
    } = useSelectableList(locationOptions, (item) => item.websafe)

    const handleSelectAllActiveLocations = () => {
        if (franchise.loading) return
        const activeLocations = franchise.locations.filter(
            (l: EulerityLocation) =>
                getLocationStatus(l) === LOCATION_STATUS.ACTIVE
        )
        setSelectedLocations(activeLocations as SelectableLocation[])
    }

    const visibleReportCategories = useMemo(() => {
        const reportTypeSet = new Set<InsightBuilderReportCategory['id']>()

        selectedReportColumns.forEach((item) => {
            if (item.category === 'default') return
            reportTypeSet.add(item.category)
        })
        return insightBuilderReportTypes.filter((item) =>
            reportTypeSet.has(item.id)
        )
    }, [selectedReportColumns])

    const build = async () => {
        if (!selectedLocations.length) {
            return
        }

        setProgress({
            completed: 0,
            total: 0,
        })

        const parseReportPreset = (preset: ReportBuilderFormatPreset[]): InsightReportBuilderFormat => {
            return {
                partialColumnFilters: preset.flatMap(entry => entry.format.partialColumnFilters),
                partialRowIndexGenerators: preset.flatMap(entry => entry.format.partialRowIndexGenerators)
            }
        }

        const applicableFormats = Object.fromEntries(
            visibleReportCategories.map((category) => {
                return [
                    category.id,
                    parseReportPreset(reportFormats[category.id]),
                ]
            })
        )

        const reportMap = await _reportBuilder.current.buildReports(
            {
                locations: selectedLocations,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                franchise,
                experiments: experimentMap,
                role: canManageFranchise() ? "zor" : "zee",
                user
            },
            makeReportFormat(applicableFormats, isReportColumnSelected)
        )

        if (Object.keys(reportMap).length === 0) return

        setViews(reportMap)
    }

    const steps = [
        {
            id: 'report-type',
            title: 'REPORT TYPE',
            component: <SelectReportType />,
        },
        {
            id: 'report-data',
            title: 'DATA',
            component: <SelectReportDataPoints />,
        },
        {
            id: 'report-campaigns',
            title: 'CAMPAIGNS',
            component: <SelectCampaigns />,
        },
        {
            id: 'report-review',
            title: 'FINAL REVIEW',
            component: <FinalReview />,
        },
    ]

    const currentStepId = steps[_S.currentStep].id as InsightReportBuilderStepId

    return (
        <InsightsReportBuilderContext.Provider
            value={{
                ..._S,
                isReportCategorySelected,
                toggleReportCategorySelected,
                selectedReportCategoryIds,
                selectedReportColumns,
                setSelectedReportColumns,
                loading: franchise.loading,
                locationSelectorController: {
                    locationOptions: options,
                    setSelectedLocations,
                    selectedLocations,
                    handleSelectAllLocations,
                    handleSelectAllActiveLocations,
                    handleClearLocations,
                    isLocationSelected,
                },
                datePickerData: {
                    selectedStartDate,
                    selectedEndDate,
                    setStartDate,
                    setEndDate,
                    maxInToTheFuture: nDaysAgo(1),
                },
                build,
                views,
                setViews,
                isReportColumnSelected,
                selectedReportCategories,
                visibleReportCategories,
                progress,
                currentStepId,
                updateReportFormat,
                reportFormats
            }}
        >
            <H2>Generate Custom Reports</H2>
            <Body>
                Build your own downloadable custom CSVs from the available
                tracked data for your businesses. <br/> The custom CSVs you build are
                not saved to our platform after creation.
            </Body>
            <MultiStepForm
                steps={steps}
                currentStep={_S.currentStep}
                disableProgressBar={false}
                creationType="insights"
            />
        </InsightsReportBuilderContext.Provider>
    )
}

export const useInsightsReportBuilderContext = () => {
    const context = React.useContext(InsightsReportBuilderContext)

    if (!context) {
        throw new Error(
            'useInsightsReportBuilder must be used within InsightsReportBuilderProvider'
        )
    }

    return context
}
