import { LOCATION_FILTERS, CLEAR_FILTERS } from "./types"


export const getLocationFilters = (filters) => dispatch => (
    dispatch({
        type: LOCATION_FILTERS,
        payload: filters
    })
)


export const clearAdvancedFilters = () => dispatch => dispatch({type: CLEAR_FILTERS})