import React from 'react';
import Confirm from "../../../components/confirm/Confirm"
import { useTheme } from 'styled-components';
import { LandingPageWarningContent } from './styles';
import { IoMdWarning } from 'react-icons/io';
import { Body, H2} from '../../../styles/typography';

const GaWarningModal = ({setIsLandingWarningOpen, goNext }) => {
    const theme = useTheme();

    return (
        <Confirm
            width={'656px'}
            buttonStyles={{ color: theme.color.textColor1, background: 'transparent', border: `1px solid ${theme.color.textColor1}` }}
            confirm={{
                text: 'Continue',
                callback: () => {
                    setIsLandingWarningOpen(false);
                    goNext()
                }
            }}
            cancel={{
                text: 'Go Back & Edit',
                callback: () => {
                    setIsLandingWarningOpen(false)
                }
            }}
        >
            <LandingPageWarningContent>
                <H2 center>Attention</H2>
                <IoMdWarning size={'5.0rem'} />
                <H2 style={{marginTop: '20px'}}>Potential Tracking Issues</H2>
                <Body>You have selected a landing page that is not your default which may result in your campaign performance not being tracked properly</Body>
            </LandingPageWarningContent>
        </Confirm>
    )
}

export default GaWarningModal;