import React, { useState } from 'react'
import { Body, H2 } from '../../../../styles/typography'
import BackButton from '../../../../components/backButton/BackButton'
import { useCreativeContext } from '../CreativeModalV2'
import { useCreativeMetadata } from '../../hooks/useCreativeMetadata'
import { useCreativeClonesMetadata } from '../../hooks/useCreativeClones'
import DisplayMedia from '../DisplayMedia'
import AnimatedLoadingBox from '../../../../components/reusableUI/components/AnimatedLoadingBox/AnimatedLoadingBox'
import { PostCloneTable, PostInformation } from '../Post/ManagePostClones'
import { AdCloneTable, AdInformation } from '../Ad/ManageAdClones'
import styled, { useTheme } from 'styled-components'
import { Theme } from '../../../../types/theme'
import DownloadLink from '../../../../components/button/DownloadLink'
import _ from 'lodash'
import { ManageClonesContext, useManageClonesContext } from './useManageClones'
import { isPostEntity } from '../../../../helpers/creative.util'

export const CreativeInformationWrapper = styled.div`

    padding-top: 2rem;

    .back-btn {
        cursor: pointer;
        svg {
            width: 24px;
            height: auto;
        }
        svg path {
            stroke: ${(props) => props.theme.color.accentColor2};
        }
        display: flex;
        position: absolute;
        top: 25px;
        line-height: 1;
    }
`

const CreativeInformation = () => {
    const { creative }= useManageClonesContext()

    if (isPostEntity(creative)) {
        return <PostInformation creative={creative} />
    }

    return <AdInformation creative={creative} />
}

const CreativeClonesTable = ({ onCSVDataUpdated }: { onCSVDataUpdated: (data: string[][]) => void }) => {
    const { creative, clonesLoading } = useManageClonesContext()

    if (isPostEntity(creative)) {
        return (
            <AnimatedLoadingBox loading={clonesLoading} loadingHeight="22rem">
                <PostCloneTable onCSVDataUpdated={onCSVDataUpdated} />
            </AnimatedLoadingBox>
        )
    }

    return (
        <AnimatedLoadingBox loading={clonesLoading} loadingHeight="22rem">
            <AdCloneTable onCSVDataUpdated={onCSVDataUpdated} />
        </AnimatedLoadingBox>
    )
}

const ManageClones = () => {
    const { setView, creative } = useCreativeContext()
    const metadata = useCreativeMetadata(creative)
    const theme: Theme = useTheme()

    const { clones, clonesLoading } = useCreativeClonesMetadata(creative)
    const [currentCSVData, setCurrentCSVData] = useState<string[][]>([]);

    return (
        <CreativeInformationWrapper>
            <BackButton
                className="back-btn"
                onBack={() => setView('overview')}
                text={`${metadata.creativeKindLabel} Review`}
            />
            <ManageClonesContext.Provider value={{
                creative,
                metadata,
                clones,
                clonesLoading,
            }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H2>Clone Information</H2>
                    <DownloadLink data={currentCSVData} filename={'clone-data'} disabled={clonesLoading}/>
                </div>
                <Body>
                    Clones are copies of your creative across different campaigns.
                </Body>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2rem',
                        margin: '2rem 0',
                    }}
                >
                    <DisplayMedia
                        creative={creative}
                        style={{
                            height: '10rem',
                            width: '10rem',
                            backgroundColor: theme.color.greyAccent2,
                        }}
                    />
                    <CreativeInformation />
                </div>
                <CreativeClonesTable
                    onCSVDataUpdated={(data) =>
                        !_.isEqual(data, currentCSVData) && setCurrentCSVData(data)
                    }
                    />
            </ManageClonesContext.Provider>
        </CreativeInformationWrapper>
    )
}

export default ManageClones
