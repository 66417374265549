// OVERLAY
export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const HIDE_OVERLAY = 'HIDE_OVERLAY'

// FRANCHISE LIST ACTIONS
export const LOAD_FRANCHISE_LIST = "LOAD_FRANCHISE_LIST";

export const CLEAR_FRANCHISE_LIST = "CLEAR_FRANCHISE_LIST";
export const SELECT_FRANCHISE = "SELECT_FRANCHISE";

// USER ACTIONS
export const LOAD_USER = "LOAD_USER";
export const CLEAR_USER = "CLEAR_USER";
export const ACKNOWLEDGE_TOS = "ACKNOWLEDGE_TOS"
export const TOGGLE_EXPERIMENT = "TOGGLE_EXPERIMENT"
export const LOAD_NETWORK_LOGIN_CONNECTIONS = "LOAD_NETWORK_LOGIN_CONNECTIONS"
export const SET_NETWORK_LOGIN_LOAD_STATUS = "SET_NETWORK_LOGIN_LOAD_STATUS"
export const UPDATE_USER_FIELD = "UPDATE_USER_FIELD"

// FRANCHISE ACTIONS
export const UPDATE_FRANCHISE = "UPDATE_FRANCHISE"
export const SET_LOADING = "SET_LOADING";
export const CLEAR_FRANCHISE = "CLEAR_FRANCHISE";
export const LOAD_FRANCHISE = "LOAD_FRANCHISE";
export const LOAD_MEMBER = "LOAD_MEMBER";
export const LOAD_MEMBERS = "LOAD_MEMBERS";
export const LOAD_LOCATIONS = "LOAD_LOCATIONS";
export const GET_FRANCHISE_IMAGES = "GET_FRANCHISE_IMAGES";
export const ADD_FRANCHISE_IMAGE = "ADD_FRANCHISE_IMAGE";
export const UPDATE_FRANCHISE_IMAGE = "UPDATE_FRANCHISE_IMAGE";
export const UPDATE_FRANCHISE_LOGO = "UPDATE_FRANCHISE_LOGO";
export const DELETE_FRANCHISE_IMAGE = "DELETE_FRANCHISE_IMAGE";
export const DELETE_FRANCHISE_LOGO = "DELETE_FRANCHISE_LOGO"
export const GET_FRANCHISE_LOGOS = "GET_FRANCHISE_LOGOS";
export const ADD_FRANCHISE_LOGO = "ADD_FRANCHISE_LOGO";
export const LOAD_TOP_TEXT_SUGGESTIONS = "LOAD_TOP_TEXT_SUGGESTIONS";
export const LOAD_FRANCHISE_LABELS = "LOAD_FRANCHISE_LABELS";
export const LOAD_FRANCHISE_COLORS = "LOAD_FRANCHISE_COLORS"
export const LOAD_FRANCHISE_APPROVALS = "LOAD_FRANCHISE_APPROVALS";
export const CREATE_MEMBER = "CREATE_MEMBER";
export const CHANGE_LABEL = "CHANGE_LABEL"
export const DELETE_LABEL = "DELETE_LABEL"
export const CHAT_GPT_SUGGESTIONS = "CHAT_GPT_SUGGESTIONS"
export const YOUTUBE_AI_SUGGESTIONS = "YOUTUBE_AI_SUGGESTIONS"

export const SELECT_LOCATION = "SELECT_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_FIELD = "UPDATE_LOCATION_FIELD";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const ADD_DEFAULT_LOGO = "ADD_DEFAULT_LOGO";

export const UPDATE_KEYWORDS = "UPDATE_KEYWORDS";
export const UPDATE_MULTIPLE_LOCATIONS_KEYWORDS = "UPDATE_MULTIPLE_LOCATIONS_KEYWORDS"
export const UPDATE_DEFAULT_CHANNELS = "UPDATE_DEFAULT_CHANNELS"

// FRANCHISEE VIEW
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";

// RESOURCES
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_CURRENCIES = "LOAD_CURRENCIES";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const LOAD_REJECTION_REASONS = "LOAD_REJECTION_REASONS";

//BUILD
export const SELECT_FORK = "SELECT_FORK";
export const MAGIC_OR_TEMPLATE_FORK = 'MAGIC_OR_TEMPLATE_FORK';
export const AD_OR_POST_FORK = "AD_OR_POST_FORK";
export const CREATION_TYPE = 'CREATION_TYPE';
export const SET_UPLOADED_MEDIA_DATA = 'SET_UPLOADED_MEDIA_DATA';
export const SELECT_CUSTOM_OR_PREMADE_FORK = "SELECT_CUSTOM_OR_PREMADE_FORK";
export const SELECT_YOUTUBE_OR_UPLOAD_FORK = "SELECT_YOUTUBE_OR_UPLOAD_FORK";
export const SET_YOUTUBE_URL = "SET_YOUTUBE_URL"
export const SET_BUILDER_LOCATION = "SET_BUILDER_LOCATION";
export const SET_BUILDER_LOGOS = "SET_BUILDER_LOGOS";
export const CLEAR_BUILD_TAB = "CLEAR_BUILD_TAB";
export const SET_LAST_EDITED = "SET_LAST_EDITED";
export const SET_BREADCRUMB_PATH = "SET_BREADCRUMB_PATH";
export const REMOVE_BREADCRUMB_PATH = "REMOVE_BREADCRUMB_PATH"
export const SET_BREADCRUMB_BULK = "SET_BREADCRUMB_BULK"
export const SET_URL_PATH = "SET_URL_PATH"
export const SET_BUILDER_FRANCHISE_IMAGES = 'SET_BUILDER_FRANCHISE_IMAGES'
export const SET_SELECTED_REPO_KEY = 'SET_SELECTED_REPO_KEY'
//BUILD IMAGE
export const CLEAR_IMAGE_BUILDER = "CLEAR_IMAGE_BUILDER";
export const SELECTED_FILTER = "SELECTED_FILTER";
export const TOGGLE_HEADER_FILTER = "TOGGLE_HEADER_FILTER";
export const TOGGLE_DESCRIPTION_FILTER = "TOGGLE_DESCRIPTION_FILTER";
export const TOGGLE_LOGO_FILTER = "TOGGLE_LOGO_FILTER";
export const HEADER_TEXT = "HEADER_TEXT";
export const DESCRIPTION_TEXT = "DESCRIPTION_TEXT";

export const LOAD_ALL_FONTS = "LOAD_ALL_FONTS";
export const LOAD_FONT = "LOAD_FONT";
export const DELETE_FONT = "DELETE_FONT";
export const GET_SELECTED_IMAGE = "GET_SELECTED_IMAGE";
export const GET_SELECTED_LOGO = "GET_SELECTED_LOGO";
export const GET_CANVAS = "GET_CANVAS";

export const ZOOM_IN_AND_OUT = "ZOOM_IN_AND_OUT";
export const INITIAL_TEMPLATE = "INITIAL_TEMPLATE";
export const LOAD_VIDEO_EXTENDED_TEXT = 'LOAD_VIDEO_EXTENDED_TEXT'
export const IMAGE_FILTERS = "IMAGE_FILTERS";
export const CLEAR_IMAGE_FILTERS = "CLEAR_IMAGE_FILTERS";
export const CANVAS_DIMENSIONS = "CANVAS_DIMENSIONS";
export const CANVAS_BORDERS = "CANVAS_BORDERS";
export const CANVAS_DESCRIPTION_FONT_SIZE = "CANVAS_DESCRIPTION_FONT_SIZE";
export const CANVAS_HEADER_FONT_SIZE = "CANVAS_HEADER_FONT_SIZE";
export const GET_Z_INDEX = "GET_Z_INDEX";
export const IS_Z_INDEX_SELECTED = "IS_Z_INDEX_SELECTED";
export const GET_IN_MEMORY_IMG = 'GET_IN_MEMORY_IMG'
export const CLEAR_SELECTED_ASSET = 'CLEAR_SELECTED_ASSET'
export const TOGGLE_LOGO_DYNAMIC = 'TOGGLE_LOGO_DYNAMIC'

// AUDIENCE ACTIONS
export const LOAD_LOCATION_AUDIENCES = 'LOAD_LOC_AUDIENCE';
export const UPLOAD_AUDIENCE_CSV = 'UPLOAD_AUDIENCE_CSV';
export const RESET_AUDIENCE_BLOB = 'RESET_AUDIENCE_BLOB';

// EMAIL ACTIONS
export const LOAD_LOCATION_EMAILS = 'LOAD_LOC_EMAILS';
export const UPDATE_EMAIL_HISTORY_SEARCH_VAL = 'UPDATE_EMAIL_HISTORY_SEARCH_VAL';
export const UPLOAD_EMAIL_HTML = 'UPLOAD_EMAIL_HTML';
export const UPDATE_SHOW_TEST_EMAILS = 'UPDATE_SHOW_TEST_EMAILS';
export const UPDATE_EMAIL_PREVIEW = 'UPDATE_EMAIL_PREVIEW';

// SMS ACTIONS
export const LOAD_LOCATION_SMS = "LOAD_LOC_SMS";
export const UPDATE_SMS_HISTORY_SEARCH_VAL = "UPDATE_SMS_HISTORY_SEARCH_VAL";
export const UPLOAD_SMS_MEDIA = "UPLOAD_SMS_MEDIA";
export const REMOVE_SMS_MEDIA = "REMOVE_SMS_MEDIA";
export const UPDATE_SMS_PREVIEW = 'UPDATE_SMS_PREVIEW';
export const CLEAR_SMS_TAB = 'CLEAR_SMS_TAB';

//MASTER REPO
export const REPO_SELECT_OPTION = "REPO_SELECT_OPTION"
export const REPO_LIST = "REPO_LIST";
export const UPDATE_REPO = "UPDATE_REPO";
export const REPO_CREATIVE_LIST = "REPO_CREATIVE_LIST";
export const BLUEPRINTS_LIST = "BLUEPRINTS_LIST";
export const CLEAR_REPO = "CLEAR_REPO";
export const CREATE_REPO = "CREATE_REPO";
export const DELETE_REPO_FOLDER = "DELETE_REPO_FOLDER"
export const UPDATE_BLUEPRINT = "UPDATE_BLUEPRINT"
export const ADD_BLUEPRINT = "ADD_BLUEPRINT"

//TAGS
export const UPDATE_GTM_CONTAINER_ID = "UPDATE_GTM_CONTAINER_ID";

//CORPORATE SETTINGS
export const UPDATE_RESTRICTIONS = 'UPDATE_RESTRICTIONS'
export const LOAD_RESTRICTIONS = 'LOAD_RESTRICTIONS'
export const UPDATE_FRANCHISOR_SETTINGS = "UPDATE_FRANCHISOR_SETTINGS"
export const LOAD_FRANCHISE_SETTINGS = 'LOAD_FRANCHISE_SETTINGS'

//EMAIL TEMPLATE
export const UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE = 'UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE'

// TOKENS
export const REFRESH_API_TOKENS = 'REFRESH_API_TOKENS'
export const TOKEN_DESCRIPTION = 'TOKEN_DESCRIPTION'
export const TOKEN_SCOPES = 'TOKEN_SCOPES'
export const TOKEN_APIKEY = 'TOKEN_APIKEY'
export const CLEAR_TOKEN_TAB = 'CLEAR_TOKEN_TAB'

//UTIL REDUCER
export const COLLAPSED_LOCATIONS = 'COLLAPSED_LOCATIONS'

//UI
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const LOAD_DEFAULTS = 'LOAD_DEFAULTS'
export const LOAD_BRAND_THEME = 'LOAD_BRAND_THEME'
export const UPDATE_BRAND_THEME_COLOR = 'UPDATE_BRAND_THEME_COLOR'
export const CLEAR_BRAND_THEME = 'CLEAR_BRAND_THEME'
export const GET_CLIENT_API_KEYS = 'GET_CLIENT_API_KEYS'
export const TOGGLE_SIDEBAR= 'TOGGLE_SIDEBAR'
export const SET_ANIMATION = 'SET_ANIMATION'

//ADVANCED FILTERS
export const LOCATION_FILTERS = 'LOCATION_FILTERS'
export const CLEAR_FILTERS = "CLEAR_FILTERS"

//CALENDAR TAB
export const LOAD_CREATIVE_FOLDERS = "LOAD_CREATIVE_FOLDERS"
export const LOAD_CREATIVE_FOLDER_CREATIVES = "LOAD_CREATIVE_FOLDER_CREATIVES"
export const CLEAR_CALENDAR_TAB = "CLEAR_CALENDAR_TAB"

//SUMMARIES
export const LOAD_MEMBER_SUMMARIES = "LOAD_MEMBER_SUMMARIES"
export const CLEAR_MEMBER_SUMMARIES = "CLEAR_MEMBER_SUMMARIES"
