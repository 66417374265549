import { MakeLoadingCheck, NonOptional } from "../reducers/franchiseReducerTypes"
import { EulerityLocation } from "./location"
import { UserInfo } from "./user"
import { LocationId, UserId, Email, Websafe, PermissionableLocationField, LocationFieldAccessLevel } from "./primitives"
import { Subscription } from "./subscription"
import { Media } from "./media"
import { KeywordGroup } from "./keywords"
import { EulerityFont } from "../helpers/font.utils"
import { CurrencyCode } from "../helpers/currency.util"
import { TopTexts, VideoTopTexts } from "../features/creatives/creative.types"


export type FranchiseFields = {
  adwordsGlobalSiteTags?: any
  allowInactivePosting: boolean
  allowLocCreationWithoutBlueprint: boolean
  allowZorsToSetGa4Filters: boolean
  alternateUsers?: UserId[]
  approvals: boolean
  brandingBlob?: string
  callForwardingOptIn: boolean
  csm?: Email
	brandStrategist?: Email
	csl?: Email
	director?: Email
  dataStudioLink?: string
  defaultCurrency: CurrencyCode
  defaultLid?: LocationId
  defaultLocation?: EulerityLocation
  defaultUid?: UserId
  disabledClientPermissions: any[]
  disableStockFonts: boolean
  disableStockImages: boolean
  disableStockLogos: boolean
  displayName: string
  dynamicLink?: string
  enableAnalyticsGoalsViewBySource: boolean
  enableAnalyticsSessionsViewBySource: boolean
  enableCallForwarding: boolean
  enableCreativeApproval: boolean
  exposeStripeBalance: boolean
  facebookPixelSnippets: any
  featureChatGptCost: number
  featureChatGptEnabled: boolean
  featureReputationManagementCost: number
  featureReputationManagementEnabled: boolean
  featureEmailCreativeCost: number
  featureEmailCreativeEnabled: boolean
  featureSmsCreativeCost: number
  featureSmsCreativeEnabled: boolean
  featureSocialPostingCost: number
  featureSocialPostingEnabled: boolean
  friendlyDynamic?: string
  gtmContainer?: string
  keywordGroups?: KeywordGroup[]
  locationFieldAccessLevels: Record<PermissionableLocationField, LocationFieldAccessLevel>
  managers: Email[]
  members: UserId[]
  minIntervalCount: number
  monthlySaas?: number
  name: string
  numAllowedSubscriptions: number
  quarterlySaasDiscount?: number
  salesContact?: Email
  searchDescriptions: any
  searchHeadlines: any
  searchTermReportEnabled: boolean
  semiAnnuallySaasDiscount?: number
  sendMonthlyReports: boolean
  showTimelineView: boolean
  subscriptionEmailTemplate?: any
  subscriptionInterval?: number
  subscriptionMax?: number
  subscriptionMin?: number
  subscription?: Subscription
  supportLink?: string
  suppressCorporateSubs: boolean
  theme?: any
  tosLink?: string
  variables: any[]
  yearlySaasDiscount?: number
} & FranchiseInjectFields

// Not on the get request but these are guarantees when the franchise is loaded
type FranchiseInjectFields = {
  locations: EulerityLocation[]
}

export type FranchiseStateFields = {
  allFonts?: EulerityFont[]
  approvals?: boolean
  approvalsList?: any[]
  chatGPT?: any
  youtubeAISuggestions?: any
  emailDep?: boolean
  defaultLocation?: EulerityLocation
  franchiseColors?: any[]
  franchiseFonts?: {
    fonts: EulerityFont[][],
    name: string,
  }
  franchiseImages?: {
    images: Media[],
    name: string,
  },
  franchiseLogos?: {
    images: Media[],
    name: string,
  },
  gtmContainerId?: string
  keywordGroups?: KeywordGroup[]
  labels?: string[]
  memberList?: UserInfo[]
  selectedLocationWebsafe?: Websafe
  subscription?: Subscription
  smsDep?: boolean
  tokens?: any[]
  topTexts?: TopTexts
  videoTopTexts?: VideoTopTexts | null
  hideGA4DataFromLocations?: boolean
  hideBrandAwarenessDataFromLocations?: boolean;
  minIntervalCount: number
}

export type FranchiseState = FranchiseStateFields & Franchise
export type Franchise = MakeLoadingCheck<FranchiseFields>

export type LoadedFranchise = NonOptional<Franchise>

export const isInLoadedState = (franchise: FranchiseState, initialState: FranchiseState): boolean => {
  if (!franchise) return false

  // This is okay since the default state has all fields defined (just with garbage values)
  if (franchise.name === initialState.name) return false

  return true
}