/**
 * A utility object to perform various operations related to numbers.
 * @namespace NumberUtil
 */
const NumberUtil = {
    /**
     * Checks arg if it is a number
     * @function isNumber
     * @memberof NumberUtil
     * @param {any} val - What we're checking
     * @returns {boolean} - Whether its a number or not
     */

    isNumber: (val: any): boolean => typeof val === 'number',
    /**
     * Formats number to a specified number of decimals
     * @function formatNumber
     * @memberof NumberUtil
     * @param {number} value - The number to format
     * @param {number} decimals - The amount of decimals to show
     * @returns {string} - The formatted number string
     */
    formatNumber: (value: number, decimals?: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            maximumFractionDigits: decimals,
        }).format(value)
    },
    //TODO: deprecate this because we should just use the Intl API directly
    /**
     * Formats currency number
     * @function formatCurrencyNumber
     * @memberof NumberUtil
     * @param {number} value - The number to format
     * @param {number} currency - The currency to display this amount
     * @param {Intl.NumberFormatOptions} settings - To configure formatting of currency
     * @returns {string} - The formatted number string
     */
    formatCurrencyNumber: (value: number, currency: string, settings?: Intl.NumberFormatOptions) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            ...settings
        }).format(value)
    },
    /**
     * Formats number to a specified number of decimals
     * @function averageNumberArray
     * @memberof NumberUtil
     * @param {number[]} arr - The array of numbers
     * @returns {number} - Average of the number array
     */
    averageNumberArray: (arr: number[]) => {
        if (arr.length) return arr.reduce((acc, v) => acc + v) / arr.length
        return 0
    },
    /**
     * Formats number to a specified number of decimals
     * @function countDigits
     * @memberof NumberUtil
     * @param {number} num - The number value
     * @returns {number} - The number of digits this number has. Useful for determining space needed in the UI
     */
    countDigits: (num: number) => {
        if (typeof num !== 'number') return 0
        return num.toString().length
    },
    percentChange: (newVal: number, oldVal: number) => {
        if (typeof newVal !== 'number' || typeof oldVal !== 'number') return 0
        if (oldVal === 0) return 0
        return (newVal - oldVal) / oldVal * 100
    },
    formatPercent: (value: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            maximumFractionDigits: 2,
        }).format(value)
    },
    formatNumberShort(num: number) : string {
        return new Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 2 }).format(num);
    }
}

export default NumberUtil
