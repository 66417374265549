export const OBJECTIVE = {
    TRAFFIC: {
        VALUE: 'traffic',
        LABEL: 'Maximize Traffic and Conversions'
    },
    LEADS: {
        VALUE: 'leads',
        LABEL: 'Meta Lead Generation'
    },
    REACH: {
        VALUE: 'reach',
        LABEL: 'Increase Brand Awareness'
    },
} as const

type Objective = keyof typeof OBJECTIVE
export type ObjectiveValue = typeof OBJECTIVE[keyof typeof OBJECTIVE]['VALUE']

export const displayObjective = (_objective: string) => {
    const objective = _objective?.toUpperCase()
    if (objective in OBJECTIVE) return OBJECTIVE[objective as Objective].LABEL
    return ""
}

export const hasIncompatibleChannels = (objective: ObjectiveValue, channelMap: Record<string, number>) => {
    // No channels are set
    if (!channelMap || !Object.keys(channelMap).length) return false

    // Handle different objective cases
    switch (objective) {
        case OBJECTIVE.LEADS.VALUE:
            return Object.keys(channelMap).find(channelName => !channelName?.toLowerCase().includes('facebook'))
        default:
            return false
    }
}