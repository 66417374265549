import React from 'react'
import { useCreativeContext } from '../CreativeModalV2';
import PostNetworkStats from './PostNetworkStats';
import PostDetailsTable from './PostDetailsTable';
import PostMetrics from './PostMetrics';

const PostDetailsView = () => {
    const { metadata } = useCreativeContext()
    const { isFolder, isDeployedPost } = metadata

    return (
        <div>
            <PostDetailsTable />
            { isDeployedPost && !isFolder &&
                <>
                    <PostNetworkStats />
                    <PostMetrics />
                </>
            }
        </div>
    )
}

export default PostDetailsView