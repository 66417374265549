import { API } from "../../constants/api";
import { batchifyRequest, makeRequest } from "../../helpers/make-request";
import * as fflate from 'fflate';
import { BTFilter, JobModalType, JobTasksResponse, SUPPORTED_JOBS } from "./types";
import { MakeRequest } from "../../types/api";

export const handlePercentChange = (val: number = 0, circle: SVGCircleElement, color: string): void => {
    if (isNaN(val)) val = 100;

    val = Math.max(0, Math.min(100, val));

    if(val === 0) circle.style.stroke = 'transparent'
    else circle.style.stroke = color;

    const r = parseInt(circle?.getAttribute('r') ?? "0");
    const c = 2 * Math.PI * r;
    const pct = ((100 - val) / 100) * c;

    circle.setAttribute('stroke-dasharray', c.toString());
    circle.style.strokeDashoffset = pct.toString();
};


export async function getActiveJobsList(franchise: string) {
    if (!franchise) return Promise.reject(new Error("Franchise is required"));

    try {
        const [jobs] = await Promise.all([batchifyRequest(makeRequest().param("franchise", franchise).url(API.JOBS.LIST_ACTIVE_JOBS)).run()])
        return Promise.resolve(jobs.filter(job => SUPPORTED_JOBS.includes(job.description || "")));
    } catch (error) {
        return Promise.reject(new Error("Failed to retrieve active jobs"));
    }
}

async function getJobTasksList(jobId: number) {
    return Promise.all([batchifyRequest(makeRequest().param("jobId", jobId).url(API.JOBS.LIST_TASKS)).run()])
}

export async function callAndDecompressJob(selectedJobId: number, shouldFilterSuccessfulResponses: boolean = true): Promise<JobTasksResponse[]> {
    try {
        let [data] = await getJobTasksList(selectedJobId);
        if (shouldFilterSuccessfulResponses) data = data.filter(filterSuccessfulResponses);

        const decompressPromises = data.map(async (response: JobTasksResponse) => {
            if (!response.gzipped) {
                response.decodedResponse = {};
                return Promise.resolve(response);
            }
            const uint8Data = new Uint8Array(response.gzipped);
            const decompressed = fflate.gunzipSync(uint8Data);
            const jsonObject = JSON.parse(new TextDecoder().decode(decompressed));
            delete response.gzipped;
            response.decodedResponse = jsonObject;
            return Promise.resolve(response);
        });

        const results = await Promise.allSettled(decompressPromises);

        const successfulResults = results
            .filter((result): result is PromiseFulfilledResult<JobTasksResponse> => result.status === 'fulfilled')
            .map(result => result.value);
        return successfulResults;
    } catch (error) {
        return Promise.reject(error);
    }
}

const isSuccessfulStatusCode = (btfilter: BTFilter) => btfilter.statusCode >= 200 && btfilter.statusCode <= 299
const filterSuccessfulResponses = (res: BTFilter) => !isSuccessfulStatusCode(res)

export const isJobModalType = (description: string): description is JobModalType => {
    return SUPPORTED_JOBS.some(it => it === description)
}



//* fetchFirstSuccessfulResponse, attempts to make a request using different sets of query parameters from a list. It tries a specified number of query parameter sets (numOfQueryParamsToTry) or the length of the list, whichever is smaller. It returns the response from the first successful request or null if none succeed.

export const fetchFirstSuccessfulResponse = async (
    listOfQueryParams: Record<string, string | number | boolean >[],
    request: MakeRequest<any>,
    numOfQueryParamsToTry: number = 4
    ) => {

    if (!listOfQueryParams.length) {
      return Promise.resolve(null);
    }

    let response = null;
    const maxQueryParamsLength: number = listOfQueryParams.length > numOfQueryParamsToTry ? numOfQueryParamsToTry : listOfQueryParams.length;
    let i: number = 0;

    while (i < maxQueryParamsLength && !response) {
      try {response = await request.params(listOfQueryParams[i]).get()} catch (_) {i++;}
    }

    return response;
  };