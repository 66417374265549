import React, { useState } from "react";
import {
  StyledSearchPreview,
  StyledSearchAd,
  OptionalPreviews,
  StyleDisclaimerWarning,
  SearchAdInfo,
  StyledSearchBg
} from "./styles";
import googleIcon from "../../assets/images/search-logo.png";
import { MdPhone } from "react-icons/md";
import { ReactComponent as Planet } from "../../assets/images/Planet.svg"
import SearchTextModal from "../../pages/build/modals/SearchTextModal";
import { H2, XS } from "../../styles/typography";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PREVIEW_PLACEHOLDER_TEXT } from "../../helpers/constants";

const SearchPreview = ({ creative, previewText }) => {
  const {
    searchHeadline,
    responsiveSearchHeadline,
    searchDescription,
    responsiveSearchDescription
  } = previewText;

  const [showSearchText, setShowSearchText] = useState(false)

  return (
    <StyledSearchPreview>
      <OptionalPreviews>
        <div className="optional-prev-wrapper">
          <img
            className="optional-prev-image"
            src={googleIcon}
            alt="google icon"
          />
          <H2>Search Preview</H2>
        </div>
        <XS underline color="creativeColor1" className="searchTextList" onClick={() => setShowSearchText(true)}>View your ad’s headline and description list</XS>
      </OptionalPreviews>
      <StyledSearchBg>
        <StyledSearchAd>
          <SearchAdInfo>
            <div className="header-wrapper">
              <div className="icon-wrapper">
                <Planet />
              </div>
              <div className="url-wrapper" style={{ color: '#3C4043' }}>
                <p style={{ fontSize: '14px', lineHeight: '16px' }}>{PREVIEW_PLACEHOLDER_TEXT.businessPlaceholder}</p>
                <p style={{ fontSize: '10px', lineHeight: '10px' }}>{PREVIEW_PLACEHOLDER_TEXT.domainPlaceholder}</p>
              </div>
              <BsThreeDotsVertical size={12} style={{ color: '#6F7479' }} />
            </div>
            <div className="ad-body">
              <p style={{ fontSize: '20px', color: '#1A0DAB', lineHeight: '20px', marginBottom: '4px' }}>
                {responsiveSearchHeadline?.length > 0 ? responsiveSearchHeadline : searchHeadline}
              </p>
              <p style={{ fontSize: '12px', color: '#464A50', lineHeight: '18px' }}>
                {responsiveSearchDescription?.length > 0 ? responsiveSearchDescription : searchDescription}
              </p>
            </div>
          </SearchAdInfo>
          <div className="phone-wrapper">
            <MdPhone size={20} />
            <p style={{ fontWeight: 300, fontSize: '12px' }}>Call {PREVIEW_PLACEHOLDER_TEXT.locationPhone}</p>
          </div>
        </StyledSearchAd>
      </StyledSearchBg>
      <StyleDisclaimerWarning>
        <XS center>
          Google will dynamically shift headlines and descriptions for better performance
        </XS>
        <XS center>
          Google Search does not support Emojis
        </XS>
      </StyleDisclaimerWarning>
      {showSearchText && <SearchTextModal creative={creative} onClose={() => setShowSearchText(false)} />}
    </StyledSearchPreview>
  );
};

export default SearchPreview;
