import { API } from "../constants/api"
import { ResponseTypes } from "./apiResponses"

type ExtractFrom<T, U> = T extends U ? T : ExtractFrom<T[keyof T], U>

export type HTTPMethod = 'GET' | 'POST'
export type ApiPath = ExtractFrom<typeof API, string> | undefined

export type MakeRequestCache = {
  isInCache: <T extends ApiPath = undefined>(request:  MakeRequest<T>) => boolean
  get: <T extends ApiPath = undefined>(request: MakeRequest<T>) => Promise<ApiPromise<T>>
  set: <T extends ApiPath = undefined>(request: MakeRequest<T>, data:  Promise<ApiPromise<T>> ) => void
}


// Generate a listable datatype for a datatype T
export type Listable<T> = {
  paginatedData: T[],
  cursor?: string
}

// Generate a list of all the listable datatypes indexed by the url
export type ListableResponseTypes = {
  [P in keyof ResponseTypes as ResponseTypes[P] extends Listable<any> ? P : never]: ResponseTypes[P]
}

// Generate a list of all urls that return a listable datatype
export type ApiPathPaginated = {
  [P in keyof ResponseTypes]: ResponseTypes[P] extends Listable<any> ? P : never;
}[keyof ResponseTypes]

// translate from url to the datatype
export type ApiPromise<T> = T extends keyof ResponseTypes ? ResponseTypes[T] : never

export type MakeRequestOptions= {
    url?: ApiPath
    searchParams?: URLSearchParams
    body?: Record<string, string | number>
    retry?: number
    cache?: MakeRequestCache
    mock?: ApiPromise<ApiPath>
    enableMockData?: () => boolean
}

// NOTE: If you modify the MakeRequest type, you must also modify the isMakeRequest function
export type MakeRequest<T extends ApiPath> = {
    withCache: (cache: MakeRequestCache) => MakeRequest<T>
    _getOptions: () => MakeRequestOptions
    _log: () => {}
    url: <J extends ApiPath>(_url: J) => MakeRequest<J>
    retry: (n: number) => MakeRequest<T>
    param: (key: string, value: string | number) => MakeRequest<T>
    params: (object: Record<string, string | number | boolean>) => MakeRequest<T>
    body: (obj: Record<string, any>) => MakeRequest<T>
    get: () => Promise<ApiPromise<T>>
    post: () => Promise<ApiPromise<T>>
    equals: <J extends ApiPath>(other: MakeRequest<J>) => boolean
    getKey: () => string
    mock: (data: ApiPromise<T>) => MakeRequest<T>
}

export const isMakeRequest = <T extends ApiPath>(
  data: any
): data is MakeRequest<T> => {
  if (isBatchifyRequest(data)) {
      return false
  }
  if (!('_getOptions' in data) || typeof data._getOptions !== 'function') return false
  if (!('_log' in data) || typeof data._log !== 'function') return false
  if (!('url' in data) || typeof data.url !== 'function') return false
  if (!('retry' in data) || typeof data.retry !== 'function') return false
  if (!('param' in data) || typeof data.param !== 'function') return false
  if (!('params' in data) || typeof data.params !== 'function') return false
  if (!('body' in data) || typeof data.body !== 'function') return false
  if (!('get' in data) || typeof data.get !== 'function') return false
  if (!('post' in data) || typeof data.post !== 'function') return false
  if (!('equals' in data) || typeof data.equals !== 'function') return false
  if (!('getKey' in data) || typeof data.getKey !== 'function') return false
  return true
}

export type BatchableMakeRequest<T extends keyof ListableResponseTypes> = Omit<MakeRequest<T>, 'get' | 'post'> & {
  get: () => Promise<ListableResponseTypes[T]>
  post: () => Promise<ListableResponseTypes[T]>
}

// NOTE: If you modify the BatchifyRequest type, you must also modify the isBatchifyRequest function
export type BatchifyRequest<T extends ApiPathPaginated> = {
  run: (method?: HTTPMethod) => Promise<ListableResponseTypes[T]['paginatedData']>
  setDataHandler: (cb: DataHandler<T>) => void
  getKey: () => string
  equals: <J extends ApiPathPaginated>(other: BatchifyRequest<J>) => boolean
}

export const isBatchifyRequest = <T extends keyof ListableResponseTypes>(
    data: any
): data is BatchifyRequest<T> => {
  if (!('run' in data) || typeof data.run !== 'function') return false
  if (!('setDataHandler' in data) || typeof data.setDataHandler !== 'function') return false
  if (!('getKey' in data) || typeof data.getKey !== 'function') return false
  return true
}

export type NextRequest = () => void
export type DataHandler<T extends keyof ListableResponseTypes> = (_data: ListableResponseTypes[T]['paginatedData'], next: NextRequest, isEnd: boolean) => void

export type PaginatedResponse<T> = {
  initialLoad: boolean;
  data: T[];
  hasLoadedAll: boolean;
  isLoading: boolean;
  handleForceRefresh: () => void
  loadMore: () => void;
  enabled: boolean;
  error: unknown;
};