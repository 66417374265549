import React from 'react'
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";
import styled, { CSSProperties } from 'styled-components';
import { Span } from '../../styles/typography';

type DownloadLinkProps = {
    data: any,
    filename: string | string[],
    disabled?: boolean,
    style?: CSSProperties,
}

const DownloadLink = ({
    data,
    filename,
    disabled = false,
    style = {},
}: DownloadLinkProps) => {

    const isMultiple = Array.isArray(data) && Array.isArray(filename);

    if(isMultiple) {

        const isFirstElementArrayofObjects = (data: any): data is Record<string, any>[] => {
            return Array.isArray(data) && data.length > 0 && typeof data[0] === 'object' && !Array.isArray(data[0]);
        }

        const isFirstElementArrayOfArrays = (data: any): data is any[][] => {
            return Array.isArray(data) && data.length > 0 && Array.isArray(data[0]);
        }

        const createCSVBlob = (data: any): Blob => {
            let csv = '';
            if (isFirstElementArrayofObjects(data)) {
                const headers = Object.keys(data[0]);
                csv += headers.join(',') + '\n';
                csv += data.map((row: any) => headers.map(header => row[header]).join(',')).join('\n');
            } else if (isFirstElementArrayOfArrays(data)) {
                csv += data.map((row: any) => row.join(',')).join('\n');
            } else {
                throw new Error('Unsupported data format');
            }
            return new Blob([csv], { type: 'text/csv' });
        };


        const downloadCSV = (blob: Blob, filename: string): void => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        };

        const handleDownload = async () => {
            for (let i = 0; i < data.length; i++) {
                const blob = createCSVBlob(data[i]);
                downloadCSV(blob, `${filename[i] || `data-${i}`}.csv`);
                await new Promise(resolve => setTimeout(resolve, 100));
            }
        }

        return (
            <StyledDownloadLink disabled={disabled} style={{ ...style }} onClick={handleDownload}>
                <FiDownload style={{ width: '16px' }} />
                <Span>Download CSVs</Span>
            </StyledDownloadLink>
        );
    }

    return (
        <StyledDownloadLink disabled={disabled} style={{...style}}>
            <FiDownload style={{width:'16px'}}/>
            <CSVLink uFEFF={false} data={data || []} filename={filename ? `${filename}.csv` : 'data.csv'}>
                <Span>Download CSV</Span>
            </CSVLink>
        </StyledDownloadLink>
    )
}

// * For cases where the download link isn't an actual file (ex: performance reports)
export const DownloadLinkRedirect = ({onClick} : {onClick : () => void}) => {
    return (
        <StyledDownloadLink onClick={onClick}>
            <FiDownload style={{width:'16px'}}/>
            <Span color='accentColor2'>
                Download
            </Span>
        </StyledDownloadLink>
    )
}

const StyledDownloadLink = styled.div<Partial<Pick<DownloadLinkProps, 'disabled'>>>`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    pointer-events: ${props => props.disabled && 'none'};

    * {
        color: ${props => props.disabled ? props.theme.color.textColor1 : props.theme.color.accentColor2};
        opacity: ${props => props.disabled && '60%'};
    }

    span {
        border-bottom: ${props => props.disabled ? 'none' : '0.5px solid'};
    }

    &:hover {
        span {
            border-bottom-width: 1px;
        }
    }
`
export default DownloadLink