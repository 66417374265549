import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { StyledModalV2, ModalOverlay } from '../styles';
import { ModalContextProvider } from '../context/ModalContextProvider';
import { ModalWrapperProps } from '../types';

//* This is the new ModalV2 Wrapper Component
//* Usage: Wrapper for all future new modals.
//* Puts all props into a context provider so that all children components have access
//* Takes care of the sizing, padding, and spacing of the modal, nav, and title components via size prop
//* Adding the ModalNav component inside this wrapper will automatically increase paddingTop
const ModalWrapper = ({
  size = "md",
  onClose: handleClose,
  onBack: handleBack,
  styles,
  children
}: ModalWrapperProps) => {

  // adds extra paddingTop if ModalNav component exists as a child
  const modalNavRef = useRef(null)
  const [hasNav, setHasNav] = useState(false)

  return ReactDOM.createPortal(
    <ModalContextProvider size={size} onClose={handleClose} onBack={handleBack} modalNavRef={modalNavRef} setHasNav={setHasNav}>
      <ModalOverlay>
        <StyledModalV2
          style={{ ...styles }}
          size={size}
          id="modal-ref"
          hasNav={hasNav}
        >
          {children}
        </StyledModalV2>
      </ModalOverlay>
    </ModalContextProvider>,
    document.getElementById("modal") as HTMLDivElement
  );
}

export default ModalWrapper;
