import styled, { css } from 'styled-components'
import { _C } from './constants'

export const StyledDatePickerBtn = styled.button<{
    picker: string
    selectedPicker: string | null
}>`
    background-color: inherit;
    border-radius: 9px;
    height: 38px;
    width: 160px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.color.accentColor2};
    color: ${(props) => props.theme.color.textColor1};
    padding: 0.5rem 1rem;

    svg {
        fill: ${(props) => props.theme.color.accentColor2};
    }
    transition: all 0.5s;
    :hover {
        background-color: ${(props) => props.theme.color.accentColor4};
    }

    ${(props) =>
        props.picker === _C.START_DROPDOWN &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `}
    ${(props) =>
        props.picker === _C.END_DROPDOWN &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        `}
`
