import React, { createContext, useState, ReactNode } from 'react';
import { Tooltip } from './Tooltip';

type TooltipContextProps = {
  tooltipContent: React.ReactNode;
  tooltipVisible: boolean;
  targetRef: HTMLElement | null;
  showTooltip: (content: React.ReactNode, targetRef: HTMLElement) => void;
  hideTooltip: () => void;
};

export const TooltipContext = createContext<TooltipContextProps | undefined>(
  undefined
);

export const TooltipProvider = ({ children }: { children: ReactNode }) => {
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null);

  const showTooltip = (content: React.ReactNode, targetRef: HTMLElement) => {
    setTargetRef(targetRef);
    setTooltipContent(content);
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
    setTargetRef(null);
    setTooltipContent(null);
  };

  return (
    <TooltipContext.Provider
      value={{
        tooltipContent,
        tooltipVisible,
        targetRef,
        showTooltip,
        hideTooltip,
      }}
    >
      {children}
      <Tooltip />
    </TooltipContext.Provider>
  );
};
