import React from "react"
import { MagicBell } from "../../../pages/build/icons"
import { H2 } from "../../../styles/typography"
import CloneCreativeModal from "../CloneCreatives/CloneCreativeModal"
import AdDetailsView from "./Ad/AdDetailsView"
import { useCreativeContext } from "./CreativeModalV2"
import PostDetailsView from "./Post/PostDetailsView"
import { CreativeTypeHeader, CreativeModalBody, CreativeModalRightContent } from "./styles"
import { getMagicDescription } from "./utils"
import CreativeActions from "./CreativeActions/index"

export const CreativeOverview = () => {

    const { creative, metadata, needsApproval, isCloneModalOpen, setIsCloneModalOpen } = useCreativeContext()

    // * Render View
    const renderCreativeType = () => {
        if (metadata.isPostEntity) return <PostDetailsView />
        else return <AdDetailsView />
    }

    return (
        <>
            <CreativeTypeHeader>
                <H2>
                    {metadata.isMagicEntity && <MagicBell />}
                    {metadata.creativeKindLabel} {needsApproval ? 'Review' : 'Preview'}
                </H2>
                {getMagicDescription(creative, metadata.user)}
            </CreativeTypeHeader>
            <CreativeModalBody>
                <CreativeActions />
                <CreativeModalRightContent>
                    {
                        renderCreativeType()
                    }
                </CreativeModalRightContent>
            </CreativeModalBody>
            {
                isCloneModalOpen &&
                <CloneCreativeModal
                    creatives={[creative]}
                    onClose={() => setIsCloneModalOpen(false)}
                    onBack={() => setIsCloneModalOpen(false)}
                />
            }
        </>
    )
}