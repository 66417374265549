import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useFilterOptionEntity } from '../advancedFilterV2/hooks/useAdvancedFilterEntity';
import useFilterOptions from '../advancedFilterV2/hooks/useFilterOptions';
import useDynamicSetting from '../../hooks/useDynamicSetting';
import { useSelector } from 'react-redux';
import { ADVANCED_FILTER_ITEM, CATEGORY_TAB_NAME, CATEGORY_TYPES } from '../advancedFilterV2/constants';
import { doesBlueprintMatch, doesDefaultLocationMatch, doesLocationMatch, isBlueprint, isCampaign, isFolder, TAB_TYPES } from './filters.utils';
import InputWithIcon from '../../components/reusableUI/components/inputWithIcon';
import { FaSearch } from 'react-icons/fa';
import { OutlineBtn } from '../../styles/buttons';
import { FilterIcon } from '../../styles/icons';
import AdvancedFilterV2 from '../advancedFilterV2/components/AdvancedFilterV2';
import ActiveFilters from './ActiveFilters';
import SearchOptions from './SearchOptions';
import SelectedOptions from './SelectedOptions';
import { StyleOptionsContainer, StyledSearchContainer, StyledStepWrapper } from './styles';
import { SuperTableCustomFilterCloseTarget } from '../../components/SuperTable/style';
import useEventListener from '../../hooks/useEventListener';
import { useAccess } from '../../hooks/useAccess';

// * This component is used for the location step selector, as well as audience selectors

const EntityResultsSelector = ({
    initialLocations,
    onSelect,
    isAd,
    options,
    colorScheme = 'default',
    value
}) => {
    const { getLabelOptions, getStatusOptions, getUserOptions, getTypeOptions, getObjectiveOptions } = useFilterOptions()
    const { createFromLocation } = useFilterOptionEntity();
    const { getPaidFeatures } = useDynamicSetting()
    const { isMember } = useAccess()
    const locations = useSelector(s => s.franchise.locations)
    const controlled = !!value
    const selectedRepoKey = useSelector((state) => state.build.selectedRepoKey);

    // * Entity Tab Selector
    const [selectedTab, setSelectedTab] = useState(selectedRepoKey ? TAB_TYPES.FOLDERS : TAB_TYPES.CAMPAIGNS);

    const itemsToDisplay = useMemo(() => {
        const display = []
        options.forEach(opt => {
            if (opt.type === 'location') display.push(ADVANCED_FILTER_ITEM.LOCATIONS)
            if (opt.type === 'repo') display.push(ADVANCED_FILTER_ITEM.REPOS)
            if (opt.category === 'Blueprints' || opt.category === 'default-campaign-setup') display.push(ADVANCED_FILTER_ITEM.BLUEPRINTS)
        })

        return [...new Set(display)]
    }, [options])

    // * Default Filter Variable
    const DEFAULT_FILTERS = {
        [CATEGORY_TYPES.LABEL]: [],
        [CATEGORY_TYPES.STATUS]: [],
        [CATEGORY_TYPES.USER]: [],
        [CATEGORY_TYPES.TYPE]: [],
        [CATEGORY_TYPES.OBJECTIVE]: [],
    }

    // * userOptions
    const entities = useMemo(() => {
        const entityArray = [];
        locations?.forEach(loc => entityArray.push(createFromLocation(loc)))
        return entityArray
    }, [locations, createFromLocation])

    // * Filter Object
    const categories = [
        {
            categoryName: CATEGORY_TAB_NAME.TYPE,
            options: getTypeOptions(itemsToDisplay)
        },
        {
            categoryName: CATEGORY_TAB_NAME.LABEL,
            options: getLabelOptions()
        },
        {
            categoryName: CATEGORY_TAB_NAME.USER,
            options: getUserOptions(entities)
        },
        {
            categoryName: CATEGORY_TAB_NAME.STATUS,
            options: getStatusOptions()
        },
        {
            categoryName: CATEGORY_TAB_NAME.OBJECTIVE,
            options: getObjectiveOptions()
        }
    ]

    // * State
    const [_selectedOptions, _setSelectedOptions] = useState([])

    const selectedOptions = useMemo(() => {
        return value ?? _selectedOptions
    }, [value, _selectedOptions])

    const setSelectedOptions = useCallback((options) => {
        if (!controlled) {
            _setSelectedOptions(options)
            return
        }
        let x = options
        if (typeof x === 'function') {
            x = options(value)
        }
        onSelect?.(x)
    }, [value, onSelect])

    const [searchTerm, setSearchTerm] = useState('')
    const [filters, setFilters] = useState(DEFAULT_FILTERS)
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    // * SELECTION METHODS -- FOR LOCATION OPTIONS
    const resolveSelect = option => {
        if (selectedOptions.find((currOption) => option.value === currOption.value)) return
        setSelectedOptions(prev => [...prev, option])
    }

    const resolveDeselect = options => {
        const updatedSelection = selectedOptions.filter(option => option.value !== options.value)
        setSelectedOptions(updatedSelection)
    }

    const resolveSelectAll = (options) => {
        if (!selectedOptions.length) {
            setSelectedOptions(options.filter(opt => opt.displayName.toLowerCase().includes(searchTerm)))
            return
        }
        let remainingOptions = options.filter(option => selectedOptions.every(selOpt => selOpt.value !== option.value))
        setSelectedOptions(prev => [...prev, ...remainingOptions.filter(opt => opt.displayName.toLowerCase().includes(searchTerm))])
    }

    const resolveDeselectAll = (options) => {
        const filteredValues = selectedOptions.filter((sOption) => !options.some((option) => sOption?.value === option.value))
        setSelectedOptions(filteredValues)
    }

    // * Pre-select on edit
    useEffect(() => {
        if (controlled) return
        if (!initialLocations?.length) {
            setSelectedOptions([])
            return
        }
        setSelectedOptions(initialLocations)
    }, [initialLocations])

    // * Update selected options (onSelect)
    useEffect(() => {
        if (controlled) return
        onSelect?.(selectedOptions)
    }, [selectedOptions])

    const filteredOptions = useMemo(() => {
        return options.filter(option => {
            let doesMatch = true;
            if (option.type === 'location') doesMatch = doesLocationMatch(option, filters)
            if (isBlueprint(option?.category)) doesMatch = doesBlueprintMatch(option, filters)
            if (option.type === 'repo' || option.category === 'default-campaign-setup') doesMatch = doesDefaultLocationMatch(option, filters)
            return doesMatch
        })
    }, [filters, options, getPaidFeatures])

    // * Will enable which tabs to show (we look at options, as filtered options actually changes dynamically and can result in tabs from being removed)
    const presentOptionTypes = useMemo(() => {
        const containsCampaigns = options?.find(opt => isCampaign(opt?.category))
        const containsFolders = options?.find(opt => isFolder(opt?.category))
        const containsBlueprints = options?.find(opt => isBlueprint(opt?.category))

        return {
            hasCampaigns: containsCampaigns,
            hasFolders: containsFolders,
            hasBlueprints: containsBlueprints
        }

    }, [options])

    const onClick = useCallback((e) => {
        setIsFilterOpen(false);
    }, []);

    useEventListener('click', onClick);


    return (
        <StyledStepWrapper>
            <StyledSearchContainer>
                <InputWithIcon
                    icon={<FaSearch />}
                    type='text'
                    placeholder={isMember() ? 'Search Your Campaigns...' : 'Search Your Campaigns and Folders...'}
                    onChange={(event) => setSearchTerm(event.target.value.trim().toLowerCase())}
                />
                {isFilterOpen && <SuperTableCustomFilterCloseTarget onClick={onClick}/> }
                <div>
                    <OutlineBtn onClick={(e) => {
                        e.stopPropagation(); // * Keep to prevent event bubble
                        setIsFilterOpen(!isFilterOpen)
                        }}>
                        <FilterIcon />Filter
                    </OutlineBtn>
                    {
                        isFilterOpen &&
                        <AdvancedFilterV2
                            categories={categories}
                            selectedFilters={filters}
                            setSelectedFilters={setFilters}
                            colorScheme={colorScheme}
                        />
                    }
                </div>
            </StyledSearchContainer>
            <ActiveFilters setFilters={setFilters} filters={filters} isAd={isAd} />
            <StyleOptionsContainer>
                <SearchOptions
                        filteredOptions={filteredOptions}
                        selectedOptions={selectedOptions}
                        resolveSelect={resolveSelect}
                        resolveSelectAll={resolveSelectAll}
                        resolveDeselectAll={resolveDeselectAll}
                        searchTerm={searchTerm}
                        isAd={isAd}
                        filters={filters}
                        colorScheme={colorScheme}
                        tabsToDisplay={presentOptionTypes}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                <SelectedOptions
                    resolveDeselect={resolveDeselect}
                    selectedOptions={selectedOptions}
                    isAd={isAd}
                    colorScheme={colorScheme}
                    tabsToDisplay={presentOptionTypes}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
            </StyleOptionsContainer>
        </StyledStepWrapper>
    )
}


export default EntityResultsSelector;