import React, { useCallback, useEffect } from 'react';
import { RenderValueWrap, RenderValuesWrap } from './SuperTableGenericTextFilterStyle';
import { Body } from '../../../styles/typography';
import CheckBox from '../../checkbox/CheckBox';
import { type FilterComponentProps } from '../getSuperTableComponents';

const SuperTableGenericTextFilter = <T,>(props: FilterComponentProps<T>) => {
    const { setFilterFunction, items, filterVal } = props;

    const [selectedValues, setSelectedValues] = React.useState<(string | number)[]>([]);

    const filterFunction = useCallback(
        (item: T) => {
            if (!filterVal) return false;
            if (selectedValues.length === 0) return true;

            const val = typeof filterVal === "function" ? filterVal(item) : item[filterVal];

            if (typeof val !== 'string' && typeof val !== 'number') {
                return true;
            }

            return selectedValues.includes(val);
        },
        [selectedValues, filterVal]
    );

    useEffect(() => {
        setFilterFunction(filterFunction);
    }, [filterFunction, setFilterFunction]);

    const renderValues = React.useMemo<(string | number)[]>(() => {
        if (!filterVal) return [];

        const set = new Set<string | number>();

        for (const item of items) {
            const val = typeof filterVal === "function" ? filterVal(item) : item[filterVal];

            if (typeof val !== 'string' && typeof val !== 'number') {
                continue;
            }

            set.add(val);
        }

        return Array.from(set).sort();
    }, [items, filterVal]);

    if (!filterVal) {
        console.error(
            `filterKey was null. this is weird, because if this happens it means we passed a function to column.filter but it didn't type as such. So basically, this should never happen`
        );
        return null;
    }

    return (
        <RenderValuesWrap>
            {renderValues.map((renderValue) => {
                return (
                    <RenderValueWrap
                        key={renderValue}
                        onClick={() => {
                            if (selectedValues.includes(renderValue)) {
                                setSelectedValues(
                                    selectedValues.filter(
                                        (selectedRenderValue) => selectedRenderValue !== renderValue
                                    )
                                );
                            } else {
                                setSelectedValues([...selectedValues, renderValue]);
                            }
                        }}
                    >
                        <CheckBox checked={selectedValues.includes(renderValue)} />
                        <Body>{renderValue}</Body>
                    </RenderValueWrap>
                );
            })}
        </RenderValuesWrap>
    );
};

export default SuperTableGenericTextFilter;