import React from 'react'
import fetchingAnimation from '../../assets/images/rocket_animation_light.gif';
import { StyledFetchingData } from './styles';

const FetchingData = ({ text }) => {
    return (
        <StyledFetchingData>
            <h2>{text}</h2>
            <img src={fetchingAnimation} alt='Fetching data' />
        </StyledFetchingData>
    )
}

export default FetchingData
