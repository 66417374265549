import { LOAD_USER, CLEAR_USER, ACKNOWLEDGE_TOS, TOGGLE_EXPERIMENT, LOAD_NETWORK_LOGIN_CONNECTIONS, SET_NETWORK_LOGIN_LOAD_STATUS, UPDATE_USER_FIELD } from '../actions/types';
import { USER_ACCESS, getUserAccessLevel } from '../helpers/user.util';
import { LOAD_STATUS } from '../types/primitives';

// ! -- IMPORTANT: -- !
// ! Any franchise values returned in the api/user/get call SHOULD ONLY be used if the loggedInUser is a member of the franchise
// ! If the loggedInUser is a manager or an admin, use the field returned from api/franchise/getFranchise call instead. (Ex. franchiseApprovals)
const initialState = {
    disabledClientPermissions: [],
    preferences: {},
    experiments: [],
    managedFranchises: [],
    isAuthenticated: false,
    accessLevel: USER_ACCESS.NO_ACCESS,
    paidFeatures: [],
    networkLogins: null,
    networkLoginLoadStatus: LOAD_STATUS.UNLOADED,
    emailOptOuts: null
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_USER: {
            const { adminPermissions, managedFranchises } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                accessLevel: getUserAccessLevel({ adminPermissions, managedFranchises }),
                ...action.payload
            }
        }
        case LOAD_NETWORK_LOGIN_CONNECTIONS: {
            return {
                ...state,
                networkLogins: action.payload
            }
        }
        case SET_NETWORK_LOGIN_LOAD_STATUS: {
            return {
                ...state,
                networkLoginLoadStatus: action.payload
            }
        }
        case UPDATE_USER_FIELD: {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
        case ACKNOWLEDGE_TOS: {
            return { ...state, tosAccepted: action.payload }
        }
        case CLEAR_USER:
            return initialState;
        case TOGGLE_EXPERIMENT: {
            const experiment = action.payload;
            const clone = [...state.experiments];

            const index = clone.indexOf(experiment)

            if(index === -1) {
                clone.push(experiment)
            } else {
                clone.splice(index, 1)
            }

            return {
                ...state,
                experiments: clone
            }
        }
        default: return state;
    }
}

export default userReducer;