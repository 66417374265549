import React from 'react';
import useAdPreviewSlides from '../../hooks/useAdPreviewSlides';

import {
    LeftSlideButton,
    RightSlideButton,
    PreviewSlideContainer,
    PreviewContainer,
    PreviewSlideWrapper,
} from "./styles";
import { Body } from '../../styles/typography';

const CreativePreviewSlides = ({ creative, networks, location, previewText, hasInstagramCollaborators }) => {
    const { previewData, slideNumber, goLeft, goRight } = useAdPreviewSlides(creative, networks, location);

    if (!previewText) return <Body>Loading...</Body>

    return (
        <PreviewSlideWrapper>
            {previewData.length >= 2 ? <LeftSlideButton onClick={goLeft} /> : null}

            <PreviewSlideContainer>
                {previewData.map(current => {
                    return (
                        <PreviewContainer x={slideNumber} key={current.type}>
                            <current.Component
                                creative={creative}
                                location={location}
                                previewText={previewText}
                                hasInstagramCollaborators={hasInstagramCollaborators}
                            />
                        </PreviewContainer>
                    );
                })}
            </PreviewSlideContainer>

            {previewData.length >= 2 ? <RightSlideButton onClick={goRight} /> : null}
        </PreviewSlideWrapper>
    )
}

export default CreativePreviewSlides
