import styled from "styled-components";


export const StyleCircularProgressBar = styled.div`
    #svg circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.2s linear;
        stroke: #EEEDEB;
        stroke-width: 24px;
    }
`