import { CreativeWithMetadata } from "../../../../../features/creatives/creative.types"

export const sortByBusinessName = (a: CreativeWithMetadata, b: CreativeWithMetadata) => {
    const aName = a.metadata.location?.name || ""
    const bName = b.metadata.location?.name || ""

    return aName.localeCompare(bName)
}

export const sortByCampaignName = (a: CreativeWithMetadata, b: CreativeWithMetadata) => {
    const aName = a.metadata.location?.nickname || ""
    const bName = b.metadata.location?.nickname || ""

    return aName.localeCompare(bName)
}