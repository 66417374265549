import { useSelector } from 'react-redux'
import { hasActiveSubscription } from '../helpers'
import { useAccess } from './useAccess'
import { EULERITY } from '../helpers/constants'
import { CALL_FORWARDING_LEASE_STATUS_TYPE } from '../pages/locations/components/CallForwardingSetup'
import { ENVIRONMENTS, getEnvironmentType } from '../features/environment/util'
import useExperiment from './useExperiment'
import { EXPERIMENTS } from '../helpers/experimentHelpers'

/**
 * This hook returns specific field depending
 * on the user's access level - either we use the field on the USER or FRANCHISE objects
 * *
 * Name the returned properties the same as the backend fields
 * For users we typically need to use the fields returned from their user object
 * For system managers we may need to look at the franchise object instead because
 * managers could manage multiple franchises
 * whereas a user can only be a member of one
 */
const useDynamicSetting = () => {
    const locations = useSelector(s => s.franchise.locations)
    const user = useSelector(s => s.user)
    const {isAdmin, canManageFranchise} = useAccess()
    const managerOrMember = (f, u) =>  canManageFranchise() ? f : u

    const franchiseSubscriptionState = useSelector(s => s.franchise.subscription?.state)

    const f_sendMonthlyReports = useSelector(state => state.franchise.sendMonthlyReports)
    const u_sendMonthlyReports = useSelector(state => state.user.sendMonthlyReports)

    const f_allowInactivePosting = useSelector(s => s.franchise.allowInactivePosting)
    const u_allowInactivePosting = useSelector(s => s.user.allowInactivePosting)

    const f_supportLink = useSelector(s => s.franchise.supportLink)
    const u_supportLink = useSelector(s => s.user.supportLink)

    const f_showTimelineView = useSelector(s => s.franchise.showTimelineView)
    const u_showTimelineView = useSelector(s => s.user.showTimelineView)

    const f_tosLink = useSelector(s => s.franchise.tosLink)
    const u_tosLink = useSelector(s => s.user.tosLink)
    const u_ofLocation_tosLink = locations?.find(l => l.user.raw.name === user.id)?.tosLink

    const f_enableAnalyticsGoalsViewBySource = useSelector(s => s.franchise.enableAnalyticsGoalsViewBySource)
    const u_enableAnalyticsGoalsViewBySource = useSelector(s => s.user.enableAnalyticsGoalsViewBySource)

    const f_enableAnalyticsSessionsViewBySource = useSelector(s => s.franchise.enableAnalyticsSessionsViewBySource)
    const u_enableAnalyticsSessionsViewBySource = useSelector(s => s.user.enableAnalyticsSessionsViewBySource)

    const f_emailFeatureEnabled = useSelector(s => s.franchise.featureEmailCreativeEnabled)
    const f_smsFeatureEnabled = useSelector(s => s.franchise.featureSmsCreativeEnabled)
    const f_socialPostingFeatureEnabled = useSelector(s => s.franchise.featureSocialPostingEnabled)
    const f_featureChatGptEnabled = useSelector(s => s.franchise.featureChatGptEnabled)
    const f_reputationManagementEnabled = useSelector(s => s.franchise.featureReputationManagementEnabled)

    const u_paidFeatures = useSelector(s => s.user.paidFeatures)

    const f_brandingBlob = useSelector(s => s.franchise.brandingBlob)
    const u_brandingBlob = useSelector(s => s.user.brandingBlob)

    const f_variables = useSelector(s => s.franchise.variables)
    const u_variables = useSelector(s => s.user.variables)
    const variables = managerOrMember(f_variables, u_variables) || []

    const f_enableCallForwarding = useSelector(s => s.franchise.enableCallForwarding)
    const u_enableCallForwarding = useSelector(s => s.user.enableCallForwarding)

    const f_callForwardingOptIn = useSelector(s => s.franchise.callForwardingOptIn)
    const u_callForwardingOptIn = useSelector(s => s.user.callForwardingOptIn)

    const f_phoneFormatsByCountryCode = useSelector(s => s.franchise.phoneFormatsByCountryCode)
    const u_phoneFormatsByCountryCode = useSelector(s => s.user.phoneFormatsByCountryCode)

    const f_shouldShowSearchVolumeKeywordReport = useSelector(s => s.franchise.shouldShowSearchVolumeKeywordReport)
    const u_shouldShowSearchVolumeKeywordReport = useSelector(s => s.user.shouldShowSearchVolumeKeywordReport)

    const f_hideBrandAwarenessDataFromLocations = useSelector(s => s.franchise.hideBrandAwarenessDataFromLocations)
    const u_hideBrandAwarenessDataFromLocations = useSelector(s => s.user.hideBrandAwarenessDataFromLocations)

    const f_hideGA4DataFromLocations = useSelector(s => s.franchise.hideGA4DataFromLocations)
    const u_hideGA4DataFromLocations = useSelector(s => s.user.hideGA4DataFromLocations)

    const f_enableGA4Reporting = useSelector(s => s.franchise.enableGA4Reporting)
    const u_enableGA4Reporting = useSelector(s => s.user.enableGA4Reporting)

    const f_enableSearchTermReport = useSelector(s => s.franchise.searchTermReportEnabled)

    const u_zeeInsightReportBuilderAccess = useSelector(s => s.user.zeeInsightReportBuilderAccess)

    const f_locationFieldAccessLevels = useSelector(s => s.franchise.locationFieldAccessLevels)
    const u_locationFieldAccessLevels = useSelector(s => s.user.locationFieldAccessLevels)

    // Flags to enable enforcing relevant connections before a user can subscribe
    const f_preventLocSubIfFbNotConnected = useSelector(s => s.franchise.preventLocSubIfFbNotConnected)
    const u_preventLocSubIfFbNotConnected = useSelector(s => s.user.preventLocSubIfFbNotConnected)

    const f_preventLocSubIfGANotConnected = useSelector(s => s.franchise.preventLocSubIfGANotConnected)
    const u_preventLocSubIfGANotConnected = useSelector(s => s.user.preventLocSubIfGANotConnected)

    //* these dates are used for the min start date in datepicker
    const f_createdDateStr = useSelector(s => s.franchise.defaultLocation?.created)
    const u_createdDateStr = useSelector(s => s.user.created)

    const f_allowLocCreationWithoutBlueprint = useSelector(s => s.franchise.allowLocCreationWithoutBlueprint)
    const u_allowLocCreationWithoutBlueprint = useSelector(s => s.user.allowLocCreationWithoutBlueprint)

    //* these are general product features that we want quick access to know if they are enabled
    const reputationManagementFeatureEnabled = managerOrMember(f_reputationManagementEnabled, u_paidFeatures?.includes("reputationManagement"))
    const advertisingEnabled = franchiseSubscriptionState === 'Active' || locations.some(location => location.subscriptions?.find(sub => sub.status === "ACTIVE"))

    /*
      This function returns the premium feature that a user has access to on a specific location.
    */
    const getPaidFeatures = (location = null) => {
      const locHasActiveSubscription = location ? hasActiveSubscription(location) : true // if no location is specified, make this variable transparent
      let paidFeatures = location && locHasActiveSubscription ? ['advertising'] : []

      if (location.phoneForwardingLeaseStatus === CALL_FORWARDING_LEASE_STATUS_TYPE.SUCCESS) {
        paidFeatures.push('call-forwarding')
      }

      if (reputationManagementFeatureEnabled) {
        paidFeatures.push('reputation-management')
      }

      if (canManageFranchise()) {
        if (f_emailFeatureEnabled && locHasActiveSubscription) paidFeatures.push('email')
        if (f_socialPostingFeatureEnabled && (locHasActiveSubscription || f_allowInactivePosting)) paidFeatures.push('social-posting')
        if (f_smsFeatureEnabled && locHasActiveSubscription) paidFeatures.push('sms')
      } else {
        if (u_paidFeatures.includes('emailCreative') && locHasActiveSubscription) paidFeatures.push('email')
        if (u_paidFeatures.includes('socialPosting') && (locHasActiveSubscription || u_allowInactivePosting)) paidFeatures.push('social-posting')
        if (u_paidFeatures.includes('smsCreative') && locHasActiveSubscription ) paidFeatures.push('sms')
      }
      return paidFeatures
    }


    const franchiseSettingOrLocationOverride = (locationSetting, userOrFranchiseSetting) => {
      return locationSetting === true || (locationSetting !== false && userOrFranchiseSetting)
    }

  return {
    allowInactivePosting : managerOrMember(f_allowInactivePosting , u_allowInactivePosting) ,
    showTimelineView: managerOrMember(f_showTimelineView , u_showTimelineView) ,
    supportLink: managerOrMember(f_supportLink, u_supportLink),
    tosLink: managerOrMember(f_tosLink, u_tosLink || u_ofLocation_tosLink) || EULERITY.TOS ,
    brandingBlob: managerOrMember(f_brandingBlob,u_brandingBlob),
    enableAnalyticsGoalsViewBySource:    isAdmin() || managerOrMember(f_enableAnalyticsGoalsViewBySource, u_enableAnalyticsGoalsViewBySource),
    enableAnalyticsSessionsViewBySource: isAdmin() || managerOrMember(f_enableAnalyticsSessionsViewBySource, u_enableAnalyticsSessionsViewBySource),
    socialPostingFeatureEnabled: managerOrMember(f_socialPostingFeatureEnabled, u_paidFeatures?.includes("socialPosting")),
    chatGPTFeatureEnabled: managerOrMember(f_featureChatGptEnabled, u_paidFeatures?.includes("chatGpt")),
    enableCallForwarding: managerOrMember(f_enableCallForwarding, u_enableCallForwarding),
    enableSearchTermReport: managerOrMember(f_enableSearchTermReport, false),
    callForwardingOptIn: managerOrMember(f_callForwardingOptIn, u_callForwardingOptIn),
    phoneFormatsByCountryCode: managerOrMember(f_phoneFormatsByCountryCode, u_phoneFormatsByCountryCode),
    shouldShowSearchVolumeKeywordReport: getEnvironmentType() === ENVIRONMENTS.STAGING || managerOrMember(f_shouldShowSearchVolumeKeywordReport, u_shouldShowSearchVolumeKeywordReport),
    zeeInsightReportBuilderAccess: u_zeeInsightReportBuilderAccess,
    hideBrandAwarenessDataFromLocations: managerOrMember(f_hideBrandAwarenessDataFromLocations, u_hideBrandAwarenessDataFromLocations),
    hideGA4DataFromLocations: managerOrMember(f_hideGA4DataFromLocations, u_hideGA4DataFromLocations),
    defaultMinStartDateStr: managerOrMember(f_createdDateStr, u_createdDateStr),
    enableGA4Reporting: managerOrMember(f_enableGA4Reporting, u_enableGA4Reporting),
    getPaidFeatures,
    franchiseSettingOrLocationOverride,
    variables,
    hasUnarchivedVariables: variables?.filter(v => !v.archived)?.length > 0,
    reputationManagementFeatureEnabled,
    advertisingEnabled,
    brandAwarenessLearnMore: managerOrMember("https://eulerity.atlassian.net/wiki/spaces/ZW/pages/883195905/Brand+Awareness+Reports", "https://eulerity.atlassian.net/wiki/spaces/ZWP/pages/882868227/Brand+Awareness+Reports"),
    preventLocSubIfFbNotConnected: managerOrMember(f_preventLocSubIfFbNotConnected, u_preventLocSubIfFbNotConnected),
    preventLocSubIfGANotConnected: managerOrMember(f_preventLocSubIfGANotConnected, u_preventLocSubIfGANotConnected),
    allowLocCreationWithoutBlueprint: managerOrMember(f_allowLocCreationWithoutBlueprint, u_allowLocCreationWithoutBlueprint),
    locationFieldAccessLevels: managerOrMember(f_locationFieldAccessLevels, u_locationFieldAccessLevels) || {},
    sendMonthlyReports: managerOrMember(f_sendMonthlyReports, u_sendMonthlyReports),
  }
}

export default useDynamicSetting