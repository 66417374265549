import styled from 'styled-components';

export const CampaignOrBlueprintOptionWrap = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;

    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const CampaignOrBlueprintLocationStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-weight: ${props => props.isActive ? '700' : '400'};
`