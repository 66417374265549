export const VARIABLE_TYPE = {
    TEXT: 'TEXT',
    LINK: 'LINK',
};

export const DEFAULT_VARIABLES = [
    {
        name: 'Business Name',
        shortcut: '%businessName%',
        description: 'The name of the business associated with a campaign',
        type: VARIABLE_TYPE.TEXT,
        isDefault: true,
    },
    {
        name: 'Business Phone',
        shortcut: '%phone%',
        description: 'The business phone number associated with a campaign',
        type: VARIABLE_TYPE.TEXT,
        isDefault: true,
    },
    {
        name: 'Campaign Landing Page',
        shortcut: '%landingPage%',
        description: 'The landing page associated with a campaign',
        type: VARIABLE_TYPE.TEXT,
        isDefault: true,
    },
];


// ^%v_ - starts with %v_
// [a-zA-Z0-9_]+ - contains at least one letter, number, or underscore
// %$ - ends with %
export const VARIABLE_SHORTCUT_REGEX_QUERY = '%(v_[a-zA-Z0-9_]+)%';

export const VARIABLE_OPTIONS = Object.keys(VARIABLE_TYPE).reduce((arr, key) => {
    arr.push({
        value: VARIABLE_TYPE[key],
        label: key.substring(0, 1) + key.substring(1).toLowerCase(),
    });

    return arr;
}, []);

export const isValidShortcut = (shortcut) => {
    const regex = /^%v_.[a-zA-Z0-9_]*%$/
    return regex.test(shortcut);
}

export const containsValidShortcut = (str) => {
    const defaultVariableShortcuts = DEFAULT_VARIABLES.map(v => v.shortcut)
    const hasCustomVariable = new RegExp(VARIABLE_SHORTCUT_REGEX_QUERY).test(str);
    const hasDefaultVariable = defaultVariableShortcuts.some(shortcut => str.includes(shortcut))
    return hasCustomVariable || hasDefaultVariable
}

// sorts alphabetically and puts archived variables at the bottom
export const sortVariables = (varA, varB) => {
    return varA.archived - varB.archived || varA.name.toLowerCase().localeCompare(varB.name.toLowerCase());
}