import React, { useMemo } from "react"
import { StyleJob } from "../styles"
import { FiAlertTriangle } from "react-icons/fi";
import { Body, Small } from "../../../styles/typography";
import { Job } from "../types";
import { FiExternalLink } from "react-icons/fi";
import CircularProgressBar from "./CircularProgress/CircularProgressBar";
import { useTheme } from "styled-components";


const CurrentJob = ({ job, getSelectedJob }: { job: Job, getSelectedJob: (job: Job) => void }) => {
    const theme = useTheme();
    const progress = Math.round((job.numberOfSuccess + job.numberOfFailure) / job.numberOfTasks * 100)
    const jobCompletedState = useMemo(() => {
        if(progress !== 100) return
        if (job.numberOfFailure > 0) {
            return job.numberOfFailure === job.numberOfTasks
                ? { status: "task failed", Icon: <FiAlertTriangle color={theme.color.errorNegativeButtonColor} /> }
                : { status: "task partially completed", Icon: <FiAlertTriangle color={theme.color.warningButtonColor} /> };
        }
        return { status: "task completed", Icon: null };
    }, [job, progress, theme.color.errorNegativeButtonColor, theme.color.warningButtonColor])


    return (
        <StyleJob>
            <div className="job-status">
                <Body>{job.description}</Body>
                    {!!jobCompletedState &&
                        <div className="job-status_group">{jobCompletedState.Icon}
                            <Small>{jobCompletedState.status}</Small>
                        </div>
                    }
            </div>
            <div style={{display: 'grid', placeContent: 'center'}}>{progress !== 100 ?
                <CircularProgressBar progress={progress} />
                : job.numberOfFailure > 0 ? <div onClick={()=>{getSelectedJob(job)}} ><FiExternalLink color={theme.color.textColor1}/></div> : <></>
                }
            </div>
        </StyleJob>
    )
}
export default CurrentJob