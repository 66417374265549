import firebase from "firebase/compat/app";
import 'firebase/compat/analytics';
import "firebase/compat/auth";

let firebaseAnalytics = null;
const addCallback = function(callback) {
  if (callback)
		firebase._callbacks.push(callback);
	if (!window.appConfig || !window.eulerity) {
		setTimeout(addCallback, 100);
		return;
  	}

	if (firebase._isInitialized) {
		runCallbacks();
		return;
	}
	try {
		console.log('Initializing Firebase');
  		firebase.initializeApp(window.appConfig.firebase_JS);
		firebase._isInitialized = true;
		firebaseAnalytics = firebase.analytics();
		runCallbacks();
	} catch (err) {
		if (!/already exists/.test(err.message)) {
			console.error('Firebase initialization error', err.stack);
		}
	}
}

const runCallbacks = function() {
  while (true) {
		let cb = firebase._callbacks.shift();
		if (cb)
			cb();
		else
			break;
	}
}

firebase.addCallback = addCallback;
firebase._isInitialized = false;
firebase._callbacks = [];
addCallback();
window.firebase = firebase;
export { firebase, firebaseAnalytics };
