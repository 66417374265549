import React, { createContext } from 'react';
import { ModalContextType, ModalContextProviderProps } from '../types';

//* This context will provide the values of onClose, onBack, and size to the child
//* components of ModalTitle and ModalNav to dictate the sizing and spacing between components

export const ModalContext = createContext<ModalContextType>({
  modalNavRef: null,
  onClose: () => {},
  onBack: () => {},
  size: "md",
  setHasNav: () => {}
});

export const ModalContextProvider = ({
  size,
  onClose: handleClose,
  onBack: handleBack,
  modalNavRef,
  setHasNav,
  children
}: ModalContextProviderProps) => {
  const value = {
    modalNavRef,
    size,
    onClose: handleClose,
    onBack: handleBack,
    setHasNav
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};
