import { useSelector } from "react-redux"
import { Loadable } from "../../../hooks/useRequest"
import { Email } from "../../../types/primitives"

export const useFranchiseCsm = (): Loadable<Email> => {

	const franchise = useSelector(s => s.franchise)

	if (franchise.loading) {
		return {
			state: 'loading',
			loading: true,
			data: undefined,
			error: undefined,
		}
	}

	if (!franchise.csm) {
		return {
			state: 'error',
			loading: false,
			data: undefined,
			error: new Error('No CSM found'),
		}
	}

	return {
		state: 'success',
		loading: false,
		data: franchise.csm,
		error: undefined,
	}
}
