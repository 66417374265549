import React from 'react'
import { H1 } from '../../styles/typography';

const ModalHeader = ({title}) => {
    return (
        <div>
            <H1 center>{title}</H1>
        </div>
    )
}

export default ModalHeader
