import React from 'react'
import Modal from '../../../components/modal/Modal'
import { Body, H2 } from '../../../styles/typography'
import CloneTable from './CloneTable'
import { ButtonContainer, OutlineBtn } from '../../../styles/buttons'
import { getCreativeKindLabel } from '../../../helpers/creative.util'

const CloneStatusModal = ({ onClose, successfulClones, failedClones, creative }) => {
    const creativeKind = creative ? getCreativeKindLabel(creative) : 'Creative(s)'

    const decideAlertText = () => {
        const lowerCasedKind = `${creativeKind?.toLocaleLowerCase()}`

        if (successfulClones?.length && failedClones?.length) return `Some of your ${lowerCasedKind} were successfully cloned but we had issues cloning some of your ${lowerCasedKind}.`
        if (successfulClones?.length && !failedClones?.length) return `Your ${lowerCasedKind} was successfully cloned to all campaigns/creative folders.`
        else return `Your ${lowerCasedKind} failed to clone to all campaigns/creative folders.`
    }

    return (
        <Modal
            onClose={onClose}
            width='1260px'
        >
            <div>
                <div className='header' style={{padding: '0 30px'}}>
                    <H2>{creativeKind} Cloned</H2>
                    <Body>{decideAlertText()}</Body>
                </div>
                <div>
                    {
                        successfulClones?.length > 0 && <CloneTable targets={successfulClones} statusType='Successfully Cloned' creative={creative}/>
                    }
                    {
                        failedClones?.length > 0 && <CloneTable targets={failedClones} statusType='Failed To Clone' creative={creative}/>
                    }
                </div>
            </div>
            <ButtonContainer>
                <OutlineBtn onClick={onClose}>Okay</OutlineBtn>
            </ButtonContainer>
        </Modal>
    )
}

export default CloneStatusModal