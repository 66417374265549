import styled, {css} from "styled-components";
import { decideTextColor } from "../../helpers/colors.util";
import { OutlineBtn } from "../../styles/buttons";
import { Card, createBoxShadow } from "../../styles/card"
import { TileIconContainer } from "../../components/tile/styles";

// CONTAINER
export const StyledRepo = styled(Card)`
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem;
    border-radius: 9px;
`

// Repos
export const ReposContainer = styled.div`
    padding: 24px 48px 40px 48px;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 200px);
    grid-gap: 0.5rem;
    margin-top: 24px;
`;

export const AssociatedBlueprintContainer = styled.div`
    li {
         margin-left: 10px;
         font-size: 12px;
         font-weight: 400;
         list-style-type: none;
    }
`;

export const AttachRepoContainer = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
`;


export const RepoItem = styled.div<{isSelected?: boolean, current?: boolean}>`
    background: ${props =>  props.theme.color.backgroundColor2};
    color: ${props => decideTextColor(props.theme.color.backgroundColor2)};
    border: 1px solid ${props => props.current ?  props.theme.color.greyAccent1 : props.theme.color.accentColor3};
    border-radius: 6px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 4px;
    padding-right: 24px;

    ${({ isSelected }) => isSelected && css`
        background: ${props => props.theme.color.accentColor4};
        color: ${props => decideTextColor(props.theme.color.accentColor4)};

        button {
            border: 1px solid ${props => decideTextColor(props.theme.color.accentColor4)};
            background: ${props => props.theme.color.backgroundColor2};
            color: ${props => decideTextColor(props.theme.color.accentColor4)};
        }
    `}

    p {
        flex: 1;
        margin: 10px;
    }

    svg {
        width: 40px;
        height: 40px;
    }


`;

export const SelectedHeader = styled.div`
    display: flex;
    align-items: center;

    h1 {
        max-width: 486px;

        &:hover {
            cursor: default
        }
    }

    div {
        display: flex;
        align-items: center;
        padding: 12px;
    }

    div:hover {
        cursor: pointer;
    }
`


// Buttons
export const RepoButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
        width: 180px;
    }
`;

// Styled Button
export const BuildButton = styled(OutlineBtn)`
    svg {
        color: ${props => props.theme.color.textColor1} !important;
    }
`

// Modal

export const RepoCreativesViewWrap = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

export const StyledDisplay = styled.div`
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
`

export const StyledGridContainer = styled.div`
    padding: 0px 1rem;
    flex-grow: 1;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(176px,0fr));
    grid-gap: 30px;
    row-gap: 30px;
    overflow-y: auto;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-content: flex-start;

    .creativeThumbnail {
        height: 131.34px;
    }
`

export const StyledCreateFolderModal = styled.div`
    padding: 20px 40px;
    .inputWrapper {
        margin: 20px 0px;
        p {
            margin-bottom: 10px;
        }
    }
`

export const StyleBlueprintList = styled.div`
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid ${props => props.theme.color.greyAccent2};
    border-radius: 9px;
    padding: 10px;
    margin: 20px auto;
`

// Rename Modal
export const RenameHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const RenameContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    gap: 8px;
`
export const ButtonContainer = styled.div`
    display: flex;
    gap: 16px;
`

// Folder Action Dropdown
export const FolderActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 48px;
  right: 10px;
  background-color: ${props => props.theme.color.backgroundColor2};
  width: 180px;
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: ${createBoxShadow};
`
export const ActionRow = styled.div`
    padding: 8px 24px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
        height: 16px;
        width: 16px;
        fill: none;
        /* override transition */
        transition: none;
    }

    .cloneIcon {
        fill: black;
    }

    &:hover .cloneIcon {
        fill: ${props => props.theme.color.accentColor2};
    }

    &:hover, &:hover svg, &:hover p {
        background-color: ${props => props.theme.color.accentColor4};
        color: ${props => props.theme.color.accentColor2};
    }
`

export const ActionIconContainer = styled(TileIconContainer)`
    svg {
        color: ${props => props.theme.color.textColor1};
    }
`