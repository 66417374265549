// * This hook is to initilize the options list for the advanced filter

import { useSelector } from "react-redux"
import { ADVANCED_FILTER_ITEM, CATEGORY_OPTIONS } from "../constants"
import { OBJECTIVE } from "../../../helpers/objectives"

const useFilterOptions = () => {
    const labels = useSelector(s => s.franchise.labels)

    const getLabelOptions = () => {
        return ["(Unlabeled)", ...labels]
    }

    const getStatusOptions = () => {
        return CATEGORY_OPTIONS.STATUS_LIST
    }

    const getUserOptions = (entities) => {
        const userEmails = new Set();

        for(const entity of entities) {
            const { userEmail } = entity.attributes;

            if (userEmail) userEmails.add(userEmail);
        }

        return Array.from(userEmails);
    }

    const getTypeOptions = (itemsToDisplay) => {
        const typeList = []

        if (itemsToDisplay.includes(ADVANCED_FILTER_ITEM.LOCATIONS)) {
            typeList.push("Primary Campaigns");
            typeList.push("Secondary Campaigns");
        }

        if (itemsToDisplay.includes(ADVANCED_FILTER_ITEM.REPOS)) {
            typeList.push("Creative Folders");
        }

        if (itemsToDisplay.includes(ADVANCED_FILTER_ITEM.BLUEPRINTS)) {
            typeList.push("Blueprints");
        }

        return typeList.sort()
    }

    const getObjectiveOptions = () => {
        return Object.values(OBJECTIVE).map(val => val.LABEL).sort()
    }

    return {
        getLabelOptions,
        getStatusOptions,
        getUserOptions,
        getTypeOptions,
        getObjectiveOptions,
    }
}

export default useFilterOptions