import { LOAD_FRANCHISE_LIST, CLEAR_FRANCHISE_LIST, SELECT_FRANCHISE } from './types';
import { API } from '../constants/api';
import { makeRequest } from '../helpers/make-request';

const fetchManagedFranchises = async () => {
    return makeRequest()
    .url(API.USER.GET_FRANCHISE_LIST)
    .get()
}

export const loadAndSelectManagedFranchises = () => async dispatch => {
    try {
        const franchiseList = await fetchManagedFranchises();
        dispatch({type: LOAD_FRANCHISE_LIST, payload: franchiseList});
        dispatch({type: SELECT_FRANCHISE, payload: franchiseList.length ? franchiseList[0] : null})
        return Promise.resolve()
    } catch (error) {
        return Promise.reject()
    }
}

export const loadManageFranchises = () => async dispatch => {
    try {
        const franchiseList = await fetchManagedFranchises();
        dispatch({ type: LOAD_FRANCHISE_LIST, payload: franchiseList});
        return Promise.resolve()
    } catch (error) {
        return Promise.reject()
    }
}

export const clearFranchiseList = () => dispatch => {
    dispatch({
        type: CLEAR_FRANCHISE_LIST
    })
}

export const selectFranchise = (franchiseName) => dispatch => {
    dispatch({
        type: SELECT_FRANCHISE,
        payload: franchiseName
    })
}