import styled from "styled-components";
import { fontWeightBold, fontWeightRegular } from "../../../styles/typography";

const isNavItemSelected = (props: {
    type: string,
    selectedField: string,
}, matchType: string) => {
    const { selectedField, type } = props
    if ( selectedField === null && type === null ) return true //Allow null case (Documents)
    if (matchType === 'exact') {
        return type === selectedField
    } else {
        return selectedField?.includes(type)
    }
}

export const NavItem = styled.div<{
    type: string,
    selectedField: string,
    exact?: boolean,
    accentColor?: string,
    noUpperCase?: boolean
}>`
    position: relative;
    border-bottom: ${props => isNavItemSelected(props, props.exact ? 'exact' : 'includes') ? `2px solid ${props.accentColor ? props.accentColor : props.theme.color.accentColor2}` : `2px solid transparent`};
    color: ${props => isNavItemSelected(props, props.exact ? 'exact' : 'includes') ? props.theme.color.textColor1 : props.theme.color.textColor2};
    ${props =>isNavItemSelected(props, props.exact ? 'exact' : 'includes') ? fontWeightBold : fontWeightRegular};
    text-transform: ${props => props.noUpperCase ? null : 'uppercase'};
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    margin: 0px 20px;
`;