import React from 'react'
import CreativePreviewSlides from '../../../../components/previews/CreativePreviewSlides';
import { Body, H2 } from '../../../../styles/typography';
import { StyledStepReview } from '../styles';

const CreativeStepPreview = ({ sampleCreative, networks, location, previewText, formData }) => {

    if (!sampleCreative || !previewText) return (
        <>
            <Body center>Loading...</Body>
        </>
    )

    return (
        <StyledStepReview>
            <H2 regular>Quick Preview</H2>
            <CreativePreviewSlides
                creative={sampleCreative}
                previewText={previewText}
                networks={networks}
                location={location}
                hasInstagramCollaborators={formData?.instagramCollaborators?.length > 0}
            />
        </StyledStepReview>
    )
}

export default CreativeStepPreview;
