import { useSelector } from "react-redux";

export const useFilterOptionEntity = () => {
    const memberList = useSelector(s => s.franchise.memberList);

    const createFromLocation = (location) => {
        return {
            attributes: {
                userEmail: memberList.find(member => member.id === location.user.raw.name)?.email,
            }
        }
    }

    const createFromBlueprint = (blueprint) => {
        return {
            attributes: {
                userEmail: null
            }
        }
    }

    return {
        createFromLocation,
        createFromBlueprint,
    }
}