import { getDayReport, numberAggregator, reduceNumberArray } from "../shared"
import columnBuilder from '../../../columnBuilder'
import { reportLoader } from '../../../loaders'

export const clicksReportCategory = columnBuilder()
	.category('performance')
	.subCategory('Clicks')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.subCategory}: ${meta.name}`
	})

const clicksFormatter = (value: number | undefined) => {
	if (value === undefined) return "N/A"
	return value.toFixed(0)
}

const clicksColumns = [
	clicksReportCategory
		.id('clicks-total')
		.name('Total')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			if (!targetDate) return
			const total = reduceNumberArray(Object.values(targetDate.channelMetrics || {}).map(channel => channel.clicks))
			return total
		})
		.aggregateRow(numberAggregator)
		.toString(clicksFormatter),
	clicksReportCategory
		.id('clicks-by-display')
		.name('Display')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.display?.clicks
		})
		.aggregateRow(numberAggregator)
		.toString(clicksFormatter),
	clicksReportCategory
		.id('clicks-by-search')
		.name('Search')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.search?.clicks
		})
		.aggregateRow(numberAggregator)
		.toString(clicksFormatter),
	clicksReportCategory
		.id('clicks-by-social')
		.name('Social')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.social?.clicks
		})
		.aggregateRow(numberAggregator)
		.toString(clicksFormatter),
	clicksReportCategory
		.id('clicks-by-video')
		.name('Video')
		.makeRow((data) => {
			const { input, row: locationReport } = data
			const targetDate = getDayReport(locationReport, input.date)
			return targetDate?.channelMetrics?.video?.clicks
		})
		.aggregateRow(numberAggregator)
		.toString(clicksFormatter),
]

export default clicksColumns
