import { Dispatch, SetStateAction } from "react"
import { CreativeModalView } from "./CreativeModalV2/CreativeModalV2"
import { EulerityLocation } from "../../types/location"
import { PostFailureReason } from "../../constants/creative"
import { CreativeKind, POST_NETWORK, POST_NETWORK_NAME } from "../../helpers/creative.util"


export type VideoTopTexts = {
  youtubeDescriptions: string[]
  youtubeHeadlines: string[]
}

export type TopTexts = {
  headers: string[]
  descriptions: string[]
  extendedTexts: string[]
  landingPages: string[]
  searchHeadlines?: string[]
  searchDescriptions?: string[]
}

export type TextBlock = {
    text: string,
    type: string,
}

export type NetworkStatus = {
    postedDate: string,
    postStatus: PostStatus,
    networkdUploadError: string,
    networkdPostId: string,
    pageId: string,
    engagedUsers: number,
    impressions: number,
    impressionsUnique: number,
    videoViews: number,
    reason: PostFailureReason,
    postUrl: string,
}

type BoundingBox = any

export type Network = typeof POST_NETWORK[keyof typeof POST_NETWORK]
export type NetworkCopy = typeof POST_NETWORK_NAME[keyof typeof POST_NETWORK_NAME]

export type CreativeStatus = "UNREVIEWED" | "REJECTED" | "PAUSED" | "ACTIVE" | "PENDING" | "CANT_POST"
export type PostStatus = "POSTED" | "PENDING" | "REJECTED" | "CANT_POST" | "SCHEDULED"
export type PostSummaryStatus = PostStatus | "PARTIALLY_POSTED" | "FAILURE"

export const FOLDER_STATUS = {
    ACTIVE: "ACTIVE",
    EXPIRED: "EXPIRED",
    REJECTED: "REJECTED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
} as const

export type FolderStatus = typeof FOLDER_STATUS[keyof typeof FOLDER_STATUS]

export type AdWithMetadata = (Ad | VideoCreative) & {
    metadata: CreativeMetadataFull,
}
export type PostWithMetadata = Post & {
    metadata: CreativeMetadataFull
}
export type CreativeWithMetadata = Creative & {
	metadata: CreativeMetadataFull
}

export type EulerityLocationInfo = {
    websafe: string,
    raw: {
        id: number,
        kind: "Location",
        parentKey: {
            id: number,
            kind: "User",
            name: string,
        }
    }
}

export type Creative = {
    businessName: TextBlock,
    carouselImages : string[],
    clonedFrom?: string,
    cta: string,
    description?: TextBlock,
    displayUrl: string,
    end: string,
    extendedText: string,
    fStatus: CreativeStatus,
    header?: TextBlock,
    id: number,
    image: string,
    imageBox: BoundingBox,
    isMagic: boolean,
    keyParam: string,
    kind: CreativeKind,
    labels: string[],
    landingPage?: string,
    location: EulerityLocationInfo,
    logo: string,
    logoBox: BoundingBox,
    networkRejectionReasons: Set<string>,
    peakStartDate?: string,
    postedDate: string,
    premade: boolean,
    preview: string,
    previewSize: number,
    previewUrl: string,
    qsParam: string,
    rejectionDate: string,
    rejectionDescription: string,
    rejectionReason: string,
    repoName?: string,
    searchDescriptions: string[],
    searchHeadlines: string[],
    searchHeadlinePreview: string,
    searchDescriptionPreview: string,
    skipWebsiteValidation?: boolean,
    start: string,
    status: CreativeStatus,
    template: string,
    useLocationLogoOnClone: boolean,
    variableDependencies: string[],
    websafe: string,
    userLocations?: string[]
}

export type Ad = {
    '~unusedAdField': never,
} & Creative

export type Post = {
    postedNetworks: Record<Network, NetworkStatus | undefined> | undefined,
    targetNetworks: Network[],
    totalEngagedUsers: number,
    totalImpressions: number,
    totalImpressionsUnique: number,
    totalVideoViews: number,
    ignoreImage: boolean,
} & Creative

type VideoFields<T> = ({
	youTubeId: string,
} | {
	media: string,
}) & T


export type VideoPost = VideoFields<Post>
export type VideoCreative = VideoFields<Creative>

export type CreativeContext = {
    isCloneModalOpen: boolean,
    setIsCloneModalOpen: Dispatch<SetStateAction<boolean>>,
    metadata: CreativeMetadataFull,
    refreshCreatives: () => void,
    onClose: () => void,
    creative: Ad | Post | undefined,
    needsApproval: boolean,
    view: CreativeModalView,
    setView: Dispatch<SetStateAction<CreativeModalView>>,
}

// Creative metadata that is stateless
export type CreativeMetadata = {
    isMagicPost: boolean,
    isMagicEntity: boolean,
    isAdEntity: boolean,
    isPostEntity: boolean,
    isVideoPost: boolean,
    isDeployedAd: boolean,
    isDeployedPost: boolean,
    creativeDateText: {
        start?: string,
        end?: string,
    },
    creativeKindLabel?: string,
}

// Creative metadata with user, isFolder, and location injected
export type CreativeMetadataFull = {
    user: any | undefined,
	// this is true if the item is in the folder
    isFolder: boolean,
	// the location that the item is in
    location: EulerityLocation | undefined,
	// this is true if the item is in the default location
    isDefaultLocation: boolean,
} & CreativeMetadata


export type PreviewText = {
    facebookCta: string;
    facebookDescription: string;
    facebookDomain: string;
    facebookHeadline: string;
    facebookLinkDescription: string;
    facebookPageName: string;
    facebookPageSubtitle: string;
    gmbBusinessName: string;
    gmbDescription: string;
    instagramAccountName: string;
    instagramCta: string;
    instagramDescription: string;
    instagramUsername: string;
    linkedInDescription: string;
    locationName: string;
    locationPhone: string;
    responsiveSearchDescription: string;
    responsiveSearchHeadline: string;
    searchDescription: string;
    searchHeadline: string;
    searchHostname: string;
    twitterDescription: string;
    twitterHandle: string;
    twitterPageName: string;

  };



