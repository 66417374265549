import React from 'react';
import Alert from '../../../../components/alert/Alert';
import Collaborator from '../../components/Collaborator';
import NetworkIcon from '../../../../features/creatives/CreativeModalV2/NetworkIcon';
import { NETWORK_ICON_NETWORKS } from '../../../../features/creatives/CreativeModalV2/NetworkIcon';
import { ALERT } from '../../../../components/alert/util';
import { Body, H2, Link, Small } from '../../../../styles/typography';
import Button from '../../../../components/button/Button';
import useCollaboratorStep from '../../hooks/useCollaboratorStep';
import { uniqueId } from 'lodash';

export type CollaboratorType = {
    name: string,
    id: string,
}

type CreativeStepProps<T> = {
    context: React.Context<T>
}

export type FormDataType = {
    instagramCollaborators?: CollaboratorType[]
}

type FormDataContextType = {
    formData: FormDataType;
    updateFormData: (key: keyof FormDataType, value: CollaboratorType[]) => void;
};

const CreativeStepInstagramCollaborators = ({ context }: CreativeStepProps<FormDataContextType>) => {
    const { formData, updateFormData } = React.useContext(context);
    const { hasReachedCollaboratorLimit, hasDuplicates, hasEmptyCollaborators, hasInvalidHandle } = useCollaboratorStep(formData)

    return (
        <div style={{ overflowY: 'scroll', maxHeight: '443px' }}>
            <div style={{display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '0.5rem'}}>
                <NetworkIcon network={NETWORK_ICON_NETWORKS.INSTAGRAM}/>
                <H2 color='textColor1'> Add Instagram Collaborators </H2>
                <Body disabled>(Optional)</Body>
            </div>

            <Body disabled> Add public Instagram accounts as collaborators in your social posts, allowing you to reach their followers as well. </Body>

            <Alert type={ALERT.INFO.TYPE}
                message='All invitations must be accepted for accounts to be post collaborators. Before acceptance, post collaborators appear as tagged only.'
                style={{display: 'flex', alignItems: 'center', margin: '1rem 0 1.5rem 0'}}
                children={<Link xs regular style={{ marginLeft: 'auto'}} onClick={() => window.open('https://help.instagram.com/5861247717337470')}> Learn More </Link>}
            />

            {(formData?.instagramCollaborators ?? []).length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                {(formData?.instagramCollaborators ?? []).map((collaborator: CollaboratorType) =>
                    <Collaborator
                        collaborator={collaborator}
                        formData={formData}
                        updateFormData={updateFormData}
                        key={collaborator.id}
                    />)}
                </div>)
            }

            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', marginTop: '1rem' }}>
                <Button
                    variant='outline'
                    style={{ width: 'fit-content' }}
                    disabled={hasReachedCollaboratorLimit || hasEmptyCollaborators || hasInvalidHandle || hasDuplicates}
                    onClick={() => updateFormData('instagramCollaborators', [...(formData?.instagramCollaborators ?? []), {name: '', id: uniqueId() }])}
                >
                    Add Collaborator
                </Button>
                <Small disabled> Any private or misspelled accounts will not be invited. </Small>
            </div>
        </div>
    )
}

export default CreativeStepInstagramCollaborators;