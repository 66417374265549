import React from 'react'
import { MetricsLifeTime, NetworkStatusItemWrap } from '../styles'
import { HR, Small } from '../../../../styles/typography'
import InfoTooltip from '../../../tooltip/InfoTooltip'

const AdMetricsTable = ({ adMetricsData }) => {
    return (
        <MetricsLifeTime>
            <div className='ad-metric-header'>
                <div className='header' />
                <div className='header'>
                    <Small bold>7 Days</Small>
                </div>
                <div className='header'>
                    <Small bold>30 Days</Small>
                </div>
                <div className='header'>
                    <Small bold>90 Days</Small>
                </div>
            </div>
            <HR spacing={0} />
            {
                adMetricsData.map((item, idx) => {
                    return (
                        <>
                            <NetworkStatusItemWrap key={idx} style={{display: 'flex', alignItems: 'center'}}>
                                <div className='ad-metrics-wrap'>
                                    <Small bold>{item.name}</Small>
                                    <InfoTooltip tooltipContent={item.toolTip}/>
                                </div>
                                <div className='ad-metric-value'>
                                    <Small>{item.weekValue}{item?.isPercent && '%'}</Small>
                                </div>
                                <div className='ad-metric-value'>
                                    <Small>{item.monthValue}{item?.isPercent && '%'}</Small>
                                </div>
                                <div className='ad-metric-value'>
                                    <Small>{item.quarterValue}{item?.isPercent && '%'}</Small>
                                </div>
                            </NetworkStatusItemWrap>
                            <HR spacing={0} />
                        </>
                    )
                })
            }
        </MetricsLifeTime>
    )
}

export default AdMetricsTable