import React, { useContext, useEffect, useMemo, useState } from 'react';

import DeprecatedSuperTableColumnFilterDoNotUse from './components/SuperTableColumnFilter';
import DeprecatedSuperTableGenericTextFilterDoNotUse from './components/SuperTableGenericTextFilter';
import DeprecatedSuperTableContextDoNotUse, { SuperTableContextProvider } from './contexts/SuperTableContext';
import { Row, Wrap } from './style';
import { H4, Span } from '../../styles/typography';
import CheckBox from '../checkbox/CheckBox';
import { FiSliders } from 'react-icons/fi';

/*
    The SuperTable component takes the props:
    - items, the list of items that will be displayed in the supertable
    - getClassesForRow, a function that takes the current item and returns a list of classes that will be applied to the row
    - useAlternateStyles, a boolean that will remove the generic styling
    - onRowClicked, a function that is called when a row is clicked, with the arguments (item, itemIndex)
    - defaultSortColumn, the index of the column that should be sorted by default(this column should also obviously have a sort function)

    The Supertable should also have one or more child elements that are SuperTable.Column elements.

    The Column element takes the props:
    - title: the name of this column
    - size: by default this is 1 and every column will take up the same amount of space. you can pass any integer
      here so that it takes up a larger amount of space. you can also pass 0 so it will only take up the minimum
      width necessary to display the content inside
    - children: this should be a function that returns an element. this function is called for each item
      passed into the main SuperTable component, with it's one argument being the current item being rendered
    - extractFilterString: this should be a function that takes the current item and returns a string that will be used
        to filter the items. if this is not passed, the renderItem function will be used to generate the string
    - sort: this should be a function that takes two items and returns a number. this function will be used to sort the items
        in the table. if this is not passed, the items will not be sortable by this column

    simple example:
    <SuperTable items={[{ name: "ben", age: 20 }, { name: "mary", age: 28 }, { name: "bob", age: 45 }]}>
        <SuperTable.Column title="Name">
            {(person) => {
                return person.name;
            }}
        </SuperTable.Column>
        <SuperTable.Column title="Age">
            {(person) => {
                return person.age;
            }}
        </SuperTable.Column>
        <SuperTable.Column title="Over 21?">
            {(person) => {
                const isOver21 = person.age >= 21;

                return <span style={{color: isOver21 ? "green" : "red" }}>{isOver21 ? "YES" : "NO"}</span>;
            }}
        </SuperTable.Column>
    </SuperTable>
*/

export const DeprecatedSelectableSuperTableDoNotUse = ({ children, isItemSelected, onItemToggled, readOnly=false, ...props }) => {
    const [itemsToToggle, setItemsToToggle] = useState([]);

    useEffect(() => {
        if (itemsToToggle.length === 0) return;

        const firstItem = itemsToToggle[0];

        onItemToggled(firstItem);

        setItemsToToggle(itemsToToggle.slice(1));
    }, [itemsToToggle, onItemToggled]);

    const areAllSelected = useMemo(() => {
        return props.items.every(isItemSelected);
    }, [props.items, isItemSelected]);

    const handleMainCheckboxSelected = () => {
        if (areAllSelected) {
            setItemsToToggle(props.items);
        } else {
            setItemsToToggle(props.items.filter((item) => !isItemSelected(item)));
        }
    };

    return (
        <DeprecatedSuperTableDoNotUse
            {...props}
            getClassesForRow={(item) => {
                if (isItemSelected(item)) return ['selected'];
                else return [];
            }}
            _isSelectable
        >
            {
                !readOnly && (
                    <DeprecatedSuperTableDoNotUse.Column
                        size='0.25'
                        title={
                            <CheckBox
                            size='large'
                            checked={areAllSelected}
                            onClick={handleMainCheckboxSelected}
                            />
                        }
                        >
                        {(item) => {
                            return (
                                <CheckBox
                                    size="large"
                                    checked={isItemSelected(item)}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        onItemToggled(item);
                                    }}
                                />
                            );
                        }}
                    </DeprecatedSuperTableDoNotUse.Column>
                )
            }
            {children}
        </DeprecatedSuperTableDoNotUse>
    );
};

const SuperTableBody = ({
    items,
    children,
    getClassesForRow,
    useAlternateStyles,
    onRowClicked,
    defaultSortColumn,
    bodyStyle,
    _isSelectable
}) => {
    const { filterFunctions } = useContext(DeprecatedSuperTableContextDoNotUse);

    const [isUsingDefaultSort, setIsUsingDefaultSort] = useState(true);

    const [sortColumn, setSortColumn] = useState(null);
    const [sortIconDirection, setSortIconDirection] = useState(Array.from({ length: children.length }, (_) => false))
    const [shouldReverseSort, setShouldReverseSort] = useState(false);

    // we use a ref here because useState doesn't handle functions well
    // and we change sortColumn or shouldReverseShort to indicate the need for a re-render
    const sortFunction = React.useRef((() => {
        return 0
    }));

    const columns = useMemo(() => {
        return React.Children.toArray(children).filter(child => child.type === SuperTableColumn).map((child) => ({
            title: child.props.title || '',
            render: child.props.children || (() => <></>),
            size: child.props.size || 1,
            filter: child.props.filter,
            extractFilterString: child.props.extractFilterString,
            sort: child.props.sort,
            className: child.props.className
        }));
    }, [children]);

    const footers = useMemo(() => {
        return React.Children.toArray(children).filter(child => child.type === SuperTableFooter).map((child) => ({
            render: child.props.children || (() => <></>),
        }));
    }, [children])

    const filterItem = (item) => {
        return Object.values(filterFunctions).every((filterFunction) => filterFunction(item));
    };


    return (
        <Wrap className='supertable-wrap' useAlternateStyles={useAlternateStyles}>
            <div className='supertable-header'>
                <Row className='supertable-header-row'>
                    {columns.map((column, columnIndex) => {
                        const columnKey = `${column.title}-${columnIndex}`;

                        let FilterComponent = column.filter;

                        // the user passed filter as true, but did not offer a specific filter component, will use generic text filter as fallback
                        if (typeof FilterComponent === 'boolean' && FilterComponent) {
                            FilterComponent = DeprecatedSuperTableGenericTextFilterDoNotUse;
                        }
                        // if we're using a selectable super table, then the first column is the checkbox. however, this means that
                        // when we go to use defaultSortColumn, then the index of the first column is 1, which is confusing. so we just manually add
                        // 1 to the defaultSortColumn if we're using a selectable super table
                        if (isUsingDefaultSort && columnIndex === defaultSortColumn+(_isSelectable ? 1 : 0) && column.sort) {
                            setIsUsingDefaultSort(false);
                            sortFunction.current = column.sort;
                            setSortColumn(columnKey);
                            setShouldReverseSort(false);
                        }

                        return (
                            <div
                                style={{ flex: column.size }}
                                key={columnKey}
                                className={`supertable-header-row-data ${column.className || ''}`}
                                id={columnKey}
                            >
                                {FilterComponent && (
                                    <DeprecatedSuperTableColumnFilterDoNotUse>
                                        <FilterComponent items={items} renderItem={column.render} extractFilterString={column.extractFilterString} />
                                    </DeprecatedSuperTableColumnFilterDoNotUse>
                                )}

                                {useAlternateStyles ? (
                                    <div>{column.title}</div>
                                ) : (
                                    <H4>{column.title}</H4>
                                )}
                                {
                                    column.sort && <FiSliders onClick={() => {
                                        setIsUsingDefaultSort(false);

                                        if (sortColumn === columnKey) {
                                            const newArr = [...sortIconDirection]
                                            newArr[columnIndex] = !newArr[columnIndex]
                                            setSortIconDirection(newArr)
                                            setShouldReverseSort(!shouldReverseSort);
                                        } else {
                                            sortFunction.current = column.sort;
                                            setSortColumn(columnKey);
                                            setShouldReverseSort(sortIconDirection[columnIndex]);
                                        }
                                        }}
                                        style={{
                                            transform: `rotate(${sortIconDirection[columnIndex] ? "0.5turn" : "0turn"}`,
                                            opacity: sortColumn === columnKey ? '1.0' : '0.3',
                                            transition: 'all .1s ease-in',
                                            flexShrink: 0
                                        }}
                                    />
                                }
                            </div>
                        );
                    })}
                </Row>
            </div>
            <div className='supertable-body' style={bodyStyle}>
                {items.filter(filterItem).sort(sortFunction.current)[shouldReverseSort ? "reverse" : "slice"]().map((item, itemIndex) => {
                    const classes = getClassesForRow?.(item, itemIndex) || [];
                    const classesWithPrefixes = classes.map((c) => `supertable-row--${c}`);

                    const SpanWithBodyStyles = ({ children, ...props }) => {
                        return (
                            <Span applyBodyStyles {...props}>
                                {children}
                            </Span>
                        );
                    };

                    const RenderComponent = useAlternateStyles ? 'div' : SpanWithBodyStyles;

                    return (
                        <div key={itemIndex} className={`supertable-row ${classesWithPrefixes.join(' ')}`}>
                            <Row
                                onClick={() => onRowClicked?.(item, itemIndex)}
                                style={{gap: "0.5rem"}}
                            >
                                {columns.map((column, columnIndex) => (
                                    <RenderComponent
                                        style={{ flex: column.size }}
                                        key={`${itemIndex}-${columnIndex}`}
                                        className='supertable-row-data'
                                    >
                                        {column.render(item, itemIndex)}
                                    </RenderComponent>
                                ))}
                            </Row>
                            {
                                footers.map((footer, footerIndex) => {
                                    return (
                                        <React.Fragment key={footerIndex}>
                                            {footer.render(item, itemIndex)}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    );
                })}
            </div>
        </Wrap>
    );
};

const DeprecatedSuperTableDoNotUse = ({ children, ...props }) => {
    return (
        <SuperTableContextProvider>
            <SuperTableBody {...props}>{children}</SuperTableBody>
        </SuperTableContextProvider>
    );
};

// merely here to appease intellisense
const SuperTableColumn = ({title = "", children, size = "", filter = () => {}, extractFilterString = () => {}, sort = (a,b) => a>b}) => {
    return null
}

const SuperTableFooter = () => {
    return null
}

DeprecatedSuperTableDoNotUse.Column = SuperTableColumn
DeprecatedSuperTableDoNotUse.Footer = SuperTableFooter

DeprecatedSelectableSuperTableDoNotUse.Column = DeprecatedSuperTableDoNotUse.Column;
DeprecatedSelectableSuperTableDoNotUse.Footer = DeprecatedSuperTableDoNotUse.Footer;

export default DeprecatedSuperTableDoNotUse;
