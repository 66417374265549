import { LOAD_COUNTRIES, LOAD_CURRENCIES, LOAD_REJECTION_REASONS } from './types';

export const loadCountries = () => dispatch => {
    try {
        window.eulerity.makeApiCall('/api/franchise/getDataFile?filename=countries', 'GET', null, response => {
            dispatch({ type: LOAD_COUNTRIES, payload: response })
        });
    } catch (err) {
        console.error('Unable to load country list');
    }
}

export const loadCurrencies = () => dispatch => {
    try {
        // Get symbol data for currencies in currencyList
        window.eulerity.makeApiCall('/api/franchise/getDataFile?filename=currency', 'GET', null, json => {
            // Get currency, conversion, created, updated
            window.eulerity.makeApiCall('/api/currency/getAll', 'GET', null, currencyData => {

                let currencyList = currencyData || [];
                currencyList.forEach((c, idx) => {
                    let currencySymbol = json.find(x => x.currency === c.currency).symbol;
                    currencyList[idx].symbol = currencySymbol;
                });

                dispatch({
                    type: LOAD_CURRENCIES,
                    payload: currencyList
                })
            });
        });
    } catch (err) {
        console.error('Unable to load currency list');
    }
}

export const loadRejectionReasons = () => dispatch => {
    try {
        window.eulerity.makeApiCall('/api/rejection/getAll', 'GET', null, function(resp) {
            dispatch({
                type: LOAD_REJECTION_REASONS,
                payload: resp
            })
        })
    } catch (err) {
        console.error('Unable to load rejection reasons list');
    }
}

