import columnBuilder from "../../../columnBuilder";
import { reportLoader } from '../../../loaders';

const costDistributionReportCategory = columnBuilder()
	.category('distribution')
	.subCategory('Spend')
	.loadRow(reportLoader)
	.makeHeader((meta) => {
		return `${meta.subCategory} ${meta.name}`
	})

const costDistributionFormatter = (value: number) => {
	return `${value.toFixed(0)}%`
}

const costColumns = [
	costDistributionReportCategory
		.id('cost-by-display')
		.name('By Display')
		.makeRow((data) => {
			const { row } = data
			return row.distributions.costs.other
		})
		.toString(costDistributionFormatter),
	costDistributionReportCategory
		.id('cost-by-search')
		.name('By Search')
		.makeRow((data) => {
			const { row } = data
			return row.distributions.costs.search
		})
		.toString(costDistributionFormatter),
	costDistributionReportCategory
		.id('cost-by-social')
		.name('By Social')
		.makeRow((data) => {
			const { row } = data
			return row.distributions.costs.social
		})
		.toString(costDistributionFormatter),
	costDistributionReportCategory
		.id('cost-by-video')
		.name('By Video')
		.makeRow((data) => {
			const { row } = data
			return row.distributions.costs.video
		})
		.toString(costDistributionFormatter),
]

export default costColumns