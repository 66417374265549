import React from 'react'
import { H2, Small } from '../../../styles/typography'
import { useTheme } from 'styled-components'
import { hexToRgba } from '../../../helpers/colors.util'
import { IncreaseBadge } from '../../reputationManagement/components/IncreaseBadge'

type HeaderProps = {
    icon?: React.ReactNode
    children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const Header = (props: HeaderProps) => {
    const { children, icon, ...rest } = props
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                gap: '0.5rem',
                minHeight: '20px'
            }}
            {...rest}
        >
            { !!icon && <div style={{
                width: '20px',
            }}>{icon}</div> }
            <Small semibold color="textColor2">
                {children}
            </Small>
        </div>
    )
}

type ContentProps = {
    children: React.ReactNode
} & React.HTMLAttributes<HTMLHeadingElement>

const Value = (props: ContentProps) => {
    const { children, color, onClick, style, ...rest } = props
    return (
        <H2 color="textColor1" style={{
            margin: '0 1.5rem',
            ...style
        }} {...rest}>
            {children}
        </H2>
    )
}

type WrapProps = React.HTMLAttributes<HTMLDivElement>

const Layout = (props: WrapProps) => {
    const {children, style, ...rest} = props
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'flex-start',
                padding: '1rem',
                userSelect: 'none',
                flex: 1,
                ...style,
            }}
            {...rest}
        >
            {children}
        </div>
    )
}

const Wrap = (props: WrapProps & {
    isActive?: boolean
}) => {
    const theme = useTheme()
    const {children, style, ...rest} = props
    return (
        <Layout
            style={{
                border: '1px solid',
                borderRadius: '0.5rem',
                borderColor: props.isActive ? theme.color.accentColor2 : hexToRgba(theme.color.greyAccent1, 0.5),
                backgroundColor: props.isActive ? hexToRgba(theme.color.accentColor4, 0.25) : 'transparent',
                transition: 'all 0.2s',
                cursor: props.onClick && 'pointer',
                ...style,
            }}
            {...rest}
        >
            {children}
        </Layout>
    )
}



export const Card = {
    Header,
    Value,
    Layout,
    Wrap,
    Change: IncreaseBadge
}
