import React from 'react';
import { Body, StatusBubble, XS } from '../../styles/typography';
import { StyledEntityOption } from './styles';
import useDynamicSetting from '../../hooks/useDynamicSetting';

export const ITEM_CONSTANT = {
    CREATIVE_FOLDER: 'Creative Folders',
    PRIMARY: 'Primary Campaigns',
    SECONDARY: 'Secondary Campaigns',
    REPO: 'repo',
    LOCATION: 'location',
    BLUEPRINT: "Blueprints",
    DEFAULT_CAMPAIGN_SETUP: "default-campaign-setup"
}

const EntityOption = ({ option, onSelectOption, isDisabled, isAd, colorScheme }) => {
    const {name, email, labelText, type, status, category, displayName} = option

    const { allowInactivePosting } = useDynamicSetting();

    const getItemType = (category) => {
        if (category === ITEM_CONSTANT.BLUEPRINT || category === ITEM_CONSTANT.DEFAULT_CAMPAIGN_SETUP) return 'Blueprint'
        if (category === ITEM_CONSTANT.CREATIVE_FOLDER) return 'Creative Folder'
        if (category === ITEM_CONSTANT.SECONDARY) return 'Secondary Campaign'
        return 'Primary Campaign'
    }

    return (
        <StyledEntityOption
            onClick={() => onSelectOption ? onSelectOption(option) : ''}
            isDisabled={isDisabled}
            isAd={isAd}
            isRepo={type === ITEM_CONSTANT.REPO}
            colorScheme={colorScheme}
            >
            <div className='statusAndType' >
                {!allowInactivePosting && type === ITEM_CONSTANT.LOCATION &&
                    <StatusBubble semibold variant={status.toLowerCase()}>{status}</StatusBubble>
                }
                <XS>{getItemType(category)}</XS>
            </div>
            <Body semibold style={{marginBottom: '7px'}}>{displayName}</Body>
            {type === ITEM_CONSTANT.LOCATION &&
            <>
                <XS disabled style={{marginBottom: '7px'}}>{labelText}</XS>
                <div className='infoContainer'>
                    <XS colorInherit ellipsis>{email}</XS>
                    <XS>·</XS>
                    <XS semibold colorInherit ellipsis>{name}</XS>
                </div>
            </>
            }
        </StyledEntityOption>
    )
}

export default EntityOption
