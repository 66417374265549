import styled from 'styled-components';

export const LoginForm = styled.div`
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    margin: auto auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    margin-top: 200px;

    button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }
`

export const SignUpForm = styled.div`
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    margin: auto auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    margin-top: 200px;

    button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }
`

export const TextLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

export const ErrorText = styled.p`
    color: red;
    font-size: 0.8rem;
    text-align: center;
`