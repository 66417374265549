import React from 'react';
import { CheckboxWrap } from './styles';
import { FaCheck, FaMinus } from 'react-icons/fa';

type CheckBoxProps = {
    checked?: boolean;
    disabled?: boolean;
    dashed?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    size?: 'small' | 'large';
} & React.HTMLAttributes<HTMLDivElement>;

const CheckBox = (props: CheckBoxProps) => {
    return (
        <CheckboxWrap
            size={props.size}
            checked={props.checked}
            disabled={props.disabled}
            dashed={props.dashed}
            onClick={props.onClick}
            className={props.className}
        >
            {props.checked && <FaCheck />}
            {props.dashed && <FaMinus />}
        </CheckboxWrap>
    );
};

export default CheckBox;