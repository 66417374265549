// * Function will convert an opacity to a hex value
// export const opacityToHex = (opacity) => {
//     const intValue = Math.round(opacity / 100 * 255); // map opacity to nearest integer (0 - 255)
//     const hexValue = intValue.toString(16); // get hexadecimal representation
//     return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
// }
export type Hex = `#${string}`
export type RGB = {
	r: number
	g: number
	b: number
}

// * Function will convert a hex color to rgba (alpha defaults to 1 if none is given - equivalent to rgb)
export const hexToRgba = (hex: Hex, alpha = 1): string | undefined  => {
	const parsedHex = hex?.match(/\w\w/g)?.map((x) => parseInt(x, 16))
	if (!parsedHex || parsedHex.length !== 3) return
	const [r, g, b] = parsedHex
	return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const hexToRgbType = (hex: Hex): RGB => {
	const bigint = parseInt(hex?.slice(1), 16)
	const r = (bigint >> 16) & 255
	const g = (bigint >> 8) & 255
	const b = bigint & 255

	return { r, g, b }
}

// * Function will convert Hex color to RGB color
export function hexToRgb(hex: Hex) {
	const { r, g, b } = hexToRgbType(hex)
	return r + ',' + g + ',' + b
}

// * Function determines if a given color (hex) is light or dark
export const isHexLight = (color: Hex) => {
	if (!color) return
	const hex = color.replace('#', '')
	const c_r = parseInt(hex.substr(0, 2), 16)
	const c_g = parseInt(hex.substr(2, 2), 16)
	const c_b = parseInt(hex.substr(4, 2), 16)
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
	return brightness > 155
}

// Formula defined by standards to get contrast https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
function calculateRelativeLuminance(color: number) {
	const sRGB = color / 255.0
	return sRGB <= 0.03928
		? sRGB / 12.92
		: Math.pow((sRGB + 0.055) / 1.055, 2.4)
}

function getContrast(hexColor1: Hex, hexColor2: Hex) {
	const color1 = hexToRgbType(hexColor1)
	const color2 = hexToRgbType(hexColor2)

	const luminance1 =
		calculateRelativeLuminance(color1.r) * 0.2126 +
		calculateRelativeLuminance(color1.g) * 0.7152 +
		calculateRelativeLuminance(color1.b) * 0.0722

	const luminance2 =
		calculateRelativeLuminance(color2.r) * 0.2126 +
		calculateRelativeLuminance(color2.g) * 0.7152 +
		calculateRelativeLuminance(color2.b) * 0.0722

	const contrast =
		(Math.max(luminance1, luminance2) + 0.05) /
		(Math.min(luminance1, luminance2) + 0.05)

	return contrast
}

const ArrayMax = <T>(input: T[], predicate: (input: T) => number): T => {
	return input.reduce((acc, curr) => {
		return predicate(acc) > predicate(curr) ? acc : curr
	})
}

// * Function will check a given bg color and return either black or white text depending on the contrast.
export const decideTextColor = (bgColor: Hex, options: Hex[] = []) => {
	// black and white text colors are defaults
	// options should contain at least a light text color and a dark text color
	// so that we can try to use them below
	let aLightTextColor = options?.find((color) => isHexLight(color))
	let aDarkTextColor = options?.find((color) => !isHexLight(color))
	return isHexLight(bgColor)
		? aDarkTextColor || 'black'
		: aLightTextColor || 'white'
}

// * Function that will return the most appropriate text color for a background
export const getHighestContrastColor = (bgColor: Hex, options: Hex[] = []) => {
	const highestContrastColor = ArrayMax(options, (color) =>
		getContrast(bgColor, color)
	)
	return highestContrastColor
}

// * Meant to re-build color objects returned from backend to be in like with our theme color obj format
export const buildColorObject = (themeObject?: BackendColorTheme) => {
	const colorTheme = Object.keys(colorMapper).reduce((acc, key) => {
		const backendColorKey = key as keyof BackendColorTheme
		return {
			...acc,
			[colorMapper[backendColorKey]]: `#${
				themeObject?.[backendColorKey] || '000000'
			}`,
		}
	}, {} as ColorTheme)
	return colorTheme
}

// * Translate our frontend ColorTheme object to a BackendColorTheme
export const buildBackendThemeObject = (colorTheme: ColorTheme): BackendColorTheme => {

	const backendTheme: BackendColorTheme = {
		...colorMapper
	}

	const isBackendColorThemeKey = (input: string): input is keyof BackendColorTheme => {
		return (input in colorMapper)
	}

	Object.entries(colorMapper).forEach(([backendKey, frontendKey]) => {

		if (!isBackendColorThemeKey(backendKey)) {
			throw new Error("invalid theme")
		}

		backendTheme[backendKey] = colorTheme[frontendKey].replace("#", "")
	})


	return backendTheme
}

// we can use this as our source of truth for color keys
const colorMapper = {
	'color-accent1': 'accentColor1',
	'color-accent2': 'accentColor2',
	'color-accent3': 'accentColor3',
	'color-accent4': 'accentColor4',
	'color-creative1': 'creativeColor1',
	'color-creative2': 'creativeColor2',
	'color-creative3': 'creativeColor3',
	'color-creative4': 'creativeColor4',
	'color-text1': 'textColor1',
	'color-text2': 'textColor2',
	'color-text3': 'textColor3',
	'color-warningbutton': 'warningButtonColor',
	'color-activebutton': 'activeButtonColor',
	'color-errornegativebutton': 'errorNegativeButtonColor',
	'color-background1': 'backgroundColor1',
	'color-background2': 'backgroundColor2',
	'color-greyaccent1': 'greyAccent1',
	'color-greyaccent2': 'greyAccent2',
	'color-navbackground': 'navbarBackgroundColor',
	'color-navtext': 'navbarTextColor',
	'color-navtextactive': 'navbarActiveTextColor',
} as const

type ColorMapper = typeof colorMapper

export type BackendColorTheme = {
	-readonly [key in keyof ColorMapper]: string
}

export type ColorTheme = {
	[key in ColorMapper[keyof ColorMapper]]: Hex
}

export type ColorThemeKey = keyof ColorTheme