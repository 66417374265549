import _ from 'lodash'
import { REPO_SELECT_OPTION, REPO_LIST, REPO_CREATIVE_LIST, BLUEPRINTS_LIST, CLEAR_REPO, CREATE_REPO, DELETE_REPO_FOLDER, UPDATE_BLUEPRINT, ADD_BLUEPRINT, UPDATE_REPO } from '../actions/types';
import { CREATIVE_KIND } from '../helpers/creative.util';

const initialState = {
    selectedRepoOption: {kind: CREATIVE_KIND.CREATIVE, isMagic: false},
    selectedDate: null,
    repoList: [],
    repoCreativeList: [],
    blueprints: [],
}


const repoReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPO_SELECT_OPTION: {
            return { ...state, selectedRepoOption: action.payload }
        }
        case REPO_LIST: {
            return { ...state, repoList: action.payload }
        }
        case REPO_CREATIVE_LIST: {
            return { ...state, repoCreativeList: action.payload,  }
        }
        case BLUEPRINTS_LIST: {
            return { ...state, blueprints: action.payload }
        }
        // this case expects action.payload to be the entire payload object
        // this is because as we null out and delete fields, other fields may be deleted that weren't part of the original update
        // since the backend returns the updates blueprint object, it just makes sense to replace it
        case UPDATE_BLUEPRINT: {
            return {
                ...state,
                blueprints: state.blueprints.map(blueprint => {
                    if(blueprint.id !== action.payload.id) {
                        return blueprint
                    }

                    return action.payload
                })
            }
        }
        case ADD_BLUEPRINT: {
            return {
                ...state,
                blueprints: [...state.blueprints, action.payload]
            }
        }
        case CREATE_REPO: {
            return { ...state, repoList: [...state.repoList, action.payload] }
        }
        case UPDATE_REPO: {
            let allRepos = _.cloneDeep(state.repoList)
            let updatedRepo = action.payload
            let updatedIndex = allRepos.findIndex(repo => repo.id === updatedRepo.id)
            allRepos[updatedIndex] = updatedRepo
            return { ...state, repoList: [...allRepos] }
        }
        case DELETE_REPO_FOLDER: {
            const {lid} = action.payload
            const filteredRepoList = state.repoList.filter((repo) => repo.id !== lid)
            return {...state, repoList: filteredRepoList }
        }
        case CLEAR_REPO:
            return initialState;
        default: {
            return { ...state }
        }
    }
}


export default repoReducer