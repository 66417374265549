import facebookColumns from "./facebook";
import ga4Columns from "./ga4";
import gmbColumns from "./gmb";
import instagramColumns from "./instagram";
import linkedinColumns from "./linkedin";
import { xReportColumns } from "./x";

const platformConnectionColumns = [
	...facebookColumns,
	...ga4Columns,
	...gmbColumns,
	...instagramColumns,
	...linkedinColumns,
	...xReportColumns
]

export default platformConnectionColumns