import styled from "styled-components";
import { decideTextColor, hexToRgba } from "../../../../../helpers/colors.util";
import { BodyCss } from "../../../../../styles/typography";

export const StyledDateStepWrapper = styled.div`

    .date-options-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`

export const StyledAlertWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    border: 1px solid ${props => hexToRgba(props.theme.color.greyAccent1, 0.5)};
    margin-top: 8px;
`

export const StyledOptionCard = styled.div`
    display: flex;
    gap: 16px;
    align-items: start;
`

export const StyledPublishWrapper = styled.div`

    .publish-options-wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .date-picker-wrapper {
        margin-top: 4px;

        .react-datepicker__input-container {
            width: 428px;

            input {
                ${BodyCss};
                background: transparent;
                color:${props => props.theme.color.textColor1};
                border-radius: 6px;
                border-color: ${props => props.theme.color.accentColor3};

                &:focus {
                    outline: solid ${props => props.theme.color.accentColor2} !important;
                }
            }

        }

        .react-datepicker-time__header {
            color: ${props => props.theme.color.textColor1};
        }

        .react-datepicker__time-list {
            background: ${props => props.theme.color.accentColor4};
        }

        .react-datepicker__time-list-item--selected {
            background-color: ${props => props.theme.color.accentColor2} !important;
        }

        .react-datepicker__time-list-item {
            &:hover {
                background-color: ${(props) => props.theme.color.accentColor3} !important;
                color: ${(props) => decideTextColor(props.theme.color.accentColor3)};
            }
        }

        .react-datepicker__close-icon {
            ::after {
                background-color: ${props => props.theme.color.accentColor2};
                color: ${props => props.theme.color.backgroundColor2};
        }
    }

`