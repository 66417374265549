import React from 'react'
import { gmtToLocaleString } from '../../../../../helpers'
import {
	POST_STATUS,
	CREATIVE_STATUS_COPY_MAP,
} from '../../../../../helpers/creative.util'
import { Small, Span } from '../../../../../styles/typography'
import { Post, Network } from '../../../creative.types'
import { useCreativeInfo } from '../../../utils'

export const NetworkStatusItemText = (props: {
	post: Post
	network: Network
}) => {
	const { getCreativeStatus } = useCreativeInfo()
	const status = getCreativeStatus(props.post, props.network)

	if (status === POST_STATUS.POSTED) {
		return (
			<Small semibold>
				Posted{' '}
				<Span regular>
					{gmtToLocaleString(
						props.post.postedNetworks?.[props.network]?.postedDate
					)}
				</Span>
			</Small>
		)
	}

	if (status === POST_STATUS.PENDING) {
		return (
			<Small semibold>
				{CREATIVE_STATUS_COPY_MAP[status]}{' '}
				<Span regular>Posting in progress</Span>
			</Small>
		)
	}

	return <Small semibold>{CREATIVE_STATUS_COPY_MAP[status]}</Small>
}
