import { SELECT_FORK, SELECT_CUSTOM_OR_PREMADE_FORK, SET_YOUTUBE_URL,
    SET_BUILDER_LOCATION, SET_BUILDER_LOGOS,
    CLEAR_BUILD_TAB, SET_UPLOADED_MEDIA_DATA ,
    SELECT_YOUTUBE_OR_UPLOAD_FORK, SET_LAST_EDITED, AD_OR_POST_FORK, CREATION_TYPE,
    SET_BREADCRUMB_PATH,REMOVE_BREADCRUMB_PATH, SET_URL_PATH, SET_BREADCRUMB_BULK, SET_BUILDER_FRANCHISE_IMAGES, MAGIC_OR_TEMPLATE_FORK, SET_SELECTED_REPO_KEY
} from '../actions/types'


// For franchisees, we need to pull options for the builder from their locations
// as they do not have access to franchise wide data

const initialState = {
    creationType: null,
    fork: '',
    magicOrTemplateFork: null,
    adOrPostFork: null,
    customOrPremade: '',
    youtubeOrUpload: '',
    youtubeUrl: '',
    mediaData: null,
    breadCrumbPath: [],
    urlPath: null,
    // Editing Creative
    lastEdited: null,
    // Franchisee States
    builderLocationOption: null,
    builderLogos: [],
    franchiseImages: [],
    selectedRepoKey: null,
}

const buildReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_FORK: {
            return { ...state, fork: action.payload }
        }
        case MAGIC_OR_TEMPLATE_FORK: {
            return {... state, magicOrTemplateFork: action.payload}
        }
        case SELECT_CUSTOM_OR_PREMADE_FORK: {
            return { ...state, customOrPremade: action.payload }
        }
        case SELECT_YOUTUBE_OR_UPLOAD_FORK: {
            return { ...state,  youtubeOrUpload: action.payload}
        }
        case SET_YOUTUBE_URL: {
            return {...state, youtubeUrl: action.payload}
        }
        case AD_OR_POST_FORK: {
            return {...state, adOrPostFork: action.payload}
        }
        case CREATION_TYPE: {
            return {...state, creationType: action.payload}
        }
        case SET_UPLOADED_MEDIA_DATA : {
            return {...state, mediaData: action.payload}
        }
        case SET_BUILDER_LOCATION: {
            return { ...state, builderLocationOption: action.payload }
        }
        case SET_BUILDER_FRANCHISE_IMAGES: {
            return { ...state, franchiseImages: action.payload }
        }
        case SET_BUILDER_LOGOS: {
            return { ...state, builderLogos: action.payload }
        }
        case CLEAR_BUILD_TAB:  {
            return initialState;
        }
        case SET_LAST_EDITED: {
            return { ...state, lastEdited: action.payload }
        }
        case SET_URL_PATH: {
            return {...state, urlPath: action.payload}
        }

        case SET_SELECTED_REPO_KEY : {
            return {...state, selectedRepoKey: action.payload}
        }

        case SET_BREADCRUMB_PATH: {
            const {path, skipException} = action.payload
            const doesPathExists = state.breadCrumbPath.find(p => p.forkName === path.forkName)
            if(doesPathExists && !skipException) return state
            return {...state, breadCrumbPath: [...state.breadCrumbPath, path]}
        }
        case REMOVE_BREADCRUMB_PATH: {
            let index = state.breadCrumbPath.findIndex(path => path.forkName === action.payload)
            if(index < 0) return state
            if(index !== state.breadCrumbPath.length-1) index+=1
            return { ...state, breadCrumbPath: state.breadCrumbPath.slice(0, index )}
        }
        case SET_BREADCRUMB_BULK: {
            return {...state, breadCrumbPath: action.payload}
        }
        default: {
            return { ...state }
        }
    }

}


export default buildReducer