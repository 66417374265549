import { POST_NETWORK } from "../../helpers/creative.util"

export const CONNECTIONS = {
    TWITTER: 'twitter',
    FACEBOOK_ADVERTISING: 'facebook-advertising',
    FACEBOOK_POSTING: 'facebook-posting',
    INSTAGRAM_ADVERTISING: 'instagram-advertising',
    INSTAGRAM_POSTING: 'instagram-posting',
    GOOGLEANALYTICS_4: 'googleAnalytics4',
    LINKEDIN: 'linkedin',
    GOOGLEBUSINESS: 'googleBusiness'
}

export const LOCATION_NETWORK_CONNECTIONS = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    GA4: CONNECTIONS.GOOGLEANALYTICS_4,
    LINKEDIN: CONNECTIONS.LINKEDIN,
    GOOGLEBUSINESS: CONNECTIONS.GOOGLEBUSINESS,
    TWITTER: CONNECTIONS.TWITTER
}

export const LOCATION_CONNECTIONS = {
    FACEBOOK_ADVERTISING: "FACEBOOK_ADVERTISING",
    FACEBOOK_POSTING: "FACEBOOK_POSTING",
    INSTAGRAM_ADVERTISING: "INSTAGRAM_ADVERTISING",
    INSTAGRAM_POSTING: "INSTAGRAM_POSTING",
    LINKEDIN_ADVERTISING: "LINKEDIN_ADVERTISING",
    LINKEDIN_POSTING: "LINKEDIN_POSTING",
    GOOGLE_ANALYTICS_4: "GOOGLE_ANALYTICS_4",
    GOOGLE_BUSINESS: "GOOGLE_BUSINESS",
    TWITTER: "TWITTER"
} as const

export const POST_NETWORK_TO_CONNECTION = {
    [POST_NETWORK.FACEBOOK]: LOCATION_CONNECTIONS.FACEBOOK_POSTING,
    [POST_NETWORK.INSTAGRAM]: LOCATION_CONNECTIONS.INSTAGRAM_POSTING,
    [POST_NETWORK.TWITTER]: LOCATION_CONNECTIONS.TWITTER,
    [POST_NETWORK.LINKEDIN]: LOCATION_CONNECTIONS.LINKEDIN_POSTING,
    [POST_NETWORK.GMB]: LOCATION_CONNECTIONS.GOOGLE_BUSINESS,
}

export const CAMPAIGN_NETWORK_CONNECTIONS = Object.values(LOCATION_NETWORK_CONNECTIONS)

export const POSTING_NETWORK_CONNECTIONS = [
    LOCATION_NETWORK_CONNECTIONS.FACEBOOK,
    LOCATION_NETWORK_CONNECTIONS.INSTAGRAM,
    LOCATION_NETWORK_CONNECTIONS.GOOGLEBUSINESS,
    LOCATION_NETWORK_CONNECTIONS.LINKEDIN,
    LOCATION_NETWORK_CONNECTIONS.TWITTER,
]

export const ADVERTISING_NETWORK_CONNECTIONS = [
    LOCATION_NETWORK_CONNECTIONS.FACEBOOK,
    LOCATION_NETWORK_CONNECTIONS.INSTAGRAM,
    LOCATION_NETWORK_CONNECTIONS.GA4,
    LOCATION_NETWORK_CONNECTIONS.LINKEDIN
]

export const DEFAULT_LOCATION_NETWORK_CONNECTIONS = [LOCATION_NETWORK_CONNECTIONS.TWITTER]