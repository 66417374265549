import { API } from '../constants/api';
import { canManageFranchise } from '../helpers/permissionsHelper';
import { CLEAR_MEMBER_SUMMARIES, LOAD_MEMBER_SUMMARIES } from './types';

const loadUserSummary = (uid) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `${API.USER.GET_SUMMARY}?uid=${uid}`,
            method: 'GET',
            callback: (summary) => {
                resolve(summary);
            },
            errorCallbacks: {
                1000: reject
            },
            retry: 3
        });
    });
};

const loadFranchiseSummaries = (franchiseName) => {
    return new Promise((resolve, reject) => {
        let loaded = [];

        window.eulerity.makeBatchedApiCall({
            url: `${API.FRANCHISE.LIST_MEMBER_SUMMARIES}?franchise=${encodeURIComponent(franchiseName)}`,
            dataCallback: (summ) => {
                loaded = loaded.concat(summ);
            },
            doneCallback: () => {
                resolve(loaded);
            },
            errorCallbacks: {
                1000: reject
            },
            retry: 3
        })
    });
};

export const loadMemberSummaries = () => async (dispatch, getState) => {
    const { user, franchise } = getState();

    if (!user || !franchise.name) return;

    let summaries = [];

    try {
        if (canManageFranchise(user.accessLevel)) {
            summaries = await loadFranchiseSummaries(franchise.name);
        } else {
            summaries = await loadUserSummary(user.id);
        }
    } catch(err) {
        console.error("error loading summaries")
    }

    dispatch({
        type: LOAD_MEMBER_SUMMARIES,
        payload: summaries,
    });
};

export const clearMemberSummaries = () => dispatch => {
    dispatch({
        type: CLEAR_MEMBER_SUMMARIES
    })
}