import React, { createContext, useCallback, useState } from 'react';

export const DeprecatedSuperTableContextDoNotUse = createContext();

export const SuperTableContextProvider = ({ children }) => {
    const [filterFunctions, setFilterFunctions] = useState({});
    const [filterStates, setFilterStates] = useState({})

    const updateFilterFunction = useCallback((func, key) => {
        setFilterFunctions((f) => {
            return {
                ...f,
                [key]: func,
            };
        });
    }, []);

    const updateFilterState = useCallback((key, state) => {
        setFilterStates((s) => {
            return {
                ...s,
                [key]: state,
            };
        });
    }, [])

    return (
        <DeprecatedSuperTableContextDoNotUse.Provider
            value={{
                updateFilterFunction,
                updateFilterState,
                filterFunctions,
                filterStates,
            }}
        >
            {children}
        </DeprecatedSuperTableContextDoNotUse.Provider>
    );
};

export default DeprecatedSuperTableContextDoNotUse;
