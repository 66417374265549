import React, { useState } from 'react';
import Input from '../../../styles/inputs';
import { CollaboratorType, FormDataType } from '../modals/steps/CreativeStepInstagramCollaborators';
import { BigBody, Body, XS } from '../../../styles/typography';
import { FiAtSign, FiTrash2 } from 'react-icons/fi';
import StringUtil from '../../../helpers/string.util';
import { uniqueId } from 'lodash';
import Regex from '../../../constants/regex';
import { useTheme } from 'styled-components';
import useCollaboratorStep from '../hooks/useCollaboratorStep';

type CollaboratorProps = {
    collaborator: CollaboratorType,
    formData: FormDataType;
    updateFormData: (key: keyof FormDataType, value: CollaboratorType[]) => void;
}

const Collaborator = ({collaborator, formData, updateFormData}: CollaboratorProps) => {
    const IG_HANDLE_LIMIT = 30;
    const [collaboratorIGHandle, setCollaboratorIGHandle] = useState(collaborator || { name: "", id: uniqueId()})
    const { hasDuplicates } = useCollaboratorStep(formData)
    const theme = useTheme()

    const handleChange = (obj: CollaboratorType) => {
        setCollaboratorIGHandle(obj)

        const updatedCollaborators = formData?.instagramCollaborators?.map((collaborator: CollaboratorType) => {
            if (collaborator.id === obj.id) return obj;
            return collaborator;
        })

        if (updatedCollaborators) updateFormData('instagramCollaborators', updatedCollaborators)
    }

    const removeCollaborator = (id: string) => {
        const filteredCollaborators = formData?.instagramCollaborators?.filter((collaborator: CollaboratorType) => collaborator?.id !== id);
        if (filteredCollaborators) updateFormData('instagramCollaborators', filteredCollaborators)
    }

    const hasInvalidSpecialCharacters = StringUtil.containsSpecialCharacters(collaborator.name, Regex.INSTAGRAM_HANDLE_INVALID_CHARACTERS)

    return (
        <div style={{ display: 'flex', gap: '1.5rem', paddingRight: '1.5rem' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '0.625rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <BigBody> Collaborator </BigBody>
                    <Body disabled> {collaborator.name.length}/{IG_HANDLE_LIMIT} </Body>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <FiAtSign style={{
                        position: 'absolute',
                        marginLeft: '20px'
                    }}/>
                    <Input
                        invalid={hasInvalidSpecialCharacters || hasDuplicates}
                        style={{ paddingLeft: '2.5rem', flex: '1' }}
                        placeholder='Enter Instagram Account Handle'
                        value={collaboratorIGHandle.name}
                        onChange={e => handleChange({name: e.target.value, id: collaborator.id})}
                        maxLength={IG_HANDLE_LIMIT}
                    />
                </div>
                {
                    hasInvalidSpecialCharacters &&
                    <XS error>Please note that Instagram handles only contain letters, periods, numbers, or underscores.</XS>
                }
                {
                    hasDuplicates &&
                    <XS error>Please input a new Instagram handle that is different from your previous entry.</XS>
                }
            </div>
            <FiTrash2
                size={32}
                style={{ marginTop: '2.875rem', opacity: '0.6', cursor: 'pointer' }}
                color={theme.color.textColor1}
                onClick={() => removeCollaborator(collaborator.id)}
            />
        </div>
    )
}

export default Collaborator;