import React from 'react'
import { useTheme } from 'styled-components'

const TwinkleIcon = ({color, size} : {
    color?: string,
    size?: number,
}) => {
    const theme = useTheme()
    const iconColor = color || theme.color.creativeColor2
    const iconSize = size || 24

    return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2533 9.77846C14.0082 8.04292 15 4 15 4C15 4 15.9918 8.04292 17.7467 9.77846C19.3842 11.3979 23 12.3077 23 12.3077C23 12.3077 19.366 13.4764 17.7467 15.2062C15.8152 17.2694 15 22 15 22C15 22 14.1848 17.2694 12.2533 15.2062C10.634 13.4764 7 12.3077 7 12.3077C7 12.3077 10.6158 11.3979 12.2533 9.77846Z" stroke={iconColor} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 3.5L8 4.76923L6 6.5L5 8L4 6.5L2 4.76923L4 3.5L5 2L6 3.5Z" stroke={iconColor} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 13L3 13.9231L2 15L1 13.9231L2 13Z" stroke={iconColor} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 8 18)" fill={iconColor}/>
        </svg>
    )
}

export default TwinkleIcon