import React from 'react'
import { StyledLandingStep, LandingPageFieldGroup, LandingPageDisplay } from '../styles';
import { Body, H2 } from '../../../../styles/typography';
import { RESTRICTIONS } from '../../../../constants/restrictions';
import { useAccess } from '../../../../hooks/useAccess';
import { useMemo } from 'react';
import Input from '../../../../styles/inputs';
import { OBJECTIVE } from '../../../../helpers/objectives';
import Alert from '../../../../components/alert/Alert';
import { ALERT } from '../../../../components/alert/util';

/**
 * This component has 3 possible cases:
 * 1) We are building a new creative
 * 2) We are editing an existing creative
 * 3) We are accepting an existing optional creative (magic)
 */
const CreativeStepLanding = ({ creative, context }) => {
    const { formData, handleFieldChange } = React.useContext(context)
    const { isRestricted } = useAccess()

    const isLandingPageRestricted = isRestricted(RESTRICTIONS.CREATIVE_LANDING_PAGE)

    const numSelectedLeadCampaigns = (formData?.selectedLocations || []).filter(loc => loc.objective === OBJECTIVE.LEADS.VALUE).length

    const defaultLandingPage = useMemo(() => {
        // We only want to show default page if there is ONE specific location
        if (formData?.selectedLocations?.length === 1) return formData?.selectedLocations[0].website
    }, [formData?.selectedLocations])

    const displayRestrictedContent = () => {
        // If using default, just show the defaultLandingDescription
        // Else show existingLandingDescription and specify what the landing page is
        const defaultLandingDescription = `People will be sent to your campaigns' default landing page when they click on this ad.  To change your landing page options please contact your corporate team.`
        const existingLandingDescription = `People will be sent to the following landing page when they click on this ad.  To change your landing page options please contact your corporate team.`

        let content = {
            landingPage: '',
            description: ''
        }

        if (creative) {
            // Use Whatever is Specified on Creative we're accepting or editing
            content.description = creative?.landingPage ? existingLandingDescription : defaultLandingDescription
            content.landingPage = creative?.landingPage ? creative?.landingPage : defaultLandingPage
        } else {
            // We're Creating a New Ad so restrict to default
            content.description = defaultLandingDescription
            content.landingPage = defaultLandingPage || ''
            // No particular landing page to display since we're using default
        }
        return (
            <>
                <Body>{content.description}</Body>
                {
                    content.landingPage &&
                    <LandingPageDisplay>
                        <Body>
                            <a target="_blank" href={content.landingPage} rel="noreferrer">{content.landingPage}</a>
                        </Body>
                    </LandingPageDisplay>
                }
            </>
        )
    }

    return (
        <StyledLandingStep>
            {
                numSelectedLeadCampaigns !== 0 && <>
                    <H2 regular>Lead Ad Form</H2>
                    <Body>When customers click on ads from Lead campaigns, they will be directed to the lead form you connect.</Body>
                    <Alert type={ALERT.WARNING.TYPE} title={`You have selected ${numSelectedLeadCampaigns} lead campaign(s). For these campaigns, users will be directed to lead forms instead of the landing page below.`} style={{margin: '20px 0px'}}/>
                </>
            }
            <H2 regular>Landing Page URL</H2>
            {isLandingPageRestricted ? (
                <>
                    {displayRestrictedContent()}
                </>
            ) : (
                <>
                    <Body>
                        This is the page people will be sent to when they click on this ad.
                        We recommend leaving this blank so the ads will use each campaign's default page.
                        If you need to override it, use https when available.
                        If this URL redirects, the ad will be rejected.
                    </Body>

                    <Alert type={ALERT.WARNING.TYPE} title={'If you are a Google Analytics user, be sure to use a landing page with Google Analytics set up.'} style={{margin: '20px 0px'}} />
                    <LandingPageFieldGroup>
                        <Input
                            type="text"
                            name='landingPage'
                            placeholder='Leave blank for campaign default'
                            value={formData?.landingPage}
                            onChange={handleFieldChange('landingPage')}
                            />
                    </LandingPageFieldGroup>
                </>
            )}
        </StyledLandingStep>
    )
}

export default CreativeStepLanding
