import { useCallback, useMemo, useRef, useState } from "react";
import _ from "lodash";

type FormValidator<T> = {
    [K in keyof T]?: (value: T[K], formData: T) => string | undefined;
}

export type UseFormStateType<T> = {
    formData: T;
    updatedFields: Partial<T>;
    errors: Partial<Record<keyof T, string>> | null;
    updateFormData: <J extends keyof T>(key: J, value: T[J]) => void;
    handleFieldChange: <J extends keyof T>(key: J) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    revertField: <J extends keyof T>(key: J) => void;
    deleteField: <J extends keyof T>(key: J) => void;
}

// We should be moving toward using this hook instead of react-hook-forms
// initialState is all of the default values we want to set. All keys should have a default value set
// formData is the object that holds all of the form data
// updateFormData is a function that takes a key and value and updates the formData object
// handleFieldChange should be used if possible instead of updateFormData. You pass this to an input or select component, and it will handle updating the formData object for you. <input onChange={handleFieldChange('name')} />
// updatedFields is an object that contains only the fields that have been updated. This is useful for sending only the updated fields
// validator is a mapping of the fields to check and the functions to check them. If validation fails, the functions should return an error message
// errors is an object that contains error messages per field if their validator function failed
// revertField sets the property at key back to whatever it was in the initial state
// deleteField is a function that takes a key and deletes it from the formData object
const useFormState = <FormType extends object>(initialState: FormType, validator: FormValidator<FormType> = {}): UseFormStateType<FormType> => {

    const [formData, setFormData] = useState<FormType>(initialState);
    const startingState = useRef<FormType>(initialState);

    const updateFormData = useCallback(<J extends keyof FormType>(key: J, value: FormType[J]) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    }, [setFormData]);

    const handleFieldChange = useCallback(<J extends keyof FormType>(key: J) => (e: React.ChangeEvent<HTMLInputElement>) => updateFormData(key, e.target.value as FormType[J]), [updateFormData]);

    const updatedFields = useMemo(() => {
        // https://stackoverflow.com/questions/67985183/lodash-difference-between-two-objects
        const changes = _.differenceWith(_.toPairs(formData), _.toPairs(startingState.current), _.isEqual);

        return _.fromPairs(changes) as Partial<FormType>;
    }, [formData]);

    const errors = useMemo(() => {
        const fieldsToValidate = Object.keys(validator) as (keyof FormType)[]
        if (!fieldsToValidate.length) return null

        // Each validation method should return an error message if there's an error
        let errs: Partial<Record<keyof FormType, string>> = {}
        for (let i = 0; i < fieldsToValidate.length; i++) {
            let field = fieldsToValidate[i]
            let validate = validator[field]

            if (!validate) continue

            let err = validate(formData[field], formData)

            if (err?.length) {
                errs[field] = err
            }
        }

        return Object.keys(errs).length > 0 ? errs : null
    }, [formData, validator])

    const revertField = useCallback(<J extends keyof FormType>(key: J) => {
        setFormData(prev => {
            const clone = _.cloneDeep(prev);

            clone[key] = startingState.current[key]

            return clone;
        })
    }, [setFormData])

    const deleteField = useCallback(<J extends keyof FormType>(key: J) => {
        setFormData((prev) => {
            const clone = _.cloneDeep(prev);

            delete clone[key];

            return clone;
        });
    }, [setFormData]);

    return {
        formData,
        updatedFields,
        errors,
        updateFormData,
        handleFieldChange,
        revertField,
        deleteField,
    };
};

export default useFormState;
