import styled, { css } from "styled-components";

export const StyleCarousel = styled.div`
    display: flex;
    width: fit-content;
    margin: 0px auto;
    width: 100%;
`

export const CarouselWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${({width}) => width};
    margin: 0px auto;
    overflow: hidden;
    position: relative;

  .carousel-slider {
    display: flex;
    transition: all 0.4s;

    ${props => {
      if (!props.justifyTabs || props.justifyTabs.length === 0) return css`
        margin: auto;
      `

      return css`
        width: 100%;
        justify-content: ${props.justifyTabs};
      `
    }}
  }
  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const CarouselButton = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 0.1rem;
  cursor: pointer;
  height: 37px;

  ${props => props.displayButtons ? css`
    visibility: ${props => props.show  ? 'visible': 'hidden'};
  ` : css`
    display: none;
  `}

  svg {
      color: ${({theme}) => theme.color.accentColor2};
      pointer-events: auto;
      ${props => !props.useChevrons && css`
        fill: ${({theme}) => theme.color.accentColor4};
      `}
  }
`

export const CarouselGradient = styled.div`
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: ${({show, direction, theme}) => show && `linear-gradient(to ${direction}, transparent, 90%, ${theme.color.backgroundColor2})` };
`

