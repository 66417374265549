import { useState, useEffect } from 'react';
import { API } from "../../../constants/api"
import { Report } from '../../../helpers/creative.util';
import { makeRequest } from "../../../helpers/make-request"

type Props = {
    uid: string,
    lid: number,
}

export const useCreativeReports = (props: Props) => {
    const { uid, lid } = props;

    const [data, setData] = useState<Report>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        let abort = false;
        if (!uid || !lid) return;

        makeRequest() // The request to get all of the creative reports from backend
        .url(API.REPORT.GET_CREATIVES)
        .param('uid', uid)
        .param('lid', lid)
        .retry(3)
        .get()
        .then((response: Report) => {
            if (!abort) {
                setData(response);
                setLoading(false);
            }
        })
        return () => {
            abort = true;
        }
    }, [uid, lid]);

    return { data, loading }
}