import React from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { FiChevronDown } from 'react-icons/fi'
import { POST_STATUS } from '../../../../../helpers/creative.util'
import { UrlUtil } from '../../../../../helpers/url.util'
import { Network, Post } from '../../../creative.types'
import { useCreativeInfo } from '../../../utils'

export const NetworkStatusItemActionButton = (props: {
	post: Post,
	network: Network
	isDetailExpanded: boolean
	setIsDetailExpanded: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const { getCreativeStatus } = useCreativeInfo()

	const status = getCreativeStatus(props.post, props.network)
	const { postedNetworks } = props.post

	// we only want to show the icon for these cases
	// return if we do not have one of these statuses
	if (
		![
			POST_STATUS.REJECTED,
			POST_STATUS.CANT_POST,
			POST_STATUS.POSTED,
		].some((item) => item === status)
	)
		return null

	const targetNetwork = postedNetworks?.[props.network]

	if (!targetNetwork) return null

	if (
		[POST_STATUS.REJECTED, POST_STATUS.CANT_POST].some(
			(item) => item === status
		)
	) {
		return (
			<FiChevronDown
				onClick={() =>
					props.setIsDetailExpanded(!props.isDetailExpanded)
				}
				style={{
					cursor: 'pointer',
					transform: props.isDetailExpanded
						? 'rotate(180deg)'
						: 'rotate(0deg)',
					transition: 'transform 0.3s',
				}}
				size={18}
				className="external-svg"
			/>
		)
	}

	if (!postedNetworks?.[props.network]?.postUrl) return null

	return (
		<BiLinkExternal
			onClick={() =>
				window.open(
					UrlUtil.attachProtocol(targetNetwork.postUrl),
					'_blank'
				)
			}
			size={18}
			className="external-svg"
		/>
	)
}
